.video-thumbnail {
  position: relative;
  cursor: pointer;
  width: inherit;
  height: inherit;
}

.video-thumbnail img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  /* margin: auto; */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: red;
  font-size: 20px;
  cursor: pointer;
}

.close-button:hover {
  text-decoration: underline;
}


.video-section-image-gallery {
  display: flex;
  align-items: flex-start;
  width: 100%;
  align-self: stretch;
  justify-content: flex-start;
  gap: 16px;
  flex-direction: column;
  padding: 0 0 4px;
  box-sizing: border-box;
  height: 560px;
  /* gap: 12px; */
}


.video-section-heading1 {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  z-index: 1;

  font-weight: 500;
}

.video-section-gallery-images-container-child {
  position: relative;
  border-radius: 12px;
  object-fit: cover;
  max-width: 100%;
}

.video-section-gallery-images-container-child {
  height: 386px;
  width: 440px;

}

.video-section-gallery-images-container-item {
  height: 72px !important;
  width: 72px !important;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.video-section-gallery-images-container {
  flex: 1;
  border-radius: 12px;
  justify-content: center;
  padding: 157px 20px;
  box-sizing: border-box;
  background-image: url("../../icons/Gerator/detailed_listing/rectangle-431@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-width: 286px;
  z-index: 1;
}

.video-section-gallery-images,
.video-section-gallery-images-container,
.video-section-gallery-images-container1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.video-section-gallery-images-container1 {
  flex: 1;
  border-radius: 12px;
  justify-content: center;
  padding: 157px 20px;
  box-sizing: border-box;
  background-image: url("../../icons/Gerator/detailed_listing/rectangle-196@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-width: 286px;
  z-index: 1;
}

.video-section-gallery-images {
  align-self: stretch;
  justify-content: flex-start;
  gap: 16px;
}

@media screen and (max-width: 1100px) {

  .video-section-gallery-images {
    flex-wrap: wrap;
  }
}


@media screen and (max-width:450px) {

  .video-section-gallery-images-container,
  .video-section-gallery-images-container1 {
    padding-top: 102px;
    padding-bottom: 102px;
    box-sizing: border-box;
  }

}