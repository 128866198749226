@import url( 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.reset-password-check-mail-gerator-04-1-icon1 {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}
.reset-password-check-mail-app-wrapper,
.reset-password-check-mail-logo1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.reset-password-check-mail-logo1 {
    align-self: stretch;
    height: 32px;
    flex-direction: row;
    background-image: url('../../icons/Gerator/reset_password/gerator04-1@2x.png');
    /* background-image: url('../../../public/reset_password/gerator04-1@2x.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  .reset-password-check-mail-app-wrapper {
    width: 121px;
    flex-direction: column;
    padding: 6px 0 0;
    box-sizing: border-box;
  }
  .reset-password-check-mail-placeholder-icon7 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .reset-password-check-mail-action-label {
    text-decoration: none;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #475467;
    text-align: left;
    display: inline-block;
    min-width: 47px;
    white-space: nowrap;
  }
  .reset-password-check-mail-buttonsbutton4,
  .reset-password-check-mail-text-padding3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  .reset-password-check-mail-buttonsbutton4 {
    cursor: pointer;
    border: 0;
    padding: 10px 16px;
    background-color: transparent;
    border-radius: 8px;
    overflow: hidden;
    gap: 6px;
  }
  .reset-password-check-mail-text5 {
    text-decoration: none;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 59px;
    white-space: nowrap;
  }

  .reset-password-check-mail-reset-password-check-email-hamburger-menu{
   display: none;
  }

  .reset-password-check-mail-buttonsbutton5,
  .reset-password-check-mail-navigation-actions1 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .reset-password-check-mail-buttonsbutton5 {
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 15px;
    background-color: #7e56d9;
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    justify-content: center;
    gap: 6px;
  }
  .reset-password-check-mail-navigation-actions1 {
    width: 190px;
    justify-content: flex-start;
    gap: 12px;
  }
 
  .reset-password-check-mail-page-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: 100%;
  }
  .reset-password-check-mail-page-content {
    flex: 1;
    justify-content: space-between;
    gap: 20px;
  }
  .reset-password-check-mail-main {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    justify-content: flex-start;
    padding: 0 32px;
    box-sizing: border-box;
  }
  .reset-password-check-mail-featured-icon1 {
    width: 56px;
    height: 56px;
    position: relative;
    border-radius: 12px;
  }
  .reset-password-check-mail-title {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: inherit;
    line-height: 38px;
    font-weight: 600;
    font-family: inherit;
  }
  .reset-password-check-mail-oliviauntitleduicom {
    font-weight: 500;
  }
  .reset-password-check-mail-supporting-text1 {
    align-self: stretch;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
  }
  .reset-password-check-mail-header1,
  .reset-password-check-mail-text-and-supporting-text1 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
  }
  .reset-password-check-mail-header1 {
    align-items: center;
    gap: 24px;
    text-align: center;
    font-size: 30px;
    color: #101828;
  }
  .reset-password-check-mail-text6 {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 121px;
  }
  .reset-password-check-mail-buttonsbutton6 {
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 20px;
    background-color: #7e56d9;
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
  .reset-password-check-mail-text7 {
    position: relative;
    line-height: 20px;
  }
  .reset-password-check-mail-placeholder-icon12 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
    min-height: 20px;
  }
  .reset-password-check-mail-option-label {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 102px;
    color: #6941c6;
    overflow: hidden;
    align-items: center;
    gap: 6px;
  }
  .reset-password-check-mail-buttonsbutton7,
  .reset-password-check-mail-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  /* .buttonsbutton7 {
    overflow: hidden;
    align-items: center;
    gap: 6px;
    color: #6941c6;
  } */
  .reset-password-check-mail-row {
    align-items: flex-start;
    padding: 0 46px;
    gap: 3px;
  }
 
 
 
  .reset-password-check-mail-container2,
  .reset-password-check-mail-container3,
  .reset-password-check-mail-content3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .reset-password-check-mail-content3 {
    width: 360px;
    gap: 32px;
    max-width: 360px;
  }
  .reset-password-check-mail-container2,
  .reset-password-check-mail-container3 {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    max-width: 1280px;
  }
  .reset-password-check-mail-container2 {
    padding: 0 32px;
    text-align: left;
    font-size: 14px;
    color: #475467;
    font-family: Inter;
  }
  .reset-password-check-mail-help-link {
    bottom: 32px;
    left: 32px;
    line-height: 20px;
  }
  .reset-password-check-mail-help-link,
  .reset-password-check-mail-helpcentergeratorcom1,
  .reset-password-check-mail-mail-01-icon1 {
    position: absolute;
    margin: 0 !important;
    z-index: 1;
  }
  .reset-password-check-mail-mail-01-icon1 {
    width: 16px;
    height: 16px;
    right: 204px;
    bottom: 34px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .reset-password-check-mail-helpcentergeratorcom1 {
    right: 31.5px;
    bottom: 32px;
    line-height: 20px;
    white-space: nowrap;
  }
  .reset-password-check-mail-log-in1,
  .reset-password-check-mail-reset-password-check-email {
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
  }
  .reset-password-check-mail-log-in1 {
    align-self: stretch;
    align-items: center;
    padding:0 100px;
    gap: 32px;
    height: max-content;
    text-align: left;
    font-size: 14px;
    color: #475467;
    font-family: Inter;
  }

  .reset-password-check-mail-footer{
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    align-self: stretch;
    align-items: center;
    
    gap: 32px;
    height: max-content;
    text-align: left;
    font-size: 14px;
    color: #475467;
    font-family: Inter;
  }

  .reset-password-check-mail-reset-password-check-email {
    width: 100%;
    overflow: hidden;
    align-items: flex-start;
    padding: 18px 0 0;
    gap: 138px;
    line-height: normal;
    letter-spacing: normal;
   
  }
  @media screen and (max-width: 1275px) {
    .reset-password-check-mail-container2,
    .reset-password-check-mail-container3 {
      max-width: 100%;
    }
  }
  @media screen and (max-width: 1100px) {
    .reset-password-check-mail-log-in1 {
      padding-top: 62px;
      padding-bottom: 329px;
      box-sizing: border-box;
    }

   
  }
  @media screen and (max-width: 750px) {
    .reset-password-check-mail-title {
      font-size: 24px;
      line-height: 30px;
    }
    .reset-password-check-mail-row {
      flex-wrap: wrap;
    }
    .reset-password-check-mail-container2 {
      gap: 16px;
    }
    .reset-password-check-mail-log-in1 {
      gap: 16px;
      padding: 40px 40px 214px;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 450px) {
  
    
    .reset-password-check-mail-reset-password-check-email {
    justify-content: space-between;
    }
    .reset-password-check-mail-navigation-actions1{
     display: none;
    }

    .reset-password-check-mail-reset-password-check-email-hamburger-menu{
      display: flex;
      position: relative;
      right: -22px;
    }

    .reset-password-check-mail-title {
      font-size: 18px;
      line-height: 23px;
    }
    .reset-password-check-mail-row {
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
    }
    .reset-password-check-mail-container3,
    .reset-password-check-mail-content3 {
      gap: 16px;
    }

    .reset-password-check-mail-help-link {
      bottom: 72px;
      left: 32px;
      line-height: 20px;
    }
    .reset-password-check-mail-helpcentergeratorcom1,
    .reset-password-check-mail-mail-01-icon1,
    .reset-password-check-mail-help-link {
      position: absolute;
      margin: 0 !important;
      z-index: 1;
    }
    .reset-password-check-mail-mail-01-icon1 {
      width: 14px;
      height: 14px;
      right: 365px;
      bottom: 34px;
      overflow: hidden;
      flex-shrink: 0;
    }
    .reset-password-check-mail-helpcentergeratorcom1 {
      right: 190px;
      bottom: 32px;
      line-height: 20px;
      white-space: nowrap;
    }

  }
  