* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

body {
    background-color: #f5f5f5;
}

.container-detail-page {
    width: 100% !important;
    /* height: 1000px; */
    padding: 20px;
    background-color: white;
}

.skeleton {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    border-radius: 4px;
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }

    100% {
        background-position: 200% 0;
    }
}

.image-carousel {
    width: 100%;
    height: 220px;
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    position: relative;
}

.carousel-item {
    flex: 1;
    height: 100%;
}

.arrow-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    z-index: 10;
}

.arrow-left {
    left: 10px;
}

.arrow-right {
    right: 10px;
}

.photos-link {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 120px;
    height: 25px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
}

.tabs {
    display: flex;
    margin-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;
}

.tab {
    width: 100px;
    height: 30px;
    margin-right: 10px;
}

.product-header {
    margin-bottom: 20px;
}

.product-title {
    height: 40px;
    margin-bottom: 10px;
    width: 90%;
}

.product-meta {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

.meta-item {
    height: 20px;
    width: 120px;
}

.actions {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.action-btn {
    height: 30px;
    width: 80px;
}

.product-info {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    margin-bottom: 20px;
}

.price-container {
    height: 100px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.price {
    height: 40px;
    width: 150px;
}

.cta-button {
    height: 50px;
    width: 200px;
}

.highlights {
    margin-bottom: 20px;
}

.highlight-header {
    height: 30px;
    width: 150px;
    margin-bottom: 10px;
}

.highlight-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.highlight-item {
    height: 60px;
}

.seller-info {
    display: flex;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
}

.seller-photo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 20px;
}

.seller-details {
    flex: 1;
}

.seller-name {
    height: 20px;
    width: 150px;
    margin-bottom: 10px;
}

.seller-badge {
    height: 20px;
    width: 100px;
}

.section {
    margin-bottom: 20px;
}

.section-title {
    height: 25px;
    width: 200px;
    margin-bottom: 10px;
}

.section-content {
    height: 80px;
}

.jobs-section {
    margin-bottom: 20px;
}

.jobs-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.job-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.job-item {
    display: flex;
    align-items: center;
    height: 80px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 10px;
}

.job-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 20px;
}

.job-details {
    flex: 1;
    height: 60px;
}

.map-container {
    height: 200px;
    width: 100%;
    position: relative;
}

.map-controls {
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.map-control {
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 4px;
}

.map-attribution {
    height: 20px;
    width: 100%;
}