.report-abuse-confirmation-email-popup {
    /* width: 650px; */
    /* height: 546px; */
    overflow: hidden;
    background-color: #fff;
    border-radius: 12px;
    max-width: 100%;
  }
  
  .report-abuse-confirmation-frame-section {
    /* position: absolute; */
    border-radius: 12px;
    /* width: 633.4px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 34px 44px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    color: #475467;
    font-family: Inter;
  }
  
  .report-abuse-confirmation-header1 {
    align-items: center;
    text-align: center;
    font-size: 30px;
    color: #101828;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .report-abuse-confirmation-featured-icon1 {
    width: 56px;
    height: 56px;
  }
  
  .report-abuse-confirmation-title1 {
    margin: 0;
    font-size: inherit;
    line-height: 38px;
    font-weight: 600;
  }
  
  .report-abuse-confirmation-supporting-text5 {
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    padding: 15px 0;

  }
  
  .report-abuse-confirmation-row1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 12px;
  }
  
  .report-abuse-confirmation-buttonsbutton10 {
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 20px;
    background-color: #7e56d9;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: #fff;
  }
  
  .report-abuse-confirmation-buttonsbutton12 {
    background: none;
    border: none;
    cursor: pointer;
    font-weight: 600;
    color: #6941c6;
  }
  
  @media screen and (max-width: 616px) {
    .report-abuse-confirmation-row1 {
      flex-wrap: wrap;
    }
    .report-abuse-confirmation-email-popup {
      height: auto;
      min-height: 546px;
    }
  }
  