/* Custom CSS */
/* .beacon-beacon-frame-layout-custom {
  margin-top: 0px !important;
} */

.beacon-main-custom {
  width: 100% !important;
}

.beacon-search-lg-icon-custom {
  cursor: pointer;
}

.beacon-beacon-frame-layout-child {
  position: relative;
  background-color: #fff;
  display: none;
}

/* .beacon-beacon-frame-layout-child {
    align-self: stretch;
    height: 8320px;
    margin-top: 200px;
  } */








.beacon-text {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 30px;
}

.beacon-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}


.beacon-chevron-down-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}


.beacon-badge1,
.beacon-nav-item-base1 {
  background-color: #f9fafb;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}



.beacon-navigation,
.beacon-search-input {
  display: flex;
  justify-content: flex-start;
}

.beacon-search-input {
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
}

.beacon-label {
  width: 47px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.beacon-search-lg-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.beacon-search-placeholder {
  flex: 1;
  position: relative;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 8px;
  outline: none;
  border: none;
  font-size: 16px;
}

.beacon-content3,
.beacon-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.beacon-content3 {
  flex: 1;
  gap: 8px;
}








.beacon-divider-icon,
.beacon-home-line-icon {
  position: relative;
  overflow: hidden;
}

.beacon-divider-icon {
  align-self: stretch;
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
}



.beacon-contrast-border1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
}





.beacon-arrow-narrow-left-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}













.beacon-content12,
.beacon-navigation4 {
  display: flex;
  justify-content: flex-start;
}

.beacon-content12 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 142px 0 0;
  gap: 12px;
}

.beacon-content5,
.beacon-navigation4 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
}

.beacon-content5 {
  padding: 36px 16px 0;
  gap: 16px;
}

.beacon-supporting-text,
.beacon-title {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.beacon-title {
  font-weight: 600;
}

.beacon-supporting-text {
  color: #475467;
  white-space: nowrap;
}


.beacon-breadcrumb-button-base {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-breadcrumb-button-base {
  border-radius: 6px;
  padding: 4px;
}

.beacon-chevron-right-icon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.beacon-location {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 74px;
}

.beacon-breadcrumb-button-base1 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.beacon-text20 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.beacon-breadcrumb-button-base2 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 7px;
}

.beacon-chevron-right-icon2 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.beacon-text21 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  white-space: nowrap;
}

.beacon-breadcrumb-button-base3 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.beacon-text22 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 65px;
}

.beacon-breadcrumb-button-base4 {
  border-radius: 6px;
  background-color: #f9fafb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  color: #344054;
}

.beacon-tabs {
  gap: 8px;
}

.beacon-breadcrumbs,
.beacon-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.beacon-heading,
.beacon-supporting-text1 {
  align-self: stretch;
  position: relative;
}

.beacon-heading {
  line-height: 38px;
  font-weight: 600;
}

.beacon-supporting-text1 {
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.beacon-text-and-supporting-text {
  width: 678px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 320px;
  max-width: 100%;
  flex-shrink: 0;
  font-size: 30px;
  color: #101828;
}

.beacon-placeholder-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.beacon-text-padding {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.beacon-button,
.beacon-button1,
.beacon-button2 {
  height: 40px;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  gap: 4px;
}

.beacon-button {
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
}

.beacon-button1,
.beacon-button2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: #fff;
  padding: 10px 13px;
}

.beacon-button1 {
  border: 1px solid #d6bbfb;
  flex-direction: row;
  align-items: center;
  color: #6941c6;
}

.beacon-button2 {
  border: 1px solid #d0d5dd;
  color: #344054;
}

.beacon-actions,
.beacon-button2,
.beacon-button3 {
  flex-direction: row;
  align-items: center;
}

.beacon-button3 {
  height: 40px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
  color: #fff;
}

.beacon-actions {
  display: none;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
}

.beacon-hint-text {
  width: 320px;
  position: relative;
  line-height: 20px;
  display: none;
}

.beacon-button4,
.beacon-button5 {
  align-self: stretch;
  border-radius: 8px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 14px;
  gap: 4px;
}

.beacon-button5 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: #fff;
  border: 1px solid #d6bbfb;
  padding: 10px 13px;
  color: #6941c6;
}

.beacon-upload-04-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}

.beacon-text29 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 45px;
}

.beacon-button6,
.beacon-text-padding6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.beacon-button6 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  padding: 8px 13px;
  gap: 4px;
  color: #344054;
}

.beacon-text30 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 73px;
}

.beacon-button7 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 13px;
  gap: 4px;
  color: #fff;
}

.beacon-content13,
.beacon-input-dropdown {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.beacon-input-dropdown {
  width: 320px;
  flex-direction: column;
  gap: 8px;
  min-width: 200px;
  max-width: 320px;
  flex-shrink: 0;
}

.beacon-content13 {
  flex-direction: row;
  padding: 0;
  box-sizing: border-box;
  gap: 70px;
}

.beacon-content13,
.beacon-divider-icon1,
.beacon-page-header {
  align-self: stretch;
  max-width: 100%;
}

.beacon-divider-icon1 {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  display: none;
}

.beacon-page-header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.beacon-text31 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  /* min-width: 71px; */
}

.beacon-button-group-base {
  background-color: #f9fafb;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
  white-space: nowrap;
}

.beacon-text32 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 55px;
}

.beacon-button-group-base1,
.beacon-button-group-base2 {
  width: 87px;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
  white-space: nowrap;
}

.beacon-button-group-base2 {
  width: 77px;
}

.beacon-text34 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.beacon-button-group-base3,
.beacon-button-group-base4,
.beacon-button-group-base5,
.beacon-button-group-base6,
.beacon-button-group-base7 {
  align-self: stretch;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  min-height: 40px;
  z-index: 5;
}

.beacon-button-group-base4,
.beacon-button-group-base5,
.beacon-button-group-base6,
.beacon-button-group-base7 {
  z-index: 4;
}

.beacon-button-group-base5,
.beacon-button-group-base6,
.beacon-button-group-base7 {
  z-index: 3;
}

.beacon-button-group-base6,
.beacon-button-group-base7 {
  z-index: 2;
}

.beacon-button-group-base7 {
  z-index: 1;
}

.beacon-text39 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 61px;
}

.beacon-button-group,
.beacon-button-group-base8 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.beacon-button-group-base8 {
  width: 93px;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
  white-space: nowrap;
}

.beacon-button-group {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  overflow-x: auto;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.beacon-filter-date {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 85px;
}

.beacon-buttonsbutton1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 13px;
  gap: 4px;
  cursor: pointer;
  text-wrap: nowrap;
}

.beacon-date-picker-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-filter-options {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 43px;
}

.beacon-actions2,
.beacon-buttonsbutton2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.beacon-buttonsbutton2 {
  width: 99px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  padding: 8px 13px;
  gap: 4px;
  color: #344054;
}

.beacon-actions2 {
  justify-content: flex-start;
  gap: 12px;
  color: #667085;
}

.beacon-container,
.beacon-header-section,
.beacon-tabs-and-filters {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.beacon-tabs-and-filters {
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  color: #344054;
}

.beacon-container,
.beacon-header-section {
  flex-direction: column;
  justify-content: flex-start;
}

.beacon-container {
  padding: 0 32px;
  box-sizing: border-box;
  gap: 24px;
}

.beacon-header-section {
  flex-shrink: 0;
}

.beacon-color {
  width: 8px;
  height: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #7e56d9;
}

.beacon-legend-color {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 0 0;
}

.beacon-series-1 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 35px;
}

.beacon-legend-series {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.beacon-color1 {
  width: 8px;
  height: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #b692f6;
}

.beacon-series-2 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 33px;
}

.beacon-color2,
.beacon-color3 {
  width: 8px;
  height: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #6941c6;
}

.beacon-color3 {
  background-color: #17b26a;
}

.beacon-series-4 {
  height: 20px;
  position: relative;
  line-height: 20px;
  display: inline-block;
}

.beacon-legend-series3 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
}

.beacon-color4 {
  height: 8px;
  width: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #6172f3;
}

.beacon-series-5 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.beacon-legend-series4 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.beacon-color5 {
  height: 8px;
  width: 8px;
  position: relative;
  border-radius: 50%;
  background-color: #ee46bb;
}

.beacon-legend {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.beacon-divider,
.beacon-y-axis-line,
.beacon-y-axis-line1 {
  align-self: stretch;
  box-sizing: border-box;
  max-width: 100%;
}

.beacon-divider {
  flex: 1;
  position: relative;
  border-top: 1px solid #f2f4f7;
}

.beacon-y-axis-line,
.beacon-y-axis-line1 {
  height: 22px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 11px 0 10px;
}

.beacon-y-axis-line1 {
  height: 17px;
  padding: 8.5px 0 7.5px;
}

.beacon-divider6 {
  height: 1px;
  width: 1217px;
  position: relative;
  border-top: 1px solid #f2f4f7;
  box-sizing: border-box;
  max-width: 121%;
  flex-shrink: 0;
}

.beacon-y-axis,
.beacon-y-axis-line6 {
  align-self: stretch;
  justify-content: flex-start;
  max-width: 100%;
}

.beacon-y-axis-line6 {
  height: 17px;
  display: none;
  flex-direction: row;
  align-items: center;
}

.beacon-y-axis {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14.2px;
}

.beacon-jan,
.beacon-jul,
.beacon-jun,
.beacon-mar,
.beacon-may {
  position: relative;
  line-height: 18px;
  display: inline-block;
  min-width: 21px;
}

.beacon-jul,
.beacon-jun,
.beacon-mar,
.beacon-may {
  min-width: 23px;
}

.beacon-jul,
.beacon-jun,
.beacon-may {
  min-width: 25px;
}

.beacon-jul,
.beacon-jun {
  min-width: 22px;
}

.beacon-jul {
  min-width: 17px;
}

.beacon-x-axis {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 24px;
  gap: 20px;
  font-size: 12px;
}

.beacon-chart-data-icon {
  width: 100%;
  height: 170px;
  position: absolute;
  margin: 0 !important;
  top: 20px;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 1;
}

.beacon-content14 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  position: relative;
  max-width: 100%;
}

.beacon-line-and-bar-chart,
.beacon-line-and-bar-chart-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.beacon-line-and-bar-chart {
  flex: 1;
}

.beacon-line-and-bar-chart-wrapper {
  align-self: stretch;
  padding: 0 32px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.beacon-heading1,
.beacon-number {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.beacon-number {
  line-height: 38px;
  white-space: nowrap;
}

.beacon-change1 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 29px;
}

.beacon-change {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.beacon-change-duration {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: #475467;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.beacon-change-and-text,
.beacon-number-and-badge {
  display: flex;
  justify-content: flex-start;
}

.beacon-change-and-text {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #079455;
}

.beacon-number-and-badge {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  min-width: 84px;
}

.beacon-chart-mini-icon {
  height: 64px;
  width: 128px;
  position: relative;
  object-fit: contain;
}

.beacon-number-and-chart {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 16px;
  font-size: 30px;
}

.beacon-dots-vertical-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.beacon-dropdown,
.beacon-metric-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-dropdown {
  margin: 0 !important;
  position: absolute;
  top: 20px;
  right: 20px;
}

.beacon-metric-item {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 20px 20px 24px 24px;
  position: relative;
  gap: 24px;
  min-width: 320px;
  max-width: 100%;
}

.beacon-change2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1px;
}

.beacon-change-and-text1,
.beacon-container1,
.beacon-metric-group,
.beacon-section {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.beacon-change-and-text1 {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #da2d20;
}

.beacon-container1,
.beacon-metric-group,
.beacon-section {
  align-items: flex-start;
  max-width: 100%;
}

.beacon-metric-group {
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 4px 0 0;
  box-sizing: border-box;
  gap: 24px 22px;
}

.beacon-container1,
.beacon-section {
  flex-direction: column;
}

.beacon-container1 {
  padding: 0 32px;
  box-sizing: border-box;
}

.beacon-section {
  flex-shrink: 0;
  font-size: 16px;
  color: #101828;
}

.beacon-separator,
.beacon-separator1 {
  width: 1px;
  height: 29px;
  position: relative;
  border-right: 1px solid #000;
  box-sizing: border-box;
  flex-shrink: 0;
}

.beacon-separator1 {
  height: 43px;
}

.beacon-main,
.beacon-main-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.beacon-main {
  height: 692px;
  flex: 1;
  background-color: #fff;
  overflow: hidden;
  flex-direction: column;
  padding: 32px 0;
  gap: 32px;
  z-index: 1;
}

.beacon-main-wrapper {
  align-self: stretch;
  flex-direction: column;
  padding: 0 0 4px;
  color: #475467;
}

.beacon-text42 {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.beacon-breadcrumb-button-base6,
.beacon-breadcrumb-button-base7 {
  align-self: stretch;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.beacon-breadcrumb-button-base6 {
  flex: 1;
  padding: 4px 8px;
}

.beacon-breadcrumb-button-base7 {
  padding: 4px 7px;
}

.beacon-breadcrumbs1,
.beacon-tabs1 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.beacon-tabs1 {
  height: 28px;
  flex: 1;
  display: flex;
  gap: 8px;
}

.beacon-breadcrumbs1 {
  width: 278px;
  display: none;
}

.beacon-text46 {
  align-self: stretch;
  position: relative;
  line-height: 38px;
  font-weight: 600;
}

.beacon-supporting-text2 {
  width: 445px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  display: none;
  max-width: 100%;
}

.beacon-text-and-supporting-text1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 320px;
  max-width: 100%;
}

.beacon-text47 {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.beacon-content16 {
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.beacon-content16,
.beacon-input-with-label1,
.beacon-input1 {
  align-self: stretch;
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.beacon-input1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 10px 13px;
  font-size: 16px;
  color: #667085;
}

.beacon-input-with-label1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.beacon-hint-text1 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.beacon-input-dropdown1 {
  height: 44px;
  width: 320px;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  min-width: 200px;
  max-width: 320px;
  font-size: 14px;
  color: #344054;
}

.beacon-content15 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px 16px;
  font-size: 26px;
  color: #101828;
}

.beacon-content15,
.beacon-page-header-wrapper,
.beacon-page-header1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.beacon-page-header1 {
  flex: 1;
  background-color: #fff;
  flex-direction: column;
  gap: 20px;
  z-index: 1;
}

.beacon-page-header-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 32px 4px;
  box-sizing: border-box;
  color: #475467;
}

.beacon-supporting-text3 {
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: 100%;
  z-index: 1;
}

.beacon-supporting-text-wrapper {
  width: 742px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 32px 20px;
  box-sizing: border-box;
  max-width: 100%;
  font-size: 16px;
  color: #475467;
}

.beacon-tab-name {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 55px;
  white-space: nowrap;
}

.beacon-tab-button-base {
  border-bottom: 2px solid #7e56d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 4px 9px;
  color: #6941c6;
}

.beacon-text48 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 48px;
  white-space: nowrap;
}

.beacon-tab-button-base1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 4px 12px;
}

.beacon-text49,
.beacon-text50,
.beacon-text51 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 52px;
  white-space: nowrap;
}

.beacon-text50,
.beacon-text51 {
  min-width: 62px;
}

.beacon-text51 {
  min-width: 34px;
}

.beacon-tab-button-base4 {
  width: 41px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 3px 12px;
  box-sizing: border-box;
}

.beacon-text52 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  white-space: nowrap;
}

.beacon-tab-button-base5 {
  align-self: stretch;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 4px 12px;
}

.beacon-text55 {
  align-self: stretch;
  position: relative;
  line-height: 18px;
  font-weight: 500;
  white-space: nowrap;
}

.beacon-badge10 {
  height: 22px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
  color: #344054;
}

.beacon-tab-button-base7,
.beacon-tab-button-base9 {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.beacon-tab-button-base7 {
  height: 32px;
  padding: 0 4px 12px;
  box-sizing: border-box;
  gap: 8px;
}

.beacon-tab-button-base9 {
  align-self: stretch;
  padding: 0 3px 12px;
}

.beacon-tabs2 {
  overflow-x: auto;
  flex-direction: row;
  padding: 0 20px 0 0;
  gap: 12px;
}

.beacon-horizontal-tabs,
.beacon-horizontal-tabs-wrapper,
.beacon-tabs2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.beacon-horizontal-tabs {
  flex: 1;
  background-color: #fff;
  border-bottom: 1px solid #eaecf0;
  flex-direction: column;
  /* z-index: 1; */
}

.beacon-horizontal-tabs-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 24px 32px;
  color: #667085;
}

.beacon-frame-item {
  width: 188px;
  height: 154px;
  position: relative;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
}

.beacon-heading4 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  z-index: 1;
}

.beacon-metric-card-title {
  flex: 1;
  flex-direction: column;
  padding: 4px 0 0;
}

.beacon-dropdown3,
.beacon-metric-card,
.beacon-metric-card-title {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-dropdown3 {
  flex-direction: column;
  position: relative;
  /* z-index: 1; */
}

.beacon-metric-card {
  align-self: stretch;
  flex-direction: row;
}

.beacon-number3 {
  width: 155px;
  position: relative;
  font-size: 36px;
  letter-spacing: -0.02em;
  line-height: 44px;
  font-weight: 600;
  color: #101828;
  display: inline-block;
  z-index: 1;
}

.beacon-metric-card-row {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.beacon-arrow-up-icon2 {
  width: 12px;
  height: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.beacon-options-icon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 0 0;
}

.beacon-text58 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 116px;
}

.beacon-badge11 {
  width: 150px;
  border-radius: 9999px;
  border: 1.5px solid #079455;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px 0 8px;
  gap: 4px;
  z-index: 1;
  text-align: center;
  color: #067647;
}

.beacon-frame-inner,
.beacon-rectangle-group {
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
}

.beacon-rectangle-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 4px 22px 15px;
  gap: 8px;
  min-width: 184px;
  max-width: 188px;
}

.beacon-frame-inner {
  height: 154px;
  width: 188px;
  position: relative;
  display: none;
}

.beacon-heading5,
.beacon-number4 {
  position: relative;
  z-index: 1;
}

.beacon-heading5 {
  width: 147px;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
}

.beacon-number4 {
  align-self: stretch;
  font-size: 36px;
  letter-spacing: -0.02em;
  line-height: 44px;
  font-weight: 600;
  color: #101828;
}

.beacon-change-amount {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 32px;
}

.beacon-badge12 {
  width: 64px;
  border-radius: 9999px;
  border: 1.5px solid #079455;
  box-sizing: border-box;
  flex-direction: row;
  padding: 0 7px 0 8px;
  gap: 2px;
  z-index: 1;
  text-align: center;
  color: #067647;
}

.beacon-badge12,
.beacon-frame-container,
.beacon-heading-parent,
.beacon-rectangle-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-heading-parent {
  width: 157px;
  flex-direction: column;
  gap: 8px;
}

.beacon-frame-container,
.beacon-rectangle-container {
  box-sizing: border-box;
}

.beacon-frame-container {
  width: 147px;
  flex-direction: column;
  padding: 4px 0 0;
}

.beacon-rectangle-container {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  flex-direction: row;
  padding: 20px 4px 22px 15px;
  min-width: 184px;
  max-width: 188px;
}

.beacon-heading-group {
  width: 153px;
  gap: 8px;
}

.beacon-dropdown7,
.beacon-heading-container,
.beacon-heading-group,
.beacon-heading-parent1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-heading-container {
  width: 163px;
  gap: 8px;
}

.beacon-dropdown7,
.beacon-heading-parent1 {
  flex-shrink: 0;
}

.beacon-heading-parent1 {
  width: 170px;
  gap: 8px;
}

.beacon-dropdown7 {
  z-index: 1;
}

.beacon-frame-group,
.beacon-frame-wrapper,
.beacon-rectangle-parent2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.beacon-rectangle-parent2 {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  justify-content: flex-start;
  padding: 20px 2px 22px 16px;
  min-width: 184px;
  max-width: 188px;
}

/* .beacon-frame-group,
  .beacon-frame-wrapper {
    max-width: 100%;
  } */

.beacon-frame-group {
  flex: 1;
  justify-content: flex-start;
  gap: 19px;
  /* z-index: 1; */
}

.beacon-frame-wrapper {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 30px 24px 32px;
  box-sizing: border-box;
  color: #475467;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.beacon-frame-wrapper::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}

.beacon-text62,
.beacon-text63 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 51px;
}

.beacon-text63 {
  min-width: 74px;
}

.beacon-buttonsbutton4,
.beacon-text-padding11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 1px;
}

.beacon-buttonsbutton4 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  padding: 8px 13px;
  gap: 4px;
  min-width: 84px;
  cursor: pointer;
}

.beacon-text64 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 78px;
}

.beacon-buttonsbutton5 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 13px;
  gap: 4px;
  min-width: 87px;
  cursor: pointer;
}

.beacon-text65 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 50px;
}

.beacon-dropdowns,
.beacon-input2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.beacon-dropdowns {
  /* flex: 0.7552; */
  flex: .5;
  align-items: flex-start;
  /* padding: 0 166px 0 0; */
  box-sizing: border-box;
  gap: 12px;
  max-width: 100%;
}

.beacon-input2 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  align-items: center;
  padding: 8px 13px;
  font-size: 16px;
  color: #667085;
  min-width: 208px;
  max-width: 320px;
}

.beacon-input-dropdown2,
.beacon-input-with-label2 {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  /* flex: .85; */
  justify-content: flex-start;
}

.beacon-input-with-label2 {
  align-self: stretch;
  gap: 6px;
  flex: .65;
}

.beacon-input-dropdown2 {
  flex: .5;
  flex-direction: row !important;
  justify-content: flex-end !important;
  gap: 8px;
  /* min-width: 208px;
    max-width: 320px; */
}

.beacon-content17 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.beacon-content17,
.beacon-filters-bar,
.beacon-section1 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.beacon-filters-bar {
  align-self: stretch;
  border-radius: 8px;
  flex-direction: row;
  align-items: flex-start;
}

.beacon-section1 {
  flex: 1;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.beacon-frame-child3,
.beacon-section-wrapper {
  box-sizing: border-box;
  max-width: 100%;
}

.beacon-section-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 24px;
  color: #344054;
  position: relative;
}

.beacon-frame-child3 {
  width: 1016px;
  height: 282px;
  position: relative;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  display: none;
}

.beacon-content-elements-icon {
  height: 148px;
  width: 148px;
  position: relative;
  border-radius: 12px;
  object-fit: cover;
  z-index: 1;
}

.beacon-search-input-icons {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  z-index: 1;
}

.beacon-search-inputs {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 1px;
}

.beacon-dot-icon10 {
  height: 8px;
  width: 8px;
  position: relative;
  display: none;
}

.beacon-badge-number {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 66px;
}

.beacon-badge16 {
  width: 84px;
  border-radius: 9999px;
  background-color: #edfbf5;
  border: 1px solid #41d992;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 1;
  text-align: center;
  font-size: 14px;
  color: #17b26a;
}

.beacon-supporting-text4 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 83px;
  z-index: 1;
}

.beacon-marker-pin-02-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.beacon-location1 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 124px;
  z-index: 1;
}

.beacon-location-pin-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
}

.beacon-type-of-work {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 109px;
  z-index: 1;
}

.beacon-location-badge,
.beacon-location-badge-number,
.beacon-type-number {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-location-badge-number {
  flex: 1;
  gap: 20px;
  max-width: 100%;
}

.beacon-location-badge,
.beacon-type-number {
  box-sizing: border-box;
}

.beacon-location-badge {
  align-self: stretch;
  padding: 0 0 0 1px;
  max-width: 100%;
}

.beacon-type-number {
  width: 50px;
  border-radius: 8px;
  background-color: #fdb022;
  padding: 1px 4px;
  z-index: 1;
}

.beacon-supporting-text5 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 117px;
  z-index: 1;
}

.beacon-type-support {
  flex-direction: column;
  padding: 1px 0 0;
  color: #475467;
}

.beacon-location-elements,
.beacon-type-badge-elements,
.beacon-type-support {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-type-badge-elements {
  flex-direction: row;
  gap: 8px;
  color: #fff;
}

.beacon-location-elements {
  align-self: stretch;
  flex-direction: column;
  gap: 15px;
  max-width: 100%;
}

.beacon-card-badge-number {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.beacon-badge17 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  min-width: 112px;
  white-space: nowrap;
  z-index: 1;
}

.beacon-badge-number-element {
  width: 61px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 61px;
}

.beacon-badge18 {
  width: 77px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 1;
}

.beacon-second-badge-count {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 60px;
}

.beacon-badge19 {
  width: 76px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 1;
}

.beacon-third-badge-number {
  width: 149px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
}

.beacon-badge20 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  min-width: 107px;
  white-space: nowrap;
  z-index: 1;
}

.beacon-badge-elements {
  flex: 1;
  flex-direction: row;
  gap: 8px;
}

.beacon-badge-elements,
.beacon-badge-elements-wrapper,
.beacon-card-badge-elements {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.beacon-badge-elements-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 1px;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.beacon-card-badge-elements {
  flex: 1;
  flex-direction: column;
  gap: 15px;
}

.beacon-location-badge-elements {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 77px;
}

.beacon-badge21 {
  width: 93px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 1;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.beacon-action-fields,
.beacon-card-elements,
.beacon-input-elements {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.beacon-card-elements {
  width: 616px;
  flex-direction: row;
  align-items: flex-end;
  gap: 7px;
  font-size: 16px;
  color: #475467;
}

.beacon-action-fields,
.beacon-input-elements {
  flex-direction: column;
  align-items: flex-start;
}

.beacon-input-elements {
  align-self: stretch;
  gap: 16px;
}

.beacon-action-fields {
  flex: 1;
  padding: 10px 0 0;
  box-sizing: border-box;
}

.beacon-price {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  white-space: nowrap;
  z-index: 1;
}

.beacon-dropdown-options {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 83px;
  z-index: 2;
}

.beacon-magnifier-1-icon {
  height: 20px;
  width: 20px;
  position: relative;
  object-fit: cover;
  min-height: 20px;
}

.beacon-frame-wrapper3,
.beacon-beacon-1-1-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-beacon-1-1-parent {
  flex: 1;
  gap: 8px;
  z-index: 1;
}

.beacon-frame-wrapper3 {
  align-self: stretch;
  padding: 0 4px 0 3px;
}

.beacon-dropdown-options-parent {
  flex: 1;
  flex-direction: column;
  gap: 4px;
}

.beacon-dropdown-elements,
.beacon-dropdown-options-parent,
.beacon-price-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-dropdown-elements {
  align-self: stretch;
  flex-direction: row;
  padding: 0 30px;
  font-size: 12px;
  color: #475467;
}

.beacon-price-parent {
  flex: 1;
  flex-direction: column;
}

.beacon-filter-elements-inner {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 10px 0 0;
}

.beacon-filter-elements {
  width: 153px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 26px;
  text-align: center;
  font-size: 20px;
  color: #6941c6;
}

.beacon-content-actions,
.beacon-content-elements-parent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100%;
}

.beacon-content-actions {
  flex: 1;
  align-items: flex-start;
  gap: 15px;
  min-width: 525px;
}

.beacon-content-elements-parent {
  align-self: stretch;
  align-items: flex-end;
  gap: 23px;
}

.beacon-location-column-child {
  height: 52px;
  width: 52px;
  position: relative;
  object-fit: cover;
  z-index: 1;
  border-radius: 99px;
  background-image: url(../../icons/Gerator/dashboard/user-placeholder2.png);
}

.beacon-name,
.beacon-role {
  position: relative;
  display: inline-block;
}

.beacon-name {
  line-height: 23px;
  font-weight: 600;
  min-width: 77px;
  z-index: 1;
}

.beacon-role {
  height: 19px;
  font-size: 12px;
  line-height: 24px;
  color: #6941c6;
  flex-shrink: 0;
  min-width: 87px;
  z-index: 2;
}

.beacon-location-column-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3px 0 0;
}

.beacon-text67 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 34px;
}

.beacon-buttonsbutton7 {
  width: 90px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 12px;
  gap: 6px;
  z-index: 1;
}

.beacon-icon1 {
  width: 16.7px;
  height: 16.7px;
  position: relative;
}

.beacon-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3.7px 0 0;
}

.beacon-text68 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 56px;
}

.beacon-buttonsbutton8,
.beacon-buttonsbutton9 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 11px;
  gap: 5.5px;
  min-width: 86px;
  z-index: 1;
}

.beacon-buttonsbutton9 {
  padding: 6px 10px 6px 12px;
  gap: 5.4px;
}

.beacon-buttonsbutton-parent {
  align-self: stretch;
  flex-direction: row;
  gap: 16px;
}

.beacon-buttonsbutton-parent,
.beacon-primary-table,
.beacon-primary-table-inner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-primary-table-inner {
  flex: 1;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
  min-width: 250px;
  max-width: 100%;
  color: #344054;
}

.beacon-primary-table {
  width: 556.4px;
  flex-direction: row;
  gap: 24px;
  max-width: calc(100% - 44px);
}

.beacon-arrow-square-up-right-icon {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.beacon-arrow-square-up-right-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 14px 0 0;
}

.beacon-primary-table-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  gap: 20px;
}

.beacon-content-items,
.beacon-help-avatar,
.beacon-rectangle-parent3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.beacon-help-avatar {
  width: 968px;
  flex-direction: column;
  gap: 16px;
  font-size: 14px;
}

.beacon-content-items,
.beacon-rectangle-parent3 {
  box-sizing: border-box;
}

.beacon-rectangle-parent3 {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  flex-direction: column;
  padding: 12px 13px 15px 23px;
  gap: 24px;
  z-index: 1;
}

.beacon-content-items {
  align-self: stretch;
  flex-direction: row;
  padding: 0 30px 24px 32px;
  font-size: 18px;
}

.beacon-text71 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 55px;
}

.beacon-badge22 {
  width: 73px;
  border-radius: 9999px;
  background-color: #fff9ef;
  border: 1px solid #f5b231;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 1;
}

.beacon-supporting-text6 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 83px;
  z-index: 1;
}

.beacon-badge-parent {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.beacon-badge-parent,
.beacon-badge23,
.beacon-frame-parent4 {
  flex-direction: row;
  justify-content: flex-start;
}

.beacon-frame-parent4 {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  text-align: left;
  font-size: 16px;
  color: #fff;
}

.beacon-badge23 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 1;
  color: #344054;
}

.beacon-badge23,
.beacon-badge24,
.beacon-frame-parent3 {
  display: flex;
  align-items: flex-start;
}

.beacon-frame-parent3 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
  min-width: 114px;
  color: #f5b231;
}

.beacon-badge24 {
  flex-direction: row;
  white-space: nowrap;
}

.beacon-badge24,
.beacon-badge25 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 1;
}

.beacon-badge-elements-copy,
.beacon-badge25,
.beacon-price-badge-elements {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.beacon-badge-elements-copy {
  flex: 1;
  justify-content: flex-start;
  gap: 8px;
}

.beacon-price-badge-elements {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 6px 0 0;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.beacon-frame-parent5 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 56px;
  min-width: 109px;
  text-align: left;
  font-size: 16px;
}

.beacon-badge26,
.beacon-date-elements {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 10px 0 18px;
}

.beacon-badge26 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  justify-content: flex-start;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 1;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.beacon-badge27,
.beacon-date-elements-parent {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
}

.beacon-date-elements-parent {
  flex: 0.9649;
  flex-direction: column;
  align-items: flex-end;
  padding: 0 6px 0 0;
  gap: 56px;
  min-width: 111px;
  text-align: left;
  font-size: 16px;
}

.beacon-badge27 {
  width: 93px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 7px;
  z-index: 1;
  color: #344054;
}

.beacon-frame-parent2 {
  width: 615px;
  align-items: flex-end;
  padding: 0 2px 0 0;
  box-sizing: border-box;
  gap: 2px;
  text-align: center;
  font-size: 14px;
  color: #475467;
}

.beacon-frame-parent1,
.beacon-frame-parent2,
.beacon-rectangle-parent5 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100%;
}

.beacon-frame-parent1 {
  flex: 1;
  align-items: flex-start;
  gap: 15px;
  min-width: 524px;
}

.beacon-rectangle-parent5 {
  align-self: stretch;
  align-items: flex-end;
  gap: 24px;
}

.beacon-frame-child5,
.beacon-frame-child6 {
  width: 24px;
  height: 24px;
  position: relative;
  z-index: 1;
}

.beacon-frame-child6 {
  width: 1016px;
  height: 282px;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}

.beacon-frame-child7,
.beacon-text78 {
  position: relative;
  z-index: 2;
}

.beacon-frame-child7 {
  height: 148px;
  width: 148px;
  border-radius: 12px;
  object-fit: cover;
}

.beacon-text78 {
  line-height: 28px;
  font-weight: 600;
}

.beacon-badge28 {
  position: absolute;
  top: 80px;
  left: 522px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  width: 93px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 2;
}

.beacon-marker-pin-02-icon2 {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}

.beacon-location3,
.beacon-type-of-work2 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 124px;
  z-index: 2;
}

.beacon-type-of-work2 {
  min-width: 109px;
}

.beacon-frame-parent12,
.beacon-frame-wrapper12 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.beacon-frame-parent12 {
  justify-content: flex-start;
  gap: 20px;
}

.beacon-frame-wrapper12 {
  justify-content: flex-end;
  padding: 0 5px;
}

.beacon-badge29,
.beacon-badge30 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 2;
}

.beacon-badge29 {
  width: 77px;
  flex-direction: row;
  align-items: flex-start;
  white-space: nowrap;
}

.beacon-badge30 {
  width: 76px;
}

.beacon-badge-container,
.beacon-badge30,
.beacon-badge31 {
  flex-direction: row;
  align-items: flex-start;
}

.beacon-badge31 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  display: flex;
  justify-content: flex-start;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
}

.beacon-badge-container {
  gap: 8px;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.beacon-badge-container,
.beacon-badge32,
.beacon-frame-parent11 {
  display: flex;
  justify-content: flex-start;
}

.beacon-frame-parent11 {
  position: absolute;
  top: 0;
  left: 180px;
  flex-direction: column;
  align-items: flex-end;
  gap: 56px;
  text-align: left;
  font-size: 16px;
  color: #475467;
}

.beacon-badge32 {
  flex: 1;
  border-radius: 9999px;
  background-color: #eceef1;
  border: 1px solid #98a2b3;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 2;
}

.beacon-supporting-text8 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 83px;
  z-index: 2;
}

.beacon-badge-parent1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.beacon-supporting-text9 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 117px;
  z-index: 2;
}

.beacon-badge33,
.beacon-frame-parent15 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-badge33 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
  color: #344054;
}

.beacon-frame-parent15 {
  position: absolute;
  top: 0;
  left: 0;
  width: 181px;
  flex-direction: column;
  gap: 15px;
  color: #98a2b3;
}

.beacon-badge-group {
  width: 615px;
  height: 104px;
  position: relative;
  max-width: 100%;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.beacon-dropdown10 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
}

.beacon-price2,
.beacon-text83 {
  position: relative;
}

.beacon-price2 {
  align-self: stretch;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  white-space: nowrap;
  z-index: 2;
}

.beacon-text83 {
  line-height: 20px;
  display: inline-block;
  min-width: 83px;
  z-index: 3;
}

.beacon-beacon-1-1-container,
.beacon-rectangle-parent7 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.beacon-beacon-1-1-container {
  flex: 1;
  align-items: flex-start;
  gap: 8px;
  z-index: 2;
}

.beacon-rectangle-parent7 {
  align-self: stretch;
  align-items: flex-end;
  padding: 0 0 76px;
  box-sizing: border-box;
  gap: 24px;
  max-width: 100%;
}

.beacon-frame-child8 {
  height: 52px;
  width: 52px;
  position: relative;
  object-fit: cover;
  z-index: 2;
}

.beacon-name2,
.beacon-role2 {
  position: relative;
  display: inline-block;
}

.beacon-name2 {
  line-height: 23px;
  font-weight: 600;
  min-width: 77px;
  z-index: 2;
}

.beacon-role2 {
  height: 19px;
  font-size: 12px;
  line-height: 24px;
  color: #6941c6;
  flex-shrink: 0;
  min-width: 87px;
  z-index: 3;
}

.beacon-buttonsbutton13,
.beacon-buttonsbutton14 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  z-index: 2;
}

.beacon-buttonsbutton13 {
  width: 90px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 12px;
  gap: 6px;
}

.beacon-buttonsbutton14 {
  flex: 1;
  overflow-x: auto;
  padding: 6px 11px;
  gap: 5.5px;
  min-width: 86px;
}

.beacon-buttonsbutton14,
.beacon-buttonsbutton15,
.beacon-frame-parent18 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-buttonsbutton15 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow-x: auto;
  padding: 6px 10px 6px 12px;
  gap: 5.4px;
  min-width: 86px;
  z-index: 2;
}

.beacon-frame-parent18 {
  width: 556.4px;
  gap: 24px;
  max-width: 100%;
}

.beacon-text84 {
  width: 66px;
  position: relative;
  line-height: 18px;
  font-weight: 600;
  display: inline-block;
  min-width: 66px;
}

.beacon-content19 {
  border-radius: 8px;
  background-color: #0c111d;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 12px;
  white-space: nowrap;
}

.beacon-tooltip-icon {
  width: 28px;
  height: 6px;
  position: relative;
}

.beacon-tooltip {
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  z-index: 3;
}

.beacon-arrow-square-up-right-icon1 {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}

.beacon-frame-wrapper16,
.beacon-tooltip-parent {
  display: flex;
  flex-direction: column;
}

.beacon-tooltip-parent {
  align-items: flex-end;
  justify-content: flex-start;
  gap: 8px;
}

.beacon-frame-wrapper16 {
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 14px;
  text-align: center;
  font-size: 12px;
  color: #fff;
}

.beacon-divider-icon5,
.beacon-frame-parent17 {
  width: 968px;
  max-width: 100%;
  margin-top: -69px;
}

.beacon-frame-parent17 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 20px;
  font-size: 14px;
}

.beacon-divider-icon5 {
  position: relative;
  max-height: 100%;
  z-index: 2;
}

.beacon-rectangle-parent6 {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 14px 13px 17px 23px;
  min-height: 282px;
  max-width: 100%;
  z-index: 1;
}

.beacon-text86 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 51px;
}

.beacon-badge34,
.beacon-badge36 {
  justify-content: flex-start;
  z-index: 2;
}

.beacon-badge34 {
  width: 69px;
  border-radius: 9999px;
  background-color: #fffdfd;
  border: 1px solid #da2d20;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 8px;
  gap: 6px;
  mix-blend-mode: normal;
}

.beacon-badge36 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  padding: 0 7px;
  white-space: nowrap;
}

.beacon-badge36,
.beacon-badge37,
.beacon-frame-wrapper18 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.beacon-badge37 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 2;
}

.beacon-frame-wrapper18 {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 2px 0 0;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.beacon-frame-parent24 {
  width: 163px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 56px;
  text-align: left;
  font-size: 16px;
}

.beacon-frame-wrapper19 {
  flex-direction: row;
  padding: 0 14px;
}

.beacon-badge38,
.beacon-frame-parent26,
.beacon-frame-wrapper19 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-badge38 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.beacon-frame-parent26 {
  flex: 1;
  flex-direction: column;
  gap: 56px;
  min-width: 107px;
  text-align: left;
  font-size: 16px;
}

.beacon-badge39,
.beacon-frame-parent21 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.beacon-badge39 {
  width: 93px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 0 7px;
  z-index: 2;
  color: #344054;
}

.beacon-frame-parent21 {
  width: 615px;
  align-items: flex-end;
  gap: 6.3px;
  max-width: 100%;
  text-align: center;
  font-size: 14px;
  color: #475467;
}

.beacon-divider-icon6 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 2;
}

.beacon-element-details-child,
.beacon-frame-child12 {
  position: relative;
  border-radius: 12px;
  display: none;
}

.beacon-frame-child12 {
  height: 208px;
  width: 1016px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  max-width: 100%;
}

.beacon-element-details-child {
  height: 160px;
  width: 160px;
  background-color: #f5f5f5;
}

.beacon-f4b711e503bc72fe396e5043e0c2-1-icon {
  height: 84px;
  width: 84px;
  position: relative;
  object-fit: cover;
  z-index: 3;
}

.beacon-element-details {
  border-radius: 12px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 38px;
  box-sizing: border-box;
  min-width: 160px;
  z-index: 2;
}

.beacon-supporting-text12 {
  position: relative;
  letter-spacing: -0.06px;
  line-height: 24px;
  z-index: 2;
}

.beacon-end-label {
  width: 50px;
  border-radius: 8px;
  background-color: #fdb022;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 4px;
  box-sizing: border-box;
  z-index: 2;
}

.beacon-text99 {
  position: relative;
  line-height: 23px;
  font-weight: 500;
  z-index: 2;
}

.beacon-supporting-text14 {
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  min-width: 104px;
  z-index: 3;
}

.beacon-date-icon,
.beacon-type-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-type-label {
  font-size: 14px;
  color: #101828;
}

.beacon-date-icon {
  flex: 1;
  gap: 16px;
  min-width: 114px;
}

.beacon-text100 {
  align-self: stretch;
  position: relative;
  line-height: 23px;
  font-weight: 500;
  z-index: 2;
}

.beacon-supporting-text15 {
  width: 104px;
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  z-index: 3;
}

.beacon-work-type-icon,
.beacon-work-type-value {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-work-type-value {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 0 1px;
  font-size: 14px;
  color: #101828;
}

.beacon-work-type-icon {
  width: 152px;
  flex-direction: column;
  gap: 58px;
  min-width: 152px;
}

.beacon-calendar-icon6 {
  flex: 1;
  gap: 8px;
  min-width: 218px;
  max-width: 100%;
}

.beacon-action-icons,
.beacon-calendar-icon6,
.beacon-work-type-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-work-type-label {
  gap: 8px;
  margin-left: -9px;
}

.beacon-action-icons {
  align-self: stretch;
  max-width: 100%;
  row-gap: 20px;
  font-size: 16px;
  color: #475467;
}

.beacon-element-actions {
  flex: 1;
  flex-direction: column;
  gap: 8px;
  min-width: 302px;
}

.beacon-element-actions,
.beacon-element-details-parent,
.beacon-frame-wrapper25 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.beacon-element-details-parent {
  align-self: stretch;
  flex-direction: row;
  gap: 24px;
}

.beacon-frame-wrapper25 {
  width: 648px;
  flex-direction: column;
  padding: 10px 0 0;
  box-sizing: border-box;
}

.beacon-text101 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 29px;
  z-index: 3;
}

.beacon-text-wrapper10 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 57px;
}

.beacon-price4 {
  align-self: stretch;
  position: relative;
  font-size: 20px;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  color: #6941c6;
  white-space: nowrap;
  z-index: 2;
}

.beacon-frame-wrapper27 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 34px 0 0;
}

.beacon-frame-parent32,
.beacon-trend-chart-inner {
  display: flex;
  justify-content: flex-start;
}

.beacon-frame-parent32 {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  gap: 19px;
}

.beacon-trend-chart-inner {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 0 3px;
}

.beacon-dropdown-parent1,
.beacon-frame-parent31,
.beacon-trend-chart {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.beacon-trend-chart {
  width: 113px;
  align-items: flex-start;
  gap: 4px;
}

.beacon-dropdown-parent1,
.beacon-frame-parent31 {
  align-items: flex-end;
}

.beacon-frame-parent31 {
  flex: 1;
}

.beacon-dropdown-parent1 {
  width: 153px;
  gap: 22px;
  text-align: center;
  font-size: 12px;
  color: #475467;
}

.beacon-rectangle-parent10 {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px 15px 12px 23px;
  max-width: 100%;
  gap: 20px;
  z-index: 1;
}

.beacon-f4b711e503bc72fe396e5043e0c2-1-icon1 {
  height: 84px;
  width: 84px;
  position: relative;
  object-fit: cover;
  z-index: 2;
}

.beacon-rectangle-parent12 {
  border-radius: 12px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 38px;
  z-index: 1;
}

.beacon-text103 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 128px;
  z-index: 1;
}

.beacon-text-wrapper11 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8px 0 0;
  font-size: 18px;
  color: #101828;
}

.beacon-icon9 {
  width: 12px;
  height: 9.3px;
  position: relative;
  z-index: 1;
}

.beacon-icon-wrapper4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 0 0;
}

.beacon-span {
  font-weight: 500;
}

.beacon-supporting-text16 {
  height: 19px;
  position: relative;
  line-height: 24px;
  display: inline-block;
  flex-shrink: 0;
  min-width: 63px;
  z-index: 1;
}

.beacon-supporting-text-wrapper1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 16px 0 0;
}

.beacon-frame-child15 {
  width: 16px;
  height: 16px;
  position: relative;
  z-index: 1;
}

.beacon-supporting-text17 {
  height: 19px;
  position: relative;
  line-height: 24px;
  display: inline-block;
  flex-shrink: 0;
  min-width: 70.9px;
  z-index: 1;
}

.beacon-frame-parent36,
.beacon-frame-parent37,
.beacon-location-elements-triple {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.beacon-frame-parent37,
.beacon-location-elements-triple {
  gap: 7px;
  font-size: 16px;
}

.beacon-frame-parent37 {
  gap: 8px;
  color: #fff;
}

.beacon-text105,
.beacon-text106,
.beacon-user-avatar {
  position: relative;
  line-height: 23px;
  font-weight: 500;
  z-index: 1;
}

.beacon-text105,
.beacon-text106 {
  display: inline-block;
}

.beacon-text105 {
  min-width: 103px;
}

.beacon-text106 {
  width: 151px;
  flex-shrink: 0;
}

.beacon-icon-button {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 34px;
}

.beacon-supporting-text19 {
  position: relative;
  line-height: 19px;
  display: inline-block;
  min-width: 92.1px;
  z-index: 2;
}

.beacon-sidebar-content1 {
  width: 161.9px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 20px 0 0;
  box-sizing: border-box;
}

.beacon-supporting-text20,
.beacon-supporting-text21 {
  position: relative;
  line-height: 19px;
  display: inline-block;
  flex-shrink: 0;
  z-index: 2;
}

.beacon-supporting-text20 {
  min-width: 70.9px;
}

.beacon-supporting-text21 {
  width: 104px;
}

.beacon-sidebar1 {
  width: 469px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  max-width: 100%;
  font-size: 12px;
  color: #475467;
}

.beacon-frame-parent33,
.beacon-frame-parent34,
.beacon-nav-item-button-icon {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.beacon-nav-item-button-icon {
  align-self: stretch;
  flex-direction: column;
  font-size: 14px;
  color: #101828;
}

.beacon-frame-parent33,
.beacon-frame-parent34 {
  flex: 1;
}

.beacon-frame-parent34 {
  flex-direction: column;
  gap: 16px;
  min-width: 335px;
}

.beacon-frame-parent33 {
  flex-direction: row;
  gap: 24px;
}

.beacon-text107 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 123px;
}

.beacon-buttonsbutton-wrapper,
.beacon-buttonsbutton19 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.beacon-buttonsbutton19 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  justify-content: flex-start;
  padding: 6px 12px;
  gap: 5px;
  z-index: 1;
}

.beacon-buttonsbutton-wrapper {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 27px 0 0;
}

.beacon-arrow-square-up-right-icon4 {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 3;
}

.beacon-dropdown-parent2,
.beacon-frame-parent38 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
}

.beacon-frame-parent38 {
  flex: 1;
  gap: 27px;
}

.beacon-dropdown-parent2 {
  align-self: stretch;
  gap: 50px;
}

.beacon-frame-wrapper29 {
  width: 215px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 9px;
  box-sizing: border-box;
  font-size: 14px;
  color: #344054;
}

.beacon-rectangle-parent11 {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 12px 13px 22px 23px;
  gap: 63px;
  z-index: 1;
}

.beacon-content-items5,
.beacon-frame-child16,
.beacon-rectangle-parent11 {
  box-sizing: border-box;
  max-width: 100%;
}

.beacon-content-items5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 30px 24px 32px;
  font-size: 12px;
  color: #475467;
}

.beacon-frame-child16 {
  height: 208px;
  width: 1016px;
  position: relative;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  display: none;
  z-index: 1;
}

.beacon-supporting-text-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  font-size: 16px;
  color: #475467;
}

.beacon-search-icon-input {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 41px;
}

.beacon-supporting-text24 {
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  min-width: 104px;
  z-index: 1;
}

.beacon-frame-parent43 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.beacon-supporting-text25 {
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  min-width: 113px;
  z-index: 1;
}

.beacon-frame-parent42 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px;
  min-width: 272px;
  max-width: 100%;
}

.beacon-text111 {
  align-self: stretch;
  position: relative;
  line-height: 23px;
  font-weight: 500;
  z-index: 1;
}

.beacon-supporting-text26 {
  width: 113px;
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  z-index: 1;
}

.beacon-text-parent8 {
  width: 233px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  color: #101828;
}

.beacon-frame-parent39,
.beacon-frame-parent41 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.beacon-frame-parent41 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-end;
  gap: 34px;
  font-size: 16px;
  color: #fff;
}

.beacon-frame-parent39 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  min-width: 445px;
}

.beacon-dropdown14,
.beacon-text112 {
  position: absolute;
  z-index: 1;
}

.beacon-dropdown14 {
  top: 0;
  left: 306px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-text112 {
  top: 19px;
  left: 137px;
  line-height: 20px;
  display: inline-block;
  min-width: 41px;
}

.beacon-price5 {
  flex: 1;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  white-space: nowrap;
  z-index: 2;
}

.beacon-price6,
.beacon-text113 {
  position: relative;
  z-index: 1;
}

.beacon-text113 {
  line-height: 20px;
  display: inline-block;
  min-width: 15px;
}

.beacon-price6 {
  flex: 1;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  white-space: nowrap;
}

.beacon-content-table-cards {
  position: absolute;
  top: 39px;
  left: 0;
  width: 316px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7.5px;
  font-size: 20px;
  color: #6941c6;
}

.beacon-text114 {
  position: absolute;
  top: 70px;
  left: 116px;
  line-height: 20px;
  display: inline-block;
  min-width: 83px;
  z-index: 3;
}

.beacon-beacon-1-1-parent3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  z-index: 1;
}

.beacon-arrow-square-up-right-icon5 {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 4;
}

.beacon-arrow-square-up-right-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 43px 0 0;
}

.beacon-frame-parent46,
.beacon-frame-wrapper32 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.beacon-frame-parent46 {
  flex: 1;
  justify-content: space-between;
  gap: 20px;
}

.beacon-frame-wrapper32 {
  width: 217px;
  justify-content: flex-end;
  padding: 0 10px;
  box-sizing: border-box;
}

.beacon-frame-parent45,
.beacon-frame-wrapper31 {
  display: flex;
  flex-direction: column;
}

.beacon-frame-parent45 {
  align-self: stretch;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 4px;
}

.beacon-frame-wrapper31 {
  width: 326px;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 9px;
  box-sizing: border-box;
  min-width: 326px;
  max-width: 100%;
  margin-left: -33px;
  text-align: center;
  font-size: 12px;
  color: #475467;
}

.beacon-card-container-child,
.beacon-rectangle-parent13 {
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  max-width: 100%;
}

.beacon-rectangle-parent13 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 12px 13px 22px 23px;
  row-gap: 20px;
  z-index: 1;
}

.beacon-card-container-child {
  width: 1016px;
  height: 301px;
  position: relative;
  display: none;
}

.beacon-supporting-text27 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 83px;
  z-index: 2;
}

.beacon-title-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: 100%;
}

.beacon-supporting-text30 {
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  min-width: 113px;
  z-index: 3;
}

.beacon-detail-title-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 151px;
  font-size: 14px;
  color: #101828;
}

.beacon-supporting-text31 {
  width: 113px;
  position: relative;
  font-size: 12px;
  line-height: 19px;
  color: #475467;
  display: inline-block;
  z-index: 3;
}

.beacon-card-row {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0 0;
  box-sizing: border-box;
  min-width: 445px;
  max-width: 100%;
}

.beacon-dropdown15,
.beacon-item-title {
  position: absolute;
  z-index: 2;
}

.beacon-dropdown15 {
  top: 0;
  left: 306px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-item-title {
  top: 19px;
  left: 139px;
  line-height: 20px;
  display: inline-block;
  min-width: 36px;
}

.beacon-price7 {
  flex: 1;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  white-space: nowrap;
  z-index: 3;
}

.beacon-text116 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 15px;
  z-index: 2;
}

.beacon-currency {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 5px 0 0;
  font-size: 12px;
  color: #475467;
}

.beacon-item-title1 {
  position: absolute;
  top: 70px;
  left: 116px;
  line-height: 20px;
  display: inline-block;
  min-width: 83px;
  z-index: 4;
}

.beacon-list-item {
  align-self: stretch;
  height: 90px;
  position: relative;
}

.beacon-item-icons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  z-index: 2;
}

.beacon-magnifier-1-icon7 {
  height: 20px;
  width: 20px;
  position: relative;
  object-fit: cover;
  min-height: 20px;
  z-index: 2;
}

.beacon-frame-wrapper33 {
  width: 310px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.beacon-card-container,
.beacon-card-list,
.beacon-list-item-parent {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.beacon-list-item-parent {
  width: 326px;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  min-width: 326px;
  margin-left: -33px;
  text-align: center;
  font-size: 12px;
  color: #475467;
}

.beacon-card-container,
.beacon-card-list {
  align-items: flex-start;
}

.beacon-card-list {
  align-self: stretch;
  flex-direction: row;
  row-gap: 20px;
}

.beacon-card-container {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  flex-direction: column;
  padding: 12px 13px 22px 23px;
  gap: 24px;
  z-index: 1;
}

.beacon-text-wrapper18 {
  padding: 0 60px 0 57px;
}

.beacon-frame-wrapper37,
.beacon-price-wrapper,
.beacon-text-wrapper18,
.beacon-text-wrapper19 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}

.beacon-price-wrapper {
  align-self: stretch;
  padding: 0 3px 0 0;
  font-size: 20px;
  color: #6941c6;
}

.beacon-frame-wrapper37,
.beacon-text-wrapper19 {
  padding: 0 33px 4px 30px;
}

.beacon-frame-wrapper37 {
  padding: 0 37px 0 0;
}

.beacon-frame-parent60 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 19px;
}

.beacon-frame-wrapper36 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 7px 0 0;
}

.beacon-text-parent12 {
  justify-content: flex-start;
  gap: 16px;
  font-size: 18px;
  color: #101828;
}

.beacon-buttonsbutton-frame,
.beacon-frame-parent64,
.beacon-text-parent12 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.beacon-frame-parent64 {
  justify-content: flex-start;
  gap: 7.3px;
}

.beacon-buttonsbutton-frame {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 30px 0 0;
}

.beacon-divider-container-child {
  width: 1016px;
  height: 493px;
  position: relative;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  max-width: 100%;
}

.beacon-badge-item-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  min-width: 114px;
  color: #da2d20;
}

.beacon-divider-icon8 {
  width: 968px;
  position: relative;
  max-height: 100%;
  max-width: 100%;
  z-index: 2;
}

.beacon-heading9 {
  width: 254px;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}

.beacon-change7,
.beacon-text139 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.beacon-change7 {
  display: inline-block;
  min-width: 38px;
}

.beacon-text139 {
  flex: 1;
  color: #475467;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.beacon-change-and-text3,
.beacon-number-and-badge3 {
  display: flex;
  justify-content: flex-start;
}

.beacon-change-and-text3 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-size: 14px;
  color: #079455;
}

.beacon-number-and-badge3 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  min-width: 105px;
}

.beacon-chart-mini-icon3 {
  height: 48px;
  width: 96px;
  position: relative;
  object-fit: contain;
}

.beacon-dropdown19,
.beacon-metric-item3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-dropdown19 {
  margin: 0 !important;
  position: absolute;
  top: 16px;
  right: 16px;
}

.beacon-metric-item3 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 16px 15px 20px;
  position: relative;
  gap: 20px;
  min-width: 229px;
  z-index: 2;
}

.beacon-buttonsbutton25,
.beacon-buttonsbutton26,
.beacon-metric-item-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-metric-item-parent {
  width: 968px;
  gap: 25px;
  max-width: 100%;
  font-size: 16px;
}

.beacon-buttonsbutton25,
.beacon-buttonsbutton26 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;

  padding: 6px 11px;
  gap: 5.5px;
  min-width: 92px;
  z-index: 2;
}

.beacon-buttonsbutton26 {
  padding: 6px 10px 6px 12px;
  gap: 5.4px;
}

.beacon-text145 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 70px;
}

.beacon-ellipse-div {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: #17b26a;
  width: 17px;
  height: 17px;
  z-index: 3;
}

.beacon-div {
  position: absolute;
  top: 4px;
  left: 6px;
  line-height: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 7px;
  height: 20px;
  min-width: 7px;
  z-index: 4;
}

.beacon-ellipse-parent {
  height: 24px;
  width: 17px;
  position: absolute;
  margin: 0 !important;
  top: -9px;
  right: -2.7px;
  font-size: 10px;
  color: #fff;
}

.beacon-buttonsbutton27 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-direction: row;
  padding: 8px 12px;
  position: relative;
  gap: 6px;
  min-width: 79px;
  z-index: 2;
}

.beacon-arrow-square-up-right-wrapper1,
.beacon-buttonsbutton-parent1,
.beacon-buttonsbutton27 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-buttonsbutton-parent1 {
  width: 523.3px;
  flex-direction: row;
  gap: 16px;
  max-width: calc(100% - 44px);
}

.beacon-arrow-square-up-right-wrapper1 {
  flex-direction: column;
  padding: 8px 0 0;
}

.beacon-divider-container1,
.beacon-frame-parent71 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.beacon-frame-parent71 {
  width: 968px;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  font-size: 14px;
  color: #344054;
}

.beacon-divider-container1 {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  padding: 12px 13px 22px 23px;
  gap: 24.3px;
  z-index: 1;
}

.beacon-text146 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 72px;
}

.beacon-buttonsbutton-wrapper1,
.beacon-buttonsbutton28 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.beacon-buttonsbutton28 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  justify-content: flex-start;
  padding: 6px 12px;
  gap: 6px;
  z-index: 1;
  cursor: pointer;
}

.beacon-buttonsbutton-wrapper1 {
  align-self: stretch;
  justify-content: center;
  padding: 0 20px;
}

.beacon-text147 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
}

.beacon-card-header-badge {
  position: relative;
  line-height: 18px;
  font-weight: 500;
}

.beacon-badge46,
.beacon-text-and-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.beacon-badge46 {
  border-radius: 9999px;
  background-color: #f9f5ff;
  border: 1px solid #e9d7fe;
  padding: 0 7px;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  color: #6941c6;
}

.beacon-text-and-badge {
  align-self: stretch;
  padding: 0 242px 0 0;
  gap: 8px;
}

.beacon-supporting-text44 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.beacon-text-and-supporting-text2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  min-width: 424px;
  max-width: 100%;
}

.beacon-text151 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 92px;
}

.beacon-actions3,
.beacon-button11 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.beacon-button11 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  overflow: hidden;
  justify-content: center;
  padding: 8px 13px;
  gap: 4px;
  color: #fff;
}

.beacon-actions3 {
  justify-content: flex-start;
  gap: 12px;
  font-size: 14px;
  color: #475467;
}

.beacon-content20,
.beacon-divider-icon10 {
  align-self: stretch;
  max-width: 100%;
}

.beacon-content20 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 20px 24px 0;
  box-sizing: border-box;
  gap: 16px;
}

.beacon-divider-icon10 {
  position: relative;
  overflow: hidden;
  max-height: 100%;
}

.beacon-card-header,
.beacon-frame-parent72 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.beacon-card-header {
  align-self: stretch;
  background-color: #fff;
  gap: 20px;
  z-index: 1;
  font-size: 26px;
  color: #101828;
}

.beacon-frame-parent72 {
  flex: 1;
  gap: 24px;
}

.beacon-button-group-base9,
.beacon-frame-wrapper44 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.beacon-frame-wrapper44 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 60px 32px;
  max-width: 100%;
  color: #344054;
  z-index: 0;
}

.beacon-button-group-base9 {
  width: 83px;
  background-color: #f9fafb;
  border-right: 1px solid #d0d5dd;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
}

.beacon-text153 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 105px;
}

.beacon-button-group-base10 {
  display: flex;
  padding: 10px 15px;
  white-space: nowrap;
}

.beacon-button-group-base10,
.beacon-button-group-base11,
.beacon-button-group-base17 {
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 40px;
}

.beacon-button-group-base11 {
  align-self: stretch;
  display: none;
  padding: 8px 16px;
  z-index: 6;
}

.beacon-button-group-base17 {
  width: 93px;
  flex-shrink: 0;
  display: flex;
  padding: 10px 15px;
}

.beacon-button-group1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-search-placeholder1 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 82px;

}

.beacon-supporting-text45 {
  align-self: stretch;
  width: 54px;
  position: relative;
  line-height: 24px;
  color: #475467;
  display: none;
}

.beacon-content22,
.beacon-input-with-label3,
.beacon-input3 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.beacon-content22 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 262px 0 0;
  box-sizing: border-box;
  gap: 8px;
}

.beacon-input-with-label3,
.beacon-input3 {
  align-self: stretch;
}

.beacon-input3 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
    0 0 0 4px rgba(158, 119, 237, 0.24);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d6bbfb;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  font-size: 16px;
  color: #101828;
}

.beacon-input-with-label3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.beacon-hint-text3 {
  width: 320px;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.beacon-input-dropdown3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: 400px;
}

.beacon-actions4,
.beacon-button12 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.beacon-button12 {
  width: 99px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
}

.beacon-actions4 {
  width: 511px;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
}

.beacon-content-items9,
.beacon-content21,
.beacon-filters-bar1 {
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.beacon-content21 {
  align-self: stretch;
  border-radius: 12px;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  gap: 20px;
}

.beacon-content-items9,
.beacon-filters-bar1 {
  justify-content: flex-start;
}

.beacon-filters-bar1 {
  flex: 1;
  background-color: #fff;
  border-bottom: 1px solid #eaecf0;
  flex-direction: column;
  z-index: 2;
}

.beacon-content-items9 {
  align-self: stretch;
  flex-direction: row;
  padding: 0 30px 23px 32px;
  color: #344054;
}

.beacon-badge-number1,
.beacon-text162 {
  position: relative;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 123px;
}

.beacon-badge-number1 {
  line-height: 18px;
  font-weight: 500;
  min-width: 56px;
}

.beacon-badge47,
.beacon-text-and-badge1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.beacon-badge47 {
  width: 72px;
  border-radius: 9999px;
  background-color: #f9f5ff;
  border: 1px solid #e9d7fe;
  box-sizing: border-box;
  padding: 0 7px;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  color: #6941c6;
}

.beacon-text-and-badge1 {
  align-self: stretch;
  padding: 0 729px 0 0;
  gap: 8px;
}

.beacon-supporting-text46 {
  width: 697px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.beacon-text-and-supporting-text3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 12px 0;
  box-sizing: border-box;
  gap: 4px;
  max-width: 100%;
}

.beacon-actions5,
.beacon-card-header1,
.beacon-content23 {
  justify-content: flex-start;
  max-width: 100%;
}

.beacon-actions5 {
  display: none;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  color: #475467;
}

.beacon-card-header1,
.beacon-content23 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}

.beacon-content23 {
  flex-direction: row;
  padding: 20px 24px 0;
  box-sizing: border-box;
  gap: 16px;
}

.beacon-card-header1 {
  background-color: #fff;
  flex-direction: column;
  gap: 20px;
  flex-shrink: 0;
  font-size: 18px;
}

.beacon-checkbox-base {
  height: 20px;
  width: 20px;
  position: relative;
  border-radius: 6px;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
}

.beacon-checkbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-sub-nav-item {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 34px;
}

.beacon-arrow-down-icon1 {
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.beacon-sub-nav-items {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.beacon-sub-nav-items-wrapper {
  flex-direction: column;
  padding: 1px 0 0;
}

.beacon-checkbox-wrapper,
.beacon-sub-nav-items-wrapper,
.beacon-table-header-cell {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-table-header-cell {
  width: 441px;
  margin: 0 !important;
  position: absolute;
  top: 0;
  left: -380px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  flex-direction: row;
  padding: 12px 24px 10px;
  gap: 12px;
  font-size: 12px;
  color: #475467;
}

.beacon-checkbox-wrapper {
  flex-direction: column;
  padding: 10px 0 0;
}

.beacon-avatar-online-indicator {
  height: 13px;
  width: 13px;
  position: relative;
  border-radius: 9999px;
  background-color: #17b26a;
  border: 1.5px solid #fff;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.beacon-avatar2,
.beacon-contrast-border2 {
  height: 40px;
  width: 40px;
  border-radius: 9999px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.beacon-contrast-border2 {
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  justify-content: flex-end;
  padding: 30px 0 0 26px;
}

.beacon-avatar2 {
  justify-content: flex-start;
  background-image: url(../../icons/Gerator/dashboard/avatar2@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.beacon-account-button,
.beacon-supporting-text47 {
  position: relative;
  line-height: 20px;
  display: inline-block;
}

.beacon-account-button {
  font-weight: 500;
  min-width: 75px;
}

.beacon-supporting-text47 {
  color: #475467;
  min-width: 97px;
}

.beacon-table-cell {
  width: 405px;
  margin: 0 !important;
  position: absolute;
  top: 44px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.beacon-avatar3 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  flex-shrink: 0;
  background-image: url(../../icons/Gerator/dashboard/avatar3@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.beacon-dropdown-first-date {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 96px;
}

.beacon-table-cell1 {
  width: 467px;
  margin: 0 !important;
  position: absolute;
  top: 116px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.beacon-text167 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 84px;
}

.beacon-table-cell2 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  top: 188px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.beacon-avatar5 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  flex-shrink: 0;
  background-image: url(../../icons/Gerator/dashboard/avatar4@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.beacon-card-content-third {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 103px;
  white-space: nowrap;
}

.beacon-contrast-border6,
.beacon-table-cell3 {
  position: absolute;
  margin: 0 !important;
  box-sizing: border-box;
}

.beacon-table-cell3 {
  width: 411px;
  top: 260px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  overflow-x: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.beacon-contrast-border6 {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.beacon-card-header-actions {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  z-index: 1;
}

.beacon-avatar6 {
  height: 40px;
  width: 40px;
  border-radius: 9999px;
  background-color: #f2f4f7;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 0;
  box-sizing: border-box;
  position: relative;
}

.beacon-button-upload-icon {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 82px;
}

.beacon-button-upload,
.beacon-table-cell4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: 14px;
  color: #101828;
}

.beacon-table-cell4 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  top: 332px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  flex-shrink: 0;
  flex-direction: row;
  padding: 16px 24px 14px;
  gap: 12px;
  text-align: center;
  font-size: 16px;
  color: #667085;
}

.beacon-dropdown-table-input {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 78px;
}

.beacon-table-cell5 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  bottom: 288px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.beacon-avatar8 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  flex-shrink: 0;
  background-image: url(../../icons/Gerator/dashboard/avatar5@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.beacon-table-cell6,
.beacon-table-cell7 {
  width: 411px;
  margin: 0 !important;
  position: absolute;
  bottom: 216px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.beacon-table-cell7 {
  width: 428px;
  bottom: 144px;
  text-align: center;
  font-size: 16px;
  color: #667085;
}

.beacon-avatar10 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  flex-shrink: 0;
  background-image: url(../../icons/Gerator/dashboard/avatar6@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.beacon-title3 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 67px;
}

.beacon-table-cell8 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  bottom: 72px;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.beacon-avatar11 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  flex-shrink: 0;
  background-image: url(../../icons/Gerator/dashboard/avatar7@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.beacon-title4 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 95px;
}

.beacon-table-cell9 {
  width: 428px;
  margin: 0 !important;
  position: absolute;
  bottom: 0;
  left: -380px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
  z-index: 1;
}

.beacon-text169 {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 50px;
}

.beacon-table-header,
.beacon-table-header-cell1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.beacon-table-header {
  gap: 4px;
}

.beacon-table-header-cell1 {
  width: 255px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 24px 11px;
  text-align: left;
  color: #475467;
}

.beacon-text170 {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 93px;
}

.beacon-table-cell10 {
  width: 255px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
}

.beacon-text171 {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 85px;
}

.beacon-table-cell11 {
  width: 247px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
}

.beacon-text172 {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 91px;
}

.beacon-table-cell15,
.beacon-table-cell16,
.beacon-table-cell18 {
  width: 279px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
}

.beacon-table-cell16,
.beacon-table-cell18 {
  width: 271px;
}

.beacon-table-cell18 {
  width: 239px;
}

.beacon-column,
.beacon-table-header-cell-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-column {
  width: 120px;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  z-index: 2;
  text-align: center;
  font-size: 12px;
  color: #344054;
}

.beacon-table-header-cell-parent {
  flex-direction: row;
  position: relative;
}

.beacon-frame-wrapper45 {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 100%;
  color: #101828;
}

.beacon-text180 {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 40px;
}

.beacon-help-icon {
  height: 16px;
  width: 16px;
  position: relative;
}

.beacon-table-header-cell2 {
  width: 297px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 13px 24px 11px;
  font-size: 12px;
}

.beacon-text181 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 101px;
}

.beacon-table-cell20 {
  width: 297px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
}

.beacon-user-cell {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 87px;
}

.beacon-table-cell21 {
  width: 407px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 23px 24px;
  max-width: 232%;
  flex-shrink: 0;
}

.beacon-user-cell1 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 93px;
}

.beacon-table-cell22 {
  width: 407px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
  max-width: 232%;
  flex-shrink: 0;
}

.beacon-user-cell2,
.beacon-user-cell3,
.beacon-user-cell4 {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 64px;
}

.beacon-user-cell3,
.beacon-user-cell4 {
  min-width: 84px;
}

.beacon-user-cell4 {
  min-width: 82px;
}

.beacon-table-cell25 {
  width: 328px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px;
  max-width: 187%;
  flex-shrink: 0;
}

.beacon-user-cell5 {
  display: inline-block;
  min-width: 92px;
}

.beacon-user-cell5,
.beacon-user-cell6,
.beacon-user-cell7 {
  position: relative;
  line-height: 20px;
}

.beacon-user-cell6 {
  display: inline-block;
  min-width: 68px;
}

.beacon-user-cell7 {
  white-space: nowrap;
}

.beacon-column1,
.beacon-table-cell29 {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}

.beacon-table-cell29 {
  width: 287px;
  border-bottom: 1px solid #eaecf0;
  flex-direction: row;
  align-items: center;
  padding: 26px 24px 24px;
}

.beacon-column1 {
  width: 176px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  z-index: 1;
}

.beacon-user-header {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 37px;
}

.beacon-table-cell30,
.beacon-table-header-cell3,
.beacon-table-header2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.beacon-table-header2 {
  flex: 1;
  justify-content: flex-start;
  gap: 4px;
}

.beacon-table-cell30,
.beacon-table-header-cell3 {
  border-bottom: 1px solid #eaecf0;
  justify-content: center;
}

.beacon-table-header-cell3 {
  align-self: stretch;
  background-color: #f9fafb;
  padding: 13px 41px 11px;
}

.beacon-table-cell30 {
  padding: 26px 50px 24px;
}

.beacon-column2,
.beacon-dropdown28 {
  width: 21px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-column2 {
  width: 120px;
  align-items: flex-end;
  padding: 0;
  box-sizing: border-box;
  z-index: 1;
  font-size: 12px;
}

.beacon-frame-parent73 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  gap: 20px;
  color: #475467;
}

.beacon-page-label {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 59px;
}

.beacon-buttonsbutton29 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 11px;
  gap: 4px;
}

.beacon-button-wrap,
.beacon-pagination-controls {
  display: flex;
  justify-content: flex-start;
}

.beacon-button-wrap {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
}

.beacon-pagination-controls {
  width: 326px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
  box-sizing: border-box;
}

.beacon-number11 {
  width: 7px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 7px;
}

.beacon-content24,
.beacon-pagination-number-base {
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-content24 {
  padding: 10px 16px;
}

.beacon-pagination-number-base {
  background-color: #f9fafb;
  overflow: hidden;
  color: #18222f;
}

.beacon-number12 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 9px;
}

.beacon-content25,
.beacon-pagination-number-base1 {
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-content25 {
  padding: 10px 15px;
}

.beacon-pagination-number-base1 {
  overflow: hidden;
}

.beacon-number13 {
  width: 10px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 10px;
}

.beacon-content26,
.beacon-pagination-number-base2 {
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-content26 {
  padding: 10px 15px;
}

.beacon-pagination-number-base2 {
  overflow: hidden;
}

.beacon-number14 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 13px;
}

.beacon-content27 {
  flex: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 13px;
}

.beacon-number17 {
  width: 16px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 16px;
}

.beacon-content30,
.beacon-pagination-numbers {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-content30 {
  border-radius: 8px;
  padding: 10px 12px;
}

.beacon-pagination-numbers {
  width: 292px;
  flex-shrink: 0;
  gap: 2px;
  text-align: center;
  color: #475467;
}

.beacon-page-label1 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 32px;
}

.beacon-button-wrap1,
.beacon-buttonsbutton30 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.beacon-buttonsbutton30 {
  width: 84px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  padding: 6px 11px;
  gap: 4px;
}

.beacon-button-wrap1 {
  align-self: stretch;
  justify-content: flex-end;
}

.beacon-pagination,
.beacon-table {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-pagination {
  align-self: stretch;
  border-top: 1px solid #eaecf0;
  overflow-x: auto;
  flex-direction: row;
  padding: 12px 24px 16px;
  gap: 12px;
  z-index: 3;
  color: #344054;
}

.beacon-table {
  flex: 1;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  max-width: 100%;
  z-index: 1;
}

.beacon-content-items10 {
  align-self: stretch;
  padding: 0 30px 24px 32px;
  max-width: 100%;
}

.beacon-content-items10,
.beacon-content-items11,
.beacon-table-cell40,
.beacon-table-cell41,
.beacon-table-cell42,
.beacon-table-header-cell4 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-content-items11 {
  align-self: stretch;
  padding: 0 30px 0 32px;
  max-width: 100%;
  font-size: 26px;
}

.beacon-table-cell40,
.beacon-table-cell41,
.beacon-table-cell42,
.beacon-table-header-cell4 {
  position: absolute;
  left: 0;
  border-bottom: 1px solid #eaecf0;
  gap: 12px;
}

.beacon-table-header-cell4 {
  top: 0;
  background-color: #f9fafb;
  width: 100%;
  padding: 12px 24px 10px;
  max-width: 100%;
  font-size: 12px;
  color: #475467;
}

.beacon-table-cell40,
.beacon-table-cell41,
.beacon-table-cell42 {
  top: 44px;
  width: 245px;
  overflow-x: auto;
  padding: 16px 24px 14px;
}

.beacon-table-cell41,
.beacon-table-cell42 {
  top: 116px;
  width: 252px;
}

.beacon-table-cell42 {
  top: 188px;
}

.beacon-table-cell43 {
  top: 260px;
  width: 252px;
}

.beacon-table-cell43,
.beacon-table-cell44,
.beacon-table-cell45 {
  position: absolute;
  left: 0;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.beacon-table-cell44 {
  top: 332px;
  width: 311px;
  text-align: center;
  font-size: 16px;
  color: #667085;
}

.beacon-table-cell45 {
  top: 404px;
  width: 260px;
}

.beacon-table-cell46 {
  top: 476px;
  box-sizing: border-box;
  width: 252px;
}

.beacon-table-cell46,
.beacon-table-cell47,
.beacon-table-cell48,
.beacon-table-cell49 {
  position: absolute;
  left: 0;
  border-bottom: 1px solid #eaecf0;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 24px 14px;
  gap: 12px;
}

.beacon-table-cell47 {
  top: 548px;
  box-sizing: border-box;
  width: 242px;
  text-align: center;
  font-size: 16px;
  color: #667085;
}

.beacon-table-cell48,
.beacon-table-cell49 {
  top: 620px;
}

.beacon-table-cell49 {
  top: 692px;
  box-sizing: border-box;
  width: 242px;
  z-index: 1;
}

.beacon-text211 {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 55px;
}

.beacon-table-header-cell5 {
  width: 441px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 13px 24px 11px;
  max-width: 286%;
  flex-shrink: 0;
  font-size: 12px;
  color: #475467;
}

.beacon-background {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
  background-color: #f5f5f5;
  width: 100%;
  height: 100%;
}

.beacon-f4b711e503bc72fe396e5043e0c2-1-icon3 {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 24px;
  height: 24px;
  object-fit: cover;
  z-index: 1;
}

.beacon-background-parent {
  height: 40px;
  width: 40px;
  position: relative;
}

.beacon-supporting-text70 {
  position: relative;
  line-height: 20px;
  color: #475467;
  display: inline-block;
  min-width: 101px;
  white-space: nowrap;
}

.beacon-table-cell50,
.beacon-table-cell51 {
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 228px 14px 24px;
  gap: 12px;
  max-width: 263%;
  flex-shrink: 0;
}

.beacon-table-cell51 {
  padding: 16px 290px 14px 24px;
  max-width: 303%;
}

.beacon-progress-label {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 98px;
}

.beacon-table-cell52 {
  border-bottom: 1px solid #eaecf0;
  flex-direction: row;
  align-items: center;
  padding: 16px 251px 14px 24px;
  gap: 12px;
  max-width: 278%;
  flex-shrink: 0;
}

.beacon-column3,
.beacon-table-cell52,
.beacon-table-cell53 {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}

.beacon-table-cell53 {
  border-bottom: 1px solid #eaecf0;
  flex-direction: row;
  align-items: center;
  padding: 16px 234px 14px 24px;
  gap: 12px;
  max-width: 267%;
  flex-shrink: 0;
}

.beacon-column3 {
  position: absolute;
  top: 0;
  left: 264.5px;
  width: 154.5px;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  z-index: 1;
}

.beacon-table-header-cell-group {
  height: 764px;
  width: 441px;
  position: relative;
  max-width: 100%;
}

.beacon-marker-pin-02-group,
.beacon-table-cell65 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.beacon-marker-pin-02-group {
  gap: 7px;
}

.beacon-table-cell65 {
  align-self: stretch;
  border-bottom: 1px solid #eaecf0;
  padding: 26px 24px 24px;
}

.beacon-table-header-cell-container {
  width: 279px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-column4 {
  align-self: stretch;
  display: flex;
  padding: 0;
  z-index: 1;
}

.beacon-column-wrapper,
.beacon-column4,
.beacon-column5 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.beacon-column-wrapper {
  width: 66px;
  display: flex;
  padding: 0 22px 0 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 12px;
  color: #344054;
}

.beacon-column5 {
  width: 176px;
  padding: 0;
  min-width: 176px;
}

.beacon-column5,
.beacon-column6,
.beacon-table-header-cell8 {
  display: flex;
  box-sizing: border-box;
}

.beacon-table-header-cell8 {
  width: 120px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 13px 41px 11px;
}

.beacon-column6 {
  width: 79px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0;
  min-width: 79px;
  font-size: 12px;
}

.beacon-column-parent,
.beacon-frame-parent74,
.beacon-pagination1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.beacon-column-parent {
  width: 333px;
  justify-content: flex-start;
  gap: 78px;
  max-width: 100%;
  z-index: 1;
  color: #475467;
}

.beacon-frame-parent74,
.beacon-pagination1 {
  align-self: stretch;
}

.beacon-frame-parent74 {
  justify-content: space-between;
  max-width: 100%;
  gap: 20px;
}

.beacon-pagination1 {
  border-top: 1px solid #eaecf0;
  overflow-x: auto;
  justify-content: flex-start;
  padding: 12px 24px 16px;
  gap: 12px;
  z-index: 2;
  color: #344054;
}

.beacon-text227 {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 73px;
}

.beacon-badge50,
.beacon-text-and-badge4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.beacon-badge50 {
  width: 89px;
  border-radius: 9999px;
  background-color: #f9f5ff;
  border: 1px solid #e9d7fe;
  box-sizing: border-box;
  padding: 0 7px;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  color: #6941c6;
}

.beacon-text-and-badge4 {
  align-self: stretch;
  padding: 0 332px 0 0;
  gap: 7px;
}

.beacon-content-items14,
.beacon-text-and-supporting-text16 {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.beacon-text-and-supporting-text16 {
  flex: 1;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  min-width: 436px;
}

.beacon-content-items14 {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 29px 0 33px;
  box-sizing: border-box;
  font-size: 26px;
}

.beacon-text232 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 53px;
}

.beacon-button-group-base27,
.beacon-button-group-base28 {
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
}

.beacon-button-group-base27 {
  width: 85px;
  background-color: #f9fafb;
  flex-shrink: 0;
  white-space: nowrap;
}

.beacon-button-group-base28 {
  background-color: #fff;
}

.beacon-text240 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 89px;
}

.beacon-filter-bar-container {
  align-self: stretch;
  flex-direction: row;
  padding: 0 29px 0 33px;
  box-sizing: border-box;
  color: #344054;
}

.beacon-filter-bar-container,
.beacon-frame-parent,
.beacon-all-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.beacon-frame-parent {
  flex: 1;
  flex-direction: column;
  min-width: 701px;
  font-size: 14px;
}

.beacon-all-content {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  /* padding: 0 0 67px; */
  box-sizing: border-box;
  row-gap: 20px;
}

.beacon-featured-icon {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 10px;
}

.beacon-supporting-text82 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}

.beacon-text-and-supporting-text17 {
  width: 352px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
  max-width: 352px;
}

.beacon-buttonsbutton33,
.beacon-content45,
.beacon-text-and-supporting-text17 {
  display: flex;
  align-items: center;
}

.beacon-content45 {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 20px;
  gap: 16px;
}

.beacon-buttonsbutton33 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  padding: 8px 15px;
  gap: 6px;
}

.beacon-text245 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 91px;
}

.beacon-actions11,
.beacon-buttonsbutton34 {
  display: flex;
  flex-direction: row;
}

.beacon-buttonsbutton34 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  gap: 6px;
  color: #fff;
}

.beacon-actions11 {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  text-align: left;
  color: #344054;
}

.beacon-empty-state,
.beacon-empty-state1 {
  flex: 1;
  display: flex;
  align-items: center;
  max-width: 100%;
}

.beacon-empty-state1 {
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
}

.beacon-empty-state {
  flex-direction: row;
  justify-content: center;
  z-index: 1;
}

.beacon-empty-state-wrapper,
.beacon-all {
  display: flex;
  box-sizing: border-box;
  max-width: 100%;
}

.beacon-empty-state-wrapper {
  width: 1074px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 29px;
  text-align: center;
}

.beacon-all {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  /* padding: 0 0 245px; */
}

.beacon-bifocal-optometry-measurement-icon {
  width: 391px;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  max-width: 100%;
}

.beacon-title8 {
  align-self: stretch;
  position: relative;
  line-height: 30px;
  font-weight: 600;
}

.beacon-content46,
.beacon-text-and-supporting-text18 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.beacon-text-and-supporting-text18 {
  width: 352px;
  gap: 8px;
  max-width: 352px;
}

.beacon-content46 {
  align-self: stretch;
  padding: 0 20px;
  box-sizing: border-box;
  gap: 20px;
  max-width: 100%;
}

.beacon-add,
.beacon-learn-more {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 98px;
}

.beacon-learn-more {
  min-width: 88px;
}

.beacon-actions12,
.beacon-empty-state3 {
  display: flex;
  justify-content: flex-start;
}

.beacon-actions12 {
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  text-align: left;
  font-size: 16px;
  color: #344054;
}

.beacon-empty-state3 {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  max-width: 100%;
}

.beacon-empty-state-container,
.beacon-empty-state2 {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
}

.beacon-empty-state2 {
  flex: 1;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  padding: 41.5px 0;
  z-index: 1;
}

.beacon-empty-state-container {
  width: 1076px;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 30px 124px;
  text-align: center;
  font-size: 20px;
}









.beacon-beacon-frame-layout {

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background-color: #fff;
  /* overflow: hidden; */
  height: max-content;

  align-self: stretch;
  height: max-content;


  max-width: 1524px;
  margin: 0 auto;
  position: relative;
  align-items: flex-end;
  padding: 7px 0 0;
  box-sizing: border-box;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  font-size: 16px;
  color: #101828;
  font-family: Inter;
}

/* NEW CSS  */

.beacon-buttonsbutton5a {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 15px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  gap: 6px;
  position: relative;
}

.beacon-buttonsbutton5a:hover {
  background-color: #6941c6;
}

.beacon-search-lg-icon1 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.beacon-text-padding15 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.beacon-text74 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 91px;
}

.beacon-dropdown-wrapper {
  position: absolute;
  z-index: 3;
  right: 158px;
  top: 227px;
}

@media screen and (max-width: 1275px) {

  .beacon-all,
  .beacon-all-content {
    padding-bottom: 20px;
    box-sizing: border-box;
  }

  .beacon-all {
    padding-bottom: 67px;
  }

  .beacon-container3 {
    max-width: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .beacon-content13 {
    gap: 35px;
    flex-wrap: wrap;
  }

  .beacon-main {
    height: auto;
    padding-top: 21px;
    padding-bottom: 21px;
    box-sizing: border-box;
  }

  .beacon-content-elements-parent,
  .beacon-content17,
  .beacon-frame-group,
  .beacon-rectangle-parent10,
  .beacon-rectangle-parent5,
  .beacon-rectangle-parent7 {
    flex-wrap: wrap;
  }

  .beacon-rectangle-parent11 {
    gap: 31px;
    flex-wrap: wrap;
  }

  .beacon-frame-wrapper31 {
    flex: 1;
  }

  .beacon-rectangle-parent13 {
    flex-wrap: wrap;
  }

  .beacon-list-item-parent {
    flex: 1;
  }

  .beacon-card-list,
  .beacon-content20,
  .beacon-content21,
  .beacon-metric-item-parent {
    flex-wrap: wrap;
  }

  .beacon-text-and-badge1 {
    flex-wrap: wrap;
    padding-right: 364px;
    box-sizing: border-box;
  }

  .beacon-content23,
  .beacon-frame-parent73,
  .beacon-frame-parent74 {
    flex-wrap: wrap;
  }

  .beacon-all {
    padding-bottom: 44px;
    box-sizing: border-box;
  }

  .beacon-empty-state-container {
    padding-bottom: 81px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 750px) {


  .beacon-heading {
    font-size: 24px;
    line-height: 30px;
  }

  .beacon-content13 {
    gap: 17px;
  }

  .beacon-tabs-and-filters {
    flex-wrap: wrap;
  }

  .beacon-jul {
    width: 100%;
    height: 17px;
  }

  .beacon-x-axis {
    flex-wrap: wrap;
  }

  .beacon-number {
    font-size: 24px;
    line-height: 30px;
  }

  .beacon-main {
    gap: 16px;
  }

  .beacon-number3,
  .beacon-number4 {
    font-size: 29px;
    line-height: 35px;
  }

  .beacon-dropdowns {
    padding-right: 83px;
    box-sizing: border-box;
    min-width: 100%;
    flex: 1;
  }

  .beacon-badge-elements,
  .beacon-card-elements,
  .beacon-dropdowns,
  .beacon-location-badge-number {
    flex-wrap: wrap;
  }

  .beacon-action-fields {
    min-width: 100%;
  }

  .beacon-content-actions {
    flex-wrap: wrap;
    min-width: 100%;
  }

  .beacon-frame-parent2,
  .beacon-primary-table {
    flex-wrap: wrap;
  }

  .beacon-frame-parent1 {
    flex-wrap: wrap;
    min-width: 100%;
  }

  .beacon-frame-parent17,
  .beacon-frame-parent18,
  .beacon-frame-parent21 {
    flex-wrap: wrap;
  }

  .beacon-element-details,
  .beacon-f4b711e503bc72fe396e5043e0c2-1-icon {
    flex: 1;
  }

  .beacon-action-icons,
  .beacon-element-details-parent {
    flex-wrap: wrap;
  }

  .beacon-icon-button {
    gap: 17px;
  }

  .beacon-icon-button,
  .beacon-sidebar1 {
    flex-wrap: wrap;
  }

  .beacon-frame-parent33 {
    flex-wrap: wrap;
    min-width: 100%;
  }

  .beacon-rectangle-parent11 {
    gap: 16px;
  }

  .beacon-frame-parent41 {
    gap: 17px;
    flex-wrap: wrap;
  }

  .beacon-frame-parent39 {
    min-width: 100%;
  }

  .beacon-frame-wrapper31 {
    margin-left: 0;
  }

  .beacon-card-row {
    min-width: 100%;
  }

  .beacon-list-item-parent {
    margin-left: 0;
  }

  .beacon-buttonsbutton-parent1 {
    flex-wrap: wrap;
  }

  .beacon-text-and-badge {
    flex-wrap: wrap;
    padding-right: 121px;
    box-sizing: border-box;
  }

  .beacon-text-and-supporting-text2 {
    min-width: 100%;
  }

  .beacon-actions4 {
    flex-wrap: wrap;
  }

  .beacon-text-and-badge1 {
    padding-right: 182px;
    box-sizing: border-box;
  }

  .beacon-text-and-supporting-text3 {
    min-width: 100%;
  }

  .beacon-actions5,
  .beacon-table-cell51,
  .beacon-table-cell52 {
    flex-wrap: wrap;
  }

  .beacon-text-and-badge4 {
    flex-wrap: wrap;
    padding-right: 166px;
    box-sizing: border-box;
  }

  .beacon-frame-parent,
  .beacon-text-and-supporting-text16 {
    min-width: 100%;
  }

  .beacon-empty-state3 {
    gap: 16px;
  }

  .beacon-empty-state2 {
    padding-top: 27px;
    padding-bottom: 27px;
    box-sizing: border-box;
  }

  .beacon-container3 {
    gap: 16px;
  }

}

@media screen and (max-width: 450px) {

  .beacon-account {
    gap: 23px;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .beacon-heading {
    font-size: 18px;
    line-height: 23px;
  }

  .beacon-actions {
    flex-wrap: wrap;
  }

  .beacon-number {
    font-size: 18px;
    line-height: 23px;
  }

  .beacon-number-and-chart {
    flex-wrap: wrap;
  }

  .beacon-main {
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
  }

  .beacon-text46 {
    font-size: 21px;
    line-height: 30px;
  }

  .beacon-number3,
  .beacon-number4 {
    font-size: 22px;
    line-height: 26px;
  }

  .beacon-dropdowns {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .beacon-price {
    font-size: 16px;
    line-height: 26px;
  }

  .beacon-buttonsbutton-parent {
    flex-wrap: wrap;
  }

  .beacon-date-elements-parent {
    flex: 1;
  }

  .beacon-badge-container,
  .beacon-frame-parent12 {
    flex-wrap: wrap;
  }

  .beacon-badge-group {
    height: auto;
    min-height: 104;
  }

  .beacon-price2 {
    font-size: 16px;
    line-height: 26px;
  }

  .beacon-work-type-icon {
    flex: 1;
  }

  .beacon-calendar-icon6 {
    flex-wrap: wrap;
  }

  .beacon-work-type-label {
    margin-left: 0;
  }

  .beacon-price4 {
    font-size: 16px;
    line-height: 26px;
  }

  .beacon-frame-parent36,
  .beacon-frame-parent42 {
    flex-wrap: wrap;
  }

  .beacon-price5,
  .beacon-price6,
  .beacon-price7 {
    font-size: 16px;
    line-height: 26px;
  }

  .beacon-frame-parent64 {
    flex-wrap: wrap;
  }

  .beacon-text147 {
    font-size: 21px;
    line-height: 22px;
  }

  .beacon-content22,
  .beacon-text-and-badge {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .beacon-input-dropdown3 {
    max-width: 100%;
  }

  .beacon-table-cell50,
  .beacon-table-cell53 {
    flex-wrap: wrap;
  }

  .beacon-table-header-cell-group {
    height: auto;
    min-height: 764;
  }

  .beacon-column5,
  .beacon-column6 {
    flex: 1;
  }

  .beacon-column-parent {
    gap: 39px;
    flex-wrap: wrap;
  }

  .beacon-text-and-badge4 {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .beacon-all {
    padding-bottom: 29px;
    box-sizing: border-box;
  }

  .beacon-title8 {
    font-size: 16px;
    line-height: 24px;
  }

  .beacon-empty-state-container {
    padding-bottom: 53px;
    box-sizing: border-box;
  }

}