.active {
    background-color: #f9fafb;
}


.custom-cursor {
    cursor: pointer;
}

/* .component-display-area-funnel{} */

.funnel-card-tab-main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.component-display-area {
    padding-top: 20px;
}

.funnel-card-tab-tab {
    width: max-content;
    position: absolute;
    z-index: 5;
}

/* .filter-button-group-base {
    width: 87px;
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
    white-space: nowrap;
} */

/* .filter-button-group {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    overflow-x: auto;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
} */