.no-access-business-button-link {
  display: flex;
  align-items: center;
}

.no-access-business-bifocal-optometry-measurement-icon {
  width: 391px;
  position: relative;
  max-height: 100%;
  object-fit: cover;
  max-width: 100%;

}

.no-access-business-supporting-text,
.no-access-business-title {
  align-self: stretch;
  position: relative;
}

.no-access-business-title {
  line-height: 30px;
  font-weight: 600;
}

.no-access-business-supporting-text {
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.no-access-business-content,
.no-access-business-text-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.no-access-business-text-and-supporting-text {
  width: 352px;
  gap: 8px;
  max-width: 352px;
}

.no-access-business-content {
  align-self: stretch;
  padding: 0 20px;
  box-sizing: border-box;
  gap: 20px;
  max-width: 100%;
}

.no-access-business-placeholder-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.no-access-business-text {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 98px;
}

.no-access-business-buttonsbutton,
.no-access-business-text-padding {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.no-access-business-buttonsbutton {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 15px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  gap: 6px;
}

.no-access-business-plus-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.no-access-business-text1 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 88px;
}

.no-access-business-buttonsbutton1 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 15px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.no-access-business-buttonsbutton1:hover {
  background-color: #6941c6;
}

.no-access-business-actions,
.no-access-business-empty-state1 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.no-access-business-actions {
  flex-direction: row;
  align-items: flex-start;
  padding: 0 20px;
  box-sizing: border-box;
  gap: 12px;
}

.no-access-business-empty-state1 {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.no-access-business-empty-state,
.no-access-business-no-access-business {
  background-color: #fff;
  max-width: 100%;
  display: flex;
  flex-direction: row;
}

.no-access-business-empty-state {
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 41.5px 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 20px;
  color: #101828;
  font-family: Inter;
}

.no-access-business-no-access-business {
  width: 100%;

  /* width: 1016px; */
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: normal;
  letter-spacing: normal;
}

@media screen and (max-width: 550px) {
  .no-access-business-empty-state1 {
    gap: 16px;
  }

  .no-access-business-empty-state {
    padding-top: 27px;
    padding-bottom: 27px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .no-access-business-title {
    font-size: 16px;
    line-height: 24px;
  }
}