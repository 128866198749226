.update-profile-view-input-disabled {
	pointer-events: none;
	background-color: #f2f4f7 !important;
}

.update-profile-view-input-disabled-color {
	color: light-dark(rgb(84, 84, 84), rgb(170, 170, 170)) !important;
}

.profile-setting-toggle-header-parent {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 12px;
	max-width: 100%;
	font-size: 14px;
	color: #344054;
	align-self: stretch;
	/* gap: 16px; */
	font-size: 18px;
	color: #101828;
}

.profile-setting-toggle-header {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 4px;
	min-width: 320px;
	max-width: 100%;
}

.profile-setting-toggle-title {
	align-self: stretch;
	position: relative;
	line-height: 28px;
	font-weight: 600;
}

.profile-setting-supporting-text2 {
	font-size: 14px;
	line-height: 20px;
	color: #475467;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	align-self: stretch;
	position: relative;
}

.profile-setting-toggle-controls {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 12px;
	max-width: 100%;
	font-size: 14px;
	color: #344054;
	flex-wrap: wrap;
}

.profile-setting-rectangle-group {
	background-color: #fff;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 10px 40px 10px 15px;
	gap: 8px;
}

.profile-setting-frame-item {
	height: 40px;
	width: 156px;
	position: relative;
	background-color: #fff;
	display: none;
}

.profile-setting-switch-label {
	letter-spacing: -0.02px;
	font-weight: 500;
	display: inline-block;
	min-width: 97px;
	z-index: 1;
	position: relative;
	line-height: 20px;
}

.profile-setting-switch-label1 {
	letter-spacing: -0.02px;
	font-weight: 500;
	display: inline-block;

	z-index: 1;
	position: relative;
	line-height: 20px;
}

.profile-setting-switch-label1.active {
	font-weight: bold;
	background-color: transparent;
}

.profile-setting-switch-label.active {
	font-weight: bold;
	background-color: transparent;
	color: #6941C6;
}

.profile-setting-toggle-base {
	height: 20px;
	width: 36px;
	background-color: #f2f4f7;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding: 2px;
	box-sizing: border-box;
	z-index: 1;
	position: relative;
	cursor: pointer;
	border-radius: 9999px;
	overflow: hidden;
	flex-shrink: 0;
}



.profile-setting-button6a {
	height: 16px;
	width: 16px;
	position: relative;
	box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
	background-color: #fff;
	position: absolute;
	transition: left 0.3s ease;
	border-radius: 9999px;
	overflow: hidden;
	flex-shrink: 0;
}

.profile-setting-button6a.left {
	left: 0;
}

.profile-setting-button6a.right {
	left: 20px;
	background-color: #6941C6;
}



.profile-setting-supporting-text3 {
	position: relative;
	line-height: 20px;
	align-self: stretch;
	width: 300px;
	color: #475467;
	display: none;
}

.profile-setting-button7 {
	cursor: pointer;
	border: 1px solid #d0d5dd;
	padding: 8px 16px;
	background-color: #fff;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
}

.profile-setting-placeholder-icon {
	height: 20px;
	width: 20px;
	position: relative;
	overflow: hidden;
	flex-shrink: 0;
	display: none;
	min-height: 20px;
}

.profile-setting-button-labels {
	position: relative;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	font-family: Inter;
	color: #344054;
	text-align: left;
	display: inline-block;
	min-width: 63px;
}

.profile-setting-button8 {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	cursor: pointer;
	border: 1px solid #7e56d9;
	padding: 8px 16px;
	background-color: #7e56d9;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	overflow: hidden;
}

.profile-setting-button8:hover {
	background-color: #6941c6;
}

.profile-setting-text49 {
	position: relative;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	font-family: Inter;
	color: #fff;
	text-align: left;
	display: inline-block;
	/* min-width: 110px; */
}

.update-profile-view-gerator-04-1-icon1 {
	height: 32px;
	width: 121px;
	position: relative;
	object-fit: cover;
	display: none;
}

.update-profile-view-logo1 {
	width: 121px;
	height: 32px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	background-image: url("../../icons/Gerator/account_setup/gerator04-1@2x.png");
	/* background-image: url(../../../public/account_setup_profile_subscription/gerator04-1@2x.png); */
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
}

.update-profile-view-featured-icon8 {
	height: 48px;
	width: 48px;
	position: relative;
	border-radius: 10px;
}

.update-profile-view-step-labels,
.update-profile-view-supporting-text9 {
	position: relative;
	line-height: 24px;
}

.update-profile-view-step-labels {
	align-self: stretch;
	font-weight: 600;
}

.update-profile-view-supporting-text9 {
	color: #475467;
}

.update-profile-view-step-elements,
.update-profile-view-step-labels-parent {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.update-profile-view-step-labels-parent {
	flex: 1;
	flex-direction: column;
	gap: 2px;
	min-width: 182px;
}

.update-profile-view-step-elements {
	align-self: stretch;
	flex-direction: row;
	gap: 16px;
}

.update-profile-view-connector4 {
	height: 26px;
	width: 2px;
	position: relative;
	border-radius: 2px;
	background-color: #eaecf0;
}

.update-profile-view-progress-connectors,
.update-profile-view-step-base4,
.update-profile-view-step-base5 {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 0 23px;
}

.update-profile-view-step-base4,
.update-profile-view-step-base5 {
	align-self: stretch;
	flex-direction: column;
	padding: 0 0 4px;
	gap: 2px;
}

.update-profile-view-step-base5 {
	opacity: 0.6;
}

.update-profile-view-featured-icon10 {
	width: 48px;
	height: 48px;
	position: relative;
	border-radius: 10px;
}

.update-profile-view-connector-wrap2 {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 0 0 4px;
	gap: 4px;
}

.update-profile-view-supporting-text11 {
	align-self: stretch;
	position: relative;
	line-height: 24px;
	color: #475467;
}

.update-profile-view-step-base6,
.update-profile-view-text-and-supporting-text2 {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.update-profile-view-text-and-supporting-text2 {
	flex: 1;
	flex-direction: column;
	padding: 0 0 32px;
	box-sizing: border-box;
	gap: 2px;
	min-width: 182px;
}

.update-profile-view-step-base6 {
	align-self: stretch;
	flex-direction: row;
	gap: 16px;
	opacity: 0.6;
}

.update-profile-view-connector7 {
	width: 2px;
	height: 26px;
	position: relative;
	border-radius: 2px;
	background-color: #eaecf0;
	display: none;
}

.update-profile-view-connector-wrap3,
.update-profile-view-progress-steps-progress-icon1 {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.update-profile-view-connector-wrap3 {
	height: 82px;
	align-items: center;
	padding: 0 0 4px;
	box-sizing: border-box;
	gap: 4px;
}

.update-profile-view-progress-steps-progress-icon1 {
	align-self: stretch;
	align-items: flex-start;
}

.update-profile-view-progress-container,
.update-profile-view-progress-container-wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	max-width: 100%;
}

.update-profile-view-progress-container {
	flex: 1;
	flex-direction: column;
	gap: 79.5px;
}

.update-profile-view-progress-container-wrapper {
	width: 380px;
	flex-direction: row;
	padding: 0 18px;
	box-sizing: border-box;
}

.update-profile-view-progress-container-wrapper-custom-padding-width {
	padding: 0px !important;
	width: auto !important;
}

.update-profile-view-text40 {
	height: 20px;
	width: 125px;
	position: relative;
	line-height: 20px;
	display: inline-block;
}

.update-profile-view-mail-01-icon2 {
	width: 16px;
	height: 16px;
	position: relative;
	overflow: hidden;
	flex-shrink: 0;
}

.update-profile-view-helpuntitleduicom1 {
	align-self: stretch;
	flex: 1;
	position: relative;
	line-height: 20px;
	white-space: nowrap;
}

.update-profile-view-footer4 {
	height: 20px;
	width: 188px;
	flex-direction: row;
	justify-content: flex-start;
	gap: 7px;
}

.update-profile-view-footer4,
.update-profile-view-section2,
.update-profile-view-text-container {
	display: flex;
	align-items: flex-start;
}

.update-profile-view-text-container {
	align-self: stretch;
	flex-direction: row;
	justify-content: space-between;
	gap: 20px;
	font-size: 14px;
}

.update-profile-view-section2 {
	flex: 1;
	background-color: #f9fafb;
	/* background-color: red; */
	flex-direction: column;
	justify-content: flex-start;
	padding: 32.5px 13px 32px 14px;
	box-sizing: border-box;
	gap: 1008px;
	min-width: 286px;
	max-width: 440px;
}

.update-profile-view-home-line-icon {
	height: 20px;
	width: 20px;
	position: relative;
	overflow: hidden;
	flex-shrink: 0;
}

.update-profile-view-breadcrumb-button-base {
	border-radius: 6px;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 4px;
}

.update-profile-view-chevron-right-icon {
	height: 16px;
	width: 16px;
	position: relative;
	overflow: hidden;
	flex-shrink: 0;
}

.update-profile-view-text41 {
	align-self: stretch;
	flex: 1;
	position: relative;
	line-height: 20px;
	font-weight: 500;
}

.update-profile-view-breadcrumb-button-base1 {
	align-self: stretch;
	flex: 1;
	border-radius: 6px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 4px 8px;
}

.update-profile-view-text42 {
	align-self: stretch;
	position: relative;
	line-height: 20px;
	font-weight: 500;
}

.update-profile-view-breadcrumb-button-base2 {
	align-self: stretch;
	border-radius: 6px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 4px 7px;
}

.update-profile-view-text43 {
	align-self: stretch;
	position: relative;
	line-height: 20px;
	font-weight: 500;
	white-space: nowrap;
}

.update-profile-view-breadcrumb-button-base3 {
	align-self: stretch;
	border-radius: 6px;
	display: none;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 4px 8px;
}

.update-profile-view-chevron-right-icon3 {
	height: 16px;
	width: 16px;
	position: relative;
	overflow: hidden;
	flex-shrink: 0;
	display: none;
}

.update-profile-view-text44 {
	position: relative;
	line-height: 20px;
	font-weight: 600;
}

.update-profile-view-breadcrumb-button-base4 {
	border-radius: 6px;
	background-color: #f9fafb;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 4px 8px;
	color: #344054;
}

.update-profile-view-breadcrumbs,
.update-profile-view-tabs {
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.update-profile-view-tabs {
	height: 28px;
	flex: 1;
	display: flex;
	gap: 8px;
}

.update-profile-view-breadcrumbs {
	width: 278px;
	display: none;
}

.update-profile-view-page-title {
	text-decoration: none;
	align-self: stretch;
	position: relative;
	line-height: 38px;
	font-weight: 600;
	color: inherit;
}

.update-profile-view-supporting-text13 {
	width: 445px;
	position: relative;
	font-size: 16px;
	line-height: 24px;
	color: #475467;
	display: none;
	max-width: 100%;
}

.update-profile-view-text-and-supporting-text4 {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 4px;
	min-width: 320px;
	max-width: 100%;
	font-size: 30px;
}

.update-profile-view-placeholder-icon16 {
	height: 20px;
	width: 20px;
	position: relative;
	overflow: hidden;
	flex-shrink: 0;
	display: none;
	min-height: 20px;
}

.update-profile-view-text45 {
	align-self: stretch;
	position: relative;
	line-height: 20px;
	font-weight: 600;
}

.update-profile-view-text-padding8 {
	align-self: stretch;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 0 2px;
}

.update-profile-view-button,
.update-profile-view-button1,
.update-profile-view-button2 {
	height: 40px;
	border-radius: 8px;
	box-sizing: border-box;
	overflow: hidden;
	display: flex;
	justify-content: center;
	gap: 4px;
}

.update-profile-view-button {
	flex-direction: row;
	align-items: center;
	padding: 10px 14px;
}

.update-profile-view-button1,
.update-profile-view-button2 {
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
	background-color: #fff;
	padding: 10px 13px;
}

.update-profile-view-button1 {
	border: 1px solid #d6bbfb;
	flex-direction: row;
	align-items: center;
	color: #6941c6;
}

.update-profile-view-button2 {
	border: 1px solid #d0d5dd;
	color: #344054;
}

.update-profile-view-actions4,
.update-profile-view-button2,
.update-profile-view-button3 {
	flex-direction: row;
	align-items: center;
}

.update-profile-view-button3 {
	height: 40px;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	background-color: #7e56d9;
	border: 1px solid #7e56d9;
	box-sizing: border-box;
	overflow: hidden;
	display: flex;
	justify-content: center;
	padding: 10px 13px;
	gap: 4px;
	color: #fff;
}

.update-profile-view-actions4 {
	display: none;
	justify-content: flex-start;
	gap: 12px;
	max-width: 100%;
	color: #475467;
}

.update-profile-view-label,
.update-profile-view-text49 {
	position: relative;
}

.update-profile-view-label {
	width: 47px;
	line-height: 20px;
	font-weight: 500;
	display: none;
}

.update-profile-view-text49 {
	align-self: stretch;
	flex: 1;
	line-height: 24px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.update-profile-view-content8 {
	flex-direction: row;
	align-items: center;
	gap: 8px;
}

.update-profile-view-content8,
.update-profile-view-input,
.update-profile-view-input-with-label {
	align-self: stretch;
	flex: 1;
	display: flex;
	justify-content: flex-start;
}

.update-profile-view-input {
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	background-color: #fff;
	border: 1px solid #d0d5dd;
	overflow: hidden;
	flex-direction: row;
	align-items: center;
	padding: 10px 13px;
	font-size: 16px;
	color: #667085;
}

.update-profile-view-input-with-label {
	flex-direction: column;
	align-items: flex-start;
	gap: 6px;
}

.update-profile-view-hint-text {
	align-self: stretch;
	position: relative;
	line-height: 20px;
	color: #475467;
	display: none;
}

.update-profile-view-content7,
.update-profile-view-input-dropdown {
	align-items: flex-start;
	justify-content: flex-start;
}

.update-profile-view-input-dropdown {
	height: 44px;
	width: 320px;
	display: none;
	flex-direction: column;
	gap: 8px;
	min-width: 200px;
	max-width: 320px;
	color: #344054;
}

.update-profile-view-content7 {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: flex-start;
	gap: 20px 16px;
	color: #101828;
}

.update-profile-view-content7,
.update-profile-view-divider-icon,
.update-profile-view-page-header {
	align-self: stretch;
	max-width: 100%;
}

.update-profile-view-divider-icon {
	position: relative;
	overflow: hidden;
	max-height: 100%;
	display: none;
}

.update-profile-view-page-header {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 20px;
}

.update-profile-view-container3 {
	padding: 0 32px;
	box-sizing: border-box;
}

.update-profile-view-container3,
.update-profile-view-header-section {
	align-self: stretch;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	max-width: 100%;
}

.update-profile-view-header-section-custom-margin {
	margin-top: 30px;
}

.update-profile-view-text50 {
	flex: 1;
	position: relative;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	font-family: Inter;
	color: #344054;
	text-align: left;
}

.update-profile-view-section-label {
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	min-width: 200px;
	max-width: 280px;
}

.update-profile-view-label1 {
	width: 36px;
	position: relative;
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
	font-family: Inter;
	color: #344054;
	text-align: left;
	display: none;
}

.update-profile-view-content10 {
	width: 100%;
	border: 0;
	outline: 0;
	background-color: transparent;
	height: 24px;
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	font-family: Inter;
	font-size: 16px;
	color: #101828;
	min-width: 130px;
}

.update-profile-view-help-icon {
	height: 16px;
	width: 16px;
	position: relative;
	display: none;
}

.update-profile-view-input-with-label1,
.update-profile-view-input1 {
	align-self: stretch;
	display: flex;
	justify-content: flex-start;
}

.update-profile-view-input1 {
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	background-color: #fff;
	border: 1px solid #d0d5dd;
	flex-direction: row;
	align-items: center;
	padding: 8px 13px;
	gap: 8px;
}

.update-profile-view-input-with-label1 {
	flex-direction: column;
	align-items: flex-start;
	gap: 6px;
}

.update-profile-view-hint-text1 {
	align-self: stretch;
	position: relative;
	font-size: 14px;
	line-height: 20px;
	font-family: Inter;
	color: #475467;
	text-align: left;
	display: none;
}

.update-profile-view-input-field {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 6px;
	min-width: 159px;
}

.update-profile-view-label2 {
	width: 96px;
	position: relative;
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
	font-family: Inter;
	color: #344054;
	text-align: left;
	display: none;
}

.update-profile-view-content11 {
	width: calc(100% - 46px);
	border: 0;
	outline: 0;
	background-color: transparent;
	height: 24px;
	flex: 1;
	align-items: center;
	font-family: Inter;
	font-size: 16px;
	color: #101828;
	min-width: 113px;
}

.update-profile-view-content11,
.update-profile-view-content9,
.update-profile-view-input-fields,
.update-profile-view-input2 {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.update-profile-view-input2 {
	align-self: stretch;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	background-color: #fff;
	border: 1px solid #d0d5dd;
	overflow: hidden;
	align-items: center;
	padding: 8px 13px;
	gap: 8px;
}

.update-profile-view-content9,
.update-profile-view-input-fields {
	align-items: flex-start;
}

.update-profile-view-input-fields {
	flex: 1;
	gap: 24px;
	min-width: 480px;
	max-width: 512px;
}

.update-profile-view-content9 {
	align-self: stretch;
	flex-wrap: wrap;
	align-content: flex-start;
	gap: 16px 32px;
	max-width: 100%;
}

.update-profile-view-divider,
.update-profile-view-text51 {
	align-self: stretch;
	position: relative;
}

.update-profile-view-divider {
	height: 1px;
	background-color: #eaecf0;
}

.update-profile-view-text51 {
	text-decoration: none;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	font-family: Inter;
	color: #344054;
	text-align: left;
}

.update-profile-view-section-label1 {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	min-width: 200px;
	max-width: 280px;
}

.update-profile-view-text52 {
	width: calc(100% - 40px);
	border: 0;
	outline: 0;
	font-family: Inter;
	font-size: 16px;
	background-color: transparent;
	height: 24px;
	flex: 1;
	position: relative;
	line-height: 24px;
	color: #667085;
	text-align: left;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	min-width: 250px;
	max-width: calc(100% - 28px);
	padding: 0;
}

.update-profile-view-content13,
.update-profile-view-input-with-label3,
.update-profile-view-input3 {
	display: flex;
	justify-content: flex-start;
}

.update-profile-view-content13 {
	flex: 1;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	max-width: calc(100% - 28px);
}

.update-profile-view-input-with-label3,
.update-profile-view-input3 {
	align-self: stretch;
	max-width: 100%;
}

.update-profile-view-input3 {
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	background-color: #fff;
	border: 1px solid #d0d5dd;
	box-sizing: border-box;
	flex-direction: row;
	align-items: center;
	padding: 8px 13px;
	gap: 8px;
}

.update-profile-view-input-with-label3 {
	flex-direction: column;
	align-items: flex-start;
	gap: 6px;
}

.update-profile-view-hint-text2 {
	width: 320px;
	position: relative;
	font-size: 14px;
	line-height: 20px;
	font-family: Inter;
	color: #475467;
	text-align: left;
	/* display: none; */
}

.update-profile-view-content12,
.update-profile-view-input-field1 {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.update-profile-view-input-field1 {
	flex: 1;
	flex-direction: column;
	gap: 6px;
	min-width: 480px;
	max-width: 512px;
}

.update-profile-view-content12 {
	align-self: stretch;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: flex-start;
	/* padding: 0 102px 0 0; */
	box-sizing: border-box;
	gap: 16px 32px;
	max-width: 100%;
}

.update-profile-view-text53,
.update-profile-view-text55 {
	position: relative;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	font-family: Inter;
	color: #344054;
	text-align: left;
}

.update-profile-view-text53 {
	align-self: stretch;
}

.update-profile-view-text55 {
	display: inline-block;
	min-width: 75px;
}

.update-profile-view-help-icon3 {
	width: 16px;
	height: 16px;
	position: relative;
}

.update-profile-view-heading-and-help-icon,
.update-profile-view-help-icon-wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.update-profile-view-help-icon-wrapper {
	flex-direction: column;
	padding: 2px 0 0;
}

.update-profile-view-heading-and-help-icon {
	flex-direction: row;
	gap: 2px;
}

.update-profile-view-supporting-text14 {
	align-self: stretch;
	position: relative;
	font-size: 14px;
	line-height: 20px;
	font-family: Inter;
	color: #475467;
	text-align: left;
}

.update-profile-view-avatar,
.update-profile-view-contrast-border {
	height: 64px;
	width: 64px;
	border-radius: 9999px;
}

.update-profile-view-contrast-border {
	position: relative;
	border: 0.8px solid rgba(0, 0, 0, 0.08);
	box-sizing: border-box;
	overflow: hidden;
	flex-shrink: 0;
}

.update-profile-view-avatar {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	background-image: url(../../icons/Gerator/dashboard/user-placeholder2.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
}

.update-profile-view-featured-icon12 {
	width: 40px;
	height: 40px;
	position: relative;
	border-radius: 8px;
}

.update-profile-view-text56 {
	position: relative;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	font-family: Inter;
	color: #6941c6;
	text-align: left;
	display: inline-block;
	min-width: 101px;
}

.update-profile-view-buttonsbutton8 {
	overflow: hidden;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 6px;
}

.update-profile-view-text57 {
	position: relative;
	font-size: 14px;
	line-height: 20px;
	font-family: Inter;
	color: #475467;
	text-align: left;
	display: inline-block;
	min-width: 112px;
}

.update-profile-view-action {
	align-self: stretch;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	padding: 0 20px;
	gap: 4px;
}

.update-profile-view-supporting-text15 {
	align-self: stretch;
	position: relative;
	font-size: 12px;
	line-height: 18px;
	font-family: Inter;
	color: #475467;
	text-align: center;
}

.update-profile-view-text-and-supporting-text5 {
	align-self: stretch;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	gap: 4px;
}

.update-profile-view-page-icon {
	position: absolute;
	height: 100%;
	width: 80%;
	top: 0;
	right: 2.5%;
	bottom: 0;
	left: 17.5%;
	max-width: 100%;
	overflow: hidden;
	max-height: 100%;
}

.update-profile-view-file-type {
	position: relative;
	font-size: 10px;
	display: inline-block;
	font-family: Inter;
	color: #fff;
	text-align: center;
	min-width: 20px;
}

.update-profile-view-file-type-wrap {
	position: absolute;
	height: 40%;
	width: 65%;
	top: 45%;
	right: 32.5%;
	bottom: 15%;
	left: 2.5%;
	border-radius: 2px;
	background-color: #7e56d9;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 2px 3px;
	box-sizing: border-box;
	z-index: 1;
}

.update-profile-view-file-type-icon {
	position: absolute;
	top: 0;
	right: 0;
	filter: drop-shadow(0 24px 48px rgba(16, 24, 40, 0.18));
	width: 100%;
	height: 100%;
}

.update-profile-view-cursor-icon {
	position: absolute;
	top: 28px;
	left: 28px;
	width: 20px;
	height: 20px;
	overflow: hidden;
	object-fit: cover;
	z-index: 2;
}

.update-profile-view-file {
	width: 40px;
	height: 40px;
	position: absolute;
	margin: 0 !important;
	right: 0;
	bottom: 4px;
	z-index: 1;
}

.update-profile-view-content17 {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	gap: 12px;
	max-width: 100%;
}

.update-profile-view-file-upload,
.update-profile-view-file-upload-base {
	align-items: flex-start;
	max-width: 100%;
}

.update-profile-view-file-upload-base {
	flex: 1;
	border-radius: 12px;
	background-color: #fff;
	border: 2px solid #7e56d9;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding: 13px 22px;
}

.update-profile-view-file-upload {
	min-width: 278px;
}

.update-profile-view-avatar-and-file-upload,
.update-profile-view-content19,
.update-profile-view-file-upload {
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.update-profile-view-avatar-and-file-upload {
	align-items: flex-start;
	gap: 20px;
	min-width: 480px;
	max-width: 512px;
}

.update-profile-view-content19 {
	width: 100%;
	border: 0;
	outline: 0;
	background-color: transparent;
	height: 24px;
	align-items: center;
	font-family: Inter;
	font-size: 16px;
	color: #667085;
	min-width: 250px;
	max-width: 100%;
}

.update-profile-view-supporting-text16,
.update-profile-view-text61 {
	position: relative;
	font-size: 16px;
	line-height: 24px;
	font-family: Inter;
	text-align: left;
}

.update-profile-view-text61 {
	font-weight: 500;
	color: #101828;
	display: inline-block;
	min-width: 68px;
}

.update-profile-view-supporting-text16 {
	align-self: stretch;
	width: 54px;
	color: #475467;
	display: none;
}

.update-profile-view-content23 {
	flex: 1;
	flex-direction: row;
	align-items: center;
	padding: 0 360px 0 0;
	box-sizing: border-box;
	max-width: calc(100% - 28px);
}

.update-profile-view-content-custom-23 {
	flex: 1;
	flex-direction: row;
	align-items: center;
	/* padding: 8px 13px; */
	box-sizing: border-box;
	border: none;
	background-color: #fff;
	outline: none;
}

.update-profile-view-content23,
.update-profile-view-input-dropdown1,
.update-profile-view-input7 {
	display: flex;
	justify-content: flex-start;
	gap: 8px;
}

.update-profile-view-input7 {
	align-self: stretch;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	background-color: #fff;
	border: 1px solid #d0d5dd;
	box-sizing: border-box;
	overflow: hidden;
	flex-direction: row;
	align-items: center;
	padding: 8px 13px;
	max-width: 100%;
}

.update-profile-view-input-dropdown1 {
	flex: 1;
	flex-direction: column;
	align-items: flex-start;
	min-width: 480px;
	max-width: 512px;
}

.update-profile-view-text63 {
	position: relative;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	font-family: Inter;
	color: #667085;
	text-align: left;
	display: inline-block;
	min-width: 126px;
}

.update-profile-view-content25 {
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 8px;
	max-width: 100%;
}

.update-profile-view-input8 {
	align-self: stretch;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	background-color: #fff;
	border: 1px solid #d0d5dd;
	box-sizing: border-box;
	overflow: hidden;
	align-items: center;
	padding: 8px 13px;
}

.update-profile-view-content-container,
.update-profile-view-content27,
.update-profile-view-content32,
.update-profile-view-input8 {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	max-width: 100%;
}

.update-profile-view-content27 {
	width: 100%;
	border: 0;
	outline: 0;
	background-color: transparent;
	height: 24px;
	flex: 1;
	align-items: center;
	font-family: Inter;
	font-weight: 500;
	font-size: 16px;
	color: #667085;
	min-width: 250px;
}

.update-profile-view-content-container,
.update-profile-view-content32 {
	align-items: flex-start;
	box-sizing: border-box;
}

.update-profile-view-content32 {
	flex: 1;
	flex-wrap: wrap;
	align-content: flex-start;
	/* padding: 0 102px 0 0; */
	gap: 16px 32px;
}

.update-profile-view-content-container {
	align-self: stretch;
	padding: 0 0 4px;
}

.update-profile-view-divider-icon1,
.update-profile-view-text68 {
	align-self: stretch;
	position: relative;
}

.update-profile-view-divider-icon1 {
	max-width: 100%;
	overflow: hidden;
	max-height: 100%;
}

.update-profile-view-text68 {
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	font-family: Inter;
	color: #475467;
	text-align: left;
	white-space: nowrap;
}

.update-profile-view-buttonsbutton9 {
	height: 20px;
	overflow: hidden;
	display: none;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 6px;
}

.update-profile-view-button4,
.update-profile-view-text69 {
	align-self: stretch;
}

.update-profile-view-text69 {
	position: relative;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	font-family: Inter;
	color: #475467;
	text-align: left;
}

.update-profile-view-button4 {
	border-radius: 8px;
	overflow: hidden;
	display: none;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 10px 14px;
	gap: 4px;
}

.update-profile-view-text70 {
	position: relative;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	font-family: Inter;
	color: #344054;
	text-align: left;
	display: inline-block;
	min-width: 48px;
}

.update-profile-view-button5,
.update-profile-view-text-padding13 {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 0 2px;
}

.update-profile-view-button5 {
	cursor: pointer;
	border: 1px solid #d0d5dd;
	padding: 8px 13px;
	background-color: #fff;
	flex: 1;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	box-sizing: border-box;
	overflow: hidden;
	gap: 4px;
	min-width: 52px;
}

.update-profile-view-text71 {
	position: relative;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	font-family: Inter;
	color: #fff;
	text-align: left;
	display: inline-block;
	min-width: 34px;
}

.update-profile-view-text71-custom {
	min-width: 50px !important;
}

.update-profile-view-text-padding14 {
	width: 37px;
	justify-content: center;
	padding: 0 1px;
	box-sizing: border-box;
}

.update-profile-view-actions5,
.update-profile-view-button6,
.update-profile-view-content34,
.update-profile-view-section-footer,
.update-profile-view-text-padding14 {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.update-profile-view-button6 {
	cursor: pointer;
	border: 1px solid #7e56d9;
	padding: 8px 13px;
	background-color: #7e56d9;
	width: 65px;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
	border-radius: 8px;
	box-sizing: border-box;
	overflow: hidden;
	flex-shrink: 0;
	justify-content: center;
	gap: 4px;
}

.update-profile-view-button6-custom-width {
	width: 100px !important;
}

.update-profile-view-actions5,
.update-profile-view-content34,
.update-profile-view-section-footer {
	justify-content: flex-end;
	max-width: 100%;
}

.update-profile-view-actions5 {
	flex: 1;
	padding: 0 0 0 769px;
	box-sizing: border-box;
	gap: 12px;
}

.update-profile-view-actions5-custom-padding {
	padding: 0px !important;
}

.update-profile-view-content34,
.update-profile-view-section-footer {
	align-self: stretch;
	gap: 20px;
}

.update-profile-view-content34-custom-align-self {
	align-self: flex-end !important;
	padding-right: 105px;
}

.update-profile-view-section-footer {
	flex-direction: column;
	justify-content: flex-start;
}

.update-profile-view-content-elements,
.update-profile-view-content-elements-wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	max-width: 100%;
}


.update-profile-view-content-elements {
	margin: 0;
	flex: 1;
	flex-direction: column;
	gap: 20px;
	padding: 0 32px;
}

.update-profile-view-content-elements-wrapper {
	align-self: stretch;
	flex-direction: row;
	padding: 0 32px 106px;
	box-sizing: border-box;
}

.update-profile-view-content-elements-wrapper-custom-padding {
	padding: 0 32px 10px;
}

.update-profile-view-helpcentergeratorcom1 {
	position: relative;
	line-height: 20px;
	white-space: nowrap;
}

.update-profile-view-icon-and-text1 {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 7px;
}

.update-profile-view-text72 {
	position: relative;
	line-height: 20px;
}

.update-profile-view-help-content {
	align-self: stretch;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	gap: 20px;
}

.update-profile-view-content-wrapper,
.update-profile-view-content-wrapper-inner,
.update-profile-view-help-content-wrapper {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	max-width: 100%;
}

.update-profile-view-help-content-wrapper {
	flex: 1;
	flex-direction: column;
}

.update-profile-view-content-wrapper,
.update-profile-view-content-wrapper-inner {
	align-self: stretch;
}

.update-profile-view-content-wrapper-inner {
	flex-direction: row;
	padding: 0 23px;
	box-sizing: border-box;
}

.update-profile-view-content-wrapper {
	flex-direction: column;
	gap: 32px;
}

.update-profile-view-content-wrapper-wrapper,
.update-profile-view-update-profile-view {
	display: flex;
	box-sizing: border-box;
	text-align: left;
	font-family: Inter;
}

.update-profile-view-content-wrapper-wrapper {
	width: 990px;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	padding: 0 0 30px;
	min-width: 990px;
	max-width: 100%;
	font-size: 14px;
	color: #475467;
}

.update-profile-view-update-profile-view {
	width: 100%;
	position: relative;
	background-color: #fff;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-start;
	padding: 0 10px 0 0;
	min-height: 960px;
	line-height: normal;
	letter-spacing: normal;
	row-gap: 20px;
	font-size: 16px;
	color: #344054;
}

.update-profile-view-update-profile-view-custom-align-item {
	align-items: unset !important;
}

.update-profile-view-mobile-container {
	width: 100%;
}

.update-profile-view-section2-custom-minwidth-padding {
	min-width: auto !important;
	padding: 0px !important;
}

@media screen and (max-width: 1200px) {
	.update-profile-view-progress-container {
		flex: 1;
	}

	.update-profile-view-section2 {
		padding-top: 21px;
		padding-bottom: 21px;
		box-sizing: border-box;
		min-width: 100%;
		gap: 70px;

	}

	.update-profile-view-content-elements-wrapper {
		padding-bottom: 69px;
		box-sizing: border-box;
	}

	.update-profile-view-content-wrapper-wrapper {
		flex: 1;
	}

	.update-profile-view-update-profile-view {
		flex-wrap: wrap;
		padding-left: 10px;
		padding-top: 10px;
		box-sizing: border-box;
	}


}

@media screen and (max-width: 1050px) {
	.update-profile-view-page-title {
		font-size: 24px;
		line-height: 30px;
	}

	.update-profile-view-content12,
	.update-profile-view-content32 {
		padding-right: 51px;
		box-sizing: border-box;
	}

	.update-profile-view-actions5 {
		flex-wrap: wrap;
		padding-left: 0px;
		box-sizing: border-box;
		max-width: 150px;

	}

	.update-profile-view-content34 {
		justify-content: flex-start
	}

	.update-profile-view-content-wrapper-wrapper {
		min-width: 100%;
	}


}

@media screen and (max-width: 750px) {
	.update-profile-view-section2 {
		padding-top: 20px;
		padding-bottom: 20px;
		box-sizing: border-box;
	}

	.update-profile-view-actions4,
	.update-profile-view-input-fields {
		flex-wrap: wrap;
	}

	.update-profile-view-input-field1,
	.update-profile-view-input-fields {
		max-width: 100%;
		min-width: 100%;
	}

	.update-profile-view-avatar-and-file-upload {
		flex-wrap: wrap;
	}

	.update-profile-view-avatar-and-file-upload,
	.update-profile-view-input-dropdown1 {
		max-width: 100%;
		min-width: 100%;
	}

	.update-profile-view-content-elements-wrapper {
		padding-bottom: 45px;
		box-sizing: border-box;
	}

	.update-profile-view-help-content {
		flex-wrap: wrap;
	}

	.update-profile-view-content-wrapper {
		gap: 16px;
	}
}

@media screen and (max-width: 450px) {

	.update-profile-view-mobile-container {
		width: 375px;
		height: 10px;
		padding-left: 16px;
		padding-right: 16px;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		gap: 32px;
		display: inline-flex;
		padding-top: 30px;
	}

	.update-profile-view-mobile-PaginationDotGroup {
		justify-content: center;
		align-items: center;
		gap: 16px;
		display: inline-flex;
	}

	.update-profile-view-mobile-PaginationDotIndicator {
		width: 10px;
		height: 10px;
		position: relative;
		background-color: #EAECF0;
		border-radius: 9999px;
	}

	.update-profile-view-mobile-PaginationDotIndicator1 {
		width: 10px;
		height: 10px;
		position: relative;
		background-color: #7F56D9;
		border-radius: 9999px;
	}

	.update-profile-view-progress-steps-progress-icon1 {
		display: none;
	}

	.update-profile-view-step-base6,
	.update-profile-view-step-elements {
		flex-wrap: wrap;
	}

	.update-profile-view-progress-container {
		gap: 40px;
	}

	.update-profile-view-text-container {
		flex-wrap: wrap;
	}

	.update-profile-view-section2 {
		gap: 50px;
		height: fit-content;
		max-width: 90%;
		background-color: white;
		padding-bottom: 25px;
		padding-top: 10px;
		padding-left: 5px;
	}

	.update-profile-view-page-title {
		font-size: 18px;
		line-height: 23px;
	}

	.update-profile-view-content9 {
		gap: 16px;
	}

	.update-profile-view-content12 {
		gap: 16px;
		padding-right: 25px;
		box-sizing: border-box;
	}

	.update-profile-view-action {
		flex-wrap: wrap;
	}

	.update-profile-view-content23,
	.update-profile-view-content32 {
		padding-right: 180px;
		box-sizing: border-box;
	}

	.update-profile-view-content32 {
		gap: 16px;
		padding-right: 25px;
	}

	.update-profile-view-actions5 {
		padding-left: 0px;
		box-sizing: border-box;
	}

	.update-profile-view-file {

		width: 30px;
		height: 30px;
		right: -10px;

	}

	.update-profile-view-cursor-icon {
		left: 18px;
		top: 18px;
	}

	.update-profile-view-content-wrapper-inner {
		padding-top: 50px;
	}

}