/* Pending Post Status */

.post-status-component-badge-parent-pending {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: flex;
}

.post-status-component-id-text-pending {
    color: #475467;
    text-align: left;
    z-index: 2;
    min-width: 83px;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    position: relative;
}

.post-status-component-badge-pending {
    box-sizing: border-box;
    mix-blend-mode: normal;
    background-color: #fff9ef;
    border: 1px solid #f5b231;
    color: #f5b231 !important;
    border-radius: 9999px;
    flex-direction: row;
    align-items: flex-start;
    gap: 6px;
    width: 72px;
    padding: 0 8px;
    display: flex;
    line-height: 20px;
}


/* Published Post Status */

.post-status-component-badge-parent-published {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: flex;
}

.post-status-component-id-text-published {
    color: #475467;
    text-align: left;
    z-index: 2;
    min-width: 83px;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    position: relative;
}

.post-status-component-badge-published {
    box-sizing: border-box;
    mix-blend-mode: normal;
    background-color: #edfbf5;
    border: 1px solid #17b26a;
    color: #17b26a !important;
    border-radius: 9999px;
    flex-direction: row;
    align-items: flex-start;
    gap: 6px;
    width: 82px;
    padding: 0 8px;
    display: flex;
    line-height: 20px;
}

/* Archived Post Status */


.post-status-component-badge-parent-archived {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: flex;
}

.post-status-component-id-text-archived {
    color: #475467;
    text-align: left;
    z-index: 2;
    min-width: 83px;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    position: relative;
}


.post-status-component-badge-archived {
    box-sizing: border-box;
    mix-blend-mode: normal;
    background-color: #eceef1;
    border: 1px solid #98a2b3;
    color: #98a2b3 !important;
    border-radius: 9999px;
    flex-direction: row;
    align-items: flex-start;
    gap: 6px;
    width: 76px;
    padding: 0 8px;
    display: flex;
    line-height: 20px;
}

/* Expired Post Status */

.post-status-component-badge-parent-expired {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: flex;
}

.post-status-component-id-text-expired {
    color: #475467;
    text-align: left;
    z-index: 2;
    min-width: 83px;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    position: relative;
}


.post-status-component-badge-expired {
    box-sizing: border-box;
    mix-blend-mode: normal;
    background-color: #fffdfd;
    border: 1px solid #da2d20;
    color: #da2d20 !important;
    border-radius: 9999px;
    flex-direction: row;
    align-items: flex-start;
    gap: 6px;
    width: 68px;
    padding: 0 8px;
    display: flex;
    line-height: 20px;
}