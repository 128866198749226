.button-dropdown-menu {
    width: 153px;
    height: max-content;
    background: white;
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid #EAECF0;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .button-dropdown-content {
    align-self: stretch;
    height: max-content;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
  }
  
  .button-dropdown-navMenuItem {
    align-self: stretch;
    /* padding: 12px 12px 12px 0;
    height: 120px; */
    border-radius: 8px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
    
  }

  .button-dropdown-navMenuItem:hover{
    
      background-color: #eaecf0;
    
  }
  
 
  .button-dropdown-itemContent {
    flex: 1 1 0;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
  }
  
  .button-dropdown-textAndSupportingText {
    align-self: stretch;
    /* height: 68px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
    padding: 8px;
  }
  
  .button-dropdown-textAndBadge {
    align-self: stretch;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
  
  .button-dropdown-text {
    color: #101828;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    line-height: 24px;
    word-wrap: break-word;
  }
  
  .button-dropdown-supportingText {
    align-self: stretch;
    color: #475467;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
  }
  