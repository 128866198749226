.business-display-menu {
  width: 336px;
  /* height: 516px; */
  background: white;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid #EAECF0;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.business-display-content {
  align-self: stretch;
  height: max-content;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
}

.business-display-navMenuItem {
  align-self: stretch;
  padding: 12px 12px 12px 0;
  border-radius: 8px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  height: 170px;
}

.business-display-navMenuItem:hover {
  background-color: #eaecf0;
}

.business-display-navMenuItem2 {
  align-self: stretch;
  padding: 12px 12px 12px 0;
  border-radius: 8px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  height: max-content;
}

.business-display-navMenuItem2-disabled {
  background-color: #eaecf0;
  cursor: default;
}

.business-display-navMenuItem2:hover {
  background-color: #eaecf0;
}

.business-display-navMenuItem3 {
  align-self: stretch;
  padding: 12px 12px 12px 0;
  border-radius: 8px;
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  height: max-content;
}

.business-display-navMenuItem3:hover {
  background-color: #eaecf0;
}

.business-display-iconWrapper {
  width: 24px;
  height: 24px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.business-display-icon {
  width: 16px;
  height: 20px;
  /* border: 2px solid #7F56D9; */
}

.business-display-itemContent {
  flex: 1 1 0;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
}

.business-display-textAndSupportingText {
  align-self: stretch;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.business-display-textAndBadge {
  align-self: stretch;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.business-display-text {
  color: #101828;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
}

.business-display-supportingText {
  align-self: stretch;
  color: #475467;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
}