.slide-out-component-slide-out-menu {
  
    
    width: 400px;
    height: max-content; 
   
  
  
  
    overflow-y: auto; 
    scrollbar-width: none;

    flex-direction: row;
  justify-content: flex-end;
  /* padding: 0 0 0 40px; */
  line-height: normal;
  letter-spacing: normal;

  border-radius: 10px;
  background-color: #fff;
  max-width: 100%;
  /* overflow: hidden; */
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;

  position: absolute;

  z-index: 3;
  right: 3px;
  /* transform: translateX(100%);  */
  transition: transform 0.3s ease-in-out;
}

.slide-out-component-slide-out-menu::-webkit-scrollbar{
    display: none;
}

.slide-out-component-slide-out-menu.open {
    right: 0; /* Slide in */
}

.slide-out-component-featured-icon2 {
    height: 40px;
    width: 40px;
    position: relative;
    border-radius: 8px;
  }
  .slide-out-component-supporting-text91,
  .slide-out-component-title9 {
    align-self: stretch;
    position: relative;
    white-space: nowrap;
  }
  .slide-out-component-title9 {
    text-decoration: none;
    line-height: 30px;
    font-weight: 600;
    color: inherit;
  }
  .slide-out-component-supporting-text91 {
    font-size: 14px;
    line-height: 20px;
    color: #475467;
  }
  .slide-out-component-content71,
  .slide-out-component-text-and-supporting-text26 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
  }
  .slide-out-component-content71 {
    margin: 0 !important;
    position: absolute;
    top: 24px;
    left: 24px;
    flex-direction: row;
    gap: 16px;
  }
  .slide-out-component-button-close-x6,
  .slide-out-component-slide-out-menu-header {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
  }
  .slide-out-component-button-close-x6 {
    height: 20px;
    margin: 0 !important;
    position: absolute;
    top: 22px;
    right: 26px;
    border-radius: 8px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    z-index: 1;
  }
  .slide-out-component-slide-out-menu-header {
    align-self: stretch;
    height: 78px;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 24px;
    position: relative;
    gap: 8px;
    text-align: left;
    font-size: 20px;
    color: #101828;
    font-family: Inter;
  }
  .slide-out-component-label9 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 67px;
  }
  .slide-out-component-content72 {
    width: 100%;
    border: 0;
    outline: 0;
    background-color: transparent;
    height: 24px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-family: Inter;
    font-size: 16px;
    color: #101828;
    min-width: 194px;
    max-width: 100%;
  }
  .slide-out-component-help-icon2 {
    height: 16px;
    width: 16px;
    position: relative;
    display: none;
  }
  .slide-out-component-input6 {
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 13px;
    gap: 8px;
    max-width: 100%;
  }
  .slide-out-component-hint-text6 {
    width: 320px;
    position: relative;
    line-height: 20px;
    color: #475467;
    display: none;
  }
  .slide-out-component-input-field {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    max-width: 100%;
  }
  .slide-out-component-label10 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 94px;
  }
  .slide-out-component-mail-01-icon,
  .slide-out-component-text274 {
    position: relative;
    overflow: hidden;
  }
  .slide-out-component-mail-01-icon {
    height: 20px;
    width: 20px;
    flex-shrink: 0;
  }
  .slide-out-component-text274 {
    width: calc(100% - 36px);
    border: 0;
    outline: 0;
    font-family: Inter;
    font-size: 16px;
    background-color: transparent;
    height: 24px;
    flex: 1;
    line-height: 24px;
    color: #101828;
    text-align: left;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 163px;
    padding: 0;
  }
  .slide-out-component-content73 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }
  .slide-out-component-help-icon3 {
    height: 16px;
    width: 16px;
    position: relative;
  }
  .slide-out-component-form,
  .slide-out-component-input-field1,
  .slide-out-component-input7 {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
  }
  .slide-out-component-input7 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    align-items: center;
    padding: 8px 13px;
    gap: 8px;
  }
  .slide-out-component-form,
  .slide-out-component-input-field1 {
    flex-direction: column;
  }
  .slide-out-component-input-field1 {
    align-items: flex-start;
    gap: 6px;
  }
  .slide-out-component-form {
    align-items: flex-end;
  }
  .slide-out-component-label11,
  .slide-out-component-label12 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 108px;
  }
  .slide-out-component-label12 {
    min-width: 69px;
  }
  .slide-out-component-section2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    max-width: 100%;
  }
  .slide-out-component-divider12,
  .slide-out-component-section-container {
    align-self: stretch;
    max-width: 100%;
  }
  .slide-out-component-section-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 24px;
    box-sizing: border-box;
  }
  .slide-out-component-divider12 {
    flex: 1;
    position: relative;
    background-color: #eaecf0;
  }
  .slide-out-component-divider-wrapper,
  .slide-out-component-slide-out-menu-header-parent {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .slide-out-component-divider-wrapper {
    height: 1px;
    flex-direction: row;
    padding: 0 24px;
    box-sizing: border-box;
  }
  .slide-out-component-slide-out-menu-header-parent {
    flex-direction: column;
    gap: 24px;
    text-align: left;
    font-size: 14px;
    color: #344054;
    font-family: Inter;
  }
  .slide-out-component-placeholder-icon91 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
    min-height: 20px;
  }
  .slide-out-component-text275 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 48px;
  }
  .slide-out-component-buttonsbutton49,
  .slide-out-component-text-padding51 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  .slide-out-component-buttonsbutton49 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 8px 13px;
    background-color: #fff;
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    gap: 4px;
  }
  .slide-out-component-text276 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 55px;
  }
  .slide-out-component-actions19,
  .slide-out-component-buttonsbutton50 {
    display: flex;
    flex-direction: row;
  }
  .slide-out-component-buttonsbutton50 {
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 13px;
    background-color: #7e56d9;
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  .slide-out-component-actions19 {
    width: 179px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
  }
  .slide-out-component-content76,
  .slide-out-component-footer2 {
    align-self: stretch;
    display: flex;
    align-items: center;
  }
  .slide-out-component-content76 {
    flex-direction: row;
    justify-content: flex-end;
    padding: 16px 24px;
  }
  .slide-out-component-footer2 {
    border-top: 1px solid #eaecf0;
    flex-direction: column;
    justify-content: flex-start;
  }
  .slide-out-component-panel {
    /* background-color: #fff;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
 
    width: 400px;
    box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08),
      0 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-left: 1px solid #eaecf0;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: flex-start;
    gap: 433px; */

    background-color: #fff;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
  
    width: 400px;
    box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08),
      0 8px 8px -4px rgba(16, 24, 40, 0.03);
    /* border-left: 1px solid #eaecf0; */
    border: 1px solid #7e56d9;
    border-radius: 8px;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: flex-start;
  }
  /* .slide-out-component-slide-out-menu {
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 0 0 40px;
    line-height: normal;
    letter-spacing: normal;

    background-color: #fff;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
  } */
  @media screen and (max-width: 400px) {
    .slide-out-component-panel {
      gap: 216px;
    }
  }
  