.review-description-component-input-field {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 848px;
  flex-direction: column;
  gap: 6px;
  z-index: 2;
  color: #344054;
  max-width: 100%;
  padding-bottom: 30px;
}

.review-description-component-input-with-label1{
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.review-description-component-label2 {
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 30px;
    position: relative;
}

.review-description-component-content6 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;

  max-width: 100%;
}

.review-description-component-textarea-input-field {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    /* width: 512px; */
    flex-direction: column;
    padding: 0;
    box-sizing: border-box;
    gap: 6px;
    /* min-width: 480px;
    max-width: 512px; */
  }

  .review-description-component-input-field-title,
.review-description-component-input-field-review {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: none; /* Prevent resizing for the textarea */
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  font-family: inherit;
}

.review-description-component-input-field-review{
    height: 100%;
    scrollbar-width: none;
}

.review-description-component-input-field-title:focus,
.review-description-component-input-field-review:focus {
    border-color: #7e56d9; 
    outline: none;
    box-shadow: 0 0 5px rgba(126, 86, 217, 0.8); 
}

.review-description-component-hint-text1 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    color: #475467;
}

.review-description-component-hint-text1.warning {
    color: #e74c3c; 
  }

.review-description-component-input-field1 {
    width: 848px;
    /* padding: 0 0 54px; */
    /* min-height: 208px; */
    max-width: 100%;
    z-index: 2;
    color: #344054;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    gap: 6px;
  }

.review-description-component-label4{
  position: relative;
  display: inline-block;

  line-height: 20px;
  font-weight: 500;
  min-width: 49px;
}

.review-description-component-textarea-input-field1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    gap: 6px;
       width: 100%;
    height: 128px;
    /* width: 512px;
    padding: 0;
    min-width: 480px;
    max-width: 512px; */
  }

  .review-description-component-hint-text3 {
    width: 848px;
    position: relative;
    line-height: 20px;
    color: #475467;
    display: inline-block;
    max-width: 166%;
    flex-shrink: 0;
  }

  .review-description-component-hint-text3.warning {
    color: #e74c3c; 
  }

  .review-description-component-hint-text{
    display: none;
  }

@media screen and (max-width: 750px) {
    .review-description-component-content6 {
        gap: 16px;
      }

      .review-description-component-textarea-input-field {
        max-width: 100%;
      }

      .review-description-component-textarea-input-field1 {
        max-width: 100%;
      }
}