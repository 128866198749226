@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.sign-up-gerator-04-1-icon {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}

.sign-up-logo {
  width: 121px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-image: url('../../icons/Gerator/gerator04-1@2x.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.sign-up-star-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}

.sign-up-stars,
.sign-up-testimonial {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.sign-up-stars {
  justify-content: flex-start;
  gap: 4px;
}

.sign-up-testimonial {
  align-self: stretch;
  justify-content: center;
  padding: 0 20px;
}

.sign-up-text {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 38px;
  font-weight: 500;
  font-family: inherit;
}

.sign-up-contrast-border {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
}

.sign-up-avatar-company-icon {
  height: 20px;
  width: 20px;
  border-radius: 9999px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 1;
}

.sign-up-avatar {
  height: 64px;
  width: 64px;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 46px 0 0;
  box-sizing: border-box;
  position: relative;
  background-image: url('../../icons/Gerator/gerator04-1@2x.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.sign-up-reviewer-name,
.sign-up-supporting-text {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.sign-up-supporting-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #475467;
}

.sign-up-reviewer-details,
.sign-up-reviewer-info {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.sign-up-reviewer-info {
  gap: 16px;
  font-size: 16px;
  margin-top: 50px;
}

.sign-up-chevron-left-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.sign-up-buttonsbutton {
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.sign-up-pagination-dot-indicator,
.sign-up-pagination-dot-indicator1,
.sign-up-pagination-dot-indicator4 {
  height: 10px;
  width: 10px;
  position: relative;
  border-radius: 9999px;
  background-color: #7e56d9;
  overflow: hidden;
  flex-shrink: 0;
}

.sign-up-pagination-dot-indicator1,
.sign-up-pagination-dot-indicator4 {
  background-color: #eaecf0;
}

.sign-up-pagination-dot-indicator4 {
  display: none;
}

.sign-up-pagination,
.sign-up-pagination-dot-group {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.sign-up-pagination-dot-group {
  flex-direction: row;
  gap: 16px;
}

.sign-up-pagination {
  flex-direction: column;
  padding: 13px 0 0;
}

.sign-up-navigation-controls {
  width: 288px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

.sign-up-content {
  width: 560px;
  flex-direction: column;
  justify-content: center;
  left: 25px;
  position: relative;
  max-width: 560px;
  height: 60vh;
  display: flex;
  align-items: flex-start;
}

.sign-up-content-wrapper,
.sign-up-logo-container {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.sign-up-content-wrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
}

.sign-up-logo-container {
  width: 608px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 219px;
}

.sign-up-text1 {
  position: relative;
  line-height: 20px;
}

.sign-up-hyperlink {
  position: relative;
  line-height: 20px;
  color: #475467;
}

.sign-up-mail-01-icon {
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.sign-up-email-link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 0 0;
}

.sign-up-helpcentergeratorcom {
  position: relative;
  line-height: 20px;
  white-space: nowrap;
}

.sign-up-footer-links {
  flex-direction: row;
  justify-content: flex-start;
  gap: 7px;
}

.sign-up-footer,
.sign-up-footer-links,
.sign-up-section {
  display: flex;
  align-items: flex-start;
}

.sign-up-footer {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: 290px;
  text-align: left;
  font-size: 14px;
  color: #475467;
  margin-top: 160px;

  /* align-self: stretch;
    align-items: center;
    
    gap: 32px;
  
    width: 100%;
    text-align: left;
    font-size: 14px;
    color: #475467;
    font-family: Inter; */
  }
  .sign-up-section {
    flex: 0.9111;
    background-color: #f9fafb;
    flex-direction: column;
    justify-content: flex-start;
    padding: 32px;
    box-sizing: border-box;
    /* gap: 231px; */
    min-width: 560px;
    max-width: 100%;
    height: max-content;
    
  }
  .sign-up-logo-title,
  .sign-up-supporting-text1 {
    align-self: stretch;
    position: relative;
  }
  .sign-up-logo-title {
    margin: 0;
    font-size: inherit;
    line-height: 38px;
    font-weight: 600;
    font-family: inherit;
    white-space: nowrap;
  }
  .sign-up-supporting-text1 {
    font-size: 16px;
    line-height: 24px;
    color: #475467;
  }
  .sign-up-text-and-supporting-text {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
  }
  .sign-up-label {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 47px;
  }
  .sign-up-content3 {
    width: 100%;
    border: 0;
    outline: 0;
    background-color: transparent;
    height: 24px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-family: Inter;
    font-size: 16px;
    color: #667085;
    min-width: 199px;
    max-width: 100%;
  }
  .sign-up-help-icon {
    height: 16px;
    width: 16px;
    position: relative;
    display: none;
  }
  .sign-up-input {
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 13px;
    gap: 8px;
    max-width: 100%;
  }
  .sign-up-hint-text {
    width: 320px;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-family: Inter;
    color: #475467;
    text-align: left;
    /* display: none; */
  }
  .sign-up-input-field {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    max-width: 100%;
  }
  .sign-up-label1 {
    font-weight: 500;
    color: #344054;
    display: inline-block;
    min-width: 44px;
  }
  .sign-up-hint-text2,
  .sign-up-label1,
  .sign-up-label2 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-family: Inter;
    text-align: left;
  }
  .sign-up-label2 {
    font-weight: 500;
    color: #344054;
    display: inline-block;
    min-width: 73px;
  }
  .sign-up-hint-text2 {
    align-self: stretch;
    color: #475467;
  }
  .sign-up-form {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    max-width: 100%;
  }
  .sign-up-placeholder-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .sign-up-signup-button-label {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 89px;
    white-space: nowrap;
  }
  .sign-up-buttonsbutton2,
  .sign-up-text-padding {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  .sign-up-buttonsbutton2 {
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 20px;
    background-color: #7e56d9;
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    gap: 6px;
  }
  .sign-up-buttonsbutton2:hover{
 background-color: #6941c6;
  }

  .sign-up-social-icon {
    height: 24px;
    width: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 24px;
  }
  .sign-up-text2 {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
  }
  .sign-up-social-button {
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 20px;
    gap: 12px;
    white-space: nowrap;
  }
  .sign-up-social-icon1 {
    height: 24px;
    width: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .sign-up-social-button1 {
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    gap: 12px;
    white-space: nowrap;
  }
  .sign-up-text5 {
    height: 24px;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
  }
  .sign-up-actions,
  .sign-up-content2,
  .sign-up-social-button-groups,
  .sign-up-social-button3 {
    align-self: stretch;
    align-items: center;
    justify-content: center;
  }
  .sign-up-social-button3 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    display: none;
    flex-direction: row;
    padding: 8px 16px;
    gap: 11px;
    white-space: nowrap;
  }
  .sign-up-actions,
  .sign-up-content2,
  .sign-up-social-button-groups {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .sign-up-actions,
  .sign-up-content2 {
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
  }
  .sign-up-content2 {
    margin: 0;
    border-radius: 12px;
    align-items: center;
    gap: 24px;
    max-width: 100%;
  }
  .sign-up-placeholder-icon2 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
    min-height: 20px;
  }
  .sign-up-text7 {
    text-decoration: none;
    position: relative;
    line-height: 20px;
    font-weight: 600;
    color: inherit;
    display: inline-block;
    min-width: 41px;
    white-space: nowrap;
  }
  .sign-up-buttonsbutton3,
  .sign-up-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .sign-up-buttonsbutton3 {
    overflow: hidden;
    align-items: center;
    gap: 6px;
    color: #6941c6;
  }
  .sign-up-row {
    align-items: flex-start;
    padding: 0 72px;
    gap: 4px;
    font-size: 14px;
    color: #475467;
  }
  .sign-up-container,
  .sign-up-content1,
  .sign-up-section1 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .sign-up-content1 {
    width: 360px;
    justify-content: flex-start;
    gap: 32px;
    max-width: 360px;
  }
  .sign-up-container,
  .sign-up-section1 {
    box-sizing: border-box;
    max-width: 100%;
  }
  .sign-up-container {
    align-self: stretch;
    justify-content: flex-start;
    padding: 0 20px;
  }
  .sign-up-section1 {
    flex: 1;
    justify-content: center;
    padding: 129px 0 0 0;
    min-width: 560px;
    text-align: left;
    height: max-content;
  }
  .sign-up-sign-up-desktop {
    width: 100%;
    position: relative;
    background-color: #fff;
 
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    /* min-height: 98.5vh; */
    height: max-content;
    line-height: normal;
    letter-spacing: normal;
    row-gap: 20px;
    text-align: center;
    font-size: 30px;
    color: #101828;
    font-family: Inter;
  }
  @media screen and (max-width: 1125px) {
    .sign-up-section,
    .sign-up-section1 {
      flex: 1;
    }
    .sign-up-sign-up-desktop {
      flex-wrap: wrap;
    }

  .sign-up-section {
    height: fit-content;

    gap: 0px;

  }

  .sign-up-content {
    left: 195px;
    top: 50px;
    height: fit-content;
    justify-content: center;
  }

  .sign-up-section1 {
    justify-content: flex-start;
    padding: 100px 0px 100px 0px;
    height: fit-content;
  }


}

/* @media screen and (max-width: 1050px) {
    .sign-up-text {
      font-size: 24px;
      line-height: 30px;
    }
    .sign-up-section {
      padding-top: 21px;
      padding-bottom: 21px;
      box-sizing: border-box;
    }
    .sign-up-logo-title {
      font-size: 24px;
      line-height: 30px;
    }
    .sign-up-section1 {
      padding-top: 129px;
      padding-bottom: 129px;
      box-sizing: border-box;
    }
  } */
@media screen and (max-width: 780px) {
  .sign-up-content {
    display: none;

  }

  .sign-up-logo-container {
    gap: 109px;
  }

  .sign-up-footer {
    display: none;
  }

  .sign-up-section {
    gap: 115px;
    background-color: white;
  }

  .sign-up-section,
  .sign-up-section1 {
    min-width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .sign-up-text {
    font-size: 18px;
    line-height: 23px;
  }

  .sign-up-logo-container {
    gap: 55px;
  }

  .sign-up-section {
    gap: 58px;
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
  }

  .sign-up-logo-title {
    font-size: 18px;
    line-height: 23px;
  }

  .sign-up-row {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .sign-up-content1 {
    gap: 16px;
  }

  .sign-up-section1 {
    padding-top: 84px;
    padding-bottom: 84px;
    box-sizing: border-box;
  }
}

.error {
  color: red;

  font-size: 15px;
}