@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

.add-wanted-device-forms-add-wanted-device-child,
.add-wanted-device-frame-child {
  position: relative;
  background-color: #fff;
  display: none;
}
.add-wanted-device-forms-add-wanted-device-child {
  align-self: stretch;
  height: 4717px;
}
.add-wanted-device-frame-child {
  height: 72px;
  width: 1440px;
  max-width: 100%;
}
.add-wanted-device-gerator-04-1-icon {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}
.add-wanted-device-logo,
.add-wanted-device-logo-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.add-wanted-device-logo {
  align-self: stretch;
  height: 32px;
  flex-direction: row;
  background-image: url("../../../icons/Gerator/add_form/gerator04-1@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}
.add-wanted-device-logo-wrapper {
  width: 121px;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
}
.add-wanted-device-bar-chart-01-icon,
.add-wanted-device-dot-icon {
  height: 10px;
  width: 10px;
  position: relative;
  display: none;
}
.add-wanted-device-bar-chart-01-icon {
  height: 24px;
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.add-wanted-device-text {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 30px;
}

.add-wanted-device-text1 {
  align-self: stretch;
  position: relative;
  line-height: 18px;
  font-weight: 500;
}
.add-wanted-device-badge {
  height: 22px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
}

.add-wanted-device-nav-item-base {
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  gap: 8px;
}
.add-wanted-device-text2,
.add-wanted-device-text3 {
  position: relative;
  font-family: Inter;
}
.add-wanted-device-text2 {
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #18222f;
  text-align: left;
  display: inline-block;
  min-width: 29px;
}
.add-wanted-device-text3 {
  align-self: stretch;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #344054;
  text-align: center;
}
.add-wanted-device-badge1,
.add-wanted-device-nav-item-base1 {
  background-color: #f9fafb;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.add-wanted-device-badge1 {
  height: 22px;
  border-radius: 9999px;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  padding: 2px 7px;
}
.add-wanted-device-nav-item-base1 {
  cursor: pointer;
  border: 0;
  padding: 8px 12px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  gap: 8px;
}
.add-wanted-device-text4 {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 38px;
}
.add-wanted-device-navigation,
.add-wanted-device-navigation-wrapper {
  display: flex;
  justify-content: flex-start;
}
.add-wanted-device-navigation {
  margin: 0;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  z-index: 1;
  text-align: left;
  font-size: 16px;
  color: #344054;
  font-family: Inter;
}
.add-wanted-device-navigation-wrapper {
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
}
.add-wanted-device-label {
  width: 47px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}
.add-wanted-device-search-lg-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.add-wanted-device-input,
.add-wanted-device-text6 {
  overflow: hidden;
}
.add-wanted-device-text6 {
  text-decoration: none;
  flex: 1;
  position: relative;
  line-height: 24px;
  color: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.add-wanted-device-input {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 13px;
  font-size: 16px;
  color: #667085;
}
.add-wanted-device-frame-group,
.add-wanted-device-frame-parent,
.add-wanted-device-input-with-label {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.add-wanted-device-input-with-label {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  z-index: 1;
}
.add-wanted-device-frame-group,
.add-wanted-device-frame-parent {
  flex-direction: row;
  max-width: 100%;
}
.add-wanted-device-frame-group {
  flex: 1;
  gap: 38px;
}
.add-wanted-device-frame-parent {
  width: 754px;
  gap: 70px;
}
.add-wanted-device-icon {
  height: 19px;
  width: 19px;
  position: relative;
}
.add-wanted-device-nav-item-button {
  width: 40px;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  z-index: 1;
}
.add-wanted-device-nav-item-button-child {
  height: 18.3px;
  width: 14.6px;
  position: relative;
}
.add-wanted-device-avatar-button1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3px 0 0;
}
.add-wanted-device-contrast-border {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  display: none;
}
.add-wanted-device-avatar {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../../icons/Gerator//add_form/avatar@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}
.add-wanted-device-avatar-button-parent,
.add-wanted-device-rectangle-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}
.add-wanted-device-rectangle-parent {
  align-self: stretch;
  background-color: #fff;
  justify-content: space-between;
  padding: 14px 64px 14px 31px;
  box-sizing: border-box;
  top: 0;
  z-index: 99;
  position: sticky;
  max-width: 100%;
  gap: 20px;
  text-align: left;
  font-size: 14px;
  color: #344054;
  font-family: Inter;
}
.add-wanted-device-divider-icon
{
  position: relative;
  overflow: hidden;
}
.add-wanted-device-divider-icon {
  align-self: stretch;
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
}














.add-wanted-device-buttonsbutton {
  margin: 0 !important;
  position: absolute;
  bottom: 12px;
  left: 218px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  z-index: 1;
}

.add-wanted-device-arrow-narrow-left-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
  z-index: 1;
}

.add-wanted-device-step-icon-base {
  width: 24px;
  height: 24px;
  position: relative;
  border-radius: 9999px;
  overflow: hidden;
  flex-shrink: 0;
}
.add-wanted-device-connector {
  align-self: stretch;
  width: 2px;
  position: relative;
  border-radius: 2px;
  background-color: #eaecf0;
}

.add-wanted-device-supporting-text {
  align-self: stretch;
  position: relative;
  font-size: 12px;
  line-height: 20px;
  color: #7e56d9;
}
.add-wanted-device-step-base,
.add-wanted-device-text-and-supporting-text {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.add-wanted-device-text-and-supporting-text {
  flex-direction: column;
  padding: 2px 0 24px;
}
.add-wanted-device-step-base {
  align-self: stretch;
  flex-direction: row;
  gap: 12px;
  color: #6941c6;
}
.add-wanted-device-step-icon-base1 {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 9999px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.add-wanted-device-step-icon
 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.add-wanted-device-step-icon {
  align-self: stretch;
  flex-direction: row;
  gap: 12px;
}
.add-wanted-device-connector1 {
  width: 2px;
  flex: 1;
  position: relative;
  border-radius: 2px;
  background-color: #eaecf0;
}

.add-wanted-device-supporting-text1 {
  flex: 1;
  position: relative;
  line-height: 20px;
}
.add-wanted-device-form,
.add-wanted-device-step-base1,

.add-wanted-device-steps {
  align-self: stretch;
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.add-wanted-device-form,
.add-wanted-device-step-base1,
.add-wanted-device-steps {
  flex-direction: column;
}
.add-wanted-device-step-base1 {
  align-items: flex-end;
  padding: 0 0 4px;
}
.add-wanted-device-form,
.add-wanted-device-steps {
  align-items: flex-start;
}
.add-wanted-device-steps {
  gap: 38px;
  z-index: 1;
  font-size: 14px;
  color: #344054;
}
.add-wanted-device-form {
  padding: 0 0 226px;
  gap: 16px;
}
.add-wanted-device-text10 {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  z-index: 2;
  margin-left: -208px;
}

.add-wanted-device-arrow-narrow-left-icon1,
.add-wanted-device-arrow-narrow-left-icon2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}
.add-wanted-device-arrow-narrow-left-icon2 {
  z-index: 2;
}
.add-wanted-device-arrow-narrow-left-parent {
  height: 24px;
  width: 24px;
  position: relative;
}
.add-wanted-device-connector3,
.add-wanted-device-step-icon-base3 {
  position: relative;
  display: none;
}
.add-wanted-device-step-icon-base3 {
  width: 24px;
  height: 24px;
  border-radius: 9999px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.add-wanted-device-connector3 {
  width: 2px;
  height: 54px;
  border-radius: 2px;
  background-color: #7e56d9;
  z-index: 2;
}
.add-wanted-device-step-two-number,
.add-wanted-device-text11 {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.add-wanted-device-text11 {
  margin-left: -214px;
}
.add-wanted-device-step-two-title {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.add-wanted-device-supporting-text3,
.add-wanted-device-supporting-text4 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}
.add-wanted-device-supporting-text4 {
  margin-top: -40px;
}
.add-wanted-device-step-base3,
.add-wanted-device-step-two-description {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.add-wanted-device-step-base3 {
  align-self: stretch;
  flex-direction: row;
}

.add-wanted-device-step-base5 {
  flex-direction: row;
  gap: 12px;
}
.add-wanted-device-step-base5,
.add-wanted-device-step-base6,
.add-wanted-device-step-three {
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.add-wanted-device-step-base6 {
  flex-direction: row;
  gap: 12px;
  margin-top: -86px;
}
.add-wanted-device-step-three {
  flex-direction: column;
  color: #6941c6;
}
.add-wanted-device-connector7 {
  width: 2px;
  height: 54px;
  position: relative;
  border-radius: 2px;
  background-color: #eaecf0;
  display: none;
  z-index: 2;
}
.add-wanted-device-step-two,
.add-wanted-device-step-two-details {
  align-self: stretch;
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.add-wanted-device-step-two-details {
  flex-direction: column;
  gap: 38px;
  z-index: 2;
}
.add-wanted-device-step-two {
  flex-direction: row;
  z-index: 1;
  font-size: 14px;
  color: #344054;
}
.add-wanted-device-form-parent
 {
  align-self: stretch;
  flex-direction: column;
}

.add-wanted-device-form-parent {
  flex: 1;
  gap: 700px;
}
.add-wanted-device-breadcrumb-button-base,
.add-wanted-device-form-parent,
.add-wanted-device-progress-steps {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.add-wanted-device-progress-steps {
  /* height: 2784px; */
  height: max-content;
  width: 250px;
  flex-direction: column;
  padding: 36px 0 0;
  box-sizing: border-box;
}
.add-wanted-device-breadcrumb-button-base {
  border-radius: 6px;
  flex-direction: row;
  padding: 4px;
}



.add-wanted-device-text18 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}








.add-wanted-device-text-padding {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.add-wanted-device-button1,
.add-wanted-device-button2 {
  height: 40px;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  gap: 4px;
}
.add-wanted-device-button1,
.add-wanted-device-button2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: #fff;
  padding: 10px 13px;
}
.add-wanted-device-button1 {
  border: 1px solid #d6bbfb;
  flex-direction: row;
  align-items: center;
  color: #6941c6;
}
.add-wanted-device-button2 {
  border: 1px solid #d0d5dd;
  color: #344054;
}
.add-wanted-device-actions,
.add-wanted-device-button2,
.add-wanted-device-button3 {
  flex-direction: row;
  align-items: center;
}
.add-wanted-device-button3 {
  height: 40px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
  color: #fff;
}

.add-wanted-device-button4,
.add-wanted-device-button5 {
  align-self: stretch;
  border-radius: 8px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 14px;
  gap: 4px;
}
.add-wanted-device-button5 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: #fff;
  border: 1px solid #d6bbfb;
  padding: 10px 13px;
  color: #6941c6;
}








.add-wanted-device-divider-icon1 {
  align-self: stretch;
  max-width: 100%;
}

.add-wanted-device-divider-icon1 {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  display: none;
}



.add-wanted-device-main-child,
.add-wanted-device-main-item {
  width: 1px;
  height: 29px;
  position: relative;
  border-right: 1px solid #000;
  box-sizing: border-box;
  flex-shrink: 0;
}
.add-wanted-device-main-item {
  height: 43px;
}

.add-wanted-device-main {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.add-wanted-device-main {
  height: 149px;
  background-color: #fff;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: 32px 0 0;
  box-sizing: border-box;
  gap: 32px;
  max-width: 100%;
  z-index: 1;
}

.add-wanted-device-divider-icon2 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.add-wanted-device-section-header {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  z-index: 1;
}





.add-wanted-device-dropdown
 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}


.add-wanted-device-hint-text1 {
  width: 320px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: none;
}




.add-wanted-device-label3
 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  text-align: left;
  display: inline-block;
  min-width: 123px;
}

















.add-wanted-device-hint-text9 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: none;
}







.add-wanted-device-divider,
.add-wanted-device-uploads-title {
  align-self: stretch;
  position: relative;
}
.add-wanted-device-divider {
  height: 1px;
  background-color: #eaecf0;
  z-index: 1;
}





.add-wanted-device-text37 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: center;
  display: inline-block;
  min-width: 38px;
}
.add-wanted-device-content24 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.add-wanted-device-x-close-icon {
  width: 12px;
  height: 12px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.add-wanted-device-tag-close-x {
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px;
}
.add-wanted-device-tag {
  width: 66px;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 3px 0 4px;
  gap: 3px;
}
.add-wanted-device-text38 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: center;
  display: inline-block;
  min-width: 54px;
}
.add-wanted-device-tag1,
.add-wanted-device-tags {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.add-wanted-device-tag1 {
  flex: 1;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  justify-content: center;
  padding: 0 3px 0 4px;
  gap: 3px;
}
.add-wanted-device-tags {
  width: 154px;
  justify-content: flex-start;
  gap: 6px;
}
.add-wanted-device-tags-placeholder {
  flex: 1;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 27px;
  max-width: 100%;
}

.add-wanted-device-content23 {
  flex: 1;
  flex-direction: row;
  align-items: center;
}
.add-wanted-device-content23 {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  max-width: 100%;
}







.add-wanted-device-text42 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #475467;
  text-align: left;
  white-space: nowrap;
}

.add-wanted-device-text43 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #475467;
  text-align: left;
}
.add-wanted-device-button8 {
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 14px;
  box-sizing: border-box;
  gap: 4px;
}



.add-wanted-device-section-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.add-wanted-device-section-footer {
  justify-content: flex-end;
  max-width: 100%;
}


.add-wanted-device-section-footer {
  align-self: stretch;
  gap: 20px;
}
.add-wanted-device-section-footer {
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1;
}
.add-wanted-device-arrows-down-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}
.add-wanted-device-text45 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 72px;
}
.add-wanted-device-buttonsbutton-wrapper,
.add-wanted-device-buttonsbutton2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.add-wanted-device-buttonsbutton2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  justify-content: flex-start;
  padding: 6px 12px;
  gap: 6px;
  z-index: 1;
}
.add-wanted-device-buttonsbutton-wrapper {
  align-self: stretch;
  justify-content: center;
  padding: 0 21px 0 20px;
}
.add-wanted-device-label18 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}
.add-wanted-device-label18 {
  max-width: 100%;
}
























.add-wanted-device-file-button-labels {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 67px;
}

.add-wanted-device-text48 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 49px;
}








.add-wanted-device-text53 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
}

.add-wanted-device-text53 {
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  display: inline-block;
  min-width: 34px;
}



.add-wanted-device-label24 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  display: inline-block;
  min-width: 66px;
}
.add-wanted-device-label24,
.add-wanted-device-label25,
.add-wanted-device-text56 {
  font-family: Inter;
  position: relative;
  text-align: left;
}
.add-wanted-device-label25 {
  width: 77px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  display: none;
}
.add-wanted-device-text56 {
  width: 100%;
  border: 0;
  outline: 0;
  font-size: 16px;
  background-color: transparent;
  height: 78px;
  flex: 1;
  line-height: 24px;
  color: #101828;
  display: inline-block;
  min-width: 250px;
  max-width: 100%;
  padding: 0;
}
.add-wanted-device-input-with-label23,
.add-wanted-device-input21 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.add-wanted-device-input21 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  padding: 10px 13px;
  max-width: 100%;
}
.add-wanted-device-input-with-label23 {
  width: 1014px;
  flex-direction: column;
  gap: 6px;
  max-width: 199%;
  flex-shrink: 0;
}
.add-wanted-device-hint-text22 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
}
.add-wanted-device-content42,
.add-wanted-device-textarea-input-field {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.add-wanted-device-textarea-input-field {
  width: 512px;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  gap: 6px;
  min-width: 480px;
  max-width: 512px;
}
.add-wanted-device-content42 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  max-width: 100%;
}
.add-wanted-device-label26 {
  display: inline-block;
  max-width: 100%;
}
.add-wanted-device-label26,
.add-wanted-device-label28,
.add-wanted-device-label30 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: left;
}
.add-wanted-device-label28 {
  display: inline-block;
  min-width: 83px;
}
.add-wanted-device-label30 {
  min-width: 62px;
}
.add-wanted-device-label30,
.add-wanted-device-label32,
.add-wanted-device-label34 {
  display: inline-block;
}
.add-wanted-device-label32 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: left;
  min-width: 103px;
}
.add-wanted-device-label34 {
  min-width: 81px;
}
.add-wanted-device-label34,
.add-wanted-device-label36 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: left;
}
.add-wanted-device-input-with-label35 {
  border: 1px solid #d0d5dd;
  background-color: #fff;
  height: 102px;
  width: 1014px;
  outline: 0;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 13px;
  font-family: Inter;
  font-size: 16px;
  color: #101828;
  max-width: 199%;
  flex-shrink: 0;
}
.add-wanted-device-label37,
.add-wanted-device-pdf {
  font-family: Inter;
}
.add-wanted-device-label37 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 77px;
  z-index: 1;
}
.add-wanted-device-pdf {
  font-weight: 600;
  color: #475467;
}
.add-wanted-device-supporting-text16 {
  align-self: stretch;
  position: relative;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
.add-wanted-device-final-upload-item-content {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 12px;
  max-width: 100%;
}


.add-wanted-device-final-upload-wrapper,
.add-wanted-device-progress-bar3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}
.add-wanted-device-progress-bar3 {
  width: 411px;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 11px;
}
.add-wanted-device-final-upload-wrapper {
  width: 495px;
  flex-direction: column;
  gap: 6px;
}
.add-wanted-device-text67 {
  text-decoration: none;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 55px;
}
.add-wanted-device-button15 {
  cursor: pointer;
  border: 0;
  padding: 10px 14px;
  background-color: #fdb022;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 4px;
  min-width: 75px;
}
.add-wanted-device-text68 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 48px;
}
.add-wanted-device-actions4,
.add-wanted-device-button16 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}
.add-wanted-device-button16 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  justify-content: center;
  gap: 4px;
  min-width: 70px;
}
.add-wanted-device-actions4 {
  justify-content: flex-end;
  flex-wrap: wrap;
  align-content: center;
  padding: 0 0 0 673px;
  gap: 12px;
  max-width: 100%;
}
.add-wanted-device-body,
.add-wanted-device-section-header-parent {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.add-wanted-device-section-header-parent {
  margin: 0;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
}
.add-wanted-device-body {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 31px 0 32px;
  box-sizing: border-box;
}
.add-wanted-device-content5,
.add-wanted-device-progress-container {
  display: flex;
  justify-content: flex-start;
}
.add-wanted-device-content5 {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  gap: 21px;
  max-width: 95%;
  padding-bottom: 40px;
  font-size: 14px;
  color: #475467;
}
.add-wanted-device-progress-container {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  max-width: 100%;
}
.add-wanted-device-supporting-text18,
.add-wanted-device-title1 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  z-index: 1;
}
.add-wanted-device-title1 {
  font-weight: 600;
}
.add-wanted-device-supporting-text18 {
  color: #475467;
  white-space: nowrap;
}
.add-wanted-device-title-container-wrapper {
  width: 222px;
  flex-direction: row;
  padding: 0 4px;
  box-sizing: border-box;
  font-size: 14px;
}
.add-wanted-device-bottom-actions,
.add-wanted-device-content-parent,
.add-wanted-device-progress-container-parent,
.add-wanted-device-title-container-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.add-wanted-device-progress-container-parent {
  flex: 1;
  flex-direction: column;
  /* gap: 393px; */
  max-width: 100%;
}
.add-wanted-device-bottom-actions,
.add-wanted-device-content-parent {
  flex-direction: row;
}
.add-wanted-device-bottom-actions {
  flex: 1;
  position: relative;
  max-width: calc(100% - 96px);
  text-align: left;
  font-size: 16px;
  color: #101828;
  font-family: Inter;
}
.add-wanted-device-content-parent {
  align-self: stretch;
  gap: 16px;
  max-width: 100%;
  height: 100vh;
  overflow: scroll;
  scrollbar-width: none;
  
}









.add-wanted-device-forms-add-wanted-device {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.add-wanted-device-forms-add-wanted-device {
  background-color: #fff;
  overflow: hidden;
  text-align: left;
  color: #475467;
  font-family: Inter;
  margin-top: 60px;
}

.add-wanted-device-forms-add-wanted-device-custom-margin{
  margin-top: 0px !important;
}

.add-wanted-device-forms-add-wanted-device {
  width: 100%;
  position: relative;
  align-items: flex-start;
  padding: 7px 0 0;
  box-sizing: border-box;
  line-height: normal;
  letter-spacing: normal;
  font-size: 14px;
  max-width: 1524px;
  margin: 0 auto;
}
@media screen and (max-width: 1275px) {
  .add-wanted-device-progress-container-parent {
    gap: 196px;
  }
  
}
@media screen and (max-width: 1100px) {
  .add-wanted-device-progress-steps {
    display: none;
  }
 
  .add-wanted-device-main {
    height: auto;
  }
  .add-wanted-device-content23 {
    flex-wrap: wrap;
  }
 
  .add-wanted-device-actions4 {
    box-sizing: border-box;
  }
  
  .add-wanted-device-actions4 {
    padding-left: 336px;
  }
  .add-wanted-device-content5 {
    max-width: 100%;
  }
  .add-wanted-device-progress-container {
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 750px) {
  .add-wanted-device-frame-group {
    gap: 19px;
  }
  .add-wanted-device-frame-parent {
    gap: 35px;
  }
  .add-wanted-device-rectangle-parent {
    padding-right: 32px;
    box-sizing: border-box;
  }
  .add-wanted-device-form {
    padding-bottom: 147px;
    box-sizing: border-box;
  }
 
  .add-wanted-device-main {
    gap: 16px;
  }
  .add-wanted-device-content22,
  .add-wanted-device-content39 {
    min-width: 100%;
  }
  
  .add-wanted-device-textarea-input-field {
    max-width: 100%;
  }
  .add-wanted-device-content42 {
    gap: 16px;
  }
  .add-wanted-device-actions4 {
    padding-left: 168px;
    box-sizing: border-box;
  }
  .add-wanted-device-progress-container-parent {
    gap: 98px;
  }
  
  
}
@media screen and (max-width: 450px) {
  .add-wanted-device-frame-parent {
    gap: 17px;
  }
  .add-wanted-device-step-two,
  .add-wanted-device-step-two-details,
  .add-wanted-device-steps {
    gap: 19px;
  }
  .add-wanted-device-form-parent {
    gap: 350px;
  }
  
  .add-wanted-device-action,
  .add-wanted-device-first-upload-item-content,
  .add-wanted-device-input1,
  .add-wanted-device-input10,
  .add-wanted-device-input9,
  .add-wanted-device-progress-bar {
    flex-wrap: wrap;
  }
  
  .add-wanted-device-actions4 {
    padding-left: 20px;
    box-sizing: border-box;
  }
  .add-wanted-device-progress-container-parent {
    gap: 49px;
  }
  
}
