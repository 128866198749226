.beacon-spare-component-beacon-content-items-admin {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    
    z-index: 0;
    box-sizing: border-box;

    
    flex-direction: row;
    /* padding: 0 30px 24px 32px; */
    padding-bottom: 24px;
    font-size: 18px;
}
.admin-dashboard-wanted-spare-component-listing-container {
    box-sizing: border-box;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: initial;
    padding: 0 29px;
    align-items: flex-start;
    width: 100%;
    display: flex;
    /* max-height: 95vh; */
    height: max-content;
    /* overflow-y: auto; */
    overflow-y: scroll;
    /* Enable vertical scrolling */
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
    gap: 50px;
}


.admin-dashboard-wanted-spare-component-listing-container::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Opera */
}


.admin-dashboard-wanted-spare-component-main-buttons-group {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.admin-dashboard-wanted-spare-component-dashboard-content-items {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    /* max-width: 100%; */
    width: 100%;
    z-index: 0;
    box-sizing: border-box;

    align-self: stretch;
    flex-direction: row;
    /* padding: 0 30px 24px 32px; */
    font-size: 18px;
}

.admin-dashboard-wanted-spare-component-dashboard-divider-container1 {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
    position: relative;
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    padding: 12px 13px 22px 23px;
    gap: 24.0px;
    z-index: 1;
}

.admin-dashboard-wanted-spare-component-dashboard-divider-container-child {
    width: 1016px;
    height: 493px;
    position: relative;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: none;
    max-width: 100%;
}


.admin-dashboard-wanted-spare-component-dashboard-rectangle-parent5 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 100%;

    align-self: stretch;
    align-items: flex-end;
    gap: 24px;
}

.admin-dashboard-wanted-spare-component-dashboard-frame-child7 {
    top: 0;

    height: 148px;
    width: 148px;
    border-radius: 12px;
    object-fit: cover;
}

.admin-dashboard-wanted-spare-component-dashboard-frame-parent1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 100%;

    flex: 1;
    align-items: flex-start;
    gap: 15px;
    min-width: 524px;
}

.admin-dashboard-wanted-spare-component-dashboard-action-fields {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;

   flex-direction: column;
    flex: 1;
    padding: 10px 0 0;
    box-sizing: border-box;
}

.admin-dashboard-wanted-spare-component-dashboard-input-elements {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;

    flex-direction: column;
    align-items: flex-start;

    align-self: stretch;
    gap: 14px;
}

.admin-dashboard-wanted-spare-component-dashboard-text78 {
    position: relative;
    z-index: 2;
    width: 640px;
    line-height: 28px;
    font-weight: 600;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: inherit !important;
}

.admin-dashboard-wanted-spare-component-dashboard-text78:hover {
    text-decoration: underline;
}

.admin-dashboard-wanted-spare-component-dashboard-frame-parent21 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    width: 615px;
    align-items: flex-end;
    gap: 6.0px;
    max-width: 100%;
    text-align: center;
    font-size: 14px;
    color: #475467;
}

.admin-dashboard-wanted-spare-component-dashboard-badge-item-parent {
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    /* min-width: 114px; */
    color: #da2d20;
}

.admin-dashboard-wanted-spare-component-dashboard-badge-parent {
    display: flex;
    align-items: flex-start;
    gap: 18px;

    flex-direction: row;
    justify-content: flex-start;
}

.admin-dashboard-wanted-spare-component-dashboard-badge34 {

    width: 69px;
    border-radius: 9999px;
    background-color: #fffdfd;
    border: 1px solid #da2d20;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 8px;
    gap: 6px;
    mix-blend-mode: normal;
}

.admin-dashboard-wanted-spare-component-dashboard-dot-icon10 {
    height: 8px;
    width: 8px;
    position: relative;
    display: none;
}

.admin-dashboard-wanted-spare-component-dashboard-text86 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 51px;
}

.admin-dashboard-wanted-spare-component-dashboard-supporting-text8 {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    text-align: left;
    display: inline-block;
    min-width: 83px;
    z-index: 2;
}

.admin-dashboard-wanted-spare-component-dashboard-frame-parent4 {
    flex-direction: row;
    justify-content: flex-start;

    display: flex;
    align-items: flex-start;
    gap: 8px;
    text-align: left;
    font-size: 16px;
    color: #fff;
}

.admin-dashboard-wanted-spare-component-dashboard-end-label {
    width: 50px;
    border-radius: 8px;
    background-color: #fdb022;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1px 4px;
    box-sizing: border-box;
    z-index: 2;
}

.admin-dashboard-wanted-spare-component-dashboard-search-icon-input {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 41px;
}

.admin-dashboard-wanted-spare-component-dashboard-type-support {
    flex-direction: column;
    padding: 1px 0 0;
    color: #475467;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.admin-dashboard-wanted-spare-component-dashboard-supporting-text9 {
    position: relative;
    line-height: 24px;
    display: inline-block;
    min-width: 117px;
    z-index: 2;
}

.admin-dashboard-wanted-spare-component-dashboard-badge33 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 2;
    color: #344054;

    
}

.admin-dashboard-wanted-spare-component-dashboard-card-badge-number {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    width: 150px;
    max-width: 200px; /* Adjust this value as needed */
    overflow: hidden; /* Hide overflowing content */
    text-overflow: ellipsis; /* Add ellipsis when text overflows */
    white-space: nowrap; /* Prevent text from wrapping */
}

.admin-dashboard-wanted-spare-component-dashboard-frame-parent24 {
    width: 163px;
    display: flex;
    flex-direction: column;
 
    justify-content: flex-start;
    gap: 56px;
    text-align: left;
    font-size: 16px;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    align-items: flex-start;
}

.admin-dashboard-wanted-spare-component-dashboard-location-pin-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 7px;
}

.admin-dashboard-wanted-spare-component-dashboard-search-input {
    display: flex;
    justify-content: flex-start;

    flex-direction: column;
    align-items: flex-start;
    padding: 2px 0 0;
}

.admin-dashboard-wanted-spare-component-dashboard-marker-pin-02-icon2 {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
}

.admin-dashboard-wanted-spare-component-dashboard-location3 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 124px;
    z-index: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 163px;
}

.admin-dashboard-wanted-spare-component-dashboard-frame-wrapper18 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;


    align-self: stretch;
    justify-content: flex-end;
    padding: 0 2px 0 0;
    text-align: center;
    font-size: 14px;
    color: #344054;
}

.admin-dashboard-wanted-spare-component-dashboard-badge-elements-copy {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    flex: 1;
    justify-content: flex-start;
    gap: 8px;
}

.admin-dashboard-wanted-spare-component-dashboard-badge36 {
    justify-content: flex-start;
    z-index: 2;

    /* flex: 1; */
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    padding: 0 7px;
    white-space: nowrap;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.admin-dashboard-wanted-spare-component-dashboard-badge-number-element {
    width: 61px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 61px;
}

.admin-dashboard-wanted-spare-component-dashboard-badge37 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    /* flex: 1; */
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
 
    padding: 0 7px;
    z-index: 2;
    white-space: nowrap;
  
    text-align: center;
    font-size: 14px;
    color: #344054;
}

.admin-dashboard-wanted-spare-component-dashboard-second-badge-count {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 60px;
}

.admin-dashboard-wanted-spare-component-dashboard-frame-parent26 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex: 1;
    flex-direction: column;
    gap: 56px;
    min-width: 107px;
    text-align: left;
    font-size: 16px;
}

.admin-dashboard-wanted-spare-component-dashboard-frame-wrapper19 {
    flex-direction: row;
    padding: 0 14px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.admin-dashboard-wanted-spare-component-dashboard-legend-series {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
}

.admin-dashboard-wanted-spare-component-dashboard-type-of-work2 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 124px;
    z-index: 2;

    min-width: 109px;
}

.admin-dashboard-wanted-spare-component-dashboard-badge38 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 2;
    text-align: center;
    font-size: 14px;
    color: #344054;
}

.admin-dashboard-wanted-spare-component-dashboard-third-badge-number {
    width: max-content;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
    max-width: 200px; /* Adjust this value as needed */
    overflow: hidden; /* Hide overflowing content */
    text-overflow: ellipsis; /* Add ellipsis when text overflows */
    white-space: nowrap; /* Prevent text from wrapping */
}

.admin-dashboard-wanted-spare-component-dashboard-badge39 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    width: 93px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    align-items: flex-start;
    padding: 0 7px;
    z-index: 2;
    color: #344054;
}

.admin-dashboard-wanted-spare-component-dashboard-location-badge-elements {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 77px;
}

.admin-dashboard-wanted-spare-component-dashboard-filter-elements-inner {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    /* padding: 0 10px 0 0; */
    margin-right: 15px;
    z-index: 0;
}

.admin-dashboard-wanted-spare-component-dashboard-filter-elements {
    width: 153px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 26px;
    text-align: center;
    font-size: 20px;
    color: #6941c6;
}

.admin-dashboard-wanted-spare-component-dashboard-dropdown10 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 2;
    margin-right: 10px;
}

.admin-dashboard-wanted-spare-component-dashboard-dots-vertical-icon {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}

.admin-dashboard-wanted-spare-component-dashboard-price-parent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    flex-direction: column;
    gap: 4px;
}

.admin-dashboard-wanted-spare-component-dashboard-price2 {
    position: relative;

    align-self: stretch;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    white-space: nowrap;
    z-index: 2;
}

.admin-dashboard-wanted-spare-component-dashboard-dropdown-elements {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    align-self: stretch;
    flex-direction: row;
    padding: 0 30px;
    font-size: 12px;
    color: #475467;
}

.admin-dashboard-wanted-spare-component-dashboard-dropdown-options-parent {
    flex: 1;
    flex-direction: column;
    gap: 6px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.admin-dashboard-wanted-spare-component-dashboard-text83 {
    position: relative;

    line-height: 20px;
    display: inline-block;
    min-width: 83px;
    z-index: 3;
}

.admin-dashboard-wanted-spare-component-dashboard-frame-wrapper3 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    align-self: stretch;
    padding: 0 4px 0 3px;
}

.admin-dashboard-wanted-spare-component-dashboard-radar-1-1-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    flex: 1;
    align-items: flex-start;
    gap: 8px;
    z-index: 2;
}


.admin-dashboard-wanted-spare-component-dashboard-magnifier-1-icon {
    height: 20px;
    width: 20px;
    position: relative;
    object-fit: cover;
    min-height: 20px;
}

.admin-dashboard-wanted-spare-component-dashboard-divider-icon8 {
    width: 968px;
    position: relative;
    max-height: 100%;
    max-width: 100%;
    z-index: 2;
}

.admin-dashboard-wanted-spare-component-dashboard-metric-item-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: max-content;
    /* width: 968px; */
    gap: 25px;
    max-width: 100%;
    font-size: 16px;
    overflow-x: scroll;
    scrollbar-width: none;
}

.admin-dashboard-wanted-spare-component-dashboard-metric-item3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    padding: 16px 15px 20px;
    position: relative;
    gap: 20px;
    /* min-width: 229px; */
    width: max-content;
    z-index: 2;
}

.admin-dashboard-wanted-spare-component-dashboard-heading9 {
    width: 254px;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
}

.admin-dashboard-wanted-spare-component-dashboard-number-and-chart {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 16px;
    font-size: 30px;
}

.admin-dashboard-wanted-spare-component-dashboard-number-and-badge3 {
    display: flex;
    justify-content: flex-start;

    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    min-width: 105px;
}

.admin-dashboard-wanted-spare-component-dashboard-heading {
    line-height: 38px;
    font-weight: 600;
}

.admin-dashboard-wanted-spare-component-dashboard-change-and-text3 {
    display: flex;
    justify-content: flex-start;

    align-self: stretch;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    text-align: center;
    font-size: 14px;
    color: #079455;
}

.admin-dashboard-wanted-spare-component-dashboard-change2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1px;
}

.admin-dashboard-wanted-spare-component-dashboard-upload-04-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 20px;
}

.admin-dashboard-wanted-spare-component-dashboard-change7 {
    position: relative;
    line-height: 20px;
    font-weight: 500;

    display: inline-block;
    min-width: 38px;
}

.admin-dashboard-wanted-spare-component-dashboard-text139 {
    position: relative;
    line-height: 20px;
    font-weight: 500;

    flex: 1;
    color: #475467;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.admin-dashboard-wanted-spare-component-dashboard-chart-mini-icon3 {
    height: 48px;
    width: 96px;
    position: relative;
    object-fit: contain;
}

.admin-dashboard-wanted-spare-component-dashboard-dropdown19 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    margin: 0 !important;
    position: absolute;
    top: 16px;
    right: 16px;
}

.admin-dashboard-wanted-spare-component-dashboard-frame-parent71 {
    display: flex;
    align-items: flex-start;
    max-width: 100%;

    width: 968px;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    font-size: 14px;
    color: #344054;
}

.admin-dashboard-wanted-spare-component-dashboard-buttonsbutton-parent1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: max-content;
    flex-direction: row;
    gap: 24px;
    max-width: calc(100% - 44px);
}

.admin-dashboard-wanted-spare-component-dashboard-buttonsbutton13 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    z-index: 2;
    cursor: pointer;
    width: 90px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px 12px;
    gap: 6px;
}


.admin-dashboard-wanted-spare-component-dashboard-arrow-narrow-left-icon {
    height: 24px;
    width: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 24px;
}

.admin-dashboard-wanted-spare-component-dashboard-text67 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 34px;
}

.admin-dashboard-wanted-spare-component-dashboard-buttonsbutton25,
.admin-dashboard-wanted-spare-component-dashboard-buttonsbutton26 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.admin-dashboard-wanted-spare-component-dashboard-buttonsbutton25,
.admin-dashboard-wanted-spare-component-dashboard-buttonsbutton26 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    cursor: pointer;
    padding: 6px 11px;
    gap: 5.0px;
    min-width: 92px;
    z-index: 2;
}

.admin-dashboard-wanted-spare-component-dashboard-buttonsbutton26 {
    padding: 6px 10px 6px 12px;
    gap: 5.0px;
}

.admin-dashboard-wanted-spare-component-dashboard-icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 3.0px 0 0;
}

.admin-dashboard-wanted-spare-component-dashboard-icon1 {
    width: 16.0px;
    height: 16.0px;
    position: relative;
}

.admin-dashboard-wanted-spare-component-dashboard-text68 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 56px;
}

.admin-dashboard-wanted-spare-component-dashboard-buttonsbutton27 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-direction: row;
    padding: 8px 12px;
    position: relative;
    gap: 6px;
    min-width: 79px;
    z-index: 2;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.admin-dashboard-wanted-spare-component-dashboard-text145 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 70px;
}

.admin-dashboard-wanted-spare-component-dashboard-ellipse-parent {
    height: 24px;
    width: 17px;
    position: absolute;
    margin: 0 !important;
    top: -9px;
    right: -2.0px;
    font-size: 10px;
    color: #fff;
}

.admin-dashboard-wanted-spare-component-dashboard-ellipse-div {
    position: absolute;
    top: 0;
    left: 3px;
    border-radius: 50%;
    background-color: #17b26a;
    width: 17px;
    height: 17px;
    z-index: 1;
}

.admin-dashboard-wanted-spare-component-dashboard-div {
    color: white;
    z-index: 2;
    position: absolute;
    top: 3px;
    left: 8px;

}

.admin-dashboard-wanted-spare-component-dashboard-arrow-square-up-right-wrapper1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;
    padding: 8px 0 0;
}

.admin-dashboard-wanted-spare-component-dashboard-arrow-square-up-right-icon1 {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
}

.admin-dashboard-wanted-spare-component-dashboard-small-card{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    padding-top: 20px;
    overflow-x: scroll;
    scrollbar-width: none;
}


@media screen and (max-width: 1100px) {

    .admin-dashboard-wanted-spare-component-dashboard-rectangle-parent5,
    .admin-dashboard-wanted-spare-component-dashboard-metric-item-parent {
        flex-wrap: wrap;
    }

}

@media screen and (max-width:750px) {

    .admin-dashboard-wanted-spare-component-dashboard-frame-parent1 {
        flex-wrap: wrap;
        min-width: 100%;
    }

    .admin-dashboard-wanted-spare-component-dashboard-action-fields {
        min-width: 100%;
    }

    .admin-dashboard-wanted-spare-component-dashboard-frame-parent21 {
        flex-wrap: wrap;
    }

    .admin-dashboard-wanted-spare-component-dashboard-heading {
        font-size: 24px;
        line-height: 30px;
    }

    .admin-dashboard-wanted-spare-component-dashboard-buttonsbutton-parent1 {
        flex-wrap: wrap;
    }

}

@media screen and (max-width:450px) {

    .admin-dashboard-wanted-spare-component-dashboard-price2 {
        font-size: 16px;
        line-height: 26px;
    }

    .admin-dashboard-wanted-spare-component-dashboard-number-and-chart {
        flex-wrap: wrap;
    }

    .admin-dashboard-wanted-spare-component-dashboard-heading {
        font-size: 18px;
        line-height: 23px;
    }
}