@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');


.sign-in-modal-overlay {
  position: fixed; /* Ensures it covers the entire screen */
  top: 0;
  left: 0;
  width: 100vw; 
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensures it appears above other elements */
}

.sign-in-modal {
  background: white;
  width: 500px; /* Adjust width as needed */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  position: relative;
}
.sign-in-modal-close-button {
  position: absolute;
  top: 20px;
  right: 15px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.sign-in-modal-gerator-04-1-icon1 {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}

.sign-in-modal-brand-navigation,
.sign-in-modal-logo1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.sign-in-modal-logo1 {
  align-self: stretch;
  height: 32px;
  width: 32px;
  flex-direction: row;
  /* background-image: url('../../icons/Gerator/gerator_logo/gerator-08.svg'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.sign-in-modal-brand-navigation {
  width: 121px;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
}

.sign-in-modal-placeholder-icon4 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.sign-in-modal-action-label {
  text-decoration: none;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 47px;
  white-space: nowrap;
}

.sign-in-modal-buttonsbutton4,
.sign-in-modal-text-padding1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.sign-in-modal-buttonsbutton4 {
  cursor: pointer;
  border: 0;
  padding: 10px 16px;
  background-color: transparent;
  border-radius: 8px;
  overflow: hidden;
  gap: 6px;
}

.sign-in-modal-text8 {
  text-decoration: none;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 59px;
  white-space: nowrap;
}




.sign-in-modal-hamburger-menu {
  display: none;
}

.sign-in-modal-buttonsbutton5,
.sign-in-modal-navigation-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sign-in-modal-buttonsbutton5 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 15px;
  background-color: #7e56d9;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  justify-content: center;
  gap: 6px;
}

.sign-in-modal-buttonsbutton5:hover {
  background-color: #6941C6;
}


.sign-in-modal-buttonsbutton5.loading {
  cursor: wait;
  pointer-events: none;
}

.sign-in-modal-navigation-actions {
  width: 190px;
  justify-content: flex-start;
  gap: 12px;
}

.sign-in-modal-brand-navigation-parent,
.sign-in-modal-desktop {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.sign-in-modal-brand-navigation-parent {
  flex: 1;
  justify-content: space-between;
  gap: 20px;
}

.sign-in-modal-desktop {
  width: 100%;
  justify-content: flex-start;
  padding: 0 32px;
  box-sizing: border-box;
  position: fixed;
}

.sign-in-modal-content-icon {
  height: 48px;
  width: 48px;
  position: relative;
  border-radius: 18px;
  overflow: hidden;
  flex-shrink: 0;
}

.sign-in-modal-login-credentials,
.sign-in-modal-logomark {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.sign-in-modal-logomark {
  box-shadow: 0 1.5px 4.5px rgba(16, 24, 40, 0.1),
    0 1.5px 3px rgba(16, 24, 40, 0.06);
  justify-content: flex-start;
}

.sign-in-modal-login-credentials {
  align-self: stretch;
  justify-content: center;
  padding: 0 20px;
}

.sign-in-modal-supporting-text2,
.sign-in-modal-title {
  align-self: stretch;
  position: relative;
}

.sign-in-modal-title {
  margin: 0;
  font-size: inherit;
  line-height: 38px;
  font-weight: 600;
  font-family: inherit;
}

.sign-in-modal-supporting-text2 {
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.sign-in-modal-brand-name,
.sign-in-modal-login-form-fields {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.sign-in-modal-login-form-fields {
  gap: 24px;
}

.sign-in-modal-label3 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 36px;
}

.sign-in-modal-content8 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Inter;
  font-size: 16px;
  color: #667085;
  min-width: 199px;
  max-width: 100%;
}

.sign-in-modal-help-icon3 {
  height: 16px;
  width: 16px;
  position: relative;
  display: none;
}

.sign-in-modal-input3 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 13px;
  gap: 8px;
  max-width: 100%;
}

.sign-in-modal-hint-text3 {
  width: 320px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  /* display: none; */
}

.sign-in-modal-input-field3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  max-width: 100%;
}

.sign-in-modal-label4 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 66px;
}

.sign-in-modal-form1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  max-width: 100%;
}

.sign-in-modal-input5 {
  margin: 0;
  height: 18px;
  width: 16px;
}

.sign-in-modal-checkbox-label,
.sign-in-modal-supporting-text3 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
}

.sign-in-modal-checkbox-label {
  flex: 1;
  font-weight: 500;
  color: #344054;
}

.sign-in-modal-supporting-text3 {
  align-self: stretch;
  width: 320px;
  color: #475467;
  display: none;
}

.sign-in-modal-checkbox,
.sign-in-modal-text-and-supporting-text1 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 144px;
}

.sign-in-modal-checkbox {
  gap: 8px;
  min-width: 160px;
}

.sign-in-modal-placeholder-icon8 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.sign-in-modal-submit-label {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #6941c6;
  text-align: left;
  display: inline-block;
  min-width: 114px;
}

.sign-in-modal-buttonsbutton6,
.sign-in-modal-remember-me {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.sign-in-modal-buttonsbutton6 {
  overflow: hidden;
  gap: 6px;
}

.sign-in-modal-remember-me {
  align-self: stretch;
  flex-wrap: wrap;
  row-gap: 20px;
}

.sign-in-modal-icon {
  text-decoration: none;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 53px;
}



.sign-in-modal-buttonsbutton7 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 20px;
  background-color: #7e56d9;
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.sign-in-modal-buttonsbutton7:hover {
  background-color: #6941C6;
}


.sign-in-modal-social-icon4 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.sign-in-modal-social-label {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
}

.sign-in-modal-social-button4 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  gap: 12px;
  white-space: nowrap;
}

.sign-in-modal-social-icon5 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.sign-in-modal-social-button5 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 12px;
  white-space: nowrap;
}

.sign-in-modal-text11 {
  height: 24px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
}

.sign-in-modal-hyperlink {
  color: #475467;
  line-height: 20px;
}

.sign-in-modal-actions1,
.sign-in-modal-content7,
.sign-in-modal-social-button-groups1,
.sign-in-modal-social-button7 {
  align-self: stretch;
  align-items: center;
  justify-content: center;
}

.sign-in-modal-social-button-groups1 {
  cursor: pointer;
}

.sign-in-modal-social-button7 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: none;
  flex-direction: row;
  padding: 8px 16px;
  gap: 11px;
  white-space: nowrap;
}

.sign-in-modal-actions1,
.sign-in-modal-content7,
.sign-in-modal-social-button-groups1 {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.sign-in-modal-actions1,
.sign-in-modal-content7 {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.sign-in-modal-content7 {
  margin: 0;
  border-radius: 12px;
  gap: 24px;
  max-width: 100%;
}

.sign-in-modal-help-label,
.sign-in-modal-question {
  position: relative;
  line-height: 20px;
}

.sign-in-modal-help-label {
  font-weight: 600;
  display: inline-block;
  min-width: 51px;
  white-space: nowrap;
}

.sign-in-modal-buttonsbutton8 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  color: #6941c6;
}

.sign-in-modal-help-link {
  flex-direction: row;
  gap: 3px;
}

.sign-in-modal-account-help,
.sign-in-modal-content6,
.sign-in-modal-help-link {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.sign-in-modal-account-help {
  flex-direction: row;
  padding: 0 75px;
  text-align: left;
  font-size: 14px;
  color: #475467;
}

.sign-in-modal-content6 {
  width: 360px;
  flex-direction: column;
  gap: 32px;
  max-width: 360px;
}

.sign-in-modal-container1,
.sign-in-modal-login-form-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.sign-in-modal-container1 {
  flex: 1;
  justify-content: center;
  padding: 0 20px;
  max-width: 1280px;
}

.sign-in-modal-login-form-container {
  align-self: center;
  justify-content: center;
  padding: 0 48px;
  max-width: 100%;
  text-align: center;
  font-size: 30px;
  color: #101828;
  font-family: Inter;
  height: 100%;
}

.sign-in-modal-copyright,
.sign-in-modal-mail-01-icon1 {
  position: relative;
  z-index: 1;
}

.sign-in-modal-copyright {
  line-height: 20px;
}

.sign-in-modal-mail-01-icon1 {
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}

.sign-in-modal-mail-icon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 0 0;
}

.sign-in-modal-helpcentergeratorcom1 {
  position: relative;
  line-height: 20px;
  white-space: nowrap;
  z-index: 1;
}

.sign-in-modal-contact {
  flex-direction: row;
  justify-content: flex-start;
  gap: 7px;
}

.sign-in-modal-contact,
.sign-in-modal-footer1,
.sign-in-modal-log-in,
.sign-in-modal-sign-in-desktop {
  display: flex;
  align-items: flex-start;
}

.sign-in-modal-footer1 {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 15px;
}

.sign-in-modal-log-in,
.sign-in-modal-sign-in-desktop {
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
}

.sign-in-modal-log-in {
  align-self: stretch;
  padding: 35px 32px 5px 32px;
  gap: 25px;
  height: inherit;
  max-width: 100%;
  text-align: left;
  font-size: 14px;
  color: #475467;
  font-family: Inter;
}

.sign-in-modal-sign-in-desktop {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 18px 0 0;
  gap: 18px;
  line-height: normal;
  letter-spacing: normal;
  height: 100vh;

}



@media screen and (max-width: 1275px) {
  .sign-in-modal-container1 {
    max-width: 100%;
  }

  .sign-in-modal-login-form-container {
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1100px) {
  .sign-in-modal-log-in {
    padding-top: 152px;
    padding-bottom: 21px;
    box-sizing: border-box;
    gap: 325px;
  }
}

@media screen and (max-width: 750px) {
  .sign-in-modal-title {
    font-size: 24px;
    line-height: 30px;
  }

  .sign-in-modal-footer1 {
    flex-wrap: wrap;
  }

}

@media screen and (max-width: 450px) {

  .sign-in-modal-desktop {

    width: 610px;
    justify-content: flex-start;
    padding: 0 0px;
    box-sizing: border-box;

  }

  .sign-in-modal-brand-navigation {
    width: 121px;
    flex-direction: column;
    padding: 6px 0 0;
    box-sizing: border-box;
    left: 30px;
    position: relative;
  }


  .sign-in-modal-navigation-actions {
    display: none !important;
  }

  .sign-in-modal-hamburger-menu {
    display: flex;
    position: relative;
    right: 10px;
  }

  .sign-in-modal-gerator-04-1-icon1 {
    height: 32px;
    width: 121px;
    position: relative;
    object-fit: cover;
    display: none;
  }

  .sign-in-modal-title {
    font-size: 18px;
    line-height: 23px;
  }

  .sign-in-modal-checkbox,
  .sign-in-modal-help-link {
    flex-wrap: wrap;
  }

  .sign-in-modal-account-help {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .sign-in-modal-container1,
  .sign-in-modal-content6 {
    gap: 16px;
  }

  .sign-in-modal-log-in {
    padding-top: 160px;
    gap: 272px;
  }
}