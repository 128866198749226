@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.account-email-check-gerator-04-1-icon {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}
.account-email-check-logo {
    width: 121px;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    background-image: url('../../../icons/Gerator/account_setup/gerator04-1@2x.png');
    /* background-image: url('../../../public/account_setup/gerator04-1@2x.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  .account-email-check-featured-icon {
    height: 48px;
    width: 48px;
    position: relative;
    border-radius: 10px;
  }
  .account-email-check-supporting-text,
  .account-email-check-text {
    position: relative;
    line-height: 24px;
  }
  .account-email-check-text {
    align-self: stretch;
    font-weight: 600;
  }
  .account-email-check-supporting-text {
    color: #475467;
  }
  .account-email-check-featured-icon-parent,
  .account-email-check-text-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .account-email-check-text-parent {
    flex: 1;
    flex-direction: column;
    gap: 2px;
    min-width: 182px;
  }
  .account-email-check-featured-icon-parent {
    align-self: stretch;
    flex-direction: row;
    gap: 16px;
  }
  .account-email-check-connector {
    height: 26px;
    width: 2px;
    position: relative;
    border-radius: 2px;
    background-color: #eaecf0;
  }
  .account-email-check-button-steps,
  .account-email-check-step-base,
  .account-email-check-step-base1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 23px;
  }
  .account-email-check-step-base,
  .account-email-check-step-base1 {
    align-self: stretch;
    flex-direction: column;
    padding: 0 0 4px;
    gap: 2px;
  }
  .account-email-check-step-base1 {
    opacity: 0.6;
  }
  .account-email-check-featured-icon2 {
    width: 48px;
    height: 48px;
    position: relative;
    border-radius: 10px;
  }
  .account-email-check-connector-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 4px;
    gap: 4px;
  }
  .account-email-check-supporting-text2 {
    align-self: stretch;
    position: relative;
    line-height: 24px;
    color: #475467;
  }
  .account-email-check-step-base2,
  .account-email-check-text-and-supporting-text {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .account-email-check-text-and-supporting-text {
    flex: 1;
    flex-direction: column;
    padding: 0 0 32px;
    box-sizing: border-box;
    gap: 2px;
    min-width: 182px;
  }
  .account-email-check-step-base2 {
    align-self: stretch;
    flex-direction: row;
    gap: 16px;
    opacity: 0.6;
  }
  .account-email-check-connector3 {
    width: 2px;
    height: 26px;
    position: relative;
    border-radius: 2px;
    background-color: #eaecf0;
    display: none;
  }
  .account-email-check-connector-wrap1 {
    height: 82px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 4px;
    box-sizing: border-box;
    gap: 4px;
  }
  .account-email-check-logo-parent,
  .account-email-check-progress-steps-progress-icon,
  .account-email-check-section-inner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .account-email-check-progress-steps-progress-icon {
    align-self: stretch;
    flex-direction: column;
  }
  .account-email-check-logo-parent,
  .account-email-check-section-inner {
    max-width: 100%;
  }
  .account-email-check-logo-parent {
    flex: 1;
    flex-direction: column;
    gap: 79.5px;
  }
  .account-email-check-section-inner {
    width: 380px;
    flex-direction: row;
    padding: 0 18px;
    box-sizing: border-box;
  }
  .account-email-check-email-title {
    height: 20px;
    width: 125px;
    position: relative;
    line-height: 20px;
    display: inline-block;
  }
  .account-email-check-mail-01-icon {
    width: 16px;
    height: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .account-email-check-mail-01-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2px 0 0;
  }
  .account-email-check-helpuntitleduicom {
    align-self: stretch;
    flex: 1;
    position: relative;
    line-height: 20px;
    white-space: nowrap;
  }
  .account-email-check-email-input {
    height: 20px;
    width: 188px;
    flex-direction: row;
    justify-content: flex-start;
    gap: 7px;
  }
  .account-email-check-email-input,
  .account-email-check-email-title-parent,
  .account-email-check-section {
    display: flex;
    align-items: flex-start;
  }
  .account-email-check-email-title-parent {
    align-self: stretch;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    font-size: 14px;
  }
  .account-email-check-section {
    flex: 0.9386;
    background-color: #f9fafb;
   
   height: inherit;
    flex-direction: column;
    justify-content: flex-start;
    padding: 32.5px 13px 32px 14px;
    box-sizing: border-box;
    gap: 273px;
    min-width: 286px;
    max-width: 440px;
    /* height: 98.5vh; */
  }
  .account-email-check-featured-icon4 {
    width: 56px;
    height: 56px;
    position: relative;
    border-radius: 12px;
  }
  .account-email-check-title {
    margin: 0;
    align-self: stretch;
    position: relative;
    font-size: inherit;
    line-height: 38px;
    font-weight: 600;
    font-family: inherit;
  }
  .account-email-check-oliviauntitleduicom {
    font-weight: 500;
  }
  .account-email-check-supporting-text4 {
    align-self: stretch;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
  }
  .account-email-check-header,
  .account-email-check-text-and-supporting-text2 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
  }
  .account-email-check-header {
    align-items: center;
    gap: 24px;
  }
  /* .account-email-check-placeholder-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  } */
  /* .account-email-check-action-label {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    
  } */
  /* .account-email-check-buttonsbutton,
  .account-email-check-text-padding {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  .account-email-check-buttonsbutton {
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 66px;
    background-color: #7e56d9;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    gap: 6px;
  } */
  /* .account-email-check-arrow-left-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 20px;
  }
  .account-email-check-back-label {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 91px;
  }
  .account-email-check-placeholder-icon2 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
    min-height: 20px;
  } */
  /* .account-email-check-buttonsbutton1, */
  .account-email-check-content1 {
    display: flex;
    align-items: center;
  }
  /* .account-email-check-buttonsbutton1 {
    overflow: hidden;
    flex-direction: row;
    justify-content: center;
    gap: 6px;
    text-align: left;
    font-size: 14px;
    color: #475467;
  } */
  .account-email-check-content1 {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    gap: 32px;
    max-width: 360px;
  }
  .account-email-check-container1 {
    width: 100%;
    padding: 0 32px;
    box-sizing: border-box;
    max-width: 1280px;
  }
  .account-email-check-container,
  .account-email-check-container1,
  .account-email-check-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .account-email-check-content {
    width: 360px;
    max-width: 360px;
  }
  .account-email-check-container {
    align-self: stretch;
    padding: 0 20px;
    height: 100%;
    
  }
  .account-email-check-mail-01-icon1 {
    height: 16px;
    width: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .account-email-check-helpcentergeratorcom {
    position: relative;
    line-height: 20px;
    white-space: nowrap;
  }

  .account-email-check-hyperlink{
    color: #475467;
    line-height: 20px;
  }

  .account-email-check-icon-and-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
  }
  .account-email-check-text3 {
    position: relative;
    line-height: 20px;
  }
  .account-email-check-container-parent,
  .account-email-check-footer {
    display: flex;
    max-width: 100%;
  }
  .account-email-check-footer {
    width: 983px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    gap: 20px;
    text-align: left;
    font-size: 14px;
    color: #475467;
    font-family: Inter;
  }
  .account-email-check-container-parent {
    align-self: stretch;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 146px;
    height: 100%;
  }
  .account-email-check-section1,
  .account-email-check-set-up-wizardcheck-email-des {
    display: flex;
    justify-content: flex-start;
    font-family: Inter;
  }
  .account-email-check-section1 {
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 110px 0 15px;
    box-sizing: border-box;
    min-width: 480px;
    max-width: 100%;
    text-align: center;
    font-size: 30px;
    color: #101828;
    height: inherit;
  }
  .account-email-check-set-up-wizardcheck-email-des {
    width: 100%;
    position: relative;
    background-color: #fff;
    flex-direction: row;
    align-items: flex-start;
    height: 100vh;
    line-height: normal;
    letter-spacing: normal;
    row-gap: 20px;
    text-align: left;
    font-size: 16px;
    color: #344054;

  }




  @media screen and (max-width: 1275px) {
    .account-email-check-logo-parent {
      flex: 1;
    }
    .account-email-check-section {
      width: 100%;
    }
    .account-email-check-container1 {
      max-width: 100%;
    }
    .account-email-check-section1 {
      flex: 1;
    }
    .account-email-check-set-up-wizardcheck-email-des {
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 1100px) {
    .account-email-check-container-parent {
      gap: 219px;
    }
    .account-email-check-container-parent {
      gap: 640px;
    }
  }

  @media screen and (max-width: 780px) {
    .account-email-check-section {
      padding-top: 21px;
      padding-bottom: 21px;
      box-sizing: border-box;
      height: fit-content;
      min-width:100%;
      gap: 50px;
      
    }
    .account-email-check-title {
      font-size: 24px;
      line-height: 30px;
    }
    .account-email-check-footer {
      flex-wrap: wrap;
    }
    .account-email-check-container-parent {
      gap: 170px;
    }
    .account-email-check-section1 {
      padding-top: 94px;
      padding-bottom: 8px;
      box-sizing: border-box;
      min-width: 100%;
    }
  }
  @media screen and (max-width: 450px) {

    .account-email-check-mobile-container{
      width: 375px; 
      height: 10px;
      padding-left: 16px; 
      padding-right: 16px; 
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 32px;
      display: inline-flex;
      padding-top: 30px;
    }
  
    .account-email-check-mobile-PaginationDotGroup{
      justify-content: center;
      align-items: center;
      gap: 16px;
      display: inline-flex;
    }
    
    .account-email-check-mobile-PaginationDotIndicator{
      width: 10px;
      height: 10px;
      position: relative;
      background-color: #EAECF0;
      border-radius: 9999px;
    }
  
    .account-email-check-mobile-PaginationDotIndicator1{
      width: 10px;
      height: 10px;
      position: relative;
      background-color: #7F56D9;
      border-radius: 9999px;
    }


    .account-email-check-featured-icon-parent,
    .account-email-check-step-base2 {
      flex-wrap: wrap;
    }
    .account-email-check-logo-parent {
      gap: 40px;
    }
    .account-email-check-email-title-parent {
      flex-wrap: wrap;
    }
    .account-email-check-section {
      gap: 0px;
      padding-top: 20px;
      padding-bottom: 20px;
      box-sizing: border-box;
      max-width: 100%;
      background-color: white;
    }

    .account-email-check-progress-steps-progress-icon{
      display: none;
    }


    .account-email-check-title {
      font-size: 18px;
      line-height: 23px;
    }
    /* .account-email-check-buttonsbutton {
      padding-left: 20px;
      padding-right: 20px;
      box-sizing: border-box;
    } */
    .account-email-check-container1,
    .account-email-check-content,
    .account-email-check-content1 {
      gap: 16px;
    }
    .account-email-check-container {
      gap: 40px;
    }
    .account-email-check-container-parent {
      gap: 550px;
    }
    .account-email-check-section1 {
      padding-top: 128px;
      padding-bottom: 20px;
      box-sizing: border-box;
    }
  }
  