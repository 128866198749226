.navbar {
  position: relative;

}

.user-menu-dropdown-user-menu-all-options-fixed {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 1000;
}

.navbar-rectangle-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}

.navbar-rectangle-parent {
  align-self: stretch;
  background-color: #fff;
  justify-content: space-between;
  padding: 14px 64px 14px 31px;
  box-sizing: border-box;
  top: 0;
  z-index: 99;
  position: sticky;
  width: 100%;
  gap: 20px;
  color: #344054;
}

.navbar-frame-child {
  position: relative;
  background-color: #fff;
  display: none;
}

.navbar-frame-child {
  height: 72px;
  width: 1440px;
  max-width: 100%;
}

.navbar-top-header,
.navbar-top-header-right {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.navbar-top-header,
.navbar-top-header-right {
  flex-direction: row;
  max-width: 100%;
}

.navbar-top-header-right {
  flex: 1;
  gap: 38px;
}

.navbar-top-header {
  width: 754px;
  gap: 70px;
}

.navbar-branding {
  width: 121px;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
}

.navbar-branding,
.navbar-logo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.navbar-logo {
  align-self: stretch;
  height: 32px;
  flex-direction: row;
  background-image: url(../../icons/Gerator/dashboard/gerator04-1@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.navbar-gerator-04-1-icon {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}

.navbar-search-input {

  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
}

.navbar-nav-item-button:hover {
  background-color: #f9fafb;
}

.navbar-navigation {
  flex-direction: row;
  align-items: center;
  gap: 4px;
  z-index: 1;
}

.navbar-navigation,
.navbar-search-input {
  display: flex;
  justify-content: flex-start;
}

.navbar-nav-item-base {
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  gap: 8px;
}

.navbar-nav-item-base:hover {
  background-color: #f9fafb;
}

.navbar-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.navbar-dot-icon {
  height: 10px;
  width: 10px;
  position: relative;
  display: none;
}

.navbar-bar-chart-01-icon {
  height: 10px;
  width: 10px;
  position: relative;
  display: none;
}

.navbar-bar-chart-01-icon {
  height: 24px;
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.navbar-text {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 30px;
}

.navbar-badge {
  height: 22px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;

}

.navbar-text1 {
  align-self: stretch;
  position: relative;
  line-height: 18px;
  font-weight: 500;
}

.navbar-chevron-down-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.navbar-search-lg-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.navbar-nav-item-base1 {
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  padding: 8px 12px;
  gap: 8px;
  color: #18222f;
}

.navbar-badge1,
.navbar-nav-item-base1 {
  background-color: #f9fafb;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.navbar-text2 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 29px;
}

.navbar-badge1 {
  height: 22px;
  border-radius: 9999px;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
  color: #344054;
}

.navbar-text4 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 38px;
}

.navbar-input-with-label {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.navbar-input-with-label {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  z-index: 1;
  font-size: 14px;
}

.navbar-label {
  width: 47px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.navbar-input {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  font-size: 16px;
  color: #667085;
}

.navbar-content3,
.navbar-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.navbar-content3 {
  flex: 1;
  gap: 8px;
}

.navbar-search-lg-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.navbar-search-placeholder {
  flex: 1;
  position: relative;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.navbar-user-actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}

.navbar-nav-item-button {
  width: 40px;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  z-index: 1;
}

.navbar-nav-item-button-child {
  height: 18.3px;
  width: 14.6px;
  position: relative;
}


.navbar-icon {
  height: 19px;
  width: 19px;
  position: relative;
}

.navbar-location-column-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3px 0 0;
}

.navbar-location-column-inner:hover {
  background-color: #f9fafb;
}

.navbar-avatar {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url(../../icons/Gerator/dashboard/user-placeholder2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}


.navbar-contrast-border {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  display: none;
}

.new-dropdown-wrapper {
  position: absolute;
  z-index: 1;
  top: 60px;
}


@media screen and (max-width: 750px) {

  .navbar-top-header-right {
    gap: 19px;
  }

  .navbar-top-header {
    gap: 35px;
  }

  .navbar-rectangle-parent {
    padding-right: 32px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .navbar-top-header {
    gap: 17px;
  }
}