.skeleton-chat-approacher-listing-offered-container {
    background: #f4f4f4;
    border-radius: 10px;
    flex-direction: column;
    gap: 10px;
    width: 96%;
    padding: 20px;
    display: flex;
    height: 350px;
    margin: 0 29px;
    align-self: flex-start;
}

.skeleton-chat-approacher-listing-offered-image {
    width: 150px;
    height: 100px;
    background: linear-gradient(90deg, #e0e0e0, #f0f0f0, #e0e0e0);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    border-radius: 10px;
}

.skeleton-chat-approacher-listing-offered-header,
.skeleton-chat-approacher-listing-offered-subheader,
.skeleton-chat-approacher-listing-offered-tags,
.skeleton-chat-approacher-listing-offered-footer {
    height: 15px;
    background: linear-gradient(90deg, #e0e0e0, #f0f0f0, #e0e0e0);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    border-radius: 5px;
}

.custom-footer {
    margin-top: 97px;
    height: 38px;
}

.skeleton-chat-approacher-listing-offered-header {
    width: 80%;
}

.skeleton-chat-approacher-listing-offered-subheader {
    width: 60%;
}

.skeleton-chat-approacher-listing-offered-tags {
    width: 90%;
}

.skeleton-chat-approacher-listing-offered-stats {
    height: 180px;
    display: flex;
    justify-content: space-between;
}

.skeleton-chat-approacher-listing-offered-box {
    width: 20%;
    height: inherit;
    background: linear-gradient(90deg, #e0e0e0, #f0f0f0, #e0e0e0);
    background-size: 200% 100%;
    animation: shimmer 1.5s infinite;
    border-radius: 5px;
}

@keyframes shimmer {
    0% {
        background-position: -200% 0;
    }

    100% {
        background-position: 200% 0;
    }
}