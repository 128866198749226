/* Import the font */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

.chatB-frame-child,
.chatB-messaging-with-list-view-car-child {
  position: relative;
  background-color: #fff;
  display: none;
}

.chatB-messaging-with-list-view-car-child {
  align-self: stretch;
  height: 1906px;
}

.chatB-frame-child {
  height: 72px;
  width: 1440px;
  max-width: 100%;
}

.chatB-gerator-04-1-icon {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}

.chatB-logo,
.chatB-logo-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-logo {
  align-self: stretch;
  height: 32px;
  flex-direction: row;
  background-image: url(../../icons/Gerator/chat_page/gerator04-1@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.chatB-logo-container {
  width: 121px;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
}

.chatB-bar-chart-01-icon,
.chatB-dot-icon {
  height: 10px;
  width: 10px;
  position: relative;
  display: none;
}

.chatB-bar-chart-01-icon {
  height: 24px;
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.chatB-text {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 30px;
}

.chatB-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.chatB-text1 {
  align-self: stretch;
  position: relative;
  line-height: 18px;
  font-weight: 500;
}

.chatB-badge {
  height: 22px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
}

.chatB-chevron-down-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.chatB-nav-item-base {
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  gap: 8px;
}

.chatB-text2 {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 29px;
}

.chatB-badge1,
.chatB-nav-item-base1 {
  background-color: #f9fafb;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.chatB-badge1 {
  height: 22px;
  border-radius: 9999px;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
  color: #344054;
}

.chatB-nav-item-base1 {
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  padding: 8px 12px;
  gap: 8px;
  color: #18222f;
}

.chatB-text4 {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 38px;
}

.chatB-navbar,
.chatB-navigation {
  display: flex;
  justify-content: flex-start;
}

.chatB-navigation {
  flex-direction: row;
  align-items: center;
  gap: 4px;
  z-index: 1;
}

.chatB-navbar {
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
}

.chatB-label {
  width: 47px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.chatB-search-lg-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.chatB-search-placeholder {
  text-decoration: none;
  flex: 1;
  position: relative;
  line-height: 24px;
  color: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chatB-input {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 13px;
  font-size: 16px;
  color: #667085;
}

.chatB-input-with-label,
.chatB-logo-container-parent,
.chatB-navbar-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-input-with-label {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  z-index: 1;
  font-size: 14px;
}

.chatB-logo-container-parent,
.chatB-navbar-parent {
  flex-direction: row;
  max-width: 100%;
}

.chatB-navbar-parent {
  flex: 1;
  gap: 38px;
}

.chatB-logo-container-parent {
  width: 754px;
  gap: 70px;
}

.chatB-icon {
  height: 19px;
  width: 19px;
  position: relative;
}

.chatB-nav-item-button {
  width: 40px;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  z-index: 1;
}

.chatB-nav-item-button-child {
  height: 18.3px;
  width: 14.6px;
  position: relative;
}

.chatB-profile-button1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3px 0 0;
}

.chatB-contrast-border {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  display: none;
}

.chatB-avatar {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url(../../icons/Gerator/chat_page/avatar@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.chatB-rectangle-parent,
.chatB-user-actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}

.chatB-rectangle-parent {
  align-self: stretch;
  background-color: #fff;
  justify-content: space-between;
  padding: 14px 64px 14px 31px;
  box-sizing: border-box;
  top: 0;
  z-index: 99;
  position: sticky;
  max-width: 100%;
  gap: 20px;
  text-align: left;
  font-size: 16px;
  color: #344054;
  font-family: Inter;
}

.chatB-divider-icon,
.chatB-home-line-icon {
  position: relative;
  overflow: hidden;
}

.chatB-divider-icon {
  align-self: stretch;
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
}

.chatB-home-line-icon {
  height: 24px;
  width: 24px;
  flex-shrink: 0;
}

.chatB-nav-item-button2,
.chatB-nav-item-button3 {
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
}

.chatB-nav-item-button3 {
  background-color: #f9fafb;
}

.chatB-navigation2 {
  align-self: stretch;
  padding: 0 16px;
  gap: 8px;
}

.chatB-navigation1,
.chatB-navigation2,
.chatB-navigation3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-navigation1 {
  align-self: stretch;
  padding: 32px 0 0;
}

.chatB-navigation3 {
  gap: 8px;
}

.chatB-contrast-border1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
}

.chatB-avatar1 {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 9999px;
  background-image: url(../../icons/Gerator/chat_page/avatar1@3x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.chatB-content4,
.chatB-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chatB-footer {
  justify-content: flex-start;
  padding: 0 16px 24px;
  gap: 24px;
}

.chatB-content4 {
  align-self: stretch;
  flex: 1;
  border-right: 1px solid #eaecf0;
  justify-content: space-between;
}

.chatB-breadcrumb-button-base,
.chatB-sidebar-navigation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-sidebar-navigation {
  height: 1260px;
  width: 80px;
  background-color: #fff;
  border-right: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.chatB-breadcrumb-button-base {
  border-radius: 6px;
  padding: 4px;
}

.chatB-chevron-right-icon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.chatB-crumb-labels {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 74px;
}

.chatB-breadcrumb-button-base1 {
  flex: 1;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.chatB-text6 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.chatB-breadcrumb-button-base2 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 7px;
}

.chatB-chevron-right-icon2 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.chatB-text7 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  white-space: nowrap;
}

.chatB-breadcrumb-button-base3 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.chatB-text8 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 75px;
}

.chatB-breadcrumb-button-base4,
.chatB-breadcrumbs,
.chatB-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chatB-breadcrumb-button-base4 {
  flex: 1;
  border-radius: 6px;
  background-color: #f9fafb;
  justify-content: center;
  padding: 4px 8px;
  color: #344054;
}

.chatB-breadcrumbs,
.chatB-tabs {
  justify-content: flex-start;
}

.chatB-tabs {
  flex: 1;
  gap: 8px;
}

.chatB-breadcrumbs {
  width: 273px;
}

.chatB-divider-icon1 {
  width: 273px;
  height: 1px;
  position: relative;
  display: none;
}

.chatB-page-header {
  align-self: stretch;
  background-color: #fff;
  flex-direction: column;
  gap: 20px;
}

.chatB-container,
.chatB-header-section,
.chatB-header-section-wrapper,
.chatB-page-header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-container {
  align-self: stretch;
  flex-direction: column;
  padding: 0 32px;
}

.chatB-header-section,
.chatB-header-section-wrapper {
  max-width: 100%;
}

.chatB-header-section {
  width: 1078px;
  flex-direction: column;
  z-index: 1;
}

.chatB-header-section-wrapper {
  align-self: stretch;
  flex-direction: row;
}

.chatB-content-divider-child,
.chatB-frame-item {
  position: relative;
  border-radius: 12px;
}

.chatB-content-divider-child {
  align-self: stretch;
  height: 505px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
}

.chatB-frame-item {
  width: 148px;
  height: 148px;
  object-fit: cover;
  z-index: 2;
}

.chatB-rectangle-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0 0;
}

.chatB-used {
  text-transform: lowercase;
}

.chatB-pre-owned-used-fully-refurbi {
  margin-block-start: 0;
  margin-block-end: 18px;
}

.chatB-ase-antibody-detection {
  margin: 0;
  text-transform: lowercase;
}

.chatB-detail-title {
  align-self: stretch;
  position: relative;
  line-height: 12px;
  font-weight: 600;
  z-index: 2;
}

.chatB-dot-icon3 {
  height: 8px;
  width: 8px;
  position: relative;
  display: none;
}

.chatB-text9 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 51px;
}

.chatB-badge3 {
  width: 69px;
  border-radius: 9999px;
  background-color: #fffdfd;
  border: 1px solid #da2d20;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 2;
}

.chatB-supporting-text {
  position: relative;
  font-size: 16px;
  letter-spacing: -0.17px;
  line-height: 24px;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 83px;
  z-index: 2;
}

.chatB-info-badge {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.chatB-marker-icon {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 41px;
}

.chatB-marker-icon-wrapper {
  width: 50px;
  border-radius: 8px;
  background-color: #fdb022;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 4px;
  box-sizing: border-box;
  z-index: 2;
}

.chatB-supporting-text1 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 117px;
  z-index: 2;
}

.chatB-frame-container,
.chatB-location-label {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-location-label {
  flex-direction: column;
  padding: 1px 0 0;
  color: #475467;
}

.chatB-frame-container {
  flex-direction: row;
  gap: 8px;
  text-align: left;
  font-size: 16px;
  color: #fff;
}

.chatB-text10 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.chatB-badge4,
.chatB-badges {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-badge4 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
  color: #344054;
}

.chatB-badges {
  flex: 1;
  flex-direction: column;
  gap: 15px;
  min-width: 114px;
  color: #da2d20;
}

.chatB-marker-pin-02-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}

.chatB-location {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 124px;
  z-index: 2;
}

.chatB-calendar-icon-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
}

.chatB-work-type {
  width: 61px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 61px;
}

.chatB-badge5 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
}

.chatB-text11 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 60px;
}

.chatB-badge6 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 2;
}

.chatB-badge6,
.chatB-work-badges,
.chatB-work-badges-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.chatB-work-badges {
  flex: 1;
  justify-content: flex-start;
  gap: 8px;
}

.chatB-work-badges-wrapper {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 2px 0 0;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.chatB-frame-div {
  width: 163px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 56px;
  text-align: left;
  font-size: 16px;
}

.chatB-type-of-work {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 109px;
  z-index: 2;
}

.chatB-price-tag,
.chatB-price-tag-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-price-tag {
  gap: 8px;
}

.chatB-price-tag-wrapper {
  padding: 0 14px;
}

.chatB-cost-label {
  width: 149px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
}

.chatB-badge7,
.chatB-frame-parent1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-badge7 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.chatB-frame-parent1 {
  flex: 1;
  flex-direction: column;
  gap: 56px;
  min-width: 107px;
  text-align: left;
  font-size: 16px;
}

.chatB-status-label {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 77px;
}

.chatB-badge8,
.chatB-badges-parent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.chatB-badge8 {
  width: 93px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 0 7px;
  z-index: 2;
  color: #344054;
}

.chatB-badges-parent {
  width: 615px;
  align-items: flex-end;
  gap: 6.chatB-3px;
  max-width: 100%;
  text-align: center;
  font-size: 14px;
  color: #475467;
}

.chatB-content-container-inner,
.chatB-detail-title-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chatB-detail-title-parent {
  align-self: stretch;
  gap: 4px;
}

.chatB-content-container-inner {
  flex: 1;
  padding: 10px 0 0;
  box-sizing: border-box;
  min-width: 575px;
}

.chatB-filter-label {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 66px;
}

.chatB-badge-wrapper,
.chatB-badge9 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-badge9 {
  align-self: stretch;
  border-radius: 6px;
  background-color: #416ec6;
  flex-direction: row;
  padding: 2px 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 2;
}

.chatB-badge-wrapper {
  flex: 1;
  flex-direction: column;
  padding: 14px 0 0;
}

.chatB-dots-vertical-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.chatB-dropdown,
.chatB-filter-dropdown {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-dropdown {
  flex-direction: column;
  z-index: 2;
}

.chatB-filter-dropdown {
  width: 123px;
  flex-direction: row;
  gap: 19px;
}

.chatB-price {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  white-space: nowrap;
  z-index: 2;
}

.chatB-price-value {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 83px;
  z-index: 3;
}

.chatB-magnifier-1-icon {
  height: 20px;
  width: 20px;
  position: relative;
  object-fit: cover;
  min-height: 20px;
}

.chatB-price-action-icons {
  flex: 1;
  flex-direction: row;
  gap: 8px;
  z-index: 2;
}

.chatB-price-action-icons,
.chatB-price-actions,
.chatB-price-amount {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-price-actions {
  align-self: stretch;
  flex-direction: row;
  padding: 0 4px 0 3px;
}

.chatB-price-amount {
  flex: 1;
  flex-direction: column;
  gap: 4px;
}

.chatB-price-currency {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 30px;
  font-size: 12px;
  color: #475467;
}

.chatB-frame-wrapper,
.chatB-price-currency,
.chatB-price-parent {
  display: flex;
  align-items: flex-start;
}

.chatB-price-parent {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
}

.chatB-frame-wrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 10px 0 0;
  font-size: 20px;
  color: #6941c6;
}

.chatB-filter-dropdown-parent {
  width: 153px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 8px;
  text-align: center;
  font-size: 14px;
  color: #fff;
}

.chatB-content-container,
.chatB-frame-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chatB-content-container {
  flex: 1;
  gap: 50px;
  min-width: 707px;
}

.chatB-frame-group {
  width: 1260px;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 24px;
}

.chatB-divider-icon2 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 2;
}

.chatB-heading,
.chatB-number {
  position: relative;
  font-weight: 600;
}

.chatB-heading {
  width: 254px;
  line-height: 24px;
  display: inline-block;
}

.chatB-number {
  align-self: stretch;
  line-height: 38px;
}

.chatB-arrow-up-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}

.chatB-change1 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 29px;
}

.chatB-change {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1px;
}

.chatB-text12 {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: #475467;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chatB-change-and-text,
.chatB-number-and-badge {
  display: flex;
  justify-content: flex-start;
}

.chatB-change-and-text {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-size: 14px;
  color: #079455;
}

.chatB-number-and-badge {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.chatB-chart-mini-icon {
  height: 48px;
  width: 96px;
  position: relative;
  object-fit: contain;
}

.chatB-number-and-chart {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 16px;
  font-size: 30px;
}

.chatB-dropdown1,
.chatB-metric-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-dropdown1 {
  margin: 0 !important;
  position: absolute;
  top: 16px;
  right: 16px;
}

.chatB-metric-item {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 18px 15px;
  position: relative;
  gap: 20px;
  min-width: 229px;
  max-width: 306px;
  z-index: 2;
}

.chatB-heading1 {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.chatB-change3,
.chatB-change5,
.chatB-change7 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 38px;
}

.chatB-change5,
.chatB-change7 {
  min-width: 28px;
}

.chatB-change7 {
  min-width: 23px;
}

.chatB-metric-item-parent {
  width: 1260px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 12px;
  max-width: 100%;
  font-size: 16px;
}

.chatB-eye-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.chatB-eye-label {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 34px;
}

.chatB-buttonsbutton {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 6px 12px;
  background-color: #fff;
  width: 90px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  z-index: 2;
}

.chatB-icon1 {
  width: 16.7px;
  height: 16.7px;
  position: relative;
}

.chatB-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3.7px 0 0;
}

.chatB-button-label {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 56px;
}

.chatB-buttonsbutton1,
.chatB-buttonsbutton2 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 10px 6px 12px;
  min-width: 92px;
  z-index: 2;
}

.chatB-buttonsbutton1 {
  overflow: hidden;
  gap: 5.5px;
}

.chatB-buttonsbutton2 {
  overflow-x: auto;
  gap: 5.4px;
}

.chatB-message-button-label {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 70px;
}

.chatB-notification-badge-background {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: #17b26a;
  width: 17px;
  height: 17px;
  z-index: 3;
}

.chatB-notification-badge-count {
  position: absolute;
  top: 4px;
  left: 6px;
  line-height: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 7px;
  height: 20px;
  min-width: 7px;
  z-index: 4;
}

.chatB-notification-badge-background-parent {
  height: 24px;
  width: 17px;
  position: absolute;
  margin: 0 !important;
  top: -9px;
  right: -2.7px;
  font-size: 10px;
  color: #fff;
}

.chatB-buttonsbutton-parent,
.chatB-buttonsbutton3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-buttonsbutton3 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  padding: 8px 12px;
  position: relative;
  gap: 6px;
  min-width: 79px;
  z-index: 2;
}

.chatB-buttonsbutton-parent {
  width: 523.3px;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16px;
  max-width: calc(100% - 44px);
}

.chatB-arrow-square-up-right-icon {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}

.chatB-arrow-square-up-right-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 0 0;
}

.chatB-content-divider,
.chatB-frame-parent2 {
  width: 1260px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  max-width: 100%;
  font-size: 14px;
  color: #344054;
}

.chatB-content-divider {
  width: 1306px;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  padding: 12px 0 22px 23px;
  gap: 23.8px;
  z-index: 1;
  font-size: 18px;
  color: #101828;
  align-self: center;
}

.chatB-chat-name-label,
.chatB-text17 {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  /* min-width: 89px; */
}

.chatB-text17 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #344054;
  text-align: center;
  min-width: 16px;
}

.chatB-badge10,
.chatB-text-and-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.chatB-badge10 {
  width: 28px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  padding: 0 5px;
}

.chatB-text-and-badge {
  align-self: stretch;
  padding: 0 131px 0 0;
  gap: 8px;
}

.chatB-supporting-text2 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chatB-text-and-supporting-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 12px 0;
  box-sizing: border-box;
  gap: 4px;
  min-width: 166px;
}

.chatB-placeholder-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.chatB-text18 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #475467;
  text-align: left;
}

.chatB-text-padding {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.chatB-button,
.chatB-text-padding,
.chatB-text19 {
  align-self: stretch;
}

.chatB-button {
  border-radius: 8px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 14px;
  gap: 4px;
}

.chatB-text19 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #6941c6;
  text-align: left;
}

.chatB-button1,
.chatB-button2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chatB-button1 {
  align-self: stretch;
  border: 1px solid #d6bbfb;
  display: none;
  padding: 10px 13px;
  gap: 4px;
}

.chatB-button2 {
  width: 40px;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  padding: 8px 9px;
}

.chatB-button3,
.chatB-text20 {
  align-self: stretch;
}

.chatB-text20 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
}

.chatB-button3 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
}

.chatB-content5,
.chatB-dropdown5 {
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-dropdown5 {
  display: none;
  flex-direction: column;
}

.chatB-content5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 20px 36px;
  gap: 16px;
}

.chatB-divider-icon4 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  display: none;
  z-index: 0;
}

.chatB-card-header {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  flex-shrink: 0;
}

.chatB-label1,
.chatB-search-placeholder1 {
  font-family: Inter;
  position: relative;
  text-align: left;
}

.chatB-label1 {
  width: 47px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  display: none;
}

.chatB-search-placeholder1 {
  width: calc(100% - 20px);
  border: 0;
  outline: 0;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  flex: 1;
  line-height: 24px;
  color: #667085;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 163px;
  padding: 0;
}

.chatB-input-with-label1,
.chatB-input1 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.chatB-input1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
}

.chatB-input-with-label1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.chatB-hint-text {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: none;
}

.chatB-input-dropdown,
.chatB-search-wrap {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-input-dropdown {
  gap: 8px;
}

.chatB-search-wrap {
  padding: 0 0 12px 30px;
  flex-shrink: 0;
}

.chatB-dot {
  width: 8px;
  height: 8px;
  position: relative;
  border-radius: 5px;
  background-color: #9e77ed;
  overflow: hidden;
  flex-shrink: 0;
}

.chatB-dot-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 0 0;
}

.chatB-contrast-border2 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
}

.chatB-avatar-online-indicator,
.chatB-avatar2 {
  border-radius: 9999px;
  box-sizing: border-box;
  position: relative;
}

.chatB-avatar-online-indicator {
  height: 13px;
  width: 13px;
  background-color: #17b26a;
  border: 1.5px solid #fff;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.chatB-avatar2 {
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  /* padding: 30px 0 0 27px; */
  background-image: url(../../icons/Gerator/dashboard/user-placeholder2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.chatB-message-sender-name,
.chatB-supporting-text3 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}

.chatB-message-sender-name {
  font-weight: 600;
  color: #344054;
  min-width: 97px;
  max-width: 102px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
}

.chatB-supporting-text3 {
  color: #475467;
  min-width: 80px;
}

.chatB-avatar-and-dot,
.chatB-avatar-label-group,
.chatB-text-and-supporting-text1 {
  display: flex;
  flex-direction: row;
  gap: 102px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 10PX 0 0;
}

.chatB-avatar-and-dot,
.chatB-avatar-label-group {
  flex-direction: row;
  gap: 12px;
}

.chatB-avatar-and-dot {
  flex: 1;
  min-width: 157px;
}

.chatB-supporting-text4 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 62px;
}

.chatB-avatar-and-time {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}

.chatB-message-preview {
  flex: 1;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
}

.chatB-message,
.chatB-message-preview-wrap {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 16px 16px 0;
}

.chatB-message {
  border-bottom: 1px solid #eaecf0;
  flex-direction: column;
  padding: 16px 16px 14px;
  gap: 16px;
  flex-shrink: 0;
}

.chatB-dot1 {
  width: 8px;
  height: 8px;
  position: relative;
  border-radius: 5px;
  background-color: #9e77ed;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0;
}

.chatB-supporting-text5,
.chatB-supporting-text6,
.chatB-text21 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}

.chatB-text21 {
  font-weight: 600;
  color: #344054;
  min-width: 106px;
  white-space: nowrap;
}

.chatB-supporting-text5,
.chatB-supporting-text6 {
  color: #475467;
  min-width: 50px;
}

.chatB-supporting-text6 {
  min-width: 71px;
}

.chatB-you {
  font-weight: 500;
}

.chatB-sure-thing-ill,
.chatB-you {
  font-family: Inter;
}

.chatB-message-preview1 {
  flex: 1;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  text-align: left;
}

.chatB-message1 {
  align-self: stretch;
  background-color: #fcfcfd;
  border-bottom: 1px solid #eaecf0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 16px 14px;
  gap: 16px;
  flex-shrink: 0;
}

.chatB-supporting-text7,
.chatB-text22 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}

.chatB-text22 {
  font-weight: 600;
  color: #344054;
  min-width: 70px;
  white-space: nowrap;
}

.chatB-supporting-text7 {
  color: #475467;
  min-width: 53px;
}

.chatB-avatar-and-dot2 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  min-width: 130px;
}

.chatB-supporting-text8 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 49px;
}

.chatB-avatar-and-time2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.chatB-avatar-online-indicator3 {
  height: 13px;
  width: 13px;
  position: relative;
  border-radius: 9999px;
  background-color: #d0d5dd;
  border: 1.5px solid #fff;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.chatB-text23 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 102px;
  white-space: nowrap;
}

.chatB-avatar-and-dot3 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  min-width: 162px;
}

.chatB-supporting-text10 {
  color: #475467;
  min-width: 51px;
}

.chatB-supporting-text10,
.chatB-supporting-text11,
.chatB-text24 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}

.chatB-text24 {
  font-weight: 600;
  color: #344054;
  min-width: 105px;
}

.chatB-supporting-text11 {
  color: #475467;
  min-width: 58px;
}

.chatB-avatar-and-dot4 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  min-width: 165px;
}

.chatB-text25 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 99px;
}

.chatB-avatar-and-dot5 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  min-width: 159px;
}

.chatB-supporting-text14 {
  color: #475467;
  min-width: 52px;
}

.chatB-supporting-text14,
.chatB-supporting-text15,
.chatB-text26 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}

.chatB-text26 {
  font-weight: 600;
  color: #344054;
  min-width: 63px;
  white-space: nowrap;
}

.chatB-supporting-text15 {
  color: #475467;
  min-width: 38px;
}

.chatB-avatar-and-dot6 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  min-width: 123px;
}

.chatB-message-preview6 {
  align-self: stretch;
  flex: 1;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
}

.chatB-message-preview-wrap6,
.chatB-side-panel {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-message-preview-wrap6 {
  align-self: stretch;
  height: 40px;
  flex-direction: row;
  padding: 0 0 0 20px;
}

.chatB-side-panel {
  /* height: 960px; */
  width: 27%;
  border-right: 1px solid #eaecf0;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: 20px 0;
  /* min-width: 360px;
  max-width: 360px; */
  z-index: 1;
}

.chatB-avatar-icon {
  height: 56px;
  width: 56px;
  position: relative;
  border-radius: 9999px;
}

.chatB-caller-name {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter;
  color: #101828;
  text-align: left;
}

.chatB-dot-icon5 {
  height: 8px;
  width: 8px;
  position: relative;
}

.chatB-text27 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: center;
  display: inline-block;
  min-width: 37px;
}

.chatB-badge11,
.chatB-text-and-badge1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.chatB-badge11 {
  width: 61px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  padding: 0 5px;
  gap: 4px;
}

.chatB-text-and-badge1 {
  align-self: stretch;
  padding: 0 217px 0 0;
  gap: 8px;
}

.chatB-supporting-text17 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chatB-avatar-and-text,
.chatB-text-and-supporting-text8 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 275px;
  max-width: 100%;
}

.chatB-avatar-and-text {
  flex-direction: row;
  gap: 16px;
  min-width: 322px;
}

.chatB-text29 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #6941c6;
  text-align: left;
  display: inline-block;
  min-width: 26px;
}

.chatB-button5,
.chatB-text-padding4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.chatB-button5 {
  cursor: pointer;
  border: 1px solid #d6bbfb;
  padding: 8px 13px;
  background-color: #fff;
  width: 82px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  gap: 4px;
}

.chatB-call-button-label {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 40px;
}

.chatB-button6 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  background-color: #fff;
  width: 96px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.chatB-text30 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 93px;
}

.chatB-actions1,
.chatB-button7,
.chatB-content7 {
  display: flex;
  flex-direction: row;
}

.chatB-button7 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.chatB-actions1,
.chatB-content7 {
  justify-content: flex-start;
}

.chatB-actions1 {
  align-items: center;
  gap: 12px;
  max-width: 100%;
}

.chatB-content7 {
  align-items: flex-start;
  padding: 20px 24px 0;
  box-sizing: border-box;
  gap: 16px;
}

.chatB-card-header1,
.chatB-content7 {
  align-self: stretch;
  max-width: 100%;
}

.chatB-divider-icon5 {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  max-width: 100%;
}

.chatB-card-header1 {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  z-index: 2;
}

.chatB-divider {
  align-self: stretch;
  height: 1px;
  position: relative;
  background-color: #eaecf0;
}

.chatB-divider-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 9.5px 0 0;
  box-sizing: border-box;
  min-width: 266px;
  max-width: 100%;
}

.chatB-divider-label {
  height: 20px;
  width: 64px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: center;
  display: inline-block;
}

.chatB-content-divider1 {
  margin-top: -159px;
  width: 898px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  flex-shrink: 0;
  max-width: 100%;
}

.chatB-sub-text-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-sub-text-wrapper {
  width: 104px;
  flex-direction: column;
  padding: 1px 0 0;
  box-sizing: border-box;
}

/* HERE */
/* Parent container for receiver's message */
.chatB-content8 {
  display: inline-flex;
  /* Allow the container to shrink or grow with content */
  flex-direction: column;
  /* Stack username/timestamp and message bubble vertically */
  gap: 6px;
  /* Add spacing between elements */
  max-width: 80%;
  /* Prevent the bubble from getting too wide */
  width: fit-content;
  /* Adjust dynamically to content */
  box-sizing: border-box;
  /* Include padding in width calculation */
  word-wrap: break-word;
  /* Wrap long words properly */
  overflow: hidden;
  /* Prevent content from overflowing */
}

/* Username and timestamp container */
.chatB-name-and-time {
  display: flex;
  /* Flexbox for layout */
  justify-content: space-between;
  /* Space out username and timestamp */
  align-items: center;
  /* Vertically align items */
  width: 100%;
  /* Match parent width */
  /* padding: 0 13px; Consistent padding with message bubble */
  box-sizing: border-box;
  /* Include padding in width */
  word-wrap: break-word;
  /* Wrap long usernames or timestamps */
}

/* Username styling */
.chatB-usernames {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  overflow: hidden;
  /* Prevent overflowing text */
  text-overflow: ellipsis;
  /* Show ellipsis for long text */
  white-space: nowrap;
  /* Keep text on one line */
}

/* Timestamp styling */
.chatB-sub-text {
  font-size: 10px;
  /* line-height: 18px; */
  color: #475467;
  text-align: end;
  /* Align timestamp text to the left */
  padding-left: 12px;
}

/* Message bubble for receiver */
.chatB-input2 {
  display: inline-flex;
  /* Allow dynamic resizing to content */
  flex-direction: column;
  /* Stack content inside */
  background-color: #f9fafb;
  /* Light gray background for received messages */
  border: 1px solid #eaecf0;
  /* Border for received messages */
  border-radius: 8px 8px 8px 8px;
  /* Rounded corners for bubble */
  padding: 8px 13px;
  /* Inner spacing for message content */
  width: fit-content;
  /* Dynamically adjust to message length */
  max-width: 100%;
  /* Prevent exceeding parent width */
  box-sizing: border-box;
  /* Include padding in width calculation */
  word-wrap: break-word;
  /* Ensure long words wrap properly */
  overflow: hidden;
  /* Prevent overflow */
  gap: 5px;
}

/* Row for each receiver message */
.chatB-message12 {
  display: flex;
  /* Flexbox layout */
  flex-direction: row;
  /* Align avatar and message horizontally */
  justify-content: flex-start;
  /* Align received messages to the left */
  align-items: flex-start;
  /* Align items to the top */
  gap: 12px;
  /* Space between avatar and message bubble */
  width: 100%;
  /* Take full width of container */
  margin-bottom: 10px;
  /* Add spacing between messages */
  flex-wrap: nowrap;
  /* Prevent wrapping unless necessary */
}

/* Avatar adjustments for receiver */
.chatB-avatar2 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  /* Size of avatar */
  height: 40px;
  /* Make it a square */
  border-radius: 50%;
  /* Make avatar circular */
  background-color: #dcdcdc;
  /* Placeholder color for avatar */
}

/* Responsive adjustments for smaller screens */
@media screen and (max-width: 750px) {
  .chatB-content8 {
    max-width: 90%;
    /* Allow more space for smaller screens */
  }

  .chatB-message12 {
    flex-wrap: wrap;
    /* Allow wrapping on smaller screens */
  }
}


/* TO HERE */

.chatB-message7 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-message7 {
  width: 100%;
  flex-direction: row;
  gap: 12px;
  max-width: 560px;
}

.chatB-sub-text1 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 104px;
  white-space: nowrap;
}

.chatB-sub-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 0 0;
}

.chatB-file-username,
.chatB-text33 {
  flex: 1;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  max-width: 100%;
}

.chatB-file-username {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 82px;
}

.chatB-name-and-time2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: 100%;
  flex-shrink: 0;
}

.chatB-page-icon {
  position: absolute;
  height: 100%;
  width: 80%;
  top: 0;
  right: 2.5%;
  bottom: 0;
  left: 17.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.chatB-file-type {
  position: relative;
  font-size: 10px;
  display: inline-block;
  font-family: Inter;
  color: #fff;
  text-align: center;
  min-width: 20px;
}

.chatB-file-type-wrap {
  position: absolute;
  height: 40%;
  width: 65%;
  top: 45%;
  right: 32.5%;
  bottom: 15%;
  left: 2.5%;
  border-radius: 2px;
  background-color: #da2d20;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 3px;
  box-sizing: border-box;
  z-index: 1;
}

.chatB-file-type-icon {
  height: 40px;
  width: 40px;
  position: relative;
}

.chatB-supporting-text18,
.chatB-text34 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
}

.chatB-text34 {
  font-weight: 500;
  color: #344054;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chatB-supporting-text18 {
  color: #475467;
}

.chatB-text-and-supporting-text9 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chatB-content11,
.chatB-content12 {
  flex: 1;
  flex-direction: row;
}

.chatB-content12 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 278px;
  max-width: 100%;
}

.chatB-content11 {
  gap: 12px;
}

.chatB-content10,
.chatB-content11,
.chatB-input4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chatB-input4 {
  align-self: stretch;
  border-radius: 0 8px 8px 8px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  padding: 10px 13px;
  min-height: 62px;
  flex-shrink: 0;
}

.chatB-content10 {
  height: 86px;
  flex: 1;
  flex-direction: column;
  gap: 6px;
  min-width: 330px;
}

.chatB-text35 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: Inter;
  color: #fff;
  text-align: left;
}

.chatB-receiever {
  height: 48px;
  flex: 1;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: Inter;
  color: black;
  text-align: left;
  display: inline-block;
  max-width: 100%;
}

.chatB-text31 {
  height: 48px;
  flex: 1;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: Inter;
  color: white;
  text-align: left;
  display: inline-block;
  max-width: 100%;
}

/* HERE */
/* Shared styles for message elements */
.chatB-single-message-username,
.chatB-sub-text3 {
  position: relative;
  font-family: "Inter", Arial, sans-serif;
  /* Apply imported font */
  display: inline-block;
  white-space: nowrap;
}

.chatB-single-message-username {
  flex: 1;
  /* Let "You" take up available space */
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  text-align: left;
  /* Align username to the left */
}

.chatB-sub-text3 {
  flex-shrink: 0;
  /* Prevent shrinking */
  font-size: 12px;
  /* line-height: 18px; */
  color: #ffff;
  text-align: right;
  /* Align timestamp to the right */
  padding-left: 12px;
}

/* Parent container for each message */
.chatB-content13 {
  display: inline-flex;
  /* Allows dynamic resizing based on children */
  flex-direction: column;
  /* Stack name/time and bubble vertically */
  max-width: 55%;
  /* Restrict to reasonable width on larger screens */
  width: fit-content;
  /* Dynamically adjust width to content */
  gap: 4px;
  /* Space between name/time and bubble */
  word-wrap: break-word;
  /* Handle long messages */
  box-sizing: border-box;
  /* Include padding in width calculation */
}

/* Username and timestamp row */
.chatB-name-and-time3 {
  display: flex;
  /* Use Flexbox for layout */
  justify-content: space-between;
  /* Space out username and timestamp */
  align-items: center;
  /* Vertically align content */
  width: 100%;
  /* Inherit width from parent container (chat-content13) */
  padding: 0 8px;
  /* Add padding for alignment */
  box-sizing: border-box;
  /* Include padding in width */
}

/* Message bubble */
.chatB-input5 {
  display: inline-flex;
  /* Allow dynamic resizing to content */
  flex-direction: column;
  /* Stack content inside */
  background-color: #7e56d9;
  /* Purple background for sent messages */
  border-radius: 8px;
  /* Rounded corners */
  padding: 8px 13px;
  /* Inner spacing for message content */
  width: fit-content;
  /* Adjust dynamically to the content size */
  max-width: 100%;
  /* Prevent exceeding parent width */
  box-sizing: border-box;
  /* Include padding in width calculation */
  word-wrap: break-word;
  /* Handle long words properly */
  color: white;
  /* Text color for contrast */
  gap: 5px;
}

/* Row for each message */
.chatB-row {
  display: flex;
  flex-direction: row;
  /* Align name/time and message bubble horizontally */
  justify-content: flex-end;
  /* Align sent messages to the right */
  width: 100%;
  /* Take full width of the container */
  margin-bottom: 10px;
  /* Space between rows */
  padding-right: 30px;
}

/* Responsive adjustments for small screens */
@media screen and (max-width: 750px) {
  .chatB-row {
    flex-wrap: wrap;
    /* Allow wrapping of elements */
    max-width: 100%;
    /* Ensure full width usage */
  }

  .chatB-name-and-time3,
  .chatB-input5 {
    max-width: 100%;
    /* Ensure bubbles and content adjust to screen width */
  }
}

/* TO HERE */

/* .chatB-input5 {
  align-self: self-end;
  border-radius: 8px 8px 8px 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: column;
  padding: 8px 13px;
  max-width: 100%;
}
.chatB-content13,
.chatB-input5,
.chatB-row {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.chatB-content13{
  flex: 1;
  flex-direction: column;
  gap: 6px;
  min-width: 330px;
  max-width: 100%;
}

.chatB-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  max-width: 560px;
}
.chatB-row {
  align-self: stretch;
  max-width: 100%;
  display: flex;
  align-items: flex-start;

  flex-direction: row;
  justify-content: flex-end;

  align-self: stretch;
  max-width: 100%;
}

@media screen and (width <= 750px) {
  .chatB-row {
      flex-wrap: wrap;
      max-width: 100%;
  }
} */
/* .chatB-input5 {
  border-radius: 8px 0 8px 8px;
  background-color: #7e56d9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 14px;
  white-space: nowrap;
}
.chatB-content13,
.chatB-message10,
.chatB-row {
  display: flex;
  align-items: flex-start;
}
.chatB-content13 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 6px;
  max-width: 100%;
}
.chatB-message10,
.chatB-row {
  flex-direction: row;
  justify-content: flex-end;
}
.chatB-message10 {
  width: 330px;
  max-width: 330px;
}
.chatB-row {
  align-self: stretch;
  max-width: 100%;
} */
.chatB-avatar-message,
.chatB-divider-frame,
.chatB-message11 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-message11 {
  flex: 1;
  flex-direction: row;
  gap: 12px;
  max-width: 560px;
}

.chatB-avatar-message,
.chatB-divider-frame {
  box-sizing: border-box;
  max-width: 100%;
}

.chatB-avatar-message {
  width: 560px;
  flex-direction: row;
  padding: 0 0 8px;
}

.chatB-divider-frame {
  flex: 1;
  flex-direction: column;
  padding: 9.chatB-5px 0 0;
  min-width: 273px;
}

.chatB-second-divider-label {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: center;
  display: inline-block;
  min-width: 41px;
}

.chatB-content-divider2,
.chatB-second-content-divider {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chatB-content-divider2 {
  flex: 1;
  gap: 8px;
}

.chatB-second-content-divider {
  align-self: stretch;
  padding: 0 0 8px;
  box-sizing: border-box;
}

.chatB-sub-text5 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 82px;
}

.chatB-reaction-username,
.chatB-sub-text6 {
  position: relative;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}

.chatB-reaction-username {
  flex: 1;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 19px;
  max-width: 100%;
}

.chatB-sub-text6 {
  font-size: 12px;
  line-height: 18px;
  color: #475467;
  min-width: 52px;
}

.chatB-name-and-time6 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  max-width: 100%;
}

.chatB-emoji-icon {
  height: 16px;
  width: 16px;
  position: relative;
  object-fit: cover;
}

.chatB-message-reaction {
  border-radius: 9999px;
  background-color: #f9fafb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.chatB-message13,
.chatB-row2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.chatB-row2 {
  align-items: center;
  padding: 0 0 0 374px;
  gap: 4px;
}

.chatB-message13 {
  width: 442px;
  align-items: flex-start;
  max-width: 442px;
}

.chatB-loading-dot {
  height: 4px;
  width: 4px;
  position: relative;
  border-radius: 50%;
  background-color: #475467;
}

.chatB-loading-dot-frame {
  height: 8px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.chatB-loading-dot1 {
  height: 4px;
  width: 4px;
  position: relative;
  border-radius: 50%;
  background-color: #98a2b3;
}

.chatB-loading-dot-frame1 {
  height: 8px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.chatB-input9,
.chatB-messages {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-input9 {
  width: 40px;
  border-radius: 0 8px 8px 8px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  flex-direction: row;
  padding: 8px 9px;
  gap: 4px;
}

.chatB-messages {
  flex: 1;
  flex-direction: column;
  gap: 16px;
  min-width: 584px;
  max-width: 100%;
}


/* .chatB-message-row-reverse{
  flex-direction: column-reverse;
  width: 100%;
  justify-content: flex-end;
  display: flex;
} */
.chatB-message-separator {
  width: 8px;
  flex: 1;
  position: relative;
  border-radius: 8px;
  background-color: #dadada;
  z-index: 4;
}

.chatB-separator {
  height: 331px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 151px 0 0;
  box-sizing: border-box;
}

.chatB-messages-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  flex-shrink: 0;
  max-width: 100%;
}

.chatB-container1 {
  height: 735px;
  max-height: 735px;
  overflow-y: scroll;
  scrollbar-width: none;
  flex-direction: column-reverse;
  /* justify-content: flex-end; */
  padding: 0 24px 24px 24px;
  box-sizing: border-box;
  gap: 24px;
  /* z-index: 3; */
  flex-shrink: 0;
  max-width: 100%;
  align-self: stretch;
  display: flex;

  align-items: flex-start;
}

.chatB-label2,
.chatB-message-placeholder {
  font-family: Inter;
  position: relative;
  text-align: left;
}

.chatB-label2 {
  width: 77px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  display: none;
}

.chatB-message-placeholder {
  width: 100%;
  border: 0;
  outline: 0;
  font-size: 16px;
  background-color: transparent;
  height: 80px;
  flex: 1;
  line-height: 24px;
  color: #667085;
  display: inline-block;
  min-width: 250px;
  max-width: 100%;
  padding: 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  resize: none;
  scrollbar-width: none;
}

.chat-message-input::placeholder {
  line-height: 24px;
  /* Matches the line-height of text */
  vertical-align: middle;
  display: flex;
  align-items: center;
  height: 100%;
}

.chatB-input10 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  /* overflow: hidden; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 13px;
  max-width: 100%;
}

.chatB-hint-text1 {
  width: 320px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: none;
}

.chatB-textarea-input-field {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  max-width: 100%;
}

.chatB-buttonsbutton4,
.chatB-icons {
  display: flex;
  flex-direction: row;
}

.chatB-buttonsbutton4 {
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.chatB-icons {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}

.chatB-send-label {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 35px;
}

.chatB-buttonsbutton6 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.chatB-actions2,
.chatB-buttonsbutton6,
.chatB-input-actions {
  display: flex;
  flex-direction: row;
}

.chatB-actions2 {
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  z-index: 1;
}

.chatB-input-actions {
  width: 173px;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 12px;
  box-sizing: border-box;
  /* margin-top: -52px; */
}

.chatB-card-header-parent,
.chatB-message-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
}

.chatB-message-input {
  align-self: stretch;
  height: 128px;
  background-color: #fff;
  align-items: flex-end;
  padding: 0 24px 24px;
  box-sizing: border-box;
  margin-bottom: 10px;
  /* z-index: 4; */
}

.chatB-card-header-parent {
  flex: 1;
  align-items: flex-start;
  min-width: 615px;
}

.chatB-buttonsbutton7,
.chatB-chat-content {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
}

.chatB-chat-content {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
  max-width: 100%;
}

.chatB-buttonsbutton7 {
  width: 36px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 6px 7px;
}

.chatB-details {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 81px;
}

.chatB-chat-panel,
.chatB-pagination {
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.chatB-pagination {
  width: 360px;
  border-top: 1px solid #eaecf0;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  gap: 20px;
  z-index: 1;
}

.chatB-chat-panel {
  margin: 0;
  align-self: stretch;
  /* padding: 0 0 0 24px; MESSAGES NEW */
  gap: 16px;
}

.chatB-chat-panel,
.chatB-main-content,
.chatB-main-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.chatB-main-content {
  align-self: stretch;
  align-items: flex-end;
  gap: 24px;
  max-width: 100%;
}

.chatB-main-content-wrapper {
  flex: 1;
  align-items: flex-start;
  padding: 32px 0 0;
  box-sizing: border-box;
  /* max-width: calc(100% - 80px); */
  width: 70%;
}

.chatB-frame-parent,
.chatB-sidebar-navigation-parent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chatB-sidebar-navigation-parent {
  flex-direction: row;
  /* padding: 0 30px 0 0; */
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  color: #475467;
  font-family: Inter;
}

.chatB-frame-parent {
  flex-direction: column;
  gap: 0.5px;
  font-family: Inter;
}

.chatB-footer-text {
  position: relative;
  line-height: 20px;
}

.chatB-footer-text-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chatB-gerator-10-2-icon {
  height: 38px;
  width: 38px;
  position: relative;
  object-fit: cover;
}

.chatB-logomark1 {
  box-shadow: 0 1.3px 3.75px rgba(16, 24, 40, 0.1),
    0 1.3px 2.5px rgba(16, 24, 40, 0.06);
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-frame-parent3,
.chatB-logomark,
.chatB-logomark1 {
  display: flex;
  flex-direction: row;
}

.chatB-logomark {
  filter: drop-shadow(0 1.3px 3.75px rgba(16, 24, 40, 0.1)) drop-shadow(0 1.3px 2.5px rgba(16, 24, 40, 0.06));
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-frame-parent3 {
  align-items: center;
  justify-content: center;
  gap: 29px;
}

.chatB-content18,
.chatB-logo1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-content18 {
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  gap: 20px;
}

.chatB-container2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 32px;
  box-sizing: border-box;
  max-width: 1280px;
}

.chatB-divider-icon6,
.chatB-footer1 {
  align-self: stretch;
  overflow: hidden;
}

.chatB-footer1 {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 48px 80px;
  z-index: 1;
}

.chatB-divider-icon6 {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  z-index: 2;
  margin-top: -120px;
}

.chatB-footer-parent,
.chatB-messaging-with-list-view-car {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chatB-footer-parent {
  align-self: stretch;
  min-height: 134px;
  text-align: left;
  font-size: 16px;
  color: #475467;
  font-family: Inter;
}

.chatB-messaging-with-list-view-car {
  width: 100%;
  position: relative;
  background-color: #fff;
  /* overflow: visible; */
  padding: 7px 0 0;
  box-sizing: border-box;
  /* gap: 49px; */
  line-height: normal;
  letter-spacing: normal;
  /* height: 100vh; */
  margin: 0 auto;
  max-width: 1524px;
}

/* Analytics css  */
.analytics-metric-card-chat-module-divider-icon8 {
  /* width: 968px; */
  position: relative;
  max-height: 100%;
  /* max-width: 100%; */
  width: 100%;
  z-index: 2;
}

.analytics-metric-card-chat-module-metric-item-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: max-content;
  /* width: 968px; */
  gap: 25px;
  max-width: 100%;
  font-size: 16px;
  overflow-x: scroll;
  scrollbar-width: none;
}


.analytics-metric-card-chat-module-metric-item3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 16px 15px 20px;
  position: relative;
  gap: 20px;
  /* min-width: 229px; */
  width: max-content;
  z-index: 2;
}

.analytics-metric-card-chat-module-heading9 {
  width: 254px;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}

.analytics-metric-card-chat-module-number-and-chart {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 16px;
  font-size: 30px;
}

.analytics-metric-card-chat-module-number-and-badge3 {
  display: flex;
  justify-content: flex-start;

  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  min-width: 105px;
}

.analytics-metric-card-chat-module-heading {
  line-height: 38px;
  font-weight: 600;
}

.analytics-metric-card-chat-module-change-and-text3 {
  display: flex;
  justify-content: flex-start;

  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-size: 14px;
  color: #079455;
}

.analytics-metric-card-chat-module-change2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1px;
}

.analytics-metric-card-chat-module-upload-04-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}


.analytics-metric-card-chat-module-change7 {
  position: relative;
  line-height: 20px;
  font-weight: 500;

  display: inline-block;
  min-width: 38px;
}

.analytics-metric-card-chat-module-text139 {
  position: relative;
  line-height: 20px;
  font-weight: 500;

  flex: 1;
  color: #475467;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.analytics-metric-card-chat-module-chart-mini-icon3 {
  height: 48px;
  width: 96px;
  position: relative;
  object-fit: contain;
}

.analytics-metric-card-chat-module-dropdown19 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin: 0 !important;
  position: absolute;
  top: 16px;
  right: 16px;
}

.analytics-metric-card-chat-module-dots-vertical-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}


@media screen and (max-width: 1275px) {
  .chatB-container2 {
    max-width: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .chatB-content-container {
    flex-wrap: wrap;
    min-width: 100%;
  }

  .chatB-side-panel {
    width: 100%;
    padding-bottom: 42px;
    box-sizing: border-box;
  }

  .chatB-content-divider1,
  .chatB-content-divider2,
  .chatB-content7,
  .chatB-messages-parent {
    flex-wrap: wrap;
  }

  .chatB-container1 {
    height: auto;
  }

  .chatB-chat-content {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 750px) {
  .chatB-navbar-parent {
    gap: 19px;
  }

  .chatB-logo-container-parent {
    gap: 35px;
  }

  .chatB-rectangle-parent {
    padding-right: 32px;
    box-sizing: border-box;
  }

  .chatB-badges-parent {
    flex-wrap: wrap;
  }

  .chatB-content-container-inner {
    min-width: 100%;
  }

  .chatB-content-container {
    gap: 25px;
  }

  .chatB-number {
    font-size: 24px;
    line-height: 30px;
  }

  .chatB-side-panel {
    padding-bottom: 27px;
    box-sizing: border-box;
  }

  .chatB-avatar-and-text,
  .chatB-name-and-time,
  .chatB-text-and-badge1 {
    flex-wrap: wrap;
  }

  .chatB-message7 {
    max-width: 100%;
  }

  .chatB-content11,
  .chatB-message7,
  .chatB-name-and-time2 {
    flex-wrap: wrap;
  }

  .chatB-content10 {
    height: auto;
  }

  .chatB-message11 {
    flex-wrap: wrap;
    max-width: 100%;
  }

  .chatB-row2 {
    flex-wrap: wrap;
  }

  .chatB-card-header-parent,
  .chatB-messages {
    min-width: 100%;
  }

  .chatB-container2 {
    gap: 16px;
  }

  .chatB-footer1 {
    gap: 32px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }

  .chatB-messaging-with-list-view-car {
    gap: 24px;
  }
}

@media screen and (max-width: 450px) {
  .chatB-logo-container-parent {
    gap: 17px;
  }

  .chatB-price {
    font-size: 16px;
    line-height: 26px;
  }

  .chatB-number {
    font-size: 18px;
    line-height: 23px;
  }

  .chatB-text-and-badge {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .chatB-avatar-and-time,
  .chatB-avatar-and-time2,
  .chatB-content5 {
    flex-wrap: wrap;
  }

  .chatB-side-panel {
    height: auto;
    min-width: 100%;
  }

  .chatB-text-and-badge1 {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .chatB-actions1,
  .chatB-name-and-time3,
  .chatB-name-and-time6 {
    flex-wrap: wrap;
  }

  .chatB-row2 {
    padding-left: 20px;
    box-sizing: border-box;
  }

  .chatB-message13 {
    max-width: 100%;
  }

  .chatB-footer1 {
    gap: 16px;
  }
}


/* 7e56d9 */


/* 
.load-more-button {
  margin: 10px auto;
  padding: 8px 16px;
  font-size: 14px;
  background-color: #7e56d9;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.load-more-button:hover {
  background-color: #7e56d9;
}

.load-more-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
} */

.load-more-button {
  margin: 10px auto;
  padding: 8px 16px;
  font-size: 14px;
  background-color: #fff;
  color: #344054;
  box-shadow: 0 1px 2px Orgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-weight: 600;
  border: 1px solid #d0d5dd;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

•load-more-button:hover {
  background-color: #eaecf0;
}

.load-more-botton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}