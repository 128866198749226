.nothing-found-message-badge-label,
.nothing-found-message-text58 {
  text-decoration: none;
  position: relative;
  line-height: 28px;
  font-weight: 600;
  color: inherit;
}
.nothing-found-message-badge-label {
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 73px;
}
.nothing-found-message-badge2,
.nothing-found-message-text-and-badge2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.nothing-found-message-badge2 {
  width: 89px;
  border-radius: 9999px;
  background-color: #f9f5ff;
  border: 1px solid #e9d7fe;
  box-sizing: border-box;
  padding: 0 7px;
  white-space: nowrap;
  text-align: center;
  font-size: 12px;
  color: #6941c6;
}
.nothing-found-message-text-and-badge2 {
  align-self: stretch;
  padding: 0 332px 0 0;
  gap: 7px;
}
.nothing-found-message-supporting-text14 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.nothing-found-message-text-and-supporting-text3 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  min-width: 436px;
  max-width: 100%;
}
.nothing-found-message-placeholder-icon20 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}
.nothing-found-message-text59 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.nothing-found-message-text-padding13 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 14px;
}
.nothing-found-message-button9 {
  border-radius: 8px;
  overflow: hidden;
  display: none;
  padding: 10px 14px;
  gap: 4px;
}
.nothing-found-message-button10,
.nothing-found-message-button9,
.nothing-found-message-text-padding14 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.nothing-found-message-text-padding14 {
  display: flex;
  padding: 0 13px;
}
.nothing-found-message-button10 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d6bbfb;
  overflow: hidden;
  display: none;
  padding: 10px 14px;
  gap: 4px;
  color: #6941c6;
}
.nothing-found-message-upload-cloud-02-icon1 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}
.nothing-found-message-text61 {
  text-decoration: none;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 45px;
}
.nothing-found-message-button11,
.nothing-found-message-text-padding15 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}
.nothing-found-message-button11 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  gap: 4px;
}
.nothing-found-message-text62 {
  text-decoration: none;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 73px;
}
.nothing-found-message-actions4,
.nothing-found-message-button12 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.nothing-found-message-button12 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  justify-content: center;
  gap: 4px;
}
.nothing-found-message-actions4 {
  justify-content: flex-start;
  gap: 12px;
  font-size: 14px;
  color: #475467;
}
.nothing-found-message-dots-vertical-icon12 {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.nothing-found-message-content12,
.nothing-found-message-dropdown12 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.nothing-found-message-content12 {
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px 24px 0;
  box-sizing: border-box;
  gap: 16px;
}
.nothing-found-message-card-header2,
.nothing-found-message-content12,
.nothing-found-message-divider-icon2 {
  align-self: stretch;
  max-width: 100%;
}
.nothing-found-message-divider-icon2 {
  position: relative;
  overflow: hidden;
  max-height: 100%;
}
.nothing-found-message-card-header2 {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}
.nothing-found-message-filter-label {
  text-decoration: none;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 53px;
}
.nothing-found-message-button-group-base9 {
  cursor: pointer;
  border: 0;
  padding: 10px 15px;
  background-color: #f9fafb;
  width: 85px;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  white-space: nowrap;
}
.nothing-found-message-button-group-base9:hover {
  background-color: #e0e0e0;
}
.nothing-found-message-text63 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 70px;
}
.nothing-found-message-button-group-base10 {
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
}
.nothing-found-message-text64 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}
.nothing-found-message-button-group-base11,
.nothing-found-message-button-group-base12,
.nothing-found-message-button-group-base13,
.nothing-found-message-button-group-base14,
.nothing-found-message-button-group-base15,
.nothing-found-message-button-group-base16 {
  align-self: stretch;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  min-height: 40px;
  z-index: 6;
}
.nothing-found-message-button-group-base12,
.nothing-found-message-button-group-base13,
.nothing-found-message-button-group-base14,
.nothing-found-message-button-group-base15,
.nothing-found-message-button-group-base16 {
  z-index: 5;
}
.nothing-found-message-button-group-base13,
.nothing-found-message-button-group-base14,
.nothing-found-message-button-group-base15,
.nothing-found-message-button-group-base16 {
  z-index: 4;
}
.nothing-found-message-button-group-base14,
.nothing-found-message-button-group-base15,
.nothing-found-message-button-group-base16 {
  z-index: 3;
}
.nothing-found-message-button-group-base15,
.nothing-found-message-button-group-base16 {
  z-index: 2;
}
.nothing-found-message-button-group-base16 {
  z-index: 1;
}
.nothing-found-message-text70 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 89px;
}
.nothing-found-message-button-group1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.nothing-found-message-label1 {
  width: 47px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}
.nothing-found-message-search-lg-icon1 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.nothing-found-message-supporting-text15,
.nothing-found-message-text71 {
  position: relative;
  line-height: 24px;
}
.nothing-found-message-text71 {
  text-decoration: none;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 82px;
}
.nothing-found-message-supporting-text15 {
  align-self: stretch;
  width: 54px;
  color: #475467;
  display: none;
}
.nothing-found-message-content14,
.nothing-found-message-input-with-label1,
.nothing-found-message-input1 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}
.nothing-found-message-content14 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 262px 0 0;
  box-sizing: border-box;
  gap: 8px;
}
.nothing-found-message-input-with-label1,
.nothing-found-message-input1 {
  align-self: stretch;
}
.nothing-found-message-input1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
    0 0 0 4px rgba(158, 119, 237, 0.24);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d6bbfb;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  font-size: 16px;
  color: #101828;
}
.nothing-found-message-input-with-label1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}
.nothing-found-message-hint-text1 {
  width: 320px;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}
.nothing-found-message-input-dropdown1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: 400px;
}
.nothing-found-message-text72 {
  text-decoration: none;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 43px;
}
.nothing-found-message-actions5,
.nothing-found-message-button13 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.nothing-found-message-button13 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 10px 13px;
  background-color: #fff;
  width: 99px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  gap: 4px;
}
.nothing-found-message-actions5 {
  width: 511px;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
}
.nothing-found-message-card-header-parent,
.nothing-found-message-content13,
.nothing-found-message-filters-bar1 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}
.nothing-found-message-content13 {
  border-radius: 12px;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  box-sizing: border-box;
  gap: 20px;
}
.nothing-found-message-card-header-parent,
.nothing-found-message-filters-bar1 {
  flex-direction: column;
  justify-content: flex-start;
}
.nothing-found-message-filters-bar1 {
  background-color: #fff;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  font-size: 14px;
  color: #344054;
}
.nothing-found-message-card-header-parent {
  text-align: left;
  font-size: 26px;
  color: #101828;
  font-family: Inter;
}
.nothing-found-message-featured-icon {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 10px;
}
.nothing-found-message-empty-title,
.nothing-found-message-supporting-text16 {
  align-self: stretch;
  position: relative;
}
.nothing-found-message-empty-title {
  line-height: 24px;
  font-weight: 600;
}
.nothing-found-message-supporting-text16 {
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}
.nothing-found-message-content15,
.nothing-found-message-text-and-supporting-text4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.nothing-found-message-text-and-supporting-text4 {
  width: 352px;
  gap: 4px;
  max-width: 352px;
}
.nothing-found-message-content15 {
  align-self: stretch;
  padding: 0 20px;
  gap: 16px;
}
.nothing-found-message-placeholder-icon27 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.nothing-found-message-text73 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 98px;
}
.nothing-found-message-buttonsbutton4 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 15px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.nothing-found-message-text74 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 91px;
}
.nothing-found-message-actions6,
.nothing-found-message-buttonsbutton5 {
  display: flex;
  flex-direction: row;
}
.nothing-found-message-buttonsbutton5 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 15px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.nothing-found-message-actions6 {
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 20px;
  box-sizing: border-box;
  gap: 12px;
  max-width: 100%;
}
.nothing-found-message-empty-state2,
.nothing-found-message-empty-state3 {
  display: flex;
  align-items: center;
  max-width: 100%;
}
.nothing-found-message-empty-state3 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
}
.nothing-found-message-empty-state2 {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  color: #101828;
  font-family: Inter;
}
.nothing-found-message-search-results {
  width: 1016px;
  background-color: #fff;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 2px;
  box-sizing: border-box;
  gap: 67px;
  line-height: normal;
  letter-spacing: normal;
}
@media screen and (max-width: 900px) {
  .nothing-found-message-content13,
  .nothing-found-message-text-and-badge2 {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 675px) {
  .nothing-found-message-text-and-badge2 {
    padding-right: 166px;
    box-sizing: border-box;
  }
  .nothing-found-message-text-and-supporting-text3 {
    min-width: 100%;
  }
  .nothing-found-message-search-results {
    gap: 33px;
  }
}
@media screen and (max-width: 450px) {
  .nothing-found-message-text58 {
    font-size: 21px;
    line-height: 22px;
  }
  .nothing-found-message-content14,
  .nothing-found-message-text-and-badge2 {
    padding-right: 20px;
    box-sizing: border-box;
  }
  .nothing-found-message-input-dropdown1 {
    max-width: 100%;
  }
  .nothing-found-message-actions5 {
    flex-wrap: wrap;
  }
  .nothing-found-message-search-results {
    gap: 17px;
  }
}
