/* Custom CSS */
/* .funnel-detail-software-funnel-detail-software-frame-layout-custom {
  margin-top: 0px !important;
} */

.funnel-detail-software-buttonsbutton-wrapper1-child {
    outline: none;
    border: none;
    background-color: #fff;
    padding: 0;
  }
  
  
  .funnel-detail-software-main-custom {
    width: 100% !important;
  }
  
  .funnel-detail-software-search-lg-icon-custom {
    cursor: pointer;
  }
  
  .funnel-detail-software-funnel-detail-software-frame-layout-child {
    position: relative;
    background-color: #fff;
    display: none;
  }
  
  /* .funnel-detail-software-funnel-detail-software-frame-layout-child {
    align-self: stretch;
    height: 8320px;
    margin-top: 200px;
  } */
  
  
  
  
  
  
  
  
  .funnel-detail-software-text {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    min-width: 30px;
  }
  
  .funnel-detail-software-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  }
  
  
  .funnel-detail-software-chevron-down-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  
  
  .funnel-detail-software-badge1,
  .funnel-detail-software-nav-item-base1 {
    background-color: #f9fafb;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  
  
  .funnel-detail-software-navigation,
  .funnel-detail-software-search-input {
    display: flex;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-search-input {
    flex-direction: column;
    align-items: flex-start;
    padding: 2px 0 0;
  }
  
  .funnel-detail-software-label {
    width: 47px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: none;
  }
  
  .funnel-detail-software-search-lg-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .funnel-detail-software-search-placeholder {
    flex: 1;
    position: relative;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 8px;
    outline: none;
    border: none;
    font-size: 16px;
  }
  
  .funnel-detail-software-content3,
  .funnel-detail-software-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-content3 {
    flex: 1;
    gap: 8px;
  }
  
  
  
  
  
  
  
  
  .funnel-detail-software-divider-icon,
  .funnel-detail-software-home-line-icon {
    position: relative;
    overflow: hidden;
  }
  
  .funnel-detail-software-divider-icon {
    align-self: stretch;
    max-width: 100%;
    max-height: 100%;
    z-index: 1;
  }
  
  
  
  .funnel-detail-software-contrast-border1 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 9999px;
    border: 0.8px solid rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    overflow: hidden;
  }
  
  
  
  
  
  .funnel-detail-software-arrow-narrow-left-icon {
    height: 24px;
    width: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 24px;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  .funnel-detail-software-content12,
  .funnel-detail-software-navigation4 {
    display: flex;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-content12 {
    flex: 1;
    flex-direction: row;
    align-items: center;
    padding: 0 142px 0 0;
    gap: 12px;
  }
  
  .funnel-detail-software-content5,
  .funnel-detail-software-navigation4 {
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .funnel-detail-software-content5 {
    padding: 36px 16px 0;
    gap: 16px;
  }
  
  .funnel-detail-software-supporting-text,
  .funnel-detail-software-title {
    align-self: stretch;
    position: relative;
    line-height: 20px;
  }
  
  .funnel-detail-software-title {
    font-weight: 600;
  }
  
  .funnel-detail-software-supporting-text {
    color: #475467;
    white-space: nowrap;
  }
  
  
  .funnel-detail-software-breadcrumb-button-base {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-breadcrumb-button-base {
    border-radius: 6px;
    padding: 4px;
  }
  
  .funnel-detail-software-chevron-right-icon {
    height: 16px;
    width: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .funnel-detail-software-location {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 74px;
  }
  
  .funnel-detail-software-breadcrumb-button-base1 {
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
  }
  
  .funnel-detail-software-text20 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  
  .funnel-detail-software-breadcrumb-button-base2 {
    align-self: stretch;
    border-radius: 6px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 7px;
  }
  
  .funnel-detail-software-chevron-right-icon2 {
    height: 16px;
    width: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  
  .funnel-detail-software-text21 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    white-space: nowrap;
  }
  
  .funnel-detail-software-breadcrumb-button-base3 {
    align-self: stretch;
    border-radius: 6px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
  }
  
  .funnel-detail-software-text22 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 65px;
  }
  
  .funnel-detail-software-breadcrumb-button-base4 {
    border-radius: 6px;
    background-color: #f9fafb;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    color: #344054;
  }
  
  .funnel-detail-software-tabs {
    gap: 8px;
  }
  
  .funnel-detail-software-breadcrumbs,
  .funnel-detail-software-tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-heading,
  .funnel-detail-software-supporting-text1 {
    align-self: stretch;
    position: relative;
  }
  
  .funnel-detail-software-heading {
    line-height: 38px;
    font-weight: 600;
  }
  
  .funnel-detail-software-supporting-text1 {
    font-size: 16px;
    line-height: 24px;
    color: #475467;
  }
  
  .funnel-detail-software-text-and-supporting-text {
    width: 678px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    min-width: 320px;
    max-width: 100%;
    flex-shrink: 0;
    font-size: 30px;
    color: #101828;
  }
  
  .funnel-detail-software-placeholder-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
    min-height: 20px;
  }
  
  .funnel-detail-software-text-padding {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  
  .funnel-detail-software-button,
  .funnel-detail-software-button1,
  .funnel-detail-software-button2 {
    height: 40px;
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: center;
    gap: 4px;
  }
  
  .funnel-detail-software-button {
    flex-direction: row;
    align-items: center;
    padding: 10px 14px;
  }
  
  .funnel-detail-software-button1,
  .funnel-detail-software-button2 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    background-color: #fff;
    padding: 10px 13px;
  }
  
  .funnel-detail-software-button1 {
    border: 1px solid #d6bbfb;
    flex-direction: row;
    align-items: center;
    color: #6941c6;
  }
  
  .funnel-detail-software-button2 {
    border: 1px solid #d0d5dd;
    color: #344054;
  }
  
  .funnel-detail-software-actions,
  .funnel-detail-software-button2,
  .funnel-detail-software-button3 {
    flex-direction: row;
    align-items: center;
  }
  
  .funnel-detail-software-button3 {
    height: 40px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #7e56d9;
    border: 1px solid #7e56d9;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding: 10px 13px;
    gap: 4px;
    color: #fff;
  }
  
  .funnel-detail-software-actions {
    display: none;
    justify-content: flex-start;
    gap: 12px;
    max-width: 100%;
  }
  
  .funnel-detail-software-hint-text {
    width: 320px;
    position: relative;
    line-height: 20px;
    display: none;
  }
  
  .funnel-detail-software-button4,
  .funnel-detail-software-button5 {
    align-self: stretch;
    border-radius: 8px;
    overflow: hidden;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 14px;
    gap: 4px;
  }
  
  .funnel-detail-software-button5 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    background-color: #fff;
    border: 1px solid #d6bbfb;
    padding: 10px 13px;
    color: #6941c6;
  }
  
  .funnel-detail-software-upload-04-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 20px;
  }
  
  .funnel-detail-software-text29 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 45px;
  }
  
  .funnel-detail-software-button6,
  .funnel-detail-software-text-padding6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  
  .funnel-detail-software-button6 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    padding: 8px 13px;
    gap: 4px;
    color: #344054;
  }
  
  .funnel-detail-software-text30 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 73px;
  }
  
  .funnel-detail-software-button7 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #7e56d9;
    border: 1px solid #7e56d9;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 13px;
    gap: 4px;
    color: #fff;
  }
  
  .funnel-detail-software-content13,
  .funnel-detail-software-input-dropdown {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-input-dropdown {
    width: 320px;
    flex-direction: column;
    gap: 8px;
    min-width: 200px;
    max-width: 320px;
    flex-shrink: 0;
  }
  
  .funnel-detail-software-content13 {
    flex-direction: row;
    padding: 0;
    box-sizing: border-box;
    gap: 70px;
  }
  
  .funnel-detail-software-content13,
  .funnel-detail-software-divider-icon1,
  .funnel-detail-software-page-header {
    align-self: stretch;
    max-width: 100%;
  }
  
  .funnel-detail-software-divider-icon1 {
    position: relative;
    overflow: hidden;
    max-height: 100%;
    display: none;
  }
  
  .funnel-detail-software-page-header {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }
  
  .funnel-detail-software-text31 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 71px;
  }
  
  .funnel-detail-software-button-group-base {
    background-color: #f9fafb;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
    white-space: nowrap;
  }
  
  .funnel-detail-software-text32 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 55px;
  }
  
  .funnel-detail-software-button-group-base1,
  .funnel-detail-software-button-group-base2 {
    width: 87px;
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
    white-space: nowrap;
  }
  
  .funnel-detail-software-button-group-base2 {
    width: 77px;
  }
  
  .funnel-detail-software-text34 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
  }
  
  .funnel-detail-software-button-group-base3,
  .funnel-detail-software-button-group-base4,
  .funnel-detail-software-button-group-base5,
  .funnel-detail-software-button-group-base6,
  .funnel-detail-software-button-group-base7 {
    align-self: stretch;
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    min-height: 40px;
    z-index: 5;
  }
  
  .funnel-detail-software-button-group-base4,
  .funnel-detail-software-button-group-base5,
  .funnel-detail-software-button-group-base6,
  .funnel-detail-software-button-group-base7 {
    z-index: 4;
  }
  
  .funnel-detail-software-button-group-base5,
  .funnel-detail-software-button-group-base6,
  .funnel-detail-software-button-group-base7 {
    z-index: 3;
  }
  
  .funnel-detail-software-button-group-base6,
  .funnel-detail-software-button-group-base7 {
    z-index: 2;
  }
  
  .funnel-detail-software-button-group-base7 {
    z-index: 1;
  }
  
  .funnel-detail-software-text39 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 61px;
  }
  
  .funnel-detail-software-button-group,
  .funnel-detail-software-button-group-base8 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
  }
  
  .funnel-detail-software-button-group-base8 {
    width: 93px;
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
    white-space: nowrap;
  }
  
  .funnel-detail-software-button-group {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    overflow-x: auto;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-filter-date {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 85px;
  }
  
  .funnel-detail-software-buttonsbutton1 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 13px;
    gap: 4px;
    cursor: pointer;
    text-wrap: nowrap;
  }
  
  .funnel-detail-software-date-picker-dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-filter-options {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 43px;
  }
  
  .funnel-detail-software-actions2,
  .funnel-detail-software-buttonsbutton2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .funnel-detail-software-buttonsbutton2 {
    width: 99px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
    padding: 8px 13px;
    gap: 4px;
    color: #344054;
  }
  
  .funnel-detail-software-actions2 {
    justify-content: flex-start;
    gap: 12px;
    color: #667085;
  }
  
  .funnel-detail-software-container,
  .funnel-detail-software-header-section,
  .funnel-detail-software-tabs-and-filters {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-tabs-and-filters {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    color: #344054;
  }
  
  .funnel-detail-software-container,
  .funnel-detail-software-header-section {
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-container {
    padding: 0 32px;
    box-sizing: border-box;
    gap: 24px;
  }
  
  .funnel-detail-software-header-section {
    flex-shrink: 0;
  }
  
  .funnel-detail-software-color {
    width: 8px;
    height: 8px;
    position: relative;
    border-radius: 50%;
    background-color: #7e56d9;
  }
  
  .funnel-detail-software-legend-color {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px 0 0;
  }
  
  .funnel-detail-software-series-1 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 35px;
  }
  
  .funnel-detail-software-legend-series {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  
  .funnel-detail-software-color1 {
    width: 8px;
    height: 8px;
    position: relative;
    border-radius: 50%;
    background-color: #b692f6;
  }
  
  .funnel-detail-software-series-2 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 33px;
  }
  
  .funnel-detail-software-color2,
  .funnel-detail-software-color3 {
    width: 8px;
    height: 8px;
    position: relative;
    border-radius: 50%;
    background-color: #6941c6;
  }
  
  .funnel-detail-software-color3 {
    background-color: #17b26a;
  }
  
  .funnel-detail-software-series-4 {
    height: 20px;
    position: relative;
    line-height: 20px;
    display: inline-block;
  }
  
  .funnel-detail-software-legend-series3 {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
  }
  
  .funnel-detail-software-color4 {
    height: 8px;
    width: 8px;
    position: relative;
    border-radius: 50%;
    background-color: #6172f3;
  }
  
  .funnel-detail-software-series-5 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
  }
  
  .funnel-detail-software-legend-series4 {
    align-self: stretch;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }
  
  .funnel-detail-software-color5 {
    height: 8px;
    width: 8px;
    position: relative;
    border-radius: 50%;
    background-color: #ee46bb;
  }
  
  .funnel-detail-software-legend {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
  }
  
  .funnel-detail-software-divider,
  .funnel-detail-software-y-axis-line,
  .funnel-detail-software-y-axis-line1 {
    align-self: stretch;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .funnel-detail-software-divider {
    flex: 1;
    position: relative;
    border-top: 1px solid #f2f4f7;
  }
  
  .funnel-detail-software-y-axis-line,
  .funnel-detail-software-y-axis-line1 {
    height: 22px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 11px 0 10px;
  }
  
  .funnel-detail-software-y-axis-line1 {
    height: 17px;
    padding: 8.5px 0 7.5px;
  }
  
  .funnel-detail-software-divider6 {
    height: 1px;
    width: 1217px;
    position: relative;
    border-top: 1px solid #f2f4f7;
    box-sizing: border-box;
    max-width: 121%;
    flex-shrink: 0;
  }
  
  .funnel-detail-software-y-axis,
  .funnel-detail-software-y-axis-line6 {
    align-self: stretch;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-y-axis-line6 {
    height: 17px;
    display: none;
    flex-direction: row;
    align-items: center;
  }
  
  .funnel-detail-software-y-axis {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14.2px;
  }
  
  .funnel-detail-software-jan,
  .funnel-detail-software-jul,
  .funnel-detail-software-jun,
  .funnel-detail-software-mar,
  .funnel-detail-software-may {
    position: relative;
    line-height: 18px;
    display: inline-block;
    min-width: 21px;
  }
  
  .funnel-detail-software-jul,
  .funnel-detail-software-jun,
  .funnel-detail-software-mar,
  .funnel-detail-software-may {
    min-width: 23px;
  }
  
  .funnel-detail-software-jul,
  .funnel-detail-software-jun,
  .funnel-detail-software-may {
    min-width: 25px;
  }
  
  .funnel-detail-software-jul,
  .funnel-detail-software-jun {
    min-width: 22px;
  }
  
  .funnel-detail-software-jul {
    min-width: 17px;
  }
  
  .funnel-detail-software-x-axis {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 24px;
    gap: 20px;
    font-size: 12px;
  }
  
  .funnel-detail-software-chart-data-icon {
    width: 100%;
    height: 170px;
    position: absolute;
    margin: 0 !important;
    top: 20px;
    right: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
    z-index: 1;
  }
  
  .funnel-detail-software-content14 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    position: relative;
    max-width: 100%;
  }
  
  .funnel-detail-software-line-and-bar-chart,
  .funnel-detail-software-line-and-bar-chart-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-line-and-bar-chart {
    flex: 1;
  }
  
  .funnel-detail-software-line-and-bar-chart-wrapper {
    align-self: stretch;
    padding: 0 32px;
    box-sizing: border-box;
    flex-shrink: 0;
  }
  
  .funnel-detail-software-heading1,
  .funnel-detail-software-number {
    align-self: stretch;
    position: relative;
    line-height: 24px;
    font-weight: 600;
  }
  
  .funnel-detail-software-number {
    line-height: 38px;
    white-space: nowrap;
  }
  
  .funnel-detail-software-change1 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 29px;
  }
  
  .funnel-detail-software-change {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .funnel-detail-software-change-duration {
    flex: 1;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    color: #475467;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .funnel-detail-software-change-and-text,
  .funnel-detail-software-number-and-badge {
    display: flex;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-change-and-text {
    align-self: stretch;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #079455;
  }
  
  .funnel-detail-software-number-and-badge {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    min-width: 84px;
  }
  
  .funnel-detail-software-chart-mini-icon {
    height: 64px;
    width: 128px;
    position: relative;
    object-fit: contain;
  }
  
  .funnel-detail-software-number-and-chart {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 16px;
    font-size: 30px;
  }
  
  .funnel-detail-software-dots-vertical-icon {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .funnel-detail-software-dropdown,
  .funnel-detail-software-metric-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-dropdown {
    margin: 0 !important;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  .funnel-detail-software-metric-item {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    padding: 20px 20px 24px 24px;
    position: relative;
    gap: 24px;
    min-width: 320px;
    max-width: 100%;
  }
  
  .funnel-detail-software-change2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1px;
  }
  
  .funnel-detail-software-change-and-text1,
  .funnel-detail-software-container1,
  .funnel-detail-software-metric-group,
  .funnel-detail-software-section {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-change-and-text1 {
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #da2d20;
  }
  
  .funnel-detail-software-container1,
  .funnel-detail-software-metric-group,
  .funnel-detail-software-section {
    align-items: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-metric-group {
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 0 4px 0 0;
    box-sizing: border-box;
    gap: 24px 22px;
  }
  
  .funnel-detail-software-container1,
  .funnel-detail-software-section {
    flex-direction: column;
  }
  
  .funnel-detail-software-container1 {
    padding: 0 32px;
    box-sizing: border-box;
  }
  
  .funnel-detail-software-section {
    flex-shrink: 0;
    font-size: 16px;
    color: #101828;
  }
  
  .funnel-detail-software-separator,
  .funnel-detail-software-separator1 {
    width: 1px;
    height: 29px;
    position: relative;
    border-right: 1px solid #000;
    box-sizing: border-box;
    flex-shrink: 0;
  }
  
  .funnel-detail-software-separator1 {
    height: 43px;
  }
  
  .funnel-detail-software-main,
  .funnel-detail-software-main-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .funnel-detail-software-main {
    height: 692px;
    flex: 1;
    background-color: #fff;
    overflow: hidden;
    flex-direction: column;
    padding: 32px 0;
    gap: 32px;
    z-index: 1;
  }
  
  .funnel-detail-software-main-wrapper {
    align-self: stretch;
    flex-direction: column;
    padding: 0 0 4px;
    color: #475467;
  }
  
  .funnel-detail-software-text42 {
    align-self: stretch;
    flex: 1;
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  
  .funnel-detail-software-breadcrumb-button-base6,
  .funnel-detail-software-breadcrumb-button-base7 {
    align-self: stretch;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .funnel-detail-software-breadcrumb-button-base6 {
    flex: 1;
    padding: 4px 8px;
  }
  
  .funnel-detail-software-breadcrumb-button-base7 {
    padding: 4px 7px;
  }
  
  .funnel-detail-software-breadcrumbs1,
  .funnel-detail-software-tabs1 {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-tabs1 {
    height: 28px;
    flex: 1;
    display: flex;
    gap: 8px;
  }
  
  .funnel-detail-software-breadcrumbs1 {
    width: 278px;
    display: none;
  }
  
  .funnel-detail-software-text46 {
    align-self: stretch;
    position: relative;
    line-height: 38px;
    font-weight: 600;
  }
  
  .funnel-detail-software-supporting-text2 {
    width: 445px;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    display: none;
    max-width: 100%;
  }
  
  .funnel-detail-software-text-and-supporting-text1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    min-width: 320px;
    max-width: 100%;
  }
  
  .funnel-detail-software-text47 {
    align-self: stretch;
    flex: 1;
    position: relative;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .funnel-detail-software-content16 {
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  
  .funnel-detail-software-content16,
  .funnel-detail-software-input-with-label1,
  .funnel-detail-software-input1 {
    align-self: stretch;
    flex: 1;
    display: flex;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-input1 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    padding: 10px 13px;
    font-size: 16px;
    color: #667085;
  }
  
  .funnel-detail-software-input-with-label1 {
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }
  
  .funnel-detail-software-hint-text1 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    color: #475467;
    display: none;
  }
  
  .funnel-detail-software-input-dropdown1 {
    height: 44px;
    width: 320px;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    min-width: 200px;
    max-width: 320px;
    font-size: 14px;
    color: #344054;
  }
  
  .funnel-detail-software-content15 {
    align-self: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 20px 16px;
    font-size: 26px;
    color: #101828;
  }
  
  .funnel-detail-software-content15,
  .funnel-detail-software-page-header-wrapper,
  .funnel-detail-software-page-header1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-page-header1 {
    flex: 1;
    background-color: #fff;
    flex-direction: column;
    gap: 20px;
    z-index: 1;
  }
  
  .funnel-detail-software-page-header-wrapper {
    align-self: stretch;
    flex-direction: row;
    padding: 0 32px 4px;
    box-sizing: border-box;
    color: #475467;
  }
  
  .funnel-detail-software-supporting-text3 {
    flex: 1;
    position: relative;
    line-height: 24px;
    display: inline-block;
    max-width: 100%;
    z-index: 1;
  }
  
  .funnel-detail-software-supporting-text-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 32px 20px;
    box-sizing: border-box;
    max-width: 100%;
    font-size: 16px;
    color: #475467;
  }
  
  .funnel-detail-software-tab-name {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 55px;
    white-space: nowrap;
  }
  
  .funnel-detail-software-tab-button-base {
    border-bottom: 2px solid #7e56d9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 4px 9px;
    color: #6941c6;
  }
  
  .funnel-detail-software-text48 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 48px;
    white-space: nowrap;
  }
  
  .funnel-detail-software-tab-button-base1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 4px 12px;
  }
  
  .funnel-detail-software-text49,
  .funnel-detail-software-text50,
  .funnel-detail-software-text51 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 52px;
    white-space: nowrap;
  }
  
  .funnel-detail-software-text50,
  .funnel-detail-software-text51 {
    min-width: 62px;
  }
  
  .funnel-detail-software-text51 {
    min-width: 34px;
  }
  
  .funnel-detail-software-tab-button-base4 {
    width: 41px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 3px 12px;
    box-sizing: border-box;
  }
  
  .funnel-detail-software-text52 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 600;
    white-space: nowrap;
  }
  
  .funnel-detail-software-tab-button-base5 {
    align-self: stretch;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 4px 12px;
  }
  
  .funnel-detail-software-text55 {
    align-self: stretch;
    position: relative;
    line-height: 18px;
    font-weight: 500;
    white-space: nowrap;
  }
  
  .funnel-detail-software-badge10 {
    height: 22px;
    border-radius: 9999px;
    background-color: #f9fafb;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 7px;
    text-align: center;
    font-size: 12px;
    color: #344054;
  }
  
  .funnel-detail-software-tab-button-base7,
  .funnel-detail-software-tab-button-base9 {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .funnel-detail-software-tab-button-base7 {
    height: 32px;
    padding: 0 4px 12px;
    box-sizing: border-box;
    gap: 8px;
  }
  
  .funnel-detail-software-tab-button-base9 {
    align-self: stretch;
    padding: 0 3px 12px;
  }
  
  .funnel-detail-software-tabs2 {
    overflow-x: auto;
    flex-direction: row;
    padding: 0 20px 0 0;
    gap: 12px;
  }
  
  .funnel-detail-software-horizontal-tabs,
  .funnel-detail-software-horizontal-tabs-wrapper,
  .funnel-detail-software-tabs2 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .funnel-detail-software-horizontal-tabs {
    flex: 1;
    background-color: #fff;
    border-bottom: 1px solid #eaecf0;
    flex-direction: column;
    z-index: 1;
  }
  
  .funnel-detail-software-horizontal-tabs-wrapper {
    align-self: stretch;
    flex-direction: row;
    padding: 0 0 24px 32px;
    color: #667085;
  }
  
  .funnel-detail-software-frame-item {
    width: 188px;
    height: 154px;
    position: relative;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: none;
  }
  
  .funnel-detail-software-heading4 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    z-index: 1;
  }
  
  .funnel-detail-software-metric-card-title {
    flex: 1;
    flex-direction: column;
    padding: 4px 0 0;
  }
  
  .funnel-detail-software-dropdown3,
  .funnel-detail-software-metric-card,
  .funnel-detail-software-metric-card-title {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-dropdown3 {
    flex-direction: column;
    z-index: 1;
  }
  
  .funnel-detail-software-metric-card {
    align-self: stretch;
    flex-direction: row;
  }
  
  .funnel-detail-software-number3 {
    width: 155px;
    position: relative;
    font-size: 36px;
    letter-spacing: -0.02em;
    line-height: 44px;
    font-weight: 600;
    color: #101828;
    display: inline-block;
    z-index: 1;
  }
  
  .funnel-detail-software-metric-card-row {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  
  .funnel-detail-software-arrow-up-icon2 {
    width: 12px;
    height: 12px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .funnel-detail-software-options-icon {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px 0 0;
  }
  
  .funnel-detail-software-text58 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 116px;
  }
  
  .funnel-detail-software-badge11 {
    width: 150px;
    border-radius: 9999px;
    border: 1.5px solid #079455;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px 0 8px;
    gap: 4px;
    z-index: 1;
    text-align: center;
    color: #067647;
  }
  
  .funnel-detail-software-frame-inner,
  .funnel-detail-software-rectangle-group {
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
  }
  
  .funnel-detail-software-rectangle-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 4px 22px 15px;
    gap: 8px;
    min-width: 184px;
    max-width: 188px;
  }
  
  .funnel-detail-software-frame-inner {
    height: 154px;
    width: 188px;
    position: relative;
    display: none;
  }
  
  .funnel-detail-software-heading5,
  .funnel-detail-software-number4 {
    position: relative;
    z-index: 1;
  }
  
  .funnel-detail-software-heading5 {
    width: 147px;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
  }
  
  .funnel-detail-software-number4 {
    align-self: stretch;
    font-size: 36px;
    letter-spacing: -0.02em;
    line-height: 44px;
    font-weight: 600;
    color: #101828;
  }
  
  .funnel-detail-software-change-amount {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 32px;
  }
  
  .funnel-detail-software-badge12 {
    width: 64px;
    border-radius: 9999px;
    border: 1.5px solid #079455;
    box-sizing: border-box;
    flex-direction: row;
    padding: 0 7px 0 8px;
    gap: 2px;
    z-index: 1;
    text-align: center;
    color: #067647;
  }
  
  .funnel-detail-software-badge12,
  .funnel-detail-software-frame-container,
  .funnel-detail-software-heading-parent,
  .funnel-detail-software-rectangle-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-heading-parent {
    width: 157px;
    flex-direction: column;
    gap: 8px;
  }
  
  .funnel-detail-software-frame-container,
  .funnel-detail-software-rectangle-container {
    box-sizing: border-box;
  }
  
  .funnel-detail-software-frame-container {
    width: 147px;
    flex-direction: column;
    padding: 4px 0 0;
  }
  
  .funnel-detail-software-rectangle-container {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    flex-direction: row;
    padding: 20px 4px 22px 15px;
    min-width: 184px;
    max-width: 188px;
  }
  
  .funnel-detail-software-heading-group {
    width: 153px;
    gap: 8px;
  }
  
  .funnel-detail-software-dropdown7,
  .funnel-detail-software-heading-container,
  .funnel-detail-software-heading-group,
  .funnel-detail-software-heading-parent1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-heading-container {
    width: 163px;
    gap: 8px;
  }
  
  .funnel-detail-software-dropdown7,
  .funnel-detail-software-heading-parent1 {
    flex-shrink: 0;
  }
  
  .funnel-detail-software-heading-parent1 {
    width: 170px;
    gap: 8px;
  }
  
  .funnel-detail-software-dropdown7 {
    z-index: 1;
  }
  
  .funnel-detail-software-frame-group,
  .funnel-detail-software-frame-wrapper,
  .funnel-detail-software-rectangle-parent2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .funnel-detail-software-rectangle-parent2 {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    justify-content: flex-start;
    padding: 20px 2px 22px 16px;
    min-width: 184px;
    max-width: 188px;
  }
  
  .funnel-detail-software-frame-group,
  .funnel-detail-software-frame-wrapper {
    max-width: 100%;
  }
  
  .funnel-detail-software-frame-group {
    flex: 1;
    justify-content: flex-start;
    gap: 19px;
    z-index: 1;
  }
  
  .funnel-detail-software-frame-wrapper {
    align-self: stretch;
    justify-content: flex-start;
    padding: 0 30px 24px 30px;
    box-sizing: border-box;
    color: #475467;
  }
  
  .funnel-detail-software-text62,
  .funnel-detail-software-text63 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 51px;
  }
  
  .funnel-detail-software-text63 {
    min-width: 74px;
  }
  
  .funnel-detail-software-buttonsbutton4,
  .funnel-detail-software-text-padding11 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 1px;
  }
  
  .funnel-detail-software-buttonsbutton4 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    padding: 8px 13px;
    gap: 4px;
    min-width: 84px;
    cursor: pointer;
  }
  
  .funnel-detail-software-text64 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 78px;
  }
  
  .funnel-detail-software-buttonsbutton5 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 13px;
    gap: 4px;
    min-width: 87px;
    cursor: pointer;
  }
  
  .funnel-detail-software-text65 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 50px;
  }
  
  .funnel-detail-software-dropdowns,
  .funnel-detail-software-input2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-dropdowns {
    /* flex: 0.7552; */
    flex: .5;
    align-items: flex-start;
    /* padding: 0 166px 0 0; */
    box-sizing: border-box;
    gap: 12px;
    max-width: 100%;
  }
  
  .funnel-detail-software-input2 {
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    align-items: center;
    padding: 8px 13px;
    font-size: 16px;
    color: #667085;
    min-width: 208px;
    max-width: 320px;
  }
  
  .funnel-detail-software-input-dropdown2,
  .funnel-detail-software-input-with-label2 {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    /* flex: .85; */
    justify-content: flex-start;
  }
  
  .funnel-detail-software-input-with-label2 {
    align-self: stretch;
    gap: 6px;
    flex: .65;
  }
  
  .funnel-detail-software-input-dropdown2 {
    flex: .5;
    flex-direction: row !important;
    justify-content: flex-end !important;
    gap: 8px;
    /* min-width: 208px;
    max-width: 320px; */
  }
  
  .funnel-detail-software-content17 {
    flex: 1;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }
  
  .funnel-detail-software-content17,
  .funnel-detail-software-filters-bar,
  .funnel-detail-software-section1 {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-filters-bar {
    align-self: stretch;
    border-radius: 8px;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .funnel-detail-software-section1 {
    flex: 1;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }
  
  .funnel-detail-software-frame-child3,
  .funnel-detail-software-section-wrapper {
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .funnel-detail-software-section-wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 24px;
    color: #344054;
    position: relative;
  }
  
  .funnel-detail-software-frame-child3 {
    width: 1016px;
    height: 282px;
    position: relative;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    display: none;
  }
  
  .funnel-detail-software-content-elements-icon {
    height: 148px;
    width: 148px;
    position: relative;
    border-radius: 12px;
    object-fit: cover;
    z-index: 1;
  }
  
  .funnel-detail-software-search-input-icons {
    position: relative;
    line-height: 28px;
    font-weight: 600;
    z-index: 1;
  }
  
  .funnel-detail-software-search-inputs {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 0 1px;
  }
  
  .funnel-detail-software-dot-icon10 {
    height: 8px;
    width: 8px;
    position: relative;
    display: none;
  }
  
  .funnel-detail-software-badge-number {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 66px;
  }
  
  .funnel-detail-software-badge16 {
    width: 84px;
    border-radius: 9999px;
    background-color: #edfbf5;
    border: 1px solid #41d992;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 8px;
    gap: 6px;
    mix-blend-mode: normal;
    z-index: 1;
    text-align: center;
    font-size: 14px;
    color: #17b26a;
  }
  
  .funnel-detail-software-supporting-text4 {
    position: relative;
    line-height: 24px;
    display: inline-block;
    min-width: 83px;
    z-index: 1;
  }
  
  .funnel-detail-software-marker-pin-02-icon {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
  }
  
  .funnel-detail-software-location1 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 124px;
    z-index: 1;
  }
  
  .funnel-detail-software-location-pin-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 7px;
  }
  
  .funnel-detail-software-type-of-work {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 109px;
    z-index: 1;
  }
  
  .funnel-detail-software-location-badge,
  .funnel-detail-software-location-badge-number,
  .funnel-detail-software-type-number {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-location-badge-number {
    flex: 1;
    gap: 20px;
    max-width: 100%;
  }
  
  .funnel-detail-software-location-badge,
  .funnel-detail-software-type-number {
    box-sizing: border-box;
  }
  
  .funnel-detail-software-location-badge {
    align-self: stretch;
    padding: 0 0 0 1px;
    max-width: 100%;
  }
  
  .funnel-detail-software-type-number {
    width: 50px;
    border-radius: 8px;
    background-color: #fdb022;
    padding: 1px 4px;
    z-index: 1;
  }
  
  .funnel-detail-software-supporting-text5 {
    position: relative;
    line-height: 24px;
    display: inline-block;
    min-width: 117px;
    z-index: 1;
  }
  
  .funnel-detail-software-type-support {
    flex-direction: column;
    padding: 1px 0 0;
    color: #475467;
  }
  
  .funnel-detail-software-location-elements,
  .funnel-detail-software-type-badge-elements,
  .funnel-detail-software-type-support {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-type-badge-elements {
    flex-direction: row;
    gap: 8px;
    color: #fff;
  }
  
  .funnel-detail-software-location-elements {
    align-self: stretch;
    flex-direction: column;
    gap: 15px;
    max-width: 100%;
  }
  
  .funnel-detail-software-card-badge-number {
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  
  .funnel-detail-software-badge17 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    min-width: 112px;
    white-space: nowrap;
    z-index: 1;
  }
  
  .funnel-detail-software-badge-number-element {
    width: 61px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 61px;
  }
  
  .funnel-detail-software-badge18 {
    width: 77px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 1;
  }
  
  .funnel-detail-software-second-badge-count {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 60px;
  }
  
  .funnel-detail-software-badge19 {
    width: 76px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 1;
  }
  
  .funnel-detail-software-third-badge-number {
    width: 149px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
  }
  
  .funnel-detail-software-badge20 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    min-width: 107px;
    white-space: nowrap;
    z-index: 1;
  }
  
  .funnel-detail-software-badge-elements {
    flex: 1;
    flex-direction: row;
    gap: 8px;
  }
  
  .funnel-detail-software-badge-elements,
  .funnel-detail-software-badge-elements-wrapper,
  .funnel-detail-software-card-badge-elements {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-badge-elements-wrapper {
    align-self: stretch;
    flex-direction: row;
    padding: 0 1px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .funnel-detail-software-card-badge-elements {
    flex: 1;
    flex-direction: column;
    gap: 15px;
  }
  
  .funnel-detail-software-location-badge-elements {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 77px;
  }
  
  .funnel-detail-software-badge21 {
    width: 93px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 1;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .funnel-detail-software-action-fields,
  .funnel-detail-software-card-elements,
  .funnel-detail-software-input-elements {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-card-elements {
    width: 616px;
    flex-direction: row;
    align-items: flex-end;
    gap: 7px;
    font-size: 16px;
    color: #475467;
  }
  
  .funnel-detail-software-action-fields,
  .funnel-detail-software-input-elements {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .funnel-detail-software-input-elements {
    align-self: stretch;
    gap: 16px;
  }
  
  .funnel-detail-software-action-fields {
    flex: 1;
    padding: 10px 0 0;
    box-sizing: border-box;
  }
  
  .funnel-detail-software-price {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    white-space: nowrap;
    z-index: 1;
  }
  
  .funnel-detail-software-dropdown-options {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 83px;
    z-index: 2;
  }
  
  .funnel-detail-software-magnifier-1-icon {
    height: 20px;
    width: 20px;
    position: relative;
    object-fit: cover;
    min-height: 20px;
  }
  
  .funnel-detail-software-frame-wrapper3,
  .funnel-detail-software-radar-1-1-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-radar-1-1-parent {
    flex: 1;
    gap: 8px;
    z-index: 1;
  }
  
  .funnel-detail-software-frame-wrapper3 {
    align-self: stretch;
    padding: 0 4px 0 3px;
  }
  
  .funnel-detail-software-dropdown-options-parent {
    flex: 1;
    flex-direction: column;
    gap: 4px;
  }
  
  .funnel-detail-software-dropdown-elements,
  .funnel-detail-software-dropdown-options-parent,
  .funnel-detail-software-price-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-dropdown-elements {
    align-self: stretch;
    flex-direction: row;
    padding: 0 30px;
    font-size: 12px;
    color: #475467;
  }
  
  .funnel-detail-software-price-parent {
    flex: 1;
    flex-direction: column;
  }
  
  .funnel-detail-software-filter-elements-inner {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 10px 0 0;
  }
  
  .funnel-detail-software-filter-elements {
    width: 153px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 26px;
    text-align: center;
    font-size: 20px;
    color: #6941c6;
  }
  
  .funnel-detail-software-content-actions,
  .funnel-detail-software-content-elements-parent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-content-actions {
    flex: 1;
    align-items: flex-start;
    gap: 15px;
    min-width: 525px;
  }
  
  .funnel-detail-software-content-elements-parent {
    align-self: stretch;
    align-items: flex-end;
    gap: 23px;
  }
  
  .funnel-detail-software-location-column-child {
    height: 52px;
    width: 52px;
    position: relative;
    object-fit: cover;
    z-index: 1;
  }
  
  .funnel-detail-software-name,
  .funnel-detail-software-role {
    position: relative;
    display: inline-block;
  }
  
  .funnel-detail-software-name {
    line-height: 23px;
    font-weight: 600;
    min-width: 77px;
    z-index: 1;
  }
  
  .funnel-detail-software-role {
    height: 19px;
    font-size: 12px;
    line-height: 24px;
    color: #6941c6;
    flex-shrink: 0;
    min-width: 87px;
    z-index: 2;
  }
  
  .funnel-detail-software-location-column-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 3px 0 0;
  }
  
  .funnel-detail-software-text67 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 34px;
  }
  
  .funnel-detail-software-buttonsbutton7 {
    width: 90px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px 12px;
    gap: 6px;
    z-index: 1;
  }
  
  .funnel-detail-software-icon1 {
    width: 16.7px;
    height: 16.7px;
    position: relative;
  }
  
  .funnel-detail-software-icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 3.7px 0 0;
  }
  
  .funnel-detail-software-text68 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 56px;
  }
  
  .funnel-detail-software-buttonsbutton8,
  .funnel-detail-software-buttonsbutton9 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px 11px;
    gap: 5.5px;
    min-width: 86px;
    z-index: 1;
  }
  
  .funnel-detail-software-buttonsbutton9 {
    padding: 6px 10px 6px 12px;
    gap: 5.4px;
  }
  
  .funnel-detail-software-buttonsbutton-parent {
    align-self: stretch;
    flex-direction: row;
    gap: 16px;
  }
  
  .funnel-detail-software-buttonsbutton-parent,
  .funnel-detail-software-primary-table,
  .funnel-detail-software-primary-table-inner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-primary-table-inner {
    flex: 1;
    flex-direction: column;
    padding: 6px 0 0;
    box-sizing: border-box;
    min-width: 250px;
    max-width: 100%;
    color: #344054;
  }
  
  .funnel-detail-software-primary-table {
    width: 556.4px;
    flex-direction: row;
    gap: 24px;
    max-width: calc(100% - 44px);
  }
  
  .funnel-detail-software-arrow-square-up-right-icon {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
  }
  
  .funnel-detail-software-arrow-square-up-right-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 14px 0 0;
  }
  
  .funnel-detail-software-primary-table-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 100%;
    gap: 20px;
  }
  
  .funnel-detail-software-content-items,
  .funnel-detail-software-help-avatar,
  .funnel-detail-software-rectangle-parent3 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-help-avatar {
    width: 968px;
    flex-direction: column;
    gap: 16px;
    font-size: 14px;
  }
  
  .funnel-detail-software-content-items,
  .funnel-detail-software-rectangle-parent3 {
    box-sizing: border-box;
  }
  
  .funnel-detail-software-rectangle-parent3 {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    flex-direction: column;
    padding: 12px 13px 15px 23px;
    gap: 24px;
    z-index: 1;
  }
  
  .funnel-detail-software-content-items {
    align-self: stretch;
    flex-direction: row;
    padding: 0 30px 24px 32px;
    font-size: 18px;
  }
  
  .funnel-detail-software-text71 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 55px;
  }
  
  .funnel-detail-software-badge22 {
    width: 73px;
    border-radius: 9999px;
    background-color: #fff9ef;
    border: 1px solid #f5b231;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 8px;
    gap: 6px;
    mix-blend-mode: normal;
    z-index: 1;
  }
  
  .funnel-detail-software-supporting-text6 {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    text-align: left;
    display: inline-block;
    min-width: 83px;
    z-index: 1;
  }
  
  .funnel-detail-software-badge-parent {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }
  
  .funnel-detail-software-badge-parent,
  .funnel-detail-software-badge23,
  .funnel-detail-software-frame-parent4 {
    flex-direction: row;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-frame-parent4 {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    text-align: left;
    font-size: 16px;
    color: #fff;
  }
  
  .funnel-detail-software-badge23 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 1;
    color: #344054;
  }
  
  .funnel-detail-software-badge23,
  .funnel-detail-software-badge24,
  .funnel-detail-software-frame-parent3 {
    display: flex;
    align-items: flex-start;
  }
  
  .funnel-detail-software-frame-parent3 {
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    min-width: 114px;
    color: #f5b231;
  }
  
  .funnel-detail-software-badge24 {
    flex-direction: row;
    white-space: nowrap;
  }
  
  .funnel-detail-software-badge24,
  .funnel-detail-software-badge25 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 1;
  }
  
  .funnel-detail-software-badge-elements-copy,
  .funnel-detail-software-badge25,
  .funnel-detail-software-price-badge-elements {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .funnel-detail-software-badge-elements-copy {
    flex: 1;
    justify-content: flex-start;
    gap: 8px;
  }
  
  .funnel-detail-software-price-badge-elements {
    align-self: stretch;
    justify-content: flex-end;
    padding: 0 6px 0 0;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .funnel-detail-software-frame-parent5 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 56px;
    min-width: 109px;
    text-align: left;
    font-size: 16px;
  }
  
  .funnel-detail-software-badge26,
  .funnel-detail-software-date-elements {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 10px 0 18px;
  }
  
  .funnel-detail-software-badge26 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    justify-content: flex-start;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 1;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .funnel-detail-software-badge27,
  .funnel-detail-software-date-elements-parent {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-date-elements-parent {
    flex: 0.9649;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 6px 0 0;
    gap: 56px;
    min-width: 111px;
    text-align: left;
    font-size: 16px;
  }
  
  .funnel-detail-software-badge27 {
    width: 93px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 7px;
    z-index: 1;
    color: #344054;
  }
  
  .funnel-detail-software-frame-parent2 {
    width: 615px;
    align-items: flex-end;
    padding: 0 2px 0 0;
    box-sizing: border-box;
    gap: 2px;
    text-align: center;
    font-size: 14px;
    color: #475467;
  }
  
  .funnel-detail-software-frame-parent1,
  .funnel-detail-software-frame-parent2,
  .funnel-detail-software-rectangle-parent5 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-frame-parent1 {
    flex: 1;
    align-items: flex-start;
    gap: 15px;
    min-width: 524px;
  }
  
  .funnel-detail-software-rectangle-parent5 {
    align-self: stretch;
    align-items: flex-end;
    gap: 24px;
  }
  
  .funnel-detail-software-frame-child5,
  .funnel-detail-software-frame-child6 {
    width: 24px;
    height: 24px;
    position: relative;
    z-index: 1;
  }
  
  .funnel-detail-software-frame-child6 {
    width: 1016px;
    height: 282px;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: none;
    max-width: 100%;
  }
  
  .funnel-detail-software-frame-child7,
  .funnel-detail-software-text78 {
    position: relative;
    z-index: 2;
  }
  
  .funnel-detail-software-frame-child7 {
    height: 148px;
    width: 148px;
    border-radius: 12px;
    object-fit: cover;
  }
  
  .funnel-detail-software-text78 {
    line-height: 28px;
    font-weight: 600;
  }
  
  .funnel-detail-software-badge28 {
    position: absolute;
    top: 80px;
    left: 522px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    width: 93px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 2;
  }
  
  .funnel-detail-software-marker-pin-02-icon2 {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
  }
  
  .funnel-detail-software-location3,
  .funnel-detail-software-type-of-work2 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 124px;
    z-index: 2;
  }
  
  .funnel-detail-software-type-of-work2 {
    min-width: 109px;
  }
  
  .funnel-detail-software-frame-parent12,
  .funnel-detail-software-frame-wrapper12 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .funnel-detail-software-frame-parent12 {
    justify-content: flex-start;
    gap: 20px;
  }
  
  .funnel-detail-software-frame-wrapper12 {
    justify-content: flex-end;
    padding: 0 5px;
  }
  
  .funnel-detail-software-badge29,
  .funnel-detail-software-badge30 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 2;
  }
  
  .funnel-detail-software-badge29 {
    width: 77px;
    flex-direction: row;
    align-items: flex-start;
    white-space: nowrap;
  }
  
  .funnel-detail-software-badge30 {
    width: 76px;
  }
  
  .funnel-detail-software-badge-container,
  .funnel-detail-software-badge30,
  .funnel-detail-software-badge31 {
    flex-direction: row;
    align-items: flex-start;
  }
  
  .funnel-detail-software-badge31 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    display: flex;
    justify-content: flex-start;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 2;
  }
  
  .funnel-detail-software-badge-container {
    gap: 8px;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .funnel-detail-software-badge-container,
  .funnel-detail-software-badge32,
  .funnel-detail-software-frame-parent11 {
    display: flex;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-frame-parent11 {
    position: absolute;
    top: 0;
    left: 180px;
    flex-direction: column;
    align-items: flex-end;
    gap: 56px;
    text-align: left;
    font-size: 16px;
    color: #475467;
  }
  
  .funnel-detail-software-badge32 {
    flex: 1;
    border-radius: 9999px;
    background-color: #eceef1;
    border: 1px solid #98a2b3;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 8px;
    gap: 6px;
    mix-blend-mode: normal;
    z-index: 2;
  }
  
  .funnel-detail-software-supporting-text8 {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    text-align: left;
    display: inline-block;
    min-width: 83px;
    z-index: 2;
  }
  
  .funnel-detail-software-badge-parent1 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }
  
  .funnel-detail-software-supporting-text9 {
    position: relative;
    line-height: 24px;
    display: inline-block;
    min-width: 117px;
    z-index: 2;
  }
  
  .funnel-detail-software-badge33,
  .funnel-detail-software-frame-parent15 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-badge33 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 2;
    color: #344054;
  }
  
  .funnel-detail-software-frame-parent15 {
    position: absolute;
    top: 0;
    left: 0;
    width: 181px;
    flex-direction: column;
    gap: 15px;
    color: #98a2b3;
  }
  
  .funnel-detail-software-badge-group {
    width: 615px;
    height: 104px;
    position: relative;
    max-width: 100%;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .funnel-detail-software-dropdown10 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 2;
  }
  
  .funnel-detail-software-price2,
  .funnel-detail-software-text83 {
    position: relative;
  }
  
  .funnel-detail-software-price2 {
    align-self: stretch;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    white-space: nowrap;
    z-index: 2;
  }
  
  .funnel-detail-software-text83 {
    line-height: 20px;
    display: inline-block;
    min-width: 83px;
    z-index: 3;
  }
  
  .funnel-detail-software-radar-1-1-container,
  .funnel-detail-software-rectangle-parent7 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-radar-1-1-container {
    flex: 1;
    align-items: flex-start;
    gap: 8px;
    z-index: 2;
  }
  
  .funnel-detail-software-rectangle-parent7 {
    align-self: stretch;
    align-items: flex-end;
    padding: 0 0 76px;
    box-sizing: border-box;
    gap: 24px;
    max-width: 100%;
  }
  
  .funnel-detail-software-frame-child8 {
    height: 52px;
    width: 52px;
    position: relative;
    object-fit: cover;
    z-index: 2;
  }
  
  .funnel-detail-software-name2,
  .funnel-detail-software-role2 {
    position: relative;
    display: inline-block;
  }
  
  .funnel-detail-software-name2 {
    line-height: 23px;
    font-weight: 600;
    min-width: 77px;
    z-index: 2;
  }
  
  .funnel-detail-software-role2 {
    height: 19px;
    font-size: 12px;
    line-height: 24px;
    color: #6941c6;
    flex-shrink: 0;
    min-width: 87px;
    z-index: 3;
  }
  
  .funnel-detail-software-buttonsbutton13,
  .funnel-detail-software-buttonsbutton14 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    z-index: 2;
  }
  
  .funnel-detail-software-buttonsbutton13 {
    width: 90px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px 12px;
    gap: 6px;
  }
  
  .funnel-detail-software-buttonsbutton14 {
    flex: 1;
    overflow-x: auto;
    padding: 6px 11px;
    gap: 5.5px;
    min-width: 86px;
  }
  
  .funnel-detail-software-buttonsbutton14,
  .funnel-detail-software-buttonsbutton15,
  .funnel-detail-software-frame-parent18 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-buttonsbutton15 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow-x: auto;
    padding: 6px 10px 6px 12px;
    gap: 5.4px;
    min-width: 86px;
    z-index: 2;
  }
  
  .funnel-detail-software-frame-parent18 {
    width: 556.4px;
    gap: 24px;
    max-width: 100%;
  }
  
  .funnel-detail-software-text84 {
    width: 66px;
    position: relative;
    line-height: 18px;
    font-weight: 600;
    display: inline-block;
    min-width: 66px;
  }
  
  .funnel-detail-software-content19 {
    border-radius: 8px;
    background-color: #0c111d;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 12px;
    white-space: nowrap;
  }
  
  .funnel-detail-software-tooltip-icon {
    width: 28px;
    height: 6px;
    position: relative;
  }
  
  .funnel-detail-software-tooltip {
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
      0 4px 6px -2px rgba(16, 24, 40, 0.03);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    z-index: 3;
  }
  
  .funnel-detail-software-arrow-square-up-right-icon1 {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
  }
  
  .funnel-detail-software-frame-wrapper16,
  .funnel-detail-software-tooltip-parent {
    display: flex;
    flex-direction: column;
  }
  
  .funnel-detail-software-tooltip-parent {
    align-items: flex-end;
    justify-content: flex-start;
    gap: 8px;
  }
  
  .funnel-detail-software-frame-wrapper16 {
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 0 14px;
    text-align: center;
    font-size: 12px;
    color: #fff;
  }
  
  .funnel-detail-software-divider-icon5,
  .funnel-detail-software-frame-parent17 {
    width: 968px;
    max-width: 100%;
    margin-top: -69px;
  }
  
  .funnel-detail-software-frame-parent17 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 20px;
    font-size: 14px;
  }
  
  .funnel-detail-software-divider-icon5 {
    position: relative;
    max-height: 100%;
    z-index: 2;
  }
  
  .funnel-detail-software-rectangle-parent6 {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 14px 13px 17px 23px;
    min-height: 282px;
    max-width: 100%;
    z-index: 1;
  }
  
  .funnel-detail-software-text86 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 51px;
  }
  
  .funnel-detail-software-badge34,
  .funnel-detail-software-badge36 {
    justify-content: flex-start;
    z-index: 2;
  }
  
  .funnel-detail-software-badge34 {
    width: 69px;
    border-radius: 9999px;
    background-color: #fffdfd;
    border: 1px solid #da2d20;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 8px;
    gap: 6px;
    mix-blend-mode: normal;
  }
  
  .funnel-detail-software-badge36 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    padding: 0 7px;
    white-space: nowrap;
  }
  
  .funnel-detail-software-badge36,
  .funnel-detail-software-badge37,
  .funnel-detail-software-frame-wrapper18 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .funnel-detail-software-badge37 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 2;
  }
  
  .funnel-detail-software-frame-wrapper18 {
    align-self: stretch;
    justify-content: flex-end;
    padding: 0 2px 0 0;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .funnel-detail-software-frame-parent24 {
    width: 163px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 56px;
    text-align: left;
    font-size: 16px;
  }
  
  .funnel-detail-software-frame-wrapper19 {
    flex-direction: row;
    padding: 0 14px;
  }
  
  .funnel-detail-software-badge38,
  .funnel-detail-software-frame-parent26,
  .funnel-detail-software-frame-wrapper19 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-badge38 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 2;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .funnel-detail-software-frame-parent26 {
    flex: 1;
    flex-direction: column;
    gap: 56px;
    min-width: 107px;
    text-align: left;
    font-size: 16px;
  }
  
  .funnel-detail-software-badge39,
  .funnel-detail-software-frame-parent21 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-badge39 {
    width: 93px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    align-items: flex-start;
    padding: 0 7px;
    z-index: 2;
    color: #344054;
  }
  
  .funnel-detail-software-frame-parent21 {
    width: 615px;
    align-items: flex-end;
    gap: 6.3px;
    max-width: 100%;
    text-align: center;
    font-size: 14px;
    color: #475467;
  }
  
  .funnel-detail-software-divider-icon6 {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    z-index: 2;
  }
  
  .funnel-detail-software-element-details-child,
  .funnel-detail-software-frame-child12 {
    position: relative;
    border-radius: 12px;
    display: none;
  }
  
  .funnel-detail-software-frame-child12 {
    height: 208px;
    width: 1016px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .funnel-detail-software-element-details-child {
    height: 160px;
    width: 160px;
    background-color: #f5f5f5;
  }
  
  .funnel-detail-software-f4b711e503bc72fe396e5043e0c2-1-icon {
    height: 84px;
    width: 84px;
    position: relative;
    object-fit: cover;
    z-index: 3;
  }
  
  .funnel-detail-software-element-details {
    border-radius: 12px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 38px;
    box-sizing: border-box;
    min-width: 160px;
    z-index: 2;
  }
  
  .funnel-detail-software-supporting-text12 {
    position: relative;
    letter-spacing: -0.06px;
    line-height: 24px;
    z-index: 2;
  }
  
  .funnel-detail-software-end-label {
    width: 50px;
    border-radius: 8px;
    background-color: #fdb022;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1px 4px;
    box-sizing: border-box;
    z-index: 2;
  }
  
  .funnel-detail-software-text99 {
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 2;
  }
  
  .funnel-detail-software-supporting-text14 {
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    min-width: 104px;
    z-index: 3;
  }
  
  .funnel-detail-software-date-icon,
  .funnel-detail-software-type-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-type-label {
    font-size: 14px;
    color: #101828;
  }
  
  .funnel-detail-software-date-icon {
    flex: 1;
    gap: 16px;
    min-width: 114px;
  }
  
  .funnel-detail-software-text100 {
    align-self: stretch;
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 2;
  }
  
  .funnel-detail-software-supporting-text15 {
    width: 104px;
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    z-index: 3;
  }
  
  .funnel-detail-software-work-type-icon,
  .funnel-detail-software-work-type-value {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-work-type-value {
    align-self: stretch;
    flex-direction: row;
    padding: 0 0 0 1px;
    font-size: 14px;
    color: #101828;
  }
  
  .funnel-detail-software-work-type-icon {
    width: 152px;
    flex-direction: column;
    gap: 58px;
    min-width: 152px;
  }
  
  .funnel-detail-software-calendar-icon6 {
    flex: 1;
    gap: 8px;
    min-width: 218px;
    max-width: 100%;
  }
  
  .funnel-detail-software-action-icons,
  .funnel-detail-software-calendar-icon6,
  .funnel-detail-software-work-type-label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-work-type-label {
    gap: 8px;
    margin-left: -9px;
  }
  
  .funnel-detail-software-action-icons {
    align-self: stretch;
    max-width: 100%;
    row-gap: 20px;
    font-size: 16px;
    color: #475467;
  }
  
  .funnel-detail-software-element-actions {
    flex: 1;
    flex-direction: column;
    gap: 8px;
    min-width: 302px;
  }
  
  .funnel-detail-software-element-actions,
  .funnel-detail-software-element-details-parent,
  .funnel-detail-software-frame-wrapper25 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-element-details-parent {
    align-self: stretch;
    flex-direction: row;
    gap: 24px;
  }
  
  .funnel-detail-software-frame-wrapper25 {
    width: 648px;
    flex-direction: column;
    padding: 10px 0 0;
    box-sizing: border-box;
  }
  
  .funnel-detail-software-text101 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 29px;
    z-index: 3;
  }
  
  .funnel-detail-software-text-wrapper10 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 57px;
  }
  
  .funnel-detail-software-price4 {
    align-self: stretch;
    position: relative;
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    color: #6941c6;
    white-space: nowrap;
    z-index: 2;
  }
  
  .funnel-detail-software-frame-wrapper27 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 34px 0 0;
  }
  
  .funnel-detail-software-frame-parent32,
  .funnel-detail-software-trend-chart-inner {
    display: flex;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-frame-parent32 {
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    gap: 19px;
  }
  
  .funnel-detail-software-trend-chart-inner {
    align-self: stretch;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 0 0 3px;
  }
  
  .funnel-detail-software-dropdown-parent1,
  .funnel-detail-software-frame-parent31,
  .funnel-detail-software-trend-chart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-trend-chart {
    width: 113px;
    align-items: flex-start;
    gap: 4px;
  }
  
  .funnel-detail-software-dropdown-parent1,
  .funnel-detail-software-frame-parent31 {
    align-items: flex-end;
  }
  
  .funnel-detail-software-frame-parent31 {
    flex: 1;
  }
  
  .funnel-detail-software-dropdown-parent1 {
    width: 153px;
    gap: 22px;
    text-align: center;
    font-size: 12px;
    color: #475467;
  }
  
  .funnel-detail-software-rectangle-parent10 {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 15px 12px 23px;
    max-width: 100%;
    gap: 20px;
    z-index: 1;
  }
  
  .funnel-detail-software-f4b711e503bc72fe396e5043e0c2-1-icon1 {
    height: 84px;
    width: 84px;
    position: relative;
    object-fit: cover;
    z-index: 2;
  }
  
  .funnel-detail-software-rectangle-parent12 {
    border-radius: 12px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 38px;
    z-index: 1;
  }
  
  .funnel-detail-software-text103 {
    position: relative;
    line-height: 28px;
    font-weight: 600;
    display: inline-block;
    min-width: 128px;
    z-index: 1;
  }
  
  .funnel-detail-software-text-wrapper11 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 8px 0 0;
    font-size: 18px;
    color: #101828;
  }
  
  .funnel-detail-software-icon9 {
    width: 12px;
    height: 9.3px;
    position: relative;
    z-index: 1;
  }
  
  .funnel-detail-software-icon-wrapper4 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 0 0;
  }
  
  .funnel-detail-software-span {
    font-weight: 500;
  }
  
  .funnel-detail-software-supporting-text16 {
    height: 19px;
    position: relative;
    line-height: 24px;
    display: inline-block;
    flex-shrink: 0;
    min-width: 63px;
    z-index: 1;
  }
  
  .funnel-detail-software-supporting-text-wrapper1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px 16px 0 0;
  }
  
  .funnel-detail-software-frame-child15 {
    width: 16px;
    height: 16px;
    position: relative;
    z-index: 1;
  }
  
  .funnel-detail-software-supporting-text17 {
    height: 19px;
    position: relative;
    line-height: 24px;
    display: inline-block;
    flex-shrink: 0;
    min-width: 70.9px;
    z-index: 1;
  }
  
  .funnel-detail-software-frame-parent36,
  .funnel-detail-software-frame-parent37,
  .funnel-detail-software-location-elements-triple {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  
  .funnel-detail-software-frame-parent37,
  .funnel-detail-software-location-elements-triple {
    gap: 7px;
    font-size: 16px;
  }
  
  .funnel-detail-software-frame-parent37 {
    gap: 8px;
    color: #fff;
  }
  
  .funnel-detail-software-text105,
  .funnel-detail-software-text106,
  .funnel-detail-software-user-avatar {
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 1;
  }
  
  .funnel-detail-software-text105,
  .funnel-detail-software-text106 {
    display: inline-block;
  }
  
  .funnel-detail-software-text105 {
    min-width: 103px;
  }
  
  .funnel-detail-software-text106 {
    width: 151px;
    flex-shrink: 0;
  }
  
  .funnel-detail-software-icon-button {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 34px;
  }
  
  .funnel-detail-software-supporting-text19 {
    position: relative;
    line-height: 19px;
    display: inline-block;
    min-width: 92.1px;
    z-index: 2;
  }
  
  .funnel-detail-software-sidebar-content1 {
    width: 161.9px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 20px 0 0;
    box-sizing: border-box;
  }
  
  .funnel-detail-software-supporting-text20,
  .funnel-detail-software-supporting-text21 {
    position: relative;
    line-height: 19px;
    display: inline-block;
    flex-shrink: 0;
    z-index: 2;
  }
  
  .funnel-detail-software-supporting-text20 {
    min-width: 70.9px;
  }
  
  .funnel-detail-software-supporting-text21 {
    width: 104px;
  }
  
  .funnel-detail-software-sidebar1 {
    width: 469px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    max-width: 100%;
    font-size: 12px;
    color: #475467;
  }
  
  .funnel-detail-software-frame-parent33,
  .funnel-detail-software-frame-parent34,
  .funnel-detail-software-nav-item-button-icon {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-nav-item-button-icon {
    align-self: stretch;
    flex-direction: column;
    font-size: 14px;
    color: #101828;
  }
  
  .funnel-detail-software-frame-parent33,
  .funnel-detail-software-frame-parent34 {
    flex: 1;
  }
  
  .funnel-detail-software-frame-parent34 {
    flex-direction: column;
    gap: 16px;
    min-width: 335px;
  }
  
  .funnel-detail-software-frame-parent33 {
    flex-direction: row;
    gap: 24px;
  }
  
  .funnel-detail-software-text107 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 123px;
  }
  
  .funnel-detail-software-buttonsbutton-wrapper,
  .funnel-detail-software-buttonsbutton19 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .funnel-detail-software-buttonsbutton19 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    justify-content: flex-start;
    padding: 6px 12px;
    gap: 5px;
    z-index: 1;
  }
  
  .funnel-detail-software-buttonsbutton-wrapper {
    align-self: stretch;
    justify-content: flex-end;
    padding: 0 27px 0 0;
  }
  
  .funnel-detail-software-arrow-square-up-right-icon4 {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 3;
  }
  
  .funnel-detail-software-dropdown-parent2,
  .funnel-detail-software-frame-parent38 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-frame-parent38 {
    flex: 1;
    gap: 27px;
  }
  
  .funnel-detail-software-dropdown-parent2 {
    align-self: stretch;
    gap: 50px;
  }
  
  .funnel-detail-software-frame-wrapper29 {
    width: 215px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 0 9px;
    box-sizing: border-box;
    font-size: 14px;
    color: #344054;
  }
  
  .funnel-detail-software-rectangle-parent11 {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 12px 13px 22px 23px;
    gap: 63px;
    z-index: 1;
  }
  
  .funnel-detail-software-content-items5,
  .funnel-detail-software-frame-child16,
  .funnel-detail-software-rectangle-parent11 {
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .funnel-detail-software-content-items5 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 30px 24px 32px;
    font-size: 12px;
    color: #475467;
  }
  
  .funnel-detail-software-frame-child16 {
    height: 208px;
    width: 1016px;
    position: relative;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    display: none;
    z-index: 1;
  }
  
  .funnel-detail-software-supporting-text-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    font-size: 16px;
    color: #475467;
  }
  
  .funnel-detail-software-search-icon-input {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 41px;
  }
  
  .funnel-detail-software-supporting-text24 {
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    min-width: 104px;
    z-index: 1;
  }
  
  .funnel-detail-software-frame-parent43 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
  }
  
  .funnel-detail-software-supporting-text25 {
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    min-width: 113px;
    z-index: 1;
  }
  
  .funnel-detail-software-frame-parent42 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 10px;
    min-width: 272px;
    max-width: 100%;
  }
  
  .funnel-detail-software-text111 {
    align-self: stretch;
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 1;
  }
  
  .funnel-detail-software-supporting-text26 {
    width: 113px;
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    z-index: 1;
  }
  
  .funnel-detail-software-text-parent8 {
    width: 233px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 14px;
    color: #101828;
  }
  
  .funnel-detail-software-frame-parent39,
  .funnel-detail-software-frame-parent41 {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-frame-parent41 {
    align-self: stretch;
    flex-direction: row;
    align-items: flex-end;
    gap: 34px;
    font-size: 16px;
    color: #fff;
  }
  
  .funnel-detail-software-frame-parent39 {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    min-width: 445px;
  }
  
  .funnel-detail-software-dropdown14,
  .funnel-detail-software-text112 {
    position: absolute;
    z-index: 1;
  }
  
  .funnel-detail-software-dropdown14 {
    top: 0;
    left: 306px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-text112 {
    top: 19px;
    left: 137px;
    line-height: 20px;
    display: inline-block;
    min-width: 41px;
  }
  
  .funnel-detail-software-price5 {
    flex: 1;
    position: relative;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    white-space: nowrap;
    z-index: 2;
  }
  
  .funnel-detail-software-price6,
  .funnel-detail-software-text113 {
    position: relative;
    z-index: 1;
  }
  
  .funnel-detail-software-text113 {
    line-height: 20px;
    display: inline-block;
    min-width: 15px;
  }
  
  .funnel-detail-software-price6 {
    flex: 1;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    white-space: nowrap;
  }
  
  .funnel-detail-software-content-table-cards {
    position: absolute;
    top: 39px;
    left: 0;
    width: 316px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 7.5px;
    font-size: 20px;
    color: #6941c6;
  }
  
  .funnel-detail-software-text114 {
    position: absolute;
    top: 70px;
    left: 116px;
    line-height: 20px;
    display: inline-block;
    min-width: 83px;
    z-index: 3;
  }
  
  .funnel-detail-software-radar-1-1-parent3 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    z-index: 1;
  }
  
  .funnel-detail-software-arrow-square-up-right-icon5 {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 4;
  }
  
  .funnel-detail-software-arrow-square-up-right-frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 43px 0 0;
  }
  
  .funnel-detail-software-frame-parent46,
  .funnel-detail-software-frame-wrapper32 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .funnel-detail-software-frame-parent46 {
    flex: 1;
    justify-content: space-between;
    gap: 20px;
  }
  
  .funnel-detail-software-frame-wrapper32 {
    width: 217px;
    justify-content: flex-end;
    padding: 0 10px;
    box-sizing: border-box;
  }
  
  .funnel-detail-software-frame-parent45,
  .funnel-detail-software-frame-wrapper31 {
    display: flex;
    flex-direction: column;
  }
  
  .funnel-detail-software-frame-parent45 {
    align-self: stretch;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 4px;
  }
  
  .funnel-detail-software-frame-wrapper31 {
    width: 326px;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 0 9px;
    box-sizing: border-box;
    min-width: 326px;
    max-width: 100%;
    margin-left: -33px;
    text-align: center;
    font-size: 12px;
    color: #475467;
  }
  
  .funnel-detail-software-card-container-child,
  .funnel-detail-software-rectangle-parent13 {
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .funnel-detail-software-rectangle-parent13 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 12px 13px 22px 23px;
    row-gap: 20px;
    z-index: 1;
  }
  
  .funnel-detail-software-card-container-child {
    width: 1016px;
    height: 301px;
    position: relative;
    display: none;
  }
  
  .funnel-detail-software-supporting-text27 {
    position: relative;
    line-height: 24px;
    display: inline-block;
    min-width: 83px;
    z-index: 2;
  }
  
  .funnel-detail-software-title-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    max-width: 100%;
  }
  
  .funnel-detail-software-supporting-text30 {
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    min-width: 113px;
    z-index: 3;
  }
  
  .funnel-detail-software-detail-title-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 151px;
    font-size: 14px;
    color: #101828;
  }
  
  .funnel-detail-software-supporting-text31 {
    width: 113px;
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    z-index: 3;
  }
  
  .funnel-detail-software-card-row {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 0 0;
    box-sizing: border-box;
    min-width: 445px;
    max-width: 100%;
  }
  
  .funnel-detail-software-dropdown15,
  .funnel-detail-software-item-title {
    position: absolute;
    z-index: 2;
  }
  
  .funnel-detail-software-dropdown15 {
    top: 0;
    left: 306px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-item-title {
    top: 19px;
    left: 139px;
    line-height: 20px;
    display: inline-block;
    min-width: 36px;
  }
  
  .funnel-detail-software-price7 {
    flex: 1;
    position: relative;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    white-space: nowrap;
    z-index: 3;
  }
  
  .funnel-detail-software-text116 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 15px;
    z-index: 2;
  }
  
  .funnel-detail-software-currency {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px 0 0;
    font-size: 12px;
    color: #475467;
  }
  
  .funnel-detail-software-item-title1 {
    position: absolute;
    top: 70px;
    left: 116px;
    line-height: 20px;
    display: inline-block;
    min-width: 83px;
    z-index: 4;
  }
  
  .funnel-detail-software-list-item {
    align-self: stretch;
    height: 90px;
    position: relative;
  }
  
  .funnel-detail-software-item-icons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    z-index: 2;
  }
  
  .funnel-detail-software-magnifier-1-icon7 {
    height: 20px;
    width: 20px;
    position: relative;
    object-fit: cover;
    min-height: 20px;
    z-index: 2;
  }
  
  .funnel-detail-software-frame-wrapper33 {
    width: 310px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  .funnel-detail-software-card-container,
  .funnel-detail-software-card-list,
  .funnel-detail-software-list-item-parent {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-list-item-parent {
    width: 326px;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
    min-width: 326px;
    margin-left: -33px;
    text-align: center;
    font-size: 12px;
    color: #475467;
  }
  
  .funnel-detail-software-card-container,
  .funnel-detail-software-card-list {
    align-items: flex-start;
  }
  
  .funnel-detail-software-card-list {
    align-self: stretch;
    flex-direction: row;
    row-gap: 20px;
  }
  
  .funnel-detail-software-card-container {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    flex-direction: column;
    padding: 12px 13px 22px 23px;
    gap: 24px;
    z-index: 1;
  }
  
  .funnel-detail-software-text-wrapper18 {
    padding: 0 60px 0 57px;
  }
  
  .funnel-detail-software-frame-wrapper37,
  .funnel-detail-software-price-wrapper,
  .funnel-detail-software-text-wrapper18,
  .funnel-detail-software-text-wrapper19 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
  }
  
  .funnel-detail-software-price-wrapper {
    align-self: stretch;
    padding: 0 3px 0 0;
    font-size: 20px;
    color: #6941c6;
  }
  
  .funnel-detail-software-frame-wrapper37,
  .funnel-detail-software-text-wrapper19 {
    padding: 0 33px 4px 30px;
  }
  
  .funnel-detail-software-frame-wrapper37 {
    padding: 0 37px 0 0;
  }
  
  .funnel-detail-software-frame-parent60 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 19px;
  }
  
  .funnel-detail-software-frame-wrapper36 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 7px 0 0;
  }
  
  .funnel-detail-software-text-parent12 {
    justify-content: flex-start;
    gap: 16px;
    font-size: 18px;
    color: #101828;
  }
  
  .funnel-detail-software-buttonsbutton-frame,
  .funnel-detail-software-frame-parent64,
  .funnel-detail-software-text-parent12 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .funnel-detail-software-frame-parent64 {
    justify-content: flex-start;
    gap: 7.3px;
  }
  
  .funnel-detail-software-buttonsbutton-frame {
    align-self: stretch;
    justify-content: flex-end;
    padding: 0 30px 0 0;
  }
  
  .funnel-detail-software-divider-container-child {
    width: 1016px;
    height: 493px;
    position: relative;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: none;
    max-width: 100%;
  }
  
  .funnel-detail-software-badge-item-parent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    min-width: 114px;
    color: #da2d20;
  }
  
  .funnel-detail-software-divider-icon8 {
    width: 968px;
    position: relative;
    max-height: 100%;
    max-width: 100%;
    z-index: 2;
  }
  
  .funnel-detail-software-heading9 {
    width: 254px;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
  }
  
  .funnel-detail-software-change7,
  .funnel-detail-software-text139 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  
  .funnel-detail-software-change7 {
    display: inline-block;
    min-width: 38px;
  }
  
  .funnel-detail-software-text139 {
    flex: 1;
    color: #475467;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .funnel-detail-software-change-and-text3,
  .funnel-detail-software-number-and-badge3 {
    display: flex;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-change-and-text3 {
    align-self: stretch;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    text-align: center;
    font-size: 14px;
    color: #079455;
  }
  
  .funnel-detail-software-number-and-badge3 {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    min-width: 105px;
  }
  
  .funnel-detail-software-chart-mini-icon3 {
    height: 48px;
    width: 96px;
    position: relative;
    object-fit: contain;
  }
  
  .funnel-detail-software-dropdown19,
  .funnel-detail-software-metric-item3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-dropdown19 {
    margin: 0 !important;
    position: absolute;
    top: 16px;
    right: 16px;
  }
  
  .funnel-detail-software-metric-item3 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    padding: 16px 15px 20px;
    position: relative;
    gap: 20px;
    min-width: 229px;
    z-index: 2;
  }
  
  .funnel-detail-software-buttonsbutton25,
  .funnel-detail-software-buttonsbutton26,
  .funnel-detail-software-metric-item-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-metric-item-parent {
    width: 968px;
    gap: 25px;
    max-width: 100%;
    font-size: 16px;
  }
  
  .funnel-detail-software-buttonsbutton25,
  .funnel-detail-software-buttonsbutton26 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
  
    padding: 6px 11px;
    gap: 5.5px;
    min-width: 92px;
    z-index: 2;
  }
  
  .funnel-detail-software-buttonsbutton26 {
    padding: 6px 10px 6px 12px;
    gap: 5.4px;
  }
  
  .funnel-detail-software-text145 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 70px;
  }
  
  .funnel-detail-software-ellipse-div {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    background-color: #17b26a;
    width: 17px;
    height: 17px;
    z-index: 3;
  }
  
  .funnel-detail-software-div {
    position: absolute;
    top: 4px;
    left: 6px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 7px;
    height: 20px;
    min-width: 7px;
    z-index: 4;
  }
  
  .funnel-detail-software-ellipse-parent {
    height: 24px;
    width: 17px;
    position: absolute;
    margin: 0 !important;
    top: -9px;
    right: -2.7px;
    font-size: 10px;
    color: #fff;
  }
  
  .funnel-detail-software-buttonsbutton27 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-direction: row;
    padding: 8px 12px;
    position: relative;
    gap: 6px;
    min-width: 79px;
    z-index: 2;
  }
  
  .funnel-detail-software-arrow-square-up-right-wrapper1,
  .funnel-detail-software-buttonsbutton-parent1,
  .funnel-detail-software-buttonsbutton27 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-buttonsbutton-parent1 {
    width: 523.3px;
    flex-direction: row;
    gap: 16px;
    max-width: calc(100% - 44px);
  }
  
  .funnel-detail-software-arrow-square-up-right-wrapper1 {
    flex-direction: column;
    padding: 8px 0 0;
  }
  
  .funnel-detail-software-divider-container1,
  .funnel-detail-software-frame-parent71 {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-frame-parent71 {
    width: 968px;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    font-size: 14px;
    color: #344054;
  }
  
  .funnel-detail-software-divider-container1 {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    padding: 12px 13px 22px 23px;
    gap: 24.3px;
    z-index: 1;
  }
  
  .funnel-detail-software-text146 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 72px;
  }
  
  .funnel-detail-software-buttonsbutton-wrapper1,
  .funnel-detail-software-buttonsbutton28 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .funnel-detail-software-buttonsbutton28 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    justify-content: flex-start;
    padding: 6px 12px;
    gap: 6px;
    z-index: 1;
    cursor: pointer;
  }
  
  .funnel-detail-software-buttonsbutton-wrapper1 {
    align-self: stretch;
    justify-content: center;
    padding: 0 20px;
  }
  
  .funnel-detail-software-text147 {
    position: relative;
    line-height: 28px;
    font-weight: 600;
  }
  
  .funnel-detail-software-card-header-badge {
    position: relative;
    line-height: 18px;
    font-weight: 500;
  }
  
  .funnel-detail-software-badge46,
  .funnel-detail-software-text-and-badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-badge46 {
    border-radius: 9999px;
    background-color: #f9f5ff;
    border: 1px solid #e9d7fe;
    padding: 0 7px;
    white-space: nowrap;
    text-align: center;
    font-size: 12px;
    color: #6941c6;
  }
  
  .funnel-detail-software-text-and-badge {
    align-self: stretch;
    padding: 0 242px 0 0;
    gap: 8px;
  }
  
  .funnel-detail-software-supporting-text44 {
    align-self: stretch;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .funnel-detail-software-text-and-supporting-text2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
    min-width: 424px;
    max-width: 100%;
  }
  
  .funnel-detail-software-text151 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 92px;
  }
  
  .funnel-detail-software-actions3,
  .funnel-detail-software-button11 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .funnel-detail-software-button11 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #7e56d9;
    border: 1px solid #7e56d9;
    overflow: hidden;
    justify-content: center;
    padding: 8px 13px;
    gap: 4px;
    color: #fff;
  }
  
  .funnel-detail-software-actions3 {
    justify-content: flex-start;
    gap: 12px;
    font-size: 14px;
    color: #475467;
  }
  
  .funnel-detail-software-content20,
  .funnel-detail-software-divider-icon10 {
    align-self: stretch;
    max-width: 100%;
  }
  
  .funnel-detail-software-content20 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 24px 0;
    box-sizing: border-box;
    gap: 16px;
  }
  
  .funnel-detail-software-divider-icon10 {
    position: relative;
    overflow: hidden;
    max-height: 100%;
  }
  
  .funnel-detail-software-card-header,
  .funnel-detail-software-frame-parent72 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-card-header {
    align-self: stretch;
    background-color: #fff;
    gap: 20px;
    z-index: 1;
    font-size: 26px;
    color: #101828;
  }
  
  .funnel-detail-software-frame-parent72 {
    flex: 1;
    gap: 24px;
  }
  
  .funnel-detail-software-button-group-base9,
  .funnel-detail-software-frame-wrapper44 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
  }
  
  .funnel-detail-software-frame-wrapper44 {
    align-self: stretch;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 60px 32px;
    max-width: 100%;
    color: #344054;
    z-index: 0;
  }
  
  .funnel-detail-software-button-group-base9 {
    width: 83px;
    background-color: #f9fafb;
    border-right: 1px solid #d0d5dd;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
  }
  
  .funnel-detail-software-text153 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 105px;
  }
  
  .funnel-detail-software-button-group-base10 {
    display: flex;
    padding: 10px 15px;
    white-space: nowrap;
  }
  
  .funnel-detail-software-button-group-base10,
  .funnel-detail-software-button-group-base11,
  .funnel-detail-software-button-group-base17 {
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }
  
  .funnel-detail-software-button-group-base11 {
    align-self: stretch;
    display: none;
    padding: 8px 16px;
    z-index: 6;
  }
  
  .funnel-detail-software-button-group-base17 {
    width: 93px;
    flex-shrink: 0;
    display: flex;
    padding: 10px 15px;
  }
  
  .funnel-detail-software-button-group1 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-search-placeholder1 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 82px;
  
  }
  
  .funnel-detail-software-supporting-text45 {
    align-self: stretch;
    width: 54px;
    position: relative;
    line-height: 24px;
    color: #475467;
    display: none;
  }
  
  .funnel-detail-software-content22,
  .funnel-detail-software-input-with-label3,
  .funnel-detail-software-input3 {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-content22 {
    flex: 1;
    flex-direction: row;
    align-items: center;
    padding: 0 262px 0 0;
    box-sizing: border-box;
    gap: 8px;
  }
  
  .funnel-detail-software-input-with-label3,
  .funnel-detail-software-input3 {
    align-self: stretch;
  }
  
  .funnel-detail-software-input3 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
      0 0 0 4px rgba(158, 119, 237, 0.24);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d6bbfb;
    box-sizing: border-box;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    padding: 8px 13px;
    font-size: 16px;
    color: #101828;
  }
  
  .funnel-detail-software-input-with-label3 {
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }
  
  .funnel-detail-software-hint-text3 {
    width: 320px;
    position: relative;
    line-height: 20px;
    color: #475467;
    display: none;
  }
  
  .funnel-detail-software-input-dropdown3 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    max-width: 400px;
  }
  
  .funnel-detail-software-actions4,
  .funnel-detail-software-button12 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .funnel-detail-software-button12 {
    width: 99px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
    padding: 10px 13px;
    gap: 4px;
  }
  
  .funnel-detail-software-actions4 {
    width: 511px;
    justify-content: flex-start;
    gap: 12px;
    max-width: 100%;
  }
  
  .funnel-detail-software-content-items9,
  .funnel-detail-software-content21,
  .funnel-detail-software-filters-bar1 {
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .funnel-detail-software-content21 {
    align-self: stretch;
    border-radius: 12px;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 16px;
    gap: 20px;
  }
  
  .funnel-detail-software-content-items9,
  .funnel-detail-software-filters-bar1 {
    justify-content: flex-start;
  }
  
  .funnel-detail-software-filters-bar1 {
    flex: 1;
    background-color: #fff;
    border-bottom: 1px solid #eaecf0;
    flex-direction: column;
    z-index: 2;
  }
  
  .funnel-detail-software-content-items9 {
    align-self: stretch;
    flex-direction: row;
    padding: 0 30px 23px 32px;
    color: #344054;
  }
  
  .funnel-detail-software-badge-number1,
  .funnel-detail-software-text162 {
    position: relative;
    line-height: 28px;
    font-weight: 600;
    display: inline-block;
    min-width: 123px;
  }
  
  .funnel-detail-software-badge-number1 {
    line-height: 18px;
    font-weight: 500;
    min-width: 56px;
  }
  
  .funnel-detail-software-badge47,
  .funnel-detail-software-text-and-badge1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-badge47 {
    width: 72px;
    border-radius: 9999px;
    background-color: #f9f5ff;
    border: 1px solid #e9d7fe;
    box-sizing: border-box;
    padding: 0 7px;
    white-space: nowrap;
    text-align: center;
    font-size: 12px;
    color: #6941c6;
  }
  
  .funnel-detail-software-text-and-badge1 {
    align-self: stretch;
    padding: 0 729px 0 0;
    gap: 8px;
  }
  
  .funnel-detail-software-supporting-text46 {
    width: 697px;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    display: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }
  
  .funnel-detail-software-text-and-supporting-text3 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 12px 0;
    box-sizing: border-box;
    gap: 4px;
    max-width: 100%;
  }
  
  .funnel-detail-software-actions5,
  .funnel-detail-software-card-header1,
  .funnel-detail-software-content23 {
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-actions5 {
    display: none;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    color: #475467;
  }
  
  .funnel-detail-software-card-header1,
  .funnel-detail-software-content23 {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
  }
  
  .funnel-detail-software-content23 {
    flex-direction: row;
    padding: 20px 24px 0;
    box-sizing: border-box;
    gap: 16px;
  }
  
  .funnel-detail-software-card-header1 {
    background-color: #fff;
    flex-direction: column;
    gap: 20px;
    flex-shrink: 0;
    font-size: 18px;
  }
  
  .funnel-detail-software-checkbox-base {
    height: 20px;
    width: 20px;
    position: relative;
    border-radius: 6px;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .funnel-detail-software-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-sub-nav-item {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 34px;
  }
  
  .funnel-detail-software-arrow-down-icon1 {
    width: 16px;
    height: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .funnel-detail-software-sub-nav-items {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
  }
  
  .funnel-detail-software-sub-nav-items-wrapper {
    flex-direction: column;
    padding: 1px 0 0;
  }
  
  .funnel-detail-software-checkbox-wrapper,
  .funnel-detail-software-sub-nav-items-wrapper,
  .funnel-detail-software-table-header-cell {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-table-header-cell {
    width: 441px;
    margin: 0 !important;
    position: absolute;
    top: 0;
    left: -380px;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    flex-direction: row;
    padding: 12px 24px 10px;
    gap: 12px;
    font-size: 12px;
    color: #475467;
  }
  
  .funnel-detail-software-checkbox-wrapper {
    flex-direction: column;
    padding: 10px 0 0;
  }
  
  .funnel-detail-software-avatar-online-indicator {
    height: 13px;
    width: 13px;
    position: relative;
    border-radius: 9999px;
    background-color: #17b26a;
    border: 1.5px solid #fff;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
  }
  
  .funnel-detail-software-avatar2,
  .funnel-detail-software-contrast-border2 {
    height: 40px;
    width: 40px;
    border-radius: 9999px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .funnel-detail-software-contrast-border2 {
    border: 0.8px solid rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    overflow: hidden;
    justify-content: flex-end;
    padding: 30px 0 0 26px;
  }
  
  .funnel-detail-software-avatar2 {
    justify-content: flex-start;
    background-image: url(../../../icons/Gerator/sales_iq/avatar2@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  
  .funnel-detail-software-account-button,
  .funnel-detail-software-supporting-text47 {
    position: relative;
    line-height: 20px;
    display: inline-block;
  }
  
  .funnel-detail-software-account-button {
    font-weight: 500;
    min-width: 75px;
  }
  
  .funnel-detail-software-supporting-text47 {
    color: #475467;
    min-width: 97px;
  }
  
  .funnel-detail-software-table-cell {
    width: 405px;
    margin: 0 !important;
    position: absolute;
    top: 44px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .funnel-detail-software-avatar3 {
    height: 40px;
    width: 40px;
    position: relative;
    border-radius: 9999px;
    flex-shrink: 0;
    background-image: url(../../../icons/Gerator/sales_iq/avatar3@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  
  .funnel-detail-software-dropdown-first-date {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 96px;
  }
  
  .funnel-detail-software-table-cell1 {
    width: 467px;
    margin: 0 !important;
    position: absolute;
    top: 116px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .funnel-detail-software-text167 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 84px;
  }
  
  .funnel-detail-software-table-cell2 {
    width: 428px;
    margin: 0 !important;
    position: absolute;
    top: 188px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .funnel-detail-software-avatar5 {
    height: 40px;
    width: 40px;
    position: relative;
    border-radius: 9999px;
    flex-shrink: 0;
    background-image: url(../../../icons/Gerator/sales_iq/avatar4@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  
  .funnel-detail-software-card-content-third {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 103px;
    white-space: nowrap;
  }
  
  .funnel-detail-software-contrast-border6,
  .funnel-detail-software-table-cell3 {
    position: absolute;
    margin: 0 !important;
    box-sizing: border-box;
  }
  
  /* .funnel-detail-software-table-cell3 {
    width: 411px;
    top: 260px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  } */
  
  .funnel-detail-software-contrast-border6 {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 9999px;
    border: 0.8px solid rgba(0, 0, 0, 0.08);
    overflow: hidden;
  }
  
  .funnel-detail-software-card-header-actions {
    flex: 1;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    z-index: 1;
  }
  
  .funnel-detail-software-avatar6 {
    height: 40px;
    width: 40px;
    border-radius: 9999px;
    background-color: #f2f4f7;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 0;
    box-sizing: border-box;
    position: relative;
  }
  
  .funnel-detail-software-button-upload-icon {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 82px;
  }
  
  .funnel-detail-software-button-upload,
  .funnel-detail-software-table-cell4 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 14px;
    color: #101828;
  }
  
  .funnel-detail-software-table-cell4 {
    width: 428px;
    margin: 0 !important;
    position: absolute;
    top: 332px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    flex-direction: row;
    padding: 16px 24px 14px;
    gap: 12px;
    text-align: center;
    font-size: 16px;
    color: #667085;
  }
  
  .funnel-detail-software-dropdown-table-input {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 78px;
  }
  
  .funnel-detail-software-table-cell5 {
    width: 428px;
    margin: 0 !important;
    position: absolute;
    bottom: 288px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .funnel-detail-software-avatar8 {
    height: 40px;
    width: 40px;
    position: relative;
    border-radius: 9999px;
    flex-shrink: 0;
    background-image: url(../../../icons/Gerator/sales_iq/avatar5@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  
  .funnel-detail-software-table-cell6,
  .funnel-detail-software-table-cell7 {
    width: 411px;
    margin: 0 !important;
    position: absolute;
    bottom: 216px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .funnel-detail-software-table-cell7 {
    width: 428px;
    bottom: 144px;
    text-align: center;
    font-size: 16px;
    color: #667085;
  }
  
  .funnel-detail-software-avatar10 {
    height: 40px;
    width: 40px;
    position: relative;
    border-radius: 9999px;
    flex-shrink: 0;
    background-image: url(../../../icons/Gerator/sales_iq/avatar6@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  
  .funnel-detail-software-title3 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 67px;
  }
  
  .funnel-detail-software-table-cell8 {
    width: 428px;
    margin: 0 !important;
    position: absolute;
    bottom: 72px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .funnel-detail-software-avatar11 {
    height: 40px;
    width: 40px;
    position: relative;
    border-radius: 9999px;
    flex-shrink: 0;
    background-image: url(../../../icons/Gerator/sales_iq/avatar7@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  
  .funnel-detail-software-title4 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 95px;
  }
  
  .funnel-detail-software-table-cell9 {
    width: 428px;
    margin: 0 !important;
    position: absolute;
    bottom: 0;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
    z-index: 1;
  }
  
  .funnel-detail-software-text169 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 50px;
  }
  
  .funnel-detail-software-table-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-table-header {
    gap: 4px;
  }
  
  
  
  .funnel-detail-software-text170 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 93px;
  }
  
  /* .funnel-detail-software-table-cell10 {
    width: 255px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 24px 24px;
  } */
  
  .funnel-detail-software-text171 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 85px;
  }
  
  .funnel-detail-software-table-cell11 {
    width: 247px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 24px 24px;
  }
  
  .funnel-detail-software-text172 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 91px;
  }
  
  .funnel-detail-software-table-cell15,
  .funnel-detail-software-table-cell16,
  .funnel-detail-software-table-cell18 {
    width: 279px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 24px 24px;
  }
  
  .funnel-detail-software-table-cell16,
  .funnel-detail-software-table-cell18 {
    width: 271px;
  }
  
  .funnel-detail-software-table-cell18 {
    width: 239px;
  }
  
  .funnel-detail-software-column,
  .funnel-detail-software-table-header-cell-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-column {
    width: 120px;
    flex-direction: column;
    padding: 0;
    box-sizing: border-box;
    z-index: 2;
    text-align: center;
    font-size: 12px;
    color: #344054;
  }
  
  .funnel-detail-software-table-header-cell-parent {
    flex-direction: row;
    position: relative;
  }
  
  .funnel-detail-software-frame-wrapper45 {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    max-width: 100%;
    color: #101828;
  }
  
  .funnel-detail-software-text180 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 40px;
  }
  
  .funnel-detail-software-help-icon {
    height: 16px;
    width: 16px;
    position: relative;
  }
  
  /* .funnel-detail-software-table-header-cell2 {
    width: 297px;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 13px 24px 11px;
    font-size: 12px;
  } */
  
  .funnel-detail-software-text181 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 101px;
  }
  
 
  
  .funnel-detail-software-user-cell {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 87px;
  }
  
  .funnel-detail-software-table-cell21 {
    width: 407px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 23px 24px;
    max-width: 232%;
    flex-shrink: 0;
  }
  
  .funnel-detail-software-user-cell1 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 93px;
  }
  
  .funnel-detail-software-table-cell22 {
    width: 407px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 24px 24px;
    max-width: 232%;
    flex-shrink: 0;
  }
  
  .funnel-detail-software-user-cell2,
  .funnel-detail-software-user-cell3,
  .funnel-detail-software-user-cell4 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 64px;
  }
  
  .funnel-detail-software-user-cell3,
  .funnel-detail-software-user-cell4 {
    min-width: 84px;
  }
  
  .funnel-detail-software-user-cell4 {
    min-width: 82px;
  }
  
  .funnel-detail-software-table-cell25 {
    width: 328px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 24px 24px;
    max-width: 187%;
    flex-shrink: 0;
  }
  
  .funnel-detail-software-user-cell5 {
    display: inline-block;
    min-width: 92px;
  }
  
  .funnel-detail-software-user-cell5,
  .funnel-detail-software-user-cell6,
  .funnel-detail-software-user-cell7 {
    position: relative;
    line-height: 20px;
  }
  
  .funnel-detail-software-user-cell6 {
    display: inline-block;
    min-width: 68px;
  }
  
  .funnel-detail-software-user-cell7 {
    white-space: nowrap;
  }
  
  .funnel-detail-software-column1,
  .funnel-detail-software-table-cell29 {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
  }
  
  .funnel-detail-software-table-cell29 {
    width: 287px;
    border-bottom: 1px solid #eaecf0;
    flex-direction: row;
    align-items: center;
    padding: 26px 24px 24px;
  }
  
  .funnel-detail-software-column1 {
    width: 176px;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    z-index: 1;
  }
  
  .funnel-detail-software-user-header {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 37px;
  }
  
  
  .funnel-detail-software-table-header2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .funnel-detail-software-table-header2 {
    flex: 1;
    justify-content: flex-start;
    gap: 4px;
  }
  
  /* .funnel-detail-software-table-cell30 {
    border-bottom: 1px solid #eaecf0;
    justify-content: center;
  } */
  
 
/*   
  .funnel-detail-software-table-cell30 {
    padding: 26px 50px 24px;
  } */
  
  .funnel-detail-software-column2,
  .funnel-detail-software-dropdown28 {
    width: 21px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-column2 {
    width: 120px;
    align-items: flex-end;
    padding: 0;
    box-sizing: border-box;
    z-index: 1;
    font-size: 12px;
  }
  
  .funnel-detail-software-frame-parent73 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 100%;
    gap: 20px;
    color: #475467;
  }
  
  .funnel-detail-software-page-label {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 59px;
  }
  
  .funnel-detail-software-buttonsbutton29 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 11px;
    gap: 4px;
  }
  
  .funnel-detail-software-button-wrap,
  .funnel-detail-software-pagination-controls {
    display: flex;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-button-wrap {
    align-self: stretch;
    flex-direction: row;
    align-items: center;
  }
  
  .funnel-detail-software-pagination-controls {
    width: 326px;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-start;
    padding: 2px 0 0;
    box-sizing: border-box;
  }
  
  .funnel-detail-software-number11 {
    width: 7px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 7px;
  }
  
  .funnel-detail-software-content24,
  .funnel-detail-software-pagination-number-base {
    flex: 1;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-content24 {
    padding: 10px 16px;
  }
  
  .funnel-detail-software-pagination-number-base {
    background-color: #f9fafb;
    overflow: hidden;
    color: #18222f;
  }
  
  .funnel-detail-software-number12 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 9px;
  }
  
  .funnel-detail-software-content25,
  .funnel-detail-software-pagination-number-base1 {
    flex: 1;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-content25 {
    padding: 10px 15px;
  }
  
  .funnel-detail-software-pagination-number-base1 {
    overflow: hidden;
  }
  
  .funnel-detail-software-number13 {
    width: 10px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 10px;
  }
  
  .funnel-detail-software-content26,
  .funnel-detail-software-pagination-number-base2 {
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-content26 {
    padding: 10px 15px;
  }
  
  .funnel-detail-software-pagination-number-base2 {
    overflow: hidden;
  }
  
  .funnel-detail-software-number14 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 13px;
  }
  
  .funnel-detail-software-content27 {
    flex: 1;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 13px;
  }
  
  .funnel-detail-software-number17 {
    width: 16px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 16px;
  }
  
  .funnel-detail-software-content30,
  .funnel-detail-software-pagination-numbers {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-content30 {
    border-radius: 8px;
    padding: 10px 12px;
  }
  
  .funnel-detail-software-pagination-numbers {
    width: 292px;
    flex-shrink: 0;
    gap: 2px;
    text-align: center;
    color: #475467;
  }
  
  .funnel-detail-software-page-label1 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 32px;
  }
  
  .funnel-detail-software-button-wrap1,
  .funnel-detail-software-buttonsbutton30 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .funnel-detail-software-buttonsbutton30 {
    width: 84px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
    padding: 6px 11px;
    gap: 4px;
  }
  
  .funnel-detail-software-button-wrap1 {
    align-self: stretch;
    justify-content: flex-end;
  }
  
  .funnel-detail-software-pagination,
  .funnel-detail-software-table {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-pagination {
    align-self: stretch;
    border-top: 1px solid #eaecf0;
    overflow-x: auto;
    flex-direction: row;
    padding: 12px 24px 16px;
    gap: 12px;
    z-index: 3;
    color: #344054;
  }
  
  .funnel-detail-software-table {
    flex: 1;
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow: hidden;
    flex-direction: column;
    max-width: 100%;
    z-index: 1;
  }
  
  .funnel-detail-software-content-items10 {
    align-self: stretch;
    padding: 0 30px 24px 32px;
    max-width: 100%;
  }
  
  .funnel-detail-software-content-items10,
  .funnel-detail-software-content-items11,
  .funnel-detail-software-table-cell41,
  .funnel-detail-software-table-cell42 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-content-items11 {
    align-self: stretch;
    padding: 0 30px 0 32px;
    max-width: 100%;
    font-size: 26px;
  }
  
  /* .funnel-detail-software-table-cell40,
  .funnel-detail-software-table-cell41,
  .funnel-detail-software-table-cell42 {
    position: absolute;
    left: 0;
    border-bottom: 1px solid #eaecf0;
    gap: 12px;
  }
   */

  
  /* .funnel-detail-software-table-cell40,
  .funnel-detail-software-table-cell41,
  .funnel-detail-software-table-cell42 {
    top: 44px;
    width: 245px;
    overflow-x: auto;
    padding: 16px 24px 14px;
  }
  
  .funnel-detail-software-table-cell41,
  .funnel-detail-software-table-cell42 {
    top: 116px;
    width: 252px;
  }
  
  .funnel-detail-software-table-cell42 {
    top: 188px;
  }
  
  .funnel-detail-software-table-cell43 {
    top: 260px;
    width: 252px;
  }
  
  .funnel-detail-software-table-cell43,
  .funnel-detail-software-table-cell44,
  .funnel-detail-software-table-cell45 {
    position: absolute;
    left: 0;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .funnel-detail-software-table-cell44 {
    top: 332px;
    width: 311px;
    text-align: center;
    font-size: 16px;
    color: #667085;
  }
  
  .funnel-detail-software-table-cell45 {
    top: 404px;
    width: 260px;
  }
  
  .funnel-detail-software-table-cell46 {
    top: 476px;
    box-sizing: border-box;
    width: 252px;
  }
  
  .funnel-detail-software-table-cell46,
  .funnel-detail-software-table-cell47,
  .funnel-detail-software-table-cell48,
  .funnel-detail-software-table-cell49 {
    position: absolute;
    left: 0;
    border-bottom: 1px solid #eaecf0;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .funnel-detail-software-table-cell47 {
    top: 548px;
    box-sizing: border-box;
    width: 242px;
    text-align: center;
    font-size: 16px;
    color: #667085;
  }
  
  .funnel-detail-software-table-cell48,
  .funnel-detail-software-table-cell49 {
    top: 620px;
  }
  
  .funnel-detail-software-table-cell49 {
    top: 692px;
    box-sizing: border-box;
    width: 242px;
    z-index: 1;
  } */
  
  .funnel-detail-software-text211 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 55px;
  }
  
  .funnel-detail-software-table-header-cell5 {
    width: 441px;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 13px 24px 11px;
    max-width: 286%;
    flex-shrink: 0;
    font-size: 12px;
    color: #475467;
  }
  
  .funnel-detail-software-background {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    background-color: #f5f5f5;
    width: 100%;
    height: 100%;
  }
  
  .funnel-detail-software-f4b711e503bc72fe396e5043e0c2-1-icon3 {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 24px;
    height: 24px;
    object-fit: cover;
    z-index: 1;
  }
  
  .funnel-detail-software-background-parent {
    height: 40px;
    width: 40px;
    position: relative;
  }
  
  .funnel-detail-software-supporting-text70 {
    position: relative;
    line-height: 20px;
    color: #475467;
    display: inline-block;
    min-width: 101px;
    white-space: nowrap;
  }
  
  .funnel-detail-software-table-cell50,
  .funnel-detail-software-table-cell51 {
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 228px 14px 24px;
    gap: 12px;
    max-width: 263%;
    flex-shrink: 0;
  }
  
  .funnel-detail-software-table-cell51 {
    padding: 16px 290px 14px 24px;
    max-width: 303%;
  }
  
  .funnel-detail-software-progress-label {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 98px;
  }
  
  .funnel-detail-software-table-cell52 {
    border-bottom: 1px solid #eaecf0;
    flex-direction: row;
    align-items: center;
    padding: 16px 251px 14px 24px;
    gap: 12px;
    max-width: 278%;
    flex-shrink: 0;
  }
  
  .funnel-detail-software-column3,
  .funnel-detail-software-table-cell52,
  .funnel-detail-software-table-cell53 {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
  }
  
  .funnel-detail-software-table-cell53 {
    border-bottom: 1px solid #eaecf0;
    flex-direction: row;
    align-items: center;
    padding: 16px 234px 14px 24px;
    gap: 12px;
    max-width: 267%;
    flex-shrink: 0;
  }
  
  .funnel-detail-software-column3 {
    position: absolute;
    top: 0;
    left: 264.5px;
    width: 154.5px;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    z-index: 1;
  }
  
  .funnel-detail-software-table-header-cell-group {
    height: 764px;
    width: 441px;
    position: relative;
    max-width: 100%;
  }
  
  .funnel-detail-software-marker-pin-02-group,
  .funnel-detail-software-table-cell65 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-marker-pin-02-group {
    gap: 7px;
  }
  
  .funnel-detail-software-table-cell65 {
    align-self: stretch;
    border-bottom: 1px solid #eaecf0;
    padding: 26px 24px 24px;
  }
  
  .funnel-detail-software-table-header-cell-container {
    width: 279px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-column4 {
    align-self: stretch;
    display: flex;
    padding: 0;
    z-index: 1;
  }
  
  .funnel-detail-software-column-wrapper,
  .funnel-detail-software-column4,
  .funnel-detail-software-column5 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-column-wrapper {
    width: 66px;
    display: flex;
    padding: 0 22px 0 0;
    box-sizing: border-box;
    text-align: center;
    font-size: 12px;
    color: #344054;
  }
  
  .funnel-detail-software-column5 {
    width: 176px;
    padding: 0;
    min-width: 176px;
  }
  
  .funnel-detail-software-column5,
  .funnel-detail-software-column6,
  .funnel-detail-software-table-header-cell8 {
    display: flex;
    box-sizing: border-box;
  }
  
  .funnel-detail-software-table-header-cell8 {
    width: 120px;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 13px 41px 11px;
  }
  
  .funnel-detail-software-column6 {
    width: 79px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 0;
    min-width: 79px;
    font-size: 12px;
  }
  
  .funnel-detail-software-column-parent,
  .funnel-detail-software-frame-parent74,
  .funnel-detail-software-pagination1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .funnel-detail-software-column-parent {
    width: 333px;
    justify-content: flex-start;
    gap: 78px;
    max-width: 100%;
    z-index: 1;
    color: #475467;
  }
  
  .funnel-detail-software-frame-parent74,
  .funnel-detail-software-pagination1 {
    align-self: stretch;
  }
  
  .funnel-detail-software-frame-parent74 {
    justify-content: space-between;
    max-width: 100%;
    gap: 20px;
  }
  
  .funnel-detail-software-pagination1 {
    border-top: 1px solid #eaecf0;
    overflow-x: auto;
    justify-content: flex-start;
    padding: 12px 24px 16px;
    gap: 12px;
    z-index: 2;
    color: #344054;
  }
  
  .funnel-detail-software-text227 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 73px;
  }
  
  .funnel-detail-software-badge50,
  .funnel-detail-software-text-and-badge4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-badge50 {
    width: 89px;
    border-radius: 9999px;
    background-color: #f9f5ff;
    border: 1px solid #e9d7fe;
    box-sizing: border-box;
    padding: 0 7px;
    white-space: nowrap;
    text-align: center;
    font-size: 12px;
    color: #6941c6;
  }
  
  .funnel-detail-software-text-and-badge4 {
    align-self: stretch;
    padding: 0 332px 0 0;
    gap: 7px;
  }
  
  .funnel-detail-software-content-items14,
  .funnel-detail-software-text-and-supporting-text16 {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-text-and-supporting-text16 {
    flex: 1;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    min-width: 436px;
  }
  
  .funnel-detail-software-content-items14 {
    align-self: stretch;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 29px 0 33px;
    box-sizing: border-box;
    font-size: 26px;
  }
  
  .funnel-detail-software-text232 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 53px;
  }
  
  .funnel-detail-software-button-group-base27,
  .funnel-detail-software-button-group-base28 {
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
  }
  
  .funnel-detail-software-button-group-base27 {
    width: 85px;
    background-color: #f9fafb;
    flex-shrink: 0;
    white-space: nowrap;
  }
  
  .funnel-detail-software-button-group-base28 {
    background-color: #fff;
  }
  
  .funnel-detail-software-text240 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 89px;
  }
  
  .funnel-detail-software-filter-bar-container {
    align-self: stretch;
    flex-direction: row;
    padding: 0 29px 0 33px;
    box-sizing: border-box;
    color: #344054;
  }
  
  .funnel-detail-software-filter-bar-container,
  .funnel-detail-software-frame-parent,
  .funnel-detail-software-all-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .funnel-detail-software-frame-parent {
    flex: 1;
    flex-direction: column;
    min-width: 701px;
    font-size: 14px;
  }
  
  .funnel-detail-software-all-content {
    align-self: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    /* padding: 0 0 67px; */
    box-sizing: border-box;
    row-gap: 20px;
  }
  
  .funnel-detail-software-featured-icon {
    width: 48px;
    height: 48px;
    position: relative;
    border-radius: 10px;
  }
  
  .funnel-detail-software-supporting-text82 {
    align-self: stretch;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
  }
  
  .funnel-detail-software-text-and-supporting-text17 {
    width: 352px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 4px;
    max-width: 352px;
  }
  
  .funnel-detail-software-buttonsbutton33,
  .funnel-detail-software-content45,
  .funnel-detail-software-text-and-supporting-text17 {
    display: flex;
    align-items: center;
  }
  
  .funnel-detail-software-content45 {
    align-self: stretch;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 20px;
    gap: 16px;
  }
  
  .funnel-detail-software-buttonsbutton33 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    flex-direction: row;
    justify-content: center;
    padding: 8px 15px;
    gap: 6px;
  }
  
  .funnel-detail-software-text245 {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    min-width: 91px;
  }
  
  .funnel-detail-software-actions11,
  .funnel-detail-software-buttonsbutton34 {
    display: flex;
    flex-direction: row;
  }
  
  .funnel-detail-software-buttonsbutton34 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #7e56d9;
    border: 1px solid #7e56d9;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: 8px 15px;
    gap: 6px;
    color: #fff;
  }
  
  .funnel-detail-software-actions11 {
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    text-align: left;
    color: #344054;
  }
  
  .funnel-detail-software-empty-state,
  .funnel-detail-software-empty-state1 {
    flex: 1;
    display: flex;
    align-items: center;
    max-width: 100%;
  }
  
  .funnel-detail-software-empty-state1 {
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
  }
  
  .funnel-detail-software-empty-state {
    flex-direction: row;
    justify-content: center;
    z-index: 1;
  }
  
  .funnel-detail-software-empty-state-wrapper,
  .funnel-detail-software-all {
    display: flex;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .funnel-detail-software-empty-state-wrapper {
    width: 1074px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 29px;
    text-align: center;
  }
  
  .funnel-detail-software-all {
    align-self: stretch;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    /* padding: 0 0 245px; */
  }
  
  .funnel-detail-software-bifocal-optometry-measurement-icon {
    width: 391px;
    position: relative;
    max-height: 100%;
    object-fit: cover;
    max-width: 100%;
  }
  
  .funnel-detail-software-title8 {
    align-self: stretch;
    position: relative;
    line-height: 30px;
    font-weight: 600;
  }
  
  .funnel-detail-software-content46,
  .funnel-detail-software-text-and-supporting-text18 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-text-and-supporting-text18 {
    width: 352px;
    gap: 8px;
    max-width: 352px;
  }
  
  .funnel-detail-software-content46 {
    align-self: stretch;
    padding: 0 20px;
    box-sizing: border-box;
    gap: 20px;
    max-width: 100%;
  }
  
  .funnel-detail-software-add,
  .funnel-detail-software-learn-more {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    min-width: 98px;
  }
  
  .funnel-detail-software-learn-more {
    min-width: 88px;
  }
  
  .funnel-detail-software-actions12,
  .funnel-detail-software-empty-state3 {
    display: flex;
    justify-content: flex-start;
  }
  
  .funnel-detail-software-actions12 {
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    text-align: left;
    font-size: 16px;
    color: #344054;
  }
  
  .funnel-detail-software-empty-state3 {
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    max-width: 100%;
  }
  
  .funnel-detail-software-empty-state-container,
  .funnel-detail-software-empty-state2 {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .funnel-detail-software-empty-state2 {
    flex: 1;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    padding: 41.5px 0;
    z-index: 1;
  }
  
  .funnel-detail-software-empty-state-container {
    width: 1076px;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 30px 124px;
    text-align: center;
    font-size: 20px;
  }
  
  
  
  
  
  
  
  
  
  .funnel-detail-software-funnel-detail-software-frame-layout {
    max-width: 1524px;
    margin: 0 auto;
    position: relative;
    align-items: flex-end;
    padding: 7px 0 0;
    box-sizing: border-box;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-size: 16px;
    color: #101828;
    font-family: Inter;
  
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    background-color: #fff;
    
  
    align-self: stretch;
    height: max-content;
  }
  
  .funnel-detail-software-buttonsbutton5a {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 15px;
    background-color: #7e56d9;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    gap: 6px;
    position: relative;
  }
  
  .funnel-detail-software-buttonsbutton5a:hover{
    background-color: #6941c6;
  }
  
  .funnel-detail-software-search-lg-icon1 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .funnel-detail-software-text-padding15 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  
  .funnel-detail-software-text74 {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 91px;
  }
  
  .funnel-detail-software-dropdown-wrapper {
    position: absolute; 
    z-index: 3;
    right: 35px;
    top: 800px;
  }


  /* Table Css */



/*Added to radar Table Css */


         /*Searched Table Css */

   
      /* Load More css  */

      .funnel-detail-software-frame-wrapper44 {
        align-self: stretch;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 10px 32px;
        max-width: 100%;
        color: #344054;
        z-index: 0;
  
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
      }
  
      .funnel-detail-software-frame-parent72 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: 100%;
  
        flex: 1;
        gap: 24px;
      }
  
      .funnel-detail-software-buttonsbutton-wrapper1 {
        align-self: stretch;
        justify-content: center;
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }
      
      .funnel-detail-software-buttonsbutton-wrapper1-child {
        outline: none;
        border: none;
        background-color: #fff;
        padding: 0;
      }
  
      .funnel-detail-software-buttonsbutton28 {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid #d0d5dd;
        overflow: hidden;
        justify-content: flex-start;
        padding: 6px 12px;
        gap: 6px;
        z-index: 1;
        cursor: pointer;
      }
  
      .funnel-detail-software-arrow-narrow-left-icon {
        height: 24px;
        width: 24px;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
        min-height: 24px;
      }
  
      .funnel-detail-software-search-input {
        display: flex;
        justify-content: flex-start;
   
        flex-direction: column;
        align-items: flex-start;
        padding: 2px 0 0;
      }
  
      .funnel-detail-software-text146 {
        position: relative;
        line-height: 20px;
        font-weight: 600;
        display: inline-block;
        min-width: 72px;
      }
  
      /* Card Css  */
      .funnel-detail-software-component-listing-container {
        box-sizing: border-box;
        flex-direction: column;
        flex-shrink: 0;
        justify-content: initial;
        padding: 0 29px;
        align-items: flex-start;
        /* width: 100%; */
        display: flex;
        /* max-height: 95vh; */
        height: max-content;
        /* overflow-y: auto; */
        overflow-y: scroll;
        /* Enable vertical scrolling */
        scrollbar-width: none;
        /* For Firefox */
        -ms-overflow-style: none;
        /* For Internet Explorer and Edge */
        gap: 50px;
    }
    
    
    .funnel-detail-software-component-listing-container::-webkit-scrollbar {
        display: none;
        /* For Chrome, Safari, and Opera */
    }
    
    
    .funnel-detail-software-component-main-buttons-group {
        display: flex;
        flex-direction: row;
        gap: 12px;
    }
    
    .funnel-detail-software-component-funnel-content-items {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        /* max-width: 100%; */
        width: 100%;
        z-index: 0;
        box-sizing: border-box;
    
        align-self: stretch;
        flex-direction: row;
        /* padding: 0 30px 24px 32px; */
        font-size: 18px;
    }
    
    .funnel-detail-software-component-funnel-divider-container1 {
        display: flex;
        align-items: flex-start;
        max-width: 100%;
        position: relative;
        flex: 1;
        border-radius: 12px;
        background-color: #fff;
        border: 1px solid #eaecf0;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: flex-start;
        padding: 12px 13px 22px 23px;
        gap: 24.0px;
        z-index: 1;
    }
    
    .funnel-detail-software-component-funnel-divider-container-child {
        width: 1016px;
        height: 493px;
        position: relative;
        border-radius: 12px;
        background-color: #fff;
        border: 1px solid #eaecf0;
        box-sizing: border-box;
        display: none;
        max-width: 100%;
    }
    
    
    .funnel-detail-software-component-funnel-rectangle-parent5 {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        max-width: 100%;
    
        align-self: stretch;
        align-items: flex-start;
        gap: 24px;
    }
    
    .funnel-detail-software-component-funnel-frame-child7 {
        top: 0;
    
        height: 148px;
        width: 148px;
        border-radius: 12px;
        object-fit: cover;
    }
    
    .funnel-detail-software-component-funnel-frame-parent1 {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        max-width: 100%;
    
        flex: 1;
        align-items: flex-start;
        gap: 15px;
        /* min-width: 524px; */
    }
    
    .funnel-detail-software-component-funnel-action-fields {
        display: flex;
        justify-content: flex-start;
        max-width: 80%;
    
       flex-direction: column;
        flex: 1;
        padding: 10px 0 0;
        box-sizing: border-box;
    }
    
    .funnel-detail-software-component-funnel-input-elements {
        display: flex;
        justify-content: flex-start;
        max-width: 100%;
    
        flex-direction: column;
        align-items: flex-start;
    
        align-self: stretch;
        gap: 14px;
    }
    
    .funnel-detail-software-component-funnel-text78 {
        position: relative;
        z-index: 2;
        width: 640px;
        line-height: 28px;
        font-weight: 600;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: inherit !important;
    }
    
    .funnel-detail-software-component-funnel-text78:hover {
        text-decoration: underline;
    }
    
    .funnel-detail-software-component-funnel-frame-parent21 {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    
        width: 615px;
        align-items: flex-end;
        gap: 6.0px;
        max-width: 100%;
        text-align: center;
        font-size: 14px;
        color: #475467;
    }
    
    .funnel-detail-software-component-funnel-badge-item-parent {
        /* flex: 1; */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 15px;
        /* min-width: 114px; */
        color: #da2d20;
    }
    
    .funnel-detail-software-component-funnel-badge-parent {
        display: flex;
        align-items: flex-start;
        gap: 18px;
    
        flex-direction: row;
        justify-content: flex-start;
    }
    
    .funnel-detail-software-component-funnel-badge34 {
    
        width: 69px;
        border-radius: 9999px;
        background-color: #fffdfd;
        border: 1px solid #da2d20;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0 8px;
        gap: 6px;
        mix-blend-mode: normal;
    }
    
    .funnel-detail-software-component-funnel-dot-icon10 {
        height: 8px;
        width: 8px;
        position: relative;
        display: none;
    }
    
    .funnel-detail-software-component-funnel-text86 {
        position: relative;
        line-height: 20px;
        font-weight: 500;
        display: inline-block;
        min-width: 51px;
    }
    
    .funnel-detail-software-component-funnel-supporting-text8 {
        position: relative;
        font-size: 16px;
        line-height: 24px;
        color: #475467;
        text-align: left;
        display: inline-block;
        min-width: 83px;
        z-index: 2;
    }
    
    .funnel-detail-software-component-funnel-frame-parent4 {
        flex-direction: row;
        justify-content: flex-start;
    
        display: flex;
        align-items: flex-start;
        gap: 8px;
        text-align: left;
        font-size: 16px;
        color: #fff;
    }
    
    .funnel-detail-software-component-funnel-end-label {
      padding: 0px 8px;
      max-width: 56px;
        border-radius: 8px;
        background-color: #fdb022;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 1px 4px;
        box-sizing: border-box;
        z-index: 2;
    }
    
    .funnel-detail-software-component-funnel-search-icon-input {
        position: relative;
        line-height: 24px;
        font-weight: 500;
        display: inline-block;
        /* min-width: 41px; */
    }
    
    .funnel-detail-software-component-funnel-type-support {
        flex-direction: column;
        padding: 1px 0 0;
        color: #475467;
    
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
    
    .funnel-detail-software-component-funnel-supporting-text9 {
        position: relative;
        line-height: 24px;
        display: inline-block;
        min-width: 117px;
        z-index: 2;
    }
    
    .funnel-detail-software-component-funnel-badge33 {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 6px;
        background-color: #fff;
        border: 1px solid #d0d5dd;
        flex-direction: row;
        padding: 0 7px;
        white-space: nowrap;
        z-index: 2;
        color: #344054;
    
        
    }
    
    .funnel-detail-software-component-funnel-card-badge-number {
        position: relative;
        line-height: 20px;
        font-weight: 500;
        width: 150px;
        max-width: 200px; /* Adjust this value as needed */
        overflow: hidden; /* Hide overflowing content */
        text-overflow: ellipsis; /* Add ellipsis when text overflows */
        white-space: nowrap; /* Prevent text from wrapping */
    }
    
    .funnel-detail-software-component-funnel-frame-parent24 {
        width: 163px;
        display: flex;
        flex-direction: column;
       
        justify-content: flex-start;
        gap: 56px;
        text-align: left;
        font-size: 16px;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        align-items: flex-start;
    }
    
    .funnel-detail-software-component-funnel-location-pin-parent {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 7px;
    }
    
    .funnel-detail-software-component-funnel-search-input {
        display: flex;
        justify-content: flex-start;
    
        flex-direction: column;
        align-items: flex-start;
        padding: 2px 0 0;
    }
    
    .funnel-detail-software-component-funnel-marker-pin-02-icon2 {
        width: 20px;
        height: 20px;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
        z-index: 2;
    }
    
    .funnel-detail-software-component-funnel-location3 {
        position: relative;
        line-height: 24px;
        font-weight: 500;
        display: inline-block;
        min-width: 124px;
        z-index: 2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 163px;
    }
    
    .funnel-detail-software-component-funnel-frame-wrapper18 {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    
    
        align-self: stretch;
        justify-content: flex-end;
        padding: 0 2px 0 0;
        text-align: center;
        font-size: 14px;
        color: #344054;
    }
    
    .funnel-detail-software-component-funnel-badge-elements-copy {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    
        flex: 1;
        justify-content: flex-start;
        gap: 8px;
    }
    
    .funnel-detail-software-component-funnel-badge36 {
        justify-content: flex-start;
        z-index: 2;
    
        /* flex: 1; */
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 6px;
        background-color: #fff;
        border: 1px solid #d0d5dd;
        padding: 0 7px;
        white-space: nowrap;
    
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
    
    .funnel-detail-software-component-funnel-badge-number-element {
        width: 61px;
        position: relative;
        line-height: 20px;
        font-weight: 500;
        display: inline-block;
        min-width: 61px;
    }
    
    .funnel-detail-software-component-funnel-badge37 {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
    
        /* flex: 1; */
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 6px;
        background-color: #fff;
        border: 1px solid #d0d5dd;
     
        padding: 0 7px;
        z-index: 2;
        white-space: nowrap;
      
        text-align: center;
        font-size: 14px;
        color: #344054;
    }
    
    .funnel-detail-software-component-funnel-second-badge-count {
        position: relative;
        line-height: 20px;
        font-weight: 500;
        display: inline-block;
        min-width: 60px;
    }
    
    .funnel-detail-software-component-funnel-frame-parent26 {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    
        flex: 1;
        flex-direction: column;
        gap: 56px;
        min-width: 107px;
        text-align: left;
        font-size: 16px;
    }
    
    .funnel-detail-software-component-funnel-frame-wrapper19 {
        flex-direction: row;
        padding: 0 14px;
    
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
    
    .funnel-detail-software-component-funnel-legend-series {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;
    }
    
    .funnel-detail-software-component-funnel-type-of-work2 {
        position: relative;
        line-height: 24px;
        font-weight: 500;
        display: inline-block;
        min-width: 124px;
        z-index: 2;
    
        min-width: 109px;
    }
    
    .funnel-detail-software-component-funnel-badge38 {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 6px;
        background-color: #fff;
        border: 1px solid #d0d5dd;
        flex-direction: row;
        padding: 0 7px;
        white-space: nowrap;
        z-index: 2;
        text-align: center;
        font-size: 14px;
        color: #344054;
    }
    
    .funnel-detail-software-component-funnel-third-badge-number {
        width: max-content;
        position: relative;
        line-height: 20px;
        font-weight: 500;
        font-size: 14px;
        display: inline-block;
        max-width: 200px; /* Adjust this value as needed */
        overflow: hidden; /* Hide overflowing content */
        text-overflow: ellipsis; /* Add ellipsis when text overflows */
        white-space: nowrap; /* Prevent text from wrapping */
    }
    
    .funnel-detail-software-component-funnel-badge39 {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    
        width: 93px;
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 6px;
        background-color: #fff;
        border: 1px solid #d0d5dd;
        box-sizing: border-box;
        align-items: flex-start;
        padding: 0 7px;
        z-index: 2;
        color: #344054;
    }
    
    .funnel-detail-software-component-funnel-location-badge-elements {
        position: relative;
        line-height: 20px;
        font-weight: 500;
        display: inline-block;
        min-width: 77px;
    }
    
    .funnel-detail-software-component-funnel-filter-elements-inner {
        align-self: stretch;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-end;
        /* padding: 0 10px 0 0; */
        margin-right: 15px;
        z-index: 0;
    }
    
    .funnel-detail-software-component-funnel-filter-elements {
        width: 153px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        /* gap: 26px; */
        text-align: center;
        font-size: 20px;
        color: #6941c6;
    }
    
    .funnel-detail-software-component-funnel-dropdown10 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        z-index: 2;
        margin-right: -20px;
    }
    
    .funnel-detail-software-component-funnel-dots-vertical-icon {
        width: 20px;
        height: 20px;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
    }
    
    .funnel-detail-software-component-funnel-price-parent {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        /* flex: 1; */
        flex-direction: column;
        margin-right: 20px;
    }
    
    .funnel-detail-software-component-funnel-price2 {
        position: relative;
    
        align-self: stretch;
        letter-spacing: -0.02em;
        line-height: 32px;
        font-weight: 600;
        white-space: nowrap;
        z-index: 2;
    }
    
    .funnel-detail-software-component-funnel-dropdown-elements {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    
        align-self: stretch;
        flex-direction: row;
        padding: 0 30px;
        font-size: 12px;
        color: #475467;
    }
    
    .funnel-detail-software-component-funnel-dropdown-options-parent {
        flex: 1;
        flex-direction: column;
        gap: 4px;
    
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
    
    .funnel-detail-software-component-funnel-text83 {
        position: relative;
    
        line-height: 20px;
        display: inline-block;
        min-width: 83px;
        z-index: 3;
    }
    
    .funnel-detail-software-component-funnel-frame-wrapper3 {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
    
        align-self: stretch;
        padding: 0 4px 0 3px;
    }
    
    .funnel-detail-software-component-funnel-radar-1-1-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    
        flex: 1;
        align-items: flex-start;
        gap: 8px;
        z-index: 2;
    }
    
    
    .funnel-detail-software-component-funnel-magnifier-1-icon {
        height: 20px;
        width: 20px;
        position: relative;
        object-fit: cover;
        min-height: 20px;
    }
    
    .funnel-detail-software-component-funnel-divider-icon8 {
        /* width: 968px; */
        position: relative;
        max-height: 100%;
        /* max-width: 100%; */
        width: 100%;
        z-index: 2;
    }
    
    .funnel-detail-software-component-funnel-metric-item-parent {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        width: max-content;
        /* width: 968px; */
        gap: 25px;
        max-width: 100%;
        font-size: 16px;
        overflow-x: scroll;
        scrollbar-width: none;
    }
    
    .funnel-detail-software-component-funnel-metric-item3 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    
        flex: 1;
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 12px;
        background-color: #fff;
        border: 1px solid #eaecf0;
        box-sizing: border-box;
        padding: 16px 15px 20px;
        position: relative;
        gap: 20px;
        /* min-width: 229px; */
        width: max-content;
        z-index: 2;
    }
    
    .funnel-detail-software-component-funnel-heading9 {
        width: 254px;
        position: relative;
        line-height: 24px;
        font-weight: 600;
        display: inline-block;
    }
    
    .funnel-detail-software-component-funnel-number-and-chart {
        align-self: stretch;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        gap: 16px;
        font-size: 30px;
    }
    
    .funnel-detail-software-component-funnel-number-and-badge3 {
        display: flex;
        justify-content: flex-start;
    
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        min-width: 105px;
    }
    
    .funnel-detail-software-component-funnel-heading {
        line-height: 38px;
        font-weight: 600;
    }
    
    .funnel-detail-software-component-funnel-change-and-text3 {
        display: flex;
        justify-content: flex-start;
    
        align-self: stretch;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        text-align: center;
        font-size: 14px;
        color: #079455;
    }
    
    .funnel-detail-software-component-funnel-change2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1px;
    }
    
    .funnel-detail-software-component-funnel-upload-04-icon {
        height: 20px;
        width: 20px;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
        min-height: 20px;
    }
    
    .funnel-detail-software-component-funnel-change7 {
        position: relative;
        line-height: 20px;
        font-weight: 500;
    
        display: inline-block;
        min-width: 38px;
    }
    
    .funnel-detail-software-component-funnel-text139 {
        position: relative;
        line-height: 20px;
        font-weight: 500;
    
        flex: 1;
        color: #475467;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
    .funnel-detail-software-component-funnel-chart-mini-icon3 {
        height: 48px;
        width: 96px;
        position: relative;
        object-fit: contain;
    }
    
    .funnel-detail-software-component-funnel-dropdown19 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    
        margin: 0 !important;
        position: absolute;
        top: 16px;
        right: 16px;
    }
    
    .funnel-detail-software-component-funnel-frame-parent71 {
        display: flex;
        align-items: flex-start;
        /* max-width: 100%;
        width: 968px; */
        width: 95%;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
        font-size: 14px;
        color: #344054;
    }
    
    .funnel-detail-software-component-funnel-buttonsbutton-parent1 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    
        width: 710px;
        flex-direction: row;
        gap: 24px;
        max-width: calc(100% - 44px);
    }
    
    .funnel-detail-software-component-funnel-buttonsbutton13 {
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid #d0d5dd;
        box-sizing: border-box;
        z-index: 2;
        cursor: pointer;
        width: 90px;
        overflow: hidden;
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 6px 12px;
        gap: 6px;
    }
    
    
    .funnel-detail-software-component-funnel-arrow-narrow-left-icon {
        height: 24px;
        width: 24px;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
        min-height: 24px;
    }
    
    .funnel-detail-software-component-funnel-text67 {
        position: relative;
        line-height: 20px;
        font-weight: 600;
        display: inline-block;
        min-width: 34px;
    }
    
    .funnel-detail-software-component-funnel-buttonsbutton25,
    .funnel-detail-software-component-funnel-buttonsbutton26 {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
    }
    
    .funnel-detail-software-component-funnel-buttonsbutton25,
    .funnel-detail-software-component-funnel-buttonsbutton26 {
        flex: 1;
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid #d0d5dd;
        box-sizing: border-box;
        cursor: pointer;
        padding: 6px 11px;
        gap: 5.0px;
        min-width: 92px;
        z-index: 2;
    }
    
    .funnel-detail-software-component-funnel-buttonsbutton26 {
        padding: 6px 10px 6px 12px;
        gap: 5.0px;
    }
    
    .funnel-detail-software-component-funnel-icon-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 3.0px 0 0;
    }
    
    .funnel-detail-software-component-funnel-icon1 {
        width: 16.0px;
        height: 16.0px;
        position: relative;
    }
    
    .funnel-detail-software-component-funnel-text68 {
        position: relative;
        line-height: 20px;
        font-weight: 600;
        display: inline-block;
        min-width: 56px;
    }
    
    .funnel-detail-software-component-funnel-buttonsbutton27 {
        flex: 1;
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid #d0d5dd;
        box-sizing: border-box;
        flex-direction: row;
        padding: 8px 12px;
        position: relative;
        gap: 6px;
        min-width: 79px;
        z-index: 2;
        cursor: pointer;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
    
    .funnel-detail-software-component-funnel-text145 {
        position: relative;
        line-height: 20px;
        font-weight: 600;
        display: inline-block;
        min-width: 70px;
    }
    
    .funnel-detail-software-component-funnel-ellipse-parent {
        height: 24px;
        width: 17px;
        position: absolute;
        margin: 0 !important;
        top: -9px;
        right: -2.0px;
        font-size: 10px;
        color: #fff;
    }
    
    .funnel-detail-software-component-funnel-ellipse-div {
        position: absolute;
        top: 0;
        left: 3px;
        border-radius: 50%;
        background-color: #17b26a;
        width: 17px;
        height: 17px;
        z-index: 1;
    }
    
    .funnel-detail-software-component-funnel-div {
        color: white;
        z-index: 2;
        position: absolute;
        top: 3px;
        left: 8px;
    
    }
    
    .funnel-detail-software-component-funnel-arrow-square-up-right-wrapper1 {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    
        flex-direction: column;
        padding: 8px 0 0;
    }
    
    .funnel-detail-software-component-funnel-arrow-square-up-right-icon1 {
        width: 24px;
        height: 24px;
        position: relative;
        overflow: hidden;
        flex-shrink: 0;
        z-index: 2;
    }
    
    .funnel-detail-software-component-funnel-small-card{
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 10px;
        padding-top: 20px;
        overflow-x: scroll;
        scrollbar-width: none;
    }


  @media screen and (max-width: 1275px) {
  
    .funnel-detail-software-all,
    .funnel-detail-software-all-content {
      padding-bottom: 20px;
      box-sizing: border-box;
    }
  
    .funnel-detail-software-all {
      padding-bottom: 67px;
    }
  
    .funnel-detail-software-container3 {
      max-width: 100%;
    }
  }
  
  @media screen and (max-width: 1100px) {

    
    .funnel-detail-software-content13 {
      gap: 35px;
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-main {
      height: auto;
      padding-top: 21px;
      padding-bottom: 21px;
      box-sizing: border-box;
    }
  
    .funnel-detail-software-content-elements-parent,
    .funnel-detail-software-content17,
    .funnel-detail-software-frame-group,
    .funnel-detail-software-rectangle-parent10,
    .funnel-detail-software-rectangle-parent5,
    .funnel-detail-software-rectangle-parent7 {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-rectangle-parent11 {
      gap: 31px;
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-frame-wrapper31 {
      flex: 1;
    }
  
    .funnel-detail-software-rectangle-parent13 {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-list-item-parent {
      flex: 1;
    }
  
    .funnel-detail-software-card-list,
    .funnel-detail-software-content20,
    .funnel-detail-software-content21,
    .funnel-detail-software-metric-item-parent {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-text-and-badge1 {
      flex-wrap: wrap;
      padding-right: 364px;
      box-sizing: border-box;
    }
  
    .funnel-detail-software-content23,
    .funnel-detail-software-frame-parent73,
    .funnel-detail-software-frame-parent74 {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-all {
      padding-bottom: 44px;
      box-sizing: border-box;
    }
  
    .funnel-detail-software-empty-state-container {
      padding-bottom: 81px;
      box-sizing: border-box;
    }

    /* Card Css  */
    .funnel-detail-software-component-funnel-rectangle-parent5,
    .funnel-detail-software-component-funnel-metric-item-parent {
        flex-wrap: wrap;
    }
  }
  
  @media screen and (max-width: 750px) {
  
  
    .funnel-detail-software-heading {
      font-size: 24px;
      line-height: 30px;
    }
  
    .funnel-detail-software-content13 {
      gap: 17px;
    }
  
    .funnel-detail-software-tabs-and-filters {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-jul {
      width: 100%;
      height: 17px;
    }
  
    .funnel-detail-software-x-axis {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-number {
      font-size: 24px;
      line-height: 30px;
    }
  
    .funnel-detail-software-main {
      gap: 16px;
    }
  
    .funnel-detail-software-number3,
    .funnel-detail-software-number4 {
      font-size: 29px;
      line-height: 35px;
    }
  
    .funnel-detail-software-dropdowns {
      padding-right: 83px;
      box-sizing: border-box;
      min-width: 100%;
      flex: 1;
    }
  
    .funnel-detail-software-badge-elements,
    .funnel-detail-software-card-elements,
    .funnel-detail-software-dropdowns,
    .funnel-detail-software-location-badge-number {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-action-fields {
      min-width: 100%;
    }
  
    .funnel-detail-software-content-actions {
      flex-wrap: wrap;
      min-width: 100%;
    }
  
    .funnel-detail-software-frame-parent2,
    .funnel-detail-software-primary-table {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-frame-parent1 {
      flex-wrap: wrap;
      min-width: 100%;
    }
  
    .funnel-detail-software-frame-parent17,
    .funnel-detail-software-frame-parent18,
    .funnel-detail-software-frame-parent21 {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-element-details,
    .funnel-detail-software-f4b711e503bc72fe396e5043e0c2-1-icon {
      flex: 1;
    }
  
    .funnel-detail-software-action-icons,
    .funnel-detail-software-element-details-parent {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-icon-button {
      gap: 17px;
    }
  
    .funnel-detail-software-icon-button,
    .funnel-detail-software-sidebar1 {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-frame-parent33 {
      flex-wrap: wrap;
      min-width: 100%;
    }
  
    .funnel-detail-software-rectangle-parent11 {
      gap: 16px;
    }
  
    .funnel-detail-software-frame-parent41 {
      gap: 17px;
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-frame-parent39 {
      min-width: 100%;
    }
  
    .funnel-detail-software-frame-wrapper31 {
      margin-left: 0;
    }
  
    .funnel-detail-software-card-row {
      min-width: 100%;
    }
  
    .funnel-detail-software-list-item-parent {
      margin-left: 0;
    }
  
    .funnel-detail-software-buttonsbutton-parent1 {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-text-and-badge {
      flex-wrap: wrap;
      padding-right: 121px;
      box-sizing: border-box;
    }
  
    .funnel-detail-software-text-and-supporting-text2 {
      min-width: 100%;
    }
  
    .funnel-detail-software-actions4 {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-text-and-badge1 {
      padding-right: 182px;
      box-sizing: border-box;
    }
  
    .funnel-detail-software-text-and-supporting-text3 {
      min-width: 100%;
    }
  
    .funnel-detail-software-actions5,
    .funnel-detail-software-table-cell51,
    .funnel-detail-software-table-cell52 {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-text-and-badge4 {
      flex-wrap: wrap;
      padding-right: 166px;
      box-sizing: border-box;
    }
  
    .funnel-detail-software-frame-parent,
    .funnel-detail-software-text-and-supporting-text16 {
      min-width: 100%;
    }
  
    .funnel-detail-software-empty-state3 {
      gap: 16px;
    }
  
    .funnel-detail-software-empty-state2 {
      padding-top: 27px;
      padding-bottom: 27px;
      box-sizing: border-box;
    }
  
    .funnel-detail-software-container3 {
      gap: 16px;
    }

    /* Card css  */
      .funnel-detail-software-component-funnel-frame-parent1 {
        flex-wrap: wrap;
        min-width: 100%;
    }

    .funnel-detail-software-component-funnel-action-fields {
        min-width: 100%;
    }

    .funnel-detail-software-component-funnel-frame-parent21 {
        flex-wrap: wrap;
    }

    .funnel-detail-software-component-funnel-heading {
        font-size: 24px;
        line-height: 30px;
    }

    .funnel-detail-software-component-funnel-buttonsbutton-parent1 {
        flex-wrap: wrap;
    }


  
  }
  
  @media screen and (max-width: 450px) {
  
    .funnel-detail-software-account {
      gap: 23px;
      padding-right: 20px;
      box-sizing: border-box;
    }
  
    .funnel-detail-software-heading {
      font-size: 18px;
      line-height: 23px;
    }
  
    .funnel-detail-software-actions {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-number {
      font-size: 18px;
      line-height: 23px;
    }
  
    .funnel-detail-software-number-and-chart {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-main {
      padding-top: 20px;
      padding-bottom: 20px;
      box-sizing: border-box;
    }
  
    .funnel-detail-software-text46 {
      font-size: 21px;
      line-height: 30px;
    }
  
    .funnel-detail-software-number3,
    .funnel-detail-software-number4 {
      font-size: 22px;
      line-height: 26px;
    }
  
    .funnel-detail-software-dropdowns {
      padding-right: 20px;
      box-sizing: border-box;
    }
  
    .funnel-detail-software-price {
      font-size: 16px;
      line-height: 26px;
    }
  
    .funnel-detail-software-buttonsbutton-parent {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-date-elements-parent {
      flex: 1;
    }
  
    .funnel-detail-software-badge-container,
    .funnel-detail-software-frame-parent12 {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-badge-group {
      height: auto;
      min-height: 104;
    }
  
    .funnel-detail-software-price2 {
      font-size: 16px;
      line-height: 26px;
    }
  
    .funnel-detail-software-work-type-icon {
      flex: 1;
    }
  
    .funnel-detail-software-calendar-icon6 {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-work-type-label {
      margin-left: 0;
    }
  
    .funnel-detail-software-price4 {
      font-size: 16px;
      line-height: 26px;
    }
  
    .funnel-detail-software-frame-parent36,
    .funnel-detail-software-frame-parent42 {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-price5,
    .funnel-detail-software-price6,
    .funnel-detail-software-price7 {
      font-size: 16px;
      line-height: 26px;
    }
  
    .funnel-detail-software-frame-parent64 {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-text147 {
      font-size: 21px;
      line-height: 22px;
    }
  
    .funnel-detail-software-content22,
    .funnel-detail-software-text-and-badge {
      padding-right: 20px;
      box-sizing: border-box;
    }
  
    .funnel-detail-software-input-dropdown3 {
      max-width: 100%;
    }
  
    .funnel-detail-software-table-cell50,
    .funnel-detail-software-table-cell53 {
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-table-header-cell-group {
      height: auto;
      min-height: 764;
    }
  
    .funnel-detail-software-column5,
    .funnel-detail-software-column6 {
      flex: 1;
    }
  
    .funnel-detail-software-column-parent {
      gap: 39px;
      flex-wrap: wrap;
    }
  
    .funnel-detail-software-text-and-badge4 {
      padding-right: 20px;
      box-sizing: border-box;
    }
  
    .funnel-detail-software-all {
      padding-bottom: 29px;
      box-sizing: border-box;
    }
  
    .funnel-detail-software-title8 {
      font-size: 16px;
      line-height: 24px;
    }
  
    .funnel-detail-software-empty-state-container {
      padding-bottom: 53px;
      box-sizing: border-box;
    }

    /* Card css  */
    .funnel-detail-software-component-funnel-price2 {
      font-size: 16px;
      line-height: 26px;
  }

  .funnel-detail-software-component-funnel-number-and-chart {
      flex-wrap: wrap;
  }

  .funnel-detail-software-component-funnel-heading {
      font-size: 18px;
      line-height: 23px;
  }
  
  }