.account-update-profile-gerator-04-1-icon1 {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}

.update-profile {
  text-align: center;
  width: 100%;
  /* padding-bottom: 10px; */
}

.account-update-profile-custom-margin {
  margin-top: 10px;
}

.account-update-profile-file-upload-custom {
  flex-direction: column !important;
}



.account-update-profile-logo1 {
  width: 121px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-image: url("../../../icons/Gerator/account_setup/gerator04-1@2x.png");
  /* background-image: url(../../../public/account_setup_profile_subscription/gerator04-1@2x.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.account-update-profile-featured-icon8 {
  height: 48px;
  width: 48px;
  position: relative;
  border-radius: 10px;
}

.account-update-profile-step-labels,
.account-update-profile-supporting-text9 {
  position: relative;
  line-height: 24px;
}

.account-update-profile-step-labels {
  align-self: stretch;
  font-weight: 600;
}

.account-update-profile-supporting-text9 {
  color: #475467;
}

.account-update-profile-step-elements,
.account-update-profile-step-labels-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.account-update-profile-step-labels-parent {
  flex: 1;
  flex-direction: column;
  gap: 2px;
  min-width: 182px;
}

.account-update-profile-step-elements {
  align-self: stretch;
  flex-direction: row;
  gap: 16px;
}

.account-update-profile-connector4 {
  height: 26px;
  width: 2px;
  position: relative;
  border-radius: 2px;
  background-color: #eaecf0;
}

.account-update-profile-progress-connectors,
.account-update-profile-step-base4,
.account-update-profile-step-base5 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 23px;
}

.account-update-profile-step-base4,
.account-update-profile-step-base5 {
  align-self: stretch;
  flex-direction: column;
  padding: 0 0 4px;
  gap: 2px;
}

.account-update-profile-step-base5 {
  opacity: 0.6;
}

.account-update-profile-featured-icon10 {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 10px;
}

.account-update-profile-connector-wrap2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 4px;
  gap: 4px;
}

.account-update-profile-supporting-text11 {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  color: #475467;
}

.account-update-profile-step-base6,
.account-update-profile-text-and-supporting-text2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.account-update-profile-text-and-supporting-text2 {
  flex: 1;
  flex-direction: column;
  padding: 0 0 32px;
  box-sizing: border-box;
  gap: 2px;
  min-width: 182px;
}

.account-update-profile-step-base6 {
  align-self: stretch;
  flex-direction: row;
  gap: 16px;
  opacity: 0.6;
}

.account-update-profile-connector7 {
  width: 2px;
  height: 26px;
  position: relative;
  border-radius: 2px;
  background-color: #eaecf0;
  display: none;
}

.account-update-profile-connector-wrap3,
.account-update-profile-progress-steps-progress-icon1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.account-update-profile-connector-wrap3 {
  height: 82px;
  align-items: center;
  padding: 0 0 4px;
  box-sizing: border-box;
  gap: 4px;
}

.account-update-profile-progress-steps-progress-icon1 {
  align-self: stretch;
  align-items: flex-start;
}

.account-update-profile-progress-container,
.account-update-profile-progress-container-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.account-update-profile-progress-container {
  flex: 1;
  flex-direction: column;
  gap: 79.5px;
}

.account-update-profile-progress-container-wrapper {
  width: 380px;
  flex-direction: row;
  padding: 0 18px;
  box-sizing: border-box;
}

.account-update-profile-text40 {
  height: 20px;
  width: 125px;
  position: relative;
  line-height: 20px;
  display: inline-block;
}

.account-update-profile-mail-01-icon2 {
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.account-update-profile-helpuntitleduicom1 {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 20px;
  white-space: nowrap;
}

.account-update-profile-footer4 {
  height: 20px;
  width: 188px;
  flex-direction: row;
  justify-content: flex-start;
  gap: 7px;
}

.account-update-profile-footer4,
.account-update-profile-section2,
.account-update-profile-text-container {
  display: flex;
  align-items: flex-start;
}

.account-update-profile-text-container {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  font-size: 14px;
}

.account-update-profile-section2 {
  flex: 1;
  background-color: #f9fafb;
  /* background-color: red; */
  flex-direction: column;
  justify-content: flex-start;
  padding: 32.5px 13px 32px 14px;
  box-sizing: border-box;
  gap: 1108px;
  min-width: 286px;
  max-width: 440px;
}



.account-update-profile-home-line-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.account-update-profile-breadcrumb-button-base {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px;
}

.account-update-profile-chevron-right-icon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.account-update-profile-text41 {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.account-update-profile-breadcrumb-button-base1 {
  align-self: stretch;
  flex: 1;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.account-update-profile-text42 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.account-update-profile-breadcrumb-button-base2 {
  align-self: stretch;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 7px;
}

.account-update-profile-text43 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  white-space: nowrap;
}

.account-update-profile-breadcrumb-button-base3 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.account-update-profile-chevron-right-icon3 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.account-update-profile-text44 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.account-update-profile-breadcrumb-button-base4 {
  border-radius: 6px;
  background-color: #f9fafb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  color: #344054;
}

.account-update-profile-breadcrumbs,
.account-update-profile-tabs {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.account-update-profile-tabs {
  height: 28px;
  flex: 1;
  display: flex;
  gap: 8px;
}

.account-update-profile-breadcrumbs {
  width: 278px;
  display: none;
}

.account-update-profile-page-title {
  text-decoration: none;
  align-self: stretch;
  position: relative;
  line-height: 38px;
  font-weight: 600;
  color: inherit;
}

.account-update-profile-supporting-text13 {
  width: 445px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  display: none;
  max-width: 100%;
}

.account-update-profile-text-and-supporting-text4 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 320px;
  max-width: 100%;
  font-size: 30px;
}

.account-update-profile-placeholder-icon16 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.account-update-profile-text45 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.account-update-profile-text-padding8 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.account-update-profile-button,
.account-update-profile-button1,
.account-update-profile-button2 {
  height: 40px;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  gap: 4px;
}

.account-update-profile-button {
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
}

.account-update-profile-button1,
.account-update-profile-button2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: #fff;
  padding: 10px 13px;
}

.account-update-profile-button1 {
  border: 1px solid #d6bbfb;
  flex-direction: row;
  align-items: center;
  color: #6941c6;
}

.account-update-profile-button2 {
  border: 1px solid #d0d5dd;
  color: #344054;
}

.account-update-profile-actions4,
.account-update-profile-button2,
.account-update-profile-button3 {
  flex-direction: row;
  align-items: center;
}

.account-update-profile-button3 {
  height: 40px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
  color: #fff;
}

.account-update-profile-actions4 {
  display: none;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
  color: #475467;
}

.account-update-profile-label,
.account-update-profile-text49 {
  position: relative;
}

.account-update-profile-label {
  width: 47px;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.account-update-profile-text49 {
  align-self: stretch;
  flex: 1;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.account-update-profile-content8 {
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.account-update-profile-content8,
.account-update-profile-input,
.account-update-profile-input-with-label {
  align-self: stretch;
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.account-update-profile-input {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 10px 13px;
  font-size: 16px;
  color: #667085;
}

.account-update-profile-input-with-label {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.account-update-profile-hint-text {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.account-update-profile-content7,
.account-update-profile-input-dropdown {
  align-items: flex-start;
  justify-content: flex-start;
}

.account-update-profile-input-dropdown {
  height: 44px;
  width: 320px;
  display: none;
  flex-direction: column;
  gap: 8px;
  min-width: 200px;
  max-width: 320px;
  color: #344054;
}

.account-update-profile-content7 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px 16px;
  color: #101828;
}

.account-update-profile-content7,
.account-update-profile-divider-icon,
.account-update-profile-page-header {
  align-self: stretch;
  max-width: 100%;
}

.account-update-profile-divider-icon {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  display: none;
}

.account-update-profile-page-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.account-update-profile-container3 {
  padding: 0 32px;
  box-sizing: border-box;
}

.account-update-profile-container3,
.account-update-profile-header-section {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.account-update-profile-text50 {
  flex: 1;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
}

.account-update-profile-section-label {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 200px;
  max-width: 280px;
}

.account-update-profile-label1 {
  width: 36px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: none;
}

.account-update-profile-content10 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Inter;
  font-size: 16px;
  color: #101828;
  min-width: 130px;
}

.account-update-profile-help-icon {
  height: 16px;
  width: 16px;
  position: relative;
  display: none;
}

.account-update-profile-input-with-label1,
.account-update-profile-input1 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.account-update-profile-input1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
}

.account-update-profile-input-with-label1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.account-update-profile-hint-text1 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: none;
}

.account-update-profile-input-field {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  min-width: 159px;
}

.account-update-profile-label2 {
  width: 96px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: none;
}

.account-update-profile-content11 {
  width: calc(100% - 46px);
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  color: #101828;
  min-width: 113px;
}

.account-update-profile-content11,
.account-update-profile-content9,
.account-update-profile-input-fields,
.account-update-profile-input2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.account-update-profile-input2 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
}

.account-update-profile-content9,
.account-update-profile-input-fields {
  align-items: flex-start;
}

.account-update-profile-input-fields {
  flex: 1;
  gap: 24px;
  min-width: 480px;
  max-width: 512px;
}

.account-update-profile-content9 {
  align-self: stretch;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16px 32px;
  max-width: 100%;
}

.account-update-profile-divider,
.account-update-profile-text51 {
  align-self: stretch;
  position: relative;
}

.account-update-profile-divider {
  height: 1px;
  background-color: #eaecf0;
}

.account-update-profile-text51 {
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
}

.account-update-profile-section-label1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 200px;
  max-width: 280px;
}

.account-update-profile-text52 {
  width: calc(100% - 40px);
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  flex: 1;
  position: relative;
  line-height: 24px;
  color: #667085 !important;
  text-align: left;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 250px;
  max-width: calc(100% - 28px);
  padding: 0;
}

.account-update-profile-content13,
.account-update-profile-input-with-label3,
.account-update-profile-input3 {
  display: flex;
  justify-content: flex-start;
}

.account-update-profile-content13 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  max-width: calc(100% - 28px);
}

.account-update-profile-input-with-label3,
.account-update-profile-input3 {
  align-self: stretch;
  max-width: 100%;
}

.account-update-profile-input3 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
}

.account-update-profile-input-with-label3 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.account-update-profile-hint-text2 {
  width: 320px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  /* display: none; */
}

.account-update-profile-content12,
.account-update-profile-input-field1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.account-update-profile-input-field1 {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  min-width: 480px;
  max-width: 512px;
}

.account-update-profile-content12 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 102px 0 0;
  box-sizing: border-box;
  gap: 16px 32px;
  max-width: 100%;
}

.account-update-profile-text53,
.account-update-profile-text55 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
}

.account-update-profile-text53 {
  align-self: stretch;
}

.account-update-profile-text55 {
  display: inline-block;
  min-width: 75px;
}

.account-update-profile-help-icon3 {
  width: 16px;
  height: 16px;
  position: relative;
}

.account-update-profile-heading-and-help-icon,
.account-update-profile-help-icon-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.account-update-profile-help-icon-wrapper {
  flex-direction: column;
  padding: 2px 0 0;
}

.account-update-profile-heading-and-help-icon {
  flex-direction: row;
  gap: 2px;
}

.account-update-profile-supporting-text14 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
}

.account-update-profile-avatar1,
.account-update-profile-contrast-border {
  height: 64px;
  width: 64px;
  border-radius: 9999px;
}

.account-update-profile-contrast-border {
  position: relative;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
}

.account-update-profile-avatar1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-image: url(../../../icons/Gerator/dashboard/user-placeholder2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.account-update-profile-featured-icon12 {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 8px;
}

.account-update-profile-text56 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #6941c6;
  text-align: left;
  display: inline-block;
  min-width: 101px;
}

.account-update-profile-buttonsbutton8 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.account-update-profile-text57 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 112px;
}

.account-update-profile-action {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px;
  gap: 4px;
}

.account-update-profile-supporting-text15 {
  align-self: stretch;
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-family: Inter;
  color: #475467;
  text-align: center;
}

.account-update-profile-text-and-supporting-text5 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.account-update-profile-page-icon {
  position: absolute;
  height: 100%;
  width: 80%;
  top: 0;
  right: 2.5%;
  bottom: 0;
  left: 17.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.account-update-profile-file-type {
  position: relative;
  font-size: 10px;
  display: inline-block;
  font-family: Inter;
  color: #fff;
  text-align: center;
  min-width: 20px;
}

.account-update-profile-file-type-wrap {
  position: absolute;
  height: 40%;
  width: 65%;
  top: 45%;
  right: 32.5%;
  bottom: 15%;
  left: 2.5%;
  border-radius: 2px;
  background-color: #7e56d9;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 3px;
  box-sizing: border-box;
  z-index: 1;
}

.account-update-profile-file-type-icon {
  position: absolute;
  top: 0;
  right: 0;
  filter: drop-shadow(0 24px 48px rgba(16, 24, 40, 0.18));
  width: 100%;
  height: 100%;
}

.account-update-profile-cursor-icon {
  position: absolute;
  top: 28px;
  left: 28px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  object-fit: cover;
  z-index: 2;
}

.account-update-profile-file {
  width: 40px;
  height: 40px;
  position: absolute;
  margin: 0 !important;
  right: 0;
  bottom: 4px;
  z-index: 1;
}

.account-update-profile-content17 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: 12px;
  max-width: 100%;
  
}

.account-update-profile-file-upload,
.account-update-profile-file-upload-base {
  align-items: flex-start;
  max-width: 100%;
}



.account-update-profile-file-upload-base {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 2px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 13px 22px;
}

.account-update-profile-file-upload-base:hover{
  cursor: pointer;
  border: 2px solid #7e56d9;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.account-update-profile-file-upload {
  min-width: 278px;
}

.account-update-profile-avatar-and-file-upload,
.account-update-profile-content19,
.account-update-profile-file-upload {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.account-update-profile-avatar-and-file-upload {
  align-items: flex-start;
  gap: 20px;
  min-width: 480px;
  max-width: 512px;
}

.account-update-profile-content19 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  color: #667085 !important;
  min-width: 250px;
  max-width: 100%;
}

.account-update-profile-supporting-text16,
.account-update-profile-text61 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: Inter;
  text-align: left;
}

.account-update-profile-text61 {
  font-weight: 500;
  color: #101828;
  display: inline-block;
  min-width: 68px;
}

.account-update-profile-supporting-text16 {
  align-self: stretch;
  width: 54px;
  color: #475467;
  display: none;
}

.account-update-profile-content23 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 360px 0 0;
  box-sizing: border-box;
  max-width: calc(100% - 28px);
}

.account-update-profile-content-custom-23 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  /* padding: 0; */
  box-sizing: border-box;
  border: none;
  background-color: #fff;
  outline: none;
  color: #667085 !important;
}

.account-update-profile-content23,
.account-update-profile-input-dropdown1,
.account-update-profile-input7 {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.account-update-profile-input7 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  max-width: 100%;
}

.account-update-profile-input-dropdown1 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  min-width: 480px;
  max-width: 512px;
}

.account-update-profile-text63 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 126px;
}

.account-update-profile-content25 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  max-width: 100%;
}

.account-update-profile-input8 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
  padding: 8px 13px;
}

.account-update-profile-content-container,
.account-update-profile-content27,
.account-update-profile-content32,
.account-update-profile-input8 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100%;
}

.account-update-profile-content27 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  align-items: center;
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  color: #667085 !important;
  min-width: 250px;
}

.account-update-profile-content-container,
.account-update-profile-content32 {
  align-items: flex-start;
  box-sizing: border-box;
}

.account-update-profile-content32 {
  flex: 1;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 102px 0 0;
  gap: 16px 32px;
}

.account-update-profile-content-container {
  align-self: stretch;
  padding: 0 0 4px;
}

.account-update-profile-divider-icon1,
.account-update-profile-text68 {
  align-self: stretch;
  position: relative;
}

.account-update-profile-divider-icon1 {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.account-update-profile-text68 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #475467;
  text-align: left;
  white-space: nowrap;
}

.account-update-profile-buttonsbutton9 {
  height: 20px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.account-update-profile-button4,
.account-update-profile-text69 {
  align-self: stretch;
}

.account-update-profile-text69 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #475467;
  text-align: left;
}

.account-update-profile-button4 {
  border-radius: 8px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 14px;
  gap: 4px;
}

.account-update-profile-text70 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 48px;
}

.account-update-profile-button5,
.account-update-profile-text-padding13 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.account-update-profile-button5 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  background-color: #fff;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  gap: 4px;
  min-width: 52px;
}

.account-update-profile-text71 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 34px;
}

.account-update-profile-text71-custom {
  min-width: 50px !important;
}

.account-update-profile-text-padding14 {
  /* width: 37px; */
  justify-content: center;
  padding: 0 1px;
  box-sizing: border-box;
}

.account-update-profile-actions5,
.account-update-profile-button6,
.account-update-profile-content34,
.account-update-profile-section-footer,
.account-update-profile-text-padding14 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.account-update-profile-button6 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  /* width: 65px; */
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  gap: 4px;
}

.account-update-profile-actions5,
.account-update-profile-content34,
.account-update-profile-section-footer {
  justify-content: flex-end;
  max-width: 100%;
}

.account-update-profile-actions5 {
  flex: 1;
  padding: 0 0 0 769px;
  box-sizing: border-box;
  gap: 12px;
}

.account-update-profile-content34,
.account-update-profile-section-footer {
  align-self: stretch;
  gap: 20px;
}

.account-update-profile-section-footer {
  flex-direction: column;
  justify-content: flex-start;
}

.account-update-profile-content-elements,
.account-update-profile-content-elements-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.account-update-profile-content-elements {
  margin: 0;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.account-update-profile-content-elements-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 32px 106px;
  box-sizing: border-box;
}

.account-update-profile-content-elements-wrapper-custom-padding {
  padding: 0 32px 10px;
}

.account-update-profile-helpcentergeratorcom1 {
  position: relative;
  line-height: 20px;
  white-space: nowrap;
}

.account-update-profile-hyperlink {
  color: #475467;
  line-height: 20px;
}


.account-update-profile-icon-and-text1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
}

.account-update-profile-text72 {
  position: relative;
  line-height: 20px;
}

.account-update-profile-help-content {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

.account-update-profile-content-wrapper,
.account-update-profile-content-wrapper-inner,
.account-update-profile-help-content-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.account-update-profile-help-content-wrapper {
  flex: 1;
  flex-direction: column;
}

.account-update-profile-content-wrapper,
.account-update-profile-content-wrapper-inner {
  align-self: stretch;
}

.account-update-profile-content-wrapper-inner {
  flex-direction: row;
  padding: 0 23px;
  box-sizing: border-box;
}

.account-update-profile-content-wrapper {
  flex-direction: column;
  gap: 32px;
}

.account-update-profile-content-wrapper-wrapper,
.account-update-profile-account-update-profile {
  display: flex;
  box-sizing: border-box;
  text-align: left;
  font-family: Inter;
}

.account-update-profile-content-wrapper-wrapper {
  width: 990px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 30px;
  min-width: 990px;
  max-width: 100%;
  font-size: 14px;
  color: #475467;
}

.account-update-profile-account-update-profile {
  width: 100%;
  position: relative;
  background-color: #fff;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0 10px 0 0;
  min-height: 960px;
  line-height: normal;
  letter-spacing: normal;
  row-gap: 20px;
  font-size: 16px;
  color: #344054;
  max-width: 1524px;
  margin: 0 auto;

}


.account-update-profile-mobile-container {
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .account-update-profile-progress-container {
    flex: 1;
  }

  .account-update-profile-section2 {
    padding-top: 21px;
    padding-bottom: 21px;
    box-sizing: border-box;
    min-width: 100%;
    gap: 70px;

  }

  .account-update-profile-content-elements-wrapper {
    padding-bottom: 69px;
    box-sizing: border-box;
  }

  .account-update-profile-content-wrapper-wrapper {
    flex: 1;
  }

  .account-update-profile-account-update-profile {
    flex-wrap: wrap;
    padding-left: 10px;
    padding-top: 10px;
    box-sizing: border-box;
  }


}

@media screen and (max-width: 1050px) {
  .account-update-profile-page-title {
    font-size: 24px;
    line-height: 30px;
  }

  .account-update-profile-content12,
  .account-update-profile-content32 {
    padding-right: 51px;
    box-sizing: border-box;
  }

  .account-update-profile-actions5 {
    flex-wrap: wrap;
    padding-left: 0px;
    box-sizing: border-box;
    max-width: 150px;

  }

  .account-update-profile-content34 {
    justify-content: flex-start
  }

  .account-update-profile-content-wrapper-wrapper {
    min-width: 100%;
  }


}

@media screen and (max-width: 750px) {
  .account-update-profile-section2 {
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
  }

  .account-update-profile-actions4,
  .account-update-profile-input-fields {
    flex-wrap: wrap;
  }

  .account-update-profile-input-field1,
  .account-update-profile-input-fields {
    max-width: 100%;
    min-width: 100%;
  }

  .account-update-profile-avatar-and-file-upload {
    flex-wrap: wrap;
  }

  .account-update-profile-avatar-and-file-upload,
  .account-update-profile-input-dropdown1 {
    max-width: 100%;
    min-width: 100%;
  }

  .account-update-profile-content-elements-wrapper {
    padding-bottom: 45px;
    box-sizing: border-box;
  }

  .account-update-profile-help-content {
    flex-wrap: wrap;
  }

  .account-update-profile-content-wrapper {
    gap: 16px;
  }
}

@media screen and (max-width: 450px) {

  .account-update-profile-mobile-container {
    width: 375px;
    height: 10px;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    display: inline-flex;
    padding-top: 30px;
  }

  .account-update-profile-mobile-PaginationDotGroup {
    justify-content: center;
    align-items: center;
    gap: 16px;
    display: inline-flex;
  }

  .account-update-profile-mobile-PaginationDotIndicator {
    width: 10px;
    height: 10px;
    position: relative;
    background-color: #EAECF0;
    border-radius: 9999px;
  }

  .account-update-profile-mobile-PaginationDotIndicator1 {
    width: 10px;
    height: 10px;
    position: relative;
    background-color: #7F56D9;
    border-radius: 9999px;
  }

  .account-update-profile-progress-steps-progress-icon1 {
    display: none;
  }

  .account-update-profile-step-base6,
  .account-update-profile-step-elements {
    flex-wrap: wrap;
  }

  .account-update-profile-progress-container {
    gap: 40px;
  }

  .account-update-profile-text-container {
    flex-wrap: wrap;
  }

  .account-update-profile-section2 {
    gap: 50px;
    height: fit-content;
    max-width: 90%;
    background-color: white;
    padding-bottom: 25px;
    padding-top: 10px;
    padding-left: 5px;
  }

  .account-update-profile-page-title {
    font-size: 18px;
    line-height: 23px;
  }

  .account-update-profile-content9 {
    gap: 16px;
  }

  .account-update-profile-content12 {
    gap: 16px;
    padding-right: 25px;
    box-sizing: border-box;
  }

  .account-update-profile-action {
    flex-wrap: wrap;
  }

  .account-update-profile-content23,
  .account-update-profile-content32 {
    padding-right: 180px;
    box-sizing: border-box;
  }

  .account-update-profile-content32 {
    gap: 16px;
    padding-right: 25px;
  }

  .account-update-profile-actions5 {
    padding-left: 0px;
    box-sizing: border-box;
  }

  .account-update-profile-file {

    width: 30px;
    height: 30px;
    right: -10px;

  }

  .account-update-profile-cursor-icon {
    left: 18px;
    top: 18px;
  }

  .account-update-profile-content-wrapper-inner {
    padding-top: 50px;
  }

}