.input-disabled{
	pointer-events: none;
	background-color: #E9ECEF;
}

.input-disabled-color{
	color: light-dark(rgb(84, 84, 84), rgb(170, 170, 170)) !important;
}

.error-color{
color: red !important;

}

.down-icon-hide{
	margin-right: 10px !important;
}
