.landing-page-divider-icon {
  position: relative;
  overflow: hidden;

  align-self: stretch;
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
}


.landing-page-listing-image-grid3 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 2px 24px;
    box-sizing: border-box;
    max-width: 100%;
  }

  
.landing-page-divider-icon11 {
    width: 1360px;
    position: relative;
    max-height: 100%;
    max-width: 100%;
    z-index: 1;
  }

  .landing-page-listings-search-results-list {
    max-width: 1524px;
    height: max-content;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    /* padding: 81px 0px 0px 0px; */
    box-sizing: border-box;
    /* gap: 999px; */
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-size: 18px;
    color: #101828;
    font-family: Inter;
    margin: 0 auto;
    padding: 7px 0 0;
  }

  .landing-page-mid-container {
    width: 100%;
    height: max-content;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
    /* padding: 81px 0px 0px 0px; */
    box-sizing: border-box;
    /* gap: 999px; */
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-size: 18px;
    color: #101828;
    font-family: Inter;
    /* min-height: 1624px; */
    /* margin: 0 auto; */
  }

  .landing-page-filters {
    max-width: 100%;
    flex-shrink: 0;

    width: 75%;
    /* display: flex; */
    height: max-content;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* padding: 0 0 1518px 0px; */
    box-sizing: border-box;
  
    /* position: relative; */
    font-size: 14px;
    color: #344054;
  }

  .landing-page-listing-container {
  display: flex;
  align-items: flex-start;
  max-width: 1524px;

  width: max-content;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 29px;
  box-sizing: border-box;
  flex-shrink: 0;

 
  gap: 20px;
  
  padding: 10px;
  /* display: grid; */
  overflow-y: auto;
}

.landing-page-divider-icon6 {
  max-width: 100%;
  flex-shrink: 0;

  height: 1px;
  top: 70px;
  overflow: hidden;
  z-index: 1;

  width: 100%;
  position: absolute;
  margin: 0 !important;
  right: 0;
  left: 0;
}

.landing-page-divider-icon7{
  width: 100%;
  position: absolute;
  margin: 0 !important;
  right: 0;
  left: 0;

  height: 1px;
  bottom: 127px;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.landing-page-divider-icon7-cstm {
    position: initial !important;
  }

  @media screen and (max-width: 1100px) {
    
    .landing-page-listings-search-results-list {
        height: auto;
      }

      .landing-page-filters {
        padding-bottom: 543px;
        box-sizing: border-box;
      }
  }

  @media screen and (max-width: 750px) {
    
    .landing-page-listings-search-results-list {
       gap: 49px;
      }

      .landing-page-filters {
        padding-bottom: 353px;
        box-sizing: border-box;
      }
  }

  @media screen and (max-width: 450px) {
    
    .landing-page-listings-search-results-list {
       gap: 25px;
      }
  }