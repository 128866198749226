.chat-frame-child,
.chat-messaging-with-list-view-car-child {
  position: relative;
  background-color: #fff;
  display: none;
}

.chat-messaging-with-list-view-car-child {
  align-self: stretch;
  height: 1906px;
}

.chat-frame-child {
  height: 72px;
  width: 1440px;
  max-width: 100%;
}

.chat-gerator-04-1-icon {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}

.chat-logo,
.chat-logo-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-logo {
  align-self: stretch;
  height: 32px;
  flex-direction: row;
  background-image: url(../../icons/Gerator/chat_page/gerator04-1@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.chat-logo-container {
  width: 121px;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
}

.chat-bar-chart-01-icon,
.chat-dot-icon {
  height: 10px;
  width: 10px;
  position: relative;
  display: none;
}

.chat-bar-chart-01-icon {
  height: 24px;
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.chat-text {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 30px;
}

.chat-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.chat-text1 {
  align-self: stretch;
  position: relative;
  line-height: 18px;
  font-weight: 500;
}

.chat-badge {
  height: 22px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
}

.chat-chevron-down-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.chat-nav-item-base {
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  gap: 8px;
}

.chat-text2 {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 29px;
}

.chat-badge1,
.chat-nav-item-base1 {
  background-color: #f9fafb;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.chat-badge1 {
  height: 22px;
  border-radius: 9999px;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
  color: #344054;
}

.chat-nav-item-base1 {
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  padding: 8px 12px;
  gap: 8px;
  color: #18222f;
}

.chat-text4 {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 38px;
}

.chat-navbar,
.chat-navigation {
  display: flex;
  justify-content: flex-start;
}

.chat-navigation {
  flex-direction: row;
  align-items: center;
  gap: 4px;
  z-index: 1;
}

.chat-navbar {
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
}

.chat-label {
  width: 47px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.chat-search-lg-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.chat-search-placeholder {
  text-decoration: none;
  flex: 1;
  position: relative;
  line-height: 24px;
  color: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-input {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 13px;
  font-size: 16px;
  color: #667085;
}

.chat-input-with-label,
.chat-logo-container-parent,
.chat-navbar-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-input-with-label {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  z-index: 1;
  font-size: 14px;
}

.chat-logo-container-parent,
.chat-navbar-parent {
  flex-direction: row;
  max-width: 100%;
}

.chat-navbar-parent {
  flex: 1;
  gap: 38px;
}

.chat-logo-container-parent {
  width: 754px;
  gap: 70px;
}

.chat-icon {
  height: 19px;
  width: 19px;
  position: relative;
}

.chat-nav-item-button {
  width: 40px;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  z-index: 1;
}

.chat-nav-item-button-child {
  height: 18.3px;
  width: 14.6px;
  position: relative;
}

.chat-profile-button1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3px 0 0;
}

.chat-contrast-border {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  display: none;
}

.chat-avatar {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url(../../icons/Gerator/dashboard/user-placeholder2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.chat-rectangle-parent,
.chat-user-actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}

.chat-rectangle-parent {
  align-self: stretch;
  background-color: #fff;
  justify-content: space-between;
  padding: 14px 64px 14px 31px;
  box-sizing: border-box;
  top: 0;
  z-index: 99;
  position: sticky;
  max-width: 100%;
  gap: 20px;
  text-align: left;
  font-size: 16px;
  color: #344054;
  font-family: Inter;
}

.chat-divider-icon,
.chat-home-line-icon {
  position: relative;
  overflow: hidden;
}

.chat-divider-icon {
  align-self: stretch;
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
}

.chat-home-line-icon {
  height: 24px;
  width: 24px;
  flex-shrink: 0;
}

.chat-nav-item-button2,
.chat-nav-item-button3 {
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
}

.chat-nav-item-button3 {
  background-color: #f9fafb;
}

.chat-navigation2 {
  align-self: stretch;
  padding: 0 16px;
  gap: 8px;
}

.chat-navigation1,
.chat-navigation2,
.chat-navigation3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-navigation1 {
  align-self: stretch;
  padding: 32px 0 0;
}

.chat-navigation3 {
  gap: 8px;
}

.chat-contrast-border1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
}

.chat-avatar1 {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 9999px;
  background-image: url(../../icons/Gerator/dashboard/user-placeholder2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.chat-content4,
.chat-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chat-footer {
  justify-content: flex-start;
  padding: 0 16px 24px;
  gap: 24px;
}

.chat-content4 {
  align-self: stretch;
  flex: 1;
  border-right: 1px solid #eaecf0;
  justify-content: space-between;
}

.chat-breadcrumb-button-base,
.chat-sidebar-navigation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-sidebar-navigation {
  height: 1260px;
  width: 80px;
  background-color: #fff;
  border-right: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.chat-breadcrumb-button-base {
  border-radius: 6px;
  padding: 4px;
}

.chat-chevron-right-icon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.chat-crumb-labels {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 74px;
}

.chat-breadcrumb-button-base1 {
  flex: 1;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.chat-text6 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.chat-breadcrumb-button-base2 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 7px;
}

.chat-chevron-right-icon2 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.chat-text7 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  white-space: nowrap;
}

.chat-breadcrumb-button-base3 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.chat-text8 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 75px;
}

.chat-breadcrumb-button-base4,
.chat-breadcrumbs,
.chat-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chat-breadcrumb-button-base4 {
  flex: 1;
  border-radius: 6px;
  background-color: #f9fafb;
  justify-content: center;
  padding: 4px 8px;
  color: #344054;
}

.chat-breadcrumbs,
.chat-tabs {
  justify-content: flex-start;
}

.chat-tabs {
  flex: 1;
  gap: 8px;
}

.chat-breadcrumbs {
  width: 273px;
}

.chat-divider-icon1 {
  width: 273px;
  height: 1px;
  position: relative;
  display: none;
}

.chat-page-header {
  align-self: stretch;
  background-color: #fff;
  flex-direction: column;
  gap: 20px;
}

.chat-container,
.chat-header-section,
.chat-header-section-wrapper,
.chat-page-header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-container {
  align-self: stretch;
  flex-direction: column;
  padding: 0 32px;
}

.chat-header-section,
.chat-header-section-wrapper {
  max-width: 100%;
}

.chat-header-section {
  width: 1078px;
  flex-direction: column;
  z-index: 1;
}

.chat-header-section-wrapper {
  align-self: stretch;
  flex-direction: row;
}

.chat-content-divider-child,
.chat-frame-item {
  position: relative;
  border-radius: 12px;
}

.chat-content-divider-child {
  align-self: stretch;
  height: 505px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
}

.chat-frame-item {
  width: 148px;
  height: 148px;
  object-fit: cover;
  z-index: 2;
}

.chat-rectangle-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0 0;
}

.chat-used {
  text-transform: lowercase;
}

.chat-pre-owned-used-fully-refurbi {
  margin-block-start: 0;
  margin-block-end: 18px;
}

.chat-ase-antibody-detection {
  margin: 0;
  text-transform: lowercase;
}

.chat-detail-title {
  align-self: stretch;
  position: relative;
  line-height: 12px;
  font-weight: 600;
  z-index: 2;
}

.chat-dot-icon3 {
  height: 8px;
  width: 8px;
  position: relative;
  display: none;
}

.chat-text9 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 51px;
}

.chat-badge3 {
  width: 69px;
  border-radius: 9999px;
  background-color: #fffdfd;
  border: 1px solid #da2d20;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 2;
}

.chat-supporting-text {
  position: relative;
  font-size: 16px;
  letter-spacing: -0.17px;
  line-height: 24px;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 83px;
  z-index: 2;
}

.chat-info-badge {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.chat-marker-icon {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 41px;
}

.chat-marker-icon-wrapper {
  width: 50px;
  border-radius: 8px;
  background-color: #fdb022;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 4px;
  box-sizing: border-box;
  z-index: 2;
}

.chat-supporting-text1 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 117px;
  z-index: 2;
}

.chat-frame-container,
.chat-location-label {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-location-label {
  flex-direction: column;
  padding: 1px 0 0;
  color: #475467;
}

.chat-frame-container {
  flex-direction: row;
  gap: 8px;
  text-align: left;
  font-size: 16px;
  color: #fff;
}

.chat-text10 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.chat-badge4,
.chat-badges {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-badge4 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
  color: #344054;
}

.chat-badges {
  flex: 1;
  flex-direction: column;
  gap: 15px;
  min-width: 114px;
  color: #da2d20;
}

.chat-marker-pin-02-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}

.chat-location {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 124px;
  z-index: 2;
}

.chat-calendar-icon-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
}

.chat-work-type {
  width: 61px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 61px;
}

.chat-badge5 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
}

.chat-text11 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 60px;
}

.chat-badge6 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  justify-content: flex-start;
  padding: 0 7px;
  z-index: 2;
}

.chat-badge6,
.chat-work-badges,
.chat-work-badges-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.chat-work-badges {
  flex: 1;
  justify-content: flex-start;
  gap: 8px;
}

.chat-work-badges-wrapper {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 2px 0 0;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.chat-frame-div {
  width: 163px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 56px;
  text-align: left;
  font-size: 16px;
}

.chat-type-of-work {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 109px;
  z-index: 2;
}

.chat-price-tag,
.chat-price-tag-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-price-tag {
  gap: 8px;
}

.chat-price-tag-wrapper {
  padding: 0 14px;
}

.chat-cost-label {
  width: 149px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
}

.chat-badge7,
.chat-frame-parent1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-badge7 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  padding: 0 7px;
  white-space: nowrap;
  z-index: 2;
  text-align: center;
  font-size: 14px;
  color: #344054;
}

.chat-frame-parent1 {
  flex: 1;
  flex-direction: column;
  gap: 56px;
  min-width: 107px;
  text-align: left;
  font-size: 16px;
}

.chat-status-label {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 77px;
}

.chat-badge8,
.chat-badges-parent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.chat-badge8 {
  width: 93px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 0 7px;
  z-index: 2;
  color: #344054;
}

.chat-badges-parent {
  width: 615px;
  align-items: flex-end;
  gap: 6.chat-3px;
  max-width: 100%;
  text-align: center;
  font-size: 14px;
  color: #475467;
}

.chat-content-container-inner,
.chat-detail-title-parent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-detail-title-parent {
  align-self: stretch;
  gap: 4px;
}

.chat-content-container-inner {
  flex: 1;
  padding: 10px 0 0;
  box-sizing: border-box;
  min-width: 575px;
}

.chat-filter-label {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 66px;
}

.chat-badge-wrapper,
.chat-badge9 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-badge9 {
  align-self: stretch;
  border-radius: 6px;
  background-color: #416ec6;
  flex-direction: row;
  padding: 2px 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 2;
}

.chat-badge-wrapper {
  flex: 1;
  flex-direction: column;
  padding: 14px 0 0;
}

.chat-dots-vertical-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.chat-dropdown,
.chat-filter-dropdown {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-dropdown {
  flex-direction: column;
  z-index: 2;
}

.chat-filter-dropdown {
  width: 123px;
  flex-direction: row;
  gap: 19px;
}

.chat-price {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  white-space: nowrap;
  z-index: 2;
}

.chat-price-value {
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 83px;
  z-index: 3;
}

.chat-magnifier-1-icon {
  height: 20px;
  width: 20px;
  position: relative;
  object-fit: cover;
  min-height: 20px;
}

.chat-price-action-icons {
  flex: 1;
  flex-direction: row;
  gap: 8px;
  z-index: 2;
}

.chat-price-action-icons,
.chat-price-actions,
.chat-price-amount {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-price-actions {
  align-self: stretch;
  flex-direction: row;
  padding: 0 4px 0 3px;
}

.chat-price-amount {
  flex: 1;
  flex-direction: column;
  gap: 4px;
}

.chat-price-currency {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 30px;
  font-size: 12px;
  color: #475467;
}

.chat-frame-wrapper,
.chat-price-currency,
.chat-price-parent {
  display: flex;
  align-items: flex-start;
}

.chat-price-parent {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
}

.chat-frame-wrapper {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 10px 0 0;
  font-size: 20px;
  color: #6941c6;
}

.chat-filter-dropdown-parent {
  width: 153px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 8px;
  text-align: center;
  font-size: 14px;
  color: #fff;
}

.chat-content-container,
.chat-frame-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-content-container {
  flex: 1;
  gap: 50px;
  min-width: 707px;
}

.chat-frame-group {
  width: 1260px;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 24px;
}

.chat-divider-icon2 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 2;
}

.chat-heading,
.chat-number {
  position: relative;
  font-weight: 600;
}

.chat-heading {
  width: 254px;
  line-height: 24px;
  display: inline-block;
}

.chat-number {
  align-self: stretch;
  line-height: 38px;
}

.chat-arrow-up-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}

.chat-change1 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 29px;
}

.chat-change {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1px;
}

.chat-text12 {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: #475467;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-change-and-text,
.chat-number-and-badge {
  display: flex;
  justify-content: flex-start;
}

.chat-change-and-text {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-size: 14px;
  color: #079455;
}

.chat-number-and-badge {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.chat-chart-mini-icon {
  height: 48px;
  width: 96px;
  position: relative;
  object-fit: contain;
}

.chat-number-and-chart {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 16px;
  font-size: 30px;
}

.chat-dropdown1,
.chat-metric-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-dropdown1 {
  margin: 0 !important;
  position: absolute;
  top: 16px;
  right: 16px;
}

.chat-metric-item {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 18px 15px;
  position: relative;
  gap: 20px;
  min-width: 229px;
  max-width: 306px;
  z-index: 2;
}

.chat-heading1 {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.chat-change3,
.chat-change5,
.chat-change7 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 38px;
}

.chat-change5,
.chat-change7 {
  min-width: 28px;
}

.chat-change7 {
  min-width: 23px;
}

.chat-metric-item-parent {
  width: 1260px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 12px;
  max-width: 100%;
  font-size: 16px;
}

.chat-eye-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.chat-eye-label {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 34px;
}

.chat-buttonsbutton {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 6px 12px;
  background-color: #fff;
  width: 90px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  z-index: 2;
}

.chat-icon1 {
  width: 16.7px;
  height: 16.7px;
  position: relative;
}

.chat-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3.7px 0 0;
}

.chat-button-label {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 56px;
}

.chat-buttonsbutton1,
.chat-buttonsbutton2 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 10px 6px 12px;
  min-width: 92px;
  z-index: 2;
}

.chat-buttonsbutton1 {
  overflow: hidden;
  gap: 5.5px;
}

.chat-buttonsbutton2 {
  overflow-x: auto;
  gap: 5.4px;
}

.chat-message-button-label {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 70px;
}

.chat-notification-badge-background {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: #17b26a;
  width: 17px;
  height: 17px;
  z-index: 3;
}

.chat-notification-badge-count {
  position: absolute;
  top: 4px;
  left: 6px;
  line-height: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 7px;
  height: 20px;
  min-width: 7px;
  z-index: 4;
}

.chat-notification-badge-background-parent {
  height: 24px;
  width: 17px;
  position: absolute;
  margin: 0 !important;
  top: -9px;
  right: -2.7px;
  font-size: 10px;
  color: #fff;
}

.chat-buttonsbutton-parent,
.chat-buttonsbutton3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-buttonsbutton3 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  padding: 8px 12px;
  position: relative;
  gap: 6px;
  min-width: 79px;
  z-index: 2;
}

.chat-buttonsbutton-parent {
  width: 523.3px;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16px;
  max-width: calc(100% - 44px);
}

.chat-arrow-square-up-right-icon {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}

.chat-arrow-square-up-right-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 0 0;
}

.chat-content-divider,
.chat-frame-parent2 {
  width: 1260px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  max-width: 100%;
  font-size: 14px;
  color: #344054;
}

.chat-content-divider {
  width: 1306px;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-start;
  padding: 12px 0 22px 23px;
  gap: 23.8px;
  z-index: 1;
  font-size: 18px;
  color: #101828;
}

.chat-chat-name-label,
.chat-text17 {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  min-width: 89px;
}

.chat-text17 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #344054;
  text-align: center;
  min-width: 16px;
}

.chat-badge10,
.chat-text-and-badge {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.chat-badge10 {
  width: 28px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  padding: 0 5px;
}

.chat-text-and-badge {
  align-self: stretch;
  padding: 0 131px 0 0;
  gap: 8px;
}

.chat-supporting-text2 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-text-and-supporting-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 12px 0;
  box-sizing: border-box;
  gap: 4px;
  min-width: 166px;
}

.chat-placeholder-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.chat-text18 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #475467;
  text-align: left;
}

.chat-text-padding {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.chat-button,
.chat-text-padding,
.chat-text19 {
  align-self: stretch;
}

.chat-button {
  border-radius: 8px;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 14px;
  gap: 4px;
}

.chat-text19 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #6941c6;
  text-align: left;
}

.chat-button1,
.chat-button2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chat-button1 {
  align-self: stretch;
  border: 1px solid #d6bbfb;
  display: none;
  padding: 10px 13px;
  gap: 4px;
}

.chat-button2 {
  width: 40px;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  padding: 8px 9px;
}

.chat-button3,
.chat-text20 {
  align-self: stretch;
}

.chat-text20 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
}

.chat-button3 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
}

.chat-content5,
.chat-dropdown5 {
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-dropdown5 {
  display: none;
  flex-direction: column;
}

.chat-content5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  padding: 20px 24px;
  gap: 16px;
}

.chat-divider-icon4 {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  display: none;
  z-index: 0;
}

.chat-card-header {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  flex-shrink: 0;
}

.chat-label1,
.chat-search-placeholder1 {
  font-family: Inter;
  position: relative;
  text-align: left;
}

.chat-label1 {
  width: 47px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  display: none;
}

.chat-search-placeholder1 {
  width: calc(100% - 20px);
  border: 0;
  outline: 0;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  flex: 1;
  line-height: 24px;
  color: #667085;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 163px;
  padding: 0;
}

.chat-input-with-label1,
.chat-input1 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.chat-input1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
}

.chat-input-with-label1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.chat-hint-text {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: none;
}

.chat-input-dropdown,
.chat-search-wrap {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-input-dropdown {
  gap: 8px;
}

.chat-search-wrap {
  padding: 0 16px 12px;
  flex-shrink: 0;
}

.chat-dot {
  width: 8px;
  height: 8px;
  position: relative;
  border-radius: 5px;
  background-color: #9e77ed;
  overflow: hidden;
  flex-shrink: 0;
}

.chat-dot-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 0 0;
}

.chat-contrast-border2 {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
}

.chat-avatar-online-indicator,
.chat-avatar-offline-indicator,
.chat-avatar2 {
  border-radius: 9999px;
  box-sizing: border-box;
  position: relative;
}

.chat-avatar-online-indicator {
  height: 13px;
  width: 13px;
  background-color: #17b26a;
  border: 1.5px solid #fff;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.chat-avatar-offline-indicator {
  height: 13px;
  width: 13px;
  background-color: red;
  border: 1.5px solid #fff;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.chat-avatar2 {
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 30px 0 0 27px;
  background-image: url(../../icons/Gerator/dashboard/user-placeholder2.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.chat-message-sender-name,
.chat-supporting-text3 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}

.chat-message-sender-name {
  font-weight: 600;
  color: #344054;
  min-width: 97px;
}

.chat-supporting-text3 {
  color: #475467;
  min-width: 67px;
}

.chat-avatar-and-dot,
.chat-avatar-label-group,
.chat-text-and-supporting-text1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-avatar-and-dot,
.chat-avatar-label-group {
  flex-direction: row;
  gap: 12px;
}

.chat-avatar-and-dot {
  flex: 1;
  min-width: 157px;
}

.chat-supporting-text4 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 62px;
}

.chat-avatar-and-time {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}

.chat-message-preview {
  flex: 1;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
}

.chat-message,
.chat-message-preview-wrap {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 20px;
}

.chat-message {
  border-bottom: 1px solid #eaecf0;
  flex-direction: column;
  padding: 16px 16px 14px;
  gap: 16px;
  flex-shrink: 0;
}

.chat-dot1 {
  width: 8px;
  height: 8px;
  position: relative;
  border-radius: 5px;
  background-color: #9e77ed;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0;
}

.chat-supporting-text5,
.chat-supporting-text6,
.chat-text21 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}

.chat-text21 {
  font-weight: 600;
  color: #344054;
  min-width: 106px;
  white-space: nowrap;
}

.chat-supporting-text5,
.chat-supporting-text6 {
  color: #475467;
  min-width: 50px;
}

.chat-supporting-text6 {
  min-width: 71px;
}

.chat-you {
  font-weight: 500;
}

.chat-sure-thing-ill,
.chat-you {
  font-family: Inter;
}

.chat-message-preview1 {
  flex: 1;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  text-align: left;
}

.chat-message1 {
  align-self: stretch;
  background-color: #fcfcfd;
  border-bottom: 1px solid #eaecf0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 16px 14px;
  gap: 16px;
  flex-shrink: 0;
}

.chat-supporting-text7,
.chat-text22 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}

.chat-text22 {
  font-weight: 600;
  color: #344054;
  min-width: 70px;
  white-space: nowrap;
}

.chat-supporting-text7 {
  color: #475467;
  min-width: 53px;
}

.chat-avatar-and-dot2 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  min-width: 130px;
}

.chat-supporting-text8 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 49px;
}

.chat-avatar-and-time2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.chat-avatar-online-indicator3 {
  height: 13px;
  width: 13px;
  position: relative;
  border-radius: 9999px;
  background-color: #d0d5dd;
  border: 1.5px solid #fff;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.chat-text23 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 102px;
  white-space: nowrap;
}

.chat-avatar-and-dot3 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  min-width: 162px;
}

.chat-supporting-text10 {
  color: #475467;
  min-width: 51px;
}

.chat-supporting-text10,
.chat-supporting-text11,
.chat-text24 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}

.chat-text24 {
  font-weight: 600;
  color: #344054;
  min-width: 105px;
}

.chat-supporting-text11 {
  color: #475467;
  min-width: 58px;
}

.chat-avatar-and-dot4 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  min-width: 165px;
}

.chat-text25 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 99px;
}

.chat-avatar-and-dot5 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  min-width: 159px;
}

.chat-supporting-text14 {
  color: #475467;
  min-width: 52px;
}

.chat-supporting-text14,
.chat-supporting-text15,
.chat-text26 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}

.chat-text26 {
  font-weight: 600;
  color: #344054;
  min-width: 63px;
  white-space: nowrap;
}

.chat-supporting-text15 {
  color: #475467;
  min-width: 38px;
}

.chat-avatar-and-dot6 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  min-width: 123px;
}

.chat-message-preview6 {
  align-self: stretch;
  flex: 1;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
}

.chat-message-preview-wrap6,
.chat-side-panel {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-message-preview-wrap6 {
  align-self: stretch;
  height: 40px;
  flex-direction: row;
  padding: 0 0 0 20px;
}

.chat-side-panel {
  height: 960px;
  width: 360px;
  border-right: 1px solid #eaecf0;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: 0 0 64px;
  min-width: 360px;
  max-width: 360px;
  z-index: 1;
}

.chat-avatar-icon {
  height: 56px;
  width: 56px;
  position: relative;
  border-radius: 9999px;
}

.chat-caller-name {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter;
  color: #101828;
  text-align: left;
}

.chat-dot-icon5 {
  height: 8px;
  width: 8px;
  position: relative;
}

.chat-text27 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: center;
  display: inline-block;
  min-width: 37px;
}

.chat-badge11,
.chat-text-and-badge1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.chat-badge11 {
  width: 61px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  padding: 0 5px;
  gap: 4px;
}

.chat-text-and-badge1 {
  align-self: stretch;
  padding: 0 217px 0 0;
  gap: 8px;
}

.chat-supporting-text17 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-avatar-and-text,
.chat-text-and-supporting-text8 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 275px;
  max-width: 100%;
}

.chat-avatar-and-text {
  flex-direction: row;
  gap: 16px;
  min-width: 322px;
}

.chat-text29 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #6941c6;
  text-align: left;
  display: inline-block;
  min-width: 26px;
}

.chat-button5,
.chat-text-padding4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.chat-button5 {
  cursor: pointer;
  border: 1px solid #d6bbfb;
  padding: 8px 13px;
  background-color: #fff;
  width: 82px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  gap: 4px;
}

.chat-call-button-label {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 40px;
}

.chat-button6 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  background-color: #fff;
  width: 96px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.chat-text30 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 93px;
}

.chat-actions1,
.chat-button7,
.chat-content7 {
  display: flex;
  flex-direction: row;
}

.chat-button7 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.chat-actions1,
.chat-content7 {
  justify-content: flex-start;
}

.chat-actions1 {
  align-items: center;
  gap: 12px;
  max-width: 100%;
}

.chat-content7 {
  align-items: flex-start;
  padding: 20px 24px 0;
  box-sizing: border-box;
  gap: 16px;
}

.chat-card-header1,
.chat-content7,
.chat-divider-icon5 {
  align-self: stretch;
  max-width: 100%;
}

.chat-divider-icon5 {
  position: relative;
  overflow: hidden;
  max-height: 100%;
}

.chat-card-header1 {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  z-index: 2;
}

.chat-divider {
  align-self: stretch;
  height: 1px;
  position: relative;
  background-color: #eaecf0;
}

.chat-divider-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 9.5px 0 0;
  box-sizing: border-box;
  min-width: 266px;
  max-width: 100%;
}

.chat-divider-label {
  height: 20px;
  width: 64px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: center;
  display: inline-block;
}

.chat-content-divider1 {
  margin-top: -159px;
  width: 898px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  flex-shrink: 0;
  max-width: 100%;
}

.chat-sub-text,
.chat-usernames {
  position: relative;
  font-family: Inter;
  text-align: left;
  display: inline-block;
  white-space: nowrap;
}

.chat-usernames {
  height: 20px;
  flex: 1;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 257px;
  max-width: 100%;
}

.chat-sub-text {
  align-self: stretch;
  height: 18px;
  font-size: 12px;
  line-height: 18px;
  color: #475467;
}

.chat-name-and-time,
.chat-sub-text-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-sub-text-wrapper {
  width: 104px;
  flex-direction: column;
  padding: 1px 0 0;
  box-sizing: border-box;
}

.chat-name-and-time {
  align-self: stretch;
  flex-direction: row;
  gap: 8px;
  max-width: 100%;
}

.chat-text31 {
  height: 48px;
  flex: 1;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  max-width: 100%;
}

.chat-input2 {
  align-self: stretch;
  border-radius: 0 8px 8px 8px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  padding: 8px 13px;
  max-width: 100%;
}

.chat-content8,
.chat-input2,
.chat-message7 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-content8 {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  min-width: 330px;
  max-width: 100%;
}

.chat-message7 {
  width: 100%;
  flex-direction: row;
  gap: 12px;
  max-width: 560px;
}

.chat-sub-text1 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 104px;
  white-space: nowrap;
}

.chat-sub-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 0 0;
}

.chat-file-username,
.chat-text33 {
  flex: 1;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: Inter;
  color: #101828;
  text-align: left;
  display: inline-block;
  max-width: 100%;
}

.chat-file-username {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 82px;
}

.chat-name-and-time2 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: 100%;
  flex-shrink: 0;
}

.chat-page-icon {
  position: absolute;
  height: 100%;
  width: 80%;
  top: 0;
  right: 2.5%;
  bottom: 0;
  left: 17.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.chat-file-type {
  position: relative;
  font-size: 10px;
  display: inline-block;
  font-family: Inter;
  color: #fff;
  text-align: center;
  min-width: 20px;
}

.chat-file-type-wrap {
  position: absolute;
  height: 40%;
  width: 65%;
  top: 45%;
  right: 32.5%;
  bottom: 15%;
  left: 2.5%;
  border-radius: 2px;
  background-color: #da2d20;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 3px;
  box-sizing: border-box;
  z-index: 1;
}

.chat-file-type-icon {
  height: 40px;
  width: 40px;
  position: relative;
}

.chat-supporting-text18,
.chat-text34 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
}

.chat-text34 {
  font-weight: 500;
  color: #344054;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-supporting-text18 {
  color: #475467;
}

.chat-text-and-supporting-text9 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-content11,
.chat-content12 {
  flex: 1;
  flex-direction: row;
}

.chat-content12 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 278px;
  max-width: 100%;
}

.chat-content11 {
  gap: 12px;
}

.chat-content10,
.chat-content11,
.chat-input4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-input4 {
  align-self: stretch;
  border-radius: 0 8px 8px 8px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  padding: 10px 13px;
  min-height: 62px;
  flex-shrink: 0;
}

.chat-content10 {
  height: 86px;
  flex: 1;
  flex-direction: column;
  gap: 6px;
  min-width: 330px;
}

.chat-single-message-username,
.chat-sub-text3 {
  position: relative;
  font-family: Inter;
  text-align: left;
  display: inline-block;
  white-space: nowrap;
}

.chat-single-message-username {
  flex: 1;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 19px;
}

.chat-sub-text3 {
  font-size: 12px;
  line-height: 18px;
  color: #475467;
  min-width: 102px;
}

.chat-name-and-time3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.chat-text35 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-family: Inter;
  color: #fff;
  text-align: left;
}

.chat-input5 {
  border-radius: 8px 0 8px 8px;
  background-color: #7e56d9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 14px;
  white-space: nowrap;
}

.chat-content13,
.chat-message10,
.chat-row {
  display: flex;
  align-items: flex-start;
}

.chat-content13 {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 6px;
  max-width: 100%;
}

.chat-message10,
.chat-row {
  flex-direction: row;
  justify-content: flex-end;
}

.chat-message10 {
  width: 330px;
  max-width: 330px;
}

.chat-row {
  align-self: stretch;
  max-width: 100%;
}

.chat-avatar-message,
.chat-divider-frame,
.chat-message11 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-message11 {
  flex: 1;
  flex-direction: row;
  gap: 12px;
  max-width: 560px;
}

.chat-avatar-message,
.chat-divider-frame {
  box-sizing: border-box;
  max-width: 100%;
}

.chat-avatar-message {
  width: 560px;
  flex-direction: row;
  padding: 0 0 8px;
}

.chat-divider-frame {
  flex: 1;
  flex-direction: column;
  padding: 9.chat-5px 0 0;
  min-width: 273px;
}

.chat-second-divider-label {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: center;
  display: inline-block;
  min-width: 41px;
}

.chat-content-divider2,
.chat-second-content-divider {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-content-divider2 {
  flex: 1;
  gap: 8px;
}

.chat-second-content-divider {
  align-self: stretch;
  padding: 0 0 8px;
  box-sizing: border-box;
}

.chat-sub-text5 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 82px;
}

.chat-message12 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  max-width: 560px;
}

.chat-reaction-username,
.chat-sub-text6 {
  position: relative;
  font-family: Inter;
  text-align: left;
  display: inline-block;
}

.chat-reaction-username {
  flex: 1;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 19px;
  max-width: 100%;
}

.chat-sub-text6 {
  font-size: 12px;
  line-height: 18px;
  color: #475467;
  min-width: 52px;
}

.chat-name-and-time6 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  max-width: 100%;
}

.chat-emoji-icon {
  height: 16px;
  width: 16px;
  position: relative;
  object-fit: cover;
}

.chat-message-reaction {
  border-radius: 9999px;
  background-color: #f9fafb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.chat-message13,
.chat-row2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.chat-row2 {
  align-items: center;
  padding: 0 0 0 374px;
  gap: 4px;
}

.chat-message13 {
  width: 442px;
  align-items: flex-start;
  max-width: 442px;
}

.chat-loading-dot {
  height: 4px;
  width: 4px;
  position: relative;
  border-radius: 50%;
  background-color: #475467;
}

.chat-loading-dot-frame {
  height: 8px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.chat-loading-dot1 {
  height: 4px;
  width: 4px;
  position: relative;
  border-radius: 50%;
  background-color: #98a2b3;
}

.chat-loading-dot-frame1 {
  height: 8px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.chat-input9,
.chat-messages {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-input9 {
  width: 40px;
  border-radius: 0 8px 8px 8px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow-x: auto;
  flex-direction: row;
  padding: 8px 9px;
  gap: 4px;
}

.chat-messages {
  flex: 1;
  flex-direction: column;
  gap: 16px;
  min-width: 584px;
  max-width: 100%;
}

.chat-message-separator {
  width: 8px;
  flex: 1;
  position: relative;
  border-radius: 8px;
  background-color: #dadada;
  z-index: 4;
}

.chat-separator {
  height: 331px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 151px 0 0;
  box-sizing: border-box;
}

.chat-container1,
.chat-messages-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  flex-shrink: 0;
  max-width: 100%;
}

.chat-container1 {
  height: 735px;
  overflow-y: auto;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0 24px 24px;
  box-sizing: border-box;
  gap: 24px;
  z-index: 3;
}

.chat-label2,
.chat-message-placeholder {
  font-family: Inter;
  position: relative;
  text-align: left;
}

.chat-label2 {
  width: 77px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #344054;
  display: none;
}

.chat-message-placeholder {
  width: 100%;
  border: 0;
  outline: 0;
  font-size: 16px;
  background-color: transparent;
  height: 80px;
  flex: 1;
  line-height: 24px;
  color: #667085;
  display: inline-block;
  min-width: 250px;
  max-width: 100%;
  padding: 0;
}

.chat-input10 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 13px;
  max-width: 100%;
}

.chat-hint-text1 {
  width: 320px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: none;
}

.chat-textarea-input-field {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  max-width: 100%;
}

.chat-buttonsbutton4,
.chat-icons {
  display: flex;
  flex-direction: row;
}

.chat-buttonsbutton4 {
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.chat-icons {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}

.chat-send-label {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 35px;
}

.chat-buttonsbutton6 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.chat-actions2,
.chat-buttonsbutton6,
.chat-input-actions {
  display: flex;
  flex-direction: row;
}

.chat-actions2 {
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  z-index: 1;
}

.chat-input-actions {
  width: 173px;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 12px;
  box-sizing: border-box;
  margin-top: -52px;
}

.chat-card-header-parent,
.chat-message-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-message-input {
  align-self: stretch;
  height: 128px;
  background-color: #fff;
  align-items: flex-end;
  padding: 0 24px 24px;
  box-sizing: border-box;
  z-index: 4;
}

.chat-card-header-parent {
  flex: 1;
  align-items: flex-start;
  min-width: 615px;
}

.chat-buttonsbutton7,
.chat-chat-content {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
}

.chat-chat-content {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
  max-width: 100%;
}

.chat-buttonsbutton7 {
  width: 36px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 6px 7px;
}

.chat-details {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 81px;
}

.chat-chat-panel,
.chat-pagination {
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.chat-pagination {
  width: 360px;
  border-top: 1px solid #eaecf0;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  gap: 20px;
  z-index: 1;
}

.chat-chat-panel {
  margin: 0;
  align-self: stretch;
  padding: 0 0 0 24px;
  gap: 16px;
}

.chat-chat-panel,
.chat-main-content,
.chat-main-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.chat-main-content {
  align-self: stretch;
  align-items: flex-end;
  gap: 24px;
  max-width: 100%;
}

.chat-main-content-wrapper {
  flex: 1;
  align-items: flex-start;
  padding: 32px 0 0;
  box-sizing: border-box;
  max-width: calc(100% - 80px);
}

.chat-frame-parent,
.chat-sidebar-navigation-parent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.chat-sidebar-navigation-parent {
  flex-direction: row;
  padding: 0 30px 0 0;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  color: #475467;
  font-family: Inter;
}

.chat-frame-parent {
  flex-direction: column;
  gap: 0.5px;
}

.chat-footer-text {
  position: relative;
  line-height: 20px;
}

.chat-footer-text-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.chat-gerator-10-2-icon {
  height: 38px;
  width: 38px;
  position: relative;
  object-fit: cover;
}

.chat-logomark1 {
  box-shadow: 0 1.3px 3.75px rgba(16, 24, 40, 0.1),
    0 1.3px 2.5px rgba(16, 24, 40, 0.06);
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-frame-parent3,
.chat-logomark,
.chat-logomark1 {
  display: flex;
  flex-direction: row;
}

.chat-logomark {
  filter: drop-shadow(0 1.3px 3.75px rgba(16, 24, 40, 0.1)) drop-shadow(0 1.3px 2.5px rgba(16, 24, 40, 0.06));
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-frame-parent3 {
  align-items: center;
  justify-content: center;
  gap: 29px;
}

.chat-content18,
.chat-logo1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-content18 {
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  gap: 20px;
}

.chat-container2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 32px;
  box-sizing: border-box;
  max-width: 1280px;
}

.chat-divider-icon6,
.chat-footer1 {
  align-self: stretch;
  overflow: hidden;
}

.chat-footer1 {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 48px 80px;
  z-index: 1;
}

.chat-divider-icon6 {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  z-index: 2;
  margin-top: -120px;
}

.chat-footer-parent,
.chat-messaging-with-list-view-car {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.chat-footer-parent {
  align-self: stretch;
  min-height: 134px;
  text-align: left;
  font-size: 16px;
  color: #475467;
  font-family: Inter;
}

.chat-messaging-with-list-view-car {
  width: 100%;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  padding: 7px 0 0;
  box-sizing: border-box;
  gap: 49px;
  line-height: normal;
  letter-spacing: normal;
}

@media screen and (max-width: 1275px) {
  .chat-container2 {
    max-width: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .chat-content-container {
    flex-wrap: wrap;
    min-width: 100%;
  }

  .chat-side-panel {
    width: 100%;
    padding-bottom: 42px;
    box-sizing: border-box;
  }

  .chat-content-divider1,
  .chat-content-divider2,
  .chat-content7,
  .chat-messages-parent {
    flex-wrap: wrap;
  }

  .chat-container1 {
    height: auto;
  }

  .chat-chat-content {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 750px) {
  .chat-navbar-parent {
    gap: 19px;
  }

  .chat-logo-container-parent {
    gap: 35px;
  }

  .chat-rectangle-parent {
    padding-right: 32px;
    box-sizing: border-box;
  }

  .chat-badges-parent {
    flex-wrap: wrap;
  }

  .chat-content-container-inner {
    min-width: 100%;
  }

  .chat-content-container {
    gap: 25px;
  }

  .chat-number {
    font-size: 24px;
    line-height: 30px;
  }

  .chat-side-panel {
    padding-bottom: 27px;
    box-sizing: border-box;
  }

  .chat-avatar-and-text,
  .chat-name-and-time,
  .chat-text-and-badge1 {
    flex-wrap: wrap;
  }

  .chat-message7 {
    max-width: 100%;
  }

  .chat-content11,
  .chat-message7,
  .chat-name-and-time2 {
    flex-wrap: wrap;
  }

  .chat-content10 {
    height: auto;
  }

  .chat-message11,
  .chat-message12 {
    flex-wrap: wrap;
    max-width: 100%;
  }

  .chat-row2 {
    flex-wrap: wrap;
  }

  .chat-card-header-parent,
  .chat-messages {
    min-width: 100%;
  }

  .chat-container2 {
    gap: 16px;
  }

  .chat-footer1 {
    gap: 32px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }

  .chat-messaging-with-list-view-car {
    gap: 24px;
  }
}

@media screen and (max-width: 450px) {
  .chat-logo-container-parent {
    gap: 17px;
  }

  .chat-price {
    font-size: 16px;
    line-height: 26px;
  }

  .chat-number {
    font-size: 18px;
    line-height: 23px;
  }

  .chat-text-and-badge {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .chat-avatar-and-time,
  .chat-avatar-and-time2,
  .chat-content5 {
    flex-wrap: wrap;
  }

  .chat-side-panel {
    height: auto;
    min-width: 100%;
  }

  .chat-text-and-badge1 {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .chat-actions1,
  .chat-name-and-time3,
  .chat-name-and-time6 {
    flex-wrap: wrap;
  }

  .chat-row2 {
    padding-left: 20px;
    box-sizing: border-box;
  }

  .chat-message13 {
    max-width: 100%;
  }

  .chat-footer1 {
    gap: 16px;
  }
}