.account-setup-button-buttonsbutton,
.account-setup-button-text-padding {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.account-setup-button-buttonsbutton {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 66px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  gap: 6px;
}

.account-setup-button-buttonsbutton:hover{
  background-color: #6941c6;
}

.account-setup-button-placeholder-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.account-setup-button-action-label {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  width: max-content;
}


@media screen and (max-width: 450px) {

  .account-setup-button-buttonsbutton {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }

}