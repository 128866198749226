.type-rent-lease {
    background-color: #E31B54;
    color: #fff;
}

.type-exchange {
    background-color: #0ba5ec;
    color: #fff;
}

.type-sale {
    background-color: #15b79e;
    color: #fff;
}

.type- {
    background-color: #475467;
    color: #fff;
}

/* .dashboard-device-component-badge-wrapper {
    padding: 0 69px;
} */

.detailed-device-component-text29 {
    /* min-width: 29px; */
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    position: relative;
    font-size: 14px;
}

.dashboard-device-component-badge3 {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    mix-blend-mode: normal;
    z-index: 2;
    /* background-color: #17b26a; */
    border-radius: 6px;
    flex: 1;
    gap: 6px;
    padding: 2px 28px;
}