@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.admin-sign-in-gerator-04-1-icon1 {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}

.admin-sign-in-brand-navigation,
.admin-sign-in-logo1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-sign-in-logo1 {
  align-self: stretch;
  height: 32px;
  flex-direction: row;
  background-image: url('../../../icons/Gerator/gerator04-1@2x.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.admin-sign-in-brand-navigation {
  width: 121px;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
}

.admin-sign-in-placeholder-icon4 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.admin-sign-in-action-label {
  text-decoration: none;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 47px;
  white-space: nowrap;
}

.admin-sign-in-buttonsbutton4,
.admin-sign-in-text-padding1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.admin-sign-in-buttonsbutton4 {
  cursor: pointer;
  border: 0;
  padding: 10px 16px;
  background-color: transparent;
  border-radius: 8px;
  overflow: hidden;
  gap: 6px;
}

.admin-sign-in-text8 {
  text-decoration: none;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 59px;
  white-space: nowrap;
}




.admin-sign-in-hamburger-menu {
  display: none;
}

.admin-sign-in-buttonsbutton5,
.admin-sign-in-navigation-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.admin-sign-in-buttonsbutton5 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 15px;
  background-color: #7e56d9;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  justify-content: center;
  gap: 6px;
}

.admin-sign-in-buttonsbutton5:hover {
  background-color: #6941C6;
}


.admin-sign-in-buttonsbutton5.loading {
  cursor: wait;
  pointer-events: none;
}

.admin-sign-in-navigation-actions {
  width: 190px;
  justify-content: flex-start;
  gap: 12px;
}

.admin-sign-in-brand-navigation-parent,
.admin-sign-in-desktop {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.admin-sign-in-brand-navigation-parent {
  flex: 1;
  justify-content: space-between;
  gap: 20px;
}

.admin-sign-in-desktop {
  width: 100%;
  justify-content: flex-start;
  padding: 0 32px;
  box-sizing: border-box;
  position: fixed;
}

.admin-sign-in-content-icon {
  height: 48px;
  width: 48px;
  position: relative;
  border-radius: 18px;
  overflow: hidden;
  flex-shrink: 0;
}

.admin-sign-in-login-credentials,
.admin-sign-in-logomark {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.admin-sign-in-logomark {
  box-shadow: 0 1.5px 4.5px rgba(16, 24, 40, 0.1),
    0 1.5px 3px rgba(16, 24, 40, 0.06);
  justify-content: flex-start;
}

.admin-sign-in-login-credentials {
  align-self: stretch;
  justify-content: center;
  padding: 0 20px;
}

.admin-sign-in-supporting-text2,
.admin-sign-in-title {
  align-self: stretch;
  position: relative;
}

.admin-sign-in-title {
  margin: 0;
  font-size: inherit;
  line-height: 38px;
  font-weight: 600;
  font-family: inherit;
}

.admin-sign-in-supporting-text2 {
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.admin-sign-in-brand-name,
.admin-sign-in-login-form-fields {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.admin-sign-in-login-form-fields {
  gap: 24px;
}

.admin-sign-in-label3 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 36px;
}

.admin-sign-in-content8 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Inter;
  font-size: 16px;
  color: #667085;
  min-width: 199px;
  max-width: 100%;
}

.admin-sign-in-help-icon3 {
  height: 16px;
  width: 16px;
  position: relative;
  display: none;
}

.admin-sign-in-input3 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 13px;
  gap: 8px;
  max-width: 100%;
}

.admin-sign-in-hint-text3 {
  width: 320px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  /* display: none; */
}

.admin-sign-in-input-field3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  max-width: 100%;
}

.admin-sign-in-label4 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 66px;
}

.admin-sign-in-form1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  max-width: 100%;
}

.admin-sign-in-input5 {
  margin: 0;
  height: 18px;
  width: 16px;
}

.admin-sign-in-checkbox-label,
.admin-sign-in-supporting-text3 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
}

.admin-sign-in-checkbox-label {
  flex: 1;
  font-weight: 500;
  color: #344054;
}

.admin-sign-in-supporting-text3 {
  align-self: stretch;
  width: 320px;
  color: #475467;
  display: none;
}

.admin-sign-in-checkbox,
.admin-sign-in-text-and-supporting-text1 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 144px;
}

.admin-sign-in-checkbox {
  gap: 8px;
  min-width: 160px;
}

.admin-sign-in-placeholder-icon8 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.admin-sign-in-submit-label {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #6941c6;
  text-align: left;
  display: inline-block;
  min-width: 114px;
}

.admin-sign-in-buttonsbutton6,
.admin-sign-in-remember-me {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-sign-in-buttonsbutton6 {
  overflow: hidden;
  gap: 6px;
}

.admin-sign-in-remember-me {
  align-self: stretch;
  flex-wrap: wrap;
  row-gap: 20px;
}

.admin-sign-in-icon {
  text-decoration: none;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 53px;
}



.admin-sign-in-buttonsbutton7 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 20px;
  background-color: #7e56d9;
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.admin-sign-in-buttonsbutton7:hover {
  background-color: #6941C6;
}


.admin-sign-in-social-icon4 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.admin-sign-in-social-label {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
}

.admin-sign-in-social-button4 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  gap: 12px;
  white-space: nowrap;
}

.admin-sign-in-social-icon5 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.admin-sign-in-social-button5 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 12px;
  white-space: nowrap;
}

.admin-sign-in-text11 {
  height: 24px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
}

.admin-sign-in-hyperlink {
  color: #475467;
  line-height: 20px;
}

.admin-sign-in-actions1,
.admin-sign-in-content7,
.admin-sign-in-social-button-groups1,
.admin-sign-in-social-button7 {
  align-self: stretch;
  align-items: center;
  justify-content: center;
}

.admin-sign-in-social-button-groups1 {
  cursor: pointer;
}

.admin-sign-in-social-button7 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: none;
  flex-direction: row;
  padding: 8px 16px;
  gap: 11px;
  white-space: nowrap;
}

.admin-sign-in-actions1,
.admin-sign-in-content7,
.admin-sign-in-social-button-groups1 {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.admin-sign-in-actions1,
.admin-sign-in-content7 {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.admin-sign-in-content7 {
  margin: 0;
  border-radius: 12px;
  gap: 24px;
  max-width: 100%;
}

.admin-sign-in-help-label,
.admin-sign-in-question {
  position: relative;
  line-height: 20px;
}

.admin-sign-in-help-label {
  font-weight: 600;
  display: inline-block;
  min-width: 51px;
  white-space: nowrap;
}

.admin-sign-in-buttonsbutton8 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  color: #6941c6;
}

.admin-sign-in-help-link {
  flex-direction: row;
  gap: 3px;
}

.admin-sign-in-account-help,
.admin-sign-in-content6,
.admin-sign-in-help-link {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-sign-in-account-help {
  flex-direction: row;
  padding: 0 75px;
  text-align: left;
  font-size: 14px;
  color: #475467;
}

.admin-sign-in-content6 {
  width: 360px;
  flex-direction: column;
  gap: 32px;
  max-width: 360px;
}

.admin-sign-in-container1,
.admin-sign-in-login-form-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.admin-sign-in-container1 {
  flex: 1;
  justify-content: center;
  padding: 0 20px;
  max-width: 1280px;
}

.admin-sign-in-login-form-container {
  align-self: center;
  justify-content: center;
  padding: 0 48px;
  max-width: 100%;
  text-align: center;
  font-size: 30px;
  color: #101828;
  font-family: Inter;
  height: 100%;
}

.admin-sign-in-copyright,
.admin-sign-in-mail-01-icon1 {
  position: relative;
  z-index: 1;
}

.admin-sign-in-copyright {
  line-height: 20px;
}

.admin-sign-in-mail-01-icon1 {
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}

.admin-sign-in-mail-icon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 0 0;
}

.admin-sign-in-helpcentergeratorcom1 {
  position: relative;
  line-height: 20px;
  white-space: nowrap;
  z-index: 1;
}

.admin-sign-in-contact {
  flex-direction: row;
  justify-content: flex-start;
  gap: 7px;
}

.admin-sign-in-contact,
.admin-sign-in-footer1,
.admin-sign-in-log-in,
.admin-sign-in-admin-sign-in-desktop {
  display: flex;
  align-items: flex-start;
}

.admin-sign-in-footer1 {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 15px;
}

.admin-sign-in-log-in,
.admin-sign-in-admin-sign-in-desktop {
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
}

.admin-sign-in-log-in {
  align-self: stretch;
  padding: 35px 32px 5px 32px;
  gap: 25px;
  height: inherit;
  max-width: 100%;
  text-align: left;
  font-size: 14px;
  color: #475467;
  font-family: Inter;
}

.admin-sign-in-admin-sign-in-desktop {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 18px 0 0;
  gap: 18px;
  line-height: normal;
  letter-spacing: normal;
  height: 100vh;

}



@media screen and (max-width: 1275px) {
  .admin-sign-in-container1 {
    max-width: 100%;
  }

  .admin-sign-in-login-form-container {
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1100px) {
  .admin-sign-in-log-in {
    padding-top: 152px;
    padding-bottom: 21px;
    box-sizing: border-box;
    gap: 325px;
  }
}

@media screen and (max-width: 750px) {
  .admin-sign-in-title {
    font-size: 24px;
    line-height: 30px;
  }

  .admin-sign-in-footer1 {
    flex-wrap: wrap;
  }

}

@media screen and (max-width: 450px) {

  .admin-sign-in-desktop {

    width: 610px;
    justify-content: flex-start;
    padding: 0 0px;
    box-sizing: border-box;

  }

  .admin-sign-in-brand-navigation {
    width: 121px;
    flex-direction: column;
    padding: 6px 0 0;
    box-sizing: border-box;
    left: 30px;
    position: relative;
  }


  .admin-sign-in-navigation-actions {
    display: none !important;
  }

  .admin-sign-in-hamburger-menu {
    display: flex;
    position: relative;
    right: 10px;
  }

  .admin-sign-in-gerator-04-1-icon1 {
    height: 32px;
    width: 121px;
    position: relative;
    object-fit: cover;
    display: none;
  }

  .admin-sign-in-title {
    font-size: 18px;
    line-height: 23px;
  }

  .admin-sign-in-checkbox,
  .admin-sign-in-help-link {
    flex-wrap: wrap;
  }

  .admin-sign-in-account-help {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .admin-sign-in-container1,
  .admin-sign-in-content6 {
    gap: 16px;
  }

  .admin-sign-in-log-in {
    padding-top: 160px;
    gap: 272px;
  }
}