@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.verified-email-gerator-04-1-icon2 {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}

.verified-email-logo2 {
  width: 121px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-image: url("../../../icons/Gerator/account_setup/gerator04-1@2x.png");
  /* background-image: url("../../../public/account_setup/gerator04-1@2x.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.verified-email-featured-icon10 {
  height: 48px;
  width: 48px;
  position: relative;
  border-radius: 10px;
}

.verified-email-step-title,
.verified-email-supporting-text10 {
  position: relative;
  line-height: 24px;
}

.verified-email-step-title {
  align-self: stretch;
  font-weight: 600;
}

.verified-email-supporting-text10 {
  color: #475467;
}

.verified-email-step-content,
.verified-email-step-title-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.verified-email-step-title-parent {
  flex: 1;
  flex-direction: column;
  gap: 2px;
  min-width: 182px;
}

.verified-email-step-content {
  align-self: stretch;
  flex-direction: row;
  gap: 16px;
}

.verified-email-connector8 {
  height: 26px;
  width: 2px;
  position: relative;
  border-radius: 2px;
  background-color: #eaecf0;
}

.verified-email-progress-connector,
.verified-email-step-base8,
.verified-email-step-base9 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 23px;
}

.verified-email-step-base8,
.verified-email-step-base9 {
  align-self: stretch;
  flex-direction: column;
  padding: 0 0 4px;
  gap: 2px;
}

.verified-email-step-base9 {
  opacity: 0.6;
}

.verified-email-featured-icon12 {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 10px;
}

.verified-email-connector-wrap4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 4px;
  gap: 4px;
}

.verified-email-supporting-text12 {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  color: #475467;
}

.verified-email-step-base10,
.verified-email-text-and-supporting-text6 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.verified-email-text-and-supporting-text6 {
  flex: 1;
  flex-direction: column;
  padding: 0 0 32px;
  box-sizing: border-box;
  gap: 2px;
  min-width: 182px;
}

.verified-email-step-base10 {
  align-self: stretch;
  flex-direction: row;
  gap: 16px;
  opacity: 0.6;
}

.verified-email-connector11 {
  width: 2px;
  height: 26px;
  position: relative;
  border-radius: 2px;
  background-color: #eaecf0;
  display: none;
}

.verified-email-connector-wrap5 {
  height: 82px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 4px;
  box-sizing: border-box;
  gap: 4px;
}

.verified-email-logo-group,
.verified-email-progress-steps-progress-icon2,
.verified-email-section-child {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.verified-email-progress-steps-progress-icon2 {
  align-self: stretch;
  flex-direction: column;
}

.verified-email-logo-group,
.verified-email-section-child {
  max-width: 100%;
}

.verified-email-logo-group {
  flex: 1;
  flex-direction: column;
  gap: 79.5px;
}

.verified-email-section-child {
  width: 380px;
  flex-direction: row;
  padding: 0 18px;
  box-sizing: border-box;
}

.verified-email-text15 {
  height: 20px;
  width: 125px;
  position: relative;
  line-height: 20px;
  display: inline-block;
}

.verified-email-mail-01-icon4 {
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.verified-email-help-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 0 0;
}

.verified-email-helpuntitleduicom2 {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 20px;
  white-space: nowrap;
}

.verified-email-footer-help {
  height: 20px;
  width: 188px;
  flex-direction: row;
  justify-content: flex-start;
  gap: 7px;
}

.verified-email-footer-help,
.verified-email-footer1,
.verified-email-section4 {
  display: flex;
  align-items: flex-start;
}

.verified-email-footer1 {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  font-size: 14px;
}

.verified-email-section4 {
  flex: 0.9386;
    background-color: #f9fafb;
   
   
    flex-direction: column;
    justify-content: flex-start;
    padding: 32.5px 13px 32px 14px;
    box-sizing: border-box;
    gap: 273px;
    min-width: 286px;
    max-width: 440px;
    height: inherit;
}

.verified-email-featured-icon14 {
  width: 56px;
  height: 56px;
  position: relative;
  border-radius: 12px;
}

.verified-email-page-title,
.verified-email-supporting-text14 {
  align-self: stretch;
  position: relative;
}

.verified-email-page-title {
  margin: 0;
  font-size: inherit;
  line-height: 38px;
  font-weight: 600;
  font-family: inherit;
}

.verified-email-supporting-text14 {
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.verified-email-header2,
.verified-email-text-and-supporting-text8 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.verified-email-header2 {
  align-items: center;
  gap: 24px;
  text-align: center;
  font-size: 30px;
  color: #101828;
}

.verified-email-placeholder-icon8 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.verified-email-text16 {
  text-decoration: none;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 71px;
}

.verified-email-buttonsbutton5,
.verified-email-text-padding2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.verified-email-buttonsbutton5 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 20px;
  background-color: #7e56d9;
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  gap: 6px;
}

.verified-email-placeholder-icon10 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.verified-email-text18 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 102px;
}

.verified-email-buttonsbutton6,
.verified-email-row1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.verified-email-buttonsbutton6 {
  overflow: hidden;
  align-items: center;
  gap: 6px;
  color: #6941c6;
}

.verified-email-row1 {
  align-items: flex-start;
  padding: 0 14px;
  gap: 3px;
}


.verified-email-content6 {
  display: flex;
  align-items: center;
}

.verified-email-content6 {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  gap: 32px;
  max-width: 360px;
}

.verified-email-container7 {
  width: 100%;
  padding: 0 32px;
  box-sizing: border-box;
  max-width: 1280px;
}

.verified-email-container5,
.verified-email-container6,
.verified-email-container7,
.verified-email-content5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.verified-email-content5 {
  width: 360px;
  max-width: 360px;
}

.verified-email-container5,
.verified-email-container6 {
  align-self: stretch;
  padding: 0 20px;
}

.verified-email-container5 {
  padding: 0 32px;
  height: 100%;
}

.verified-email-mail-01-icon5 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.verified-email-helpcentergeratorcom2 {
  position: relative;
  line-height: 20px;
  white-space: nowrap;
}

.verified-email-hyperlink{
  color: #475467;
  line-height: 20px;
}

.verified-email-icon-and-text2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
}

.verified-email-copyright {
  position: relative;
  line-height: 20px;
}

.verified-email-footer-info {
  width: 983px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  gap: 20px;
  max-width: 100%;
  text-align: left;
  font-size: 14px;
  color: #475467;
  font-family: Inter;
}

.verified-email-container-group {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 166px;
  max-width: 100%;
  height: 100%;
}

.verified-email-section5,
.verified-email-set-up-wizardemail-verified {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  font-family: Inter;
}

.verified-email-section5 {
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 160px 0 31px;
  box-sizing: border-box;
  min-width: 480px;
  max-width: 100%;
  font-size: 14px;
  color: #475467;
  height: inherit;
}

.verified-email-set-up-wizardemail-verified {
  width: 100%;
  position: relative;
  background-color: #fff;
  flex-direction: row;
  align-items: flex-start;
  height: max-content;
  line-height: normal;
  letter-spacing: normal;
  row-gap: 20px;
  font-size: 16px;
  color: #344054;
  height: 100vh;
}

@media screen and (max-width: 1275px) {
  .verified-email-logo-group {
    flex: 1;
  }

  .verified-email-section4 {
    width: 100%;
    gap: 50px;
  }

  .verified-email-container7 {
    max-width: 100%;
  }

  .verified-email-section5 {
    flex: 1;
  }

  .verified-email-set-up-wizardemail-verified {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1100px) {
  .verified-email-section4 {
    padding-top: 21px;
    padding-bottom: 21px;
    box-sizing: border-box;


  }

  .verified-email-container5 {
    gap: 40px;
  }

  .verified-email-container-group {
    gap: 640px;
  }

  .verified-email-section5 {
    padding-top: 104px;
    padding-bottom: 20px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 780px) {
  .verified-email-page-title {
    font-size: 24px;
    line-height: 30px;
  }

  .verified-email-container5 {
    gap: 20px;
  }

  .verified-email-footer-info {
    flex-wrap: wrap;
  }

  .verified-email-container-group {
    gap: 130px;
  }

  .verified-email-section5 {
    padding-top: 94px;
  }

  .verified-email-section4 {
    min-width: 100%;
    gap: 50px;
    height: fit-content;
  }

}

@media screen and (max-width: 450px) {

  .verified-email-mobile-container {
    width: 375px;
    height: 10px;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    display: inline-flex;
    padding-top: 20px;
  }

  .verified-email-mobile-PaginationDotGroup {
    justify-content: center;
    align-items: center;
    gap: 16px;
    display: inline-flex;
  }

  .verified-email-mobile-PaginationDotIndicator {
    width: 10px;
    height: 10px;
    position: relative;
    background-color: #EAECF0;
    border-radius: 9999px;
  }

  .verified-email-mobile-PaginationDotIndicator1 {
    width: 10px;
    height: 10px;
    position: relative;
    background-color: #7F56D9;
    border-radius: 9999px;
  }

  .verified-email-progress-steps-progress-icon2 {
    display: none;
  }

  .verified-email-step-base10,
  .verified-email-step-content {
    flex-wrap: wrap;
  }

  .verified-email-logo-group {
    gap: 40px;
  }

  .verified-email-footer1 {
    flex-wrap: wrap;
  }

  .verified-email-section4 {

    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    min-width: 100%;
    background-color: white;
  }

  .verified-email-page-title {
    font-size: 18px;
    line-height: 23px;
  }

  .verified-email-row1 {
    flex-wrap: wrap;
  }

  .verified-email-container7,
  .verified-email-content5,
  .verified-email-content6 {
    gap: 18px;
  }

  .verified-email-container6 {
    gap: 40px;
  }

  .verified-email-container-group {
    gap: 620px;
  }

  .verified-email-section5 {
    padding-top: 128px;
    box-sizing: border-box;
  }
}