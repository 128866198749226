.contact-featured-icon2 {
    /* height: 40px;
      width: 40px; */
    position: relative;
    border-radius: 8px;
    padding-top: 3px;
  }
  
  .contact-supporting-text91,
  .contact-title9 {
    align-self: stretch;
    position: relative;
    white-space: nowrap;
  }
  
  .contact-title9 {
    text-decoration: none;
    line-height: 30px;
    font-weight: 600;
    color: inherit;
  }
  
  .contact-supporting-text91 {
    font-size: 14px;
    line-height: 20px;
    color: #475467;
  }
  
  .contact-content71,
  .contact-text-and-supporting-text26 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
  }
  
  .contact-content71 {
    margin: 0 !important;
    position: absolute;
    top: 24px;
    left: 24px;
    flex-direction: row;
    gap: 16px;
  }
  
  .contact-button-close-x6,
  .contact-slide-out-menu-header {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
  }
  
  .contact-button-close-x6 {
    height: 20px;
    margin: 0 !important;
    position: absolute;
    top: 27px;
    right: 26px;
    border-radius: 8px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    z-index: 1;
    cursor: pointer;
  }
  
  .contact-slide-out-menu-header {
    align-self: stretch;
    height: 78px;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 24px;
    position: relative;
    gap: 8px;
    text-align: left;
    font-size: 20px;
    color: #101828;
    font-family: Inter;
  }
  
  .contact-label9 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 67px;
  }
  
  .contact-content72 {
    width: 100%;
    border: 0;
    outline: 0;
    background-color: transparent;
    height: 24px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-family: Inter;
    font-size: 16px;
    color: #101828;
    min-width: 194px;
    max-width: 100%;
  }
  
  .contact-help-icon2 {
    height: 16px;
    width: 16px;
    position: relative;
    display: none;
  }
  
  .contact-input6 {
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 13px;
    gap: 8px;
    max-width: 100%;
    cursor: pointer;
  }
  
  .contact-input6:focus {
    border-color: #7e56d9;
    /* Set to your preferred active color */
    box-shadow: 0 0 5px rgba(126, 86, 217, 0.5);
    /* Optional glow effect */
  }
  
  .contact-input6:hover {
    border-color: #7e56d9;
    /* Slightly darker border on hover */
  }
  
  .contact-input6a {
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 13px;
    gap: 8px;
    max-width: 100%;
  
    outline: none;
    appearance: none;
    position: relative;
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"%3E%3Cpath fill="gray" d="M10 12l-6-6h12z"/%3E%3C/svg%3E');
    /* Custom dropdown arrow */
    background-repeat: no-repeat;
    background-position: 95%;
    background-size: 15px;
  }
  
  .contact-input6a:focus {
    border-color: #7e56d9;
    box-shadow: 0 0 5px rgba(126, 86, 217, 0.5);
  }
  
  
  .contact-input6a:hover {
    border-color: #7e56d9;
  }
  
  .contact-hint-text6 {
    width: 320px;
    position: relative;
    line-height: 20px;
    color: #475467;
    display: none;
  }
  
  .contact-input-field {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    max-width: 100%;
  }
  
  .contact-label10 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 94px;
  }
  
  .contact-mail-01-icon,
  .contact-text274 {
    position: relative;
    overflow: hidden;
  }
  
  .contact-mail-01-icon {
    height: 20px;
    width: 20px;
    flex-shrink: 0;
  }
  
  .contact-text274 {
    /* width: calc(100% - 36px); */
    width: 80%;
    border: 0;
    outline: 0;
    font-family: Inter;
    font-size: 16px;
    background-color: transparent;
    height: 24px;
    flex: 1;
    line-height: 24px;
    color: #101828;
    text-align: left;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* min-width: 163px; */
    padding: 0;
  }
  
  .contact-content73 {
    /* flex: 1; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;
  }
  
  .contact-help-icon3 {
    height: 16px;
    width: 16px;
    position: relative;
  }
  
  .contact-form,
  .contact-input-field1,
  .contact-input7 {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
  }
  
  .contact-input7 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    align-items: center;
    padding: 8px 13px;
    gap: 8px;
    width: 50%;
    cursor: pointer;
  }
  
  .contact-input7:focus {
    border-color: #7e56d9;
    /* Set to your preferred active color */
    box-shadow: 0 0 5px rgba(126, 86, 217, 0.5);
    /* Optional glow effect */
  }
  
  .contact-input7:hover {
    border-color: #7e56d9;
    /* Slightly darker border on hover */
  }
  
  .contact-email-domain {
    width: 50%;
    position: relative;
    line-height: 20px;
    color: #475467;
    padding: 9px 0px 0px 8px;
  
  }
  
  .contact-email-card {
    flex-direction: row;
    display: flex;
  }
  
  .contact-form,
  .contact-input-field1 {
    flex-direction: column;
  }
  
  .contact-input-field1 {
    align-items: flex-start;
    gap: 6px;
  }
  
  .contact-form {
    align-items: flex-end;
  }
  
  .contact-label11,
  .contact-label12 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 108px;
  }
  
  .contact-label12 {
    min-width: 69px;
  }
  
  .contact-section2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    max-width: 100%;
  }
  
  .contact-divider12,
  .contact-section-container {
    align-self: stretch;
    max-width: 100%;
  }
  
  .contact-section-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 24px;
    box-sizing: border-box;
  }
  
  .contact-divider12 {
    flex: 1;
    position: relative;
    background-color: #eaecf0;
  }
  
  .contact-divider-wrapper,
  .contact-slide-out-menu-header-parent {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .contact-divider-wrapper {
    height: 1px;
    flex-direction: row;
    padding: 0 24px;
    box-sizing: border-box;
  }
  
  .contact-slide-out-menu-header-parent {
    flex-direction: column;
    gap: 24px;
    text-align: left;
    font-size: 14px;
    color: #344054;
    font-family: Inter;
  }
  
  .contact-placeholder-icon91 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
    min-height: 20px;
  }
  
  .contact-text275 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 48px;
  }
  
  .contact-buttonsbutton49,
  .contact-text-padding51 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  
  .contact-buttonsbutton49 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 8px 13px;
    background-color: #fff;
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    gap: 4px;
  }
  
  .contact-buttonsbutton49:hover {
    background-color: #f9fafb;
  }
  
  .contact-text276 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 55px;
  }
  
  .contact-actions19,
  .contact-buttonsbutton50 {
    display: flex;
    flex-direction: row;
  }
  
  .contact-buttonsbutton50 {
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 13px;
    background-color: #7e56d9;
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  
  .contact-buttonsbutton50:hover {
    background-color: #6941c6;
  }
  
  .contact-actions19 {
    /* width: 179px; */
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
  }
  
  .contact-content76,
  .contact-footer2 {
    align-self: stretch;
    display: flex;
    align-items: center;
  }
  
  .contact-content76 {
    flex-direction: row;
    justify-content: flex-end;
    padding: 16px 24px;
  }
  
  .contact-footer2 {
    border-top: 1px solid #eaecf0;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .contact-panel {
    background-color: #fff;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
  
    width: 400px;
    box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08),
      0 8px 8px -4px rgba(16, 24, 40, 0.03);
    /* border-left: 1px solid #eaecf0; */
    border: 1px solid #7e56d9;
    border-radius: 8px;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: flex-start;
    /* gap: 433px; */
  }
  
  .contact-slide-out-menu {
    flex-direction: row;
    justify-content: flex-end;
    /* padding: 0 0 0 40px; */
    line-height: normal;
    letter-spacing: normal;
  
    border-radius: 10px;
    background-color: #fff;
    max-width: 100%;
    /* overflow: hidden; */
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
  
    position: absolute;
  
    z-index: 3;
    right: 3px;
    /* transform: translateX(100%);  */
    transition: transform 0.3s ease-in-out;
  
  
  }
  
  .contact-slide-out-menu.open {
    transform: translateX(0);
  }
  
  
  @media screen and (max-width: 400px) {
    .contact-panel {
      gap: 216px;
    }
  }