.active {
    background-color: #f9fafb;
}


.custom-cursor {
    cursor: pointer;
}


.easy-filter-admin-button-group {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    overflow-x: auto;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;

    box-sizing: border-box;
    display: flex;
    flex-direction: row;

  }

  .easy-filter-admin-button-group-base1 {
  /* width: 87px; */
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
  white-space: nowrap;
}

.easy-filter-admin-text {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    min-width: 30px;
  }

  .easy-filter-admin-text31 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 55px;
  }

  .easy-filter-admin-text32 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 71px;
  }

  .easy-filter-admin-text40 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 71px;
  }


  .easy-filter-admin-text39 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 61px;
  }

  .easy-filter-admin-text29 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    /* min-width: 45px; */
  }

  .easy-filter-badge {
    /* background-color: red;  */
    color: white;           
    /* padding: 0.2em 0.5em; */
    border-radius: 10px;
    font-size: 0.75em;
    padding-left: 5px;
    /* position: absolute;
    top: 5px;               
    right: 5px;             */
}
  .easy-filter-badge-container{
    width: 20px; 
    height: 20px; 
    padding-left: 8px; 
    padding-right: 8px; 
    padding-top: 2px; 
    padding-bottom: 2px; 
    background-color: #FEF3F2; 
    border-radius: 9999px; 
    border: 1px #FECDCA solid; 
    justify-content: center; 
    align-items:center;
    display:inline-flex;
  }

  .easy-filter-badge-counter{
    text-align: center; 
    color: #B42318; 
    font-size: 12px; 
    font-family: Inter; 
    font-weight: 500; 
    /* line-height: 18px;  */
    word-wrap: break-word;
  }

  .easy-filter-badge-container-success {
    width: 20px;
    height: 20px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
    background-color: #F4EEFF;
    border-radius: 9999px;
    border: 1px #B09CE3 solid;
    justify-content: center;
    align-items: center;
    display: inline-flex;
  }
  
  .easy-filter-badge-counter-success {
    text-align: center;
    color: #7E56D9;
    font-size: 12px;
    font-family: Inter;
    font-weight: 500;
    /* line-height: 18px;  */
    word-wrap: break-word;
  }
  
/* .filter-button-group-base {
    width: 87px;
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
    white-space: nowrap;
} */

/* .filter-button-group {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    overflow-x: auto;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
} */