/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"); */

.setting-dashboard-frame-layout-setti-child,
.setting-frame-child {
  position: relative;
  background-color: #fff;
  display: none;
}

/* .setting-dashboard-frame-layout-setti-child {
  align-self: stretch;
  height: 2583px;
  z-index: 1;
} */

.setting-frame-child {
  height: 72px;
  width: 1440px;
  max-width: 100%;
}

.setting-gerator-04-1-icon {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}

.setting-logo,
.setting-logo-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.setting-logo {
  align-self: stretch;
  height: 32px;
  flex-direction: row;
  background-image: url("../../icons/Gerator/profile_settings/gerator04-1@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.setting-logo-wrapper {
  width: 121px;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
}

.setting-bar-chart-01-icon,
.setting-dot-icon {
  height: 10px;
  width: 10px;
  position: relative;
  display: none;
}

.setting-bar-chart-01-icon {
  height: 24px;
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.setting-text {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 30px;
}

.setting-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.setting-text1 {
  align-self: stretch;
  position: relative;
  line-height: 18px;
  font-weight: 500;
}

.setting-badge {
  height: 22px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
}

.setting-chevron-down-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.setting-nav-item-base {
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  gap: 8px;
}

.setting-text2,
.setting-text3 {
  position: relative;
  /* font-family: Inter; */
}

.setting-text2 {
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #18222f;
  text-align: left;
  display: inline-block;
  min-width: 29px;
}

.setting-text3 {
  align-self: stretch;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #344054;
  text-align: center;
}

.setting-badge1,
.setting-nav-item-base1 {
  background-color: #f9fafb;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.setting-badge1 {
  height: 22px;
  border-radius: 9999px;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  padding: 2px 7px;
}

.setting-nav-item-base1 {
  cursor: pointer;
  border: 0;
  padding: 8px 12px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  gap: 8px;
}

.setting-text4 {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 38px;
}

.setting-navigation,
.setting-navigation-wrapper {
  display: flex;
  justify-content: flex-start;
}

.setting-navigation {
  flex-direction: row;
  align-items: center;
  gap: 4px;
  z-index: 1;
}

.setting-navigation-wrapper {
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
}

.setting-label {
  width: 47px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.setting-search-lg-icon,
.setting-text6 {
  position: relative;
  overflow: hidden;
}

.setting-search-lg-icon {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
}

.setting-text6 {
  text-decoration: none;
  flex: 1;
  line-height: 24px;
  color: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.setting-content3,
.setting-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.setting-content3 {
  flex: 1;
  gap: 8px;
}

.setting-input {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  padding: 8px 13px;
  font-size: 16px;
  color: #667085;
}

.setting-frame-group,
.setting-frame-parent,
.setting-input-with-label {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.setting-input-with-label {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  z-index: 1;
  font-size: 14px;
}

.setting-frame-group,
.setting-frame-parent {
  flex-direction: row;
  max-width: 100%;
}

.setting-frame-group {
  flex: 1;
  gap: 38px;
}

.setting-frame-parent {
  width: 754px;
  gap: 70px;
}

.setting-icon {
  height: 19px;
  width: 19px;
  position: relative;
}

.setting-nav-item-button {
  width: 40px;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  z-index: 1;
}

.setting-nav-item-button-child {
  height: 18.3px;
  width: 14.6px;
  position: relative;
}

.setting-avatar-button1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3px 0 0;
}

.setting-contrast-border {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  display: none;
}

.setting-avatar {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../icons/Gerator/profile_settings/avatar@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.setting-avatar-button-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}

.setting-dashboard-frame-layout-setti-inner,
.setting-rectangle-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

/* .setting-rectangle-parent {
  flex: 1;
  background-color: #fff;
  justify-content: space-between;
  padding: 14px 64px 14px 31px;
  top: 0;
  z-index: 99;
  position: sticky;
  gap: 20px;
  text-align: left;
  font-size: 16px;
  color: #344054;
  font-family: Inter;
} */

.setting-dashboard-frame-layout-setti-inner {
  align-self: stretch;
  justify-content: flex-start;
  /* padding: 0 0 135px; */
}

.setting-divider-icon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}

.setting-divider-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  /* padding: 0 0 134px; */
  box-sizing: border-box;
  max-width: 100%;
  /* margin-top: -134px; */
}

.setting-home-line-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.setting-nav-item-button2,
.setting-nav-item-button3 {
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
}

.setting-nav-item-button3 {
  background-color: #f9fafb;
}

.setting-navigation2 {
  padding: 0 16px;
  gap: 8px;
}

.setting-navigation1,
.setting-navigation2,
.setting-navigation3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.setting-navigation1 {
  padding: 32px 0 0;
}

.setting-navigation3 {
  gap: 8px;
}

.setting-contrast-border1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
}

.setting-avatar1 {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../icons/Gerator/profile_settings/avatar1@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.setting-content4,
.setting-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.setting-footer {
  justify-content: flex-start;
  padding: 0 16px 24px;
  gap: 24px;
}

.setting-content4 {
  align-self: stretch;
  border-right: 1px solid #eaecf0;
  justify-content: space-between;
}

.setting-sub-nav-title {
  text-decoration: none;
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  color: inherit;
}

.setting-arrow-narrow-left-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.setting-sub-nav-header {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 13px 0 0;
  gap: 5px;
}

.setting-text7 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 46px;
}

.setting-badge3,
.setting-content6,
.setting-nav-item-base3 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.setting-content6 {
  flex: 1;
  display: flex;
  padding: 0 144px 0 0;
  gap: 12px;
}

.setting-badge3,
.setting-nav-item-base3 {
  background-color: #f9fafb;
}

.setting-badge3 {
  height: 22px;
  border-radius: 9999px;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
  color: #344054;
}

.setting-nav-item-base3 {
  align-self: stretch;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  padding: 8px 12px;
  gap: 8px;
  color: #18222f;
}

.setting-text9 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 49px;
}

.setting-content7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 103px 0 0;
  gap: 12px;
}

.setting-text10 {
  width: 14px;
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 14px;
}

.setting-badge4 {
  width: 30px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 0 7px;
  text-align: center;
  font-size: 12px;
}

.setting-badge4,
.setting-content8,
.setting-nav-item-base4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.setting-nav-item-base4 {
  align-self: stretch;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  padding: 8px 12px;
  gap: 8px;
}

.setting-content8 {
  flex: 1;
  padding: 0 141px 0 0;
  gap: 12px;
}

.setting-text19 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 48px;
}

.setting-content12,
.setting-content5,
.setting-navigation4 {
  display: flex;
  justify-content: flex-start;
}

.setting-content12 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 142px 0 0;
  gap: 12px;
}

.setting-content5,
.setting-navigation4 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
}

.setting-navigation4 {
  gap: 4px;
  color: #344054;
}

.setting-content5 {
  padding: 36px 16px 0;
  gap: 16px;
}

.setting-supporting-text,
.setting-title {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.setting-title {
  font-weight: 600;
}

.setting-supporting-text {
  color: #475467;
  white-space: nowrap;
}

.setting-text21 {
  width: 214px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.setting-buttonsbutton {
  margin: 0 !important;
  position: absolute;
  top: -8px;
  right: 12px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.setting-account,
.setting-sub-nav {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}

.setting-account {
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 20px 24px;
  position: relative;
  gap: 47px;
  font-size: 14px;
}

.setting-sub-nav {
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.setting-breadcrumb-button-base,
.setting-sidebar-navigation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.setting-sidebar-navigation {
  /* height: 1300px; */
  width: 362px;
  background-color: #fff;
  border-right: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  max-width: 100%;
  z-index: 1;
}

.admin-setting-sidebar-navigation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  /* height: 1300px; */
  /* width: 362px; */
  background-color: #fff;
  border-right: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  max-width: 100%;
  z-index: 1;
}

.setting-breadcrumb-button-base {
  border-radius: 6px;
  padding: 4px;
}

.setting-chevron-right-icon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.setting-text22 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 74px;
}

.setting-breadcrumb-button-base1 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  text-align: center;
}

.setting-text23 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.setting-breadcrumb-button-base2 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 7px;
}

.setting-chevron-right-icon2 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.setting-text24 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  white-space: nowrap;
}

.setting-breadcrumb-button-base3 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.setting-text25 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 57px;
}

.setting-breadcrumb-button-base4 {
  border-radius: 6px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  color: #344054;
}

.setting-tabs {
  gap: 8px;
}

.setting-breadcrumbs,
.setting-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.setting-divider-icon1 {
  width: 255px;
  height: 1px;
  position: relative;
  display: none;
}

.setting-page-header {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.setting-container {
  padding: 0 32px;
}

.setting-container,
.setting-header-section {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.setting-main-child {
  width: 1px;
  height: 29px;
  position: relative;
  border-right: 1px solid #000;
}

.setting-main,
.setting-main-child,
.setting-main-item {
  flex-shrink: 0;
  box-sizing: border-box;
}

.setting-main-item {
  width: 1px;
  height: 43px;
  position: relative;
  border-right: 1px solid #000;
}

.setting-main {
  align-self: stretch;
  height: 60px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 0 0;
  gap: 32px;
  z-index: 1;
}

.setting-text26 {
  height: 20px;
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
}

.setting-breadcrumb-button-base6,
.setting-breadcrumb-button-base7 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.setting-breadcrumb-button-base6 {
  flex: 1;
  padding: 4px 8px;
  min-width: 47px;
}

.setting-breadcrumb-button-base7 {
  height: 28px;
  padding: 4px 7px;
}

.setting-text29 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.setting-breadcrumbs1,
.setting-tabs1 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.setting-tabs1 {
  flex: 1;
  display: flex;
  gap: 8px;
}

.setting-breadcrumbs1 {
  width: 278px;
  display: none;
}

.setting-page-title {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 38px;
  font-weight: 600;
  font-family: inherit;
}

.setting-supporting-text1 {
  width: 445px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  display: none;
  max-width: 100%;
}

.setting-text-and-supporting-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 320px;
  max-width: 100%;
  font-size: 30px;
}

.setting-placeholder-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.setting-text-padding {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.setting-button,
.setting-button1,
.setting-button2 {
  height: 40px;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  gap: 4px;
}

.setting-button {
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
}

.setting-button1,
.setting-button2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: #fff;
  padding: 10px 13px;
}

.setting-button1 {
  border: 1px solid #d6bbfb;
  flex-direction: row;
  align-items: center;
  color: #6941c6;
}

.setting-button2 {
  border: 1px solid #d0d5dd;
  color: #344054;
}

.setting-actions,
.setting-button2,
.setting-button3 {
  flex-direction: row;
  align-items: center;
}

.setting-button3 {
  height: 40px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
  color: #fff;
}

.setting-actions {
  display: none;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
  color: #475467;
}

.setting-text34 {
  width: calc(100% - 20px);
  border: 0;
  outline: 0;
  font-family: inherit;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  flex: 1;
  position: relative;
  line-height: 24px;
  color: #667085;
  text-align: left;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 158px;
  padding: 0;
}

.setting-input-with-label1,
.setting-input1 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.setting-input1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
}

.setting-input-with-label1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.setting-hint-text {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.setting-content13,
.setting-input-dropdown {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.setting-input-dropdown {
  flex: 1;
  flex-direction: column;
  gap: 8px;
  min-width: 200px;
  max-width: 320px;
  color: #344054;
}

.setting-content13 {
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 2px 0 0;
  box-sizing: border-box;
  gap: 16px 14px;
  color: #101828;
}

.setting-content13,
.setting-divider-icon2,
.setting-page-header1 {
  align-self: stretch;
  max-width: 100%;
}

.setting-divider-icon2 {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  display: none;
}

.setting-page-header1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.setting-text35 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 44px;
}

.setting-tab-button-base {
  border-bottom: 2px solid #7e56d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 4px 9px;
  color: #6941c6;
}

.setting-text36 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  /* min-width: 62px; */
}

.setting-tab-button-base {
  border-bottom: 2px solid #7e56d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 15px 12px 4px;
  color: #6941c6;
}

.setting-tab-button-base1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 15px 12px 4px;
}

.setting-badge-count,
.setting-text38 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 38px;
}

.setting-badge-count {
  line-height: 18px;
  font-weight: 500;
  min-width: 8px;
}

.setting-badge10,
.setting-tab-button-base3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.setting-badge10 {
  width: 24px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  justify-content: flex-start;
  padding: 0 7px;
  text-align: center;
  font-size: 12px;
  color: #344054;
}

.setting-tab-button-base3 {
  height: 32px;
  justify-content: center;
  padding: 0 4px 11px;
  gap: 8px;
}

.setting-text39,
.setting-text40,
.setting-text43 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 30px;
}

.setting-text40,
.setting-text43 {
  text-decoration: none;
  color: inherit;
  min-width: 42px;
}

.setting-text43 {
  min-width: 83px;
}

.setting-tab-button-base7 {
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 4px 11px;
  box-sizing: border-box;
  gap: 7px;
}

.setting-text45,
.setting-text46 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 82px;
}

.setting-text46 {
  min-width: 24px;
}

.setting-tab-button-base9,
.setting-tabs2 {
  display: flex;
  flex-direction: row;
}

.setting-tab-button-base9 {
  width: 31px;
  align-items: center;
  justify-content: center;
  padding: 0 3px 12px;
  box-sizing: border-box;
}

.setting-tabs2 {
  align-items: flex-start;
  justify-content: flex-start;
  /* gap: 12px; */
  max-width: 100%;
}

.setting-container1,
.setting-horizontal-tabs {
  align-self: stretch;
  flex-direction: column;
  box-sizing: border-box;
}

.setting-horizontal-tabs {
  border-bottom: 1px solid #eaecf0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  color: #667085;
}

.setting-container1 {
  padding: 0 32px;
  gap: 24px;
}

.setting-container1,
.setting-header-content,
.setting-header-section1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.setting-header-section1 {
  flex: 1;
  flex-direction: column;
}

.setting-header-content {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 12px;
  box-sizing: border-box;
}

.setting-text47 {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.setting-button4,
.setting-button5,
.setting-text-padding4 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.setting-text-padding4 {
  align-self: stretch;
  flex: 1;
  display: flex;
  padding: 0 2px;
}

.setting-button4,
.setting-button5 {
  width: 86px;
  border-radius: 8px;
  overflow: hidden;
  display: none;
  padding: 10px 14px;
  box-sizing: border-box;
  gap: 4px;
}

.setting-button5 {
  width: 106px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: #fff;
  border: 1px solid #d6bbfb;
  padding: 10px 13px;
  color: #6941c6;
}

.setting-dots-vertical-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.setting-dropdown {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.setting-supporting-text2,
.setting-toggle-title {
  align-self: stretch;
  position: relative;
}

.setting-toggle-title {
  line-height: 28px;
  font-weight: 600;
}

.setting-supporting-text2 {
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.setting-toggle-header {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 320px;
  max-width: 100%;
}

.setting-frame-item {
  height: 40px;
  width: 156px;
  position: relative;
  background-color: #fff;
  display: none;
}

.setting-button6,
.setting-toggle-base {
  border-radius: 9999px;
  overflow: hidden;
  flex-shrink: 0;
}

.setting-button6 {
  height: 16px;
  width: 16px;
  position: relative;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  background-color: #fff;
}

.setting-toggle-base {
  height: 20px;
  width: 36px;
  background-color: #f2f4f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px;
  box-sizing: border-box;
  z-index: 1;
}

.setting-supporting-text3,
.setting-switch-label {
  position: relative;
  line-height: 20px;
}

.setting-switch-label {
  letter-spacing: -0.02px;
  font-weight: 500;
  display: inline-block;
  min-width: 97px;
  z-index: 1;
}

.setting-supporting-text3 {
  align-self: stretch;
  width: 300px;
  color: #475467;
  display: none;
}

.setting-rectangle-group {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0 10px 15px;
  gap: 8px;
}

.setting-button-labels {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  /* font-family: Inter; */
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 63px;
}

.setting-button7 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 16px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.setting-text49 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  /* font-family: Inter; */
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 110px;
}

.setting-button8,
.setting-toggle-controls,
.setting-toggle-header-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.setting-button8 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 16px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
}

.setting-toggle-controls,
.setting-toggle-header-parent {
  gap: 12px;
  max-width: 100%;
  font-size: 14px;
  color: #344054;
}

.setting-toggle-header-parent {
  align-self: stretch;
  gap: 16px;
  font-size: 18px;
  color: #101828;
}

.setting-divider-icon3 {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.setting-divider-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 4px;
  box-sizing: border-box;
  max-width: 100%;
}

.setting-text50 {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.setting-section-label {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 200px;
  max-width: 280px;
}

.setting-label2 {
  width: 36px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.setting-content16 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  /* font-family: Inter; */
  font-size: 16px;
  color: #101828;
  min-width: 130px;
}

.setting-help-icon {
  height: 16px;
  width: 16px;
  position: relative;
  display: none;
}

.setting-input-field,
.setting-input2 {
  display: flex;
  justify-content: flex-start;
}

.setting-input2 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
}

.setting-input-field {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  min-width: 159px;
}

.setting-label3 {
  width: 96px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.setting-content17 {
  width: calc(100% - 46px);
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  align-items: center;
  /* font-family: Inter; */
  font-size: 16px;
  color: #101828;
  min-width: 113px;
}

.setting-content15,
.setting-content17,
.setting-input-fields,
.setting-input3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.setting-input3 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
}

.setting-content15,
.setting-input-fields {
  align-items: flex-start;
}

.setting-input-fields {
  flex: 1;
  gap: 24px;
  min-width: 480px;
  max-width: 512px;
}

.setting-content15 {
  align-self: stretch;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16px 32px;
  max-width: 100%;
}

.setting-divider {
  align-self: stretch;
  height: 1px;
  position: relative;
  background-color: #eaecf0;
}

.setting-section-label1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 200px;
  max-width: 280px;
}

.setting-extra-input-info {
  width: calc(100% - 40px);
  border: 0;
  outline: 0;
  /* font-family: Inter; */
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  flex: 1;
  position: relative;
  line-height: 24px;
  color: #101828;
  text-align: left;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 250px;
  max-width: calc(100% - 28px);
  padding: 0;
}

.setting-content19,
.setting-input4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.setting-content19 {
  flex: 1;
  max-width: calc(100% - 28px);
}

.setting-input4 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  padding: 8px 13px;
  max-width: 100%;
}

.setting-hint-text2 {
  width: 320px;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.setting-content18,
.setting-input-field1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.setting-input-field1 {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  min-width: 480px;
  max-width: 512px;
}

.setting-content18 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 102px 0 0;
  box-sizing: border-box;
  gap: 16px 32px;
  max-width: 100%;
}

.setting-upload-title {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 75px;
}

.setting-help-icon3 {
  width: 16px;
  height: 16px;
  position: relative;
}

.setting-label-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}

.setting-supporting-text4 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: #475467;
}

.setting-avatar2 {
  height: 64px;
  width: 64px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../icons/Gerator/profile_settings/avatar2@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.setting-featured-icon {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 8px;
}

.setting-text54 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 101px;
}

.setting-buttonsbutton1 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.setting-text55 {
  position: relative;
  line-height: 20px;
  color: #475467;
  display: inline-block;
  min-width: 112px;
}

.setting-action {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px;
  gap: 4px;
}

.setting-supporting-text5 {
  align-self: stretch;
  position: relative;
  font-size: 12px;
  line-height: 18px;
  color: #475467;
  text-align: center;
}

.setting-text-and-supporting-text1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.setting-page-icon {
  position: absolute;
  height: 100%;
  width: 80%;
  top: 0;
  right: 2.5%;
  bottom: 0;
  left: 17.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.setting-file-type {
  position: relative;
  display: inline-block;
  min-width: 20px;
}

.setting-file-type-wrap {
  position: absolute;
  height: 40%;
  width: 65%;
  top: 45%;
  right: 32.5%;
  bottom: 15%;
  left: 2.5%;
  border-radius: 2px;
  background-color: #7e56d9;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 3px;
  box-sizing: border-box;
  z-index: 1;
}

.setting-cursor-icon,
.setting-file-type-icon {
  position: absolute;
  margin: 0 !important;
}

.setting-file-type-icon {
  width: 40px;
  height: 40px;
  right: 0;
  bottom: 4px;
  filter: drop-shadow(0 24px 48px rgba(16, 24, 40, 0.18));
  z-index: 1;
  text-align: center;
  font-size: 10px;
  color: #fff;
}

.setting-cursor-icon {
  width: 20px;
  height: 20px;
  right: -8px;
  bottom: -4px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 2;
}

.setting-content23,
.setting-file-upload-base {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.setting-content23 {
  align-self: stretch;
  align-items: center;
  position: relative;
  gap: 12px;
}

.setting-file-upload-base {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 2px solid #7e56d9;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 13px 24px;
  min-width: 278px;
  max-width: 100%;
}

.setting-avatar-and-file-upload,
.setting-content25 {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.setting-avatar-and-file-upload {
  align-items: flex-start;
  gap: 20px;
  min-width: 480px;
  max-width: 512px;
  color: #6941c6;
}

.setting-content25 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  align-items: center;
  /* font-family: Inter; */
  font-size: 16px;
  color: #101828;
  min-width: 250px;
  max-width: 100%;
}

.setting-supporting-text6,
.setting-text59 {
  position: relative;
  line-height: 24px;
}

.setting-text59 {
  font-weight: 500;
  display: inline-block;
  min-width: 68px;
}

.setting-supporting-text6 {
  align-self: stretch;
  width: 54px;
  color: #475467;
  display: none;
}

.setting-content29 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 360px 0 0;
  box-sizing: border-box;
  max-width: calc(100% - 28px);
}

.setting-content29,
.setting-input-dropdown1,
.setting-input8 {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.setting-input8 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  max-width: 100%;
  font-size: 16px;
  color: #101828;
}

.setting-input-dropdown1 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  min-width: 480px;
  max-width: 512px;
}

.setting-text61 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 126px;
}

.setting-content31 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  max-width: 100%;
}

.setting-input9 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  padding: 8px 13px;
  font-size: 16px;
  color: #101828;
}

.setting-content33,
.setting-input10,
.setting-input9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}

.setting-content33 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  /* font-family: Inter; */
  font-weight: 500;
  font-size: 16px;
  color: #101828;
  min-width: 250px;
}

.setting-input10 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  padding: 8px 13px;
}

.setting-label-title {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 67px;
}

.setting-help-icon6 {
  height: 16px;
  width: 16px;
  position: relative;
}

.setting-heading-and-help-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
}

.setting-supporting-text8,
.setting-text66 {
  position: relative;
  line-height: 24px;
}

.setting-text66 {
  font-weight: 500;
}

.setting-supporting-text8 {
  color: #475467;
  display: inline-block;
  min-width: 89px;
  white-space: nowrap;
}

.setting-content41 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 116px 0 0;
  box-sizing: border-box;
  gap: 8px;
}

.setting-content41,
.setting-form-content,
.setting-form-content-wrapper,
.setting-input14 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.setting-input14 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
  font-size: 16px;
  color: #101828;
}

.setting-form-content,
.setting-form-content-wrapper {
  align-items: flex-start;
}

.setting-form-content {
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.setting-form-content-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 32px;
  box-sizing: border-box;
  color: #344054;
}

.setting-write-a-short {
  margin-block-start: 0;
  margin-block-end: 14px;
}

.setting-this-is-also {
  margin: 0;
}

.setting-dropdown-placeholder {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 59px;
}

.setting-content43 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  min-width: 59px;
}

.setting-content43,
.setting-input-dropdown8,
.setting-input15 {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.setting-input15 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  font-size: 16px;
  color: #101828;
}

.setting-input-dropdown8 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  min-width: 156px;
  max-width: 240px;
}

.setting-wysiwyg-editor-icon,
.setting-wysiwyg-editor-icon2 {
  height: 32px;
  width: 32px;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 32px;
}

.setting-wysiwyg-editor-icon2 {
  display: none;
}

.setting-icons,
.setting-wysiwyg-toolbar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.setting-wysiwyg-toolbar {
  align-self: stretch;
  align-items: center;
  padding: 0 84px 0 0;
  gap: 12px;
}

.setting-label16 {
  width: 77px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.setting-text68 {
  height: 130px;
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: 100%;
}

.setting-input16,
.setting-textarea-input-field {
  align-self: stretch;
  align-items: flex-start;
  max-width: 100%;
}

.setting-input16 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 13px;
  font-size: 16px;
  color: #101828;
}

.setting-textarea-input-field {
  gap: 6px;
}

.setting-content45,
.setting-file-upload-base1,
.setting-textarea-input-field,
.setting-toolbar-and-text-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.setting-toolbar-and-text-input {
  flex: 1;
  align-items: flex-start;
  gap: 12px;
  min-width: 480px;
  max-width: 512px;
}

.setting-content45,
.setting-file-upload-base1 {
  align-self: stretch;
  align-items: center;
}

.setting-content45 {
  gap: 12px;
}

.setting-file-upload-base1 {
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  padding: 14px 23px;
}

.setting-page-icon1 {
  height: 40px;
  width: 32px;
  position: absolute;
  margin: 0 !important;
  top: -18px;
  right: -12px;
}

.setting-file-type-wrap1 {
  border-radius: 2px;
  background-color: #da2d20;
  flex-direction: row;
  padding: 2px 3px;
  z-index: 1;
}

.setting-file-type-wrap1,
.setting-item-container,
.setting-item-info-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.setting-item-info-container {
  flex-direction: row;
  position: relative;
}

.setting-item-container {
  flex-direction: column;
  padding: 18px 0 0;
}

.setting-supporting-text13,
.setting-uploaded-file-name {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.setting-uploaded-file-name {
  font-weight: 500;
}

.setting-supporting-text13 {
  color: #475467;
}

.setting-background,
.setting-progress {
  align-self: stretch;
  width: 379px;
  position: relative;
  border-radius: 9999px;
  background-color: #eaecf0;
  display: none;
  max-width: 100%;
}

.setting-progress {
  background-color: #7e56d9;
}

.setting-item-progress-info,
.setting-progress-bar {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.setting-progress-bar {
  width: 379px;
  height: 8px;
  border-radius: 9999px;
  background-color: #7e56d9;
  flex-direction: row;
  z-index: 1;
}

.setting-item-progress-info {
  flex: 1;
  flex-direction: column;
  gap: 10px;
  min-width: 278px;
}

.setting-checkbox-base-icon {
  height: 16px;
  width: 16px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
}

.setting-select-file-checkbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 21px;
}

.setting-percentage {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 37px;
}

.setting-item-status {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 28px;
  margin-left: -37px;
}

.setting-file-upload-item-base,
.setting-item-progress-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.setting-item-progress-container {
  flex: 1;
  justify-content: center;
  min-width: 278px;
  row-gap: 20px;
  text-align: left;
  font-size: 14px;
  color: #344054;
}

.setting-file-upload-item-base {
  align-self: stretch;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  justify-content: flex-start;
  padding: 14px 15px 14px 16px;
  gap: 25px;
}

.setting-page-icon2 {
  height: 40px;
  width: 32px;
  position: absolute;
  margin: 0 !important;
  top: -18px;
  right: -9px;
}

.setting-file-type2 {
  position: relative;
  display: inline-block;
  min-width: 23px;
}

.setting-file-type-wrap2 {
  border-radius: 2px;
  background-color: #155eef;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 3px;
  z-index: 1;
}

.setting-text71 {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.setting-buttonsbutton3 {
  margin: 0 !important;
  position: absolute;
  top: calc(50% - 18px);
  right: -8px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.setting-progress-bar-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  max-width: 100%;
}

.setting-background1,
.setting-progress1 {
  position: relative;
  border-radius: 9999px;
}

.setting-background1 {
  align-self: stretch;
  width: 386px;
  background-color: #eaecf0;
  display: none;
  max-width: 100%;
}

.setting-progress1 {
  height: 8px;
  width: 155.5px;
  background-color: #7e56d9;
  z-index: 1;
}

.setting-progress-bar-wrapper,
.setting-progress-bar1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.setting-progress-bar1 {
  align-self: stretch;
  border-radius: 9999px;
  background-color: #eaecf0;
  flex-direction: row;
}

.setting-progress-bar-wrapper {
  flex: 1;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
  min-width: 251px;
}

.setting-percentage1 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 33px;
}

.setting-progress-bar-container1 {
  align-self: stretch;
  flex-direction: row;
  gap: 9px;
}

.setting-file-upload-item-base1,
.setting-progress-bar-container-parent,
.setting-progress-bar-container1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.setting-progress-bar-container-parent {
  flex: 1;
  flex-direction: column;
  gap: 2px;
  min-width: 278px;
  text-align: left;
  font-size: 14px;
  color: #344054;
}

.setting-file-upload-item-base1 {
  align-self: stretch;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  flex-direction: row;
  padding: 14px 15px 14px 16px;
  gap: 22px;
}

.setting-page-icon3 {
  height: 40px;
  width: 32px;
  position: absolute;
  margin: 0 !important;
  top: -18px;
  right: -15px;
}

.setting-file-type3 {
  position: relative;
  display: inline-block;
  min-width: 17px;
}

.setting-file-type-wrap3,
.setting-progress2 {
  background-color: #7e56d9;
  z-index: 1;
}

.setting-file-type-wrap3 {
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 3px;
}

.setting-progress2 {
  height: 8px;
  width: 311.1px;
  position: relative;
  border-radius: 9999px;
}

.setting-percentage2 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 32px;
}

.setting-file-upload-item-base2,
.setting-frame-div {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  max-width: 100%;
}

.setting-file-upload-item-base2 {
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 14px 15px 14px 16px;
  gap: 28px;
}

.setting-file-queue {
  align-self: stretch;
  flex-direction: column;
  gap: 12px;
  max-width: 100%;
  text-align: center;
  font-size: 10px;
  color: #fff;
}

.setting-content-wrapper,
.setting-content44,
.setting-file-queue,
.setting-file-upload {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.setting-file-upload {
  flex: 1;
  flex-direction: column;
  gap: 16px;
  min-width: 480px;
  max-width: 512px;
  color: #6941c6;
}

.setting-content-wrapper,
.setting-content44 {
  flex-direction: row;
  max-width: 100%;
}

.setting-content44 {
  flex: 1;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16px 32px;
}

.setting-content-wrapper {
  align-self: stretch;
  padding: 0 32px 4px;
  box-sizing: border-box;
  color: #344054;
}

.setting-divider-icon4,
.setting-text73 {
  align-self: stretch;
  position: relative;
}

.setting-divider-icon4 {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.setting-text73 {
  line-height: 20px;
  font-weight: 600;
  white-space: nowrap;
}

.setting-button9,
.setting-buttonsbutton5 {
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.setting-buttonsbutton5 {
  height: 20px;
  gap: 6px;
}

.setting-button9 {
  align-self: stretch;
  border-radius: 8px;
  padding: 10px 14px;
  gap: 4px;
}

.setting-text75 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  /* font-family: Inter; */
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 48px;
}

.setting-button10,
.setting-text-padding7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.setting-button10 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  background-color: #fff;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  gap: 4px;
  min-width: 52px;
}

.setting-text76 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  /* font-family: Inter; */
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 34px;
}

.setting-text-padding8 {
  width: 37px;
  justify-content: center;
  padding: 0 1px;
  box-sizing: border-box;
}

.setting-actions1,
.setting-button11,
.setting-content46,
.setting-text-padding8 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.setting-button11 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  width: 65px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  gap: 4px;
}

.setting-actions1,
.setting-content46 {
  justify-content: flex-end;
  max-width: 100%;
}

.setting-actions1 {
  flex: 1;
  padding: 0 0 0 769px;
  box-sizing: border-box;
  gap: 12px;
}

.setting-content46 {
  align-self: stretch;
  gap: 20px;
}

.setting-main1,
.setting-section-footer,
.setting-upload-footer {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.setting-section-footer {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.setting-main1,
.setting-upload-footer {
  align-items: flex-start;
  box-sizing: border-box;
}

.setting-upload-footer {
  align-self: stretch;
  flex-direction: row;
  padding: 0 32px;
}

.setting-main1 {
  flex: 1;
  background-color: #fff;
  overflow: hidden;
  flex-direction: column;
  padding: 32px 0 48px;
  gap: 20px;
  z-index: 1;
}

.setting-main-wrapper {
  width: 1038px;
  flex-direction: row;
  padding: 0 24px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  /* height: 100vh;
  overflow: scroll;
  scrollbar-width: none; */
}



.setting-sidebar-navigation-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.setting-main-parent {
  flex: 1;
  flex-direction: column;
  min-width: 701px;
  font-size: 14px;
  color: #475467;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  /* height: 100vh; */
}

.setting-sidebar-navigation-parent {
  /* min-height: 1300px; */
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  /* padding: 0 0 137px; */
  box-sizing: border-box;
  row-gap: 20px;
  /* margin-top: -134px; */
  text-align: left;
  font-size: 16px;
  color: #101828;
  /* font-family: Inter; */
}

.setting-footer-text {
  position: relative;
  line-height: 20px;
}

.setting-logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.setting-gerator-10-2-icon {
  height: 38px;
  width: 38px;
  position: relative;
  object-fit: cover;
}

.setting-logomark1 {
  box-shadow: 0 1.3px 3.75px rgba(16, 24, 40, 0.1),
    0 1.3px 2.5px rgba(16, 24, 40, 0.06);
  align-items: flex-start;
  justify-content: flex-start;
}

.setting-logo-wrapper1,
.setting-logomark,
.setting-logomark1 {
  display: flex;
  flex-direction: row;
}

.setting-logomark {
  filter: drop-shadow(0 1.3px 3.75px rgba(16, 24, 40, 0.1)) drop-shadow(0 1.3px 2.5px rgba(16, 24, 40, 0.06));
  align-items: flex-start;
  justify-content: flex-start;
}

.setting-logo-wrapper1 {
  align-items: center;
  justify-content: center;
  gap: 29px;
}

.setting-content47,
.setting-logo1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.setting-content47 {
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  gap: 20px;
}

.setting-container2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 32px;
  box-sizing: border-box;
  max-width: 1280px;
}

.setting-divider-icon5,
.setting-footer1 {
  align-self: stretch;
  overflow: hidden;
  /* margin-top: -134px; */
}

.setting-footer1 {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  /* padding: 48px 80px; */
  z-index: 1;
  text-align: left;
  font-size: 16px;
  color: #475467;
  /* font-family: Inter; */
}

.setting-divider-icon5 {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  z-index: 2;
}

.setting-dashboard-frame-layout-setti,
.setting-dashboard-frame-layout-setti1 {
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.setting-dashboard-frame-layout-setti1 {
  flex: 1;
  flex-direction: column;
  /* padding: 7px 0 0; */
  box-sizing: border-box;
  /* min-height: 2590px; */
  max-width: 100%;
}

.setting-dashboard-frame-layout-setti {
  width: 100%;
  position: relative;
  font-family: Inter;
  flex-direction: row;
  line-height: normal;
  letter-spacing: normal;
  max-width: 1524px;
  margin: 0 auto;
}

@media screen and (max-width: 1275px) {
  .setting-main1 {
    padding-top: 154px;
    padding-bottom: 20px;
    box-sizing: border-box;
  }

  .setting-sidebar-navigation-parent {
    padding-bottom: 58px;
    box-sizing: border-box;
  }

  .setting-container2 {
    max-width: 100%;
  }
}

@media screen and (max-width: 1100px) {

  .setting-tabs2,
  .setting-toggle-header-parent {
    flex-wrap: wrap;
  }

  .setting-content18 {
    padding-right: 51px;
    box-sizing: border-box;
  }

  .setting-actions1 {
    flex-wrap: wrap;
    padding-left: 384px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 750px) {
  .setting-frame-group {
    gap: 19px;
  }

  .setting-frame-parent {
    gap: 35px;
  }

  .setting-rectangle-parent {
    padding-right: 32px;
    box-sizing: border-box;
  }

  .setting-main {
    gap: 16px;
  }

  .setting-page-title {
    font-size: 24px;
    line-height: 30px;
  }

  .setting-actions {
    flex-wrap: wrap;
  }

  .setting-input-dropdown {
    max-width: 100%;
  }

  .setting-input-fields,
  .setting-toggle-controls {
    flex-wrap: wrap;
  }

  .setting-input-field1,
  .setting-input-fields {
    max-width: 100%;
    min-width: 100%;
  }

  .setting-avatar-and-file-upload {
    flex-wrap: wrap;
  }

  .setting-avatar-and-file-upload,
  .setting-input-dropdown1 {
    max-width: 100%;
    min-width: 100%;
  }

  .setting-content41,
  .setting-input14 {
    flex-wrap: wrap;
  }

  .setting-wysiwyg-toolbar {
    flex-wrap: wrap;
    padding-right: 42px;
    box-sizing: border-box;
  }

  .setting-toolbar-and-text-input {
    max-width: 100%;
    min-width: 100%;
  }

  .setting-file-upload-item-base,
  .setting-file-upload-item-base1,
  .setting-file-upload-item-base2,
  .setting-frame-div,
  .setting-item-progress-container,
  .setting-progress-bar-container1 {
    flex-wrap: wrap;
  }

  .setting-file-upload {
    max-width: 100%;
  }

  .setting-file-upload,
  .setting-main-parent {
    min-width: 100%;
  }

  .setting-sidebar-navigation-parent {
    padding-bottom: 38px;
    box-sizing: border-box;
  }

  .setting-container2 {
    gap: 16px;
  }

  .setting-footer1 {
    gap: 32px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .setting-frame-parent {
    gap: 17px;
  }

  .setting-account {
    gap: 23px;
  }

  .setting-tabs1 {
    flex-wrap: wrap;
  }

  .setting-page-title {
    font-size: 18px;
    line-height: 23px;
  }

  .setting-content15 {
    gap: 16px;
  }

  .setting-content18 {
    gap: 16px;
    padding-right: 25px;
    box-sizing: border-box;
  }

  .setting-action {
    flex-wrap: wrap;
  }

  .setting-content29,
  .setting-content41 {
    padding-right: 180px;
    box-sizing: border-box;
  }

  .setting-content41 {
    padding-right: 58px;
  }

  .setting-input15 {
    flex-wrap: wrap;
  }

  .setting-wysiwyg-toolbar {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .setting-item-status {
    margin-left: 0;
  }

  .setting-content44 {
    gap: 16px;
  }

  .setting-actions1 {
    padding-left: 192px;
    box-sizing: border-box;
  }

  .setting-footer1 {
    gap: 16px;
  }
}