@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

.text-overflow-archive-software{
  /* width: 95% ; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #344054;
}

.archive-wanted-software-listings-search-results-list-child {
    align-self: stretch;
    height: 5871px;
    position: relative;
    background-color: #fff;
    display: none;
    z-index: 0;
  }
  .archive-wanted-software-frame-child {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: 0 !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
  }
  .archive-wanted-software-gerator-04-1-icon {
    height: 32px;
    width: 121px;
    position: relative;
    object-fit: cover;
  }
  .archive-wanted-software-logo,
  .archive-wanted-software-logo-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-logo {
    flex-direction: row;
    z-index: 1;
  }
  .archive-wanted-software-logo-wrapper {
    flex-direction: column;
    padding: 6px 0 0;
  }
  .archive-wanted-software-bar-chart-01-icon,
  .archive-wanted-software-dot-icon {
    height: 10px;
    width: 10px;
    position: relative;
    display: none;
  }
  .archive-wanted-software-bar-chart-01-icon {
    height: 24px;
    width: 24px;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 24px;
  }
  .archive-wanted-software-text {
    text-decoration: none;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    color: inherit;
    display: inline-block;
    min-width: 30px;
    white-space: nowrap;
  }
  .archive-wanted-software-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  }
  .archive-wanted-software-text1 {
    align-self: stretch;
    position: relative;
    line-height: 18px;
    font-weight: 500;
  }
  .archive-wanted-software-badge {
    height: 22px;
    border-radius: 9999px;
    background-color: #f9fafb;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 7px;
    text-align: center;
    font-size: 12px;
  }
  .archive-wanted-software-chevron-down-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .archive-wanted-software-nav-item-base {
    border-radius: 6px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 12px;
    gap: 8px;
  }
  .archive-wanted-software-text2,
  .archive-wanted-software-text3 {
    position: relative;
    font-family: Inter;
  }
  .archive-wanted-software-text2 {
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #18222f;
    text-align: left;
    display: inline-block;
    min-width: 29px;
    white-space: nowrap;
  }
  .archive-wanted-software-text3 {
    align-self: stretch;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #344054;
    text-align: center;
  }
  .archive-wanted-software-badge1,
  .archive-wanted-software-nav-item-base1 {
    background-color: #f9fafb;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .archive-wanted-software-badge1 {
    height: 22px;
    border-radius: 9999px;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: none;
    padding: 2px 7px;
  }
  .archive-wanted-software-nav-item-base1 {
    cursor: pointer;
    border: 0;
    padding: 8px 12px;
    border-radius: 6px;
    overflow: hidden;
    display: flex;
    gap: 8px;
  }
  .archive-wanted-software-text4 {
    text-decoration: none;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    color: inherit;
    display: inline-block;
    min-width: 38px;
    white-space: nowrap;
  }
  .archive-wanted-software-navigation,
  .archive-wanted-software-navigation1 {
    display: flex;
    justify-content: flex-start;
  }
  .archive-wanted-software-navigation1 {
    flex-direction: row;
    align-items: center;
    gap: 4px;
    z-index: 1;
  }
  .archive-wanted-software-navigation {
    flex-direction: column;
    align-items: flex-start;
    padding: 2px 0 0;
  }
  .archive-wanted-software-label {
    width: 47px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: none;
    white-space: nowrap;
  }
  .archive-wanted-software-search-lg-icon,
  .archive-wanted-software-text6 {
    position: relative;
    overflow: hidden;
  }
  .archive-wanted-software-search-lg-icon {
    height: 20px;
    width: 20px;
    flex-shrink: 0;
  }
  .archive-wanted-software-text6 {
    width: calc(100% - 20px);
    border: 0;
    outline: 0;
    font-family: Inter;
    font-size: 16px;
    background-color: transparent;
    height: 24px;
    flex: 1;
    line-height: 24px;
    color: #667085;
    text-align: left;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 158px;
    padding: 0;
  }
  .archive-wanted-software-content3,
  .archive-wanted-software-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .archive-wanted-software-content3 {
    flex: 1;
    gap: 8px;
  }
  .archive-wanted-software-input {
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    padding: 8px 13px;
  }
  .archive-wanted-software-frame-parent,
  .archive-wanted-software-input-with-label,
  .archive-wanted-software-navigation-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-input-with-label {
    flex: 1;
    flex-direction: column;
    gap: 6px;
    z-index: 1;
    font-size: 14px;
  }
  .archive-wanted-software-frame-parent,
  .archive-wanted-software-navigation-parent {
    flex-direction: row;
    max-width: 100%;
  }
  .archive-wanted-software-navigation-parent {
    flex: 1;
    gap: 38px;
  }
  .archive-wanted-software-frame-parent {
    width: 754px;
    gap: 70px;
  }
  .archive-wanted-software-icon {
    height: 19px;
    width: 19px;
    position: relative;
  }
  .archive-wanted-software-nav-item-button {
    width: 40px;
    border-radius: 6px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px;
    box-sizing: border-box;
    z-index: 1;
  }
  .archive-wanted-software-nav-item-button-child {
    height: 18.3px;
    width: 14.6px;
    position: relative;
  }
  .archive-wanted-software-contrast-border {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 9999px;
    border: 0.8px solid rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    overflow: hidden;
  }
  .archive-wanted-software-avatar {
    height: 40px;
    width: 40px;
    position: relative;
    border-radius: 9999px;
    background-image: url("../../../icons/Gerator/archive_page/avatar@3x.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    z-index: 1;
  }
  .archive-wanted-software-user-buttons {
    justify-content: flex-start;
    gap: 15px;
  }
  .archive-wanted-software-breadcrumb-button-base,
  .archive-wanted-software-rectangle-parent,
  .archive-wanted-software-user-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .archive-wanted-software-rectangle-parent {
    width: 100%;
    margin: 0 !important;
    position: absolute;
    top: 7px;
    right: 0;
    left: 0;
    justify-content: space-between;
    padding: 14px 64px 14px 31px;
    box-sizing: border-box;
    gap: 20px;
    max-width: 100%;
    z-index: 1;
    text-align: left;
    font-size: 16px;
    color: #344054;
    font-family: Inter;
  }
  .archive-wanted-software-breadcrumb-button-base {
    border-radius: 6px;
    justify-content: flex-start;
    padding: 4px;
  }
  .archive-wanted-software-chevron-right-icon {
    height: 16px;
    width: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .archive-wanted-software-text7 {
    text-decoration: none;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    color: inherit;
    display: inline-block;
    min-width: 74px;
  }
  .archive-wanted-software-breadcrumb-button-base1 {
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
  }
  .archive-wanted-software-text8 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  .archive-wanted-software-breadcrumb-button-base2 {
    align-self: stretch;
    border-radius: 6px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 7px;
  }
  .archive-wanted-software-chevron-right-icon2 {
    height: 16px;
    width: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .archive-wanted-software-text9 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    white-space: nowrap;
  }
  .archive-wanted-software-breadcrumb-button-base3 {
    align-self: stretch;
    border-radius: 6px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
  }
  .archive-wanted-software-text10 {
    text-decoration: none;
    position: relative;
    line-height: 20px;
    font-weight: 600;
    color: inherit;
    display: inline-block;
    min-width: 64px;
  }
  .archive-wanted-software-breadcrumb-button-base4 {
    border-radius: 6px;
    background-color: #f9fafb;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    color: #344054;
  }
  .archive-wanted-software-tabs {
    gap: 8px;
  }
  .archive-wanted-software-breadcrumbs,
  .archive-wanted-software-tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .archive-wanted-software-divider-icon {
    width: 262px;
    height: 1px;
    position: relative;
    display: none;
  }
  .archive-wanted-software-page-header {
    align-self: stretch;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }
  .archive-wanted-software-container {
    padding: 0 32px;
  }
  .archive-wanted-software-container,
  .archive-wanted-software-header-section {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-main-child,
  .archive-wanted-software-main-item {
    width: 1px;
    height: 29px;
    position: relative;
    border-right: 1px solid #000;
    box-sizing: border-box;
    flex-shrink: 0;
  }
  .archive-wanted-software-main-item {
    height: 43px;
  }
  .archive-wanted-software-main,
  .archive-wanted-software-text11 {
    align-self: stretch;
  }
  .archive-wanted-software-main {
    height: 80px;
    background-color: #fff;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 32px 0 0;
    box-sizing: border-box;
    gap: 32px;
    z-index: 1;
    color: #475467;
  }
  .archive-wanted-software-text11 {
    flex: 1;
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  .archive-wanted-software-breadcrumb-button-base6,
  .archive-wanted-software-breadcrumb-button-base7 {
    align-self: stretch;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .archive-wanted-software-breadcrumb-button-base6 {
    flex: 1;
    padding: 4px 8px;
  }
  .archive-wanted-software-breadcrumb-button-base7 {
    padding: 4px 7px;
  }
  .archive-wanted-software-breadcrumbs1,
  .archive-wanted-software-tabs1 {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .archive-wanted-software-tabs1 {
    height: 28px;
    flex: 1;
    display: flex;
    gap: 8px;
  }
  .archive-wanted-software-breadcrumbs1 {
    width: 278px;
    display: none;
  }
  .archive-wanted-software-page-title {
    align-self: stretch;
    position: relative;
    line-height: 38px;
    font-weight: 600;
  }
  .archive-wanted-software-supporting-text {
    width: 445px;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    display: none;
    max-width: 100%;
  }
  .archive-wanted-software-text-and-supporting-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    min-width: 320px;
    max-width: 100%;
  }
  .archive-wanted-software-label1,
  .archive-wanted-software-text15 {
    position: relative;
  }
  .archive-wanted-software-label1 {
    width: 47px;
    line-height: 20px;
    font-weight: 500;
    display: none;
  }
  .archive-wanted-software-text15 {
    align-self: stretch;
    flex: 1;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .archive-wanted-software-content5 {
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  .archive-wanted-software-content5,
  .archive-wanted-software-input-with-label1,
  .archive-wanted-software-input1 {
    align-self: stretch;
    flex: 1;
    display: flex;
    justify-content: flex-start;
  }
  .archive-wanted-software-input1 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    padding: 10px 13px;
    font-size: 16px;
    color: #667085;
  }
  .archive-wanted-software-input-with-label1 {
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }
  .archive-wanted-software-hint-text {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    color: #475467;
    display: none;
  }
  .archive-wanted-software-input-dropdown {
    height: 44px;
    width: 320px;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    min-width: 200px;
    max-width: 320px;
    font-size: 14px;
    color: #344054;
  }
  .archive-wanted-software-content4,
  .archive-wanted-software-divider-icon1 {
    align-self: stretch;
    max-width: 100%;
  }
  .archive-wanted-software-content4 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 20px 16px;
    font-size: 26px;
    color: #101828;
  }
  .archive-wanted-software-divider-icon1 {
    position: relative;
    overflow: hidden;
    max-height: 100%;
    display: none;
  }
  .archive-wanted-software-page-header1,
  .archive-wanted-software-secondary-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .archive-wanted-software-page-header1 {
    flex: 1;
    background-color: #fff;
    flex-direction: column;
    gap: 20px;
    z-index: 1;
  }
  .archive-wanted-software-secondary-content {
    width: 939px;
    flex-direction: row;
    padding: 0 32px;
    box-sizing: border-box;
    color: #475467;
  }
  .archive-wanted-software-text16 {
    text-decoration: none;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 53px;
  }
  .archive-wanted-software-button-group-base {
    cursor: pointer;
    border: 0;
    padding: 10px 15px 10px 16px;
    background-color: #f9fafb;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 40px;
    white-space: nowrap;
  }
  .archive-wanted-software-button-group-base:hover {
    background-color: #e0e0e0;
  }
  .archive-wanted-software-text17 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 70px;
  }
  .archive-wanted-software-button-group-base1 {
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 15px 10px 16px;
    min-height: 40px;
  }
  .archive-wanted-software-button-group-base2,
  .archive-wanted-software-button-group-base3,
  .archive-wanted-software-button-group-base4,
  .archive-wanted-software-button-group-base5,
  .archive-wanted-software-button-group-base6,
  .archive-wanted-software-button-group-base7 {
    align-self: stretch;
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    min-height: 40px;
    z-index: 7;
  }
  .archive-wanted-software-button-group-base3,
  .archive-wanted-software-button-group-base4,
  .archive-wanted-software-button-group-base5,
  .archive-wanted-software-button-group-base6,
  .archive-wanted-software-button-group-base7 {
    z-index: 6;
  }
  .archive-wanted-software-button-group-base4,
  .archive-wanted-software-button-group-base5,
  .archive-wanted-software-button-group-base6,
  .archive-wanted-software-button-group-base7 {
    z-index: 5;
  }
  .archive-wanted-software-button-group-base5,
  .archive-wanted-software-button-group-base6,
  .archive-wanted-software-button-group-base7 {
    z-index: 4;
  }
  .archive-wanted-software-button-group-base6,
  .archive-wanted-software-button-group-base7 {
    z-index: 3;
  }
  .archive-wanted-software-button-group-base7 {
    z-index: 2;
  }
  .archive-wanted-software-text24 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 89px;
  }
  .archive-wanted-software-chevron-right {
    margin: 0;
    width: 16px;
    height: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .archive-wanted-software-breadcrumb-button-base11,
  .archive-wanted-software-breadcrumb-button-base12 {
    align-self: stretch;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-breadcrumb-button-base11 {
    flex: 1;
    padding: 4px 8px;
  }
  .archive-wanted-software-breadcrumb-button-base12 {
    width: 28px;
    padding: 4px 7px;
    box-sizing: border-box;
  }
  .archive-wanted-software-text28 {
    align-self: stretch;
    flex: 1;
    position: relative;
    line-height: 20px;
    font-weight: 600;
  }
  .archive-wanted-software-breadcrumb-button-base14 {
    width: 54px;
    border-radius: 6px;
    background-color: #f9fafb;
    padding: 4px 8px;
    box-sizing: border-box;
    color: #344054;
  }
  .archive-wanted-software-breadcrumb-button-base14,
  .archive-wanted-software-breadcrumbs2,
  .archive-wanted-software-button-group,
  .archive-wanted-software-tabs2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-tabs2 {
    align-self: stretch;
    flex: 1;
    gap: 8px;
  }
  .archive-wanted-software-breadcrumbs2,
  .archive-wanted-software-button-group {
    margin: 0 !important;
    position: absolute;
  }
  .archive-wanted-software-breadcrumbs2 {
    height: 28px;
    width: 278px;
    right: -2px;
    bottom: -20px;
    color: #475467;
  }
  .archive-wanted-software-button-group {
    top: 304px;
    left: 52px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    z-index: 1;
  }
  .archive-wanted-software-hint-text1 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    display: none;
  }
  .archive-wanted-software-sliders-02-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 20px;
  }
  .archive-wanted-software-menu-label {
    position: relative;
    line-height: 20px;
    font-weight: 600;
  }
  .archive-wanted-software-text-padding {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  .archive-wanted-software-placeholder-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
    min-height: 20px;
  }
  .archive-wanted-software-buttonsbutton {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 13px;
    gap: 4px;
    color: #344054;
  }
  .archive-wanted-software-input-dropdown1 {
    margin: 0 !important;
    position: absolute;
    top: 304px;
    right: 29px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    max-width: 320px;
    z-index: 1;
    color: #475467;
  }
  .archive-wanted-software-divider-icon2,
  .archive-wanted-software-divider-icon3 {
    width: 1077px;
    height: 1px;
    right: 0;
    z-index: 1;
  }
  .archive-wanted-software-divider-icon2 {
    position: absolute;
    margin: 0 !important;
    top: 356px;
  }
  .archive-wanted-software-divider-icon3 {
    top: 291px;
  }
  .archive-wanted-software-divider-icon3,
  .archive-wanted-software-filter-items,
  .archive-wanted-software-supporting-text1 {
    position: absolute;
    margin: 0 !important;
  }
  .archive-wanted-software-supporting-text1 {
    width: 678px;
    top: 122px;
    left: 52px;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    display: inline-block;
    z-index: 1;
  }
  .archive-wanted-software-filter-items {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
  }
  .archive-wanted-software-close-icons-child {
    height: 148px;
    width: 148px;
    position: relative;
    border-radius: 12px;
    object-fit: cover;
    z-index: 1;
  }
  .archive-wanted-software-text29 {
    position: relative;
    line-height: 28px;
    font-weight: 600;
    z-index: 1;
  }
  .archive-wanted-software-dots {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 0 1px;
  }
  .archive-wanted-software-dot-icon3 {
    height: 8px;
    width: 8px;
    position: relative;
    display: none;
  }
  .archive-wanted-software-text30 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 66px;
  }
  .archive-wanted-software-badge3 {
    width: 84px;
    border-radius: 9999px;
    background-color: #edfbf5;
    border: 1px solid #41d992;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 8px;
    gap: 6px;
    mix-blend-mode: normal;
    z-index: 1;
    text-align: center;
    font-size: 14px;
    color: #17b26a;
  }
  .archive-wanted-software-supporting-text2 {
    position: relative;
    letter-spacing: -0.17px;
    line-height: 24px;
    display: inline-block;
    min-width: 83px;
    z-index: 1;
  }
  .archive-wanted-software-marker-pin-02-icon {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
  }
  .archive-wanted-software-location {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 124px;
    z-index: 1;
  }
  .archive-wanted-software-dot-item-location-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 7px;
  }
  .archive-wanted-software-type-of-work {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 109px;
    z-index: 1;
  }
  .archive-wanted-software-dot-item-type-parent {
    gap: 8px;
  }
  .archive-wanted-software-completed,
  .archive-wanted-software-dot-item-content,
  .archive-wanted-software-dot-item-innermost,
  .archive-wanted-software-dot-item-type-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-dot-item-content {
    flex: 1;
    gap: 20px;
    max-width: 100%;
  }
  .archive-wanted-software-completed,
  .archive-wanted-software-dot-item-innermost {
    box-sizing: border-box;
  }
  .archive-wanted-software-dot-item-innermost {
    align-self: stretch;
    padding: 0 0 0 1px;
    max-width: 100%;
  }
  .archive-wanted-software-completed {
    width: 50px;
    border-radius: 8px;
    background-color: #fdb022;
    padding: 1px 4px;
    z-index: 1;
  }
  .archive-wanted-software-supporting-text3 {
    position: relative;
    line-height: 24px;
    display: inline-block;
    min-width: 117px;
    z-index: 1;
  }
  .archive-wanted-software-supporting-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1px 0 0;
    color: #475467;
  }
  .archive-wanted-software-completed-parent {
    flex-direction: row;
    gap: 8px;
    color: #fff;
  }
  .archive-wanted-software-badge4,
  .archive-wanted-software-completed-parent,
  .archive-wanted-software-dot-item-inner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-dot-item-inner {
    align-self: stretch;
    flex-direction: column;
    gap: 15px;
    max-width: 100%;
  }
  .archive-wanted-software-badge4 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-direction: row;
    padding: 0 7px;
    min-width: 112px;
    white-space: nowrap;
    z-index: 1;
  }
  .archive-wanted-software-text33 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 61px;
  }
  .archive-wanted-software-badge5,
  .archive-wanted-software-badge6 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 1;
  }
  .archive-wanted-software-badge5 {
    width: 77px;
    white-space: nowrap;
  }
  .archive-wanted-software-badge6 {
    width: 76px;
  }
  .archive-wanted-software-badge7,
  .archive-wanted-software-badges {
    flex: 1;
    flex-direction: row;
  }
  .archive-wanted-software-badge7 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    min-width: 107px;
    white-space: nowrap;
    z-index: 1;
  }
  .archive-wanted-software-badges {
    gap: 8px;
  }
  .archive-wanted-software-badges,
  .archive-wanted-software-badges-wrapper,
  .archive-wanted-software-dot-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .archive-wanted-software-badges-wrapper {
    align-self: stretch;
    flex-direction: row;
    padding: 0 1px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  .archive-wanted-software-dot-item {
    flex: 1;
    flex-direction: column;
    gap: 15px;
  }
  .archive-wanted-software-text36 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 77px;
  }
  .archive-wanted-software-badge8 {
    width: 93px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 1;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  .archive-wanted-software-dot-item-parent,
  .archive-wanted-software-dots-parent,
  .archive-wanted-software-dots-vertical {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  .archive-wanted-software-dot-item-parent {
    align-self: stretch;
    flex-direction: row;
    align-items: flex-end;
    padding: 0 23px 0 0;
    box-sizing: border-box;
    gap: 7px;
    font-size: 16px;
    color: #475467;
  }
  .archive-wanted-software-dots-parent,
  .archive-wanted-software-dots-vertical {
    flex-direction: column;
    align-items: flex-start;
  }
  .archive-wanted-software-dots-parent {
    align-self: stretch;
    gap: 16px;
  }
  .archive-wanted-software-dots-vertical {
    flex: 1;
    padding: 10px 0 0;
    box-sizing: border-box;
  }
  .archive-wanted-software-price {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    white-space: nowrap;
    z-index: 1;
  }
  .archive-wanted-software-deal-title {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 83px;
    z-index: 2;
  }
  .archive-wanted-software-magnifier-1-icon {
    height: 20px;
    width: 20px;
    position: relative;
    object-fit: cover;
    min-height: 20px;
  }
  .archive-wanted-software-deal-inner,
  .archive-wanted-software-radar-1-1-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .archive-wanted-software-radar-1-1-parent {
    flex: 1;
    justify-content: flex-start;
    gap: 8px;
    z-index: 1;
  }
  .archive-wanted-software-deal-inner {
    align-self: stretch;
    padding: 0 4px 0 3px;
  }
  .archive-wanted-software-card-deal,
  .archive-wanted-software-deal-details,
  .archive-wanted-software-deal-inner {
    justify-content: flex-start;
  }
  .archive-wanted-software-deal-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
  .archive-wanted-software-card-deal {
    align-self: stretch;
    flex-direction: row;
    padding: 0 30px;
    font-size: 12px;
    color: #475467;
  }
  .archive-wanted-software-card-deal,
  .archive-wanted-software-frame-wrapper,
  .archive-wanted-software-price-parent {
    display: flex;
    align-items: flex-start;
  }
  .archive-wanted-software-price-parent {
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
  }
  .archive-wanted-software-frame-wrapper {
    align-self: stretch;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 10px 0 0;
  }
  .archive-wanted-software-dots-vertical-group {
    width: 153px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 26px;
    text-align: center;
    font-size: 20px;
    color: #6941c6;
  }
  .archive-wanted-software-dots-vertical-parent {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    min-width: 525px;
    max-width: 100%;
  }
  .archive-wanted-software-close-icons,
  .archive-wanted-software-divider-icon4 {
    align-self: stretch;
    max-width: 100%;
  }
  .archive-wanted-software-close-icons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-end;
    gap: 23px;
  }
  .archive-wanted-software-divider-icon4 {
    position: relative;
    overflow: hidden;
    max-height: 100%;
    z-index: 1;
  }
  .archive-wanted-software-user-content-child {
    height: 52px;
    width: 52px;
    position: relative;
    object-fit: cover;
    z-index: 1;
  }
  .archive-wanted-software-name,
  .archive-wanted-software-role {
    position: relative;
    display: inline-block;
  }
  .archive-wanted-software-name {
    line-height: 23px;
    font-weight: 600;
    min-width: 77px;
    z-index: 1;
  }
  .archive-wanted-software-role {
    height: 19px;
    font-size: 12px;
    line-height: 24px;
    color: #6941c6;
    flex-shrink: 0;
    min-width: 94px;
    z-index: 2;
  }
  .archive-wanted-software-inner-info {
    flex-direction: column;
  }
  .archive-wanted-software-inner-info,
  .archive-wanted-software-inner-info-wrapper,
  .archive-wanted-software-user-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-inner-info-wrapper {
    flex-direction: column;
    padding: 3px 0 0;
  }
  .archive-wanted-software-user-content {
    flex: 1;
    flex-direction: row;
    gap: 8px;
    min-width: 100px;
    color: #101828;
  }
  .archive-wanted-software-eye-icon {
    height: 24px;
    width: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 24px;
  }
  .archive-wanted-software-view-label {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 34px;
  }
  .archive-wanted-software-buttonsbutton1 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 6px 12px;
    background-color: #fff;
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    z-index: 1;
  }
  .archive-wanted-software-icon1 {
    width: 16.7px;
    height: 16.7px;
    position: relative;
  }
  .archive-wanted-software-icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 3.7px 0 0;
  }
  .archive-wanted-software-lock-label {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 56px;
  }
  .archive-wanted-software-buttonsbutton2 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow-x: auto;
    padding: 6px 11px;
    gap: 5.5px;
    z-index: 1;
  }
  .archive-wanted-software-buttonsbutton2,
  .archive-wanted-software-buttonsbutton3,
  .archive-wanted-software-user-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-buttonsbutton3 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow-x: auto;
    padding: 6px 10px 6px 12px;
    gap: 5.4px;
    z-index: 1;
  }
  .archive-wanted-software-user-item {
    width: 556.4px;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 16.3px;
    max-width: calc(100% - 44px);
  }
  .archive-wanted-software-arrow-square-up-right-icon {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
  }
  .archive-wanted-software-arrow-square-up-right-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 14px 0 0;
  }
  .archive-wanted-software-user-listings {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 100%;
    gap: 20px;
  }
  .archive-wanted-software-filter-items-parent,
  .archive-wanted-software-listing-separator {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    max-width: 100%;
  }
  .archive-wanted-software-listing-separator {
    align-self: stretch;
    padding: 0 10px 0 0;
    gap: 16px;
    font-size: 14px;
    color: #344054;
  }
  .archive-wanted-software-filter-items-parent {
    width: 1016px;
    margin: 0 !important;
    position: absolute;
    right: 30px;
    bottom: 291px;
    padding: 14px 14px 17px 24px;
    gap: 24px;
    z-index: 1;
    font-size: 18px;
    color: #101828;
  }
  .archive-wanted-software-frame-inner {
    height: 148px;
    width: 148px;
    position: relative;
    border-radius: 12px;
    object-fit: cover;
    min-height: 148px;
    z-index: 1;
  }
  .archive-wanted-software-dots-vertical-icon1 {
    height: 20px;
    width: 20px;
    position: absolute;
    margin: 0 !important;
    top: -10px;
    right: -10px;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
  }
  .archive-wanted-software-price-value,
  .archive-wanted-software-text37 {
    position: relative;
    display: inline-block;
  }
  .archive-wanted-software-text37 {
    line-height: 28px;
    font-weight: 600;
    max-width: 100%;
    z-index: 1;
  }
  .archive-wanted-software-price-value {
    line-height: 20px;
    font-weight: 500;
    min-width: 55px;
  }
  .archive-wanted-software-badge9 {
    width: 73px;
    border-radius: 9999px;
    background-color: #fff9ef;
    border: 1px solid #f5b231;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 8px;
    gap: 6px;
    mix-blend-mode: normal;
    z-index: 1;
  }
  .archive-wanted-software-supporting-text4 {
    position: relative;
    font-size: 16px;
    letter-spacing: -0.17px;
    line-height: 24px;
    color: #475467;
    text-align: left;
    display: inline-block;
    min-width: 83px;
    z-index: 1;
  }
  .archive-wanted-software-badge-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }
  .archive-wanted-software-deal-type-parent {
    flex-direction: row;
    gap: 8px;
    text-align: left;
    font-size: 16px;
    color: #fff;
  }
  .archive-wanted-software-badge10,
  .archive-wanted-software-badge11,
  .archive-wanted-software-deal-type-parent,
  .archive-wanted-software-frame-parent1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-badge10 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 1;
    color: #344054;
  }
  .archive-wanted-software-badge11,
  .archive-wanted-software-frame-parent1 {
    flex: 1;
  }
  .archive-wanted-software-frame-parent1 {
    flex-direction: column;
    gap: 15px;
    min-width: 114px;
    color: #f5b231;
  }
  .archive-wanted-software-badge11 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 1;
  }
  .archive-wanted-software-sub-badge {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 60px;
  }
  .archive-wanted-software-badge12 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 1;
  }
  .archive-wanted-software-badge-group,
  .archive-wanted-software-badge12,
  .archive-wanted-software-frame-wrapper1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .archive-wanted-software-badge-group {
    flex: 1;
    justify-content: flex-start;
    gap: 8px;
  }
  .archive-wanted-software-frame-wrapper1 {
    align-self: stretch;
    justify-content: flex-end;
    padding: 0 6px 0 0;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  .archive-wanted-software-deal-location-parent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 56px;
    min-width: 109px;
    text-align: left;
    font-size: 16px;
  }
  .archive-wanted-software-badge13,
  .archive-wanted-software-inner-work {
    flex-direction: row;
    align-items: flex-start;
  }
  .archive-wanted-software-inner-work {
    display: flex;
    justify-content: flex-end;
    padding: 0 10px 0 18px;
  }
  .archive-wanted-software-badge13 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 1;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  .archive-wanted-software-badge13,
  .archive-wanted-software-badge14,
  .archive-wanted-software-card-work,
  .archive-wanted-software-frame-div {
    display: flex;
    justify-content: flex-start;
  }
  .archive-wanted-software-card-work {
    flex: 0.9649;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 6px 0 0;
    box-sizing: border-box;
    gap: 56px;
    min-width: 111px;
    text-align: left;
    font-size: 16px;
  }
  .archive-wanted-software-badge14,
  .archive-wanted-software-frame-div {
    flex-direction: row;
  }
  .archive-wanted-software-badge14 {
    width: 93px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    align-items: flex-start;
    padding: 0 7px;
    z-index: 1;
    color: #344054;
  }
  .archive-wanted-software-frame-div {
    align-self: stretch;
    align-items: flex-end;
    padding: 0 2px 0 0;
    gap: 2px;
  }
  .archive-wanted-software-frame-container,
  .archive-wanted-software-price-value1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-frame-container {
    flex: 1;
    flex-direction: column;
    padding: 8px 0 0;
    box-sizing: border-box;
    max-width: 100%;
  }
  .archive-wanted-software-price-value1 {
    flex-direction: row;
    padding: 0 30px;
    font-size: 12px;
    color: #475467;
  }
  .archive-wanted-software-inner-icons {
    flex-direction: row;
    gap: 8px;
    z-index: 1;
  }
  .archive-wanted-software-inner-icons,
  .archive-wanted-software-inner-icons-wrapper,
  .archive-wanted-software-second-price {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-inner-icons-wrapper {
    flex-direction: row;
    padding: 0 34px 0 33px;
  }
  .archive-wanted-software-second-price {
    width: 143px;
    flex-direction: column;
    gap: 2px;
    font-size: 20px;
    color: #6941c6;
  }
  .archive-wanted-software-frame-group {
    align-self: stretch;
    flex-direction: row;
    gap: 38px;
    text-align: center;
    font-size: 14px;
    color: #475467;
  }
  .archive-wanted-software-dots-vertical-container,
  .archive-wanted-software-frame-group,
  .archive-wanted-software-rectangle-container,
  .archive-wanted-software-text-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .archive-wanted-software-text-parent {
    flex: 1;
    flex-direction: column;
    gap: 8px;
  }
  .archive-wanted-software-dots-vertical-container,
  .archive-wanted-software-rectangle-container {
    flex-direction: row;
  }
  .archive-wanted-software-dots-vertical-container {
    flex: 1;
    position: relative;
    min-width: 517px;
  }
  .archive-wanted-software-rectangle-container {
    align-self: stretch;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 24px;
  }
  .archive-wanted-software-role1 {
    height: 19px;
    position: relative;
    font-size: 12px;
    line-height: 24px;
    color: #6941c6;
    display: inline-block;
    flex-shrink: 0;
    min-width: 96px;
    z-index: 2;
  }
  .archive-wanted-software-frame-parent4 {
    flex: 1;
    flex-direction: row;
    gap: 8px;
    min-width: 101px;
    color: #101828;
  }
  .archive-wanted-software-buttonsbutton-wrapper,
  .archive-wanted-software-frame-parent3,
  .archive-wanted-software-frame-parent4 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-buttonsbutton-wrapper {
    width: 90px;
    flex-direction: column;
    padding: 6px 0 0;
    box-sizing: border-box;
  }
  .archive-wanted-software-frame-parent3 {
    width: 556.4px;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 15.7px;
    max-width: calc(100% - 44px);
  }
  .archive-wanted-software-frame-child1 {
    width: 24px;
    height: 24px;
    position: relative;
    z-index: 1;
  }
  .archive-wanted-software-divider-parent,
  .archive-wanted-software-rectangle-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .archive-wanted-software-divider-parent {
    align-self: stretch;
    gap: 16px;
    font-size: 14px;
    color: #344054;
  }
  .archive-wanted-software-rectangle-group {
    width: 1016px;
    margin: 0 !important;
    /* position: absolute;
    right: 30px;
    bottom: -15px; */
    padding: 24px 24px 17px;
    box-sizing: border-box;
    gap: 24px;
    z-index: 1;
    font-size: 18px;
    color: #101828;
    top: 400px;
    height: max-content;
    
  }
  .archive-wanted-software-text47 {
    text-decoration: none;
    position: relative;
    line-height: 20px;
    font-weight: 600;
    color: inherit;
    display: inline-block;
    min-width: 51px;
  }
  .archive-wanted-software-buttonsbutton7 {
    margin: 0 !important;
    position: absolute;
    top: 173px;
    left: 45px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 13px 8px 15px;
    gap: 6px;
    z-index: 1;
  }
  .archive-wanted-software-text48 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
  }
  .archive-wanted-software-buttonsbutton8 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 8px 13px 8px 15px;
    background-color: #fff;
    margin: 0 !important;
    position: absolute;
    top: 173px;
    left: 164px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    z-index: 1;
  }
  .archive-wanted-software-text49 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 74px;
  }
  .archive-wanted-software-buttonsbutton9 {
    margin: 0 !important;
    position: absolute;
    top: 235px;
    left: 45px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 13px 8px 15px;
    gap: 5px;
    z-index: 1;
  }
  .archive-wanted-software-text50 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 90px;
  }
  .archive-wanted-software-buttonsbutton10 {
    left: 370px;
  }
  .archive-wanted-software-buttonsbutton10,
  .archive-wanted-software-buttonsbutton11,
  .archive-wanted-software-buttonsbutton12 {
    margin: 0 !important;
    position: absolute;
    top: 173px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 13px 8px 15px;
    gap: 5px;
    z-index: 1;
  }
  .archive-wanted-software-buttonsbutton11 {
    cursor: pointer;
    right: 377px;
  }
  .archive-wanted-software-buttonsbutton12 {
    right: 171px;
  }
  .archive-wanted-software-text53 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 63px;
  }
  .archive-wanted-software-buttonsbutton13 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 8px 13px 8px 15px;
    background-color: #fff;
    margin: 0 !important;
    position: absolute;
    top: 235px;
    left: 186px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    z-index: 1;
  }
  .archive-wanted-software-text54 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 93px;
  }
  .archive-wanted-software-buttonsbutton14 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 8px 13px 8px 15px;
    background-color: #fff;
    margin: 0 !important;
    position: absolute;
    top: 235px;
    left: 317px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    z-index: 1;
  }
  .archive-wanted-software-text55 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 106px;
  }
  .archive-wanted-software-buttonsbutton15 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 8px 13px 8px 15px;
    background-color: #fff;
    margin: 0 !important;
    position: absolute;
    top: 235px;
    left: 477px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    z-index: 1;
  }
  .archive-wanted-software-text56 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 77px;
  }
  .archive-wanted-software-buttonsbutton16 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 8px 13px 8px 15px;
    background-color: #fff;
    margin: 0 !important;
    position: absolute;
    top: 173px;
    right: 26px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    z-index: 1;
  }
  .archive-wanted-software-text57 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 78px;
  }
  .archive-wanted-software-buttonsbutton17 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 8px 14px;
    background-color: #fff;
    margin: 0 !important;
    position: absolute;
    top: 235px;
    right: 309px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    z-index: 1;
  }
  .archive-wanted-software-text58 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #eaecf0;
    text-align: left;
    display: inline-block;
    min-width: 75px;
  }
  .archive-wanted-software-buttonsbutton18 {
    cursor: pointer;
    border: 0;
    padding: 10px 14px;
    background-color: #7e56d9;
    margin: 0 !important;
    position: absolute;
    top: 235px;
    right: 166px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    z-index: 1;
  }
  .archive-wanted-software-text59 {
    align-self: stretch;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #475467;
    text-align: left;
  }
  .archive-wanted-software-button,
  .archive-wanted-software-text-padding1 {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .archive-wanted-software-text-padding1 {
    align-self: stretch;
    display: flex;
    padding: 0 2px;
  }
  .archive-wanted-software-button {
    border-radius: 8px;
    overflow: hidden;
    display: none;
    padding: 10px 14px;
    gap: 4px;
  }
  .archive-wanted-software-button,
  .archive-wanted-software-button1,
  .archive-wanted-software-text60 {
    align-self: stretch;
  }
  .archive-wanted-software-text60 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #6941c6;
    text-align: left;
  }
  .archive-wanted-software-button1 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d6bbfb;
    overflow: hidden;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 13px;
    gap: 4px;
  }
  .archive-wanted-software-filter-label {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 72px;
  }
  .archive-wanted-software-actions,
  .archive-wanted-software-button2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .archive-wanted-software-button2 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    justify-content: center;
    padding: 8px 13px;
    gap: 4px;
  }
  .archive-wanted-software-actions {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    margin: 0 !important;
    position: absolute;
    top: 80px;
    right: 26px;
    justify-content: flex-start;
    gap: 12px;
    z-index: 2;
  }
  .archive-wanted-software-divider-icon6,
  .archive-wanted-software-filters {
    max-width: 100%;
    flex-shrink: 0;
  }
  .archive-wanted-software-filters {
    width: 1098px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* padding: 0 0 836px 20px; */
    box-sizing: border-box;
    position: relative;
    font-size: 14px;
    color: #344054;
    margin: auto;
    
  }
  .archive-wanted-software-divider-icon6 {
    height: 1px;
    top: 80px;
    overflow: hidden;
    z-index: 1;
  }
  .archive-wanted-software-divider-icon6,
  .archive-wanted-software-divider-icon7,
  .archive-wanted-software-listing-border {
    width: 100%;
    position: absolute;
    margin: 0 !important;
    right: 0;
    left: 0;
  }
  .archive-wanted-software-divider-icon7 {
    height: 1px;
    bottom: 127px;
    max-width: 100%;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
  }
  .archive-wanted-software-listing-border {
    height: 100%;
    top: 0;
    bottom: 0;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
  }
  .archive-wanted-software-text-group,
  .archive-wanted-software-work-type {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-work-type {
    flex-direction: row;
    gap: 20px;
    font-size: 16px;
    color: #475467;
  }
  .archive-wanted-software-text-group {
    flex-direction: column;
    gap: 8px;
    max-width: 100%;
  }
  .archive-wanted-software-supporting-text8 {
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    min-width: 104px;
    z-index: 1;
  }
  .archive-wanted-software-last-active,
  .archive-wanted-software-name-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-last-active {
    font-size: 14px;
    color: #101828;
  }
  .archive-wanted-software-name-container {
    gap: 16px;
  }
  .archive-wanted-software-supporting-text9 {
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    min-width: 113px;
    z-index: 1;
  }
  .archive-wanted-software-applications,
  .archive-wanted-software-provider-name {
    flex: 1;
    display: flex;
    justify-content: flex-start;
  }
  .archive-wanted-software-applications {
    flex-direction: column;
    align-items: flex-start;
    min-width: 151px;
    font-size: 14px;
    color: #101828;
  }
  .archive-wanted-software-provider-name {
    flex-direction: row;
    align-items: flex-end;
    gap: 10px;
    min-width: 272px;
    max-width: 100%;
  }
  .archive-wanted-software-text64 {
    align-self: stretch;
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 1;
  }
  .archive-wanted-software-supporting-text10 {
    width: 113px;
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    z-index: 1;
  }
  .archive-wanted-software-invites {
    width: 233px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 14px;
    color: #101828;
  }
  .archive-wanted-software-card-actions,
  .archive-wanted-software-provider-details {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  .archive-wanted-software-provider-details {
    align-self: stretch;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    align-content: flex-end;
    gap: 34px;
    font-size: 16px;
    color: #fff;
  }
  .archive-wanted-software-card-actions {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  .archive-wanted-software-dots-vertical-icon2 {
    height: 20px;
    width: 20px;
    position: absolute;
    margin: 0 !important;
    top: -19px;
    right: -10px;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
  }
  .archive-wanted-software-text65 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 41px;
    z-index: 1;
  }
  .archive-wanted-software-financial-details {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0 21px 0 20px;
  }
  .archive-wanted-software-price2,
  .archive-wanted-software-rate {
    position: relative;
    display: inline-block;
  }
  .archive-wanted-software-price2 {
    flex: 1;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    min-width: 93px;
    white-space: nowrap;
    z-index: 2;
  }
  .archive-wanted-software-rate {
    line-height: 20px;
    min-width: 15px;
    z-index: 1;
  }
  .archive-wanted-software-daily-rate-amount {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px 0 0;
    font-size: 12px;
    color: #475467;
  }
  .archive-wanted-software-price3 {
    flex: 1;
    position: relative;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    display: inline-block;
    min-width: 93px;
    white-space: nowrap;
    z-index: 1;
  }
  .archive-wanted-software-price-group {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 7.5px;
    font-size: 20px;
    color: #6941c6;
  }
  .archive-wanted-software-text66 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 83px;
    z-index: 3;
  }
  .archive-wanted-software-deal-icons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 4px 0 3px;
  }
  .archive-wanted-software-deal-container {
    flex-direction: column;
    gap: 4px;
  }
  .archive-wanted-software-card-actions1,
  .archive-wanted-software-deal-container,
  .archive-wanted-software-listing-border-parent,
  .archive-wanted-software-listing-card-actions {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-card-actions1 {
    align-self: stretch;
    flex-direction: row;
    position: relative;
  }
  .archive-wanted-software-listing-border-parent,
  .archive-wanted-software-listing-card-actions {
    box-sizing: border-box;
  }
  .archive-wanted-software-listing-card-actions {
    width: 316px;
    flex-direction: column;
    padding: 9px 0 0;
    margin-left: -33px;
    text-align: center;
    font-size: 12px;
    color: #475467;
  }
  .archive-wanted-software-listing-border-parent {
    width: 1016px;
    margin: 0 !important;
    position: absolute;
    right: -24px;
    bottom: 33px;
    flex-direction: row;
    padding: 24px;
    z-index: 1;
  }
  .archive-wanted-software-collapsed-image {
    height: 160px;
    width: 160px;
    position: absolute;
    margin: 0 !important;
    bottom: 0;
    left: 0;
    border-radius: 12px;
    background-color: #f5f5f5;
    z-index: 2;
  }
  .archive-wanted-software-f4b711e503bc72fe396e5043e0c2-1-icon {
    height: 84px;
    width: 84px;
    position: absolute;
    margin: 0 !important;
    bottom: 38px;
    left: 38px;
    object-fit: cover;
    z-index: 3;
  }
  .archive-wanted-software-collapsed-names-child {
    height: 52px;
    width: 52px;
    position: relative;
    object-fit: cover;
    z-index: 2;
  }
  .archive-wanted-software-name3,
  .archive-wanted-software-role2 {
    position: relative;
    display: inline-block;
  }
  .archive-wanted-software-name3 {
    line-height: 23px;
    font-weight: 600;
    min-width: 77px;
    z-index: 2;
  }
  .archive-wanted-software-role2 {
    align-self: stretch;
    height: 19px;
    font-size: 12px;
    line-height: 24px;
    color: #6941c6;
    flex-shrink: 0;
    z-index: 3;
  }
  .archive-wanted-software-collapsed-name-details,
  .archive-wanted-software-collapsed-names {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-collapsed-name-details {
    flex: 1;
    flex-direction: column;
    padding: 3px 0 0;
  }
  .archive-wanted-software-collapsed-names {
    align-self: stretch;
    flex-direction: row;
    gap: 8px;
  }
  .archive-wanted-software-collapsed-name-role,
  .archive-wanted-software-collapsed-names-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-collapsed-names-parent {
    align-self: stretch;
    gap: 254px;
  }
  .archive-wanted-software-collapsed-name-role {
    width: 166px;
    padding: 153px 0 0;
    box-sizing: border-box;
  }
  .archive-wanted-software-detail-header,
  .archive-wanted-software-details-image {
    position: absolute;
    margin: 0 !important;
  }
  .archive-wanted-software-detail-header {
    top: 262px;
    right: -124px;
    line-height: 28px;
    font-weight: 600;
    z-index: 2;
  }
  .archive-wanted-software-details-image {
    height: 208px;
    width: 1016px;
    right: -306px;
    bottom: -24px;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    z-index: 1;
  }
  .archive-wanted-software-detail-header1,
  .archive-wanted-software-divider-icon8 {
    position: absolute;
    margin: 0 !important;
    z-index: 2;
  }
  .archive-wanted-software-detail-header1 {
    bottom: 132px;
    left: 13px;
    line-height: 28px;
    font-weight: 600;
  }
  .archive-wanted-software-divider-icon8 {
    height: 1px;
    width: 968px;
    right: -282px;
    bottom: 70px;
  }
  .archive-wanted-software-badge15 {
    flex: 1;
    border-radius: 9999px;
    background-color: #eceef1;
    border: 1px solid #98a2b3;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 8px;
    gap: 6px;
    mix-blend-mode: normal;
    z-index: 2;
  }
  .archive-wanted-software-supporting-text11 {
    position: relative;
    font-size: 16px;
    letter-spacing: -0.17px;
    line-height: 24px;
    color: #475467;
    text-align: left;
    display: inline-block;
    min-width: 83px;
    z-index: 2;
  }
  .archive-wanted-software-badge-value {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }
  .archive-wanted-software-second-value {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 41px;
  }
  .archive-wanted-software-second-badge-count {
    width: 50px;
    border-radius: 8px;
    background-color: #fdb022;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1px 4px;
    box-sizing: border-box;
    z-index: 2;
  }
  .archive-wanted-software-supporting-text12 {
    position: relative;
    line-height: 24px;
    display: inline-block;
    min-width: 117px;
    z-index: 2;
  }
  .archive-wanted-software-third-badge-count {
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  .archive-wanted-software-badge16,
  .archive-wanted-software-first-badge {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-badge16 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 2;
    color: #344054;
  }
  .archive-wanted-software-first-badge {
    width: 181px;
    flex-direction: column;
    gap: 15px;
    min-width: 181px;
  }
  .archive-wanted-software-marker-pin-02-icon2 {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
  }
  .archive-wanted-software-location2,
  .archive-wanted-software-type-of-work3 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 124px;
    z-index: 2;
  }
  .archive-wanted-software-type-of-work3 {
    min-width: 109px;
  }
  .archive-wanted-software-location-content,
  .archive-wanted-software-location-details {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .archive-wanted-software-location-content {
    justify-content: flex-start;
    gap: 20px;
  }
  .archive-wanted-software-location-details {
    justify-content: flex-end;
    padding: 0 5px;
  }
  .archive-wanted-software-badge-count {
    width: 61px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 61px;
  }
  .archive-wanted-software-badge17,
  .archive-wanted-software-badge18 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    padding: 0 7px;
    z-index: 2;
  }
  .archive-wanted-software-badge17 {
    width: 77px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    white-space: nowrap;
  }
  .archive-wanted-software-badge18 {
    width: 76px;
  }
  .archive-wanted-software-badge-container,
  .archive-wanted-software-badge18,
  .archive-wanted-software-badge19 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-badge19 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 2;
  }
  .archive-wanted-software-badge-container {
    align-self: stretch;
    gap: 8px;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  .archive-wanted-software-location-details-parent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 56px;
    min-width: 217px;
    max-width: 100%;
    margin-left: -1px;
    text-align: left;
    font-size: 16px;
    color: #475467;
  }
  .archive-wanted-software-badge-name,
  .archive-wanted-software-buttonsbutton19,
  .archive-wanted-software-buttonsbutton20,
  .archive-wanted-software-buttonsbutton21 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-badge-name {
    flex: 1;
    max-width: 100%;
    row-gap: 20px;
  }
  .archive-wanted-software-buttonsbutton19,
  .archive-wanted-software-buttonsbutton20,
  .archive-wanted-software-buttonsbutton21 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    z-index: 2;
  }
  .archive-wanted-software-buttonsbutton19 {
    cursor: pointer;
    padding: 6px 12px;
    width: 90px;
    overflow: hidden;
    flex-shrink: 0;
    gap: 6px;
  }
  .archive-wanted-software-buttonsbutton20,
  .archive-wanted-software-buttonsbutton21 {
    flex: 1;
    overflow-x: auto;
    padding: 6px 11px;
    gap: 5.5px;
    min-width: 86px;
  }
  .archive-wanted-software-buttonsbutton21 {
    padding: 6px 10px 6px 12px;
    gap: 5.4px;
  }
  .archive-wanted-software-buttonsbutton-parent {
    width: 385.4px;
    flex-direction: row;
    gap: 16px;
    text-align: left;
    color: #344054;
  }
  .archive-wanted-software-badge-details,
  .archive-wanted-software-buttonsbutton-parent,
  .archive-wanted-software-divider-group {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .archive-wanted-software-badge-details {
    flex: 1;
    flex-direction: column;
    padding: 0 0 8px;
    box-sizing: border-box;
    gap: 47px;
  }
  .archive-wanted-software-divider-group {
    align-self: stretch;
    flex-direction: row;
    position: relative;
    text-align: center;
    font-size: 14px;
    color: #98a2b3;
  }
  .archive-wanted-software-divider-icon9 {
    height: 1px;
    width: 968px;
    position: absolute;
    margin: 0 !important;
    right: -282px;
    bottom: 62px;
    z-index: 2;
  }
  .archive-wanted-software-name-value {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 51px;
  }
  .archive-wanted-software-badge20 {
    width: 69px;
    border-radius: 9999px;
    background-color: #fffdfd;
    border: 1px solid #da2d20;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 8px;
    gap: 6px;
    mix-blend-mode: normal;
    z-index: 2;
  }
  .archive-wanted-software-badge22,
  .archive-wanted-software-first-badge-content {
    flex: 1;
    justify-content: flex-start;
  }
  .archive-wanted-software-first-badge-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    min-width: 114px;
    text-align: center;
    font-size: 14px;
    color: #da2d20;
  }
  .archive-wanted-software-badge22 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 2;
  }
  .archive-wanted-software-badge-list-item,
  .archive-wanted-software-badge22,
  .archive-wanted-software-badge23 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .archive-wanted-software-badge23 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 2;
  }
  .archive-wanted-software-badge-list-item {
    align-self: stretch;
    justify-content: flex-end;
    padding: 0 2px 0 0;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  .archive-wanted-software-location-item,
  .archive-wanted-software-work-calendar {
    display: flex;
    justify-content: flex-start;
  }
  .archive-wanted-software-location-item {
    width: 163px;
    flex-direction: column;
    align-items: flex-end;
    gap: 56px;
  }
  .archive-wanted-software-work-calendar {
    flex-direction: row;
    align-items: flex-start;
    padding: 0 14px;
  }
  .archive-wanted-software-work-type-value {
    width: 149px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
  }
  .archive-wanted-software-badge24,
  .archive-wanted-software-buttonsbutton23,
  .archive-wanted-software-first-badge-details,
  .archive-wanted-software-work-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-badge24 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 2;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  .archive-wanted-software-buttonsbutton23,
  .archive-wanted-software-first-badge-details,
  .archive-wanted-software-work-item {
    flex: 1;
  }
  .archive-wanted-software-work-item {
    flex-direction: column;
    gap: 56px;
    min-width: 107px;
  }
  .archive-wanted-software-buttonsbutton23,
  .archive-wanted-software-first-badge-details {
    flex-direction: row;
    gap: 5.5px;
  }
  .archive-wanted-software-first-badge-details {
    max-width: 100%;
  }
  .archive-wanted-software-buttonsbutton23 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    padding: 6px 11px;
    min-width: 86px;
    z-index: 2;
  }
  .archive-wanted-software-buttonsbutton-group {
    width: 385.4px;
    flex-direction: row;
    gap: 16px;
    font-size: 14px;
    color: #344054;
  }
  .archive-wanted-software-buttonsbutton-group,
  .archive-wanted-software-second-badge-divider,
  .archive-wanted-software-second-badge-list-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .archive-wanted-software-second-badge-list-parent {
    flex: 1;
    flex-direction: column;
    gap: 47px;
  }
  .archive-wanted-software-second-badge-divider {
    align-self: stretch;
    flex-direction: row;
    position: relative;
  }
  .archive-wanted-software-supporting-text15 {
    position: relative;
    letter-spacing: -0.06px;
    line-height: 24px;
    z-index: 2;
  }
  .archive-wanted-software-supporting-text17 {
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    min-width: 104px;
    z-index: 3;
  }
  .archive-wanted-software-summary-content-parent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    min-width: 114px;
  }
  .archive-wanted-software-price-details-title {
    align-self: stretch;
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 2;
  }
  .archive-wanted-software-supporting-text18 {
    width: 104px;
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    z-index: 3;
  }
  .archive-wanted-software-summary-location-name {
    align-self: stretch;
    flex-direction: row;
    padding: 0 0 0 1px;
    font-size: 14px;
    color: #101828;
  }
  .archive-wanted-software-location-summary,
  .archive-wanted-software-summary-details,
  .archive-wanted-software-summary-location-name {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-location-summary {
    width: 152px;
    flex-direction: column;
    gap: 58px;
    min-width: 152px;
  }
  .archive-wanted-software-summary-details {
    flex: 1;
    flex-direction: row;
    gap: 8px;
    min-width: 218px;
    max-width: 100%;
  }
  .archive-wanted-software-third-badge-details {
    width: 490px;
    flex-direction: row;
    padding: 0 13px;
    box-sizing: border-box;
    max-width: 100%;
  }
  .archive-wanted-software-badge25,
  .archive-wanted-software-frame-parent5,
  .archive-wanted-software-third-badge-details {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-frame-parent5 {
    flex: 1;
    flex-direction: column;
    gap: 107px;
    max-width: 100%;
    font-size: 16px;
    color: #475467;
  }
  .archive-wanted-software-badge25 {
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    padding: 0 7px;
    z-index: 2;
  }
  .archive-wanted-software-first-badges {
    align-self: stretch;
    flex-direction: column;
    gap: 282px;
  }
  .archive-wanted-software-expanded-content,
  .archive-wanted-software-expanded-listing,
  .archive-wanted-software-first-badges,
  .archive-wanted-software-work-type-badge-list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-work-type-badge-list {
    width: 93px;
    flex-direction: column;
    padding: 80px 0 0;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  .archive-wanted-software-expanded-content,
  .archive-wanted-software-expanded-listing {
    max-width: 100%;
  }
  .archive-wanted-software-expanded-content {
    align-self: stretch;
    flex-direction: row;
    gap: 8px;
  }
  .archive-wanted-software-expanded-listing {
    flex: 1;
    flex-direction: column;
    padding: 8px 33px 0 0;
    box-sizing: border-box;
    min-width: 422px;
    font-size: 18px;
  }
  .archive-wanted-software-arrow-square-up-right-icon1 {
    height: 24px;
    width: 24px;
    top: 167px;
    right: 0;
  }
  .archive-wanted-software-arrow-square-up-right-icon1,
  .archive-wanted-software-arrow-square-up-right-icon2,
  .archive-wanted-software-dots-vertical-icon3,
  .archive-wanted-software-dots-vertical-icon4 {
    position: absolute;
    margin: 0 !important;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
  }
  .archive-wanted-software-arrow-square-up-right-icon2 {
    height: 24px;
    width: 24px;
    right: 0;
    bottom: 206px;
  }
  .archive-wanted-software-dots-vertical-icon3,
  .archive-wanted-software-dots-vertical-icon4 {
    height: 20px;
    width: 20px;
    right: -10px;
  }
  .archive-wanted-software-dots-vertical-icon3 {
    top: 260px;
  }
  .archive-wanted-software-dots-vertical-icon4 {
    bottom: 117px;
  }
  .archive-wanted-software-price4 {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    white-space: nowrap;
    z-index: 2;
  }
  .archive-wanted-software-radar-1-1-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    z-index: 2;
  }
  .archive-wanted-software-text81 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 29px;
    z-index: 3;
  }
  .archive-wanted-software-text-wrapper3 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 57px;
  }
  .archive-wanted-software-price6 {
    align-self: stretch;
    position: relative;
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    color: #6941c6;
    white-space: nowrap;
    z-index: 2;
  }
  .archive-wanted-software-frame-parent7 {
    align-self: stretch;
    flex-direction: column;
    font-size: 12px;
    color: #475467;
  }
  .archive-wanted-software-arrow-square-up-right-parent,
  .archive-wanted-software-frame-parent6,
  .archive-wanted-software-frame-parent7 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-frame-parent6 {
    flex: 1;
    flex-direction: column;
    gap: 229px;
  }
  .archive-wanted-software-arrow-square-up-right-parent {
    width: 143px;
    flex-direction: row;
    position: relative;
    text-align: center;
    font-size: 20px;
    color: #6941c6;
  }
  .archive-wanted-software-collapsed-details {
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 5px;
  }
  .archive-wanted-software-collapsed-content,
  .archive-wanted-software-collapsed-details,
  .archive-wanted-software-collapsed-listing {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .archive-wanted-software-collapsed-content {
    position: relative;
  }
  .archive-wanted-software-collapsed-listing {
    padding: 0 0 521px 24px;
    box-sizing: border-box;
    font-size: 14px;
  }
  .archive-wanted-software-dots-vertical-icon5 {
    height: 20px;
    width: 20px;
    position: absolute;
    margin: 0 !important;
    top: 14px;
    right: 14px;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
  }
  .archive-wanted-software-f4b711e503bc72fe396e5043e0c2-1-icon1 {
    height: 84px;
    width: 84px;
    position: absolute;
    margin: 0 !important;
    top: calc(50% - 42px);
    left: 62px;
    object-fit: cover;
    z-index: 2;
  }
  .archive-wanted-software-business-image,
  .archive-wanted-software-text83 {
    position: relative;
    z-index: 1;
  }
  .archive-wanted-software-business-image {
    height: 160px;
    width: 160px;
    border-radius: 12px;
    background-color: #f5f5f5;
  }
  .archive-wanted-software-text83 {
    line-height: 28px;
    font-weight: 600;
    display: inline-block;
    min-width: 128px;
  }
  .archive-wanted-software-text-wrapper4 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 8px 0 0;
    font-size: 18px;
    color: #101828;
  }
  .archive-wanted-software-icon9 {
    width: 12px;
    height: 9.3px;
    position: relative;
    z-index: 1;
  }
  .archive-wanted-software-business-verified {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 0 0;
  }
  .archive-wanted-software-span {
    font-weight: 500;
  }
  .archive-wanted-software-supporting-text19 {
    height: 19px;
    position: relative;
    line-height: 24px;
    display: inline-block;
    flex-shrink: 0;
    min-width: 63px;
    z-index: 1;
  }
  .archive-wanted-software-business-role {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px 16px 0 0;
  }
  .archive-wanted-software-rating-child {
    width: 16px;
    height: 16px;
    position: relative;
    z-index: 1;
  }
  .archive-wanted-software-supporting-text20 {
    height: 19px;
    position: relative;
    line-height: 24px;
    display: inline-block;
    flex-shrink: 0;
    min-width: 70.9px;
    z-index: 1;
  }
  .archive-wanted-software-reviews {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px 0 0;
  }
  .archive-wanted-software-application-count,
  .archive-wanted-software-business-location,
  .archive-wanted-software-business-name-role {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  .archive-wanted-software-application-count,
  .archive-wanted-software-business-location {
    gap: 7px;
    font-size: 16px;
  }
  .archive-wanted-software-application-count {
    gap: 8px;
    color: #fff;
  }
  .archive-wanted-software-badge-one,
  .archive-wanted-software-badge-three,
  .archive-wanted-software-badge-two {
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 1;
  }
  .archive-wanted-software-badge-three,
  .archive-wanted-software-badge-two {
    display: inline-block;
  }
  .archive-wanted-software-badge-two {
    min-width: 103px;
  }
  .archive-wanted-software-badge-three {
    width: 151px;
    flex-shrink: 0;
  }
  .archive-wanted-software-badges1 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 34px;
  }
  .archive-wanted-software-supporting-text22 {
    position: relative;
    line-height: 19px;
    display: inline-block;
    min-width: 92.1px;
    z-index: 2;
  }
  .archive-wanted-software-badge-support {
    width: 161.9px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 20px 0 0;
    box-sizing: border-box;
  }
  .archive-wanted-software-supporting-text23,
  .archive-wanted-software-supporting-text24 {
    position: relative;
    line-height: 19px;
    display: inline-block;
    flex-shrink: 0;
    z-index: 2;
  }
  .archive-wanted-software-supporting-text23 {
    min-width: 70.9px;
  }
  .archive-wanted-software-supporting-text24 {
    width: 104px;
  }
  .archive-wanted-software-badge-support-parent {
    width: 469px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    max-width: 100%;
    font-size: 12px;
    color: #475467;
  }
  .archive-wanted-software-badges-parent {
    align-self: stretch;
    flex-direction: column;
    font-size: 14px;
    color: #101828;
  }
  .archive-wanted-software-badges-parent,
  .archive-wanted-software-business-details,
  .archive-wanted-software-business-listing {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .archive-wanted-software-business-details {
    flex: 1;
    flex-direction: column;
    gap: 16px;
    min-width: 335px;
  }
  .archive-wanted-software-business-listing {
    width: 700px;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 24px;
  }
  .archive-wanted-software-text84 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 55px;
  }
  .archive-wanted-software-buttonsbutton25 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 6px 12px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    z-index: 1;
  }
  .archive-wanted-software-business-actions,
  .archive-wanted-software-listing-card-my-radar-bus {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 60px 0 0;
  }
  .archive-wanted-software-listing-card-my-radar-bus {
    width: 1016px;
    margin: 0 !important;
    position: absolute;
    right: -24px;
    bottom: 265px;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 40px 24px 24px;
    box-sizing: border-box;
    gap: 20px;
    z-index: 1;
    font-size: 12px;
    color: #475467;
  }
  .archive-wanted-software-text85 {
    position: relative;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: center;
    display: inline-block;
    min-width: 66px;
  }
  .archive-wanted-software-content6 {
    cursor: pointer;
    border: 0;
    padding: 8px 12px;
    background-color: #0c111d;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    white-space: nowrap;
  }
  .archive-wanted-software-content6:hover {
    background-color: #40454f;
  }
  .archive-wanted-software-tooltip-icon {
    width: 28px;
    height: 6px;
    position: relative;
  }
  .archive-wanted-software-tooltip {
    margin: 0 !important;
    position: absolute;
    top: 119px;
    right: 0;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
      0 4px 6px -2px rgba(16, 24, 40, 0.03);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    z-index: 3;
  }
  .archive-wanted-software-arrow-square-up-right-icon3,
  .archive-wanted-software-arrow-square-up-right-icon4,
  .archive-wanted-software-arrow-square-up-right-icon5 {
    height: 24px;
    width: 24px;
    position: absolute;
    margin: 0 !important;
    right: 0;
    bottom: 511px;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
  }
  .archive-wanted-software-arrow-square-up-right-icon4,
  .archive-wanted-software-arrow-square-up-right-icon5 {
    bottom: 298px;
    z-index: 3;
  }
  .archive-wanted-software-arrow-square-up-right-icon5 {
    bottom: 66px;
    z-index: 4;
  }
  .archive-wanted-software-list-header {
    width: 992px;
    position: relative;
    max-width: 100%;
  }
  .archive-wanted-software-frame-parent11,
  .archive-wanted-software-group-div,
  .archive-wanted-software-list-header,
  .archive-wanted-software-text-parent4 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-text-parent4 {
    gap: 16px;
    font-size: 18px;
    color: #101828;
  }
  .archive-wanted-software-frame-parent11,
  .archive-wanted-software-group-div {
    gap: 7.3px;
  }
  .archive-wanted-software-group-div {
    width: 1016px;
    margin: 0 !important;
    position: absolute;
    bottom: 64px;
    left: calc(50% - 508px);
    justify-content: space-between;
    padding: 24px 40px 24px 24px;
    box-sizing: border-box;
    gap: 20px;
    z-index: 1;
  }
  .archive-wanted-software-divider-icon10 {
    height: 1px;
    width: 100%;
    top: 175px;
    right: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
  }
  .archive-wanted-software-divider-icon10,
  .archive-wanted-software-dots-vertical-icon7,
  .archive-wanted-software-text92 {
    position: absolute;
    margin: 0 !important;
    z-index: 2;
  }
  .archive-wanted-software-text92 {
    top: -9px;
    left: 0;
    line-height: 28px;
    font-weight: 600;
  }
  .archive-wanted-software-dots-vertical-icon7 {
    height: 20px;
    width: 20px;
    top: -19px;
    right: -10px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .archive-wanted-software-text93 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 36px;
    z-index: 2;
  }
  .archive-wanted-software-dots-vertical-parent1,
  .archive-wanted-software-text-wrapper7 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-text-wrapper7 {
    flex: 1;
  }
  .archive-wanted-software-dots-vertical-parent1 {
    width: 177px;
    position: relative;
  }
  .archive-wanted-software-rectangle-textarea,
  .archive-wanted-software-text94 {
    position: absolute;
    margin: 0 !important;
  }
  .archive-wanted-software-text94 {
    bottom: 132px;
    left: 184px;
    line-height: 28px;
    font-weight: 600;
    z-index: 2;
  }
  .archive-wanted-software-rectangle-textarea {
    border: 1px solid #eaecf0;
    background-color: #fff;
    height: 301px;
    width: 1016px;
    outline: 0;
    top: -60px;
    right: -307px;
    border-radius: 12px;
    box-sizing: border-box;
    z-index: 1;
  }
  .archive-wanted-software-supporting-text31 {
    position: relative;
    letter-spacing: -0.17px;
    line-height: 24px;
    display: inline-block;
    min-width: 83px;
    z-index: 2;
  }
  .archive-wanted-software-price-label {
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 2;
  }
  .archive-wanted-software-supporting-text34 {
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    min-width: 113px;
    z-index: 3;
  }
  .archive-wanted-software-contact-details,
  .archive-wanted-software-detail-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .archive-wanted-software-detail-item {
    align-items: flex-end;
    gap: 10px;
    max-width: 100%;
  }
  .archive-wanted-software-contact-details {
    flex: 1;
    align-items: flex-start;
    gap: 8px;
    min-width: 104px;
  }
  .archive-wanted-software-text95 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 70px;
  }
  .archive-wanted-software-buttonsbutton27,
  .archive-wanted-software-buttonsbutton28,
  .archive-wanted-software-contact-actions {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-buttonsbutton27 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 6px 12px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    flex-direction: row;
    gap: 6px;
    z-index: 2;
  }
  .archive-wanted-software-buttonsbutton28,
  .archive-wanted-software-contact-actions {
    flex-direction: column;
    padding: 6px 5px 0 0;
  }
  .archive-wanted-software-buttonsbutton28 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow-x: auto;
    flex-direction: row;
    padding: 6px 11px;
    gap: 5.5px;
    z-index: 2;
  }
  .archive-wanted-software-lock-action1 {
    flex-direction: column;
    padding: 6px 0 0;
    color: #344054;
  }
  .archive-wanted-software-contact-details-parent,
  .archive-wanted-software-detail-item-parent,
  .archive-wanted-software-lock-action1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-contact-details-parent {
    align-self: stretch;
    flex-direction: row;
    gap: 11px;
    font-size: 14px;
    color: #101828;
  }
  .archive-wanted-software-detail-item-parent {
    flex: 1;
    flex-direction: column;
    gap: 41px;
    min-width: 289px;
    max-width: 100%;
  }
  .archive-wanted-software-supporting-text35 {
    width: 113px;
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    z-index: 3;
  }
  .archive-wanted-software-buttonsbutton-wrapper2,
  .archive-wanted-software-buttonsbutton29 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-buttonsbutton29 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow-x: auto;
    padding: 6px 10px 6px 12px;
    gap: 5.4px;
    z-index: 2;
  }
  .archive-wanted-software-buttonsbutton-wrapper2 {
    padding: 0 8px;
    color: #344054;
  }
  .archive-wanted-software-frame-parent22,
  .archive-wanted-software-second-listing-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .archive-wanted-software-frame-parent22 {
    align-self: stretch;
    justify-content: flex-start;
    gap: 47px;
  }
  .archive-wanted-software-second-listing-details {
    width: 233px;
    justify-content: flex-end;
    padding: 0 0 6px;
    box-sizing: border-box;
    min-width: 233px;
    font-size: 14px;
    color: #101828;
  }
  .archive-wanted-software-listing-detail {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 7.3px;
    max-width: 100%;
    color: #fff;
  }
  .archive-wanted-software-pair-container-parent,
  .archive-wanted-software-third-listing-details {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .archive-wanted-software-pair-container-parent {
    justify-content: flex-start;
    gap: 8px;
    margin-left: -9px;
  }
  .archive-wanted-software-third-listing-details {
    width: 538px;
    justify-content: flex-end;
    padding: 0 37px;
    box-sizing: border-box;
    max-width: 100%;
  }
  .archive-wanted-software-frame-parent19,
  .archive-wanted-software-frame-wrapper11 {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 100%;
  }
  .archive-wanted-software-frame-parent19 {
    align-items: flex-end;
    gap: 108px;
    font-size: 16px;
    color: #475467;
  }
  .archive-wanted-software-frame-wrapper11 {
    align-items: flex-start;
    padding: 7px 0 0;
    box-sizing: border-box;
    min-width: 445px;
  }
  .archive-wanted-software-price7,
  .archive-wanted-software-total {
    position: relative;
    display: inline-block;
  }
  .archive-wanted-software-price7 {
    flex: 1;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    min-width: 93px;
    white-space: nowrap;
    z-index: 3;
  }
  .archive-wanted-software-total {
    line-height: 20px;
    min-width: 15px;
    z-index: 2;
  }
  .archive-wanted-software-price-parent2 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 7.5px;
  }
  .archive-wanted-software-key5 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 83px;
    z-index: 4;
  }
  .archive-wanted-software-radar-1-1-parent3 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    z-index: 2;
  }
  .archive-wanted-software-pair-container-container,
  .archive-wanted-software-pair-container-wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .archive-wanted-software-pair-container-wrapper {
    justify-content: center;
    padding: 0 21px 0 20px;
    font-size: 12px;
    color: #475467;
  }
  .archive-wanted-software-pair-container-container {
    justify-content: flex-start;
    padding: 0 30px;
  }
  .archive-wanted-software-frame-parent24,
  .archive-wanted-software-pair-container-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .archive-wanted-software-pair-container-group {
    width: 143px;
    align-items: flex-start;
    font-size: 12px;
    color: #475467;
  }
  .archive-wanted-software-frame-parent24 {
    flex: 1;
    align-items: flex-end;
    gap: 253px;
  }
  .archive-wanted-software-fourth-listing,
  .archive-wanted-software-frame-parent18 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .archive-wanted-software-fourth-listing {
    width: 316px;
    justify-content: flex-start;
    position: relative;
    min-width: 316px;
    margin-left: -33px;
    text-align: center;
    font-size: 20px;
    color: #6941c6;
  }
  .archive-wanted-software-frame-parent18 {
    align-self: stretch;
    justify-content: center;
    max-width: 100%;
    row-gap: 20px;
    text-align: left;
    font-size: 18px;
    color: #101828;
  }
  .archive-wanted-software-frame-parent17 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    max-width: 100%;
    text-align: center;
    font-size: 12px;
    color: #475467;
  }
  .archive-wanted-software-text99 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 72px;
  }
  .archive-wanted-software-buttonsbutton30 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    flex-direction: row;
    justify-content: flex-start;
    padding: 6px 12px;
    gap: 6px;
    z-index: 1;
  }
  .archive-wanted-software-buttonsbutton30,
  .archive-wanted-software-expand-action,
  .archive-wanted-software-frame-parent16 {
    display: flex;
    align-items: flex-start;
  }
  .archive-wanted-software-expand-action {
    align-self: stretch;
    flex-direction: row;
    justify-content: center;
    padding: 0 20px;
    font-size: 14px;
    color: #344054;
  }
  .archive-wanted-software-frame-parent16 {
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    gap: 280px;
    max-width: 100%;
    font-size: 18px;
    color: #101828;
  }
  .archive-wanted-software-arrow-square-up-right-icon6,
  .archive-wanted-software-arrow-square-up-right-icon7,
  .archive-wanted-software-arrow-square-up-right-icon8 {
    height: 24px;
    width: 24px;
    position: absolute;
    margin: 0 !important;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
  }
  .archive-wanted-software-arrow-square-up-right-icon6 {
    top: 206px;
    right: 0;
  }
  .archive-wanted-software-arrow-square-up-right-icon7,
  .archive-wanted-software-arrow-square-up-right-icon8 {
    right: -3px;
    bottom: 310px;
  }
  .archive-wanted-software-arrow-square-up-right-icon8 {
    bottom: 97px;
    z-index: 3;
  }
  .archive-wanted-software-magnifier-1-icon8 {
    height: 20px;
    width: 20px;
    position: absolute;
    margin: 0 !important;
    top: 75px;
    right: 93px;
    object-fit: cover;
    z-index: 2;
  }
  .archive-wanted-software-listing-wrapper-inner {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 25px 0 24px;
    box-sizing: border-box;
    max-width: 100%;
    font-size: 12px;
    color: #475467;
  }
  .archive-wanted-software-text100,
  .archive-wanted-software-text101 {
    position: relative;
    font-family: Inter;
  }
  .archive-wanted-software-text100 {
    margin: 0;
    font-size: 26px;
    line-height: 28px;
    font-weight: 600;
    color: #101828;
    text-align: left;
    display: inline-block;
    min-width: 128px;
  }
  .archive-wanted-software-text101 {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #6941c6;
    text-align: center;
  }
  .archive-wanted-software-badge27,
  .archive-wanted-software-text-and-badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .archive-wanted-software-badge27 {
    border-radius: 9999px;
    background-color: #f9f5ff;
    border: 1px solid #e9d7fe;
    padding: 0 7px;
    white-space: nowrap;
  }
  .archive-wanted-software-text-and-badge {
    align-self: stretch;
    padding: 0 359px 0 0;
    gap: 8px;
  }
  .archive-wanted-software-supporting-text40 {
    align-self: stretch;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-family: Inter;
    color: #475467;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .archive-wanted-software-text-and-supporting-text1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
    min-width: 424px;
    max-width: 100%;
  }
  .archive-wanted-software-text104 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 45px;
  }
  .archive-wanted-software-button5 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 8px 13px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  .archive-wanted-software-text105 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 92px;
  }
  .archive-wanted-software-button6 {
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 13px;
    background-color: #7e56d9;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  .archive-wanted-software-dots-vertical-icon9 {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .archive-wanted-software-content7 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 20px 24px 0;
    box-sizing: border-box;
    gap: 16px;
  }
  .archive-wanted-software-card-header,
  .archive-wanted-software-content7,
  .archive-wanted-software-divider-icon11 {
    align-self: stretch;
    max-width: 100%;
  }
  .archive-wanted-software-divider-icon11 {
    position: relative;
    overflow: hidden;
    max-height: 100%;
  }
  .archive-wanted-software-card-header {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    z-index: 1;
  }
  .archive-wanted-software-text106 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 51px;
  }
  .archive-wanted-software-button-group-base9 {
    width: 83px;
    background-color: #f9fafb;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
  }
  .archive-wanted-software-text107 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 65px;
  }
  .archive-wanted-software-button-group-base10,
  .archive-wanted-software-button-group-base16 {
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }
  .archive-wanted-software-button-group-base10 {
    cursor: pointer;
    border: 0;
    padding: 10px 15px;
    width: 97px;
    flex-shrink: 0;
    display: flex;
  }
  .archive-wanted-software-button-group-base10:hover {
    background-color: #e6e6e6;
  }
  .archive-wanted-software-button-group-base16 {
    align-self: stretch;
    display: none;
    padding: 8px 16px;
    z-index: 1;
  }
  .archive-wanted-software-text114 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 61px;
  }
  .archive-wanted-software-button-group-base17 {
    width: 93px;
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
  }
  .archive-wanted-software-button-group1 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-label3,
  .archive-wanted-software-supporting-text41,
  .archive-wanted-software-text115 {
    position: relative;
    font-family: Inter;
    text-align: left;
  }
  .archive-wanted-software-label3 {
    width: 47px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #344054;
    display: none;
  }
  .archive-wanted-software-supporting-text41,
  .archive-wanted-software-text115 {
    font-size: 16px;
    line-height: 24px;
  }
  .archive-wanted-software-text115 {
    width: calc(100% - 228px);
    border: 0;
    outline: 0;
    font-weight: 500;
    background-color: transparent;
    height: 24px;
    flex: 1;
    color: #101828;
    display: inline-block;
    min-width: 82px;
    padding: 0;
  }
  .archive-wanted-software-supporting-text41 {
    align-self: stretch;
    width: 54px;
    color: #475467;
    display: none;
  }
  .archive-wanted-software-content9,
  .archive-wanted-software-input-with-label2,
  .archive-wanted-software-input2 {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  .archive-wanted-software-content9 {
    flex: 1;
    flex-direction: row;
    align-items: center;
    padding: 0 208px 0 0;
    box-sizing: border-box;
    gap: 8px;
  }
  .archive-wanted-software-input-with-label2,
  .archive-wanted-software-input2 {
    align-self: stretch;
  }
  .archive-wanted-software-input2 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
      0 0 0 4px rgba(158, 119, 237, 0.24);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d6bbfb;
    box-sizing: border-box;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    padding: 8px 13px;
  }
  .archive-wanted-software-input-with-label2 {
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }
  .archive-wanted-software-hint-text2 {
    width: 320px;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-family: Inter;
    color: #475467;
    text-align: left;
    display: none;
  }
  .archive-wanted-software-input-dropdown2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    max-width: 400px;
  }
  .archive-wanted-software-text116 {
    text-decoration: none;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 43px;
  }
  .archive-wanted-software-actions2,
  .archive-wanted-software-button7,
  .archive-wanted-software-content8 {
    display: flex;
    flex-direction: row;
  }
  .archive-wanted-software-button7 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 10px 13px;
    background-color: #fff;
    width: 99px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  .archive-wanted-software-actions2,
  .archive-wanted-software-content8 {
    max-width: 100%;
  }
  .archive-wanted-software-actions2 {
    width: 511px;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  }
  .archive-wanted-software-content8 {
    align-self: stretch;
    border-radius: 12px;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 16px;
    box-sizing: border-box;
    gap: 20px;
  }
  .archive-wanted-software-filters-bar {
    background-color: #fff;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    z-index: 2;
  }
  .archive-wanted-software-card-header-parent,
  .archive-wanted-software-filters-bar {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .archive-wanted-software-text117,
  .archive-wanted-software-text118 {
    position: relative;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    font-family: Inter;
    color: #101828;
    text-align: left;
  }
  .archive-wanted-software-text118 {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #6941c6;
    text-align: center;
    display: inline-block;
    min-width: 56px;
  }
  .archive-wanted-software-badge28,
  .archive-wanted-software-text-and-badge1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .archive-wanted-software-badge28 {
    width: 72px;
    border-radius: 9999px;
    background-color: #f9f5ff;
    border: 1px solid #e9d7fe;
    box-sizing: border-box;
    padding: 0 7px;
    white-space: nowrap;
  }
  .archive-wanted-software-text-and-badge1 {
    align-self: stretch;
    padding: 0 666px 0 0;
    gap: 8px;
  }
  .archive-wanted-software-supporting-text42 {
    width: 697px;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-family: Inter;
    color: #475467;
    text-align: left;
    display: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }
  .archive-wanted-software-button8,
  .archive-wanted-software-button9,
  .archive-wanted-software-text-and-supporting-text2 {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    gap: 4px;
  }
  .archive-wanted-software-text-and-supporting-text2 {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 0;
    max-width: 100%;
  }
  .archive-wanted-software-button8,
  .archive-wanted-software-button9 {
    height: 40px;
    border-radius: 8px;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    padding: 10px 14px;
  }
  .archive-wanted-software-button9 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    background-color: #fff;
    border: 1px solid #d6bbfb;
    padding: 10px 13px;
  }
  .archive-wanted-software-text121 {
    align-self: stretch;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
  }
  .archive-wanted-software-button10 {
    height: 40px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 13px;
    gap: 4px;
  }
  .archive-wanted-software-text122 {
    align-self: stretch;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
  }
  .archive-wanted-software-button11 {
    height: 40px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #7e56d9;
    border: 1px solid #7e56d9;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 13px;
    gap: 4px;
  }
  .archive-wanted-software-actions3,
  .archive-wanted-software-card-header1 {
    justify-content: flex-start;
    max-width: 100%;
  }
  .archive-wanted-software-actions3 {
    display: none;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
  .archive-wanted-software-card-header1 {
    align-self: stretch;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    flex-shrink: 0;
  }
  .archive-wanted-software-checkbox-base {
    margin: 0;
    height: 20px;
    width: 20px;
    position: relative;
    border-radius: 6px;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
  }
  .archive-wanted-software-text123 {
    position: relative;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    font-family: Inter;
    color: #475467;
    text-align: left;
    display: inline-block;
    min-width: 34px;
  }
  .archive-wanted-software-arrow-down-icon {
    width: 16px;
    height: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .archive-wanted-software-table-header-cell,
  .archive-wanted-software-table-header-cell-inner,
  .archive-wanted-software-text-parent9 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-text-parent9 {
    flex-direction: row;
    gap: 4px;
  }
  .archive-wanted-software-table-header-cell,
  .archive-wanted-software-table-header-cell-inner {
    flex-direction: column;
    padding: 1px 0 0;
  }
  .archive-wanted-software-table-header-cell {
    width: 441px;
    margin: 0 !important;
    position: absolute;
    top: 0;
    left: -380px;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    flex-direction: row;
    padding: 12px 24px 10px;
    gap: 12px;
  }
  .archive-wanted-software-checkbox-base1 {
    margin: 0;
    width: 20px;
    height: 20px;
    position: relative;
    border-radius: 6px;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
  }
  .archive-wanted-software-checkbox-base-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 0 0;
  }
  .archive-wanted-software-avatar-online-indicator,
  .archive-wanted-software-contrast-border1 {
    position: absolute;
    border-radius: 9999px;
    box-sizing: border-box;
    overflow: hidden;
  }
  .archive-wanted-software-contrast-border1 {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 0.8px solid rgba(0, 0, 0, 0.08);
    display: none;
  }
  .archive-wanted-software-avatar-online-indicator {
    right: -1.5px;
    bottom: -1.5px;
    background-color: #17b26a;
    border: 1.5px solid #fff;
    width: 13px;
    height: 13px;
    z-index: 1;
  }
  .archive-wanted-software-avatar1 {
    height: 40px;
    width: 40px;
    position: relative;
    border-radius: 9999px;
    background-image: url("../../../icons/Gerator/archive_page/avatar1@3x.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  .archive-wanted-software-supporting-text43,
  .archive-wanted-software-text124 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-family: Inter;
    text-align: left;
    display: inline-block;
  }
  .archive-wanted-software-text124 {
    font-weight: 500;
    color: #101828;
    min-width: 75px;
  }
  .archive-wanted-software-supporting-text43 {
    color: #475467;
    min-width: 102px;
    white-space: nowrap;
  }
  .archive-wanted-software-table-cell {
    width: 405px;
    margin: 0 !important;
    position: absolute;
    top: 44px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  .archive-wanted-software-text125 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    color: #101828;
    text-align: left;
    display: inline-block;
    min-width: 96px;
  }
  .archive-wanted-software-table-cell1 {
    width: 467px;
    margin: 0 !important;
    position: absolute;
    top: 116px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  .archive-wanted-software-text126 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    color: #101828;
    text-align: left;
    display: inline-block;
    min-width: 84px;
  }
  .archive-wanted-software-table-cell2 {
    width: 428px;
    margin: 0 !important;
    position: absolute;
    top: 188px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  .archive-wanted-software-supporting-text46,
  .archive-wanted-software-text127 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-family: Inter;
    text-align: left;
    display: inline-block;
  }
  .archive-wanted-software-text127 {
    font-weight: 500;
    color: #101828;
    min-width: 103px;
    white-space: nowrap;
  }
  .archive-wanted-software-supporting-text46 {
    color: #475467;
    min-width: 102px;
  }
  .archive-wanted-software-table-cell3 {
    width: 411px;
    margin: 0 !important;
    position: absolute;
    top: 260px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  .archive-wanted-software-text128 {
    flex: 1;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #667085;
    text-align: center;
    z-index: 1;
  }
  .archive-wanted-software-avatar5,
  .archive-wanted-software-contrast-border5 {
    border-radius: 9999px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-contrast-border5 {
    height: 40px;
    width: 40px;
    border: 0.8px solid rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    padding: 8px 0;
  }
  .archive-wanted-software-avatar5 {
    background-color: #f2f4f7;
  }
  .archive-wanted-software-text129 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    color: #101828;
    text-align: left;
    display: inline-block;
    min-width: 82px;
  }
  .archive-wanted-software-table-cell4 {
    width: 428px;
    margin: 0 !important;
    position: absolute;
    top: 332px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  .archive-wanted-software-text130 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    color: #101828;
    text-align: left;
    display: inline-block;
    min-width: 78px;
  }
  .archive-wanted-software-table-cell5 {
    width: 428px;
    margin: 0 !important;
    position: absolute;
    bottom: 288px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  .archive-wanted-software-text131 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    color: #101828;
    text-align: left;
    display: inline-block;
    min-width: 74px;
  }
  .archive-wanted-software-table-cell6,
  .archive-wanted-software-table-cell7 {
    width: 411px;
    margin: 0 !important;
    position: absolute;
    bottom: 216px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  .archive-wanted-software-table-cell7 {
    width: 428px;
    bottom: 144px;
  }
  .archive-wanted-software-text134 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    color: #101828;
    text-align: left;
    display: inline-block;
    min-width: 67px;
  }
  .archive-wanted-software-table-cell8 {
    width: 428px;
    margin: 0 !important;
    position: absolute;
    bottom: 72px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  .archive-wanted-software-text135 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    color: #101828;
    text-align: left;
    display: inline-block;
    min-width: 95px;
  }
  .archive-wanted-software-table-cell9 {
    width: 428px;
    margin: 0 !important;
    position: absolute;
    bottom: 0;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
    z-index: 1;
  }
  .archive-wanted-software-text136 {
    position: relative;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    font-family: Inter;
    color: #475467;
    text-align: left;
    display: inline-block;
    min-width: 50px;
  }
  .archive-wanted-software-table-header,
  .archive-wanted-software-table-header-cell1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .archive-wanted-software-table-header {
    gap: 4px;
  }
  .archive-wanted-software-table-header-cell1 {
    width: 255px;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    padding: 13px 24px 11px;
  }
  .archive-wanted-software-text137 {
    position: relative;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    font-family: Inter;
    color: #344054;
    text-align: center;
    display: inline-block;
    min-width: 93px;
  }
  .archive-wanted-software-table-cell10 {
    width: 255px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 24px 24px;
  }
  .archive-wanted-software-text138 {
    position: relative;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    font-family: Inter;
    color: #344054;
    text-align: center;
    display: inline-block;
    min-width: 85px;
  }
  .archive-wanted-software-marker-pin-02-group,
  .archive-wanted-software-table-cell11 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .archive-wanted-software-marker-pin-02-group {
    gap: 7px;
  }
  .archive-wanted-software-table-cell11 {
    width: 247px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    padding: 26px 24px 24px;
  }
  .archive-wanted-software-text139 {
    position: relative;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    font-family: Inter;
    color: #344054;
    text-align: center;
    display: inline-block;
    min-width: 91px;
  }
  .archive-wanted-software-table-cell15,
  .archive-wanted-software-table-cell16,
  .archive-wanted-software-table-cell18 {
    width: 279px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 24px 24px;
  }
  .archive-wanted-software-table-cell16,
  .archive-wanted-software-table-cell18 {
    width: 271px;
  }
  .archive-wanted-software-table-cell18 {
    width: 239px;
  }
  .archive-wanted-software-column,
  .archive-wanted-software-table-header-cell-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-column {
    width: 120px;
    flex-direction: column;
    padding: 0;
    box-sizing: border-box;
    z-index: 2;
  }
  .archive-wanted-software-table-header-cell-parent {
    flex-direction: row;
    position: relative;
  }
  .archive-wanted-software-frame-wrapper12 {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    max-width: 100%;
  }
  .archive-wanted-software-text147 {
    width: calc(100% - 16px);
    border: 0;
    outline: 0;
    font-weight: 500;
    font-family: Inter;
    font-size: 12px;
    background-color: transparent;
    height: 18px;
    flex: 1;
    position: relative;
    line-height: 18px;
    color: #475467;
    text-align: left;
    display: inline-block;
    min-width: 41px;
    padding: 0;
  }
  .archive-wanted-software-help-icon {
    height: 16px;
    width: 16px;
    position: relative;
  }
  .archive-wanted-software-table-header-cell2,
  .archive-wanted-software-table-header1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .archive-wanted-software-table-header1 {
    width: 88px;
    gap: 4px;
  }
  .archive-wanted-software-table-header-cell2 {
    width: 297px;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    padding: 13px 24px 11px;
  }
  .archive-wanted-software-text148 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-family: Inter;
    color: #475467;
    text-align: left;
    white-space: nowrap;
  }
  .archive-wanted-software-table-cell20,
  .archive-wanted-software-table-cell21 {
    width: 297px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 24px 24px;
  }
  .archive-wanted-software-table-cell21 {
    width: 407px;
    max-width: 232%;
    flex-shrink: 0;
  }
  .archive-wanted-software-text153 {
    width: 172px;
    border: 0;
    outline: 0;
    font-family: Inter;
    font-size: 14px;
    background-color: transparent;
    height: 20px;
    position: relative;
    line-height: 20px;
    color: #475467;
    text-align: left;
    display: inline-block;
    white-space: nowrap;
    padding: 0;
  }
  .archive-wanted-software-table-cell25 {
    width: 328px;
    border-bottom: 1px solid #eaecf0;
    flex-direction: row;
    align-items: center;
    padding: 26px 24px 24px;
    max-width: 187%;
    flex-shrink: 0;
  }
  .archive-wanted-software-column1,
  .archive-wanted-software-table-cell25,
  .archive-wanted-software-table-cell29 {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
  }
  .archive-wanted-software-table-cell29 {
    width: 287px;
    border-bottom: 1px solid #eaecf0;
    flex-direction: row;
    align-items: center;
    padding: 26px 24px 24px;
  }
  .archive-wanted-software-column1 {
    width: 176px;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    z-index: 1;
  }
  .archive-wanted-software-text158 {
    position: relative;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    font-family: Inter;
    color: #475467;
    text-align: left;
    display: inline-block;
    min-width: 37px;
  }
  .archive-wanted-software-table-cell30,
  .archive-wanted-software-table-header-cell3,
  .archive-wanted-software-table-header2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .archive-wanted-software-table-header2 {
    flex: 1;
    justify-content: flex-start;
    gap: 4px;
  }
  .archive-wanted-software-table-cell30,
  .archive-wanted-software-table-header-cell3 {
    border-bottom: 1px solid #eaecf0;
    justify-content: center;
  }
  .archive-wanted-software-table-header-cell3 {
    cursor: pointer;
    border: 0;
    padding: 13px 41px 11px;
    background-color: #f9fafb;
    align-self: stretch;
  }
  .archive-wanted-software-table-cell30 {
    padding: 26px 50px 24px;
  }
  .archive-wanted-software-column2,
  .archive-wanted-software-dropdown6 {
    width: 21px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-column2 {
    width: 120px;
    align-items: flex-end;
    padding: 0;
    box-sizing: border-box;
    z-index: 1;
  }
  .archive-wanted-software-frame-parent26 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 100%;
    gap: 20px;
  }
  .archive-wanted-software-text159 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 59px;
  }
  .archive-wanted-software-buttonsbutton31 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 6px 11px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  .archive-wanted-software-button-wrap,
  .archive-wanted-software-button-wrap-wrapper {
    display: flex;
    justify-content: flex-start;
  }
  .archive-wanted-software-button-wrap {
    align-self: stretch;
    flex-direction: row;
    align-items: center;
  }
  .archive-wanted-software-button-wrap-wrapper {
    width: 326px;
    flex-direction: column;
    align-items: flex-start;
    padding: 2px 0 0;
    box-sizing: border-box;
    max-width: 100%;
  }
  .archive-wanted-software-number {
    width: 7px;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    color: #18222f;
    text-align: center;
    display: inline-block;
    min-width: 7px;
  }
  .archive-wanted-software-content11,
  .archive-wanted-software-pagination-number-base {
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-content11 {
    flex: 1;
    padding: 10px 16px;
  }
  .archive-wanted-software-pagination-number-base {
    width: 40px;
    background-color: #f9fafb;
    overflow: hidden;
    flex-shrink: 0;
  }
  .archive-wanted-software-number1 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    color: #475467;
    text-align: center;
    display: inline-block;
    min-width: 9px;
  }
  .archive-wanted-software-content12,
  .archive-wanted-software-pagination-number-base1 {
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-content12 {
    flex: 1;
    padding: 10px 15px;
  }
  .archive-wanted-software-pagination-number-base1 {
    width: 40px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .archive-wanted-software-number2 {
    width: 10px;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    color: #475467;
    text-align: center;
    display: inline-block;
    min-width: 10px;
  }
  .archive-wanted-software-content13,
  .archive-wanted-software-pagination-number-base2 {
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-content13 {
    padding: 10px 15px;
  }
  .archive-wanted-software-pagination-number-base2 {
    overflow: hidden;
  }
  .archive-wanted-software-number3 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    color: #475467;
    text-align: center;
    display: inline-block;
    min-width: 13px;
  }
  .archive-wanted-software-content14 {
    flex: 1;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 13px;
  }
  .archive-wanted-software-number6 {
    width: 16px;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    font-family: Inter;
    color: #475467;
    text-align: center;
    display: inline-block;
    min-width: 16px;
  }
  .archive-wanted-software-content17,
  .archive-wanted-software-pagination-number-base-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-content17 {
    border-radius: 8px;
    flex-direction: row;
    padding: 10px 12px;
  }
  .archive-wanted-software-pagination-number-base-wrapper {
    flex-direction: column;
    padding: 0 10px 0 0;
  }
  .archive-wanted-software-text160 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 32px;
  }
  .archive-wanted-software-button-wrap1,
  .archive-wanted-software-buttonsbutton32 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .archive-wanted-software-buttonsbutton32 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 6px 11px;
    background-color: #fff;
    width: 84px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
    gap: 4px;
  }
  .archive-wanted-software-button-wrap1 {
    align-self: stretch;
    justify-content: flex-end;
  }
  .archive-wanted-software-next-page-button {
    flex: 1;
    flex-direction: column;
    padding: 2px 0 0;
    box-sizing: border-box;
    min-width: 212px;
  }
  .archive-wanted-software-next-page-button,
  .archive-wanted-software-page-navigation,
  .archive-wanted-software-pagination,
  .archive-wanted-software-table {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .archive-wanted-software-page-navigation {
    flex: 1;
    flex-direction: row;
    gap: 2px;
    min-width: 409px;
  }
  .archive-wanted-software-pagination,
  .archive-wanted-software-table {
    align-self: stretch;
    box-sizing: border-box;
  }
  .archive-wanted-software-pagination {
    border-top: 1px solid #eaecf0;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 12px 24px 16px;
    gap: 12px;
    z-index: 3;
  }
  .archive-wanted-software-table {
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    overflow: hidden;
    flex-direction: column;
    z-index: 1;
  }
  .archive-wanted-software-text161,
  .archive-wanted-software-text162 {
    position: relative;
    font-family: Inter;
    display: inline-block;
  }
  .archive-wanted-software-text161 {
    text-decoration: none;
    font-size: 26px;
    line-height: 28px;
    font-weight: 600;
    color: #101828;
    text-align: left;
    min-width: 128px;
  }
  .archive-wanted-software-text162 {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: #6941c6;
    text-align: center;
    min-width: 73px;
  }
  .archive-wanted-software-badge29,
  .archive-wanted-software-text-and-badge2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .archive-wanted-software-badge29 {
    width: 89px;
    border-radius: 9999px;
    background-color: #f9f5ff;
    border: 1px solid #e9d7fe;
    box-sizing: border-box;
    padding: 0 7px;
    white-space: nowrap;
  }
  .archive-wanted-software-text-and-badge2 {
    align-self: stretch;
    padding: 0 440px 0 0;
    gap: 8px;
  }
  .archive-wanted-software-text-and-supporting-text3 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
    min-width: 432px;
    max-width: 100%;
  }
  .archive-wanted-software-text166 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 79px;
  }
  .archive-wanted-software-button-group-base18,
  .archive-wanted-software-button-group-base19 {
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
  }
  .archive-wanted-software-button-group-base18 {
    cursor: pointer;
    border: 0;
    background-color: #f9fafb;
    width: 85px;
    white-space: nowrap;
  }
  .archive-wanted-software-button-group-base18:hover {
    background-color: #e0e0e0;
  }
  .archive-wanted-software-button-group-base19 {
    background-color: #fff;
  }
  .archive-wanted-software-text175 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 89px;
  }
  .archive-wanted-software-button-group2 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 20px;
  }
  .archive-wanted-software-text176 {
    width: calc(100% - 318px);
    border: 0;
    outline: 0;
    font-weight: 500;
    font-family: Inter;
    font-size: 16px;
    background-color: transparent;
    height: 24px;
    flex: 1;
    position: relative;
    line-height: 24px;
    color: #101828;
    text-align: left;
    display: inline-block;
    min-width: 28px;
    padding: 0;
  }
  .archive-wanted-software-card-header-group,
  .archive-wanted-software-content20 {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  .archive-wanted-software-content20 {
    flex-direction: row;
    align-items: center;
    padding: 0 298px 0 0;
    box-sizing: border-box;
    gap: 8px;
  }
  .archive-wanted-software-card-header-group {
    flex-direction: column;
    align-items: flex-start;
  }
  .archive-wanted-software-featured-icon {
    width: 48px;
    height: 48px;
    position: relative;
    border-radius: 10px;
  }
  .archive-wanted-software-supporting-text55,
  .archive-wanted-software-text178 {
    align-self: stretch;
    position: relative;
    font-family: Inter;
    text-align: center;
  }
  .archive-wanted-software-text178 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #101828;
  }
  .archive-wanted-software-supporting-text55 {
    font-size: 14px;
    line-height: 20px;
    color: #475467;
  }
  .archive-wanted-software-content21,
  .archive-wanted-software-text-and-supporting-text4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .archive-wanted-software-text-and-supporting-text4 {
    width: 352px;
    gap: 4px;
    max-width: 352px;
  }
  .archive-wanted-software-content21 {
    align-self: stretch;
    padding: 0 20px;
    gap: 16px;
  }
  .archive-wanted-software-text179 {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 98px;
  }
  .archive-wanted-software-buttonsbutton33 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 8px 15px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
  .archive-wanted-software-text180 {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 94px;
  }
  .archive-wanted-software-buttonsbutton34 {
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 15px;
    background-color: #7e56d9;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
  .archive-wanted-software-actions6,
  .archive-wanted-software-empty-state1 {
    justify-content: flex-start;
  }
  .archive-wanted-software-actions6 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
  }
  .archive-wanted-software-empty-state1 {
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .archive-wanted-software-empty-state,
  .archive-wanted-software-empty-state1,
  .archive-wanted-software-frame-form {
    display: flex;
    max-width: 100%;
  }
  .archive-wanted-software-empty-state {
    width: 100%;
    margin: 0 !important;
    position: absolute;
    right: 0;
    bottom: 151px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  .archive-wanted-software-frame-form {
    margin: 0;
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 418px;
    box-sizing: border-box;
    position: relative;
    gap: 23.5px;
  }
  .archive-wanted-software-bifocal-optometry-measurement-icon {
    width: 391px;
    position: relative;
    max-height: 100%;
    object-fit: cover;
    max-width: 100%;
  }
  .archive-wanted-software-supporting-text56,
  .archive-wanted-software-text181 {
    align-self: stretch;
    position: relative;
  }
  .archive-wanted-software-text181 {
    margin: 0;
    font-size: inherit;
    line-height: 30px;
    font-weight: 600;
    font-family: inherit;
  }
  .archive-wanted-software-supporting-text56 {
    font-size: 16px;
    line-height: 24px;
    color: #475467;
  }
  .archive-wanted-software-content22,
  .archive-wanted-software-text-and-supporting-text5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .archive-wanted-software-text-and-supporting-text5 {
    width: 352px;
    gap: 8px;
    max-width: 352px;
  }
  .archive-wanted-software-content22 {
    align-self: stretch;
    padding: 0 20px;
    box-sizing: border-box;
    gap: 20px;
    max-width: 100%;
  }
  .archive-wanted-software-text183 {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 88px;
  }
  .archive-wanted-software-empty-state2,
  .archive-wanted-software-empty-state3 {
    display: flex;
    align-items: center;
    max-width: 100%;
  }
  .archive-wanted-software-empty-state3 {
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    gap: 32px;
  }
  .archive-wanted-software-empty-state2 {
    align-self: stretch;
    background-color: #fff;
    flex-direction: row;
    justify-content: center;
    padding: 41.5px 0;
    box-sizing: border-box;
    z-index: 1;
    text-align: center;
    font-size: 20px;
  }
  .archive-wanted-software-listing-container,
  .archive-wanted-software-listing-wrapper {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
  }
  .archive-wanted-software-listing-wrapper {
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
    text-align: left;
    font-size: 18px;
    color: #101828;
    font-family: Inter;
  }
  .archive-wanted-software-listing-container {
    width: 1075px;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 29px;
    box-sizing: border-box;
    flex-shrink: 0;
  }
  .archive-wanted-software-frame-child5 {
    height: 282px;
    width: 1016px;
    position: absolute;
    margin: 0 !important;
    right: -844px;
    bottom: -110px;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    z-index: 1;
  }
  .archive-wanted-software-rectangle-icon {
    height: 148px;
    width: 148px;
    position: relative;
    border-radius: 12px;
    object-fit: cover;
    z-index: 2;
  }
  .archive-wanted-software-rectangle-parent2 {
    top: 1098px;
    left: 418px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-frame-child6,
  .archive-wanted-software-rectangle-parent2,
  .archive-wanted-software-rectangle-parent3 {
    margin: 0 !important;
    position: absolute;
  }
  .archive-wanted-software-frame-child6 {
    border: 1px solid #eaecf0;
    background-color: #fff;
    height: 282px;
    width: 1016px;
    outline: 0;
    right: -844px;
    bottom: -110px;
    border-radius: 12px;
    box-sizing: border-box;
    z-index: 1;
  }
  .archive-wanted-software-rectangle-parent3 {
    top: 1404px;
    left: 418px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-dots-vertical-icon22,
  .archive-wanted-software-list-footer {
    position: absolute;
    margin: 0 !important;
    z-index: 2;
  }
  .archive-wanted-software-list-footer {
    top: 1098px;
    right: 212px;
    line-height: 28px;
    font-weight: 600;
  }
  .archive-wanted-software-dots-vertical-icon22 {
    width: 20px;
    height: 20px;
    top: 1088px;
    right: 44px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .archive-wanted-software-listing-footer-graphics-child {
    height: 208px;
    width: 1016px;
    position: absolute;
    margin: 0 !important;
    top: calc(50% - 104px);
    right: -870px;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    z-index: 1;
  }
  .archive-wanted-software-f4b711e503bc72fe396e5043e0c2-7-icon {
    height: 84px;
    width: 84px;
    position: relative;
    object-fit: cover;
    z-index: 3;
  }
  .archive-wanted-software-listing-footer-graphics {
    margin: 0 !important;
    position: absolute;
    top: 2769px;
    left: 456px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-footer-text {
    position: relative;
    line-height: 20px;
  }
  .archive-wanted-software-footer-text-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .archive-wanted-software-gerator-10-2-icon {
    height: 38px;
    width: 38px;
    position: relative;
    object-fit: cover;
  }
  .archive-wanted-software-logomark1 {
    box-shadow: 0 1.3px 3.75px rgba(16, 24, 40, 0.1),
      0 1.3px 2.5px rgba(16, 24, 40, 0.06);
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-frame-parent27,
  .archive-wanted-software-logomark,
  .archive-wanted-software-logomark1 {
    display: flex;
    flex-direction: row;
  }
  .archive-wanted-software-logomark {
    filter: drop-shadow(0 1.3px 3.75px rgba(16, 24, 40, 0.1))
      drop-shadow(0 1.3px 2.5px rgba(16, 24, 40, 0.06));
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-frame-parent27 {
    align-items: center;
    justify-content: center;
    gap: 29px;
  }
  .archive-wanted-software-content23,
  .archive-wanted-software-logo1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-content23 {
    align-self: stretch;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    align-content: center;
    gap: 20px;
  }
  .archive-wanted-software-container1,
  .archive-wanted-software-footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .archive-wanted-software-container1 {
    width: 100%;
    align-items: flex-start;
    padding: 0 32px;
    box-sizing: border-box;
    max-width: 1280px;
  }
  .archive-wanted-software-footer {
    align-self: stretch;
    background-color: #fff;
    overflow: hidden;
    align-items: center;
    padding: 48px 80px;
    z-index: 1;
    text-align: left;
    font-size: 16px;
    color: #475467;
    font-family: Inter;
  }
  .archive-wanted-software-divider-icon14 {
    height: 1488px;
    width: 2px;
    position: absolute;
    margin: 0 !important;
    top: 0;
    right: 0;
    object-fit: contain;
    z-index: 2;
  }
  .archive-wanted-software-frame-child8 {
    width: 338px;
    height: 1488px;
    position: relative;
    background-color: #fff;
    display: none;
    max-width: 100%;
    z-index: 0;
  }
  .archive-wanted-software-frame-child9 {
    width: 100%;
    height: 100%;
    position: absolute;
    margin: 0 !important;
    top: 0;
    right: 1px;
    bottom: 1px;
    left: 0;
    background-color: #fff;
    z-index: 1;
  }
  .archive-wanted-software-dot-icon10,
  .archive-wanted-software-dot-icon11,
  .archive-wanted-software-dot-icon7,
  .archive-wanted-software-dot-icon8,
  .archive-wanted-software-dot-icon9 {
    width: 10px;
    height: 10px;
    position: relative;
    display: none;
    z-index: 2;
  }
  .archive-wanted-software-dot-icon10,
  .archive-wanted-software-dot-icon11,
  .archive-wanted-software-dot-icon8,
  .archive-wanted-software-dot-icon9 {
    z-index: 3;
  }
  .archive-wanted-software-dot-icon10,
  .archive-wanted-software-dot-icon11,
  .archive-wanted-software-dot-icon9 {
    z-index: 4;
  }
  .archive-wanted-software-dot-icon10,
  .archive-wanted-software-dot-icon11 {
    z-index: 5;
  }
  .archive-wanted-software-dot-icon11 {
    z-index: 6;
  }
  .archive-wanted-software-dot-icon12 {
    width: 10px;
    height: 10px;
    z-index: 7;
  }
  .archive-wanted-software-dot-icon12,
  .archive-wanted-software-dot-icon13,
  .archive-wanted-software-featured-icon1 {
    position: relative;
    display: none;
  }
  .archive-wanted-software-dot-icon13 {
    width: 10px;
    height: 10px;
    z-index: 8;
  }
  .archive-wanted-software-featured-icon1 {
    height: 40px;
    width: 40px;
    border-radius: 8px;
  }
  .archive-wanted-software-supporting-text57,
  .archive-wanted-software-text184 {
    align-self: stretch;
    position: relative;
  }
  .archive-wanted-software-text184 {
    text-decoration: none;
    line-height: 28px;
    font-weight: 600;
    color: inherit;
  }
  .archive-wanted-software-supporting-text57 {
    font-size: 14px;
    line-height: 20px;
    color: #475467;
  }
  .archive-wanted-software-text-and-supporting-text6 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    max-width: 100%;
  }
  .archive-wanted-software-button-close-x,
  .archive-wanted-software-content24 {
    display: flex;
    flex-direction: row;
  }
  .archive-wanted-software-content24 {
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 24px 0 0;
    box-sizing: border-box;
    gap: 16px;
    max-width: 100%;
  }
  .archive-wanted-software-button-close-x {
    margin: 0 !important;
    position: absolute;
    top: 12px;
    right: 16px;
    border-radius: 8px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: 10px;
    z-index: 1;
  }
  .archive-wanted-software-slide-out-menu-header {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 16px 0 24px;
    box-sizing: border-box;
    position: relative;
    gap: 8px;
    max-width: 100%;
    z-index: 2;
  }
  .archive-wanted-software-slide-out-title {
    text-decoration: none;
    position: relative;
    line-height: 24px;
    font-weight: 500;
    color: inherit;
    display: inline-block;
    min-width: 35px;
    z-index: 2;
  }
  .archive-wanted-software-slide-out-title-parent {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
  }
  .archive-wanted-software-frame-wrapper13,
  .archive-wanted-software-slide-out-menu-header-parent {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-frame-wrapper13 {
    flex-direction: row;
    padding: 0 33px 0 24px;
    font-size: 14px;
    color: #344054;
  }
  .archive-wanted-software-slide-out-menu-header-parent {
    flex-direction: column;
    gap: 24px;
    max-width: 100%;
  }
  .archive-wanted-software-background,
  .archive-wanted-software-progress-line {
    height: 100%;
    position: absolute;
    margin: 0 !important;
    top: 0;
    bottom: 0;
    border-radius: 9999px;
  }
  .archive-wanted-software-background {
    width: 305px;
    right: -56.2px;
    background-color: #eaecf0;
    z-index: 2;
  }
  .archive-wanted-software-progress-line {
    width: 100%;
    right: -0.2px;
    background-color: #7e56d9;
  }
  .archive-wanted-software-text185 {
    position: relative;
    line-height: 18px;
    font-weight: 600;
    display: inline-block;
    min-width: 9px;
  }
  .archive-wanted-software-content25,
  .archive-wanted-software-tooltip1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-content25 {
    flex: 1;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    padding: 6px 11px;
  }
  .archive-wanted-software-tooltip1 {
    margin-top: -42px;
    margin-left: -4.5px;
    width: 33px;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
      0 4px 6px -2px rgba(16, 24, 40, 0.03);
    flex-shrink: 0;
  }
  .archive-wanted-software-control-handle,
  .archive-wanted-software-handle {
    height: 24px;
    width: 24px;
    box-sizing: border-box;
    flex-shrink: 0;
  }
  .archive-wanted-software-handle {
    position: relative;
    box-shadow: 0 4px 8px -2px rgba(16, 24, 40, 0.1),
      0 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 9999px;
    background-color: #fff;
    border: 2px solid #7e56d9;
    overflow: hidden;
  }
  .archive-wanted-software-control-handle {
    margin-top: -8px;
    border-radius: 12px;
    border: 1.5px solid #7e56d9;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    z-index: 1;
  }
  .archive-wanted-software-text186 {
    position: relative;
    line-height: 18px;
    font-weight: 600;
    display: inline-block;
    min-width: 80px;
  }
  .archive-wanted-software-content26 {
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    padding: 6px 11px;
  }
  .archive-wanted-software-background-parent,
  .archive-wanted-software-content26,
  .archive-wanted-software-progress,
  .archive-wanted-software-tooltip2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-tooltip2 {
    margin-top: -42px;
    margin-left: -40px;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
      0 4px 6px -2px rgba(16, 24, 40, 0.03);
    flex-shrink: 0;
  }
  .archive-wanted-software-background-parent,
  .archive-wanted-software-progress {
    position: relative;
  }
  .archive-wanted-software-progress {
    height: 8px;
    width: 228.8px;
    gap: 193px;
    z-index: 3;
  }
  .archive-wanted-software-background-parent {
    padding: 0 20px 0 0;
  }
  .archive-wanted-software-label5 {
    width: 36px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: none;
  }
  .archive-wanted-software-text187 {
    flex: 1;
    position: relative;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .archive-wanted-software-content27 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .archive-wanted-software-help-icon1 {
    height: 16px;
    width: 16px;
    position: relative;
    display: none;
  }
  .archive-wanted-software-input4 {
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 30px;
    gap: 8px;
    font-size: 16px;
    color: #101828;
  }
  .archive-wanted-software-input-field,
  .archive-wanted-software-input-with-label4 {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .archive-wanted-software-input-with-label4 {
    align-self: stretch;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-input-field {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
  .archive-wanted-software-text188 {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
    display: inline-block;
    min-width: 8px;
  }
  .archive-wanted-software-input-fields {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    z-index: 2;
  }
  .archive-wanted-software-input6 {
    margin: 0;
    height: 18px;
    width: 16px;
  }
  .archive-wanted-software-checkboxes {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 0 8px;
    gap: 12px;
    z-index: 2;
  }
  .archive-wanted-software-input-fields-parent {
    align-self: stretch;
    gap: 24px;
    text-align: left;
    font-size: 14px;
  }
  .archive-wanted-software-frame-parent31,
  .archive-wanted-software-frame-wrapper14,
  .archive-wanted-software-input-fields-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-frame-parent31 {
    align-self: stretch;
    gap: 32px;
  }
  .archive-wanted-software-frame-wrapper14 {
    flex: 1;
    padding: 9px 0 0;
  }
  .archive-wanted-software-frame-child10 {
    height: 163px;
    width: 5px;
    position: relative;
    border-radius: 8px;
    background-color: #dadada;
    z-index: 2;
  }
  .archive-wanted-software-frame-parent30 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 0 24px;
    gap: 28px;
    text-align: center;
    font-size: 12px;
  }
  .archive-wanted-software-divider-icon15 {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    z-index: 2;
  }
  .archive-wanted-software-text194 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 83px;
  }
  .archive-wanted-software-content-parent,
  .archive-wanted-software-content29 {
    flex-direction: row;
    align-items: center;
  }
  .archive-wanted-software-content29 {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    gap: 12px;
  }
  .archive-wanted-software-content-parent {
    gap: 42px;
  }
  .archive-wanted-software-content-parent,
  .archive-wanted-software-input10,
  .archive-wanted-software-section {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
  }
  .archive-wanted-software-section {
    flex-direction: column;
    align-items: flex-start;
    z-index: 2;
  }
  .archive-wanted-software-input10 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    align-items: center;
    padding: 8px 13px;
    gap: 8px;
    font-size: 16px;
    color: #101828;
  }
  .archive-wanted-software-input-field2,
  .archive-wanted-software-section-parent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
  }
  .archive-wanted-software-section-parent {
    gap: 8px;
  }
  .archive-wanted-software-text198 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 71px;
  }
  .archive-wanted-software-checkboxes1 {
    align-self: stretch;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 0 0 8px;
    gap: 12px;
  }
  .archive-wanted-software-checkboxes1,
  .archive-wanted-software-section-wrapper,
  .archive-wanted-software-section1 {
    display: flex;
    align-items: flex-start;
  }
  .archive-wanted-software-section1 {
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
    z-index: 2;
  }
  .archive-wanted-software-section-wrapper {
    align-self: stretch;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 33px 0 24px;
  }
  .archive-wanted-software-text203,
  .archive-wanted-software-text207 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 116px;
  }
  .archive-wanted-software-text207 {
    min-width: 44px;
  }
  .archive-wanted-software-section3 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 2;
  }
  .archive-wanted-software-text208,
  .archive-wanted-software-text209 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 62px;
  }
  .archive-wanted-software-text209 {
    min-width: 59px;
  }
  .archive-wanted-software-search-lg,
  .archive-wanted-software-text210 {
    position: relative;
    z-index: 2;
  }
  .archive-wanted-software-search-lg {
    cursor: pointer;
    margin: 0;
    width: 20px;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .archive-wanted-software-text210 {
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 55px;
  }
  .archive-wanted-software-content37,
  .archive-wanted-software-input-fields2,
  .archive-wanted-software-input19 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .archive-wanted-software-content37 {
    width: 100%;
    border: 0;
    outline: 0;
    background-color: transparent;
    height: 24px;
    flex: 1;
    justify-content: flex-start;
    font-family: Inter;
    font-size: 16px;
    color: #9ea1a8;
    min-width: 166px;
  }
  .archive-wanted-software-input-fields2,
  .archive-wanted-software-input19 {
    align-self: stretch;
  }
  .archive-wanted-software-input19 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    justify-content: flex-start;
    padding: 8px 13px;
    gap: 8px;
  }
  .archive-wanted-software-input-fields2 {
    justify-content: center;
    z-index: 2;
  }
  .archive-wanted-software-divider-icon22 {
    flex: 1;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    z-index: 2;
  }
  .archive-wanted-software-divider-icon23 {
    height: 0.2px;
    width: 338px;
    position: absolute;
    margin: 0 !important;
    top: 0;
    left: 0;
    z-index: 3;
  }
  .archive-wanted-software-text211 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 112px;
    z-index: 2;
  }
  .archive-wanted-software-frame-wrapper17 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 24px;
  }
  .archive-wanted-software-text212 {
    display: inline-block;
    min-width: 76px;
  }
  .archive-wanted-software-text212,
  .archive-wanted-software-text213 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    z-index: 2;
  }
  .archive-wanted-software-frame-parent37 {
    flex-direction: row;
    gap: 11px;
  }
  .archive-wanted-software-frame-parent36,
  .archive-wanted-software-frame-parent37,
  .archive-wanted-software-frame-wrapper19 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .archive-wanted-software-frame-wrapper19 {
    flex-direction: row;
    padding: 0 24px;
  }
  .archive-wanted-software-frame-parent36 {
    align-self: stretch;
    flex-direction: column;
    gap: 15.3px;
    max-width: 100%;
  }
  .archive-wanted-software-divider-icon31 {
    width: 338px;
    position: relative;
    max-height: 100%;
    max-width: 100%;
    z-index: 3;
  }
  .archive-wanted-software-text216 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 118px;
    z-index: 2;
  }
  .archive-wanted-software-frame-parent28,
  .archive-wanted-software-frame-parent29 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 100%;
  }
  .archive-wanted-software-frame-parent29 {
    align-items: flex-end;
    gap: 15.6px;
    font-size: 14px;
    color: #344054;
  }
  .archive-wanted-software-frame-parent28 {
    align-items: flex-start;
    padding: 0 1px 0 0;
    box-sizing: border-box;
    gap: 56px;
  }
  .archive-wanted-software-dot-icon20 {
    width: 10px;
    height: 10px;
    position: relative;
    display: none;
    z-index: 10;
  }
  .archive-wanted-software-divider-icon35 {
    height: 1px;
    width: calc(100% - 1px);
    position: absolute;
    margin: 0 !important;
    top: 0;
    right: 1px;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    z-index: 2;
  }
  .archive-wanted-software-text217 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 69px;
  }
  .archive-wanted-software-buttonsbutton37 {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
  .archive-wanted-software-text218 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 48px;
  }
  .archive-wanted-software-buttonsbutton38 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 8px 13px;
    background-color: #fff;
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  .archive-wanted-software-text219 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 40px;
  }
  .archive-wanted-software-buttonsbutton39 {
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 13px;
    background-color: #7e56d9;
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  .archive-wanted-software-actions9,
  .archive-wanted-software-buttonsbutton39,
  .archive-wanted-software-content38 {
    display: flex;
    flex-direction: row;
  }
  .archive-wanted-software-actions9 {
    width: 164px;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
  }
  .archive-wanted-software-content38 {
    align-self: stretch;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    gap: 20px;
  }
  .archive-wanted-software-divider-parent6,
  .archive-wanted-software-footer1 {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    max-width: 100%;
  }
  .archive-wanted-software-footer1 {
    flex: 1;
    border-top: 1px solid #eaecf0;
    flex-direction: column;
    align-items: center;
    z-index: 4;
  }
  .archive-wanted-software-divider-parent6 {
    align-self: stretch;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 1px 0 0;
    position: relative;
  }
  .archive-wanted-software-divider-icon37 {
    height: 0.2px;
    width: 338px;
    position: absolute;
    margin: 0 !important;
    top: 0;
    left: -1px;
    z-index: 3;
  }
  .archive-wanted-software-frame-parent41 {
    align-self: stretch;
    flex-direction: column;
    font-size: 14px;
    color: #6941c6;
  }
  .archive-wanted-software-frame-parent41,
  .archive-wanted-software-rectangle-parent4,
  .archive-wanted-software-slide-out {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .archive-wanted-software-rectangle-parent4 {
    flex: 1;
    flex-direction: column;
    position: relative;
    gap: 61px;
    z-index: 3;
    
  }
  .archive-wanted-software-slide-out {
    width: 363px;
    margin: 0 !important;
    position: absolute;
    top: 81px;
    left: 0;
    flex-direction: row;
  }
  .archive-wanted-software-listings-search-results-list {
    width: 100%;
    height: max-content;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    /* padding: 81px 0px 0px 0px; */
    box-sizing: border-box;
    /* gap: 999px; */
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-size: 18px;
    color: #101828;
    font-family: Inter;
  }
  @media screen and (max-width: 1275px) {
    .archive-wanted-software-collapsed-listing,
    .archive-wanted-software-frame-form {
      padding-bottom: 339px;
      box-sizing: border-box;
    }
    .archive-wanted-software-frame-form {
      padding-bottom: 272px;
    }
    .archive-wanted-software-container1 {
      max-width: 100%;
    }
  }
  @media screen and (max-width: 1100px) {
    .archive-wanted-software-filters {
      padding-bottom: 543px;
      box-sizing: border-box;
    }
    .archive-wanted-software-fourth-listing {
      flex: 1;
    }
    .archive-wanted-software-frame-parent18 {
      flex-wrap: wrap;
    }
    .archive-wanted-software-frame-parent16 {
      gap: 140px;
    }
    .archive-wanted-software-content8 {
      flex-wrap: wrap;
    }
    .archive-wanted-software-text-and-badge1 {
      flex-wrap: wrap;
      padding-right: 333px;
      box-sizing: border-box;
    }
    .archive-wanted-software-frame-parent26 {
      flex-wrap: wrap;
    }
    .archive-wanted-software-frame-form {
      padding-bottom: 177px;
      box-sizing: border-box;
    }
    .archive-wanted-software-listings-search-results-list {
      height: auto;
    }
  }
  @media screen and (max-width: 750px) {
    .archive-wanted-software-navigation-parent {
      gap: 19px;
    }
    .archive-wanted-software-frame-parent {
      gap: 35px;
    }
    .archive-wanted-software-rectangle-parent {
      padding-right: 32px;
      box-sizing: border-box;
    }
    .archive-wanted-software-main {
      gap: 16px;
    }
    .archive-wanted-software-badges,
    .archive-wanted-software-dot-item-content,
    .archive-wanted-software-dot-item-parent {
      flex-wrap: wrap;
    }
    .archive-wanted-software-dots-vertical {
      min-width: 100%;
    }
    .archive-wanted-software-dots-vertical-parent {
      flex-wrap: wrap;
      min-width: 100%;
    }
    .archive-wanted-software-frame-div {
      flex-wrap: wrap;
    }
    .archive-wanted-software-frame-container {
      min-width: 100%;
    }
    .archive-wanted-software-frame-group {
      flex-wrap: wrap;
    }
    .archive-wanted-software-dots-vertical-container {
      min-width: 100%;
    }
    .archive-wanted-software-filters {
      padding-bottom: 353px;
      box-sizing: border-box;
    }
    .archive-wanted-software-collapsed-name-role {
      padding-top: 99px;
      box-sizing: border-box;
    }
    .archive-wanted-software-first-badge,
    .archive-wanted-software-location-content {
      flex: 1;
    }
    .archive-wanted-software-badge-name {
      flex-wrap: wrap;
    }
    .archive-wanted-software-badge-details {
      gap: 23px;
    }
    .archive-wanted-software-first-badge-details {
      flex-wrap: wrap;
    }
    .archive-wanted-software-second-badge-list-parent {
      gap: 23px;
    }
    .archive-wanted-software-frame-parent5 {
      gap: 53px;
    }
    .archive-wanted-software-expanded-content {
      flex-wrap: wrap;
    }
    .archive-wanted-software-expanded-listing {
      min-width: 100%;
    }
    .archive-wanted-software-collapsed-listing {
      padding-bottom: 220px;
      box-sizing: border-box;
    }
    .archive-wanted-software-badge-support-parent,
    .archive-wanted-software-badges1,
    .archive-wanted-software-detail-item,
    .archive-wanted-software-frame-parent11 {
      flex-wrap: wrap;
    }
    .archive-wanted-software-second-listing-details {
      flex: 1;
    }
    .archive-wanted-software-listing-detail {
      flex-wrap: wrap;
    }
    .archive-wanted-software-frame-parent19 {
      gap: 54px;
    }
    .archive-wanted-software-frame-wrapper11 {
      min-width: 100%;
    }
    .archive-wanted-software-fourth-listing {
      margin-left: 0;
    }
    .archive-wanted-software-frame-parent16 {
      gap: 70px;
    }
    .archive-wanted-software-text-and-badge {
      flex-wrap: wrap;
      padding-right: 179px;
      box-sizing: border-box;
    }
    .archive-wanted-software-text-and-supporting-text1 {
      min-width: 100%;
    }
    .archive-wanted-software-actions2 {
      flex-wrap: wrap;
    }
    .archive-wanted-software-text-and-badge1 {
      padding-right: 166px;
      box-sizing: border-box;
    }
    .archive-wanted-software-text-and-supporting-text2 {
      min-width: 100%;
    }
    .archive-wanted-software-actions3 {
      flex-wrap: wrap;
    }
    .archive-wanted-software-page-navigation {
      flex-wrap: wrap;
      min-width: 100%;
    }
    .archive-wanted-software-text-and-badge2 {
      flex-wrap: wrap;
      padding-right: 220px;
      box-sizing: border-box;
    }
    .archive-wanted-software-text-and-supporting-text3 {
      min-width: 100%;
    }
    .archive-wanted-software-empty-state3 {
      gap: 16px;
    }
    .archive-wanted-software-empty-state2 {
      padding-top: 27px;
      padding-bottom: 27px;
      box-sizing: border-box;
    }
    .archive-wanted-software-container1 {
      gap: 16px;
    }
    .archive-wanted-software-footer {
      gap: 32px;
      padding-left: 40px;
      padding-right: 40px;
      box-sizing: border-box;
    }
    .archive-wanted-software-listings-search-results-list {
      gap: 49px;
    }
  }
  @media screen and (max-width: 450px) {
    .archive-wanted-software-frame-parent {
      gap: 17px;
    }
    .archive-wanted-software-page-title {
      font-size: 21px;
      line-height: 30px;
    }
    .archive-wanted-software-price {
      font-size: 16px;
      line-height: 26px;
    }
    .archive-wanted-software-card-work {
      flex: 1;
    }
    .archive-wanted-software-provider-name {
      flex-wrap: wrap;
    }
    .archive-wanted-software-price2,
    .archive-wanted-software-price3 {
      font-size: 16px;
      line-height: 26px;
    }
    .archive-wanted-software-badge-container,
    .archive-wanted-software-location-content {
      flex-wrap: wrap;
    }
    .archive-wanted-software-location-details-parent {
      gap: 28px;
      margin-left: 0;
    }
    .archive-wanted-software-buttonsbutton-group,
    .archive-wanted-software-buttonsbutton-parent {
      flex-wrap: wrap;
    }
    .archive-wanted-software-location-summary {
      flex: 1;
    }
    .archive-wanted-software-summary-details {
      flex-wrap: wrap;
    }
    .archive-wanted-software-frame-parent5 {
      gap: 27px;
    }
    .archive-wanted-software-work-type-badge-list {
      padding-top: 52px;
      box-sizing: border-box;
    }
    .archive-wanted-software-price4,
    .archive-wanted-software-price6 {
      font-size: 16px;
      line-height: 26px;
    }
    .archive-wanted-software-business-name-role,
    .archive-wanted-software-contact-details-parent {
      flex-wrap: wrap;
    }
    .archive-wanted-software-detail-item-parent {
      gap: 20px;
    }
    .archive-wanted-software-pair-container-parent {
      margin-left: 0;
    }
    .archive-wanted-software-frame-parent19 {
      gap: 27px;
    }
    .archive-wanted-software-price7 {
      font-size: 16px;
      line-height: 26px;
    }
    .archive-wanted-software-price-parent2 {
      flex-wrap: wrap;
    }
    .archive-wanted-software-frame-parent24 {
      gap: 126px;
    }
    .archive-wanted-software-text100 {
      font-size: 21px;
      line-height: 22px;
    }
    .archive-wanted-software-content9,
    .archive-wanted-software-text-and-badge {
      padding-right: 20px;
      box-sizing: border-box;
    }
    .archive-wanted-software-input-dropdown2 {
      max-width: 100%;
    }
    .archive-wanted-software-text161 {
      font-size: 21px;
      line-height: 22px;
    }
    .archive-wanted-software-text-and-badge2 {
      padding-right: 20px;
      box-sizing: border-box;
    }
    .archive-wanted-software-button-group2 {
      flex-wrap: wrap;
    }
    .archive-wanted-software-content20 {
      padding-right: 20px;
      box-sizing: border-box;
    }
    .archive-wanted-software-frame-form {
      padding-bottom: 115px;
      box-sizing: border-box;
    }
    .archive-wanted-software-text181 {
      font-size: 16px;
      line-height: 24px;
    }
    .archive-wanted-software-footer {
      gap: 16px;
    }
    .archive-wanted-software-listings-search-results-list {
      gap: 25px;
    }
  }
  