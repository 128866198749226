@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

.plan-and-billing-display-text-overlflow {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.plan-and-billing-display-Content-width {
  width: 70%;
}

.plan-and-billing-display-buttonsbutton-wrapper-width {
  width: 30%;
}


.plan-and-billing-display-component-listing-container {
  box-sizing: border-box;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: initial;
  padding: 0 29px 0 0;
  align-items: flex-start;
  width: 100%;
  display: flex;
  max-height: 85vh;
  gap: 15px;
  /* overflow-y: auto; */
  overflow-y: scroll;
  /* Enable vertical scrolling */
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}


.plan-and-billing-display-x-close-icon3 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.plan-and-billing-display-button-close-x1 {
  position: absolute;
  top: 24px;
  left: 611px;
  border-radius: 8px;
  width: 39px;
  height: 40px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  z-index: 1;
}

.plan-and-billing-display-featured-icon1,
.plan-and-billing-display-frame-child5 {
  position: relative;
  border-radius: 12px;
}

.plan-and-billing-display-frame-child5 {
  height: 546px;
  width: 633.4px;
  background-color: #fff;
  display: none;
  max-width: 100%;
}

.plan-and-billing-display-featured-icon1 {
  width: 56px;
  height: 56px;
}

.plan-and-billing-display-supporting-text5,
.plan-and-billing-display-title1 {
  align-self: stretch;
  position: relative;
}

.plan-and-billing-display-title1 {
  margin: 0;
  font-size: inherit;
  line-height: 38px;
  font-weight: 600;
  font-family: inherit;
}

.plan-and-billing-display-supporting-text5 {
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.plan-and-billing-display-header1,
.plan-and-billing-display-text-and-supporting-text3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.plan-and-billing-display-header1 {
  align-items: center;
  gap: 24px;
  text-align: center;
  font-size: 30px;
  color: #101828;
}

.plan-and-billing-display-placeholder-icon25 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.plan-and-billing-display-text76 {
  text-decoration: none;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 71px;
}

.plan-and-billing-display-buttonsbutton10,
.plan-and-billing-display-text-padding15 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.plan-and-billing-display-buttonsbutton10 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 20px;
  background-color: #7e56d9;
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  gap: 6px;
}

.plan-and-billing-display-secondary-label {
  position: relative;
  line-height: 20px;
}

.plan-and-billing-display-placeholder-icon27 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.plan-and-billing-display-text77 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 102px;
}

.plan-and-billing-display-buttonsbutton11,
.plan-and-billing-display-row1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.plan-and-billing-display-buttonsbutton11 {
  overflow: hidden;
  align-items: center;
  gap: 6px;
  color: #6941c6;
}

.plan-and-billing-display-row1 {
  align-items: flex-start;
  padding: 0 14px;
  gap: 3px;
}

.plan-and-billing-display-arrow-left-icon1 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}

.plan-and-billing-display-back-label {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 91px;
}

.plan-and-billing-display-buttonsbutton12 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.plan-and-billing-display-container9,
.plan-and-billing-display-content22 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.plan-and-billing-display-content22 {
  gap: 32px;
  max-width: 360px;
}

.plan-and-billing-display-container9 {
  padding: 0 32px;
  box-sizing: border-box;
  max-width: 1280px;
}

.plan-and-billing-display-content21 {
  width: 100%;
  max-width: 361px;
}

.plan-and-billing-display-container7,
.plan-and-billing-display-container8,
.plan-and-billing-display-content21 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.plan-and-billing-display-container8 {
  align-self: stretch;
  padding: 0 48px;
}

.plan-and-billing-display-container7 {
  flex: 1;
  padding: 0 32px;
  box-sizing: border-box;
  max-width: 100%;
  z-index: 1;
  border: 2px black;
}

.plan-and-billing-display-email-popup,
.plan-and-billing-display-frame-section {
  background-color: #fff;
  max-width: 100%;
}

.plan-and-billing-display-frame-section {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 12px;
  width: 633.4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 94px 55px 94px 56px;
  box-sizing: border-box;
  text-align: left;
  font-size: 14px;
  color: #475467;
  font-family: Inter;
}

.plan-and-billing-display-email-popup {
  width: 650px;
  height: 546px;
  overflow: hidden;
  line-height: normal;
  letter-spacing: normal;
}


.plan-and-billing-display-plan-and-billing-display-frame-layout-setti-child,
.plan-and-billing-display-frame-child {
  position: relative;
  background-color: #fff;
  display: none;
}

.plan-and-billing-display-plan-and-billing-display-frame-layout-setti-child {
  align-self: stretch;
  height: 2583px;
  z-index: 1;
}

.plan-and-billing-display-frame-child {
  height: 72px;
  width: 1440px;
  max-width: 100%;
}

.plan-and-billing-display-gerator-04-1-icon {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}

.plan-and-billing-display-logo,
.plan-and-billing-display-logo-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-display-logo {
  align-self: stretch;
  height: 32px;
  flex-direction: row;
  background-image: url("../../icons/Gerator/profile_settings/gerator04-1@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.plan-and-billing-display-logo-wrapper {
  width: 121px;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
}

.plan-and-billing-display-bar-chart-01-icon,
.plan-and-billing-display-dot-icon {
  height: 10px;
  width: 10px;
  position: relative;
  display: none;
}

.plan-and-billing-display-bar-chart-01-icon {
  height: 24px;
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.plan-and-billing-display-text {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 30px;
}

.plan-and-billing-display-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.plan-and-billing-display-text1 {
  align-self: stretch;
  position: relative;
  line-height: 18px;
  font-weight: 500;
}

.plan-and-billing-display-badge {
  height: 22px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
}

.plan-and-billing-display-chevron-down-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.plan-and-billing-display-nav-item-base {
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  gap: 8px;
}

.plan-and-billing-display-text2,
.plan-and-billing-display-text3 {
  position: relative;
  font-family: Inter;
}

.plan-and-billing-display-text2 {
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #18222f;
  text-align: left;
  display: inline-block;
  min-width: 29px;
}

.plan-and-billing-display-text3 {
  align-self: stretch;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #344054;
  text-align: center;
}

.plan-and-billing-display-badge1,
.plan-and-billing-display-nav-item-base1 {
  background-color: #f9fafb;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.plan-and-billing-display-badge1 {
  height: 22px;
  border-radius: 9999px;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  padding: 2px 7px;
}

.plan-and-billing-display-nav-item-base1 {
  cursor: pointer;
  border: 0;
  padding: 8px 12px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  gap: 8px;
}

.plan-and-billing-display-text4 {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 38px;
}

.plan-and-billing-display-navigation,
.plan-and-billing-display-navigation-wrapper {
  display: flex;
  justify-content: flex-start;
}

.plan-and-billing-display-navigation {
  flex-direction: row;
  align-items: center;
  gap: 4px;
  z-index: 1;
}

.plan-and-billing-display-navigation-wrapper {
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
}

.plan-and-billing-display-label {
  width: 47px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.plan-and-billing-display-search-lg-icon,
.plan-and-billing-display-text6 {
  position: relative;
  overflow: hidden;
}

.plan-and-billing-display-search-lg-icon {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
}

.plan-and-billing-display-text6 {
  text-decoration: none;
  flex: 1;
  line-height: 24px;
  color: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.plan-and-billing-display-content3,
.plan-and-billing-display-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.plan-and-billing-display-content3 {
  flex: 1;
  gap: 8px;
}

.plan-and-billing-display-input {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  padding: 8px 13px;
  font-size: 16px;
  color: #667085;
}

.plan-and-billing-display-frame-group,
.plan-and-billing-display-frame-parent,
.plan-and-billing-display-input-with-label {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-display-input-with-label {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  z-index: 1;
  font-size: 14px;
}

.plan-and-billing-display-frame-group,
.plan-and-billing-display-frame-parent {
  flex-direction: row;
  max-width: 100%;
}

.plan-and-billing-display-frame-group {
  flex: 1;
  gap: 38px;
}

.plan-and-billing-display-frame-parent {
  width: 754px;
  gap: 70px;
}

.plan-and-billing-display-icon {
  height: 19px;
  width: 19px;
  position: relative;
}

.plan-and-billing-display-nav-item-button {
  width: 40px;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  z-index: 1;
}

.plan-and-billing-display-nav-item-button-child {
  height: 18.3px;
  width: 14.6px;
  position: relative;
}

.plan-and-billing-display-avatar-button1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3px 0 0;
}

.plan-and-billing-display-contrast-border {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  display: none;
}

.plan-and-billing-display-avatar {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../icons/Gerator/profile_settings/avatar@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.plan-and-billing-display-avatar-button-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}

.plan-and-billing-display-plan-and-billing-display-frame-layout-setti-inner,
.plan-and-billing-display-rectangle-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.plan-and-billing-display-rectangle-parent {
  flex: 1;
  background-color: #fff;
  justify-content: space-between;
  padding: 14px 64px 14px 0;
  top: 0;
  z-index: 2;
  position: sticky;
  gap: 20px;
  text-align: left;
  font-size: 16px;
  color: #344054;
  font-family: Inter;
}

.plan-and-billing-display-plan-and-billing-display-frame-layout-setti-inner {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 0 135px;
}

.plan-and-billing-display-divider-icon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}

.plan-and-billing-display-divider-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 134px;
  box-sizing: border-box;
  max-width: 100%;
  margin-top: -134px;
}

.plan-and-billing-display-home-line-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.plan-and-billing-display-nav-item-button2,
.plan-and-billing-display-nav-item-button3 {
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
}

.plan-and-billing-display-nav-item-button3 {
  background-color: #f9fafb;
}

.plan-and-billing-display-navigation2 {
  padding: 0 16px;
  gap: 8px;
}

.plan-and-billing-display-navigation1,
.plan-and-billing-display-navigation2,
.plan-and-billing-display-navigation3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-display-navigation1 {
  padding: 32px 0 0;
}

.plan-and-billing-display-navigation3 {
  gap: 8px;
}

.plan-and-billing-display-contrast-border1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
}

.plan-and-billing-display-avatar1 {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../icons/Gerator/profile_settings/avatar1@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.plan-and-billing-display-content4,
.plan-and-billing-display-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.plan-and-billing-display-footer {
  justify-content: flex-start;
  padding: 0 16px 24px;
  gap: 24px;
}

.plan-and-billing-display-content4 {
  align-self: stretch;
  border-right: 1px solid #eaecf0;
  justify-content: space-between;
}

.plan-and-billing-display-sub-nav-title {
  text-decoration: none;
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  color: inherit;
}

.plan-and-billing-display-arrow-narrow-left-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.plan-and-billing-display-sub-nav-header {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 13px 0 0;
  gap: 5px;
}

.plan-and-billing-display-text7 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 46px;
}

.plan-and-billing-display-badge3,
.plan-and-billing-display-content6,
.plan-and-billing-display-nav-item-base3 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.plan-and-billing-display-content6 {
  flex: 1;
  display: flex;
  padding: 0 144px 0 0;
  gap: 12px;
}

.plan-and-billing-display-badge3,
.plan-and-billing-display-nav-item-base3 {
  background-color: #f9fafb;
}

.plan-and-billing-display-badge3 {
  height: 22px;
  border-radius: 9999px;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
  color: #344054;
}

.plan-and-billing-display-nav-item-base3 {
  align-self: stretch;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  padding: 8px 12px;
  gap: 8px;
  color: #18222f;
}

.plan-and-billing-display-text9 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 49px;
}

.plan-and-billing-display-content7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 103px 0 0;
  gap: 12px;
}

.plan-and-billing-display-text10 {
  width: 14px;
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 14px;
}

.plan-and-billing-display-badge4 {
  width: 30px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 0 7px;
  text-align: center;
  font-size: 12px;
}

.plan-and-billing-display-badge4,
.plan-and-billing-display-content8,
.plan-and-billing-display-nav-item-base4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.plan-and-billing-display-nav-item-base4 {
  align-self: stretch;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  padding: 8px 12px;
  gap: 8px;
}

.plan-and-billing-display-content8 {
  flex: 1;
  padding: 0 141px 0 0;
  gap: 12px;
}

.plan-and-billing-display-text19 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 48px;
}

.plan-and-billing-display-content12,
.plan-and-billing-display-content5,
.plan-and-billing-display-navigation4 {
  display: flex;
  justify-content: flex-start;
}

.plan-and-billing-display-content12 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 142px 0 0;
  gap: 12px;
}

.plan-and-billing-display-content5,
.plan-and-billing-display-navigation4 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
}

.plan-and-billing-display-navigation4 {
  gap: 4px;
  color: #344054;
}

.plan-and-billing-display-content5 {
  padding: 36px 16px 0;
  gap: 16px;
}

.plan-and-billing-display-supporting-text,
.plan-and-billing-display-title {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.plan-and-billing-display-title {
  font-weight: 600;
}

.plan-and-billing-display-supporting-text {
  color: #475467;
  white-space: nowrap;
}

.plan-and-billing-display-text21 {
  width: 214px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-display-buttonsbutton {
  margin: 0 !important;
  position: absolute;
  top: -8px;
  right: 12px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.plan-and-billing-display-account,
.plan-and-billing-display-sub-nav {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}

.plan-and-billing-display-account {
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 20px 24px;
  position: relative;
  gap: 47px;
  font-size: 14px;
}

.plan-and-billing-display-sub-nav {
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.plan-and-billing-display-breadcrumb-button-base,
.plan-and-billing-display-sidebar-navigation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-display-sidebar-navigation {
  height: 1260px;
  width: 362px;
  background-color: #fff;
  border-right: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  max-width: 100%;
  z-index: 1;
}

.plan-and-billing-display-breadcrumb-button-base {
  border-radius: 6px;
  padding: 4px;
}

.plan-and-billing-display-chevron-right-icon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.plan-and-billing-display-text22 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 74px;
}

.plan-and-billing-display-breadcrumb-button-base1 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.plan-and-billing-display-text23 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.plan-and-billing-display-breadcrumb-button-base2 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 7px;
}

.plan-and-billing-display-chevron-right-icon2 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.plan-and-billing-display-text24 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  white-space: nowrap;
}

.plan-and-billing-display-breadcrumb-button-base3 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.plan-and-billing-display-text25 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 57px;
}

.plan-and-billing-display-breadcrumb-button-base4 {
  border-radius: 6px;
  background-color: #f9fafb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  color: #344054;
}

.plan-and-billing-display-tabs {
  gap: 8px;
}

.plan-and-billing-display-breadcrumbs,
.plan-and-billing-display-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.plan-and-billing-display-divider-icon1 {
  width: 255px;
  height: 1px;
  position: relative;
  display: none;
}

.plan-and-billing-display-page-header {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.plan-and-billing-display-container {
  padding: 0 32px;
}

.plan-and-billing-display-container,
.plan-and-billing-display-header-section {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-display-main-child {
  width: 1px;
  height: 29px;
  position: relative;
  border-right: 1px solid #000;
}

.plan-and-billing-display-main,
.plan-and-billing-display-main-child,
.plan-and-billing-display-main-item {
  flex-shrink: 0;
  box-sizing: border-box;
}

.plan-and-billing-display-main-item {
  width: 1px;
  height: 43px;
  position: relative;
  border-right: 1px solid #000;
}

.plan-and-billing-display-main {
  align-self: stretch;
  height: 60px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 0 0;
  gap: 32px;
  z-index: 1;
}

.plan-and-billing-display-text26 {
  height: 20px;
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
}

.plan-and-billing-display-breadcrumb-button-base6,
.plan-and-billing-display-breadcrumb-button-base7 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.plan-and-billing-display-breadcrumb-button-base6 {
  flex: 1;
  padding: 4px 8px;
  min-width: 47px;
}

.plan-and-billing-display-breadcrumb-button-base7 {
  height: 28px;
  padding: 4px 7px;
}

.plan-and-billing-display-text29 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.plan-and-billing-display-breadcrumbs1,
.plan-and-billing-display-tabs1 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.plan-and-billing-display-tabs1 {
  flex: 1;
  display: flex;
  gap: 8px;
}

.plan-and-billing-display-breadcrumbs1 {
  width: 278px;
  display: none;
}

.plan-and-billing-display-page-title {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 38px;
  font-weight: 600;
  font-family: inherit;
}

.plan-and-billing-display-supporting-text1 {
  width: 445px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  display: none;
  max-width: 100%;
}

.plan-and-billing-display-text-and-supporting-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 320px;
  max-width: 100%;
  font-size: 30px;
}

.plan-and-billing-display-placeholder-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.plan-and-billing-display-text-padding {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.plan-and-billing-display-button,
.plan-and-billing-display-button1,
.plan-and-billing-display-button2 {
  height: 40px;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  gap: 4px;
}

.plan-and-billing-display-button {
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
}

.plan-and-billing-display-button1,
.plan-and-billing-display-button2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: #fff;
  padding: 10px 13px;
}

.plan-and-billing-display-button1 {
  border: 1px solid #d6bbfb;
  flex-direction: row;
  align-items: center;
  color: #6941c6;
}

.plan-and-billing-display-button2 {
  border: 1px solid #d0d5dd;
  color: #344054;
}

.plan-and-billing-display-actions,
.plan-and-billing-display-button2,
.plan-and-billing-display-button3 {
  flex-direction: row;
  align-items: center;
}

.plan-and-billing-display-button3 {
  height: 40px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
  color: #fff;
}

.plan-and-billing-display-actions {
  display: none;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
  color: #475467;
}

.plan-and-billing-display-text34 {
  width: calc(100% - 20px);
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  flex: 1;
  position: relative;
  line-height: 24px;
  color: #667085;
  text-align: left;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 158px;
  padding: 0;
}

.plan-and-billing-display-input-with-label1,
.plan-and-billing-display-input1 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.plan-and-billing-display-input1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
}

.plan-and-billing-display-input-with-label1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.plan-and-billing-display-hint-text {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.plan-and-billing-display-content13,
.plan-and-billing-display-input-dropdown {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-display-input-dropdown {
  flex: 1;
  flex-direction: column;
  gap: 8px;
  min-width: 200px;
  max-width: 320px;
  color: #344054;
}

.plan-and-billing-display-content13 {
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 2px 0 0;
  box-sizing: border-box;
  gap: 16px 14px;
  color: #101828;
}

.plan-and-billing-display-content13,
.plan-and-billing-display-divider-icon2,
.plan-and-billing-display-page-header1 {
  align-self: stretch;
  max-width: 100%;
}

.plan-and-billing-display-divider-icon2 {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  display: none;
}

.plan-and-billing-display-page-header1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.plan-and-billing-display-text35 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 44px;
}

.plan-and-billing-display-tab-button-base {
  border-bottom: 2px solid #7e56d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 4px 9px;
  color: #6941c6;
}

.plan-and-billing-display-text36 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 62px;
}

.plan-and-billing-display-tab-button-base1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 4px 12px;
}

.plan-and-billing-display-badge-count,
.plan-and-billing-display-text38 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 38px;
}

.plan-and-billing-display-badge-count {
  line-height: 18px;
  font-weight: 500;
  min-width: 8px;
}

.plan-and-billing-display-badge10,
.plan-and-billing-display-tab-button-base3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.plan-and-billing-display-badge10 {
  width: 24px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  justify-content: flex-start;
  padding: 0 7px;
  text-align: center;
  font-size: 12px;
  color: #344054;
}

.plan-and-billing-display-tab-button-base3 {
  height: 32px;
  justify-content: center;
  padding: 0 4px 11px;
  gap: 8px;
}

.plan-and-billing-display-text39,
.plan-and-billing-display-text40,
.plan-and-billing-display-text43 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 30px;
}

.plan-and-billing-display-text40,
.plan-and-billing-display-text43 {
  text-decoration: none;
  color: inherit;
  min-width: 42px;
}

.plan-and-billing-display-text43 {
  min-width: 83px;
}

.plan-and-billing-display-tab-button-base7 {
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 4px 11px;
  box-sizing: border-box;
  gap: 7px;
}

.plan-and-billing-display-text45,
.plan-and-billing-display-text46 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 82px;
}

.plan-and-billing-display-text46 {
  min-width: 24px;
}

.plan-and-billing-display-tab-button-base9,
.plan-and-billing-display-tabs2 {
  display: flex;
  flex-direction: row;
}

.plan-and-billing-display-tab-button-base9 {
  width: 31px;
  align-items: center;
  justify-content: center;
  padding: 0 3px 12px;
  box-sizing: border-box;
}

.plan-and-billing-display-tabs2 {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
}

.plan-and-billing-display-container1,
.plan-and-billing-display-horizontal-tabs {
  align-self: stretch;
  flex-direction: column;
  box-sizing: border-box;
}

.plan-and-billing-display-horizontal-tabs {
  border-bottom: 1px solid #eaecf0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  color: #667085;
}

.plan-and-billing-display-container1 {
  padding: 0 32px;
  gap: 24px;
}

.plan-and-billing-display-container1,
.plan-and-billing-display-header-content,
.plan-and-billing-display-header-section1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.plan-and-billing-display-header-section1 {
  flex: 1;
  flex-direction: column;
}

.plan-and-billing-display-header-content {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 12px;
  box-sizing: border-box;
}

.plan-and-billing-display-text47 {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.plan-and-billing-display-button4,
.plan-and-billing-display-button5,
.plan-and-billing-display-text-padding4 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.plan-and-billing-display-text-padding4 {
  align-self: stretch;
  flex: 1;
  display: flex;
  padding: 0 2px;
}

.plan-and-billing-display-button4,
.plan-and-billing-display-button5 {
  width: 86px;
  border-radius: 8px;
  overflow: hidden;
  display: none;
  padding: 10px 14px;
  box-sizing: border-box;
  gap: 4px;
}

.plan-and-billing-display-button5 {
  width: 106px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: #fff;
  border: 1px solid #d6bbfb;
  padding: 10px 13px;
  color: #6941c6;
}

.plan-and-billing-display-dots-vertical-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.plan-and-billing-display-dropdown {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-display-supporting-text2,
.plan-and-billing-display-toggle-title {
  align-self: stretch;
  position: relative;
}

.plan-and-billing-display-toggle-title {
  line-height: 28px;
  font-weight: 600;
}

.plan-and-billing-display-supporting-text2 {
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.plan-and-billing-display-toggle-header {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 320px;
  max-width: 100%;
}

.plan-and-billing-display-frame-item {
  height: 40px;
  width: 156px;
  position: relative;
  background-color: #fff;
  display: none;
}

.plan-and-billing-display-button6,
.plan-and-billing-display-toggle-base {
  border-radius: 9999px;
  overflow: hidden;
  flex-shrink: 0;
}

.plan-and-billing-display-button6 {
  height: 16px;
  width: 16px;
  position: relative;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  background-color: #fff;
  position: absolute;
  transition: left 0.3s ease;
}

.plan-and-billing-display-button6.left {
  left: 0;
}

.plan-and-billing-display-button6.right {
  left: 20px;
}

.plan-and-billing-display-toggle-base {
  height: 20px;
  width: 36px;
  background-color: #f2f4f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px;
  box-sizing: border-box;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.plan-and-billing-display-supporting-text3
   {
  position: relative;
  line-height: 20px;
}

.plan-and-billing-display-switch-label {
  letter-spacing: -0.02px;
  font-weight: 500;
  display: inline-block;
  min-width: 97px;
  z-index: 1;
  position: relative;
  line-height: 20px;
}

.plan-and-billing-display-switch-label1 {
    letter-spacing: -0.02px;
    font-weight: 500;
    display: inline-block;
    position: relative;
    line-height: 20px;
    z-index: 1;
  }

.plan-and-billing-display-switch-label1.active,
.plan-and-billing-display-switch-label.active {
  font-weight: bold;
  background-color: transparent;
  color: #6941C6;
}


.plan-and-billing-display-supporting-text3 {
  align-self: stretch;
  width: 300px;
  color: #475467;
  display: none;
}

.plan-and-billing-display-rectangle-group {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0 10px 15px;
  gap: 8px;
}

.plan-and-billing-display-button-labels {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 63px;
}

.plan-and-billing-display-button7 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 16px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-display-text49 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  /* min-width: 110px; */
}

.plan-and-billing-display-button8,
.plan-and-billing-display-toggle-controls,
.plan-and-billing-display-toggle-header-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-display-button8 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 16px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  width: max-content;
}

.plan-and-billing-display-toggle-controls,
.plan-and-billing-display-toggle-header-parent {
  gap: 12px;
  max-width: 100%;
  font-size: 14px;
  color: #344054;
}

.plan-and-billing-display-toggle-header-parent {
  align-self: stretch;
  gap: 16px;
  font-size: 18px;
  color: #101828;
}

.plan-and-billing-display-divider-icon3 {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.plan-and-billing-display-divider-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 4px;
  box-sizing: border-box;
  max-width: 100%;
}

.plan-and-billing-display-text50 {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.plan-and-billing-display-section-label {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 200px;
  max-width: 280px;
}

.plan-and-billing-display-label2 {
  width: 36px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.plan-and-billing-display-content16 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Inter;
  font-size: 16px;
  color: #101828;
  min-width: 130px;
}

.plan-and-billing-display-help-icon {
  height: 16px;
  width: 16px;
  position: relative;
  display: none;
}

.plan-and-billing-display-input-field,
.plan-and-billing-display-input2 {
  display: flex;
  justify-content: flex-start;
}

.plan-and-billing-display-input2 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
}

.plan-and-billing-display-input-field {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  min-width: 159px;
}

.plan-and-billing-display-label3 {
  width: 96px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.plan-and-billing-display-content17 {
  width: calc(100% - 46px);
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  color: #101828;
  min-width: 113px;
}

.plan-and-billing-display-content15,
.plan-and-billing-display-content17,
.plan-and-billing-display-input-fields,
.plan-and-billing-display-input3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.plan-and-billing-display-input3 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
}

.plan-and-billing-display-content15,
.plan-and-billing-display-input-fields {
  align-items: flex-start;
}

.plan-and-billing-display-input-fields {
  flex: 1;
  gap: 24px;
  min-width: 480px;
  max-width: 512px;
}

.plan-and-billing-display-content15 {
  align-self: stretch;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16px 32px;
  max-width: 100%;
}

.plan-and-billing-display-divider {
  align-self: stretch;
  height: 1px;
  position: relative;
  background-color: #eaecf0;
}

.plan-and-billing-display-section-label1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 200px;
  max-width: 280px;
}

.plan-and-billing-display-extra-input-info {
  width: calc(100% - 40px);
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  flex: 1;
  position: relative;
  line-height: 24px;
  color: #101828;
  text-align: left;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 250px;
  max-width: calc(100% - 28px);
  padding: 0;
}

.plan-and-billing-display-content19,
.plan-and-billing-display-input4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.plan-and-billing-display-content19 {
  flex: 1;
  max-width: calc(100% - 28px);
}

.plan-and-billing-display-input4 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  padding: 8px 13px;
  max-width: 100%;
}

.plan-and-billing-display-hint-text2 {
  width: 320px;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.plan-and-billing-display-content18,
.plan-and-billing-display-input-field1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-display-input-field1 {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  min-width: 480px;
  max-width: 512px;
}

.plan-and-billing-display-content18 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 102px 0 0;
  box-sizing: border-box;
  gap: 16px 32px;
  max-width: 100%;
}

.plan-and-billing-display-upload-title {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 75px;
}

.plan-and-billing-display-help-icon3 {
  width: 16px;
  height: 16px;
  position: relative;
}

.plan-and-billing-display-label-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}

.plan-and-billing-display-supporting-text4 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: #475467;
}

.plan-and-billing-display-avatar2 {
  height: 64px;
  width: 64px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../icons/Gerator/profile_settings/avatar2@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.plan-and-billing-display-featured-icon {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 8px;
}

.plan-and-billing-display-text54 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 101px;
}

.plan-and-billing-display-buttonsbutton1 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.plan-and-billing-display-text55 {
  position: relative;
  line-height: 20px;
  color: #475467;
  display: inline-block;
  min-width: 112px;
}

.plan-and-billing-display-action {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px;
  gap: 4px;
}

.plan-and-billing-display-supporting-text5 {
  align-self: stretch;
  position: relative;
  font-size: 12px;
  line-height: 18px;
  color: #475467;
  text-align: center;
}

.plan-and-billing-display-text-and-supporting-text1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.plan-and-billing-display-page-icon {
  position: absolute;
  height: 100%;
  width: 80%;
  top: 0;
  right: 2.5%;
  bottom: 0;
  left: 17.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.plan-and-billing-display-file-type {
  position: relative;
  display: inline-block;
  min-width: 20px;
}

.plan-and-billing-display-file-type-wrap {
  position: absolute;
  height: 40%;
  width: 65%;
  top: 45%;
  right: 32.5%;
  bottom: 15%;
  left: 2.5%;
  border-radius: 2px;
  background-color: #7e56d9;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 3px;
  box-sizing: border-box;
  z-index: 1;
}

.plan-and-billing-display-cursor-icon,
.plan-and-billing-display-file-type-icon {
  position: absolute;
  margin: 0 !important;
}

.plan-and-billing-display-file-type-icon {
  width: 40px;
  height: 40px;
  right: 0;
  bottom: 4px;
  filter: drop-shadow(0 24px 48px rgba(16, 24, 40, 0.18));
  z-index: 1;
  text-align: center;
  font-size: 10px;
  color: #fff;
}

.plan-and-billing-display-cursor-icon {
  width: 20px;
  height: 20px;
  right: -8px;
  bottom: -4px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 2;
}

.plan-and-billing-display-content23,
.plan-and-billing-display-file-upload-base {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.plan-and-billing-display-content23 {
  align-self: stretch;
  align-items: center;
  position: relative;
  gap: 12px;
}

.plan-and-billing-display-file-upload-base {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 2px solid #7e56d9;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 13px 24px;
  min-width: 278px;
  max-width: 100%;
}

.plan-and-billing-display-avatar-and-file-upload,
.plan-and-billing-display-content25 {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.plan-and-billing-display-avatar-and-file-upload {
  align-items: flex-start;
  gap: 20px;
  min-width: 480px;
  max-width: 512px;
  color: #6941c6;
}

.plan-and-billing-display-content25 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  color: #101828;
  min-width: 250px;
  max-width: 100%;
}

.plan-and-billing-display-supporting-text6,
.plan-and-billing-display-text59 {
  position: relative;
  line-height: 24px;
}

.plan-and-billing-display-text59 {
  font-weight: 500;
  display: inline-block;
  min-width: 68px;
}

.plan-and-billing-display-supporting-text6 {
  align-self: stretch;
  width: 54px;
  color: #475467;
  display: none;
}

.plan-and-billing-display-content29 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 360px 0 0;
  box-sizing: border-box;
  max-width: calc(100% - 28px);
}

.plan-and-billing-display-content29,
.plan-and-billing-display-input-dropdown1,
.plan-and-billing-display-input8 {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.plan-and-billing-display-input8 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  max-width: 100%;
  font-size: 16px;
  color: #101828;
}

.plan-and-billing-display-input-dropdown1 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  min-width: 480px;
  max-width: 512px;
}

.plan-and-billing-display-text61 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 126px;
}

.plan-and-billing-display-content31 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  max-width: 100%;
}

.plan-and-billing-display-input9 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  padding: 8px 13px;
  font-size: 16px;
  color: #101828;
}

.plan-and-billing-display-content33,
.plan-and-billing-display-input10,
.plan-and-billing-display-input9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}

.plan-and-billing-display-content33 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  color: #101828;
  min-width: 250px;
}

.plan-and-billing-display-input10 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  padding: 8px 13px;
}

.plan-and-billing-display-label-title {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 67px;
}

.plan-and-billing-display-help-icon6 {
  height: 16px;
  width: 16px;
  position: relative;
}

.plan-and-billing-display-heading-and-help-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
}

.plan-and-billing-display-supporting-text8,
.plan-and-billing-display-text66 {
  position: relative;
  line-height: 24px;
}

.plan-and-billing-display-text66 {
  font-weight: 500;
}

.plan-and-billing-display-supporting-text8 {
  color: #475467;
  display: inline-block;
  min-width: 89px;
  white-space: nowrap;
}

.plan-and-billing-display-content41 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 116px 0 0;
  box-sizing: border-box;
  gap: 8px;
}

.plan-and-billing-display-content41,
.plan-and-billing-display-form-content,
.plan-and-billing-display-form-content-wrapper,
.plan-and-billing-display-input14 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.plan-and-billing-display-input14 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
  font-size: 16px;
  color: #101828;
}

.plan-and-billing-display-form-content,
.plan-and-billing-display-form-content-wrapper {
  align-items: flex-start;
}

.plan-and-billing-display-form-content {
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.plan-and-billing-display-form-content-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 32px;
  box-sizing: border-box;
  color: #344054;
}

.plan-and-billing-display-write-a-short {
  margin-block-start: 0;
  margin-block-end: 14px;
}

.plan-and-billing-display-this-is-also {
  margin: 0;
}

.plan-and-billing-display-dropdown-placeholder {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 59px;
}

.plan-and-billing-display-content43 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  min-width: 59px;
}

.plan-and-billing-display-content43,
.plan-and-billing-display-input-dropdown8,
.plan-and-billing-display-input15 {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.plan-and-billing-display-input15 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  font-size: 16px;
  color: #101828;
}

.plan-and-billing-display-input-dropdown8 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  min-width: 156px;
  max-width: 240px;
}

.plan-and-billing-display-wysiwyg-editor-icon,
.plan-and-billing-display-wysiwyg-editor-icon2 {
  height: 32px;
  width: 32px;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 32px;
}

.plan-and-billing-display-wysiwyg-editor-icon2 {
  display: none;
}

.plan-and-billing-display-icons,
.plan-and-billing-display-wysiwyg-toolbar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.plan-and-billing-display-wysiwyg-toolbar {
  align-self: stretch;
  align-items: center;
  padding: 0 84px 0 0;
  gap: 12px;
}

.plan-and-billing-display-label16 {
  width: 77px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.plan-and-billing-display-text68 {
  height: 130px;
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: 100%;
}

.plan-and-billing-display-input16,
.plan-and-billing-display-textarea-input-field {
  align-self: stretch;
  align-items: flex-start;
  max-width: 100%;
}

.plan-and-billing-display-input16 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 13px;
  font-size: 16px;
  color: #101828;
}

.plan-and-billing-display-textarea-input-field {
  gap: 6px;
}

.plan-and-billing-display-content45,
.plan-and-billing-display-file-upload-base1,
.plan-and-billing-display-textarea-input-field,
.plan-and-billing-display-toolbar-and-text-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.plan-and-billing-display-toolbar-and-text-input {
  flex: 1;
  align-items: flex-start;
  gap: 12px;
  min-width: 480px;
  max-width: 512px;
}

.plan-and-billing-display-content45,
.plan-and-billing-display-file-upload-base1 {
  align-self: stretch;
  align-items: center;
}

.plan-and-billing-display-content45 {
  gap: 12px;
}

.plan-and-billing-display-file-upload-base1 {
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  padding: 14px 23px;
}

.plan-and-billing-display-page-icon1 {
  height: 40px;
  width: 32px;
  position: absolute;
  margin: 0 !important;
  top: -18px;
  right: -12px;
}

.plan-and-billing-display-file-type-wrap1 {
  border-radius: 2px;
  background-color: #da2d20;
  flex-direction: row;
  padding: 2px 3px;
  z-index: 1;
}

.plan-and-billing-display-file-type-wrap1,
.plan-and-billing-display-item-container,
.plan-and-billing-display-item-info-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-display-item-info-container {
  flex-direction: row;
  position: relative;
}

.plan-and-billing-display-item-container {
  flex-direction: column;
  padding: 18px 0 0;
}

.plan-and-billing-display-supporting-text13,
.plan-and-billing-display-uploaded-file-name {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.plan-and-billing-display-uploaded-file-name {
  font-weight: 500;
}

.plan-and-billing-display-supporting-text13 {
  color: #475467;
}

.plan-and-billing-display-background,
.plan-and-billing-display-progress {
  align-self: stretch;
  width: 379px;
  position: relative;
  border-radius: 9999px;
  background-color: #eaecf0;
  display: none;
  max-width: 100%;
}

.plan-and-billing-display-progress {
  background-color: #7e56d9;
}

.plan-and-billing-display-item-progress-info,
.plan-and-billing-display-progress-bar {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.plan-and-billing-display-progress-bar {
  width: 379px;
  height: 8px;
  border-radius: 9999px;
  background-color: #7e56d9;
  flex-direction: row;
  z-index: 1;
}

.plan-and-billing-display-item-progress-info {
  flex: 1;
  flex-direction: column;
  gap: 10px;
  min-width: 278px;
}

.plan-and-billing-display-checkbox-base-icon {
  height: 16px;
  width: 16px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
}

.plan-and-billing-display-select-file-checkbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 21px;
}

.plan-and-billing-display-percentage {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 37px;
}

.plan-and-billing-display-item-status {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 28px;
  margin-left: -37px;
}

.plan-and-billing-display-file-upload-item-base,
.plan-and-billing-display-item-progress-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.plan-and-billing-display-item-progress-container {
  flex: 1;
  justify-content: center;
  min-width: 278px;
  row-gap: 20px;
  text-align: left;
  font-size: 14px;
  color: #344054;
}

.plan-and-billing-display-file-upload-item-base {
  align-self: stretch;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  justify-content: flex-start;
  padding: 14px 15px 14px 16px;
  gap: 25px;
}

.plan-and-billing-display-page-icon2 {
  height: 40px;
  width: 32px;
  position: absolute;
  margin: 0 !important;
  top: -18px;
  right: -9px;
}

.plan-and-billing-display-file-type2 {
  position: relative;
  display: inline-block;
  min-width: 23px;
}

.plan-and-billing-display-file-type-wrap2 {
  border-radius: 2px;
  background-color: #155eef;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 3px;
  z-index: 1;
}

.plan-and-billing-display-text71 {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.plan-and-billing-display-buttonsbutton3 {
  margin: 0 !important;
  position: absolute;
  top: calc(50% - 18px);
  right: -8px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.plan-and-billing-display-progress-bar-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  max-width: 100%;
}

.plan-and-billing-display-background1,
.plan-and-billing-display-progress1 {
  position: relative;
  border-radius: 9999px;
}

.plan-and-billing-display-background1 {
  align-self: stretch;
  width: 386px;
  background-color: #eaecf0;
  display: none;
  max-width: 100%;
}

.plan-and-billing-display-progress1 {
  height: 8px;
  width: 155.5px;
  background-color: #7e56d9;
  z-index: 1;
}

.plan-and-billing-display-progress-bar-wrapper,
.plan-and-billing-display-progress-bar1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.plan-and-billing-display-progress-bar1 {
  align-self: stretch;
  border-radius: 9999px;
  background-color: #eaecf0;
  flex-direction: row;
}

.plan-and-billing-display-progress-bar-wrapper {
  flex: 1;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
  min-width: 251px;
}

.plan-and-billing-display-percentage1 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 33px;
}

.plan-and-billing-display-progress-bar-container1 {
  align-self: stretch;
  flex-direction: row;
  gap: 9px;
}

.plan-and-billing-display-file-upload-item-base1,
.plan-and-billing-display-progress-bar-container-parent,
.plan-and-billing-display-progress-bar-container1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.plan-and-billing-display-progress-bar-container-parent {
  flex: 1;
  flex-direction: column;
  gap: 2px;
  min-width: 278px;
  text-align: left;
  font-size: 14px;
  color: #344054;
}

.plan-and-billing-display-file-upload-item-base1 {
  align-self: stretch;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  flex-direction: row;
  padding: 14px 15px 14px 16px;
  gap: 22px;
}

.plan-and-billing-display-page-icon3 {
  height: 40px;
  width: 32px;
  position: absolute;
  margin: 0 !important;
  top: -18px;
  right: -15px;
}

.plan-and-billing-display-file-type3 {
  position: relative;
  display: inline-block;
  min-width: 17px;
}

.plan-and-billing-display-file-type-wrap3,
.plan-and-billing-display-progress2 {
  background-color: #7e56d9;
  z-index: 1;
}

.plan-and-billing-display-file-type-wrap3 {
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 3px;
}

.plan-and-billing-display-progress2 {
  height: 8px;
  width: 311.1px;
  position: relative;
  border-radius: 9999px;
}

.plan-and-billing-display-percentage2 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 32px;
}

.plan-and-billing-display-file-upload-item-base2,
.plan-and-billing-display-frame-div {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  max-width: 100%;
}

.plan-and-billing-display-file-upload-item-base2 {
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 14px 15px 14px 16px;
  gap: 28px;
}

.plan-and-billing-display-file-queue {
  align-self: stretch;
  flex-direction: column;
  gap: 12px;
  max-width: 100%;
  text-align: center;
  font-size: 10px;
  color: #fff;
}

.plan-and-billing-display-content-wrapper,
.plan-and-billing-display-content44,
.plan-and-billing-display-file-queue,
.plan-and-billing-display-file-upload {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-display-file-upload {
  flex: 1;
  flex-direction: column;
  gap: 16px;
  min-width: 480px;
  max-width: 512px;
  color: #6941c6;
}

.plan-and-billing-display-content-wrapper,
.plan-and-billing-display-content44 {
  flex-direction: row;
  max-width: 100%;
}

.plan-and-billing-display-content44 {
  flex: 1;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16px 32px;
}

.plan-and-billing-display-content-wrapper {
  align-self: stretch;
  padding: 0 32px 4px;
  box-sizing: border-box;
  color: #344054;
}

.plan-and-billing-display-divider-icon4,
.plan-and-billing-display-text73 {
  align-self: stretch;
  position: relative;
}

.plan-and-billing-display-divider-icon4 {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.plan-and-billing-display-text73 {
  line-height: 20px;
  font-weight: 600;
  white-space: nowrap;
}

.plan-and-billing-display-button9,
.plan-and-billing-display-buttonsbutton5 {
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.plan-and-billing-display-buttonsbutton5 {
  height: 20px;
  gap: 6px;
}

.plan-and-billing-display-button9 {
  align-self: stretch;
  border-radius: 8px;
  padding: 10px 14px;
  gap: 4px;
}

.plan-and-billing-display-text75 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 48px;
}

.plan-and-billing-display-button10,
.plan-and-billing-display-text-padding7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.plan-and-billing-display-button10 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  background-color: #fff;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  gap: 4px;
  min-width: 52px;
}

.plan-and-billing-display-text76 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 34px;
}

.plan-and-billing-display-text-padding8 {
  width: 37px;
  justify-content: center;
  padding: 0 1px;
  box-sizing: border-box;
}

.plan-and-billing-display-actions1,
.plan-and-billing-display-button11,
.plan-and-billing-display-content46,
.plan-and-billing-display-text-padding8 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.plan-and-billing-display-button11 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  width: 65px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  gap: 4px;
}

.plan-and-billing-display-actions1,
.plan-and-billing-display-content46 {
  justify-content: flex-end;
  max-width: 100%;
}

.plan-and-billing-display-actions1 {
  flex: 1;
  padding: 0 0 0 769px;
  box-sizing: border-box;
  gap: 12px;
}

.plan-and-billing-display-content46 {
  align-self: stretch;
  gap: 20px;
}

.plan-and-billing-display-main1,
.plan-and-billing-display-section-footer,
.plan-and-billing-display-upload-footer {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.plan-and-billing-display-section-footer {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.plan-and-billing-display-main1,
.plan-and-billing-display-upload-footer {
  align-items: flex-start;
  box-sizing: border-box;
}

.plan-and-billing-display-upload-footer {
  align-self: stretch;
  flex-direction: row;
  padding: 0 32px;
}

.plan-and-billing-display-main1 {
  flex: 1;
  background-color: #fff;
  overflow: hidden;
  flex-direction: column;
  padding: 32px 0 48px;
  gap: 20px;
  z-index: 1;
}

.plan-and-billing-display-main-wrapper {
  width: 1038px;
  flex-direction: row;
  padding: 0 24px;
  box-sizing: border-box;
}

.plan-and-billing-display-main-parent,
.plan-and-billing-display-main-wrapper,
.plan-and-billing-display-sidebar-navigation-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.plan-and-billing-display-main-parent {
  flex: 1;
  flex-direction: column;
  min-width: 701px;
  font-size: 14px;
  color: #475467;
}

.plan-and-billing-display-sidebar-navigation-parent {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 0 137px;
  box-sizing: border-box;
  row-gap: 20px;
  margin-top: -134px;
  text-align: left;
  font-size: 16px;
  color: #101828;
  font-family: Inter;
}

.plan-and-billing-display-footer-text {
  position: relative;
  line-height: 20px;
}

.plan-and-billing-display-logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.plan-and-billing-display-gerator-10-2-icon {
  height: 38px;
  width: 38px;
  position: relative;
  object-fit: cover;
}

.plan-and-billing-display-logomark1 {
  box-shadow: 0 1.3px 3.75px rgba(16, 24, 40, 0.1),
    0 1.3px 2.5px rgba(16, 24, 40, 0.06);
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-display-logo-wrapper1,
.plan-and-billing-display-logomark,
.plan-and-billing-display-logomark1 {
  display: flex;
  flex-direction: row;
}

.plan-and-billing-display-logomark {
  filter: drop-shadow(0 1.3px 3.75px rgba(16, 24, 40, 0.1)) drop-shadow(0 1.3px 2.5px rgba(16, 24, 40, 0.06));
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-display-logo-wrapper1 {
  align-items: center;
  justify-content: center;
  gap: 29px;
}

.plan-and-billing-display-content47,
.plan-and-billing-display-logo1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-display-content47 {
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  gap: 20px;
}

.plan-and-billing-display-container2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 32px;
  box-sizing: border-box;
  max-width: 1280px;
}

.plan-and-billing-display-divider-icon5,
.plan-and-billing-display-footer1 {
  align-self: stretch;
  overflow: hidden;
  margin-top: -134px;
}

.plan-and-billing-display-footer1 {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 48px 80px;
  z-index: 1;
  text-align: left;
  font-size: 16px;
  color: #475467;
  font-family: Inter;
}

.plan-and-billing-display-divider-icon5 {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  z-index: 2;
}

.plan-and-billing-display-plan-and-billing-display-frame-layout-setti,
.plan-and-billing-display-plan-and-billing-display-frame-layout-setti1 {
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-display-plan-and-billing-display-frame-layout-setti1 {
  flex: 1;
  flex-direction: column;
  padding: 7px 0 0;
  box-sizing: border-box;
  min-height: 1480px;
  max-width: 100%;
}

.plan-and-billing-display-plan-and-billing-display-frame-layout-setti {
  width: 100%;
  position: relative;
  overflow: hidden;
  flex-direction: row;
  line-height: normal;
  letter-spacing: normal;
}

.plan-and-billing-display-content-display-div {
  width: 40%;

  height: 300px;
  box-shadow: 0px 0px 10px rgba(128, 128, 128, 0.5);
  border: none;
  border-radius: 10px;
}

.plan-and-billing-display-toggle-header-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 12px;
  max-width: 100%;
  font-size: 14px;
  color: #344054;

  align-self: stretch;
  gap: 16px;
  font-size: 18px;
  color: #101828;
}

.plan-and-billing-display-toggle-title1 {
  line-height: 28px;
  font-weight: 600;
  margin: 25px auto;
  color: #7e56d9;
  text-decoration: underline;
}

.plan-and-billing-display-supporting-text2a {
  align-self: stretch;
  position: relative;
}

.plan-and-billing-display-supporting-text2a {
  font-size: 16px;
  line-height: 20px;
  color: #475467;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: auto;
}

.plan-and-billing-display-eye-lg-icon {
  position: relative;
  overflow: hidden;
}

.plan-and-billing-display-eye-lg-icon {
  height: 25px;
  width: 25px;
  flex-shrink: 0;
  top: 120px;
  left: 150px;

}

.plan-and-billing-display-pencil-lg-icon {
  position: relative;
  overflow: hidden;
}

.plan-and-billing-display-pencil-lg-icon {
  height: 25px;
  width: 25px;
  flex-shrink: 0;
  top: 120px;
  left: 160px;
  color: black;
}


/* New Css */

.plan-and-billing-display-CheckboxGroupItem {
  width: 768px;
 
  padding: 16px 16px 16px 16px;
  background: white;
  border-radius: 12px;
  border: 2px #EAECF0 solid;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  height: max-content;
  flex-direction: column;
  position: relative;
}

.plan-and-billing-display-CheckboxGroupItem:hover{
  border-color: #6941C6;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.plan-and-billing-display-CheckboxGroupItem1 {
  width: 768px;
  /* height: 114px; */
  height: max-content;
  padding: 16px 16px 16px 16px;
  background: white;
  border-radius: 12px;
  border: 2px #7F56D9 solid;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
  flex-direction: column;
}

.plan-and-billing-display-Content {
  flex: 1 1 0;
  height: max-content;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}



.plan-and-billing-display-Input {
  padding-top: 2px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.plan-and-billing-display-CheckboxBase {
  width: 20px;
  height: 20px;
  padding: 3px;

  border-radius: 6px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
}

.plan-and-billing-display-CheckboxBase input[type="checkbox"] {
  accent-color: #7F56D9;
}

.plan-and-billing-display-Check {
  width: 14px;
  height: 14px;
  padding-top: 3.50px;
  padding-bottom: 4.08px;
  padding-left: 2.33px;
  padding-right: 2.33px;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.plan-and-billing-display-Icon {
  width: 9.33px;
  height: 6.42px;
  border: 2px white solid;
}

.plan-and-billing-display-TextAndSupportingText {
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  height: max-content;
  /* overflow: hidden; */
}

.plan-and-billing-display-TextAndSubtext {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
}

.plan-and-billing-display-Text-1 {
  color: #344054;
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;

}

.plan-and-billing-display-Subtext {
  color: #475467;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.plan-and-billing-display-SupportingText {
  align-self: stretch;
  color: #475467;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.plan-and-billing-display-Actions {
  /* width: 133px; */
  width: max-content;
  height: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  /* gap: 22px; */
  position: relative;
  /* top: 14px; */
  display: inline-flex;
}

.plan-and-billing-display-ButtonsButton {
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: flex;
}

.plan-and-billing-display-Text-2 {
  color: #475467;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
}

.plan-and-billing-display-Text-3 {
  color: #6941C6;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
}

.plan-and-billing-display-buttonsbutton-wrapper,
.plan-and-billing-display-buttonsbutton19 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.plan-and-billing-display-buttonsbutton-wrapper {
  align-self: stretch;
  justify-content: flex-end;
  /* padding: 0 27px 0 0; */
  /* margin-top: 20px; */
  cursor: pointer;
  position: absolute;
  top: 32px;
  right: 35px;
}

.plan-and-billing-display-buttonsbutton19 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  justify-content: flex-start;
  padding: 6px 12px;
  gap: 5px;
  z-index: 1;
}

.plan-and-billing-display-buttonsbutton19:hover{
  border-color: #6941C6;
}

.plan-and-billing-display-arrow-narrow-left-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.plan-and-billing-display-search-input {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
}

.plan-and-billing-display-text107 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;


}

.plan-and-billing-display-text107a {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  color: #6941C6;

}

/* Table Css */
.plan-and-billing-table {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #fff;
  max-width: 100%;
  overflow: hidden;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
  align-self: stretch;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  border: 1px solid #eaecf0;
}

.plan-and-billing-card-header1 {
  justify-content: flex-start;
  max-width: 100%;
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  background-color: #fff;
  flex-direction: column;
  gap: 20px;
  flex-shrink: 0;
}

.plan-and-billing-content4 {
  justify-content: flex-start;
  max-width: 100%;
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px 24px 0;
  box-sizing: border-box;
  gap: 16px;
}

.plan-and-billing-text-and-supporting-text2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 12px 0;
  box-sizing: border-box;
  gap: 4px;
  max-width: 100%;
}

.plan-and-billing-text-and-badge1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  padding: 0 729px 0 0;
  gap: 8px;
}

.plan-and-billing-table-header-title {
  font-family: Inter;
  color: #101828;
  text-align: left;
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 123px;
}

.plan-and-billing-dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-dots-vertical-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.plan-and-billing-divider-icon {
  align-self: stretch;
  max-width: 100%;

  position: relative;
  overflow: hidden;
  max-height: 100%;
}

.plan-and-billing-table-content {
  display: flex;
  box-sizing: border-box;
  width: 1014px;
  height: 607px;
  overflow-y: auto;
  scrollbar-width: none;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-between; */
  padding: 0 1px 0 0;
  /* gap: 20px; */
  max-width: 100%;
}

.plan-and-billing-table-row {

  display: flex;
  justify-content: start;
  flex-shrink: 0;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
}

.plan-and-billing-table-header-cell1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 180px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 24px 11px;
}

.plan-and-billing-checkbox-wrapper {
  margin: 0;
  height: 20px;
  width: 28px;
}

.plan-and-billing-table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  gap: 4px;
}

.plan-and-billing-column-title {
  text-decoration: none;
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 50px;
}

.plan-and-billing-arrow-down-icon1 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.plan-and-billing-table-header-cell2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 186px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 27px 11px;
}

.plan-and-billing-help-icon {
  height: 16px;
  width: 16px;
  position: relative;
}

.plan-and-billing-table-header-cell3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 169px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 27px 11px 14px;

}

.plan-and-billing-table-header-cell4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 97px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 27px 11px;
}
.plan-and-billing-table-header-cell4-a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 107px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 27px 11px 9px;
}


.plan-and-billing-table-header2 {
  display: flex;
  flex-direction: row;
  align-items: center;

  flex: 1;
  justify-content: center;
  gap: 4px;
}

.plan-and-billing-action-column-title {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 37px;
}

.plan-and-billing-arrow-down-icon2 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.plan-and-billing-table-header-cell-parent {
  display: flex;
  justify-content: start;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  /* padding: 0 0 0 20px; */
  position: relative;
}

.plan-and-billing-table-cell10 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 180px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 26px 24px 22px 24px;
}

.plan-and-billing-cell-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* padding: 10px 0 0; */
}



.plan-and-billing-contrast-border {
  height: 40px;
  width: 40px;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: flex-end;
  padding: 30px 0 0 26px;
}



.plan-and-billing-dropdown1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: max-content;
  padding-left: 10px;
}

.plan-and-billing-cell-title {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;

  font-weight: 500;
  color: #101828;
  min-width: 75px;
}

.plan-and-billing-supporting-text4 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;
  color: #475467;
  min-width: 97px;
}

.plan-and-billing-table-cell20 {
  width: 186px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 24px 19px;
}

.plan-and-billing-table-cell50 {
  width: 186px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 26px 24px 20px 19px;
}

.plan-and-billing-location-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: -3px;
  padding-bottom: 3px;
  gap: 8px;
}

.plan-and-billing-text36 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: center;
  display: inline-block;
  min-width: 93px;
  text-wrap: nowrap;
}

.plan-and-billing-table-cell30 {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #eaecf0;
  justify-content: flex-start;
  width: 169px;
  padding: 26px 50px 24px 26px;
}

.plan-and-billing-text47 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 87px;
}

.plan-and-billing-table-cell40 {
  width: 100px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 26px 24px 22px 26px;
}

.plan-and-billing-table-cell40-a {
  width: 100px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 26px 24px 22px 26px;
}

/* Accordion Css */
/* 
.plan-and-billing-content2 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  padding: 32px 32px 40px;
  text-align: left;
  font-size: 16px;
  color: #475467;
}

.plan-and-billing-check-items {
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-check-item-text {
  align-self: stretch;
  flex-direction: row;
  gap: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-check-icon {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 9999px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.plan-and-billing-text-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  flex-direction: column;
  min-width: 208px;
}

.plan-and-billing-item-description {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}

.plan-and-billing-span {
  font-weight: 800;
}

.plan-and-billing-business-page {
  font-size: 16px;
  color: #475467;
}

.plan-and-billing-check-item-text5 {
  display: none;
  flex-direction: row;
  gap: 12px;
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-check-icon5 {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 9999px;
  overflow: hidden;
  flex-shrink: 0;
} */

.plan-and-billing-content2 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  text-align: left;
  font-size: 16px;
  color: #475467;
  background-color: #f9f9f9; /* Light background for accordion content */
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-top: 10px;
  transition: all 0.3s ease;
}

.plan-and-billing-check-items {
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}

.plan-and-billing-check-item-text {
  align-self: stretch;
  flex-direction: row;
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.plan-and-billing-check-icon {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
  background-color: #e0e0e0; /* Placeholder background color */
}

.plan-and-billing-text-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  min-width: 208px;
}

.plan-and-billing-item-description {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-size: 15px;
  color: #3c3c3c;
}

.plan-and-billing-span {
  font-weight: 800;
  color: #2d2d2d; /* Bold and darker color for emphasis */
}

.plan-and-billing-business-page {
  font-size: 16px;
  color: #475467;
}

.plan-and-billing-check-item-text5 {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-self: stretch;
  align-items: center;
  justify-content: flex-start;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.plan-and-billing-check-icon5 {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 9999px;
  overflow: hidden;
  flex-shrink: 0;
}

/* Accordion Toggle Animation */
.accordion-content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.accordion-content.active {
  max-height: 500px; /* Adjust as needed */
  opacity: 1;
}

/* Hover Effects for See Details Button */
.plan-and-billing-display-ButtonsButton {
  transition: color 0.3s ease;
}

.plan-and-billing-display-ButtonsButton:hover {
  color: #6a1b9a; /* Accent color on hover */
}




@media screen and (max-width: 1275px) {
  .plan-and-billing-display-main1 {
    padding-top: 154px;
    padding-bottom: 20px;
    box-sizing: border-box;
  }

  .plan-and-billing-display-sidebar-navigation-parent {
    padding-bottom: 58px;
    box-sizing: border-box;
  }

  .plan-and-billing-display-container2 {
    max-width: 100%;
  }
}

@media screen and (max-width: 1100px) {

  .plan-and-billing-display-tabs2,
  .plan-and-billing-display-toggle-header-parent {
    flex-wrap: wrap;
  }

  .plan-and-billing-display-content18 {
    padding-right: 51px;
    box-sizing: border-box;
  }

  .plan-and-billing-display-actions1 {
    flex-wrap: wrap;
    padding-left: 384px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 925px) {
  .plan-and-billing-text-and-badge1 {
    flex-wrap: wrap;
    padding-right: 364px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 750px) {
  .plan-and-billing-display-frame-group {
    gap: 19px;
  }

  .plan-and-billing-display-frame-parent {
    gap: 35px;
  }

  .plan-and-billing-display-rectangle-parent {
    padding-right: 32px;
    box-sizing: border-box;
  }

  .plan-and-billing-display-main {
    gap: 16px;
  }

  .plan-and-billing-display-page-title {
    font-size: 24px;
    line-height: 30px;
  }

  .plan-and-billing-display-actions {
    flex-wrap: wrap;
  }

  .plan-and-billing-display-input-dropdown {
    max-width: 100%;
  }

  .plan-and-billing-display-input-fields,
  .plan-and-billing-display-toggle-controls {
    flex-wrap: wrap;
  }

  .plan-and-billing-display-input-field1,
  .plan-and-billing-display-input-fields {
    max-width: 100%;
    min-width: 100%;
  }

  .plan-and-billing-display-avatar-and-file-upload {
    flex-wrap: wrap;
  }

  .plan-and-billing-display-avatar-and-file-upload,
  .plan-and-billing-display-input-dropdown1 {
    max-width: 100%;
    min-width: 100%;
  }

  .plan-and-billing-display-content41,
  .plan-and-billing-display-input14 {
    flex-wrap: wrap;
  }

  .plan-and-billing-display-wysiwyg-toolbar {
    flex-wrap: wrap;
    padding-right: 42px;
    box-sizing: border-box;
  }

  .plan-and-billing-display-toolbar-and-text-input {
    max-width: 100%;
    min-width: 100%;
  }

  .plan-and-billing-display-file-upload-item-base,
  .plan-and-billing-display-file-upload-item-base1,
  .plan-and-billing-display-file-upload-item-base2,
  .plan-and-billing-display-frame-div,
  .plan-and-billing-display-item-progress-container,
  .plan-and-billing-display-progress-bar-container1 {
    flex-wrap: wrap;
  }

  .plan-and-billing-display-file-upload {
    max-width: 100%;
  }

  .plan-and-billing-display-file-upload,
  .plan-and-billing-display-main-parent {
    min-width: 100%;
  }

  .plan-and-billing-display-sidebar-navigation-parent {
    padding-bottom: 38px;
    box-sizing: border-box;
  }

  .plan-and-billing-display-container2 {
    gap: 16px;
  }

  .plan-and-billing-display-footer1 {
    gap: 32px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }

  .plan-and-billing-text-and-supporting-text2 {
    min-width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .plan-and-billing-text-and-supporting-text2 {
    min-width: 100%;
  }


  .plan-and-billing-text-and-badge1 {
    padding-right: 121px;
    box-sizing: border-box;

    padding-right: 182px;
  }
}

@media screen and (max-width: 450px) {
  .plan-and-billing-display-frame-parent {
    gap: 17px;
  }

  .plan-and-billing-display-account {
    gap: 23px;
  }

  .plan-and-billing-display-tabs1 {
    flex-wrap: wrap;
  }

  .plan-and-billing-display-page-title {
    font-size: 18px;
    line-height: 23px;
  }

  .plan-and-billing-display-content15 {
    gap: 16px;
  }

  .plan-and-billing-display-content18 {
    gap: 16px;
    padding-right: 25px;
    box-sizing: border-box;
  }

  .plan-and-billing-display-action {
    flex-wrap: wrap;
  }

  .plan-and-billing-display-content29,
  .plan-and-billing-display-content41 {
    padding-right: 180px;
    box-sizing: border-box;
  }

  .plan-and-billing-display-content41 {
    padding-right: 58px;
  }

  .plan-and-billing-display-input15 {
    flex-wrap: wrap;
  }

  .plan-and-billing-display-wysiwyg-toolbar {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .plan-and-billing-display-item-status {
    margin-left: 0;
  }

  .plan-and-billing-display-content44 {
    gap: 16px;
  }

  .plan-and-billing-display-actions1 {
    padding-left: 192px;
    box-sizing: border-box;
  }

  .plan-and-billing-display-footer1 {
    gap: 16px;
  }
}

@media screen and (max-width: 616px) {
  .plan-and-billing-display-row1 {
    flex-wrap: wrap;
  }

  .plan-and-billing-display-email-popup {
    height: auto;
    min-height: 546;
  }
}