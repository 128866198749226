.team-member-table-display-form-content-wrapper-Inactive {
  align-self: stretch;
  flex-direction: row;
  /* padding: 0 0 0 10px; */
  box-sizing: border-box;
  color: #344054;
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  width: max-content;
}

.team-member-table-display-form-content-Inactive {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  align-items: flex-start;
  flex: 1;
  flex-direction: column;
  gap: 20px;

}

.team-member-table-display-toggle-header-parent-Inactive {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 12px;
  max-width: 100%;
  font-size: 14px;
  color: #344054;

  align-self: stretch;
  gap: 16px;
  font-size: 18px;
  color: #101828;

}

.team-member-table-display-toggle-header-Inactive {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 320px;
  max-width: 100%;
}

.team-member-table-display-toggle-title-Inactive {
  align-self: stretch;
  position: relative;

  line-height: 28px;
  font-weight: 600;
}


.team-member-table-display-divider-container-Inactive {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 4px;
  box-sizing: border-box;
  max-width: 100%;
}

.team-member-table-display-divider-icon3-Inactive {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.team-member-table-table-Inactive {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #fff;
  /* max-width: 100%; */
  width: max-content;
  min-height: 500px;
  overflow: hidden;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
  align-self: stretch;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 12px;
  border: 1px solid #eaecf0;
}

.team-member-table-card-header1-Inactive {
  justify-content: flex-start;
  max-width: 100%;
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  background-color: #fff;
  flex-direction: column;
  gap: 20px;
  flex-shrink: 0;
}

.team-member-table-content4-Inactive {
  justify-content: flex-start;
  max-width: 100%;
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px 24px 0;
  box-sizing: border-box;
  gap: 16px;
}

.team-member-table-text-and-supporting-text2-Inactive {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 12px 0;
  box-sizing: border-box;
  gap: 4px;
  max-width: 100%;
}

.team-member-table-text-and-badge1-Inactive {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  padding: 0 729px 0 0;
  gap: 8px;
}

.team-member-table-table-header-title-Inactive {
  font-family: Inter;
  color: #101828;
  text-align: left;
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  min-width: 123px;
}

.team-member-table-dropdown-Inactive {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.team-member-table-dots-vertical-icon-Inactive {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.team-member-table-divider-icon-Inactive {
  align-self: stretch;
  max-width: 100%;

  position: relative;
  overflow: hidden;
  max-height: 100%;
}

.team-member-table-table-content-Inactive {
  display: flex;
  box-sizing: border-box;
  width: max-content;
  /* height: 607px; */
  overflow-y: auto;
  scrollbar-width: none;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-between; */
  padding: 0 1px 0 0;
  /* gap: 20px; */
  max-width: 100%;
}

.team-member-table-table-row-Inactive {

  display: flex;
  justify-content: start;
  flex-shrink: 0;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
}

.team-member-table-table-header-cell1-Inactive {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 280px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 24px 11px;
}

.team-member-table-table-cell10-Inactive {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 280px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 5.5px 20px 4.5px;
  gap: 12px;
}

.team-member-table-checkbox-wrapper-Inactive {
  margin: 0;
  height: 20px;
  width: 28px;
}

.team-member-table-table-header-Inactive {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  gap: 4px;
}

.team-member-table-column-title-Inactive {
  text-decoration: none;
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 50px;
}

.team-member-table-arrow-down-icon1-Inactive {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.team-member-table-table-header-cell2-Inactive {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 300px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 27px 11px;
}

.team-member-table-table-cell20-Inactive {
  width: 300px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 14px 27px 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.team-member-table-help-icon-Inactive {
  height: 16px;
  width: 16px;
  position: relative;
}

.team-member-table-table-header-cell3-Inactive {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 220px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 27px 11px;

}

.team-member-table-table-cell30-Inactive {
  width: 220px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 13px 27px 11px;
}

.team-member-table-table-header-cell4-Inactive {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 180px;
  background-color: #f9fafb;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 13px 27px 11px;
}

.team-member-table-table-cell40-Inactive {
  width: 180px;
  border-bottom: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 91px 8px 11px;
  cursor: pointer;
}

.team-member-table-table-header2-Inactive {
  display: flex;
  flex-direction: row;
  align-items: center;

  flex: 1;
  justify-content: center;
  gap: 4px;
}

.team-member-table-action-column-title-Inactive {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 37px;
}

.team-member-table-arrow-down-icon2-Inactive {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.team-member-table-table-header-cell-parent {
  display: flex;
  justify-content: start;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  /* padding: 0 0 0 20px; */
  position: relative;
}



.team-member-table-cell-content-Inactive {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* padding: 10px 0 0; */
}


.team-member-table-table-data-custom-main-container {
  display: flex;
  flex-direction: column;
  min-height: 420px;
  overflow-y: scroll;
  scrollbar-width: none;
  width: 100%;
}



.team-member-table-avatar-online-indicator {
  height: 13px;
  width: 13px;
  position: relative;
  border-radius: 9999px;
  background-color: #17b26a;
  border: 1.5px solid #fff;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.team-member-table-avatar-Inactive {
  height: 35px;
  width: 35px;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border: 1px solid #344054;
  justify-content: flex-start;
  background-image: url("./../../../icons/Gerator/dashboard/user01.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

/* .team-member-table-location-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: -3px;
    padding-bottom: 3px;
    gap: 8px;
  } */

  .team-member-table-location-content{
    position: relative;
  }

.team-member-table-text36 {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  /* text-align: center; */
  display: inline-block;
  /* min-width: 93px; */
  text-wrap: nowrap;
}

.team-member-table-cell-title-Inactive {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
  display: inline-block;

  font-weight: 500;
  color: #101828;
  /* min-width: 75px; */
}

.team-member-table-supporting-text4-Inactive {
  position: relative;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  /* text-align: center; */
  display: inline-block;
  /* min-width: 93px; */
  text-wrap: nowrap;
  /* min-width: 97px; */
}

.team-member-table-dropdown1-Inactive {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 170px;
  /* padding-left: 10px; */
}

/* Load More css  */

.team-member-table-frame-wrapper44 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 32px;
  max-width: 100%;
  color: #344054;
  z-index: 0;

  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.team-member-table-frame-parent72 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;

  flex: 1;
  gap: 24px;
}

.team-member-table-buttonsbutton-wrapper1 {
  align-self: stretch;
  justify-content: center;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.team-member-table-buttonsbutton-wrapper1-child {
  outline: none;
  border: none;
  background-color: #fff;
  padding: 0;
}

.team-member-table-buttonsbutton28 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  justify-content: flex-start;
  padding: 6px 12px;
  gap: 6px;
  z-index: 1;
  cursor: pointer;
}

.team-member-table-arrow-narrow-left-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.team-member-table-search-input {
  display: flex;
  justify-content: flex-start;

  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
}

.team-member-table-text146 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 72px;
}