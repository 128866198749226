.login-buttonsbutton1 {
  display: flex;
  align-items: center;
}
.login-buttonsbutton1 {
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  gap: 6px;
  text-align: left;
  font-size: 14px;
  color: #475467;
  padding: 10px 0;
}
.login-arrow-left-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}
.login-back-label {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 91px;
}
.login-placeholder-icon2 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}