.funnel-button-group {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
  
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    overflow-x: auto;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .funnel-button-group-base1 {
    width: max-content;
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
    white-space: nowrap;
  }
  
  
  .funnel-text29 {
      position: relative;
      line-height: 20px;
      font-weight: 600;
      display: inline-block;
      min-width: 45px;
  }
  
  .funnel-text31 {
      position: relative;
      line-height: 20px;
      font-weight: 600;
      display: inline-block;
      min-width: 71px;
  }
  
  .funnel-text32 {
      position: relative;
      line-height: 20px;
      font-weight: 600;
      display: inline-block;
      min-width: 55px;
  }
    
  
  
  .funnel-text {
      position: relative;
      line-height: 24px;
      font-weight: 600;
      display: inline-block;
      min-width: 30px;
    }
    
  
  .active {
      background-color: #f9fafb;
  }
  
  
  .custom-cursor {
      cursor: pointer;
  }
  
  /* .filter-button-group-base {
      width: 87px;
      background-color: #fff;
      border-right: 1px solid #d0d5dd;
      box-sizing: border-box;
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 10px 15px;
      min-height: 40px;
      white-space: nowrap;
  } */
  
  /* .filter-button-group {
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      border: 1px solid #d0d5dd;
      overflow-x: auto;
      align-items: flex-start;
      justify-content: flex-start;
      max-width: 100%;
  } */