.no-table-data-display-button-link {
    display: flex;
    align-items: center;
  }
  
  .no-table-data-display-bifocal-optometry-measurement-icon {
    width: 391px;
    position: relative;
    max-height: 100%;
    object-fit: cover;
    max-width: 100%;
    left: 50px;
    height: 300px;
  }
  
  .no-table-data-display-supporting-text,
  .no-table-data-display-title {
    align-self: stretch;
    position: relative;
  }
  
  .no-table-data-display-title {
    line-height: 30px;
    font-weight: 600;
  }
  
  .no-table-data-display-supporting-text {
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    padding-left: 33px;
  }
  
  .no-table-data-display-content,
  .no-table-data-display-text-and-supporting-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  .no-table-data-display-text-and-supporting-text {
    /* width: 352px; */
    gap: 8px;
    /* max-width: 352px; */
  }
  
  .no-table-data-display-content {
    align-self: stretch;
    padding: 0 20px;
    box-sizing: border-box;
    gap: 20px;
    max-width: 100%;
  }
  
  .no-table-data-display-placeholder-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  
  .no-table-data-display-text {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 98px;
  }
  
  .no-table-data-display-buttonsbutton,
  .no-table-data-display-text-padding {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  
  .no-table-data-display-buttonsbutton {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 8px 15px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    gap: 6px;
  }
  
  .no-table-data-display-plus-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .no-table-data-display-text1 {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 88px;
  }
  
  .no-table-data-display-buttonsbutton1 {
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 15px;
    background-color: #7e56d9;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
  
  .no-table-data-display-buttonsbutton1:hover{
    background-color: #6941c6;
  }
  
  .no-table-data-display-actions,
  .no-table-data-display-empty-state1 {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .no-table-data-display-actions {
    flex-direction: row;
    align-items: flex-start;
    padding: 0 20px;
    box-sizing: border-box;
    gap: 12px;
  }
  
  .no-table-data-display-empty-state1 {
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
  
  .no-table-data-display-empty-state,
  .no-table-data-display-no-table-data-display {
    background-color: #fff;
    max-width: 100%;
    display: flex;
    flex-direction: row;
  }
  
  .no-table-data-display-empty-state {
    flex: 1;
    align-items: center;
    justify-content: center;
    /* padding: 41.5px 0; */
    padding-top: 20px;
    box-sizing: border-box;
    text-align: center;
    font-size: 20px;
    color: #101828;
    font-family: Inter;
  }
  
  .no-table-data-display-no-table-data-display {
    width: 100%;
    overflow: hidden;
    align-items: flex-start;
    justify-content: flex-start;
    line-height: normal;
    letter-spacing: normal;
  }
  
  @media screen and (max-width: 550px) {
    .no-table-data-display-empty-state1 {
      gap: 16px;
    }
  
    .no-table-data-display-empty-state {
      padding-top: 27px;
      padding-bottom: 27px;
      box-sizing: border-box;
    }
  }
  
  @media screen and (max-width: 450px) {
    .no-table-data-display-title {
      font-size: 16px;
      line-height: 24px;
    }
  }