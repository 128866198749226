.Plan-billing-success-component-x-close-icon3 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .Plan-billing-success-component-button-close-x1 {
    position: absolute;
    top: 24px;
    left: 611px;
    border-radius: 8px;
    width: 39px;
    height: 40px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px;
    box-sizing: border-box;
    z-index: 1;
  }
  .Plan-billing-success-component-featured-icon1,
  .Plan-billing-success-component-frame-child5 {
    position: relative;
    border-radius: 12px;
  }
  .Plan-billing-success-component-frame-child5 {
    height: 546px;
    width: 633.4px;
    background-color: #fff;
    display: none;
    max-width: 100%;
  }
  .Plan-billing-success-component-featured-icon1 {
    width: 56px;
    height: 56px;
  }
  .Plan-billing-success-component-supporting-text5,
  .Plan-billing-success-component-title1 {
    align-self: stretch;
    position: relative;
  }
  .Plan-billing-success-component-title1 {
    margin: 0;
    font-size: inherit;
    line-height: 38px;
    font-weight: 600;
    font-family: inherit;
  }
  .Plan-billing-success-component-supporting-text5 {
    font-size: 16px;
    line-height: 24px;
    color: #475467;
  }
  .Plan-billing-success-component-header1,
  .Plan-billing-success-component-text-and-supporting-text3 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
  }
  .Plan-billing-success-component-header1 {
    align-items: center;
    gap: 24px;
    text-align: center;
    font-size: 30px;
    color: #101828;
  }
  .Plan-billing-success-component-placeholder-icon25 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .Plan-billing-success-component-text76 {
    text-decoration: none;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 71px;
  }
  .Plan-billing-success-component-buttonsbutton10,
  .Plan-billing-success-component-text-padding15 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  .Plan-billing-success-component-buttonsbutton10 {
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 20px;
    background-color: #7e56d9;
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    gap: 6px;
  }
  .Plan-billing-success-component-secondary-label {
    position: relative;
    line-height: 20px;
  }
  .Plan-billing-success-component-placeholder-icon27 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
    min-height: 20px;
  }
  .Plan-billing-success-component-text77 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 102px;
  }
  .Plan-billing-success-component-buttonsbutton11,
  .Plan-billing-success-component-row1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .Plan-billing-success-component-buttonsbutton11 {
    overflow: hidden;
    align-items: center;
    gap: 6px;
    color: #6941c6;
  }
  .Plan-billing-success-component-row1 {
    align-items: flex-start;
    padding: 0 14px;
    gap: 3px;
  }
  .Plan-billing-success-component-arrow-left-icon1 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 20px;
  }
  
  .Plan-billing-success-component-back-label {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 91px;
  }
  .Plan-billing-success-component-buttonsbutton12 {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
  .Plan-billing-success-component-container9,
  .Plan-billing-success-component-content22 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .Plan-billing-success-component-content22 {
    gap: 32px;
    max-width: 360px;
  }
  .Plan-billing-success-component-container9 {
    /* padding: 0 32px; */
    box-sizing: border-box;
    max-width: 1280px;
  }
  .Plan-billing-success-component-content21 {
    width: 100%;
    max-width: 361px;
  }
  .Plan-billing-success-component-container7,
  .Plan-billing-success-component-container8,
  .Plan-billing-success-component-content21 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .Plan-billing-success-component-container8 {
    align-self: stretch;
    padding: 0 48px;
  }
  .Plan-billing-success-component-container7 {
    flex: 1;
    /* padding: 0 32px; */
    box-sizing: border-box;
    max-width: 100%;
    z-index: 1;
  }
  .Plan-billing-success-component-email-popup,
  .Plan-billing-success-component-frame-section {
    background-color: #fff;
    max-width: 100%;
  }
  .Plan-billing-success-component-frame-section {
    position: absolute;
  
    border-radius: 12px;
    width: 633.4px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 34px 55px 94px 100px;
    box-sizing: border-box;
    text-align: left;
    font-size: 14px;
    color: #475467;
    font-family: Inter;
  }
  .Plan-billing-success-component-email-popup {
    width: 650px;
    height: 546px;
    overflow: hidden;
    line-height: normal;
    letter-spacing: normal;
  }

  .Plan-billing-success-component-span{
   color: #6941c6;
  }

  @media screen and (max-width: 616px) {
    .Plan-billing-success-component-row1 {
      flex-wrap: wrap;
    }
    .Plan-billing-success-component-email-popup {
      height: auto;
      min-height: 546;
    }
  }
  