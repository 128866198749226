.rating-star-component-frame-parent3 {
  width: 526px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  max-width: 100%;
  z-index: 2;
}

.rating-star-component-heading-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.rating-star-component-heading33 {
  position: relative;
  line-height: 24px;

  width: 120px;
  display: inline-block;
}

.rating-star-component-heading35 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 116px;
}


.rating-star-component-listing-detail-item-date-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

@media screen and (max-width: 750px) {
  .rating-star-component-frame-parent3 {
    flex-wrap: wrap;
  }
}