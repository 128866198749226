.add-software-step3-text79 {
    flex: 1;
    position: relative;
    line-height: 24px;
    font-weight: 500;
  }
  .add-software-step3-arrow-narrow-left-icon5 {
    height: 24px;
    width: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 24px;
  }
  .add-software-step3-text-group {
    width: 237px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
  }
  .add-software-step3-step-icon-base16 {
    height: 24px;
    width: 24px;
    position: relative;
    border-radius: 9999px;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 24px;
  }
  .add-software-step3-step-title1 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 600;
  }
  .add-software-step3-step-icon-base15,
  .add-software-step3-step-title-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .add-software-step3-step-title-wrapper {
    flex: 1;
    flex-direction: column;
    padding: 2px 0 0;
  }
  .add-software-step3-step-icon-base15 {
    align-self: stretch;
    flex-direction: row;
    gap: 12px;
  }
  .add-software-step3-connector15 {
    width: 2px;
    flex: 1;
    position: relative;
    border-radius: 2px;
    background-color: #7e56d9;
  }
  .add-software-step3-connector-wrapper8 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px 0 0;
  }
  .add-software-step3-supporting-text25 {
    flex: 1;
    position: relative;
    line-height: 20px;
  }
  .add-software-step3-frame-parent5,
  .add-software-step3-step-base16 {
    align-self: stretch;
    flex: 1;
    display: flex;
    justify-content: flex-start;
  }
  .add-software-step3-frame-parent5 {
    flex-direction: row;
    align-items: flex-start;
    padding: 0 0 0 11px;
    gap: 23px;
    margin-top: -2px;
    font-size: 12px;
    color: #475467;
  }
  .add-software-step3-step-base16 {
    flex-direction: column;
    align-items: flex-end;
    padding: 0 0 4px;
  }
  .add-software-step3-step-icon-base18 {
    width: 24px;
    height: 24px;
    position: relative;
    border-radius: 9999px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .add-software-step3-connector17 {
    align-self: stretch;
    width: 2px;
    position: relative;
    border-radius: 2px;
    background-color: #eaecf0;
  }
  .add-software-step3-connector-wrap5,
  .add-software-step3-connector-wrapper10 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .add-software-step3-connector-wrapper10 {
    flex: 1;
    flex-direction: row;
    padding: 0 11px;
  }
  .add-software-step3-connector-wrap5 {
    align-self: stretch;
    flex-direction: column;
    padding: 0 0 4px;
    gap: 4px;
  }
  .add-software-step3-supporting-text27 {
    align-self: stretch;
    position: relative;
    font-size: 12px;
    line-height: 20px;
    color: #7e56d9;
  }
  .add-software-step3-step-base18,
  .add-software-step3-text-and-supporting-text12 {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .add-software-step3-text-and-supporting-text12 {
    flex-direction: column;
    padding: 2px 0 24px;
  }
  .add-software-step3-step-base18 {
    align-self: stretch;
    flex-direction: row;
    gap: 12px;
    color: #6941c6;
  }
  .add-software-step3-step-base15 {
    margin: 0;
    align-self: stretch;
    flex: 1;
    gap: 38px;
    text-align: left;
    font-size: 14px;
    color: #344054;
    font-family: Inter;
  }
  .add-software-step3-add-software-step-3-inner,
  .add-software-step3-frame-parent4,
  .add-software-step3-step-base15 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .add-software-step3-frame-parent4 {
    align-self: stretch;
    flex: 1;
    gap: 16px;
  }
  .add-software-step3-add-software-step-3-inner {
    height: 412px;
    width: 250px;
    padding: 38px 0 0;
    box-sizing: border-box;
  }
  .add-software-step3-hint-text46 {
    width: 320px;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    display: none;
    z-index: 1;
  }
  .add-software-step3-content61 {
    width: 100%;
    border: 0;
    outline: 0;
    background-color: transparent;
    align-self: stretch;
    height: 28px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: Inter;
    font-weight: 600;
    font-size: 18px;
    color: #101828;
    min-width: 250px;
  }
  .add-software-step3-divider-icon14 {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .add-software-step3-section-header7 {
    align-self: stretch;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }
  .add-software-step3-label45,
  .add-software-step3-label46 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  .add-software-step3-label45 {
    text-decoration: none;
    color: inherit;
    display: inline-block;
    min-width: 66px;
  }
  .add-software-step3-label46 {
    width: 77px;
    display: none;
  }
  .add-software-step3-text82 {
    width: 100%;
    border: 0;
    outline: 0;
    font-family: Inter;
    font-size: 16px;
    background-color: transparent;
    height: 78px;
    flex: 1;
    position: relative;
    line-height: 24px;
    color: #101828;
    text-align: left;
    display: inline-block;
    min-width: 250px;
    max-width: 100%;
    padding: 0;
  }
  .add-software-step3-input-with-label43,
  .add-software-step3-input31 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .add-software-step3-input31 {
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    flex-direction: row;
    padding: 10px 13px;
    max-width: 100%;
  }
  .add-software-step3-input-with-label43 {
    width: 1014px;
    flex-direction: column;
    gap: 6px;
    max-width: 199%;
    flex-shrink: 0;
  }
  .add-software-step3-hint-text47 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    color: #475467;
  }
  .add-software-step3-content62,
  .add-software-step3-input-with-label42,
  .add-software-step3-textarea-input-field7 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .add-software-step3-textarea-input-field7 {
    width: 512px;
    flex-direction: column;
    padding: 0;
    box-sizing: border-box;
    gap: 6px;
    min-width: 480px;
    max-width: 512px;
  }
  .add-software-step3-content62,
  .add-software-step3-input-with-label42 {
    align-self: stretch;
    max-width: 100%;
  }
  .add-software-step3-content62 {
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .add-software-step3-input-with-label42 {
    flex-direction: column;
    gap: 6px;
  }
  .add-software-step3-hint-text48 {
    width: 320px;
    position: relative;
    line-height: 20px;
    color: #475467;
    display: none;
  }
  .add-software-step3-frame-section,
  .add-software-step3-input-field34 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .add-software-step3-input-field34 {
    flex: 1;
    flex-direction: column;
    gap: 6px;
  }
  .add-software-step3-frame-section {
    align-self: stretch;
    flex-direction: row;
    text-align: left;
    font-size: 14px;
    color: #344054;
    font-family: Inter;
  }
  .add-software-step3-label47 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    max-width: 100%;
  }
  .add-software-step3-input-field35 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    max-width: 100%;
    text-align: left;
    font-size: 14px;
    color: #344054;
    font-family: Inter;
  }
  .add-software-step3-label49,
  .add-software-step3-label51 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  .add-software-step3-label49 {
    display: inline-block;
    min-width: 83px;
  }
  .add-software-step3-label51 {
    min-width: 62px;
  }
  .add-software-step3-label51,
  .add-software-step3-label53,
  .add-software-step3-label55 {
    display: inline-block;
  }
  .add-software-step3-label53 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    min-width: 103px;
  }
  .add-software-step3-label55 {
    min-width: 81px;
  }
  .add-software-step3-label55,
  .add-software-step3-label57 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  .add-software-step3-input-with-label55 {
    border: 1px solid #d0d5dd;
    background-color: #fff;
    height: 102px;
    width: 1014px;
    outline: 0;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 13px;
    font-family: Inter;
    font-size: 16px;
    color: #101828;
    max-width: 199%;
    flex-shrink: 0;
  }
  .add-software-step3-hint-text59 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
  }
  .add-software-step3-content68 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
    max-width: 100%;
    color: #475467;
  }
  .add-software-step3-label58 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 77px;
  }
  .add-software-step3-featured-icon5 {
    width: 40px;
    height: 40px;
    position: relative;
    border-radius: 8px;
  }
  .add-software-step3-placeholder-icon60 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
    min-height: 20px;
  }
  .add-software-step3-text88 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 101px;
  }
  .add-software-step3-buttonsbutton16 {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }
  .add-software-step3-text89 {
    position: relative;
    line-height: 20px;
    color: #475467;
    display: inline-block;
    min-width: 112px;
  }
  .add-software-step3-action5 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0 20px;
    gap: 4px;
  }
  .add-software-step3-pdf1 {
    font-weight: 600;
  }
  .add-software-step3-supporting-text28 {
    align-self: stretch;
    position: relative;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #475467;
  }
  .add-software-step3-text-and-supporting-text13 {
    gap: 4px;
  }
  .add-software-step3-content69,
  .add-software-step3-file-upload-base5,
  .add-software-step3-text-and-supporting-text13 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .add-software-step3-content69 {
    gap: 12px;
  }
  .add-software-step3-file-upload-base5 {
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    padding: 14px 23px;
    color: #6941c6;
  }
  .add-software-step3-page-icon3 {
    position: absolute;
    height: 100%;
    width: 80%;
    top: 0;
    right: 2.5%;
    bottom: 0;
    left: 17.5%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }
  .add-software-step3-file-type3 {
    position: relative;
    display: inline-block;
    min-width: 20px;
  }
  .add-software-step3-file-type-wrap3 {
    position: absolute;
    height: 40%;
    width: 65%;
    top: 45%;
    right: 32.5%;
    bottom: 15%;
    left: 2.5%;
    border-radius: 2px;
    background-color: #da2d20;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2px 3px;
    box-sizing: border-box;
    z-index: 1;
  }
  .add-software-step3-file-type-icon3 {
    height: 40px;
    width: 40px;
    position: relative;
  }
  .add-software-step3-checkbox-base-icon3,
  .add-software-step3-text90 {
    position: relative;
    overflow: hidden;
  }
  .add-software-step3-text90 {
    flex: 1;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }
  .add-software-step3-checkbox-base-icon3 {
    height: 16px;
    width: 16px;
    border-radius: 4px;
    flex-shrink: 0;
  }
  .add-software-step3-checkbox3 {
    margin: 0 !important;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .add-software-step3-supporting-text29,
  .add-software-step3-text-parent1 {
    align-self: stretch;
    position: relative;
  }
  .add-software-step3-text-parent1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .add-software-step3-supporting-text29 {
    line-height: 20px;
    color: #475467;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .add-software-step3-file-type-icon-parent,
  .add-software-step3-frame-parent7 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .add-software-step3-frame-parent7 {
    flex: 1;
    flex-direction: column;
    min-width: 267px;
    text-align: left;
    font-size: 14px;
    color: #344054;
  }
  .add-software-step3-file-type-icon-parent {
    align-self: stretch;
    flex-direction: row;
    gap: 12px;
  }
  .add-software-step3-background3 {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: 0 !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 9999px;
    background-color: #eaecf0;
  }
  .add-software-step3-progress3 {
    align-self: stretch;
    flex: 1;
    position: relative;
    border-radius: 9999px;
    background-color: #7e56d9;
    max-width: 100%;
    z-index: 1;
  }
  .add-software-step3-progress-bar-wrapper,
  .add-software-step3-progress-bar9 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .add-software-step3-progress-bar9 {
    align-self: stretch;
    height: 8px;
    border-radius: 8px;
    flex-direction: row;
    position: relative;
  }
  .add-software-step3-progress-bar-wrapper {
    flex: 1;
    flex-direction: column;
    padding: 6px 0 0;
    box-sizing: border-box;
    min-width: 235px;
  }
  .add-software-step3-percentage3 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 38px;
  }
  .add-software-step3-file-upload,
  .add-software-step3-file-upload-item-base3,
  .add-software-step3-progress-bar8 {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  .add-software-step3-progress-bar8 {
    width: 411px;
    flex-direction: row;
    align-items: flex-start;
    gap: 11px;
    text-align: left;
    font-size: 14px;
    color: #344054;
  }
  .add-software-step3-file-upload,
  .add-software-step3-file-upload-item-base3 {
    flex-direction: column;
  }
  .add-software-step3-file-upload-item-base3 {
    align-self: stretch;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    align-items: flex-end;
    padding: 14px 15px;
    gap: 4px;
    text-align: center;
    font-size: 10px;
    color: #fff;
  }
  .add-software-step3-file-upload {
    width: 495px;
    align-items: flex-start;
    gap: 6px;
  }
  .add-software-step3-text91 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 600;
    white-space: nowrap;
  }
  .add-software-step3-buttonsbutton17 {
    height: 20px;
    overflow: hidden;
    display: none;
    gap: 6px;
  }
  .add-software-step3-button20,
  .add-software-step3-buttonsbutton17,
  .add-software-step3-text-padding24 {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .add-software-step3-text-padding24 {
    align-self: stretch;
    display: flex;
    padding: 0 2px;
  }
  .add-software-step3-button20 {
    height: 40px;
    border-radius: 8px;
    overflow: hidden;
    display: none;
    padding: 10px 14px;
    box-sizing: border-box;
    gap: 4px;
  }
  .add-software-step3-chevron-left-icon3,
  .add-software-step3-placeholder-icon66 {
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  .add-software-step3-placeholder-icon66 {
    height: 20px;
    width: 20px;
    display: none;
  }
  .add-software-step3-chevron-left-icon3 {
    height: 24px;
    width: 24px;
  }
  .add-software-step3-text93 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 34px;
  }
  .add-software-step3-button21,
  .add-software-step3-text-padding25 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  .add-software-step3-button21 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 8px 13px;
    background-color: #fff;
    width: 94px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    gap: 4px;
  }
  .add-software-step3-text94 {
    text-decoration: none;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 55px;
  }
  .add-software-step3-button22 {
    cursor: pointer;
    border: 0;
    padding: 10px 14px;
    background-color: #fdb022;
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    gap: 4px;
    min-width: 75px;
  }
  .add-software-step3-text95 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 48px;
  }
  .add-software-step3-actions6,
  .add-software-step3-button23 {
    /* flex: 1; */
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
  }
  .add-software-step3-button23 {
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 13px;
    background-color: #7e56d9;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    justify-content: center;
    gap: 4px;
    min-width: 70px;
  }
  .add-software-step3-actions6 {
    justify-content: flex-end;
    /* padding: 0 0 0 673px; */
    gap: 12px;
    max-width: 100%;
  }
  .add-software-step3-content70,
  .add-software-step3-section-footer7,
  .add-software-step3-section-footer8 {
    align-self: stretch;
    display: flex;
    align-items: center;
    max-width: 100%;
  }
  .add-software-step3-content70 {
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
  }
  .add-software-step3-section-footer7,
  .add-software-step3-section-footer8 {
    background-color: #fff;
    flex-direction: column;
    justify-content: flex-start;
  }
  .add-software-step3-section-footer8 {
    gap: 20px;
  }
  .add-software-step3-section-footer7 {
    color: #475467;
  }
  .add-software-step3-add-software-step-3,
  .add-software-step3-section-header-group {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-family: Inter;
  }
  .add-software-step3-section-header-group {
    flex: 1;
    flex-direction: column;
    gap: 24px;
    min-width: 659px;
    max-width: 100%;
    font-size: 14px;
    color: #344054;
  }
  .add-software-step3-add-software-step-3 {
    width: 100%;
    position: relative;
    background-color: #fff;
    overflow: hidden;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    /* padding: 29px 32px 29px 16px; */
    box-sizing: border-box;
    gap: 128px;
    line-height: normal;
    letter-spacing: normal;
    font-size: 16px;
    color: #101828;
  }
  @media screen and (max-width: 1025px) {
    .add-software-step3-actions6 {
      padding-left: 336px;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 975px) {
    .add-software-step3-actions6 {
      flex-wrap: wrap;
    }
    .add-software-step3-section-header-group {
      min-width: 100%;
    }
    .add-software-step3-add-software-step-3 {
      gap: 64px;
    }
  }
  @media screen and (max-width: 725px) {
    .add-software-step3-textarea-input-field7 {
      max-width: 100%;
    }
    .add-software-step3-content62,
    .add-software-step3-content68 {
      gap: 16px;
    }
    .add-software-step3-actions6 {
      padding-left: 168px;
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 450px) {
    .add-software-step3-step-base15 {
      gap: 19px;
    }
    .add-software-step3-action5,
    .add-software-step3-file-type-icon-parent,
    .add-software-step3-progress-bar8 {
      flex-wrap: wrap;
    }
    .add-software-step3-actions6 {
      padding-left: 20px;
      box-sizing: border-box;
    }
    .add-software-step3-add-software-step-3 {
      gap: 32px;
    }
  }
  