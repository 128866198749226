.beacon-broadcast-featured-icon2 {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 8px;
  padding-top: 3px;
}

.backend-validation-errors {
  width: 100%;
}

.beacon-broadcast-supporting-text91,
.beacon-broadcast-title9 {
  align-self: stretch;
  position: relative;
  white-space: nowrap;
}

.beacon-broadcast-title9 {
  text-decoration: none;
  line-height: 30px;
  font-weight: 600;
  font-size: 18px;
  color: inherit;
}

.beacon-broadcast-supporting-text91 {
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}

.beacon-broadcast-content71,
.beacon-broadcast-text-and-supporting-text26 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.beacon-broadcast-content71 {
  margin: 0 !important;
  position: absolute;
  top: 24px;
  left: 24px;
  flex-direction: row;
  gap: 16px;
}

.beacon-broadcast-button-close-x6,
.beacon-broadcast-slide-out-menu-header {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.beacon-broadcast-button-close-x6 {
  height: 20px;
  margin: 0 !important;
  position: absolute;
  top: 27px;
  right: 26px;
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  z-index: 1;
  cursor: pointer;
}

.beacon-broadcast-slide-out-menu-header {
  align-self: stretch;
  height: 78px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 24px;
  position: relative;
  gap: 8px;
  text-align: left;
  font-size: 20px;
  color: #101828;
  font-family: Inter;
}

.beacon-broadcast-label9 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 67px;
}

.beacon-broadcast-content72 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Inter;
  font-size: 16px;
  color: #101828;
  min-width: 194px;
  max-width: 100%;
}

.beacon-broadcast-help-icon2 {
  height: 16px;
  width: 16px;
  position: relative;
  display: block;
}

.beacon-broadcast-input6 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 13px;
  gap: 8px;
  max-width: 100%;
  cursor: pointer;

}

.beacon-broadcast-input6:focus {
  border-color: #7e56d9;
  /* Set to your preferred active color */
  box-shadow: 0 0 5px rgba(126, 86, 217, 0.5);
  /* Optional glow effect */
}

.beacon-broadcast-input6:hover {
  border-color: #7e56d9;
  /* Slightly darker border on hover */
}

.beacon-broadcast-input6a {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 13px;
  gap: 8px;
  max-width: 100%;

  outline: none;
  appearance: none;
  position: relative;
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"%3E%3Cpath fill="gray" d="M10 12l-6-6h12z"/%3E%3C/svg%3E');
  /* Custom dropdown arrow */
  background-repeat: no-repeat;
  background-position: 95%;
  background-size: 15px;
}

.beacon-broadcast-input6a:focus {
  border-color: #7e56d9;
  box-shadow: 0 0 5px rgba(126, 86, 217, 0.5);
}


.beacon-broadcast-input6a:hover {
  border-color: #7e56d9;
}

.beacon-broadcast-hint-text6 {
  width: 320px;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.beacon-broadcast-input-field {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  max-width: 100%;
}

.beacon-broadcast-label10 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 94px;
}

.beacon-broadcast-mail-01-icon,
.beacon-broadcast-text274 {
  position: relative;
  overflow: hidden;
}

.beacon-broadcast-mail-01-icon {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
}

.beacon-broadcast-text274 {
  /* width: calc(100% - 36px); */
  width: 80%;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  flex: 1;
  line-height: 24px;
  color: #101828;
  text-align: left;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* min-width: 163px; */
  padding: 0;
}

.beacon-broadcast-content73 {
  /* flex: 1; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
}

.beacon-broadcast-help-icon3 {
  height: 16px;
  width: 16px;
  position: relative;
}

.beacon-broadcast-form,
.beacon-broadcast-input-field1,
.beacon-broadcast-input7 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.beacon-broadcast-input7 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
  width: 50%;
}

.beacon-broadcast-email-domain {
  width: 50%;
  position: relative;
  line-height: 20px;
  color: #475467;
  padding: 9px 0px 0px 8px;

}

.beacon-broadcast-email-card {
  flex-direction: row;
  display: flex;
}

.beacon-broadcast-form,
.beacon-broadcast-input-field1 {
  flex-direction: column;
}

.beacon-broadcast-input-field1 {
  align-items: flex-start;
  gap: 6px;
}

.beacon-broadcast-form {
  align-items: flex-end;
}

.beacon-broadcast-label11,
.beacon-broadcast-label12 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 108px;
}

.beacon-broadcast-label12 {
  min-width: 69px;
}

.beacon-broadcast-section2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  max-width: 100%;
}

.beacon-broadcast-divider12,
.beacon-broadcast-section-container {
  align-self: stretch;
  max-width: 100%;
}

.beacon-broadcast-section-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 24px;
  box-sizing: border-box;
}

.beacon-broadcast-divider12 {
  flex: 1;
  position: relative;
  display: none;
  background-color: #eaecf0;
}

.beacon-broadcast-divider-wrapper,
.beacon-broadcast-slide-out-menu-header-parent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.beacon-broadcast-divider-wrapper {
  height: 1px;
  flex-direction: row;
  padding: 0 24px;
  box-sizing: border-box;
}

.beacon-broadcast-slide-out-menu-header-parent {
  flex-direction: column;
  gap: 24px;
  text-align: left;
  font-size: 14px;
  color: #344054;
  font-family: Inter;
}

.beacon-broadcast-placeholder-icon91 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.beacon-broadcast-text275 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 48px;
}

.beacon-broadcast-buttonsbutton49,
.beacon-broadcast-text-padding51 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.beacon-broadcast-buttonsbutton49 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  background-color: #fff;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  gap: 4px;
}

.beacon-broadcast-buttonsbutton49:hover {
  background-color: #f9fafb;
}

.beacon-broadcast-text276 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 55px;
}

.beacon-broadcast-actions19,
.beacon-broadcast-buttonsbutton50 {
  display: flex;
  flex-direction: row;
}

.beacon-broadcast-buttonsbutton50 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  /* flex: 1; */
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.beacon-broadcast-buttonsbutton50:hover {
  background-color: #6941c6;
}

.beacon-broadcast-actions19 {
  width: max-content;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.beacon-broadcast-content76,
.beacon-broadcast-footer2 {
  align-self: stretch;
  display: flex;
  align-items: center;
}

.beacon-broadcast-content76 {
  flex-direction: row;
  justify-content: flex-end;
  padding: 16px 24px;
}

.beacon-broadcast-footer2 {
  border-top: 1px solid #eaecf0;
  flex-direction: column;
  justify-content: flex-start;
}

.beacon-broadcast-panel {
  background-color: #fff;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
}

.beacon-broadcast-panel {
  width: 400px;
  box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08),
    0 8px 8px -4px rgba(16, 24, 40, 0.03);
  /* border-left: 1px solid #eaecf0; */
  border: 1px solid #7e56d9;
  border-radius: 8px;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  /* gap: 433px; */
}

.beacon-broadcast-slide-out-menu {
  flex-direction: row;
  justify-content: flex-end;
  /* padding: 0 0 0 40px; */
  line-height: normal;
  letter-spacing: normal;

  border-radius: 10px;
  background-color: #fff;
  max-width: 100%;
  /* overflow: hidden; */
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;

  position: absolute;
  top: 120px;
  z-index: 3;
  right: 3px;
  /* transform: translateX(100%);  */
  transition: transform 0.3s ease-in-out;


}

.beacon-broadcast-slide-out-menu.open {
  transform: translateX(0);
}


/* New css for start campaign  */
.beacon-broadcast-searchbox-container {

  align-self: stretch;
  /* padding-left: 14px;
  padding-right: 14px;
  padding-top: 10px;
  padding-bottom: 10px; */
  background-color: white;
  /* box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05); */
  border-radius: 8px;
  overflow: hidden;
  /* border: 1px #D0D5DD solid; */
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
  /* Use flex to enable responsiveness */
  margin: 0 23px;
}

.beacon-broadcast-searchbox {
  /* flex: '1 1 0'; */
  height: 24px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}

.beacon-broadcast-searchbox-input-field {
  width: 20px;
  height: 20px;
  padding: 2.50px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.beacon-broadcast-searchbox-search-icon {
  top: 2px;
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;

}

.beacon-broadcast-searchbox-placeholder {


  /* flex: 1;  */
  border: none;
  outline: none;
  background-color: transparent;
  color: #667085;
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 24px;
  padding: 0 8px;
}

.beacon-broadcast-contact-header {
  color: #344054;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
}

.beacon-broadcast-contact-container {
  align-self: stretch;
  height: 196px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.beacon-broadcast-contact-container-menu {
  align-self: stretch;
  justify-content: space-between;
  align-items: flex-start;
  display: inline-flex;
}

.beacon-broadcast-contact-main {
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: flex
}

.beacon-broadcast-contact-avatar-menu {
  width: 40px;
  height: 40px;
  position: relative;
  background: linear-gradient(0deg, #CFCBDC 0%, #CFCBDC 100%);
  border-radius: 9999px;
  /* background-image: url(https://via.placeholder.com/40x40); */
}

.beacon-broadcast-contact-avatar {
  width: 40px;
  height: 40px;
  left: 0px;
  top: 0px;
  background-size: contain;
  position: absolute;
  border-radius: 9999px;
  background-image: url("../../../icons/Gerator/contacts/avatar@3x.png");
  border: 0.75px rgba(0, 0, 0, 0.08) solid;
}

.beacon-broadcast-contact-avatar-online-indicator {
  width: 10px;
  height: 10px;
  left: 30px;
  top: 30px;
  position: absolute;
  background: #17B26A;
  border-radius: 9999px;
  border: 1.50px white solid;
}

.beacon-broadcast-contact-name-and-work-title {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
}

.beacon-broadcast-contact-name {
  color: #344054;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 220px;
}

.beacon-broadcast-contact-work-title {
  color: #475467;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 220px;

}

.beacon-broadcast-contact-checkbox-container {
  padding: 8px;
  border-radius: 8px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
}

.beacon-broadcast-contact-checkbox {
  width: 20px;
  height: 20px;
  padding-left: 1.67px;
  padding-right: 1.67px;
  padding-top: 3.33px;
  padding-bottom: 3.33px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.beacon-broadcast-input-field-container {
  align-self: stretch;
  padding: 10px 3px;
  overflow: hidden;
  margin: 0 23px;
}

@media screen and (max-width: 400px) {
  .beacon-broadcast-panel {
    gap: 216px;
  }
}