.admin-detailed-wanted-spare-frame-child,
.admin-detailed-wanted-spare-frame-item {
  position: relative;
  background-color: #fff;
  display: none;
}

.admin-detailed-wanted-spare-frame-child {
  align-self: stretch;
  height: 8259px;
  z-index: 0;
}

.admin-detailed-wanted-spare-frame-item {
  height: 72px;
  width: 1440px;
  max-width: 100%;
}

.admin-detailed-wanted-spare-gerator-04-1-icon {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}

.admin-detailed-wanted-spare-header-content,
.admin-detailed-wanted-spare-logo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-logo {
  align-self: stretch;
  height: 32px;
  flex-direction: row;
  background-image: url("../../../../icons/Gerator/detailed_listing/gerator04-1@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.admin-detailed-wanted-spare-header-content {
  width: 121px;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
}

.admin-detailed-wanted-spare-bar-chart-01-icon,
.admin-detailed-wanted-spare-dot-icon {
  height: 10px;
  width: 10px;
  position: relative;
  display: none;
}

.admin-detailed-wanted-spare-bar-chart-01-icon {
  height: 24px;
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.admin-detailed-wanted-spare-text {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 30px;
}

.admin-detailed-wanted-spare-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.admin-detailed-wanted-spare-text1 {
  align-self: stretch;
  position: relative;
  line-height: 18px;
  font-weight: 500;
}

.admin-detailed-wanted-spare-badge {
  height: 22px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
}

.admin-detailed-wanted-spare-chevron-down-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.admin-detailed-wanted-spare-nav-item-base {
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  gap: 8px;
}

.admin-detailed-wanted-spare-text2 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 29px;
}

.admin-detailed-wanted-spare-badge1,
.admin-detailed-wanted-spare-nav-item-base1 {
  background-color: #f9fafb;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-badge1 {
  height: 22px;
  border-radius: 9999px;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
  color: #344054;
}

.admin-detailed-wanted-spare-nav-item-base1 {
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  padding: 8px 12px;
  gap: 8px;
  color: #18222f;
}

.admin-detailed-wanted-spare-text4 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 38px;
}

.admin-detailed-wanted-spare-navigation,
.admin-detailed-wanted-spare-navigation1 {
  display: flex;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-navigation1 {
  flex-direction: row;
  align-items: center;
  gap: 4px;
  z-index: 1;
}

.admin-detailed-wanted-spare-navigation {
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
}

.admin-detailed-wanted-spare-label {
  width: 47px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.admin-detailed-wanted-spare-placeholder,
.admin-detailed-wanted-spare-search-lg-icon {
  position: relative;
  overflow: hidden;
}

.admin-detailed-wanted-spare-search-lg-icon {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
}

.admin-detailed-wanted-spare-placeholder {
  flex: 1;
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.admin-detailed-wanted-spare-content3,
.admin-detailed-wanted-spare-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-content3 {
  flex: 1;
  gap: 8px;
}

.admin-detailed-wanted-spare-input {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  padding: 8px 13px;
  font-size: 16px;
  color: #667085;
}

.admin-detailed-wanted-spare-header-container,
.admin-detailed-wanted-spare-input-with-label,
.admin-detailed-wanted-spare-nav-search-avatar {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-input-with-label {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  min-width: 208px;
  z-index: 1;
  font-size: 14px;
}

.admin-detailed-wanted-spare-header-container,
.admin-detailed-wanted-spare-nav-search-avatar {
  flex-direction: row;
  max-width: 100%;
}

.admin-detailed-wanted-spare-nav-search-avatar {
  flex: 1;
  gap: 38px;
  min-width: 366px;
}

.admin-detailed-wanted-spare-header-container {
  width: 754px;
  gap: 70px;
}

.admin-detailed-wanted-spare-icon {
  height: 19px;
  width: 19px;
  position: relative;
}

.admin-detailed-wanted-spare-nav-item-button {
  width: 40px;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  z-index: 1;
}

.admin-detailed-wanted-spare-nav-item-button-child {
  height: 18.3px;
  width: 14.6px;
  position: relative;
}

.admin-detailed-wanted-spare-nav-item-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3px 0 0;
}

.admin-detailed-wanted-spare-contrast-border {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  display: none;
}

.admin-detailed-wanted-spare-avatar {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../../../icons/Gerator/detailed_listing/avatar@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.admin-detailed-wanted-spare-button-container,
.admin-detailed-wanted-spare-rectangle-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}

.admin-detailed-wanted-spare-rectangle-group {
  align-self: stretch;
  background-color: #fff;
  justify-content: space-between;
  padding: 14px 64px 14px 31px;
  box-sizing: border-box;
  max-width: 100%;
  gap: 20px;
  z-index: 1;
  color: #344054;
}

.admin-detailed-wanted-spare-divider-icon,
.admin-detailed-wanted-spare-text6 {
  align-self: stretch;
  position: relative;
}

.admin-detailed-wanted-spare-divider-icon {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}

.admin-detailed-wanted-spare-text6 {
  line-height: 20px;
  font-weight: 500;
}

.admin-detailed-wanted-spare-breadcrumb-button-base {
  width: 28px;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 7px;
  box-sizing: border-box;
  z-index: 3;
  font-size: 14px;
}

.admin-detailed-wanted-spare-chevron-right-icon,
.admin-detailed-wanted-spare-text7 {
  position: relative;
  flex-shrink: 0;
}

.admin-detailed-wanted-spare-chevron-right-icon {
  width: 16px;
  height: 16px;
  overflow: hidden;
  display: none;
  z-index: 4;
}

.admin-detailed-wanted-spare-text7 {
  align-self: stretch;
  line-height: 20px;
  font-weight: 500;
  white-space: nowrap;
}

.admin-detailed-wanted-spare-breadcrumb-button-base1 {
  width: 71px;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  box-sizing: border-box;
  z-index: 5;
  font-size: 14px;
}

.admin-detailed-wanted-spare-chevron-right-icon1 {
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  z-index: 6;
}

.admin-detailed-wanted-spare-breadcrumb-controls-child {
  height: 288px;
  width: 288px;
  position: relative;
  object-fit: cover;
  display: none;
}

.admin-detailed-wanted-spare-arrow-left-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.admin-detailed-wanted-spare-breadcrumb-controls,
.admin-detailed-wanted-spare-testiomonial-carousel-arrow {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.admin-detailed-wanted-spare-testiomonial-carousel-arrow {
  height: 40px;
  width: 40px;
  border-radius: 9999px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.admin-detailed-wanted-spare-breadcrumb-controls {
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 116px 24px;
  background-image: url("../../../../icons/Gerator//detailed_listing/rectangle-5@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-width: 187px;
  min-height: 288px;
  z-index: 1;
}

.admin-detailed-wanted-spare-breadcrumb-controls-icon,
.admin-detailed-wanted-spare-breadcrumb-controls-item {
  width: 288px;
  height: 288px;
  position: relative;
  object-fit: cover;
}

.admin-detailed-wanted-spare-breadcrumb-controls-icon {
  min-height: 288px;
  z-index: 1;
}

.admin-detailed-wanted-spare-breadcrumb-controls-item {
  display: none;
}

.admin-detailed-wanted-spare-maximize-01-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.admin-detailed-wanted-spare-text8 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 109px;
}

.admin-detailed-wanted-spare-buttonsbutton,
.admin-detailed-wanted-spare-testiomonial-carousel-arrow1 {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  z-index: 2;
}

.admin-detailed-wanted-spare-buttonsbutton {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 9px;
  gap: 8px;
}

.admin-detailed-wanted-spare-testiomonial-carousel-arrow1 {
  width: 40px;
  height: 40px;
  border-radius: 9999px;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.admin-detailed-wanted-spare-breadcrumb-controls1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 12px 24px 124px;
  box-sizing: border-box;
  gap: 72px;
  background-image: url("../../../../icons/Gerator/detailed_listing/rectangle-9@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-width: 187px;
  min-height: 288px;
  z-index: 1;
}

.admin-detailed-wanted-spare-breadcrumb-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
}

.admin-detailed-wanted-spare-text9 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 74px;
}

.admin-detailed-wanted-spare-breadcrumb-button-base2 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 8px;
  z-index: 1;
}

.admin-detailed-wanted-spare-chevron-right-icon2 {
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.admin-detailed-wanted-spare-chevron-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 6px 0 0;
}

.admin-detailed-wanted-spare-text10 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 47px;
}

.admin-detailed-wanted-spare-breadcrumb-button-base3 {
  border-radius: 6px;
  background-color: #f9fafb;
  flex-direction: row;
  padding: 4px 8px;
  z-index: 1;
  color: #344054;
}

.admin-detailed-wanted-spare-breadcrumb,
.admin-detailed-wanted-spare-breadcrumb-button-base3,
.admin-detailed-wanted-spare-breadcrumb-container-parent,
.admin-detailed-wanted-spare-breadcrumb-items {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-breadcrumb-items {
  flex-direction: row;
  gap: 8px;
}

.admin-detailed-wanted-spare-breadcrumb,
.admin-detailed-wanted-spare-breadcrumb-container-parent {
  max-width: 100%;
}

.admin-detailed-wanted-spare-breadcrumb {
  flex-direction: row;
  padding: 0 70px;
  box-sizing: border-box;
  font-size: 14px;
  color: #475467;
}

.admin-detailed-wanted-spare-breadcrumb-container-parent {
  align-self: stretch;
  flex-direction: column;
  gap: 24px;
}

.admin-detailed-wanted-spare-pre- {
  text-transform: capitalize;
}

.admin-detailed-wanted-spare-o {
  text-transform: lowercase;
}

.admin-detailed-wanted-spare-description-heading {
  width: 886px;
  position: relative;
  letter-spacing: 0.01em;
  line-height: 28px;
  font-weight: 600;
  display: inline-block;
  max-width: 100%;
  z-index: 1;
}

.admin-detailed-wanted-spare-marker-pin-02-icon,
.admin-detailed-wanted-spare-supporting-text {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.admin-detailed-wanted-spare-marker-pin-02-icon {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  min-height: 20px;
}

.admin-detailed-wanted-spare-supporting-text {
  flex: 1;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.admin-detailed-wanted-spare-detail-items,
.admin-detailed-wanted-spare-details-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-detail-items {
  flex: 1;
  gap: 8px;
}

.admin-detailed-wanted-spare-details-row {
  width: 294px;
  gap: 20px;
}

.admin-detailed-wanted-spare-contact-name {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 41px;
}

.admin-detailed-wanted-spare-contact-name-wrapper {
  width: 50px;
  border-radius: 8px;
  background-color: #fdb022;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 4px;
  box-sizing: border-box;
  z-index: 1;
}

.admin-detailed-wanted-spare-supporting-text2 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 117px;
  z-index: 1;
}

.admin-detailed-wanted-spare-contact-role {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 0 0;
  color: #475467;
}

.admin-detailed-wanted-spare-frame-inner {
  height: 34px;
  width: 127px;
  position: relative;
  border-radius: 8px;
  background-color: #000;
  flex-shrink: 0;
  display: none;
}

.admin-detailed-wanted-spare-icon-backgrounds {
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: 50%;
  background-color: #fff;
  width: 18px;
  height: 18px;
}

.admin-detailed-wanted-spare-icon-backgrounds-parent {
  height: 20px;
  width: 20px;
  position: relative;
  flex-shrink: 0;
  z-index: 1;
}

.admin-detailed-wanted-spare-ellipse-div,
.admin-detailed-wanted-spare-frame-child1 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.admin-detailed-wanted-spare-frame-child1 {
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
}

.admin-detailed-wanted-spare-whatsapp-1-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.admin-detailed-wanted-spare-rectangle-container {
  align-self: stretch;
  border-radius: 8px;
  background-color: #000;
  overflow-x: auto;
  flex-direction: row;
  padding: 7px 5px;
  gap: 4px;
  z-index: 1;
}

.admin-detailed-wanted-spare-contact-details,
.admin-detailed-wanted-spare-details-content,
.admin-detailed-wanted-spare-rectangle-container,
.admin-detailed-wanted-spare-social-links {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-social-links {
  flex: 1;
  flex-direction: column;
  padding: 3px 0 0;
  box-sizing: border-box;
  min-width: 83px;
}

.admin-detailed-wanted-spare-contact-details,
.admin-detailed-wanted-spare-details-content {
  align-self: stretch;
}

.admin-detailed-wanted-spare-contact-details {
  flex-direction: row;
  gap: 10px;
  font-size: 16px;
  color: #fff;
}

.admin-detailed-wanted-spare-details-content {
  flex-direction: column;
  gap: 8px;
}

.admin-detailed-wanted-spare-text11 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 104px;
}

.admin-detailed-wanted-spare-buttonsbutton1,
.admin-detailed-wanted-spare-text-padding {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.admin-detailed-wanted-spare-buttonsbutton1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  padding: 8px 7px;
  gap: 6px;
}

.admin-detailed-wanted-spare-placeholder-icon4 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.admin-detailed-wanted-spare-buttonsbutton2 {
  width: max-content;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 9px;
  gap: 6px;
}

.admin-detailed-wanted-spare-magnifier-1-icon {
  height: 20px;
  width: 20px;
  position: relative;
  object-fit: cover;
}

.admin-detailed-wanted-spare-button-row-child {
  height: 6px;
  width: 28px;
  position: absolute;
  margin: 0 !important;
  top: -2px;
  right: -20px;
  z-index: 2;
}

.admin-detailed-wanted-spare-button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 8px;
  z-index: 1;
  font-size: 16px;
  color: #344054;
  width: max-content;
}

.admin-detailed-wanted-spare-detail-container {
  width: 314px;
  justify-content: flex-start;
  gap: 5px;
}

.admin-detailed-wanted-spare-detail-container,
.admin-detailed-wanted-spare-dropdown,
.admin-detailed-wanted-spare-dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.admin-detailed-wanted-spare-dropdown {
  justify-content: flex-start;
  z-index: 1;
}

.admin-detailed-wanted-spare-dropdown-container {
  justify-content: flex-end;
  padding: 0 0 12px;
  position: relative;
}

.admin-detailed-wanted-spare-listing-details {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 20px;
  font-size: 14px;
  color: #475467;
}

.admin-detailed-wanted-spare-description-block {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: 100%;
  font-size: 20px;
}

.admin-detailed-wanted-spare-heading {
  width: 856px;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  max-width: 100%;
}

.admin-detailed-wanted-spare-heading,
.admin-detailed-wanted-spare-paragraph,
.admin-detailed-wanted-spare-paragraph1 {
  position: relative;
  z-index: 1;
}

.admin-detailed-wanted-spare-paragraph {
  align-self: stretch;
  letter-spacing: 0.01em;
  line-height: 20px;
  font-weight: 500;
}

.admin-detailed-wanted-spare-paragraph1 {
  width: 174px;
  line-height: 24px;
  display: inline-block;
}

.admin-detailed-wanted-spare-first-columns-paragraphs {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 217px;
}

.admin-detailed-wanted-spare-description-columns-first {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 13px;
}

.admin-detailed-wanted-spare-second-columns-paragraphs {
  width: 290px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.admin-detailed-wanted-spare-description-columns-second,
.admin-detailed-wanted-spare-description-content,
.admin-detailed-wanted-spare-heading-parent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-description-columns-second {
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 24px 36px;
  min-height: 120px;
}

.admin-detailed-wanted-spare-description-content,
.admin-detailed-wanted-spare-heading-parent {
  flex-direction: column;
}

.admin-detailed-wanted-spare-description-content {
  gap: 24px;
  font-size: 14px;
  color: #475467;
}

.admin-detailed-wanted-spare-heading-parent {
  gap: 12px;
  max-width: 100%;
}

.admin-detailed-wanted-spare-paragraph18 {
  align-self: stretch;
  position: relative;
  letter-spacing: 0.01em;
  line-height: 20px;
  font-weight: 500;
}

.admin-detailed-wanted-spare-paragraph19 {
  width: 174px;
  position: relative;
  line-height: 24px;
  display: inline-block;
}

.admin-detailed-wanted-spare-description-paragraphs,
.admin-detailed-wanted-spare-third-columns-paragraphs-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 14px;
  color: #475467;
}

.admin-detailed-wanted-spare-third-columns-paragraphs-parent {
  align-self: stretch;
  flex-direction: row;
  gap: 13px;
  z-index: 1;
}

.admin-detailed-wanted-spare-description-paragraphs {
  width: 290px;
  flex-direction: column;
  gap: 4px;
}

.admin-detailed-wanted-spare-heading1,
.admin-detailed-wanted-spare-paragraph26 {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  z-index: 1;
}

.admin-detailed-wanted-spare-heading1 {
  font-weight: 500;
}

.admin-detailed-wanted-spare-paragraph26 {
  color: #475467;
}

.admin-detailed-wanted-spare-content-blocks {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.admin-detailed-wanted-spare-gallery-images-container-child,
.admin-detailed-wanted-spare-rectangle-icon {
  position: relative;
  border-radius: 12px;
  object-fit: cover;
  max-width: 100%;
}

.admin-detailed-wanted-spare-rectangle-icon {
  align-self: stretch;
  height: 368px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}

.admin-detailed-wanted-spare-gallery-images-container-child {
  height: 386px;
  width: 440px;
  display: none;
}

.admin-detailed-wanted-spare-gallery-images-container-item {
  height: 72px;
  width: 72px;
  position: relative;
  z-index: 2;
}

.admin-detailed-wanted-spare-gallery-images-container {
  flex: 1;
  border-radius: 12px;
  justify-content: center;
  padding: 157px 20px;
  box-sizing: border-box;
  background-image: url("../../../../icons/Gerator/detailed_listing/rectangle-431@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-width: 286px;
  z-index: 1;
}

.admin-detailed-wanted-spare-gallery-images,
.admin-detailed-wanted-spare-gallery-images-container,
.admin-detailed-wanted-spare-gallery-images-container1,
.admin-detailed-wanted-spare-image-gallery {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.admin-detailed-wanted-spare-gallery-images-container1 {
  flex: 1;
  border-radius: 12px;
  justify-content: center;
  padding: 157px 20px;
  box-sizing: border-box;
  background-image: url("../../../../icons/Gerator/detailed_listing/rectangle-196@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-width: 286px;
  z-index: 1;
}

.admin-detailed-wanted-spare-gallery-images,
.admin-detailed-wanted-spare-image-gallery {
  align-self: stretch;
  justify-content: flex-start;
  gap: 16px;
}

.admin-detailed-wanted-spare-image-gallery {
  flex-direction: column;
  padding: 0 0 4px;
  box-sizing: border-box;
  gap: 12px;
}

.admin-detailed-wanted-spare-heading6 {
  font-weight: 600;
}

.admin-detailed-wanted-spare-heading6,
.admin-detailed-wanted-spare-heading7,
.admin-detailed-wanted-spare-paragraph30 {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}

.admin-detailed-wanted-spare-heading7 {
  font-weight: 500;
}

.admin-detailed-wanted-spare-paragraph30 {
  color: #475467;
}

.admin-detailed-wanted-spare-benefits-subtitle {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  font-size: 16px;
}

.admin-detailed-wanted-spare-benefits-content {
  gap: 16px;
}

.admin-detailed-wanted-spare-benefits-container,
.admin-detailed-wanted-spare-benefits-content,
.admin-detailed-wanted-spare-section {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-section {
  z-index: 1;
  font-size: 18px;
}

.admin-detailed-wanted-spare-paragraph35 {
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: 100%;
  z-index: 1;
}

.admin-detailed-wanted-spare-second-paragraph {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 6px;
  box-sizing: border-box;
  max-width: 100%;
  color: #475467;
}

.admin-detailed-wanted-spare-progress-fill-icon {
  height: 100%;
  width: 100%;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.admin-detailed-wanted-spare-page-icon {
  width: 32px;
  height: 40px;
  position: relative;
}

.admin-detailed-wanted-spare-file-type {
  align-self: stretch;
  position: relative;
  z-index: 1;
  margin-top: -17px;
}

.admin-detailed-wanted-spare-file-type-icon {
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 4px;
  box-sizing: border-box;
  z-index: 1;
}

.admin-detailed-wanted-spare-supporting-text3,
.admin-detailed-wanted-spare-text12 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 1;
}

.admin-detailed-wanted-spare-text12 {
  font-weight: 500;
}

.admin-detailed-wanted-spare-supporting-text3 {
  color: #475467;
}

.admin-detailed-wanted-spare-download-info {
  width: 326px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  text-align: left;
  font-size: 14px;
  color: #344054;
}

.admin-detailed-wanted-spare-download-items,
.admin-detailed-wanted-spare-download-items-parent,
.admin-detailed-wanted-spare-file-upload-item-base {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
}

.admin-detailed-wanted-spare-file-upload-item-base {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow: hidden;
  padding: 14px 16px;
  position: relative;
  min-width: 287px;
}

.admin-detailed-wanted-spare-download-items,
.admin-detailed-wanted-spare-download-items-parent {
  align-self: stretch;
}

.admin-detailed-wanted-spare-download-items-parent {
  flex-direction: column;
  z-index: 1;
  text-align: center;
  font-size: 9px;
  color: #fff;
}

.admin-detailed-wanted-spare-heading15,
.admin-detailed-wanted-spare-text18 {
  position: relative;
  font-weight: 600;
}

.admin-detailed-wanted-spare-heading15 {
  align-self: stretch;
  font-size: 18px;
  line-height: 24px;
  z-index: 1;
}

.admin-detailed-wanted-spare-text18 {
  line-height: 20px;
  display: inline-block;
  min-width: 52px;
}

.admin-detailed-wanted-spare-button-group-base {
  width: 84px;
  background-color: #f9fafb;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
}

.admin-detailed-wanted-spare-text19 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 53px;
}

.admin-detailed-wanted-spare-button-group-base1 {
  width: 85px;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
}

.admin-detailed-wanted-spare-text20 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.admin-detailed-wanted-spare-button-group-base2,
.admin-detailed-wanted-spare-button-group-base3,
.admin-detailed-wanted-spare-button-group-base4,
.admin-detailed-wanted-spare-button-group-base5,
.admin-detailed-wanted-spare-button-group-base6,
.admin-detailed-wanted-spare-button-group-base7 {
  align-self: stretch;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  min-height: 40px;
  z-index: 6;
}

.admin-detailed-wanted-spare-button-group-base3,
.admin-detailed-wanted-spare-button-group-base4,
.admin-detailed-wanted-spare-button-group-base5,
.admin-detailed-wanted-spare-button-group-base6,
.admin-detailed-wanted-spare-button-group-base7 {
  z-index: 5;
}

.admin-detailed-wanted-spare-button-group-base4,
.admin-detailed-wanted-spare-button-group-base5,
.admin-detailed-wanted-spare-button-group-base6,
.admin-detailed-wanted-spare-button-group-base7 {
  z-index: 4;
}

.admin-detailed-wanted-spare-button-group-base5,
.admin-detailed-wanted-spare-button-group-base6,
.admin-detailed-wanted-spare-button-group-base7 {
  z-index: 3;
}

.admin-detailed-wanted-spare-button-group-base6,
.admin-detailed-wanted-spare-button-group-base7 {
  z-index: 2;
}

.admin-detailed-wanted-spare-button-group-base7 {
  z-index: 1;
}

.admin-detailed-wanted-spare-text26 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 50px;
}

.admin-detailed-wanted-spare-button-group-base8 {
  width: 82px;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
}

.admin-detailed-wanted-spare-button-group {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-supporting-text9,
.admin-detailed-wanted-spare-text27 {
  position: relative;
  line-height: 24px;
}

.admin-detailed-wanted-spare-text27 {
  font-weight: 500;
}

.admin-detailed-wanted-spare-supporting-text9 {
  align-self: stretch;
  width: 54px;
  color: #475467;
  display: none;
}

.admin-detailed-wanted-spare-content5,
.admin-detailed-wanted-spare-input-with-label1,
.admin-detailed-wanted-spare-input1 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.admin-detailed-wanted-spare-content5 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 208px 0 0;
  box-sizing: border-box;
  gap: 7px;
}

.admin-detailed-wanted-spare-input-with-label1,
.admin-detailed-wanted-spare-input1 {
  align-self: stretch;
}

.admin-detailed-wanted-spare-input1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
    0 0 0 4px rgba(158, 119, 237, 0.24);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d6bbfb;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  font-size: 16px;
  color: #101828;
}

.admin-detailed-wanted-spare-input-with-label1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.admin-detailed-wanted-spare-hint-text {
  width: 320px;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.admin-detailed-wanted-spare-input-dropdown {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: 400px;
}

.admin-detailed-wanted-spare-filter-lines-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
}

.admin-detailed-wanted-spare-text28 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 43px;
}

.admin-detailed-wanted-spare-actions,
.admin-detailed-wanted-spare-button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.admin-detailed-wanted-spare-button {
  width: 99px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
}

.admin-detailed-wanted-spare-actions {
  width: 511px;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
}

.admin-detailed-wanted-spare-content4,
.admin-detailed-wanted-spare-filters-bar {
  align-self: stretch;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.admin-detailed-wanted-spare-content4 {
  border-radius: 12px;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 16px;
  gap: 20px;
}

.admin-detailed-wanted-spare-filters-bar {
  background-color: #fff;
  border-bottom: 1px solid #eaecf0;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 1;
  font-size: 14px;
  color: #344054;
}

.admin-detailed-wanted-spare-empty-review,
.admin-detailed-wanted-spare-heading16 {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 1;
}

.admin-detailed-wanted-spare-empty-review {
  height: 44px;
  flex: 1;
  line-height: 24px;
  justify-content: center;
  min-width: 91px;
}

.admin-detailed-wanted-spare-heading16 {
  align-self: stretch;
  height: 37px;
  line-height: 7px;
  font-weight: 500;
  flex-shrink: 0;
}

.admin-detailed-wanted-spare-reviewer {
  width: 55px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 3px;
  box-sizing: border-box;
  text-align: left;
  font-size: 36px;
  color: #101828;
}

.admin-detailed-wanted-spare-first-review {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 2px;
}

.admin-detailed-wanted-spare-star-background-icon {
  height: 28px;
  width: 28px;
  position: relative;
  border-radius: 0.5px;
  min-height: 28px;
}

.admin-detailed-wanted-spare-review-content,
.admin-detailed-wanted-spare-review-stars-background {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-review-stars-background {
  flex-direction: row;
  gap: 2px;
  z-index: 1;
}

.admin-detailed-wanted-spare-review-content {
  width: 148px;
  flex-direction: column;
  gap: 11.5px;
}

.admin-detailed-wanted-spare-rectangle-div {
  width: 200px;
  height: 84px;
  position: relative;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
}

.admin-detailed-wanted-spare-stars {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  z-index: 1;
}

.admin-detailed-wanted-spare-heading17 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 98px;
  z-index: 1;
}

.admin-detailed-wanted-spare-first-review-fifth-star,
.admin-detailed-wanted-spare-rectangle-parent1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 9px;
}

.admin-detailed-wanted-spare-rectangle-parent1 {
  flex: 0.7891;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  flex-direction: column;
  padding: 14px 41px;
  gap: 8px;
  min-width: 150px;
}

.admin-detailed-wanted-spare-heading18 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 107px;
  z-index: 1;
}

.admin-detailed-wanted-spare-heading-wrapper {
  padding: 0 4px;
}

.admin-detailed-wanted-spare-heading-wrapper,
.admin-detailed-wanted-spare-stars-wrapper,
.admin-detailed-wanted-spare-stars2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-stars2 {
  flex: 1;
  gap: 4px;
  z-index: 1;
}

.admin-detailed-wanted-spare-stars-wrapper {
  align-self: stretch;
  padding: 0 15px;
}

.admin-detailed-wanted-spare-heading19 {
  position: relative;
  line-height: 24px;
  z-index: 1;
}

.admin-detailed-wanted-spare-rectangle-parent3,
.admin-detailed-wanted-spare-review-container,
.admin-detailed-wanted-spare-review-stars {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-rectangle-parent3 {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  flex-direction: column;
  padding: 14px 25px 14px 26px;
  gap: 8px;
  min-width: 150px;
}

.admin-detailed-wanted-spare-review-container,
.admin-detailed-wanted-spare-review-stars {
  flex-direction: row;
  max-width: 100%;
}

.admin-detailed-wanted-spare-review-stars {
  flex: 1;
  gap: 16px;
  min-width: 411px;
  z-index: 1;
  text-align: left;
  font-size: 14px;
  color: #101828;
}

.admin-detailed-wanted-spare-review-container {
  width: 812px;
  gap: 32px;
  text-align: center;
  font-size: 56px;
  color: #000;
}

.admin-detailed-wanted-spare-divider-icon3 {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}

.admin-detailed-wanted-spare-divider-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 10px;
  box-sizing: border-box;
  max-width: 100%;
}

.admin-detailed-wanted-spare-client-review-child {
  height: 50px;
  width: 50px;
  position: relative;
  object-fit: cover;
  min-height: 50px;
  z-index: 1;
}

.admin-detailed-wanted-spare-heading20 {
  width: 665px;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: 100%;
  z-index: 2;
}

.admin-detailed-wanted-spare-alvo-medical,
.admin-detailed-wanted-spare-heading20 {
  font-weight: 500;
}

.admin-detailed-wanted-spare-heading21 {
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 24px;
  z-index: 1;
}

.admin-detailed-wanted-spare-client-review-header,
.admin-detailed-wanted-spare-stars3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-client-review-header {
  flex: 1;
  flex-direction: column;
  padding: 0 10px 0 0;
  box-sizing: border-box;
  min-width: 451px;
  max-width: 100%;
}

.admin-detailed-wanted-spare-stars3 {
  flex-direction: row;
  gap: 4px;
  z-index: 1;
}

.admin-detailed-wanted-spare-heading22 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 74px;
  z-index: 1;
}

.admin-detailed-wanted-spare-rating-value {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}

.admin-detailed-wanted-spare-client-rating,
.admin-detailed-wanted-spare-client-review-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-client-rating {
  align-self: stretch;
  gap: 4px;
}

.admin-detailed-wanted-spare-client-review-content {
  width: 116px;
  padding: 2px 0 0;
  box-sizing: border-box;
  font-size: 12px;
}

.admin-detailed-wanted-spare-client-review,
.admin-detailed-wanted-spare-description-block-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.admin-detailed-wanted-spare-client-review {
  width: 884px;
  flex-direction: row;
  gap: 12px;
}

.admin-detailed-wanted-spare-description-block-parent {
  flex: 1;
  flex-direction: column;
  gap: 22px;
}

.admin-detailed-wanted-spare-card-content-child {
  align-self: stretch;
  height: 532px;
  position: relative;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
}

.admin-detailed-wanted-spare-dot-icon3 {
  height: 8px;
  width: 8px;
  position: relative;
  display: none;
}

.admin-detailed-wanted-spare-text29 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 29px;
}

.admin-detailed-wanted-spare-badge-wrapper,
.admin-detailed-wanted-spare-badge3 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-badge3 {
  flex: 1;
  border-radius: 6px;
  background-color: #17b26a;
  padding: 2px 8px;
  gap: 6px;
  mix-blend-mode: normal;
  z-index: 2;
}

.admin-detailed-wanted-spare-badge-wrapper {
  align-self: stretch;
  padding: 0 69px;
}

.admin-detailed-wanted-spare-discount,
.admin-detailed-wanted-spare-price {
  position: relative;
  z-index: 2;
}

.admin-detailed-wanted-spare-price {
  align-self: stretch;
  letter-spacing: -0.02em;
  line-height: 32px;
  font-weight: 600;
  white-space: nowrap;
}

.admin-detailed-wanted-spare-discount {
  line-height: 20px;
  display: inline-block;
  min-width: 97px;
}

.admin-detailed-wanted-spare-original-price,
.admin-detailed-wanted-spare-price-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-original-price {
  flex-direction: row;
  padding: 0 44px;
  font-size: 14px;
  color: #475467;
}

.admin-detailed-wanted-spare-price-parent {
  align-self: stretch;
  flex-direction: column;
  gap: 2px;
  font-size: 26px;
  color: #6941c6;
}

.admin-detailed-wanted-spare-service-title {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  z-index: 2;
}

.admin-detailed-wanted-spare-service-title-container {
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 12px 0 11px;
  color: #475467;
}

.admin-detailed-wanted-spare-card-header,
.admin-detailed-wanted-spare-price-tag,
.admin-detailed-wanted-spare-service-title-container {
  display: flex;
  align-items: flex-start;
}

.admin-detailed-wanted-spare-price-tag {
  width: 185px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 9px;
}

.admin-detailed-wanted-spare-card-header {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  padding: 0 20px 0 22px;
}

.admin-detailed-wanted-spare-card-footer-child {
  height: 96px;
  width: 96px;
  /* position: absolute; */
  margin: 0 !important;
  /* right: 130px;
  bottom: -4px; */
  object-fit: cover;
  z-index: 2;
  border-radius: 80px;
}

.admin-detailed-wanted-spare-divider-icon4 {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 2;
}

.admin-detailed-wanted-spare-footer-divider {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 116px;
  box-sizing: border-box;
  max-width: 100%;
}

.admin-detailed-wanted-spare-amlie-laurent,
.admin-detailed-wanted-spare-card-footer,
.admin-detailed-wanted-spare-role {
  align-self: stretch;
  position: relative;
}

.admin-detailed-wanted-spare-card-footer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
}

.admin-detailed-wanted-spare-amlie-laurent,
.admin-detailed-wanted-spare-role {
  line-height: 28px;
  font-weight: 600;
  z-index: 2;
}

.admin-detailed-wanted-spare-role {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #6941c6;
  z-index: 3;
}

.admin-detailed-wanted-spare-bookmark-check-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}

.admin-detailed-wanted-spare-supporting-text10 {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 114px;
  z-index: 2;
}

.admin-detailed-wanted-spare-contact-button,
.admin-detailed-wanted-spare-message-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-message-button {
  flex: 1;
  gap: 2px;
}

.admin-detailed-wanted-spare-contact-button {
  align-self: stretch;
  padding: 0 52px;
}

.admin-detailed-wanted-spare-supporting-text11 {
  align-self: stretch;
  position: relative;
  line-height: 18px;
  z-index: 2;
}

.admin-detailed-wanted-spare-action-buttons {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-size: 12px;
  color: #475467;
}

.admin-detailed-wanted-spare-text30 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 113px;
}

.admin-detailed-wanted-spare-buttonsbutton5,
.admin-detailed-wanted-spare-buttonsbutton6 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  z-index: 2;
}

.admin-detailed-wanted-spare-buttonsbutton5 {
  background-color: #7e56d9;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 12px;
  gap: 6px;
  white-space: nowrap;
}

.admin-detailed-wanted-spare-buttonsbutton6 {
  background-color: #56d97b;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.admin-detailed-wanted-spare-buttonsbutton6-add {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  z-index: 2;
  border: 1px solid #667085;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.admin-detailed-wanted-spare-button-container2,
.admin-detailed-wanted-spare-favorite-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.admin-detailed-wanted-spare-button-container2 {
  flex: 1;
  gap: 16px;
}

.admin-detailed-wanted-spare-favorite-button {
  align-self: stretch;
  padding: 0 12px 0 11px;
  text-align: left;
  font-size: 14px;
  color: #eaecf0;
}

.admin-detailed-wanted-spare-professional-actions {
  flex: 1;
  gap: 24px;
  min-width: 156px;
}

.admin-detailed-wanted-spare-dropdown-menu,
.admin-detailed-wanted-spare-dropdown1,
.admin-detailed-wanted-spare-professional-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-dropdown1 {
  z-index: 2;
}

.admin-detailed-wanted-spare-dropdown-menu {
  padding: 19px 0 0;
  position: relative;
}

.admin-detailed-wanted-spare-professional-details,
.admin-detailed-wanted-spare-professional-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.admin-detailed-wanted-spare-professional-details {
  flex: 1;
  justify-content: flex-start;
  gap: 29px;
}

.admin-detailed-wanted-spare-professional-info {
  width: 307px;
  justify-content: flex-end;
  padding: 0 9px;
  box-sizing: border-box;
  font-size: 18px;
  color: rgba(16, 24, 40, 0.9);
}

.admin-detailed-wanted-spare-card-content,
.admin-detailed-wanted-spare-frame-child6 {
  align-self: stretch;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
}

.admin-detailed-wanted-spare-card-content {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 22px 0;
  gap: 24px;
  max-width: 100%;
  z-index: 1;
}

.admin-detailed-wanted-spare-frame-child6 {
  height: 622px;
  position: relative;
  display: none;
}

.admin-detailed-wanted-spare-description,
.admin-detailed-wanted-spare-heading23 {
  position: relative;
  display: inline-block;
  z-index: 1;
}

.admin-detailed-wanted-spare-heading23 {
  width: 162px;
  line-height: 24px;
  font-weight: 600;
  flex-shrink: 0;
}

.admin-detailed-wanted-spare-description {
  line-height: 20px;
  font-weight: 500;
  min-width: 54px;
}

.admin-detailed-wanted-spare-testimonial-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 0 0;
  font-size: 14px;
  color: #6941c6;
}

.admin-detailed-wanted-spare-header-content1,
.admin-detailed-wanted-spare-testimonial-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.admin-detailed-wanted-spare-header-content1 {
  flex: 1;
  justify-content: space-between;
  gap: 20px;
}

.admin-detailed-wanted-spare-testimonial-header {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 14px 0 16px;
  box-sizing: border-box;
}

.admin-detailed-wanted-spare-frame-child7 {
  height: 76px;
  width: 76px;
  position: relative;
  border-radius: 12px;
  background-color: #f5f5f5;
  display: none;
}

.admin-detailed-wanted-spare-f4b711e503bc72fe396e5043e0c2-7-icon {
  height: 46px;
  width: 46px;
  position: relative;
  object-fit: cover;
  z-index: 1;
}

.admin-detailed-wanted-spare-rectangle-parent5 {
  border-radius: 12px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 15px;
  z-index: 1;
}

.admin-detailed-wanted-spare-client-name,
.admin-detailed-wanted-spare-heading24 {
  position: relative;
  line-height: 24px;
  z-index: 1;
}

.admin-detailed-wanted-spare-client-name {
  align-self: stretch;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  font-weight: 500;
}

.admin-detailed-wanted-spare-heading24 {
  width: 164px;
  font-size: 14px;
  display: inline-block;
}

.admin-detailed-wanted-spare-testimonial-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 165px;
}

.admin-detailed-wanted-spare-testimonial-divider,
.admin-detailed-wanted-spare-testimonial-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.admin-detailed-wanted-spare-testimonial-item {
  flex: 1;
  justify-content: flex-start;
  gap: 12px;
}

.admin-detailed-wanted-spare-testimonial-divider {
  width: 344px;
  justify-content: flex-end;
  padding: 0 1px;
  box-sizing: border-box;
}

.admin-detailed-wanted-spare-rectangle-parent4,
.admin-detailed-wanted-spare-testimonial-list {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 16.4px;
  max-width: 100%;
  font-size: 16px;
}

.admin-detailed-wanted-spare-rectangle-parent4 {
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 22px 0 15px;
  gap: 24px;
  z-index: 1;
  text-align: left;
  font-size: 18px;
  color: #101828;
}

.admin-detailed-wanted-spare-frame-parent,
.admin-detailed-wanted-spare-listing-content,
.admin-detailed-wanted-spare-listing-content-wrapper,
.admin-detailed-wanted-spare-service-card {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.admin-detailed-wanted-spare-service-card {
  width: 360px;
  flex-direction: column;
  gap: 24px;
  min-width: 360px;
  text-align: center;
  font-size: 14px;
  color: #fff;
}

.admin-detailed-wanted-spare-frame-parent,
.admin-detailed-wanted-spare-listing-content,
.admin-detailed-wanted-spare-listing-content-wrapper {
  flex-direction: row;
}

.admin-detailed-wanted-spare-listing-content {
  flex: 1;
  gap: 14px;
}

.admin-detailed-wanted-spare-frame-parent,
.admin-detailed-wanted-spare-listing-content-wrapper {
  align-self: stretch;
  padding: 0 80px;
  box-sizing: border-box;
  color: #101828;
}

.admin-detailed-wanted-spare-frame-parent {
  flex-direction: column;
  padding: 0 2px 24px 0;
  gap: 16px;
  color: #344054;
  margin-top: 50px;
}

.admin-detailed-wanted-spare-paragraph37 {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  max-width: 100%;
  z-index: 1;
}

.admin-detailed-wanted-spare-listing-image-grid,
.admin-detailed-wanted-spare-listing-image-grid1 {
  width: 1056px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.admin-detailed-wanted-spare-listing-image-grid {
  padding: 0 80px 8px;
  color: #344054;
}

.admin-detailed-wanted-spare-listing-image-grid1 {
  padding: 0 80px 24px;
}

.admin-detailed-wanted-spare-feedback-content-child {
  height: 50px;
  width: 50px;
  position: relative;
  object-fit: cover;
  z-index: 1;
}

.admin-detailed-wanted-spare-feedback-header,
.admin-detailed-wanted-spare-header-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
}

.admin-detailed-wanted-spare-header-info {
  align-self: stretch;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-feedback-header {
  flex: 1;
  justify-content: flex-end;
  padding: 0 10px 2px 0;
  box-sizing: border-box;
  min-width: 451px;
}

.admin-detailed-wanted-spare-text35 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 38px;
}

.admin-detailed-wanted-spare-buttonsbutton7 {
  width: 90px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  overflow: hidden;
  flex-direction: row;
  justify-content: flex-start;
  padding: 8px 12px;
  box-sizing: border-box;
  gap: 6px;
  z-index: 1;
}

.admin-detailed-wanted-spare-buttonsbutton7,
.admin-detailed-wanted-spare-feedback-score,
.admin-detailed-wanted-spare-stars-parent {
  display: flex;
  align-items: flex-start;
}

.admin-detailed-wanted-spare-stars-parent {
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}

.admin-detailed-wanted-spare-feedback-score {
  align-self: stretch;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0 12px 0 0;
  font-size: 12px;
  color: #101828;
}

.admin-detailed-wanted-spare-feedback-rating {
  width: 128px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 26px;
  font-size: 14px;
  color: #eaecf0;
}

.admin-detailed-wanted-spare-client-feedback,
.admin-detailed-wanted-spare-feedback-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 100%;
}

.admin-detailed-wanted-spare-feedback-content {
  flex: 1;
  align-items: flex-end;
  flex-wrap: wrap;
  align-content: flex-end;
  gap: 12px;
}

.admin-detailed-wanted-spare-client-feedback {
  width: 1056px;
  align-items: flex-start;
  padding: 0 80px 24px;
  box-sizing: border-box;
  color: #101828;
}

.admin-detailed-wanted-spare-paragraph39 {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  z-index: 1;
}

.admin-detailed-wanted-spare-client-message,
.admin-detailed-wanted-spare-message-content {
  justify-content: flex-start;
  max-width: 100%;
}

.admin-detailed-wanted-spare-message-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.admin-detailed-wanted-spare-client-message {
  width: 1056px;
  padding: 0 80px 24px;
  box-sizing: border-box;
  color: #344054;
}

.admin-detailed-wanted-spare-buttonsbutton8,
.admin-detailed-wanted-spare-client-message,
.admin-detailed-wanted-spare-view-replies {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.admin-detailed-wanted-spare-buttonsbutton8 {
  width: 90px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: 8px 12px;
  box-sizing: border-box;
  gap: 6px;
  z-index: 1;
}

.admin-detailed-wanted-spare-view-replies {
  align-self: stretch;
  justify-content: center;
  padding: 0 20px 24px 440px;
  font-size: 14px;
  color: #eaecf0;
}

.admin-detailed-wanted-spare-page-number {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 59px;
}

.admin-detailed-wanted-spare-button-wrap,
.admin-detailed-wanted-spare-buttonsbutton9 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.admin-detailed-wanted-spare-buttonsbutton9 {
  width: 87px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  padding: 6px 11px;
  gap: 4px;
}

.admin-detailed-wanted-spare-button-wrap {
  flex: 1;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-details,
.admin-detailed-wanted-spare-text37 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 44px;
}

.admin-detailed-wanted-spare-text37 {
  font-weight: 600;
  min-width: 32px;
}

.admin-detailed-wanted-spare-button-wrap1,
.admin-detailed-wanted-spare-buttonsbutton10 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.admin-detailed-wanted-spare-buttonsbutton10 {
  width: 60px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  padding: 6px 11px;
  gap: 4px;
}

.admin-detailed-wanted-spare-button-wrap1 {
  flex: 1;
  justify-content: flex-end;
}

.admin-detailed-wanted-spare-pagination,
.admin-detailed-wanted-spare-pagination-wrapper {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: 100%;
}

.admin-detailed-wanted-spare-pagination {
  flex: 1;
  background-color: #fff;
  border-top: 1px solid #eaecf0;
  overflow-x: auto;
  align-items: center;
  justify-content: center;
  padding: 12px 24px 16px;
  gap: 12px;
  z-index: 1;
}

.admin-detailed-wanted-spare-pagination-wrapper {
  width: 1056px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 80px 24px;
  font-size: 14px;
  color: #344054;
}

.admin-detailed-wanted-spare-review-form-child {
  width: 896px;
  height: 590px;
  position: relative;
  background-color: #f9fafb;
  display: none;
  max-width: 100%;
}

.admin-detailed-wanted-spare-heading32,
.admin-detailed-wanted-spare-heading33 {
  position: relative;
  line-height: 24px;
}

.admin-detailed-wanted-spare-heading32 {
  align-self: stretch;
  font-size: 18px;
  font-weight: 600;
  z-index: 2;
}

.admin-detailed-wanted-spare-heading33 {
  width: 107px;
  display: inline-block;
}

.admin-detailed-wanted-spare-heading-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.admin-detailed-wanted-spare-heading35 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 116px;
}

.admin-detailed-wanted-spare-frame-parent3 {
  width: 526px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  max-width: 100%;
  z-index: 2;
}

.admin-detailed-wanted-spare-label2 {
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 30px;
}

.admin-detailed-wanted-spare-label2,
.admin-detailed-wanted-spare-label3,
.admin-detailed-wanted-spare-text38 {
  position: relative;
}

.admin-detailed-wanted-spare-label3 {
  width: 77px;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.admin-detailed-wanted-spare-text38 {
  flex: 1;
  line-height: 24px;
  display: inline-block;
  max-width: 100%;
}

.admin-detailed-wanted-spare-input-with-label3,
.admin-detailed-wanted-spare-input2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-input2 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  padding: 10px 13px 2px;
  max-width: 100%;
  font-size: 16px;
  color: #101828;
}

.admin-detailed-wanted-spare-input-with-label3 {
  width: 848px;
  flex-direction: column;
  gap: 6px;
  max-width: 166%;
  flex-shrink: 0;
}

.admin-detailed-wanted-spare-hint-text1 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: #475467;
}

.admin-detailed-wanted-spare-content6,
.admin-detailed-wanted-spare-input-field,
.admin-detailed-wanted-spare-textarea-input-field {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-textarea-input-field {
  width: 512px;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  gap: 6px;
  min-width: 480px;
  max-width: 512px;
}

.admin-detailed-wanted-spare-content6,
.admin-detailed-wanted-spare-input-field {
  max-width: 100%;
}

.admin-detailed-wanted-spare-content6 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}

.admin-detailed-wanted-spare-input-field {
  width: 848px;
  flex-direction: column;
  gap: 6px;
  z-index: 2;
  color: #344054;
}

.admin-detailed-wanted-spare-label4,
.admin-detailed-wanted-spare-text39 {
  position: relative;
  display: inline-block;
}

.admin-detailed-wanted-spare-label4 {
  line-height: 20px;
  font-weight: 500;
  min-width: 49px;
}

.admin-detailed-wanted-spare-text39 {
  height: 158px;
  flex: 1;
  line-height: 24px;
  max-width: 100%;
}

.admin-detailed-wanted-spare-input3 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 13px;
  max-width: 100%;
  font-size: 16px;
  color: #101828;
}

.admin-detailed-wanted-spare-hint-text3 {
  width: 848px;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: inline-block;
  max-width: 166%;
  flex-shrink: 0;
}

.admin-detailed-wanted-spare-input-field1,
.admin-detailed-wanted-spare-textarea-input-field1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  gap: 6px;
}

.admin-detailed-wanted-spare-textarea-input-field1 {
  height: 128px;
  width: 512px;
  padding: 0;
  min-width: 480px;
  max-width: 512px;
}

.admin-detailed-wanted-spare-input-field1 {
  width: 848px;
  padding: 0 0 54px;
  min-height: 208px;
  max-width: 100%;
  z-index: 2;
  color: #344054;
}

.admin-detailed-wanted-spare-log-in-04-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.admin-detailed-wanted-spare-text40 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 48px;
}

.admin-detailed-wanted-spare-button1,
.admin-detailed-wanted-spare-submit-button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.admin-detailed-wanted-spare-button1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  overflow: hidden;
  justify-content: flex-start;
  padding: 8px 14px;
  gap: 6px;
  z-index: 2;
}

.admin-detailed-wanted-spare-submit-button-wrapper {
  justify-content: flex-end;
  padding: 0 24px;
  color: #fff;
}

.admin-detailed-wanted-spare-review-form,
.admin-detailed-wanted-spare-review-form-wrapper {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.admin-detailed-wanted-spare-review-form {
  flex: 1;
  background-color: #f9fafb;
  flex-direction: column;
  align-items: flex-end;
  padding: 24px 0 24px 24px;
  gap: 50px;
  z-index: 1;
}

.admin-detailed-wanted-spare-review-form-wrapper {
  width: 1056px;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 80px 24px;
  font-size: 14px;
  color: #101828;
}

.admin-detailed-wanted-spare-divider-icon11 {
  width: 1360px;
  position: relative;
  max-height: 100%;
  max-width: 100%;
  z-index: 1;
}

.admin-detailed-wanted-spare-listing-image-grid3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 2px 24px;
  box-sizing: border-box;
  max-width: 100%;
}

.admin-detailed-wanted-spare-heading36 {
  width: 802px;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
  z-index: 1;
}

.admin-detailed-wanted-spare-heading-parent3,
.admin-detailed-wanted-spare-text-wrapper {
  display: flex;
  align-items: flex-start;
}

.admin-detailed-wanted-spare-text-wrapper {
  flex-direction: column;
  justify-content: flex-start;
  padding: 2px 0 0;
  font-size: 14px;
  color: #7e56d9;
}

.admin-detailed-wanted-spare-heading-parent3 {
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
  gap: 20px;
}

.admin-detailed-wanted-spare-frame-child12,
.admin-detailed-wanted-spare-listing-image-grid4 {
  align-self: stretch;
  box-sizing: border-box;
}

.admin-detailed-wanted-spare-listing-image-grid4 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 83px 24px 80px;
  max-width: 100%;
  font-size: 18px;
  color: #101828;
}

.admin-detailed-wanted-spare-frame-child12 {
  height: 449px;
  position: relative;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  display: none;
}

.admin-detailed-wanted-spare-listing-card-badge-overlay-child {
  align-self: stretch;
  width: 322px;
  position: relative;
  border-radius: 12px 12px 0 0;
  max-height: 100%;
  object-fit: cover;
  display: none;
  min-height: 172px;
  max-width: 100%;
}

.admin-detailed-wanted-spare-listing-card-price {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 48px;
}

.admin-detailed-wanted-spare-badge4 {
  width: 62px;
  border-radius: 9999px;
  background-color: #fff;
  border: 1px solid #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 6px;
  mix-blend-mode: normal;
  z-index: 3;
}

.admin-detailed-wanted-spare-listing-card-radar {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 65px;
}

.admin-detailed-wanted-spare-badge5,
.admin-detailed-wanted-spare-buttonsbutton11 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  z-index: 3;
}

.admin-detailed-wanted-spare-badge5 {
  width: 77px;
  border-radius: 9999px;
  background-color: #fff;
  border: 1px solid #fff;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 5px;
  mix-blend-mode: normal;
  white-space: nowrap;
}

.admin-detailed-wanted-spare-buttonsbutton11 {
  width: 44px;
  backdrop-filter: blur(16px);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.6);
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  padding: 10px 11px;
}

.admin-detailed-wanted-spare-image-overlay,
.admin-detailed-wanted-spare-listing-card-badge-overlay {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.admin-detailed-wanted-spare-image-overlay {
  flex: 1;
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.2));
  flex-direction: column;
  align-items: flex-end;
  padding: 12px 9px 8px 8px;
  box-sizing: border-box;
  gap: 86px;
  z-index: 2;
}

.admin-detailed-wanted-spare-listing-card-badge-overlay {
  align-self: stretch;
  border-radius: 12px 12px 0 0;
  flex-direction: row;
  align-items: flex-start;
  background-image: url("../../../../icons/Gerator/detailed_listing/rectangle-95@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.admin-detailed-wanted-spare-text42 {
  flex: 1;
  position: relative;
  line-height: 28px;
  font-weight: 600;
  z-index: 1;
}

.admin-detailed-wanted-spare-listing-detail-item-date,
.admin-detailed-wanted-spare-text-frame {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-text-frame {
  width: 296px;
  flex-direction: row;
  padding: 0 15px;
  box-sizing: border-box;
}

.admin-detailed-wanted-spare-listing-detail-item-date {
  flex-direction: column;
  padding: 4px 0 0;
}

.admin-detailed-wanted-spare-location {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 93px;
  z-index: 1;
}

.admin-detailed-wanted-spare-listing-detail-item-date-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.admin-detailed-wanted-spare-type-of-work {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 82px;
  z-index: 1;
}

.admin-detailed-wanted-spare-listing-detail-item-location {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}

.admin-detailed-wanted-spare-listing-team-member {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 36px;
}

.admin-detailed-wanted-spare-listing-team-member-wrapper {
  border-radius: 8px;
  background-color: #fdb022;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 7px;
  z-index: 1;
}

.admin-detailed-wanted-spare-supporting-text12 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 102px;
  z-index: 1;
}

.admin-detailed-wanted-spare-listing-detail-item-pin-wrapper,
.admin-detailed-wanted-spare-listing-team {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-listing-team {
  padding: 0 20px 0 0;
  gap: 8px;
  font-size: 14px;
  color: #fff;
}

.admin-detailed-wanted-spare-listing-detail-item-pin-wrapper {
  padding: 0 15px;
}

.admin-detailed-wanted-spare-listing-short-info {
  position: relative;
  line-height: 30px;
  font-weight: 600;
  white-space: nowrap;
  z-index: 1;
}

.admin-detailed-wanted-spare-supporting-text13 {
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 77px;
  z-index: 1;
}

.admin-detailed-wanted-spare-listing,
.admin-detailed-wanted-spare-supporting-text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 0 0;
  font-size: 12px;
  color: #475467;
}

.admin-detailed-wanted-spare-listing {
  flex-direction: row;
  padding: 0 15px;
  font-size: 18px;
  color: #6941c6;
}

.admin-detailed-wanted-spare-name {
  margin-bottom: -4px;
  flex: 1;
  position: relative;
  line-height: 23px;
  font-weight: 600;
  flex-shrink: 0;
  z-index: 1;
}

.admin-detailed-wanted-spare-listing-short-detail-item-pin {
  align-self: stretch;
  height: 19px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 10px 0 0;
  box-sizing: border-box;
}

.admin-detailed-wanted-spare-role1 {
  align-self: stretch;
  height: 19px;
  position: relative;
  font-size: 10px;
  line-height: 24px;
  color: #6941c6;
  display: inline-block;
  flex-shrink: 0;
  z-index: 1;
}

.admin-detailed-wanted-spare-listing-short-detail-item-pin-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-icon1 {
  width: 18px;
  height: 18px;
  position: relative;
  z-index: 1;
}

.admin-detailed-wanted-spare-listing-short-detail-item-date {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 12px 0 0;
}

.admin-detailed-wanted-spare-listing-detail-item-value,
.admin-detailed-wanted-spare-listing-detail-item-value-inner {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-listing-detail-item-value-inner {
  flex-direction: row;
  padding: 0 9px 0 12px;
  color: #101828;
}

.admin-detailed-wanted-spare-listing-detail-item-value {
  flex-direction: column;
  gap: 15.3px;
  font-size: 12px;
  color: #475467;
}

.admin-detailed-wanted-spare-listing-detail-item,
.admin-detailed-wanted-spare-rectangle-parent10 {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.admin-detailed-wanted-spare-listing-detail-item {
  align-self: stretch;
  flex-direction: row;
  padding: 0 1px;
  text-align: left;
  font-size: 16px;
  color: #101828;
}

.admin-detailed-wanted-spare-rectangle-parent10 {
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  flex-direction: column;
  padding: 0 0 14px;
  gap: 20px;
}

.admin-detailed-wanted-spare-short-listing-team {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 20px;
  color: #8e8e8e;
}

.admin-detailed-wanted-spare-footer-social-child {
  height: 42px;
  width: 42px;
  position: relative;
  object-fit: cover;
  z-index: 1;
}

.admin-detailed-wanted-spare-footer-social,
.admin-detailed-wanted-spare-footer-social-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.admin-detailed-wanted-spare-footer-social {
  width: 137px;
  justify-content: flex-start;
  gap: 8px;
}

.admin-detailed-wanted-spare-footer-social-parent {
  flex: 1;
  justify-content: space-between;
  gap: 20px;
}

.admin-detailed-wanted-spare-buttonsbutton14 {
  width: 44px;
  margin: 0 !important;
  position: absolute;
  top: -22px;
  right: 0;
  backdrop-filter: blur(16px);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 11px;
  z-index: 3;
}

.admin-detailed-wanted-spare-badge-group {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  color: #8e8e8e;
}

.admin-detailed-wanted-spare-badge-group,
.admin-detailed-wanted-spare-image-overlay3,
.admin-detailed-wanted-spare-work-four-wrapper {
  display: flex;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-image-overlay3 {
  flex: 1;
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.2));
  flex-direction: column;
  align-items: flex-end;
  padding: 12px 9px 8px 8px;
  box-sizing: border-box;
  gap: 108px;
  max-width: 100%;
  z-index: 2;
}

.admin-detailed-wanted-spare-work-four-wrapper {
  flex-direction: row;
  align-items: flex-start;
  padding: 0 15px 8px;
  font-size: 12px;
  color: #475467;
}

.admin-detailed-wanted-spare-icon4 {
  height: 18px;
  width: 18px;
  position: absolute;
  margin: 0 !important;
  right: 9px;
  bottom: 12px;
  z-index: 1;
}

.admin-detailed-wanted-spare-agent-details-icon,
.admin-detailed-wanted-spare-agent-extra-info,
.admin-detailed-wanted-spare-another-agent-info {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-another-agent-info {
  width: 161px;
  flex-direction: row;
  padding: 0 12px;
  box-sizing: border-box;
  font-size: 12px;
  color: #101828;
}

.admin-detailed-wanted-spare-agent-details-icon,
.admin-detailed-wanted-spare-agent-extra-info {
  max-width: 100%;
}

.admin-detailed-wanted-spare-agent-extra-info {
  flex: 1;
  flex-direction: column;
  gap: 15px;
}

.admin-detailed-wanted-spare-agent-details-icon {
  align-self: stretch;
  flex-direction: row;
  position: relative;
  font-size: 18px;
  color: #6941c6;
}

.admin-detailed-wanted-spare-testiomonial-carousel-arrow2 {
  height: 40px;
  width: 40px;
  margin: 0 !important;
  position: absolute;
  bottom: 204px;
  left: -19px;
  border-radius: 9999px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.admin-detailed-wanted-spare-frame-parent5 {
  position: absolute;
  top: 0;
  left: 20px;
  width: 1340px;
  display: grid;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  max-width: 100%;
  grid-template-columns: repeat(4, minmax(242px, 1fr));
  z-index: 1;
}

.admin-detailed-wanted-spare-testiomonial-carousel-arrow3 {
  position: absolute;
  top: 205px;
  left: 1342px;
  border-radius: 9999px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.admin-detailed-wanted-spare-frame-parent4 {
  height: 449px;
  width: 100%;
  position: relative;
  max-width: 100%;
  margin-left: 50px;
}

.admin-detailed-wanted-spare-listing-image-grid5 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 0 24px;
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: 12px;
  color: #fdb022;
}

.admin-detailed-wanted-spare-testiomonial-carousel-arrow4,
.admin-detailed-wanted-spare-testiomonial-carousel-arrow5 {
  position: absolute;
  top: 204px;
  border-radius: 9999px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.admin-detailed-wanted-spare-testiomonial-carousel-arrow4 {
  margin: 0 !important;
  left: -19px;
}

.admin-detailed-wanted-spare-testiomonial-carousel-arrow5 {
  left: 1342px;
}

.admin-detailed-wanted-spare-heading38 {
  width: 802px;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  white-space: pre-wrap;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
  z-index: 1;
}

.admin-detailed-wanted-spare-rectangle-parent30,
.admin-detailed-wanted-spare-supporting-six-wrapper,
.admin-detailed-wanted-spare-supporting-work-five {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-rectangle-parent30 {
  align-self: stretch;
  border-radius: 12px 12px 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  max-width: 100%;
  z-index: 1;
}

.admin-detailed-wanted-spare-supporting-six-wrapper,
.admin-detailed-wanted-spare-supporting-work-five {
  padding: 0 15px 8px;
  font-size: 14px;
  color: #fff;
}

.admin-detailed-wanted-spare-supporting-six-wrapper {
  padding: 0 15px 6px;
  font-size: 18px;
  color: #6941c6;
}

.admin-detailed-wanted-spare-divider-container {
  align-self: stretch;
  padding: 0 0 8px;
  box-sizing: border-box;
  max-width: 100%;
}

.admin-detailed-wanted-spare-divider-container,
.admin-detailed-wanted-spare-icon-group,
.admin-detailed-wanted-spare-team-info-four {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-team-info-four {
  width: 161px;
  padding: 0 12px;
  box-sizing: border-box;
  color: #101828;
}

.admin-detailed-wanted-spare-icon-group {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  font-size: 12px;
  color: #475467;
}

.admin-detailed-wanted-spare-testiomonial-carousel-arrow6 {
  height: 40px;
  width: 40px;
  margin: 0 !important;
  top: 203px;
  left: -19px;
  border-radius: 9999px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.admin-detailed-wanted-spare-breadcrumb-button-base4,
.admin-detailed-wanted-spare-testiomonial-carousel-arrow6,
.admin-detailed-wanted-spare-testiomonial-carousel-arrow7 {
  position: absolute;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.admin-detailed-wanted-spare-testiomonial-carousel-arrow7 {
  top: 203px;
  left: 1342px;
  border-radius: 9999px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.admin-detailed-wanted-spare-breadcrumb-button-base4 {
  margin: 0 !important;
  top: 518px;
  left: 244px;
  border-radius: 6px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 7px;
  font-size: 14px;
}

.admin-detailed-wanted-spare-chevron-right-icon3 {
  width: 16px;
  height: 16px;
  position: absolute;
  margin: 0 !important;
  top: 524px;
  left: 280px;
  overflow: hidden;
  flex-shrink: 0;
}

.admin-detailed-wanted-spare-text97 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  flex-shrink: 0;
  white-space: nowrap;
}

.admin-detailed-wanted-spare-breadcrumb-button-base5 {
  margin: 0 !important;
  position: absolute;
  top: 518px;
  left: 304px;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4px 0 4px 8px;
  box-sizing: border-box;
  font-size: 14px;
}

.admin-detailed-wanted-spare-chevron-right-icon4 {
  width: 16px;
  height: 16px;
  position: absolute;
  margin: 0 !important;
  top: 524px;
  left: 409px;
  overflow: hidden;
  flex-shrink: 0;
}

.admin-detailed-wanted-spare-divider-icon24 {
  width: 100%;
  height: 1px;
  position: absolute;
  margin-top: 200px !important;
  top: 712px;
  left: 0;
}

.admin-detailed-wanted-spare-placeholder-icon17 {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.admin-detailed-wanted-spare-text98 {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.admin-detailed-wanted-spare-button2,
.admin-detailed-wanted-spare-text-padding4,
.admin-detailed-wanted-spare-text-padding5 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-text-padding4 {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  padding: 0 2px;
}

.admin-detailed-wanted-spare-button2,
.admin-detailed-wanted-spare-text-padding5 {
  box-sizing: border-box;
}

.admin-detailed-wanted-spare-button2 {
  height: 40px;
  width: 86px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: 10px 14px;
  gap: 4px;
}

.admin-detailed-wanted-spare-text-padding5 {
  width: 78px;
  height: 20px;
  flex-direction: row;
  padding: 0 2px;
}

.admin-detailed-wanted-spare-button3,
.admin-detailed-wanted-spare-button4 {
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  flex-direction: column;
  padding: 8px 13px;
  gap: 4px;
  min-width: 69px;
}

.admin-detailed-wanted-spare-button3 {
  border: 1px solid #d6bbfb;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: #6941c6;
}

.admin-detailed-wanted-spare-button4 {
  border: 1px solid #d0d5dd;
  color: #344054;
}

.admin-detailed-wanted-spare-button4,
.admin-detailed-wanted-spare-button5,
.admin-detailed-wanted-spare-text-padding7 {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-text-padding7 {
  width: 57px;
  flex: 1;
  flex-direction: row;
  padding: 0 2px;
}

.admin-detailed-wanted-spare-button5 {
  height: 40px;
  width: 85px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  overflow: hidden;
  flex-shrink: 0;
  flex-direction: column;
  padding: 10px 13px;
  gap: 4px;
  color: #fff;
}

.admin-detailed-wanted-spare-actions1,
.admin-detailed-wanted-spare-testiomonial-carousel-arrow8,
.admin-detailed-wanted-spare-testiomonial-carousel-arrow9 {
  position: absolute;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.admin-detailed-wanted-spare-actions1 {
  margin: 0 !important;
  top: 609px;
  right: 158px;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 24px 0 0;
  gap: 12px;
  max-width: 100%;
  font-size: 14px;
}

.admin-detailed-wanted-spare-testiomonial-carousel-arrow8,
.admin-detailed-wanted-spare-testiomonial-carousel-arrow9 {
  border-radius: 9999px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.admin-detailed-wanted-spare-testiomonial-carousel-arrow8 {
  margin: 0 !important;
  bottom: 197px;
  left: -19px;
}

.admin-detailed-wanted-spare-testiomonial-carousel-arrow9 {
  top: 212px;
  left: 1342px;
}

.admin-detailed-wanted-spare-listing-image-grid11,
.admin-detailed-wanted-spare-logo1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.admin-detailed-wanted-spare-listing-image-grid11 {
  align-self: stretch;
  justify-content: flex-end;
  padding: 0 0 138px;
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: 12px;
  color: #fdb022;
}

.admin-detailed-wanted-spare-logo1 {
  width: 121px;
  height: 32px;
  justify-content: flex-start;
  background-image: url("../../../../icons/Gerator/detailed_listing/gerator04-1@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.admin-detailed-wanted-spare-supporting-text44 {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}

.admin-detailed-wanted-spare-logo-and-supporting-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  min-width: 320px;
  max-width: 320px;
}

.admin-detailed-wanted-spare-linkedin-logo-2-icon {
  height: 34px;
  width: 34px;
  position: relative;
  object-fit: cover;
  min-height: 34px;
}

.admin-detailed-wanted-spare-brand-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  min-width: 335px;
  max-width: 100%;
}

.admin-detailed-wanted-spare-heading40,
.admin-detailed-wanted-spare-text123 {
  position: relative;
  font-weight: 600;
}

.admin-detailed-wanted-spare-heading40 {
  align-self: stretch;
  line-height: 20px;
}

.admin-detailed-wanted-spare-text123 {
  line-height: 24px;
  display: inline-block;
  min-width: 62px;
}

.admin-detailed-wanted-spare-buttonsbutton27,
.admin-detailed-wanted-spare-footer-link1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.admin-detailed-wanted-spare-buttonsbutton27 {
  overflow: hidden;
  justify-content: center;
  gap: 8px;
}

.admin-detailed-wanted-spare-footer-link1 {
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-text124,
.admin-detailed-wanted-spare-text125,
.admin-detailed-wanted-spare-text126 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 54px;
}

.admin-detailed-wanted-spare-text125,
.admin-detailed-wanted-spare-text126 {
  min-width: 68px;
}

.admin-detailed-wanted-spare-text126 {
  line-height: 18px;
  font-weight: 500;
  min-width: 26px;
}

.admin-detailed-wanted-spare-badge36,
.admin-detailed-wanted-spare-footer-link3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-badge36 {
  width: 42px;
  border-radius: 9999px;
  border: 1.5px solid #7e56d9;
  box-sizing: border-box;
  padding: 0 6px;
  text-align: center;
  font-size: 12px;
}

.admin-detailed-wanted-spare-footer-link3 {
  gap: 8px;
}

.admin-detailed-wanted-spare-text127,
.admin-detailed-wanted-spare-text128,
.admin-detailed-wanted-spare-text129 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 71px;
}

.admin-detailed-wanted-spare-text128,
.admin-detailed-wanted-spare-text129 {
  min-width: 97px;
}

.admin-detailed-wanted-spare-text129 {
  min-width: 81px;
}

.admin-detailed-wanted-spare-footer-links-column,
.admin-detailed-wanted-spare-footer-links1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-footer-links1 {
  padding: 0 26px 0 0;
  gap: 12px;
  font-size: 16px;
  color: #6941c6;
}

.admin-detailed-wanted-spare-footer-links-column {
  flex: 1;
  gap: 16px;
  min-width: 96px;
}

.admin-detailed-wanted-spare-text130,
.admin-detailed-wanted-spare-text131,
.admin-detailed-wanted-spare-text132,
.admin-detailed-wanted-spare-text133,
.admin-detailed-wanted-spare-text134,
.admin-detailed-wanted-spare-text135 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
}

.admin-detailed-wanted-spare-text130 {
  min-width: 100px;
  white-space: nowrap;
}

.admin-detailed-wanted-spare-text131,
.admin-detailed-wanted-spare-text132,
.admin-detailed-wanted-spare-text133,
.admin-detailed-wanted-spare-text134,
.admin-detailed-wanted-spare-text135 {
  min-width: 58px;
}

.admin-detailed-wanted-spare-text132,
.admin-detailed-wanted-spare-text133,
.admin-detailed-wanted-spare-text134,
.admin-detailed-wanted-spare-text135 {
  min-width: 66px;
}

.admin-detailed-wanted-spare-text133,
.admin-detailed-wanted-spare-text134,
.admin-detailed-wanted-spare-text135 {
  min-width: 90px;
}

.admin-detailed-wanted-spare-text134,
.admin-detailed-wanted-spare-text135 {
  min-width: 55px;
}

.admin-detailed-wanted-spare-text135 {
  min-width: 63px;
}

.admin-detailed-wanted-spare-footer-links2,
.admin-detailed-wanted-spare-links {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-footer-links2 {
  flex-direction: column;
  padding: 0 44px 0 0;
  gap: 12px;
  font-size: 16px;
  color: #6941c6;
}

.admin-detailed-wanted-spare-links {
  width: 320px;
  flex-direction: row;
  gap: 32px;
  min-width: 320px;
  flex-shrink: 0;
}

.admin-detailed-wanted-spare-help-icon,
.admin-detailed-wanted-spare-label6 {
  position: relative;
  display: none;
}

.admin-detailed-wanted-spare-label6 {
  width: 102px;
  line-height: 20px;
  font-weight: 500;
}

.admin-detailed-wanted-spare-help-icon {
  height: 16px;
  width: 16px;
}

.admin-detailed-wanted-spare-input-with-label6,
.admin-detailed-wanted-spare-input4 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-input4 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
  font-size: 16px;
  color: #667085;
}

.admin-detailed-wanted-spare-input-with-label6 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.admin-detailed-wanted-spare-hint-text5 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.admin-detailed-wanted-spare-input-field2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  min-width: 149px;
}

.admin-detailed-wanted-spare-text137 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 79px;
}

.admin-detailed-wanted-spare-buttonsbutton39 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  gap: 6px;
  font-size: 16px;
  color: #fff;
}

.admin-detailed-wanted-spare-email-capture {
  align-self: stretch;
  flex-direction: row;
  gap: 16px;
}

.admin-detailed-wanted-spare-email-capture,
.admin-detailed-wanted-spare-links-and-newsletter,
.admin-detailed-wanted-spare-newsletter {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-newsletter {
  width: 360px;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;
  flex-shrink: 0;
}

.admin-detailed-wanted-spare-links-and-newsletter {
  flex: 1;
  flex-direction: row;
  padding: 0;
  box-sizing: border-box;
  gap: 32px;
  min-width: 640px;
  max-width: 712px;
  margin-left: -2px;
  font-size: 14px;
  color: #101828;
}

.admin-detailed-wanted-spare-content8 {
  width: 1207px;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 2px 0 0;
  box-sizing: border-box;
  max-width: 100%;
}

.admin-detailed-wanted-spare-container,
.admin-detailed-wanted-spare-content8,
.admin-detailed-wanted-spare-footer-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-container {
  flex: 1;
  max-width: 1280px;
}

.admin-detailed-wanted-spare-footer-wrapper {
  align-self: stretch;
  padding: 0 80px;
  box-sizing: border-box;
  max-width: 100%;
  flex-shrink: 0;
}

.admin-detailed-wanted-spare-divider-icon29,
.admin-detailed-wanted-spare-footer {
  align-self: stretch;
  overflow: hidden;
  max-width: 100%;
}

.admin-detailed-wanted-spare-divider-icon29 {
  position: relative;
  max-height: 100%;
  z-index: 3;
}

.admin-detailed-wanted-spare-footer {
  height: 352px;
  background-color: #fff;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 64px 0 48px;
  box-sizing: border-box;
  gap: 48px;
  z-index: 2;
  margin-top: 250px;
}

.admin-detailed-wanted-spare-footer-text {
  position: relative;
  line-height: 20px;
}

.admin-detailed-wanted-spare-brand-rights {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.admin-detailed-wanted-spare-gerator-10-2-icon {
  height: 38px;
  width: 38px;
  position: relative;
  object-fit: cover;
}

.admin-detailed-wanted-spare-logomark,
.admin-detailed-wanted-spare-logomark1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-logomark1 {
  box-shadow: 0 1.3px 3.75px rgba(16, 24, 40, 0.1),
    0 1.3px 2.5px rgba(16, 24, 40, 0.06);
}

.admin-detailed-wanted-spare-logomark {
  filter: drop-shadow(0 1.3px 3.75px rgba(16, 24, 40, 0.1)) drop-shadow(0 1.3px 2.5px rgba(16, 24, 40, 0.06));
}

.admin-detailed-wanted-spare-copyright-brand-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 29px;
}

.admin-detailed-wanted-spare-content10,
.admin-detailed-wanted-spare-logo2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-content10 {
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  gap: 20px;
}

.admin-detailed-wanted-spare-container1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 32px;
  box-sizing: border-box;
  max-width: 1280px;
}

.admin-detailed-wanted-spare-footer1,
.admin-detailed-wanted-spare-listings-detailed-page-devic,
.admin-detailed-wanted-spare-rectangle-parent {
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
}

.admin-detailed-wanted-spare-footer1 {
  align-self: stretch;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  padding: 48px 80px;
  z-index: 1;
}

.admin-detailed-wanted-spare-listings-detailed-page-devic,
.admin-detailed-wanted-spare-rectangle-parent {
  position: relative;
  align-items: flex-start;
}

.admin-detailed-wanted-spare-rectangle-parent {
  flex: 1;
  flex-direction: column;
  max-width: 100%;
}

.admin-detailed-wanted-spare-listings-detailed-page-devic {
  width: 100%;
  overflow: hidden;
  flex-direction: row;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  font-size: 16px;
  color: #475467;
  font-family: Inter;
}


.admin-detailed-wanted-spare-sharethis {
  display: flex;
  position: absolute;
  flex-direction: row;
  width: 400px;
  bottom: 105%;


}

.admin-detailed-wanted-spare-sharethis-parent {
  position: relative;

}

.admin-detailed-wanted-spare-sharethis-follow {
  display: flex;
  position: absolute;
  flex-direction: row;
  width: 600px;
  bottom: 105%;



}

.admin-detailed-wanted-spare-sharethis-follow-parent {
  position: relative;

}


@media screen and (max-width: 1275px) {
  .admin-detailed-wanted-spare-breadcrumb-controls {
    flex: 1;
    min-height: auto;
  }

  .admin-detailed-wanted-spare-breadcrumb-controls1 {
    flex: 1;
  }

  .admin-detailed-wanted-spare-breadcrumb-container {
    flex-wrap: wrap;
  }

  .admin-detailed-wanted-spare-service-card {
    flex: 1;
  }

  .admin-detailed-wanted-spare-listing-content {
    flex-wrap: wrap;
  }

  .admin-detailed-wanted-spare-container,
  .admin-detailed-wanted-spare-container1 {
    max-width: 100%;
  }
}

@media screen and (max-width: 1100px) {

  .admin-detailed-wanted-spare-client-review,
  .admin-detailed-wanted-spare-content4,
  .admin-detailed-wanted-spare-description-columns-first,
  .admin-detailed-wanted-spare-download-items,
  .admin-detailed-wanted-spare-gallery-images,
  .admin-detailed-wanted-spare-rectangle-group,
  .admin-detailed-wanted-spare-third-columns-paragraphs-parent {
    flex-wrap: wrap;
  }

  .admin-detailed-wanted-spare-description-block-parent {
    min-width: 100%;
  }

  .admin-detailed-wanted-spare-client-feedback,
  .admin-detailed-wanted-spare-client-message,
  .admin-detailed-wanted-spare-listing-image-grid,
  .admin-detailed-wanted-spare-listing-image-grid1,
  .admin-detailed-wanted-spare-pagination-wrapper,
  .admin-detailed-wanted-spare-review-form-wrapper {
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }

  .admin-detailed-wanted-spare-heading-parent3 {
    flex-wrap: wrap;
  }

  .admin-detailed-wanted-spare-frame-parent5 {
    justify-content: center;
    grid-template-columns: repeat(2, minmax(242px, 420px));
  }
}

@media screen and (max-width: 750px) {
  .admin-detailed-wanted-spare-nav-search-avatar {
    gap: 19px;
    flex-wrap: wrap;
    min-width: 100%;
  }

  .admin-detailed-wanted-spare-header-container {
    gap: 35px;
    flex-wrap: wrap;
  }

  .admin-detailed-wanted-spare-rectangle-group {
    padding-right: 32px;
    box-sizing: border-box;
  }

  .admin-detailed-wanted-spare-actions,
  .admin-detailed-wanted-spare-file-upload-item-base {
    flex-wrap: wrap;
  }

  .admin-detailed-wanted-spare-empty-review {
    font-size: 45px;
    line-height: 19px;
  }

  .admin-detailed-wanted-spare-heading16 {
    font-size: 29px;
    line-height: 6px;
  }

  .admin-detailed-wanted-spare-review-stars {
    min-width: 100%;
  }

  .admin-detailed-wanted-spare-review-container,
  .admin-detailed-wanted-spare-review-stars {
    flex-wrap: wrap;
  }

  .admin-detailed-wanted-spare-client-review-header {
    min-width: 100%;
  }

  .admin-detailed-wanted-spare-card-content {
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
  }

  .admin-detailed-wanted-spare-service-card {
    min-width: 100%;
  }

  .admin-detailed-wanted-spare-listing-content-wrapper {
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }

  .admin-detailed-wanted-spare-feedback-header {
    min-width: 100%;
  }

  .admin-detailed-wanted-spare-view-replies {
    padding-left: 220px;
    box-sizing: border-box;
  }

  .admin-detailed-wanted-spare-frame-parent3 {
    flex-wrap: wrap;
  }

  .admin-detailed-wanted-spare-textarea-input-field {
    max-width: 100%;
  }

  .admin-detailed-wanted-spare-content6 {
    gap: 16px;
  }

  .admin-detailed-wanted-spare-textarea-input-field1 {
    max-width: 100%;
  }

  .admin-detailed-wanted-spare-review-form {
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
  }

  .admin-detailed-wanted-spare-listing-image-grid4 {
    padding-left: 40px;
    padding-right: 41px;
    box-sizing: border-box;
  }

  .admin-detailed-wanted-spare-listing-image-grid11 {
    padding-bottom: 90px;
    box-sizing: border-box;
  }

  .admin-detailed-wanted-spare-links {
    flex: 1;
  }

  .admin-detailed-wanted-spare-links-and-newsletter {
    gap: 16px;
    flex-wrap: wrap;
    max-width: 100%;
    min-width: 100%;
  }

  .admin-detailed-wanted-spare-container {
    gap: 24px;
  }

  .admin-detailed-wanted-spare-footer-wrapper {
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }

  .admin-detailed-wanted-spare-footer {
    height: auto;
    gap: 24px;
  }

  .admin-detailed-wanted-spare-container1 {
    gap: 16px;
  }

  .admin-detailed-wanted-spare-footer1 {
    gap: 32px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .admin-detailed-wanted-spare-header-container {
    gap: 17px;
  }

  .admin-detailed-wanted-spare-breadcrumb {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .admin-detailed-wanted-spare-description-heading {
    font-size: 16px;
    line-height: 22px;
  }

  .admin-detailed-wanted-spare-button-row,
  .admin-detailed-wanted-spare-contact-details {
    flex-wrap: wrap;
  }

  .admin-detailed-wanted-spare-gallery-images-container,
  .admin-detailed-wanted-spare-gallery-images-container1 {
    padding-top: 102px;
    padding-bottom: 102px;
    box-sizing: border-box;
  }

  .admin-detailed-wanted-spare-content5 {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .admin-detailed-wanted-spare-input-dropdown {
    max-width: 100%;
  }

  .admin-detailed-wanted-spare-empty-review {
    font-size: 34px;
    line-height: 14px;
  }

  .admin-detailed-wanted-spare-heading16 {
    font-size: 22px;
    line-height: 4px;
  }

  .admin-detailed-wanted-spare-rectangle-parent1 {
    flex: 1;
  }

  .admin-detailed-wanted-spare-review-container {
    gap: 16px;
  }

  .admin-detailed-wanted-spare-price {
    font-size: 21px;
    line-height: 26px;
  }

  .admin-detailed-wanted-spare-header-content1,
  .admin-detailed-wanted-spare-professional-details,
  .admin-detailed-wanted-spare-testimonial-item {
    flex-wrap: wrap;
  }

  .admin-detailed-wanted-spare-view-replies {
    padding-left: 20px;
    box-sizing: border-box;
  }

  .admin-detailed-wanted-spare-review-form {
    gap: 25px;
  }

  .admin-detailed-wanted-spare-frame-parent5 {
    grid-template-columns: minmax(242px, 1fr);
  }

  .admin-detailed-wanted-spare-links,
  .admin-detailed-wanted-spare-logo-and-supporting-text {
    gap: 16px;
  }

  .admin-detailed-wanted-spare-email-capture {
    flex-wrap: wrap;
  }

  .admin-detailed-wanted-spare-footer {
    padding-top: 42px;
    padding-bottom: 31px;
    box-sizing: border-box;
  }

  .admin-detailed-wanted-spare-footer1 {
    gap: 16px;
  }
}