.add-form-footer1{
  background-color: #fff;
  overflow: hidden;
  text-align: left;
  color: #475467;
  font-family: Inter;
  align-self: stretch;
  align-items: center;
  padding: 48px 0px;
  z-index: 1;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.add-form-container1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start;
  padding: 0 32px;
  box-sizing: border-box;
  /* max-width: 1280px; */
}

.add-form-content51 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  gap: 20px;
}

.add-form-footer-text {
    position: relative;
    line-height: 20px;
}

.add-form-logo1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.add-form-footer-logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 29px;
}

.add-form-footer-brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.add-form-footer-text {
    position: relative;
    line-height: 20px;
}

.add-form-logomark,
.add-form-logomark1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
/* .add-form-logomark1 {
  box-shadow: 0 1.3px 3.75px rgba(16, 24, 40, 0.1),
    0 1.3px 2.5px rgba(16, 24, 40, 0.06);
}
.add-form-logomark {
  filter: drop-shadow(0 1.3px 3.75px rgba(16, 24, 40, 0.1))
    drop-shadow(0 1.3px 2.5px rgba(16, 24, 40, 0.06));
} */

.add-form-gerator-10-2-icon {
    height: 38px;
    width: 38px;
    position: relative;
    object-fit: cover;
    
}

.add-form-page-footer-divider {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 15px;
  box-sizing: border-box;
  max-width: 100%;
}

.add-form-divider-icon10 {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 2;
}


@media screen and (max-width: 1275px) {
  
    .add-form-container1 {
      max-width: 100%;
    }
}


@media screen and (max-width: 780px) {
    .add-form-footer1 {
        gap: 32px;
        padding-left: 40px;
        padding-right: 40px;
        box-sizing: border-box;
    }
    
    .add-form-container1 {
        gap: 16px;
    }
}


@media screen and (max-width: 450px) {
    .add-form-footer1 {
        gap: 16px;
        
      }    
}