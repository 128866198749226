/* Search input component */
.cursor-pointer {
    cursor: pointer;
}

.dashboard-content3 {
    display: flex;
    align-items: center;
    width: 100%;
}

.dashboard-search-placeholder {
    flex-grow: 1;
    margin: 0 8px;
    border: none;
    outline: none;
    background: transparent;
}

/* Ensure the X icon aligns properly */
.dashboard-content3 .lucide-x {
    flex-shrink: 0;
}

.dashboard-input-focused {
    border: 1px solid #9e77ed; /* Highlight border when focused */
    box-shadow: 0 1px 2px #1018280d, 0 0 0 4px #9e77ed3d; /* Focus shadow */
  }
  
  .dashboard-search-placeholder {
    border: none;
    outline: none; /* Remove default focus outline */
    flex: 1; /* Adjust width dynamically */
  }