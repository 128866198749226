@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

.error-page-gerator-04-1-icon {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}

.error-page-logo,
.error-page-logo-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.error-page-logo {
  align-self: stretch;
  height: 32px;
  flex-direction: row;
  background-image: url(../../../icons/Gerator/error_page/gerator04-1@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.error-page-logo-wrapper {
  width: 121px;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
}

.error-page-placeholder-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.error-page-text {
  text-decoration: none;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 47px;
  white-space: nowrap;
}

.error-page-buttonsbutton,
.error-page-text-padding {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.error-page-buttonsbutton {
  cursor: pointer;
  border: 0;
  padding: 10px 16px;
  background-color: transparent;
  border-radius: 8px;
  overflow: hidden;
  gap: 6px;
}

.error-page-text1 {
  text-decoration: none;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 59px;
  white-space: nowrap;
}

.error-page-buttonsbutton1,
.error-page-navigation-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.error-page-buttonsbutton1 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 15px;
  background-color: #7e56d9;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  justify-content: center;
  gap: 6px;
}

.error-page-navigation-actions {
  width: 190px;
  justify-content: flex-start;
  gap: 12px;
}

.error-page-error-404-page-desktop-inner,
.error-page-frame-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.error-page-frame-parent {
  flex: 1;
  justify-content: space-between;
  gap: 20px;
}

.error-page-error-404-page-desktop-inner {
  width: 1360px;
  justify-content: flex-start;
  padding: 0 32px;
  box-sizing: border-box;
}

.error-page-heading,
.error-page-subheading {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.error-page-heading {
  margin: 0;
  font-size: 60px;
  letter-spacing: -0.02em;
  line-height: 72px;
  font-family: inherit;
  color: #101828;
}

.error-page-heading-and-subheading {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.error-page-we-searched-high {
  margin-block-start: 0;
  margin-block-end: 20px;
}

.error-page-lets-find-a {
  margin: 0;
}

.error-page-supporting-text {
  align-self: stretch;
  height: 60px;
  position: relative;
  font-size: 20px;
  line-height: 30px;
  color: #475467;
  display: inline-block;
}

.error-page-heading-and-supporting-text {
  width: 768px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  max-width: 768px;
}

.error-page-label {
  width: 36px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.error-page-search-lg-icon,
.error-page-text2 {
  position: relative;
  overflow: hidden;
}

.error-page-search-lg-icon {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
}

.error-page-text2 {
  flex: 1;
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.error-page-content2 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  max-width: 100%;
}

.error-page-help-icon {
  height: 16px;
  width: 16px;
  position: relative;
  display: none;
}

.error-page-input,
.error-page-input-with-label {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.error-page-input {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: 10px 13px;
  gap: 8px;
  font-size: 16px;
  color: #667085;
}

.error-page-input-with-label {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.error-page-hint-text {
  width: 320px;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.error-page-input-field {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  min-width: 240px;
  max-width: 100%;
}

.error-page-text3 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 55px;
}

.error-page-buttonsbutton2,
.error-page-search-input {
  display: flex;
  flex-direction: row;
}

.error-page-buttonsbutton2 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 10px 17px;
  background-color: #fff;
  width: 95px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.error-page-search-input {
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16px;
  max-width: 480px;
  text-align: left;
  font-size: 14px;
  color: #344054;
}

.error-page-arrow-left-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.error-page-text4 {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 72px;
}

.error-page-placeholder-icon6 {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.error-page-buttonsbutton3 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 14px 21px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.error-page-text5 {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 78px;
}

.error-page-buttonsbutton4 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 14px 21px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.error-page-actions,
.error-page-container1,
.error-page-content1 {
  display: flex;
  justify-content: flex-start;
}

.error-page-actions {
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
}

.error-page-container1,
.error-page-content1 {
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.error-page-content1 {
  align-self: stretch;
  padding: 0 20px;
  gap: 48px;
  max-width: 100%;
}

.error-page-container1 {
  width: 1280px;
  padding: 0 32px;
  max-width: 1280px;
  text-align: center;
}

.error-page-code-square-02-icon {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.error-page-feature-title,
.error-page-supporting-text1 {
  align-self: stretch;
  position: relative;
}

.error-page-feature-title {
  line-height: 30px;
  font-weight: 600;
}

.error-page-supporting-text1 {
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.error-page-text-and-supporting-text {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.error-page-text6 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 106px;
}

.error-page-buttonsbutton5 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  color: #6941c6;
}

.error-page-content4,
.error-page-feature-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.error-page-content4 {
  align-self: stretch;
  gap: 20px;
}

.error-page-feature-text {
  flex: 1;
  background-color: #f9fafb;
  padding: 24px;
  box-sizing: border-box;
  gap: 48px;
  min-width: 280px;
  max-width: 100%;
}

.error-page-text8 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}

.error-page-container2,
.error-page-content3 {
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
}

.error-page-content3 {
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 4px 0 0;
  gap: 32px 30px;
  max-width: 100%;
}

.error-page-container2 {
  width: 1280px;
  flex-direction: column;
  align-items: center;
  padding: 0 32px;
  max-width: 1280px;
  font-size: 20px;
  color: #101828;
}

.error-page-text11 {
  position: relative;
  line-height: 20px;
}

.error-page-placeholder-icon12 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.error-page-text12 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 51px;
  white-space: nowrap;
}

.error-page-buttonsbutton8,
.error-page-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.error-page-buttonsbutton8 {
  overflow: hidden;
  align-items: center;
  gap: 6px;
  color: #6941c6;
}

.error-page-row {
  align-items: baseline;
  padding: 0 75px;
  gap: 3px;
  font-size: 14px;
  color: #475467;
}

.error-page-container,
.error-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.error-page-content {
  width: 360px;
  padding: 0;
  box-sizing: border-box;
  gap: 32px;
  max-width: 360px;
}

.error-page-container {
  width: 100%;
  max-width: 1280px;
  text-align: left;
  font-size: 16px;
  color: #6941c6;
  font-family: Inter;
}

.error-page-text13 {
  bottom: 32px;
  left: 32px;
  line-height: 20px;
}

.error-page-helpcentergeratorcom,
.error-page-mail-01-icon,
.error-page-text13 {
  position: absolute;
  margin: 0 !important;
  z-index: 1;
}

.error-page-mail-01-icon {
  width: 16px;
  height: 16px;
  right: 204px;
  bottom: 34px;
  overflow: hidden;
  flex-shrink: 0;
}

.error-page-helpcentergeratorcom {
  right: 31.5px;
  bottom: 32px;
  line-height: 20px;
  white-space: nowrap;
}

.error-page-error-404-page-desktop,
.error-page-log-in {
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
}

.error-page-log-in {
  align-self: stretch;
  align-items: center;
  padding: 96px 80px 158px;
  gap: 32px;
  min-height: 960px;
  max-width: 100%;
  text-align: left;
  font-size: 14px;
  color: #475467;
  font-family: Inter;
}

.error-page-error-404-page-desktop {
  width: 100%;
  overflow: hidden;
  align-items: flex-start;
  padding: 18px 0 0;
  /* gap: 18px; */
  line-height: normal;
  letter-spacing: normal;
}

.error-page-error-404-page-desktop-custom {
  padding: 0 !important;
}

@media screen and (max-width: 1275px) {

  .error-page-container,
  .error-page-container1,
  .error-page-container2 {
    max-width: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .error-page-log-in {
    padding-top: 62px;
    padding-bottom: 103px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 750px) {
  .error-page-heading {
    font-size: 48px;
    line-height: 58px;
  }

  .error-page-heading-and-supporting-text,
  .error-page-search-input {
    max-width: 100%;
  }

  .error-page-container1 {
    gap: 16px;
  }

  .error-page-container2 {
    gap: 48px;
  }

  .error-page-content,
  .error-page-log-in {
    gap: 16px;
  }

  .error-page-log-in {
    padding: 40px 40px 67px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .error-page-heading {
    font-size: 36px;
    line-height: 43px;
  }

  .error-page-supporting-text {
    font-size: 16px;
    line-height: 24px;
  }

  .error-page-actions {
    flex-wrap: wrap;
  }

  .error-page-content1 {
    gap: 24px;
  }

  .error-page-feature-title {
    font-size: 16px;
    line-height: 24px;
  }

  .error-page-container2,
  .error-page-feature-text {
    gap: 24px;
  }

  .error-page-row {
    flex-wrap: wrap;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }

  .error-page-container {
    gap: 16px;
  }
}