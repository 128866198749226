/* Custom CSS */
/* .sales-iq-sales-iq-frame-layout-custom {
  margin-top: 0px !important;
} */

.sales-iq-buttonsbutton-wrapper1-child {
    outline: none;
    border: none;
    background-color: #fff;
    padding: 0;
  }
  
  
  .sales-iq-main-custom {
    width: 100% !important;
  }
  
  .sales-iq-search-lg-icon-custom {
    cursor: pointer;
  }
  
  .sales-iq-sales-iq-frame-layout-child {
    position: relative;
    background-color: #fff;
    display: none;
  }
  
  /* .sales-iq-sales-iq-frame-layout-child {
    align-self: stretch;
    height: 8320px;
    margin-top: 200px;
  } */
  
  
  
  
  
  
  
  
  .sales-iq-text {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    min-width: 30px;
  }
  
  .sales-iq-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
  }
  
  
  .sales-iq-chevron-down-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  
  
  .sales-iq-badge1,
  .sales-iq-nav-item-base1 {
    background-color: #f9fafb;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  
  
  .sales-iq-navigation,
  .sales-iq-search-input {
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-search-input {
    flex-direction: column;
    align-items: flex-start;
    padding: 2px 0 0;
  }
  
  .sales-iq-label {
    width: 47px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: none;
  }
  
  .sales-iq-search-lg-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .sales-iq-search-placeholder {
    flex: 1;
    position: relative;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 8px;
    outline: none;
    border: none;
    font-size: 16px;
  }
  
  .sales-iq-content3,
  .sales-iq-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .sales-iq-content3 {
    flex: 1;
    gap: 8px;
  }
  
  
  
  
  
  
  
  
  .sales-iq-divider-icon,
  .sales-iq-home-line-icon {
    position: relative;
    overflow: hidden;
  }
  
  .sales-iq-divider-icon {
    align-self: stretch;
    max-width: 100%;
    max-height: 100%;
    z-index: 1;
  }
  
  
  
  .sales-iq-contrast-border1 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 9999px;
    border: 0.8px solid rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    overflow: hidden;
  }
  
  
  
  
  
  .sales-iq-arrow-narrow-left-icon {
    height: 24px;
    width: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 24px;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  .sales-iq-content12,
  .sales-iq-navigation4 {
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-content12 {
    flex: 1;
    flex-direction: row;
    align-items: center;
    padding: 0 142px 0 0;
    gap: 12px;
  }
  
  .sales-iq-content5,
  .sales-iq-navigation4 {
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .sales-iq-content5 {
    padding: 36px 16px 0;
    gap: 16px;
  }
  
  .sales-iq-supporting-text,
  .sales-iq-title {
    align-self: stretch;
    position: relative;
    line-height: 20px;
  }
  
  .sales-iq-title {
    font-weight: 600;
  }
  
  .sales-iq-supporting-text {
    color: #475467;
    white-space: nowrap;
  }
  
  
  .sales-iq-breadcrumb-button-base {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-breadcrumb-button-base {
    border-radius: 6px;
    padding: 4px;
  }
  
  .sales-iq-chevron-right-icon {
    height: 16px;
    width: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .sales-iq-location {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 74px;
  }
  
  .sales-iq-breadcrumb-button-base1 {
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
  }
  
  .sales-iq-text20 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  
  .sales-iq-breadcrumb-button-base2 {
    align-self: stretch;
    border-radius: 6px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 7px;
  }
  
  .sales-iq-chevron-right-icon2 {
    height: 16px;
    width: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  
  .sales-iq-text21 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    white-space: nowrap;
  }
  
  .sales-iq-breadcrumb-button-base3 {
    align-self: stretch;
    border-radius: 6px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
  }
  
  .sales-iq-text22 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 65px;
  }
  
  .sales-iq-breadcrumb-button-base4 {
    border-radius: 6px;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    color: #344054;
  }
  
  .sales-iq-tabs {
    gap: 8px;
  }
  
  .sales-iq-breadcrumbs,
  .sales-iq-tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .sales-iq-heading,
  .sales-iq-supporting-text1 {
    align-self: stretch;
    position: relative;
  }
  
  .sales-iq-heading {
    line-height: 38px;
    font-weight: 600;
  }
  
  .sales-iq-supporting-text1 {
    font-size: 16px;
    line-height: 24px;
    color: #475467;
  }
  
  .sales-iq-text-and-supporting-text {
    width: 678px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    min-width: 320px;
    max-width: 100%;
    flex-shrink: 0;
    font-size: 30px;
    color: #101828;
  }
  
  .sales-iq-placeholder-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
    min-height: 20px;
  }
  
  .sales-iq-text-padding {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  
  .sales-iq-button,
  .sales-iq-button1,
  .sales-iq-button2 {
    height: 40px;
    border-radius: 8px;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: center;
    gap: 4px;
  }
  
  .sales-iq-button {
    flex-direction: row;
    align-items: center;
    padding: 10px 14px;
  }
  
  .sales-iq-button1,
  .sales-iq-button2 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    background-color: #fff;
    padding: 10px 13px;
  }
  
  .sales-iq-button1 {
    border: 1px solid #d6bbfb;
    flex-direction: row;
    align-items: center;
    color: #6941c6;
  }
  
  .sales-iq-button2 {
    border: 1px solid #d0d5dd;
    color: #344054;
  }
  
  .sales-iq-actions,
  .sales-iq-button2,
  .sales-iq-button3 {
    flex-direction: row;
    align-items: center;
  }
  
  .sales-iq-button3 {
    height: 40px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #7e56d9;
    border: 1px solid #7e56d9;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding: 10px 13px;
    gap: 4px;
    color: #fff;
  }
  
  .sales-iq-actions {
    display: none;
    justify-content: flex-start;
    gap: 12px;
    max-width: 100%;
  }
  
  .sales-iq-hint-text {
    width: 320px;
    position: relative;
    line-height: 20px;
    display: none;
  }
  
  .sales-iq-button4,
  .sales-iq-button5 {
    align-self: stretch;
    border-radius: 8px;
    overflow: hidden;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 14px;
    gap: 4px;
  }
  
  .sales-iq-button5 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    background-color: #fff;
    border: 1px solid #d6bbfb;
    padding: 10px 13px;
    color: #6941c6;
  }
  
  .sales-iq-upload-04-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 20px;
  }
  
  .sales-iq-text29 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 45px;
  }
  
  .sales-iq-button6,
  .sales-iq-text-padding6 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  
  .sales-iq-button6 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    padding: 8px 13px;
    gap: 4px;
    color: #344054;
  }
  
  .sales-iq-text30 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 73px;
  }
  
  .sales-iq-button7 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #7e56d9;
    border: 1px solid #7e56d9;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 13px;
    gap: 4px;
    color: #fff;
  }
  
  .sales-iq-content13,
  .sales-iq-input-dropdown {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-input-dropdown {
    width: 320px;
    flex-direction: column;
    gap: 8px;
    min-width: 200px;
    max-width: 320px;
    flex-shrink: 0;
    position: relative;
  }
  
  .sales-iq-content13 {
    flex-direction: row;
    padding: 0;
    box-sizing: border-box;
    gap: 70px;
  }
  
  .sales-iq-content13,
  .sales-iq-divider-icon1,
  .sales-iq-page-header {
    align-self: stretch;
    max-width: 100%;
  }
  
  .sales-iq-divider-icon1 {
    position: relative;
    overflow: hidden;
    max-height: 100%;
    display: none;
  }
  
  .sales-iq-page-header {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }
  
  .sales-iq-text31 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 71px;
  }
  
  .sales-iq-button-group-base {
    background-color: #f9fafb;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
    white-space: nowrap;
  }
  
  .sales-iq-text32 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 55px;
  }
  
  .sales-iq-button-group-base1,
  .sales-iq-button-group-base2 {
    width: 87px;
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
    white-space: nowrap;
  }
  
  .sales-iq-button-group-base2 {
    width: 77px;
  }
  
  .sales-iq-text34 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
  }
  
  .sales-iq-button-group-base3,
  .sales-iq-button-group-base4,
  .sales-iq-button-group-base5,
  .sales-iq-button-group-base6,
  .sales-iq-button-group-base7 {
    align-self: stretch;
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    min-height: 40px;
    z-index: 5;
  }
  
  .sales-iq-button-group-base4,
  .sales-iq-button-group-base5,
  .sales-iq-button-group-base6,
  .sales-iq-button-group-base7 {
    z-index: 4;
  }
  
  .sales-iq-button-group-base5,
  .sales-iq-button-group-base6,
  .sales-iq-button-group-base7 {
    z-index: 3;
  }
  
  .sales-iq-button-group-base6,
  .sales-iq-button-group-base7 {
    z-index: 2;
  }
  
  .sales-iq-button-group-base7 {
    z-index: 1;
  }
  
  .sales-iq-text39 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 61px;
  }
  
  .sales-iq-button-group,
  .sales-iq-button-group-base8 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
  }
  
  .sales-iq-button-group-base8 {
    width: 93px;
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
    white-space: nowrap;
  }
  
  .sales-iq-button-group {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    overflow-x: auto;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-filter-date {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 85px;
  }
  
  .sales-iq-buttonsbutton1 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 13px;
    gap: 4px;
    cursor: pointer;
    text-wrap: nowrap;
  }
  
  .sales-iq-date-picker-dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-filter-options {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 43px;
  }
  
  .sales-iq-actions2,
  .sales-iq-buttonsbutton2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .sales-iq-buttonsbutton2 {
    width: 99px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
    padding: 8px 13px;
    gap: 4px;
    color: #344054;
  }
  
  .sales-iq-actions2 {
    justify-content: flex-start;
    gap: 12px;
    color: #667085;
  }
  
  .sales-iq-container,
  .sales-iq-header-section,
  .sales-iq-tabs-and-filters {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-tabs-and-filters {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    color: #344054;
  }
  
  .sales-iq-container,
  .sales-iq-header-section {
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .sales-iq-container {
    padding: 0 32px;
    box-sizing: border-box;
    gap: 24px;
  }
  
  .sales-iq-header-section {
    flex-shrink: 0;
  }
  
  .sales-iq-color {
    width: 8px;
    height: 8px;
    position: relative;
    border-radius: 50%;
    background-color: #7e56d9;
  }
  
  .sales-iq-legend-color {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px 0 0;
  }
  
  .sales-iq-series-1 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 35px;
  }
  
  .sales-iq-legend-series {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  
  .sales-iq-color1 {
    width: 8px;
    height: 8px;
    position: relative;
    border-radius: 50%;
    background-color: #b692f6;
  }
  
  .sales-iq-series-2 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 33px;
  }
  
  .sales-iq-color2,
  .sales-iq-color3 {
    width: 8px;
    height: 8px;
    position: relative;
    border-radius: 50%;
    background-color: #6941c6;
  }
  
  .sales-iq-color3 {
    background-color: #17b26a;
  }
  
  .sales-iq-series-4 {
    height: 20px;
    position: relative;
    line-height: 20px;
    display: inline-block;
  }
  
  .sales-iq-legend-series3 {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
  }
  
  .sales-iq-color4 {
    height: 8px;
    width: 8px;
    position: relative;
    border-radius: 50%;
    background-color: #6172f3;
  }
  
  .sales-iq-series-5 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
  }
  
  .sales-iq-legend-series4 {
    align-self: stretch;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
  }
  
  .sales-iq-color5 {
    height: 8px;
    width: 8px;
    position: relative;
    border-radius: 50%;
    background-color: #ee46bb;
  }
  
  .sales-iq-legend {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
  }
  
  .sales-iq-divider,
  .sales-iq-y-axis-line,
  .sales-iq-y-axis-line1 {
    align-self: stretch;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .sales-iq-divider {
    flex: 1;
    position: relative;
    border-top: 1px solid #f2f4f7;
  }
  
  .sales-iq-y-axis-line,
  .sales-iq-y-axis-line1 {
    height: 22px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 11px 0 10px;
  }
  
  .sales-iq-y-axis-line1 {
    height: 17px;
    padding: 8.5px 0 7.5px;
  }
  
  .sales-iq-divider6 {
    height: 1px;
    width: 1217px;
    position: relative;
    border-top: 1px solid #f2f4f7;
    box-sizing: border-box;
    max-width: 121%;
    flex-shrink: 0;
  }
  
  .sales-iq-y-axis,
  .sales-iq-y-axis-line6 {
    align-self: stretch;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-y-axis-line6 {
    height: 17px;
    display: none;
    flex-direction: row;
    align-items: center;
  }
  
  .sales-iq-y-axis {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 14.2px;
  }
  
  .sales-iq-jan,
  .sales-iq-jul,
  .sales-iq-jun,
  .sales-iq-mar,
  .sales-iq-may {
    position: relative;
    line-height: 18px;
    display: inline-block;
    min-width: 21px;
  }
  
  .sales-iq-jul,
  .sales-iq-jun,
  .sales-iq-mar,
  .sales-iq-may {
    min-width: 23px;
  }
  
  .sales-iq-jul,
  .sales-iq-jun,
  .sales-iq-may {
    min-width: 25px;
  }
  
  .sales-iq-jul,
  .sales-iq-jun {
    min-width: 22px;
  }
  
  .sales-iq-jul {
    min-width: 17px;
  }
  
  .sales-iq-x-axis {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0 24px;
    gap: 20px;
    font-size: 12px;
  }
  
  .sales-iq-chart-data-icon {
    width: 100%;
    height: 170px;
    position: absolute;
    margin: 0 !important;
    top: 20px;
    right: 0;
    left: 0;
    max-width: 100%;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
    z-index: 1;
  }
  
  .sales-iq-content14 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    position: relative;
    max-width: 100%;
  }
  
  .sales-iq-line-and-bar-chart,
  .sales-iq-line-and-bar-chart-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-line-and-bar-chart {
    flex: 1;
  }
  
  .sales-iq-line-and-bar-chart-wrapper {
    align-self: stretch;
    padding: 0 32px;
    box-sizing: border-box;
    flex-shrink: 0;
  }
  
  .sales-iq-heading1,
  .sales-iq-number {
    align-self: stretch;
    position: relative;
    line-height: 24px;
    font-weight: 600;
  }
  
  .sales-iq-number {
    line-height: 38px;
    white-space: nowrap;
  }
  
  .sales-iq-change1 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 29px;
  }
  
  .sales-iq-change {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .sales-iq-change-duration {
    flex: 1;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    color: #475467;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .sales-iq-change-and-text,
  .sales-iq-number-and-badge {
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-change-and-text {
    align-self: stretch;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #079455;
  }
  
  .sales-iq-number-and-badge {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    min-width: 84px;
  }
  
  .sales-iq-chart-mini-icon {
    height: 64px;
    width: 128px;
    position: relative;
    object-fit: contain;
  }
  
  .sales-iq-number-and-chart {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 16px;
    font-size: 30px;
  }
  
  .sales-iq-dots-vertical-icon {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .sales-iq-dropdown,
  .sales-iq-metric-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-dropdown {
    margin: 0 !important;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  .sales-iq-metric-item {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    padding: 20px 20px 24px 24px;
    position: relative;
    gap: 24px;
    min-width: 320px;
    max-width: 100%;
  }
  
  .sales-iq-change2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1px;
  }
  
  .sales-iq-change-and-text1,
  .sales-iq-container1,
  .sales-iq-metric-group,
  .sales-iq-section {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-change-and-text1 {
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #da2d20;
  }
  
  .sales-iq-container1,
  .sales-iq-metric-group,
  .sales-iq-section {
    align-items: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-metric-group {
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 0 4px 0 0;
    box-sizing: border-box;
    gap: 24px 22px;
  }
  
  .sales-iq-container1,
  .sales-iq-section {
    flex-direction: column;
  }
  
  .sales-iq-container1 {
    padding: 0 32px;
    box-sizing: border-box;
  }
  
  .sales-iq-section {
    flex-shrink: 0;
    font-size: 16px;
    color: #101828;
  }
  
  .sales-iq-separator,
  .sales-iq-separator1 {
    width: 1px;
    height: 29px;
    position: relative;
    border-right: 1px solid #000;
    box-sizing: border-box;
    flex-shrink: 0;
  }
  
  .sales-iq-separator1 {
    height: 43px;
  }
  
  .sales-iq-main,
  .sales-iq-main-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .sales-iq-main {
    height: 692px;
    flex: 1;
    background-color: #fff;
    overflow: hidden;
    flex-direction: column;
    padding: 32px 0;
    gap: 32px;
    z-index: 1;
  }
  
  .sales-iq-main-wrapper {
    align-self: stretch;
    flex-direction: column;
    padding: 0 0 4px;
    color: #475467;
  }
  
  .sales-iq-text42 {
    align-self: stretch;
    flex: 1;
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  
  .sales-iq-breadcrumb-button-base6,
  .sales-iq-breadcrumb-button-base7 {
    align-self: stretch;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .sales-iq-breadcrumb-button-base6 {
    flex: 1;
    padding: 4px 8px;
  }
  
  .sales-iq-breadcrumb-button-base7 {
    padding: 4px 7px;
  }
  
  .sales-iq-breadcrumbs1,
  .sales-iq-tabs1 {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .sales-iq-tabs1 {
    height: 28px;
    flex: 1;
    display: flex;
    gap: 8px;
  }
  
  .sales-iq-breadcrumbs1 {
    width: 278px;
    display: none;
  }
  
  .sales-iq-text46 {
    align-self: stretch;
    position: relative;
    line-height: 38px;
    font-weight: 600;
  }
  
  .sales-iq-supporting-text2 {
    width: 445px;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    display: none;
    max-width: 100%;
  }
  
  .sales-iq-text-and-supporting-text1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    min-width: 320px;
    max-width: 100%;
  }
  
  .sales-iq-text47 {
    align-self: stretch;
    flex: 1;
    position: relative;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .sales-iq-content16 {
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  
  .sales-iq-content16,
  .sales-iq-input-with-label1,
  .sales-iq-input1 {
    align-self: stretch;
    flex: 1;
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-input1 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    padding: 10px 13px;
    font-size: 16px;
    color: #667085;
  }
  
  .sales-iq-input-with-label1 {
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }
  
  .sales-iq-hint-text1 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    color: #475467;
    display: none;
  }
  
  .sales-iq-input-dropdown1 {
    height: 44px;
    width: 320px;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    min-width: 200px;
    max-width: 320px;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-content15 {
    align-self: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 20px 16px;
    font-size: 26px;
    color: #101828;
  }
  
  .sales-iq-content15,
  .sales-iq-page-header-wrapper,
  .sales-iq-page-header1 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-page-header1 {
    flex: 1;
    background-color: #fff;
    flex-direction: column;
    gap: 20px;
    z-index: 1;
  }
  
  .sales-iq-page-header-wrapper {
    align-self: stretch;
    flex-direction: row;
    padding: 0 32px 4px;
    box-sizing: border-box;
    color: #475467;
  }
  
  .sales-iq-supporting-text3 {
    flex: 1;
    position: relative;
    line-height: 24px;
    display: inline-block;
    max-width: 100%;
    z-index: 1;
  }
  
  .sales-iq-supporting-text-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 32px 20px;
    box-sizing: border-box;
    max-width: 100%;
    font-size: 16px;
    color: #475467;
  }
  
  .sales-iq-tab-name {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 55px;
    white-space: nowrap;
  }
  
  .sales-iq-tab-button-base {
    border-bottom: 2px solid #7e56d9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 4px 9px;
    color: #6941c6;
  }
  
  .sales-iq-text48 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 48px;
    white-space: nowrap;
  }
  
  .sales-iq-tab-button-base1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 4px 12px;
  }
  
  .sales-iq-text49,
  .sales-iq-text50,
  .sales-iq-text51 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 52px;
    white-space: nowrap;
  }
  
  .sales-iq-text50,
  .sales-iq-text51 {
    min-width: 62px;
  }
  
  .sales-iq-text51 {
    min-width: 34px;
  }
  
  .sales-iq-tab-button-base4 {
    width: 41px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 3px 12px;
    box-sizing: border-box;
  }
  
  .sales-iq-text52 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 600;
    white-space: nowrap;
  }
  
  .sales-iq-tab-button-base5 {
    align-self: stretch;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 4px 12px;
  }
  
  .sales-iq-text55 {
    align-self: stretch;
    position: relative;
    line-height: 18px;
    font-weight: 500;
    white-space: nowrap;
  }
  
  .sales-iq-badge10 {
    height: 22px;
    border-radius: 9999px;
    background-color: #f9fafb;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 7px;
    text-align: center;
    font-size: 12px;
    color: #344054;
  }
  
  .sales-iq-tab-button-base7,
  .sales-iq-tab-button-base9 {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .sales-iq-tab-button-base7 {
    height: 32px;
    padding: 0 4px 12px;
    box-sizing: border-box;
    gap: 8px;
  }
  
  .sales-iq-tab-button-base9 {
    align-self: stretch;
    padding: 0 3px 12px;
  }
  
  .sales-iq-tabs2 {
    overflow-x: auto;
    flex-direction: row;
    padding: 0 20px 0 0;
    gap: 12px;
  }
  
  .sales-iq-horizontal-tabs,
  .sales-iq-horizontal-tabs-wrapper,
  .sales-iq-tabs2 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .sales-iq-horizontal-tabs {
    flex: 1;
    background-color: #fff;
    border-bottom: 1px solid #eaecf0;
    flex-direction: column;
    z-index: 1;
  }
  
  .sales-iq-horizontal-tabs-wrapper {
    align-self: stretch;
    flex-direction: row;
    padding: 0 0 24px 32px;
    color: #667085;
  }
  
  .sales-iq-frame-item {
    width: 188px;
    height: 154px;
    position: relative;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: none;
  }
  
  .sales-iq-heading4 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    z-index: 1;
  }
  
  .sales-iq-metric-card-title {
    flex: 1;
    flex-direction: column;
    padding: 4px 0 0;
  }
  
  .sales-iq-dropdown3,
  .sales-iq-metric-card,
  .sales-iq-metric-card-title {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-dropdown3 {
    flex-direction: column;
    z-index: 1;
    position: relative;
  }
  
  .sales-iq-metric-card {
    align-self: stretch;
    flex-direction: row;
  }
  
  .sales-iq-number3 {
    width: 155px;
    position: relative;
    font-size: 36px;
    letter-spacing: -0.02em;
    line-height: 44px;
    font-weight: 600;
    color: #101828;
    display: inline-block;
    z-index: 1;
  }
  
  .sales-iq-metric-card-row {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  
  .sales-iq-arrow-up-icon2 {
    width: 12px;
    height: 12px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .sales-iq-options-icon {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px 0 0;
  }
  
  .sales-iq-text58 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 116px;
  }
  
  .sales-iq-badge11 {
    width: 150px;
    border-radius: 9999px;
    border: 1.5px solid #079455;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px 0 8px;
    gap: 4px;
    z-index: 1;
    text-align: center;
    color: #067647;
  }
  
  .sales-iq-frame-inner,
  .sales-iq-rectangle-group {
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
  }
  
  .sales-iq-rectangle-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 4px 22px 15px;
    gap: 8px;
    min-width: 184px;
    max-width: 188px;
  }
  
  .sales-iq-frame-inner {
    height: 154px;
    width: 188px;
    position: relative;
    display: none;
  }
  
  .sales-iq-heading5,
  .sales-iq-number4 {
    position: relative;
    z-index: 1;
  }
  
  .sales-iq-heading5 {
    width: 147px;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
  }
  
  .sales-iq-number4 {
    align-self: stretch;
    font-size: 36px;
    letter-spacing: -0.02em;
    line-height: 44px;
    font-weight: 600;
    color: #101828;
  }
  
  .sales-iq-change-amount {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 32px;
  }
  
  .sales-iq-badge12 {
    width: 64px;
    border-radius: 9999px;
    border: 1.5px solid #079455;
    box-sizing: border-box;
    flex-direction: row;
    padding: 0 7px 0 8px;
    gap: 2px;
    z-index: 1;
    text-align: center;
    color: #067647;
  }
  
  .sales-iq-badge12,
  .sales-iq-frame-container,
  .sales-iq-heading-parent,
  .sales-iq-rectangle-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-heading-parent {
    width: 157px;
    flex-direction: column;
    gap: 8px;
  }
  
  .sales-iq-frame-container,
  .sales-iq-rectangle-container {
    box-sizing: border-box;
  }
  
  .sales-iq-frame-container {
    width: 147px;
    flex-direction: column;
    padding: 4px 0 0;
  }
  
  .sales-iq-rectangle-container {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    flex-direction: row;
    padding: 20px 4px 22px 15px;
    min-width: 184px;
    max-width: 188px;
  }
  
  .sales-iq-heading-group {
    width: 153px;
    gap: 8px;
  }
  
  .sales-iq-dropdown7,
  .sales-iq-heading-container,
  .sales-iq-heading-group,
  .sales-iq-heading-parent1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-heading-container {
    width: 163px;
    gap: 8px;
  }
  
  .sales-iq-dropdown7,
  .sales-iq-heading-parent1 {
    flex-shrink: 0;
  }
  
  .sales-iq-heading-parent1 {
    width: 170px;
    gap: 8px;
  }
  
  .sales-iq-dropdown7 {
    z-index: 1;
  }
  
  .sales-iq-frame-group,
  .sales-iq-frame-wrapper,
  .sales-iq-rectangle-parent2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-rectangle-parent2 {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    justify-content: flex-start;
    padding: 20px 2px 22px 16px;
    min-width: 184px;
    max-width: 188px;
  }
  
  .sales-iq-frame-group,
  .sales-iq-frame-wrapper {
    max-width: 100%;
  }
  
  .sales-iq-frame-group {
    flex: 1;
    justify-content: flex-start;
    gap: 19px;
    z-index: 1;
  }
  
  .sales-iq-frame-wrapper {
    align-self: stretch;
    justify-content: flex-start;
    padding: 0 30px 24px 30px;
    box-sizing: border-box;
    color: #475467;
  }
  
  .sales-iq-text62,
  .sales-iq-text63 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 51px;
  }
  
  .sales-iq-text63 {
    min-width: 74px;
  }
  
  .sales-iq-buttonsbutton4,
  .sales-iq-text-padding11 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 1px;
  }
  
  .sales-iq-buttonsbutton4 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    padding: 8px 13px;
    gap: 4px;
    min-width: 84px;
    cursor: pointer;
  }
  
  .sales-iq-text64 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 78px;
  }
  
  .sales-iq-buttonsbutton5 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px 13px;
    gap: 4px;
    min-width: 87px;
    cursor: pointer;
  }
  
  .sales-iq-text65 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 50px;
  }
  
  .sales-iq-dropdowns,
  .sales-iq-input2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  
  .sales-iq-dropdowns {
    /* flex: 0.7552; */
    flex: .5;
    align-items: flex-start;
    /* padding: 0 166px 0 0; */
    box-sizing: border-box;
    gap: 12px;
    max-width: 100%;
  }
  
  .sales-iq-input2 {
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    align-items: center;
    padding: 8px 13px;
    font-size: 16px;
    color: #667085;
    min-width: 208px;
    max-width: 320px;
  }
  
  .sales-iq-input-dropdown2,
  .sales-iq-input-with-label2 {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    /* flex: .85; */
    justify-content: flex-start;
  }
  
  .sales-iq-input-with-label2 {
    align-self: stretch;
    gap: 6px;
    flex: .65;
  }
  
  .sales-iq-input-dropdown2 {
    flex: .5;
    flex-direction: row !important;
    justify-content: flex-end !important;
    gap: 8px;
    /* min-width: 208px;
    max-width: 320px; */
  }
  
  .sales-iq-content17 {
    flex: 1;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }
  
  .sales-iq-content17,
  .sales-iq-filters-bar,
  .sales-iq-section1 {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-filters-bar {
    align-self: stretch;
    border-radius: 8px;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-section1 {
    flex: 1;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }
  
  .sales-iq-frame-child3,
  .sales-iq-section-wrapper {
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .sales-iq-section-wrapper {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 24px;
    color: #344054;
    position: relative;
  }
  
  .sales-iq-frame-child3 {
    width: 1016px;
    height: 282px;
    position: relative;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    display: none;
  }
  
  .sales-iq-content-elements-icon {
    height: 148px;
    width: 148px;
    position: relative;
    border-radius: 12px;
    object-fit: cover;
    z-index: 1;
  }
  
  .sales-iq-search-input-icons {
    position: relative;
    line-height: 28px;
    font-weight: 600;
    z-index: 1;
  }
  
  .sales-iq-search-inputs {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 0 1px;
  }
  
  .sales-iq-dot-icon10 {
    height: 8px;
    width: 8px;
    position: relative;
    display: none;
  }
  
  .sales-iq-badge-number {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 66px;
  }
  
  .sales-iq-badge16 {
    width: 84px;
    border-radius: 9999px;
    background-color: #edfbf5;
    border: 1px solid #41d992;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 8px;
    gap: 6px;
    mix-blend-mode: normal;
    z-index: 1;
    text-align: center;
    font-size: 14px;
    color: #17b26a;
  }
  
  .sales-iq-supporting-text4 {
    position: relative;
    line-height: 24px;
    display: inline-block;
    min-width: 83px;
    z-index: 1;
  }
  
  .sales-iq-marker-pin-02-icon {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
  }
  
  .sales-iq-location1 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 124px;
    z-index: 1;
  }
  
  .sales-iq-location-pin-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 7px;
  }
  
  .sales-iq-type-of-work {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 109px;
    z-index: 1;
  }
  
  .sales-iq-location-badge,
  .sales-iq-location-badge-number,
  .sales-iq-type-number {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-location-badge-number {
    flex: 1;
    gap: 20px;
    max-width: 100%;
  }
  
  .sales-iq-location-badge,
  .sales-iq-type-number {
    box-sizing: border-box;
  }
  
  .sales-iq-location-badge {
    align-self: stretch;
    padding: 0 0 0 1px;
    max-width: 100%;
  }
  
  .sales-iq-type-number {
    width: 50px;
    border-radius: 8px;
    background-color: #fdb022;
    padding: 1px 4px;
    z-index: 1;
  }
  
  .sales-iq-supporting-text5 {
    position: relative;
    line-height: 24px;
    display: inline-block;
    min-width: 117px;
    z-index: 1;
  }
  
  .sales-iq-type-support {
    flex-direction: column;
    padding: 1px 0 0;
    color: #475467;
  }
  
  .sales-iq-location-elements,
  .sales-iq-type-badge-elements,
  .sales-iq-type-support {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-type-badge-elements {
    flex-direction: row;
    gap: 8px;
    color: #fff;
  }
  
  .sales-iq-location-elements {
    align-self: stretch;
    flex-direction: column;
    gap: 15px;
    max-width: 100%;
  }
  
  .sales-iq-card-badge-number {
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  
  .sales-iq-badge17 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    min-width: 112px;
    white-space: nowrap;
    z-index: 1;
  }
  
  .sales-iq-badge-number-element {
    width: 61px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 61px;
  }
  
  .sales-iq-badge18 {
    width: 77px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 1;
  }
  
  .sales-iq-second-badge-count {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 60px;
  }
  
  .sales-iq-badge19 {
    width: 76px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 1;
  }
  
  .sales-iq-third-badge-number {
    width: 149px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
  }
  
  .sales-iq-badge20 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    min-width: 107px;
    white-space: nowrap;
    z-index: 1;
  }
  
  .sales-iq-badge-elements {
    flex: 1;
    flex-direction: row;
    gap: 8px;
  }
  
  .sales-iq-badge-elements,
  .sales-iq-badge-elements-wrapper,
  .sales-iq-card-badge-elements {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-badge-elements-wrapper {
    align-self: stretch;
    flex-direction: row;
    padding: 0 1px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-card-badge-elements {
    flex: 1;
    flex-direction: column;
    gap: 15px;
  }
  
  .sales-iq-location-badge-elements {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 77px;
  }
  
  .sales-iq-badge21 {
    width: 93px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 1;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-action-fields,
  .sales-iq-card-elements,
  .sales-iq-input-elements {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-card-elements {
    width: 616px;
    flex-direction: row;
    align-items: flex-end;
    gap: 7px;
    font-size: 16px;
    color: #475467;
  }
  
  .sales-iq-action-fields,
  .sales-iq-input-elements {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .sales-iq-input-elements {
    align-self: stretch;
    gap: 16px;
  }
  
  .sales-iq-action-fields {
    flex: 1;
    padding: 10px 0 0;
    box-sizing: border-box;
  }
  
  .sales-iq-price {
    align-self: stretch;
    position: relative;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    white-space: nowrap;
    z-index: 1;
  }
  
  .sales-iq-dropdown-options {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 83px;
    z-index: 2;
  }
  
  .sales-iq-magnifier-1-icon {
    height: 20px;
    width: 20px;
    position: relative;
    object-fit: cover;
    min-height: 20px;
  }
  
  .sales-iq-frame-wrapper3,
  .sales-iq-radar-1-1-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-radar-1-1-parent {
    flex: 1;
    gap: 8px;
    z-index: 1;
  }
  
  .sales-iq-frame-wrapper3 {
    align-self: stretch;
    padding: 0 4px 0 3px;
  }
  
  .sales-iq-dropdown-options-parent {
    flex: 1;
    flex-direction: column;
    gap: 4px;
  }
  
  .sales-iq-dropdown-elements,
  .sales-iq-dropdown-options-parent,
  .sales-iq-price-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-dropdown-elements {
    align-self: stretch;
    flex-direction: row;
    padding: 0 30px;
    font-size: 12px;
    color: #475467;
  }
  
  .sales-iq-price-parent {
    flex: 1;
    flex-direction: column;
  }
  
  .sales-iq-filter-elements-inner {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 10px 0 0;
  }
  
  .sales-iq-filter-elements {
    width: 153px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 26px;
    text-align: center;
    font-size: 20px;
    color: #6941c6;
  }
  
  .sales-iq-content-actions,
  .sales-iq-content-elements-parent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-content-actions {
    flex: 1;
    align-items: flex-start;
    gap: 15px;
    min-width: 525px;
  }
  
  .sales-iq-content-elements-parent {
    align-self: stretch;
    align-items: flex-end;
    gap: 23px;
  }
  
  .sales-iq-location-column-child {
    height: 52px;
    width: 52px;
    position: relative;
    object-fit: cover;
    z-index: 1;
    border-radius: 99px;
  }
  
  .sales-iq-name,
  .sales-iq-role {
    position: relative;
    display: inline-block;
  }
  
  .sales-iq-name {
    line-height: 23px;
    font-weight: 600;
    min-width: 77px;
    z-index: 1;
  }
  
  .sales-iq-role {
    height: 19px;
    font-size: 12px;
    line-height: 24px;
    color: #6941c6;
    flex-shrink: 0;
    min-width: 87px;
    z-index: 2;
  }
  
  .sales-iq-location-column-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 3px 0 0;
  }
  
  .sales-iq-text67 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 34px;
  }
  
  .sales-iq-buttonsbutton7 {
    width: 90px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px 12px;
    gap: 6px;
    z-index: 1;
  }
  
  .sales-iq-icon1 {
    width: 16.7px;
    height: 16.7px;
    position: relative;
  }
  
  .sales-iq-icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 3.7px 0 0;
  }
  
  .sales-iq-text68 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 56px;
  }
  
  .sales-iq-buttonsbutton8,
  .sales-iq-buttonsbutton9 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px 11px;
    gap: 5.5px;
    min-width: 86px;
    z-index: 1;
  }
  
  .sales-iq-buttonsbutton9 {
    padding: 6px 10px 6px 12px;
    gap: 5.4px;
  }
  
  .sales-iq-buttonsbutton-parent {
    align-self: stretch;
    flex-direction: row;
    gap: 16px;
  }
  
  .sales-iq-buttonsbutton-parent,
  .sales-iq-primary-table,
  .sales-iq-primary-table-inner {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-primary-table-inner {
    flex: 1;
    flex-direction: column;
    padding: 6px 0 0;
    box-sizing: border-box;
    min-width: 250px;
    max-width: 100%;
    color: #344054;
  }
  
  .sales-iq-primary-table {
    width: 556.4px;
    flex-direction: row;
    gap: 24px;
    max-width: calc(100% - 44px);
  }
  
  .sales-iq-arrow-square-up-right-icon {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
  }
  
  .sales-iq-arrow-square-up-right-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 14px 0 0;
  }
  
  .sales-iq-primary-table-parent {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 100%;
    gap: 20px;
  }
  
  .sales-iq-content-items,
  .sales-iq-help-avatar,
  .sales-iq-rectangle-parent3 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-help-avatar {
    width: 968px;
    flex-direction: column;
    gap: 16px;
    font-size: 14px;
  }
  
  .sales-iq-content-items,
  .sales-iq-rectangle-parent3 {
    box-sizing: border-box;
  }
  
  .sales-iq-rectangle-parent3 {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    flex-direction: column;
    padding: 12px 13px 15px 23px;
    gap: 24px;
    z-index: 1;
  }
  
  .sales-iq-content-items {
    align-self: stretch;
    flex-direction: row;
    padding: 0 30px 24px 32px;
    font-size: 18px;
  }
  
  .sales-iq-text71 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 55px;
  }
  
  .sales-iq-badge22 {
    width: 73px;
    border-radius: 9999px;
    background-color: #fff9ef;
    border: 1px solid #f5b231;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 8px;
    gap: 6px;
    mix-blend-mode: normal;
    z-index: 1;
  }
  
  .sales-iq-supporting-text6 {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    text-align: left;
    display: inline-block;
    min-width: 83px;
    z-index: 1;
  }
  
  .sales-iq-badge-parent {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }
  
  .sales-iq-badge-parent,
  .sales-iq-badge23,
  .sales-iq-frame-parent4 {
    flex-direction: row;
    justify-content: flex-start;
  }
  
  .sales-iq-frame-parent4 {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    text-align: left;
    font-size: 16px;
    color: #fff;
  }
  
  .sales-iq-badge23 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 1;
    color: #344054;
  }
  
  .sales-iq-badge23,
  .sales-iq-badge24,
  .sales-iq-frame-parent3 {
    display: flex;
    align-items: flex-start;
  }
  
  .sales-iq-frame-parent3 {
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    min-width: 114px;
    color: #f5b231;
  }
  
  .sales-iq-badge24 {
    flex-direction: row;
    white-space: nowrap;
  }
  
  .sales-iq-badge24,
  .sales-iq-badge25 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 1;
  }
  
  .sales-iq-badge-elements-copy,
  .sales-iq-badge25,
  .sales-iq-price-badge-elements {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-badge-elements-copy {
    flex: 1;
    justify-content: flex-start;
    gap: 8px;
  }
  
  .sales-iq-price-badge-elements {
    align-self: stretch;
    justify-content: flex-end;
    padding: 0 6px 0 0;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-frame-parent5 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 56px;
    min-width: 109px;
    text-align: left;
    font-size: 16px;
  }
  
  .sales-iq-badge26,
  .sales-iq-date-elements {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 10px 0 18px;
  }
  
  .sales-iq-badge26 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    justify-content: flex-start;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 1;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-badge27,
  .sales-iq-date-elements-parent {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-date-elements-parent {
    flex: 0.9649;
    flex-direction: column;
    align-items: flex-end;
    padding: 0 6px 0 0;
    gap: 56px;
    min-width: 111px;
    text-align: left;
    font-size: 16px;
  }
  
  .sales-iq-badge27 {
    width: 93px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 7px;
    z-index: 1;
    color: #344054;
  }
  
  .sales-iq-frame-parent2 {
    width: 615px;
    align-items: flex-end;
    padding: 0 2px 0 0;
    box-sizing: border-box;
    gap: 2px;
    text-align: center;
    font-size: 14px;
    color: #475467;
  }
  
  .sales-iq-frame-parent1,
  .sales-iq-frame-parent2,
  .sales-iq-rectangle-parent5 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-frame-parent1 {
    flex: 1;
    align-items: flex-start;
    gap: 15px;
    min-width: 524px;
  }
  
  .sales-iq-rectangle-parent5 {
    align-self: stretch;
    align-items: flex-end;
    gap: 24px;
  }
  
  .sales-iq-frame-child5,
  .sales-iq-frame-child6 {
    width: 24px;
    height: 24px;
    position: relative;
    z-index: 1;
  }
  
  .sales-iq-frame-child6 {
    width: 1016px;
    height: 282px;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: none;
    max-width: 100%;
  }
  
  .sales-iq-frame-child7,
  .sales-iq-text78 {
    position: relative;
    z-index: 2;
  }
  
  .sales-iq-frame-child7 {
    height: 148px;
    width: 148px;
    border-radius: 12px;
    object-fit: cover;
  }
  
  .sales-iq-text78 {
    line-height: 28px;
    font-weight: 600;
  }
  
  .sales-iq-badge28 {
    position: absolute;
    top: 80px;
    left: 522px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    width: 93px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 2;
  }
  
  .sales-iq-marker-pin-02-icon2 {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
  }
  
  .sales-iq-location3,
  .sales-iq-type-of-work2 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 124px;
    z-index: 2;
  }
  
  .sales-iq-type-of-work2 {
    min-width: 109px;
  }
  
  .sales-iq-frame-parent12,
  .sales-iq-frame-wrapper12 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-frame-parent12 {
    justify-content: flex-start;
    gap: 20px;
  }
  
  .sales-iq-frame-wrapper12 {
    justify-content: flex-end;
    padding: 0 5px;
  }
  
  .sales-iq-badge29,
  .sales-iq-badge30 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 2;
  }
  
  .sales-iq-badge29 {
    width: 77px;
    flex-direction: row;
    align-items: flex-start;
    white-space: nowrap;
  }
  
  .sales-iq-badge30 {
    width: 76px;
  }
  
  .sales-iq-badge-container,
  .sales-iq-badge30,
  .sales-iq-badge31 {
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-badge31 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    display: flex;
    justify-content: flex-start;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 2;
  }
  
  .sales-iq-badge-container {
    gap: 8px;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-badge-container,
  .sales-iq-badge32,
  .sales-iq-frame-parent11 {
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-frame-parent11 {
    position: absolute;
    top: 0;
    left: 180px;
    flex-direction: column;
    align-items: flex-end;
    gap: 56px;
    text-align: left;
    font-size: 16px;
    color: #475467;
  }
  
  .sales-iq-badge32 {
    flex: 1;
    border-radius: 9999px;
    background-color: #eceef1;
    border: 1px solid #98a2b3;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 8px;
    gap: 6px;
    mix-blend-mode: normal;
    z-index: 2;
  }
  
  .sales-iq-supporting-text8 {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    text-align: left;
    display: inline-block;
    min-width: 83px;
    z-index: 2;
  }
  
  .sales-iq-badge-parent1 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
  }
  
  .sales-iq-supporting-text9 {
    position: relative;
    line-height: 24px;
    display: inline-block;
    min-width: 117px;
    z-index: 2;
  }
  
  .sales-iq-badge33,
  .sales-iq-frame-parent15 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-badge33 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 2;
    color: #344054;
  }
  
  .sales-iq-frame-parent15 {
    position: absolute;
    top: 0;
    left: 0;
    width: 181px;
    flex-direction: column;
    gap: 15px;
    color: #98a2b3;
  }
  
  .sales-iq-badge-group {
    width: 615px;
    height: 104px;
    position: relative;
    max-width: 100%;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-dropdown10 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 2;
  }
  
  .sales-iq-price2,
  .sales-iq-text83 {
    position: relative;
  }
  
  .sales-iq-price2 {
    align-self: stretch;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    white-space: nowrap;
    z-index: 2;
  }
  
  .sales-iq-text83 {
    line-height: 20px;
    display: inline-block;
    min-width: 83px;
    z-index: 3;
  }
  
  .sales-iq-radar-1-1-container,
  .sales-iq-rectangle-parent7 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  
  .sales-iq-radar-1-1-container {
    flex: 1;
    align-items: flex-start;
    gap: 8px;
    z-index: 2;
  }
  
  .sales-iq-rectangle-parent7 {
    align-self: stretch;
    align-items: flex-end;
    padding: 0 0 76px;
    box-sizing: border-box;
    gap: 24px;
    max-width: 100%;
  }
  
  .sales-iq-frame-child8 {
    height: 52px;
    width: 52px;
    position: relative;
    object-fit: cover;
    z-index: 2;
  }
  
  .sales-iq-name2,
  .sales-iq-role2 {
    position: relative;
    display: inline-block;
  }
  
  .sales-iq-name2 {
    line-height: 23px;
    font-weight: 600;
    min-width: 77px;
    z-index: 2;
  }
  
  .sales-iq-role2 {
    height: 19px;
    font-size: 12px;
    line-height: 24px;
    color: #6941c6;
    flex-shrink: 0;
    min-width: 87px;
    z-index: 3;
  }
  
  .sales-iq-buttonsbutton13,
  .sales-iq-buttonsbutton14 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    z-index: 2;
  }
  
  .sales-iq-buttonsbutton13 {
    width: 90px;
    overflow: hidden;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px 12px;
    gap: 6px;
  }
  
  .sales-iq-buttonsbutton14 {
    flex: 1;
    overflow-x: auto;
    padding: 6px 11px;
    gap: 5.5px;
    min-width: 86px;
  }
  
  .sales-iq-buttonsbutton14,
  .sales-iq-buttonsbutton15,
  .sales-iq-frame-parent18 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-buttonsbutton15 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow-x: auto;
    padding: 6px 10px 6px 12px;
    gap: 5.4px;
    min-width: 86px;
    z-index: 2;
  }
  
  .sales-iq-frame-parent18 {
    width: 556.4px;
    gap: 24px;
    max-width: 100%;
  }
  
  .sales-iq-text84 {
    width: 66px;
    position: relative;
    line-height: 18px;
    font-weight: 600;
    display: inline-block;
    min-width: 66px;
  }
  
  .sales-iq-content19 {
    border-radius: 8px;
    background-color: #0c111d;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 12px;
    white-space: nowrap;
  }
  
  .sales-iq-tooltip-icon {
    width: 28px;
    height: 6px;
    position: relative;
  }
  
  .sales-iq-tooltip {
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
      0 4px 6px -2px rgba(16, 24, 40, 0.03);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    z-index: 3;
  }
  
  .sales-iq-arrow-square-up-right-icon1 {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 2;
  }
  
  .sales-iq-frame-wrapper16,
  .sales-iq-tooltip-parent {
    display: flex;
    flex-direction: column;
  }
  
  .sales-iq-tooltip-parent {
    align-items: flex-end;
    justify-content: flex-start;
    gap: 8px;
  }
  
  .sales-iq-frame-wrapper16 {
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 0 14px;
    text-align: center;
    font-size: 12px;
    color: #fff;
  }
  
  .sales-iq-divider-icon5,
  .sales-iq-frame-parent17 {
    width: 968px;
    max-width: 100%;
    margin-top: -69px;
  }
  
  .sales-iq-frame-parent17 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: 20px;
    font-size: 14px;
  }
  
  .sales-iq-divider-icon5 {
    position: relative;
    max-height: 100%;
    z-index: 2;
  }
  
  .sales-iq-rectangle-parent6 {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 14px 13px 17px 23px;
    min-height: 282px;
    max-width: 100%;
    z-index: 1;
  }
  
  .sales-iq-text86 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 51px;
  }
  
  .sales-iq-badge34,
  .sales-iq-badge36 {
    justify-content: flex-start;
    z-index: 2;
  }
  
  .sales-iq-badge34 {
    width: 69px;
    border-radius: 9999px;
    background-color: #fffdfd;
    border: 1px solid #da2d20;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 8px;
    gap: 6px;
    mix-blend-mode: normal;
  }
  
  .sales-iq-badge36 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    padding: 0 7px;
    white-space: nowrap;
  }
  
  .sales-iq-badge36,
  .sales-iq-badge37,
  .sales-iq-frame-wrapper18 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-badge37 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    justify-content: flex-start;
    padding: 0 7px;
    z-index: 2;
  }
  
  .sales-iq-frame-wrapper18 {
    align-self: stretch;
    justify-content: flex-end;
    padding: 0 2px 0 0;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-frame-parent24 {
    width: 163px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 56px;
    text-align: left;
    font-size: 16px;
  }
  
  .sales-iq-frame-wrapper19 {
    flex-direction: row;
    padding: 0 14px;
  }
  
  .sales-iq-badge38,
  .sales-iq-frame-parent26,
  .sales-iq-frame-wrapper19 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-badge38 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    padding: 0 7px;
    white-space: nowrap;
    z-index: 2;
    text-align: center;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-frame-parent26 {
    flex: 1;
    flex-direction: column;
    gap: 56px;
    min-width: 107px;
    text-align: left;
    font-size: 16px;
  }
  
  .sales-iq-badge39,
  .sales-iq-frame-parent21 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  
  .sales-iq-badge39 {
    width: 93px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    align-items: flex-start;
    padding: 0 7px;
    z-index: 2;
    color: #344054;
  }
  
  .sales-iq-frame-parent21 {
    width: 615px;
    align-items: flex-end;
    gap: 6.3px;
    max-width: 100%;
    text-align: center;
    font-size: 14px;
    color: #475467;
  }
  
  .sales-iq-divider-icon6 {
    align-self: stretch;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    z-index: 2;
  }
  
  .sales-iq-element-details-child,
  .sales-iq-frame-child12 {
    position: relative;
    border-radius: 12px;
    display: none;
  }
  
  .sales-iq-frame-child12 {
    height: 208px;
    width: 1016px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .sales-iq-element-details-child {
    height: 160px;
    width: 160px;
    background-color: #f5f5f5;
  }
  
  .sales-iq-f4b711e503bc72fe396e5043e0c2-1-icon {
    height: 84px;
    width: 84px;
    position: relative;
    object-fit: cover;
    z-index: 3;
  }
  
  .sales-iq-element-details {
    border-radius: 12px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 38px;
    box-sizing: border-box;
    min-width: 160px;
    z-index: 2;
  }
  
  .sales-iq-supporting-text12 {
    position: relative;
    letter-spacing: -0.06px;
    line-height: 24px;
    z-index: 2;
  }
  
  .sales-iq-end-label {
    width: 50px;
    border-radius: 8px;
    background-color: #fdb022;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 1px 4px;
    box-sizing: border-box;
    z-index: 2;
  }
  
  .sales-iq-text99 {
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 2;
  }
  
  .sales-iq-supporting-text14 {
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    min-width: 104px;
    z-index: 3;
  }
  
  .sales-iq-date-icon,
  .sales-iq-type-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-type-label {
    font-size: 14px;
    color: #101828;
  }
  
  .sales-iq-date-icon {
    flex: 1;
    gap: 16px;
    min-width: 114px;
  }
  
  .sales-iq-text100 {
    align-self: stretch;
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 2;
  }
  
  .sales-iq-supporting-text15 {
    width: 104px;
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    z-index: 3;
  }
  
  .sales-iq-work-type-icon,
  .sales-iq-work-type-value {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-work-type-value {
    align-self: stretch;
    flex-direction: row;
    padding: 0 0 0 1px;
    font-size: 14px;
    color: #101828;
  }
  
  .sales-iq-work-type-icon {
    width: 152px;
    flex-direction: column;
    gap: 58px;
    min-width: 152px;
  }
  
  .sales-iq-calendar-icon6 {
    flex: 1;
    gap: 8px;
    min-width: 218px;
    max-width: 100%;
  }
  
  .sales-iq-action-icons,
  .sales-iq-calendar-icon6,
  .sales-iq-work-type-label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-work-type-label {
    gap: 8px;
    margin-left: -9px;
  }
  
  .sales-iq-action-icons {
    align-self: stretch;
    max-width: 100%;
    row-gap: 20px;
    font-size: 16px;
    color: #475467;
  }
  
  .sales-iq-element-actions {
    flex: 1;
    flex-direction: column;
    gap: 8px;
    min-width: 302px;
  }
  
  .sales-iq-element-actions,
  .sales-iq-element-details-parent,
  .sales-iq-frame-wrapper25 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-element-details-parent {
    align-self: stretch;
    flex-direction: row;
    gap: 24px;
  }
  
  .sales-iq-frame-wrapper25 {
    width: 648px;
    flex-direction: column;
    padding: 10px 0 0;
    box-sizing: border-box;
  }
  
  .sales-iq-text101 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 29px;
    z-index: 3;
  }
  
  .sales-iq-text-wrapper10 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 57px;
  }
  
  .sales-iq-price4 {
    align-self: stretch;
    position: relative;
    font-size: 20px;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    color: #6941c6;
    white-space: nowrap;
    z-index: 2;
  }
  
  .sales-iq-frame-wrapper27 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 34px 0 0;
  }
  
  .sales-iq-frame-parent32,
  .sales-iq-trend-chart-inner {
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-frame-parent32 {
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    gap: 19px;
  }
  
  .sales-iq-trend-chart-inner {
    align-self: stretch;
    flex-direction: row;
    align-items: flex-start;
    padding: 0 0 0 3px;
  }
  
  .sales-iq-dropdown-parent1,
  .sales-iq-frame-parent31,
  .sales-iq-trend-chart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .sales-iq-trend-chart {
    width: 113px;
    align-items: flex-start;
    gap: 4px;
  }
  
  .sales-iq-dropdown-parent1,
  .sales-iq-frame-parent31 {
    align-items: flex-end;
  }
  
  .sales-iq-frame-parent31 {
    flex: 1;
  }
  
  .sales-iq-dropdown-parent1 {
    width: 153px;
    gap: 22px;
    text-align: center;
    font-size: 12px;
    color: #475467;
  }
  
  .sales-iq-rectangle-parent10 {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 15px 12px 23px;
    max-width: 100%;
    gap: 20px;
    z-index: 1;
  }
  
  .sales-iq-f4b711e503bc72fe396e5043e0c2-1-icon1 {
    height: 84px;
    width: 84px;
    position: relative;
    object-fit: cover;
    z-index: 2;
  }
  
  .sales-iq-rectangle-parent12 {
    border-radius: 12px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 38px;
    z-index: 1;
  }
  
  .sales-iq-text103 {
    position: relative;
    line-height: 28px;
    font-weight: 600;
    display: inline-block;
    min-width: 128px;
    z-index: 1;
  }
  
  .sales-iq-text-wrapper11 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 8px 0 0;
    font-size: 18px;
    color: #101828;
  }
  
  .sales-iq-icon9 {
    width: 12px;
    height: 9.3px;
    position: relative;
    z-index: 1;
  }
  
  .sales-iq-icon-wrapper4 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 0 0;
  }
  
  .sales-iq-span {
    font-weight: 500;
  }
  
  .sales-iq-supporting-text16 {
    height: 19px;
    position: relative;
    line-height: 24px;
    display: inline-block;
    flex-shrink: 0;
    min-width: 63px;
    z-index: 1;
  }
  
  .sales-iq-supporting-text-wrapper1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px 16px 0 0;
  }
  
  .sales-iq-frame-child15 {
    width: 16px;
    height: 16px;
    position: relative;
    z-index: 1;
  }
  
  .sales-iq-supporting-text17 {
    height: 19px;
    position: relative;
    line-height: 24px;
    display: inline-block;
    flex-shrink: 0;
    min-width: 70.9px;
    z-index: 1;
  }
  
  .sales-iq-frame-parent36,
  .sales-iq-frame-parent37,
  .sales-iq-location-elements-triple {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
  }
  
  .sales-iq-frame-parent37,
  .sales-iq-location-elements-triple {
    gap: 7px;
    font-size: 16px;
  }
  
  .sales-iq-frame-parent37 {
    gap: 8px;
    color: #fff;
  }
  
  .sales-iq-text105,
  .sales-iq-text106,
  .sales-iq-user-avatar {
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 1;
  }
  
  .sales-iq-text105,
  .sales-iq-text106 {
    display: inline-block;
  }
  
  .sales-iq-text105 {
    min-width: 103px;
  }
  
  .sales-iq-text106 {
    width: 151px;
    flex-shrink: 0;
  }
  
  .sales-iq-icon-button {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 34px;
  }
  
  .sales-iq-supporting-text19 {
    position: relative;
    line-height: 19px;
    display: inline-block;
    min-width: 92.1px;
    z-index: 2;
  }
  
  .sales-iq-sidebar-content1 {
    width: 161.9px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 20px 0 0;
    box-sizing: border-box;
  }
  
  .sales-iq-supporting-text20,
  .sales-iq-supporting-text21 {
    position: relative;
    line-height: 19px;
    display: inline-block;
    flex-shrink: 0;
    z-index: 2;
  }
  
  .sales-iq-supporting-text20 {
    min-width: 70.9px;
  }
  
  .sales-iq-supporting-text21 {
    width: 104px;
  }
  
  .sales-iq-sidebar1 {
    width: 469px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    max-width: 100%;
    font-size: 12px;
    color: #475467;
  }
  
  .sales-iq-frame-parent33,
  .sales-iq-frame-parent34,
  .sales-iq-nav-item-button-icon {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-nav-item-button-icon {
    align-self: stretch;
    flex-direction: column;
    font-size: 14px;
    color: #101828;
  }
  
  .sales-iq-frame-parent33,
  .sales-iq-frame-parent34 {
    flex: 1;
  }
  
  .sales-iq-frame-parent34 {
    flex-direction: column;
    gap: 16px;
    min-width: 335px;
  }
  
  .sales-iq-frame-parent33 {
    flex-direction: row;
    gap: 24px;
  }
  
  .sales-iq-text107 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 123px;
  }
  
  .sales-iq-buttonsbutton-wrapper,
  .sales-iq-buttonsbutton19 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-buttonsbutton19 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    justify-content: flex-start;
    padding: 6px 12px;
    gap: 5px;
    z-index: 1;
  }
  
  .sales-iq-buttonsbutton-wrapper {
    align-self: stretch;
    justify-content: flex-end;
    padding: 0 27px 0 0;
  }
  
  .sales-iq-arrow-square-up-right-icon4 {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 3;
  }
  
  .sales-iq-dropdown-parent2,
  .sales-iq-frame-parent38 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
  
  .sales-iq-frame-parent38 {
    flex: 1;
    gap: 27px;
  }
  
  .sales-iq-dropdown-parent2 {
    align-self: stretch;
    gap: 50px;
  }
  
  .sales-iq-frame-wrapper29 {
    width: 215px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 0 9px;
    box-sizing: border-box;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-rectangle-parent11 {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 12px 13px 22px 23px;
    gap: 63px;
    z-index: 1;
  }
  
  .sales-iq-content-items5,
  .sales-iq-frame-child16,
  .sales-iq-rectangle-parent11 {
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .sales-iq-content-items5 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 30px 24px 32px;
    font-size: 12px;
    color: #475467;
  }
  
  .sales-iq-frame-child16 {
    height: 208px;
    width: 1016px;
    position: relative;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    display: none;
    z-index: 1;
  }
  
  .sales-iq-supporting-text-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    font-size: 16px;
    color: #475467;
  }
  
  .sales-iq-search-icon-input {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 41px;
  }
  
  .sales-iq-supporting-text24 {
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    min-width: 104px;
    z-index: 1;
  }
  
  .sales-iq-frame-parent43 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
  }
  
  .sales-iq-supporting-text25 {
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    min-width: 113px;
    z-index: 1;
  }
  
  .sales-iq-frame-parent42 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 10px;
    min-width: 272px;
    max-width: 100%;
  }
  
  .sales-iq-text111 {
    align-self: stretch;
    position: relative;
    line-height: 23px;
    font-weight: 500;
    z-index: 1;
  }
  
  .sales-iq-supporting-text26 {
    width: 113px;
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    z-index: 1;
  }
  
  .sales-iq-text-parent8 {
    width: 233px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 14px;
    color: #101828;
  }
  
  .sales-iq-frame-parent39,
  .sales-iq-frame-parent41 {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-frame-parent41 {
    align-self: stretch;
    flex-direction: row;
    align-items: flex-end;
    gap: 34px;
    font-size: 16px;
    color: #fff;
  }
  
  .sales-iq-frame-parent39 {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    min-width: 445px;
  }
  
  .sales-iq-dropdown14,
  .sales-iq-text112 {
    position: absolute;
    z-index: 1;
  }
  
  .sales-iq-dropdown14 {
    top: 0;
    left: 306px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-text112 {
    top: 19px;
    left: 137px;
    line-height: 20px;
    display: inline-block;
    min-width: 41px;
  }
  
  .sales-iq-price5 {
    flex: 1;
    position: relative;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    white-space: nowrap;
    z-index: 2;
  }
  
  .sales-iq-price6,
  .sales-iq-text113 {
    position: relative;
    z-index: 1;
  }
  
  .sales-iq-text113 {
    line-height: 20px;
    display: inline-block;
    min-width: 15px;
  }
  
  .sales-iq-price6 {
    flex: 1;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    white-space: nowrap;
  }
  
  .sales-iq-content-table-cards {
    position: absolute;
    top: 39px;
    left: 0;
    width: 316px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 7.5px;
    font-size: 20px;
    color: #6941c6;
  }
  
  .sales-iq-text114 {
    position: absolute;
    top: 70px;
    left: 116px;
    line-height: 20px;
    display: inline-block;
    min-width: 83px;
    z-index: 3;
  }
  
  .sales-iq-radar-1-1-parent3 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    z-index: 1;
  }
  
  .sales-iq-arrow-square-up-right-icon5 {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 4;
  }
  
  .sales-iq-arrow-square-up-right-frame {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 43px 0 0;
  }
  
  .sales-iq-frame-parent46,
  .sales-iq-frame-wrapper32 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-frame-parent46 {
    flex: 1;
    justify-content: space-between;
    gap: 20px;
  }
  
  .sales-iq-frame-wrapper32 {
    width: 217px;
    justify-content: flex-end;
    padding: 0 10px;
    box-sizing: border-box;
  }
  
  .sales-iq-frame-parent45,
  .sales-iq-frame-wrapper31 {
    display: flex;
    flex-direction: column;
  }
  
  .sales-iq-frame-parent45 {
    align-self: stretch;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 4px;
  }
  
  .sales-iq-frame-wrapper31 {
    width: 326px;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 0 9px;
    box-sizing: border-box;
    min-width: 326px;
    max-width: 100%;
    margin-left: -33px;
    text-align: center;
    font-size: 12px;
    color: #475467;
  }
  
  .sales-iq-card-container-child,
  .sales-iq-rectangle-parent13 {
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .sales-iq-rectangle-parent13 {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 12px 13px 22px 23px;
    row-gap: 20px;
    z-index: 1;
  }
  
  .sales-iq-card-container-child {
    width: 1016px;
    height: 301px;
    position: relative;
    display: none;
  }
  
  .sales-iq-supporting-text27 {
    position: relative;
    line-height: 24px;
    display: inline-block;
    min-width: 83px;
    z-index: 2;
  }
  
  .sales-iq-title-parent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    max-width: 100%;
  }
  
  .sales-iq-supporting-text30 {
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    min-width: 113px;
    z-index: 3;
  }
  
  .sales-iq-detail-title-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 151px;
    font-size: 14px;
    color: #101828;
  }
  
  .sales-iq-supporting-text31 {
    width: 113px;
    position: relative;
    font-size: 12px;
    line-height: 19px;
    color: #475467;
    display: inline-block;
    z-index: 3;
  }
  
  .sales-iq-card-row {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 0 0;
    box-sizing: border-box;
    min-width: 445px;
    max-width: 100%;
  }
  
  .sales-iq-dropdown15,
  .sales-iq-item-title {
    position: absolute;
    z-index: 2;
  }
  
  .sales-iq-dropdown15 {
    top: 0;
    left: 306px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-item-title {
    top: 19px;
    left: 139px;
    line-height: 20px;
    display: inline-block;
    min-width: 36px;
  }
  
  .sales-iq-price7 {
    flex: 1;
    position: relative;
    letter-spacing: -0.02em;
    line-height: 32px;
    font-weight: 600;
    white-space: nowrap;
    z-index: 3;
  }
  
  .sales-iq-text116 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 15px;
    z-index: 2;
  }
  
  .sales-iq-currency {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px 0 0;
    font-size: 12px;
    color: #475467;
  }
  
  .sales-iq-item-title1 {
    position: absolute;
    top: 70px;
    left: 116px;
    line-height: 20px;
    display: inline-block;
    min-width: 83px;
    z-index: 4;
  }
  
  .sales-iq-list-item {
    align-self: stretch;
    height: 90px;
    position: relative;
  }
  
  .sales-iq-item-icons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    z-index: 2;
  }
  
  .sales-iq-magnifier-1-icon7 {
    height: 20px;
    width: 20px;
    position: relative;
    object-fit: cover;
    min-height: 20px;
    z-index: 2;
  }
  
  .sales-iq-frame-wrapper33 {
    width: 310px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
  }
  
  .sales-iq-card-container,
  .sales-iq-card-list,
  .sales-iq-list-item-parent {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-list-item-parent {
    width: 326px;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
    min-width: 326px;
    margin-left: -33px;
    text-align: center;
    font-size: 12px;
    color: #475467;
  }
  
  .sales-iq-card-container,
  .sales-iq-card-list {
    align-items: flex-start;
  }
  
  .sales-iq-card-list {
    align-self: stretch;
    flex-direction: row;
    row-gap: 20px;
  }
  
  .sales-iq-card-container {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    flex-direction: column;
    padding: 12px 13px 22px 23px;
    gap: 24px;
    z-index: 1;
  }
  
  .sales-iq-text-wrapper18 {
    padding: 0 60px 0 57px;
  }
  
  .sales-iq-frame-wrapper37,
  .sales-iq-price-wrapper,
  .sales-iq-text-wrapper18,
  .sales-iq-text-wrapper19 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
  }
  
  .sales-iq-price-wrapper {
    align-self: stretch;
    padding: 0 3px 0 0;
    font-size: 20px;
    color: #6941c6;
  }
  
  .sales-iq-frame-wrapper37,
  .sales-iq-text-wrapper19 {
    padding: 0 33px 4px 30px;
  }
  
  .sales-iq-frame-wrapper37 {
    padding: 0 37px 0 0;
  }
  
  .sales-iq-frame-parent60 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 19px;
  }
  
  .sales-iq-frame-wrapper36 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 7px 0 0;
  }
  
  .sales-iq-text-parent12 {
    justify-content: flex-start;
    gap: 16px;
    font-size: 18px;
    color: #101828;
  }
  
  .sales-iq-buttonsbutton-frame,
  .sales-iq-frame-parent64,
  .sales-iq-text-parent12 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-frame-parent64 {
    justify-content: flex-start;
    gap: 7.3px;
  }
  
  .sales-iq-buttonsbutton-frame {
    align-self: stretch;
    justify-content: flex-end;
    padding: 0 30px 0 0;
  }
  
  .sales-iq-divider-container-child {
    width: 1016px;
    height: 493px;
    position: relative;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: none;
    max-width: 100%;
  }
  
  .sales-iq-badge-item-parent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 15px;
    min-width: 114px;
    color: #da2d20;
  }
  
  .sales-iq-divider-icon8 {
    width: 968px;
    position: relative;
    max-height: 100%;
    max-width: 100%;
    z-index: 2;
  }
  
  .sales-iq-heading9 {
    width: 254px;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
  }
  
  .sales-iq-change7,
  .sales-iq-text139 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
  }
  
  .sales-iq-change7 {
    display: inline-block;
    min-width: 38px;
  }
  
  .sales-iq-text139 {
    flex: 1;
    color: #475467;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .sales-iq-change-and-text3,
  .sales-iq-number-and-badge3 {
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-change-and-text3 {
    align-self: stretch;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    text-align: center;
    font-size: 14px;
    color: #079455;
  }
  
  .sales-iq-number-and-badge3 {
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    min-width: 105px;
  }
  
  .sales-iq-chart-mini-icon3 {
    height: 48px;
    width: 96px;
    position: relative;
    object-fit: contain;
  }
  
  .sales-iq-dropdown19,
  .sales-iq-metric-item3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-dropdown19 {
    margin: 0 !important;
    position: absolute;
    top: 16px;
    right: 16px;
    position: relative;
  }
  
  .sales-iq-metric-item3 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    padding: 16px 15px 20px;
    position: relative;
    gap: 20px;
    min-width: 229px;
    z-index: 2;
  }
  
  .sales-iq-buttonsbutton25,
  .sales-iq-buttonsbutton26,
  .sales-iq-metric-item-parent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-metric-item-parent {
    width: 968px;
    gap: 25px;
    max-width: 100%;
    font-size: 16px;
  }
  
  .sales-iq-buttonsbutton25,
  .sales-iq-buttonsbutton26 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
  
    padding: 6px 11px;
    gap: 5.5px;
    min-width: 92px;
    z-index: 2;
  }
  
  .sales-iq-buttonsbutton26 {
    padding: 6px 10px 6px 12px;
    gap: 5.4px;
  }
  
  .sales-iq-text145 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 70px;
  }
  
  .sales-iq-ellipse-div {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    background-color: #17b26a;
    width: 17px;
    height: 17px;
    z-index: 3;
  }
  
  .sales-iq-div {
    position: absolute;
    top: 4px;
    left: 6px;
    line-height: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: 7px;
    height: 20px;
    min-width: 7px;
    z-index: 4;
  }
  
  .sales-iq-ellipse-parent {
    height: 24px;
    width: 17px;
    position: absolute;
    margin: 0 !important;
    top: -9px;
    right: -2.7px;
    font-size: 10px;
    color: #fff;
  }
  
  .sales-iq-buttonsbutton27 {
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-direction: row;
    padding: 8px 12px;
    position: relative;
    gap: 6px;
    min-width: 79px;
    z-index: 2;
  }
  
  .sales-iq-arrow-square-up-right-wrapper1,
  .sales-iq-buttonsbutton-parent1,
  .sales-iq-buttonsbutton27 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-buttonsbutton-parent1 {
    width: 523.3px;
    flex-direction: row;
    gap: 16px;
    max-width: calc(100% - 44px);
  }
  
  .sales-iq-arrow-square-up-right-wrapper1 {
    flex-direction: column;
    padding: 8px 0 0;
  }
  
  .sales-iq-divider-container1,
  .sales-iq-frame-parent71 {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-frame-parent71 {
    width: 968px;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    font-size: 14px;
    color: #344054;
  }
  
  .sales-iq-divider-container1 {
    flex: 1;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    padding: 12px 13px 22px 23px;
    gap: 24.3px;
    z-index: 1;
  }
  
  .sales-iq-text146 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 72px;
  }
  
  .sales-iq-buttonsbutton-wrapper1,
  .sales-iq-buttonsbutton28 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-buttonsbutton28 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    justify-content: flex-start;
    padding: 6px 12px;
    gap: 6px;
    z-index: 1;
    cursor: pointer;
  }
  
  .sales-iq-buttonsbutton-wrapper1 {
    align-self: stretch;
    justify-content: center;
    padding: 0 20px;
  }
  
  .sales-iq-text147 {
    position: relative;
    line-height: 28px;
    font-weight: 600;
  }
  
  .sales-iq-card-header-badge {
    position: relative;
    line-height: 18px;
    font-weight: 500;
  }
  
  .sales-iq-badge46,
  .sales-iq-text-and-badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .sales-iq-badge46 {
    border-radius: 9999px;
    background-color: #f9f5ff;
    border: 1px solid #e9d7fe;
    padding: 0 7px;
    white-space: nowrap;
    text-align: center;
    font-size: 12px;
    color: #6941c6;
  }
  
  .sales-iq-text-and-badge {
    align-self: stretch;
    padding: 0 242px 0 0;
    gap: 8px;
  }
  
  .sales-iq-supporting-text44 {
    align-self: stretch;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .sales-iq-text-and-supporting-text2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
    min-width: 424px;
    max-width: 100%;
  }
  
  .sales-iq-text151 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 92px;
  }
  
  .sales-iq-actions3,
  .sales-iq-button11 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .sales-iq-button11 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #7e56d9;
    border: 1px solid #7e56d9;
    overflow: hidden;
    justify-content: center;
    padding: 8px 13px;
    gap: 4px;
    color: #fff;
  }
  
  .sales-iq-actions3 {
    justify-content: flex-start;
    gap: 12px;
    font-size: 14px;
    color: #475467;
  }
  
  .sales-iq-content20,
  .sales-iq-divider-icon10 {
    align-self: stretch;
    max-width: 100%;
  }
  
  .sales-iq-content20 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 24px 0;
    box-sizing: border-box;
    gap: 16px;
  }
  
  .sales-iq-divider-icon10 {
    position: relative;
    overflow: hidden;
    max-height: 100%;
  }
  
  .sales-iq-card-header,
  .sales-iq-frame-parent72 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-card-header {
    align-self: stretch;
    background-color: #fff;
    gap: 20px;
    z-index: 1;
    font-size: 26px;
    color: #101828;
  }
  
  .sales-iq-frame-parent72 {
    flex: 1;
    gap: 24px;
  }
  
  .sales-iq-button-group-base9,
  .sales-iq-frame-wrapper44 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
  }
  
  .sales-iq-frame-wrapper44 {
    align-self: stretch;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 60px 32px;
    max-width: 100%;
    color: #344054;
    z-index: 0;
  }
  
  .sales-iq-button-group-base9 {
    width: 83px;
    background-color: #f9fafb;
    border-right: 1px solid #d0d5dd;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
  }
  
  .sales-iq-text153 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 105px;
  }
  
  .sales-iq-button-group-base10 {
    display: flex;
    padding: 10px 15px;
    white-space: nowrap;
  }
  
  .sales-iq-button-group-base10,
  .sales-iq-button-group-base11,
  .sales-iq-button-group-base17 {
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 40px;
  }
  
  .sales-iq-button-group-base11 {
    align-self: stretch;
    display: none;
    padding: 8px 16px;
    z-index: 6;
  }
  
  .sales-iq-button-group-base17 {
    width: 93px;
    flex-shrink: 0;
    display: flex;
    padding: 10px 15px;
  }
  
  .sales-iq-button-group1 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-search-placeholder1 {
    position: relative;
    line-height: 24px;
    font-weight: 500;
    display: inline-block;
    min-width: 82px;
  
  }
  
  .sales-iq-supporting-text45 {
    align-self: stretch;
    width: 54px;
    position: relative;
    line-height: 24px;
    color: #475467;
    display: none;
  }
  
  .sales-iq-content22,
  .sales-iq-input-with-label3,
  .sales-iq-input3 {
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-content22 {
    flex: 1;
    flex-direction: row;
    align-items: center;
    padding: 0 262px 0 0;
    box-sizing: border-box;
    gap: 8px;
  }
  
  .sales-iq-input-with-label3,
  .sales-iq-input3 {
    align-self: stretch;
  }
  
  .sales-iq-input3 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05),
      0 0 0 4px rgba(158, 119, 237, 0.24);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d6bbfb;
    box-sizing: border-box;
    overflow: hidden;
    flex-direction: row;
    align-items: center;
    padding: 8px 13px;
    font-size: 16px;
    color: #101828;
  }
  
  .sales-iq-input-with-label3 {
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }
  
  .sales-iq-hint-text3 {
    width: 320px;
    position: relative;
    line-height: 20px;
    color: #475467;
    display: none;
  }
  
  .sales-iq-input-dropdown3 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    max-width: 400px;
  }
  
  .sales-iq-actions4,
  .sales-iq-button12 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .sales-iq-button12 {
    width: 99px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
    padding: 10px 13px;
    gap: 4px;
  }
  
  .sales-iq-actions4 {
    width: 511px;
    justify-content: flex-start;
    gap: 12px;
    max-width: 100%;
  }
  
  .sales-iq-content-items9,
  .sales-iq-content21,
  .sales-iq-filters-bar1 {
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .sales-iq-content21 {
    align-self: stretch;
    border-radius: 12px;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 16px;
    gap: 20px;
  }
  
  .sales-iq-content-items9,
  .sales-iq-filters-bar1 {
    justify-content: flex-start;
  }
  
  .sales-iq-filters-bar1 {
    flex: 1;
    background-color: #fff;
    border-bottom: 1px solid #eaecf0;
    flex-direction: column;
    z-index: 2;
  }
  
  .sales-iq-content-items9 {
    align-self: stretch;
    flex-direction: row;
    padding: 0 30px 23px 32px;
    color: #344054;
  }
  
  .sales-iq-badge-number1,
  .sales-iq-text162 {
    position: relative;
    line-height: 28px;
    font-weight: 600;
    display: inline-block;
    min-width: 123px;
  }
  
  .sales-iq-badge-number1 {
    line-height: 18px;
    font-weight: 500;
    min-width: 56px;
  }
  
  .sales-iq-badge47,
  .sales-iq-text-and-badge1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .sales-iq-badge47 {
    width: 72px;
    border-radius: 9999px;
    background-color: #f9f5ff;
    border: 1px solid #e9d7fe;
    box-sizing: border-box;
    padding: 0 7px;
    white-space: nowrap;
    text-align: center;
    font-size: 12px;
    color: #6941c6;
  }
  
  .sales-iq-text-and-badge1 {
    align-self: stretch;
    padding: 0 729px 0 0;
    gap: 8px;
  }
  
  .sales-iq-supporting-text46 {
    width: 697px;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    display: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }
  
  .sales-iq-text-and-supporting-text3 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 12px 0;
    box-sizing: border-box;
    gap: 4px;
    max-width: 100%;
  }
  
  .sales-iq-actions5,
  .sales-iq-card-header1,
  .sales-iq-content23 {
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-actions5 {
    display: none;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    color: #475467;
  }
  
  .sales-iq-card-header1,
  .sales-iq-content23 {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
  }
  
  .sales-iq-content23 {
    flex-direction: row;
    padding: 20px 24px 0;
    box-sizing: border-box;
    gap: 16px;
  }
  
  .sales-iq-card-header1 {
    background-color: #fff;
    flex-direction: column;
    gap: 20px;
    flex-shrink: 0;
    font-size: 18px;
  }
  
  .sales-iq-checkbox-base {
    height: 20px;
    width: 20px;
    position: relative;
    border-radius: 6px;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .sales-iq-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-sub-nav-item {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 34px;
  }
  
  .sales-iq-arrow-down-icon1 {
    width: 16px;
    height: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .sales-iq-sub-nav-items {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
  }
  
  .sales-iq-sub-nav-items-wrapper {
    flex-direction: column;
    padding: 1px 0 0;
  }
  
  .sales-iq-checkbox-wrapper,
  .sales-iq-sub-nav-items-wrapper,
  .sales-iq-table-header-cell {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-table-header-cell {
    width: 441px;
    margin: 0 !important;
    position: absolute;
    top: 0;
    left: -380px;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    flex-direction: row;
    padding: 12px 24px 10px;
    gap: 12px;
    font-size: 12px;
    color: #475467;
  }
  
  .sales-iq-checkbox-wrapper {
    flex-direction: column;
    padding: 10px 0 0;
  }
  
  .sales-iq-avatar-online-indicator {
    height: 13px;
    width: 13px;
    position: relative;
    border-radius: 9999px;
    background-color: #17b26a;
    border: 1.5px solid #fff;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    z-index: 1;
  }
  
  .sales-iq-avatar2,
  .sales-iq-contrast-border2 {
    height: 40px;
    width: 40px;
    border-radius: 9999px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-contrast-border2 {
    border: 0.8px solid rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    overflow: hidden;
    justify-content: flex-end;
    padding: 30px 0 0 26px;
  }
  
  .sales-iq-avatar2 {
    justify-content: flex-start;
    background-image: url(../../icons/Gerator/sales_iq/avatar2@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  
  .sales-iq-account-button,
  .sales-iq-supporting-text47 {
    position: relative;
    line-height: 20px;
    display: inline-block;
  }
  
  .sales-iq-account-button {
    font-weight: 500;
    min-width: 75px;
  }
  
  .sales-iq-supporting-text47 {
    color: #475467;
    min-width: 97px;
  }
  
  .sales-iq-table-cell {
    width: 405px;
    margin: 0 !important;
    position: absolute;
    top: 44px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .sales-iq-avatar3 {
    height: 40px;
    width: 40px;
    position: relative;
    border-radius: 9999px;
    flex-shrink: 0;
    background-image: url(../../icons/Gerator/sales_iq/avatar3@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  
  .sales-iq-dropdown-first-date {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 96px;
  }
  
  .sales-iq-table-cell1 {
    width: 467px;
    margin: 0 !important;
    position: absolute;
    top: 116px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .sales-iq-text167 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 84px;
  }
  
  .sales-iq-table-cell2 {
    width: 428px;
    margin: 0 !important;
    position: absolute;
    top: 188px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .sales-iq-avatar5 {
    height: 40px;
    width: 40px;
    position: relative;
    border-radius: 9999px;
    flex-shrink: 0;
    background-image: url(../../icons/Gerator/sales_iq/avatar4@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  
  .sales-iq-card-content-third {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 103px;
    white-space: nowrap;
  }
  
  .sales-iq-contrast-border6,
  .sales-iq-table-cell3 {
    position: absolute;
    margin: 0 !important;
    box-sizing: border-box;
  }
  
  .sales-iq-table-cell3 {
    width: 411px;
    top: 260px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .sales-iq-contrast-border6 {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 9999px;
    border: 0.8px solid rgba(0, 0, 0, 0.08);
    overflow: hidden;
  }
  
  .sales-iq-card-header-actions {
    flex: 1;
    position: relative;
    line-height: 24px;
    font-weight: 600;
    z-index: 1;
  }
  
  .sales-iq-avatar6 {
    height: 40px;
    width: 40px;
    border-radius: 9999px;
    background-color: #f2f4f7;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 8px 0;
    box-sizing: border-box;
    position: relative;
  }
  
  .sales-iq-button-upload-icon {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 82px;
  }
  
  .sales-iq-button-upload,
  .sales-iq-table-cell4 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-size: 14px;
    color: #101828;
  }
  
  .sales-iq-table-cell4 {
    width: 428px;
    margin: 0 !important;
    position: absolute;
    top: 332px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    flex-direction: row;
    padding: 16px 24px 14px;
    gap: 12px;
    text-align: center;
    font-size: 16px;
    color: #667085;
  }
  
  .sales-iq-dropdown-table-input {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 78px;
  }
  
  .sales-iq-table-cell5 {
    width: 428px;
    margin: 0 !important;
    position: absolute;
    bottom: 288px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .sales-iq-avatar8 {
    height: 40px;
    width: 40px;
    position: relative;
    border-radius: 9999px;
    flex-shrink: 0;
    background-image: url(../../icons/Gerator/sales_iq/avatar5@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  
  .sales-iq-table-cell6,
  .sales-iq-table-cell7 {
    width: 411px;
    margin: 0 !important;
    position: absolute;
    bottom: 216px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .sales-iq-table-cell7 {
    width: 428px;
    bottom: 144px;
    text-align: center;
    font-size: 16px;
    color: #667085;
  }
  
  .sales-iq-avatar10 {
    height: 40px;
    width: 40px;
    position: relative;
    border-radius: 9999px;
    flex-shrink: 0;
    background-image: url(../../icons/Gerator/sales_iq/avatar6@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  
  .sales-iq-title3 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 67px;
  }
  
  .sales-iq-table-cell8 {
    width: 428px;
    margin: 0 !important;
    position: absolute;
    bottom: 72px;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .sales-iq-avatar11 {
    height: 40px;
    width: 40px;
    position: relative;
    border-radius: 9999px;
    flex-shrink: 0;
    background-image: url(../../icons/Gerator/sales_iq/avatar7@3x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  
  .sales-iq-title4 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 95px;
  }
  
  .sales-iq-table-cell9 {
    width: 428px;
    margin: 0 !important;
    position: absolute;
    bottom: 0;
    left: -380px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
    z-index: 1;
  }
  
  .sales-iq-text169 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 50px;
  }
  
  .sales-iq-table-header,
  .sales-iq-table-header-cell1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .sales-iq-table-header {
    gap: 4px;
  }
  
  .sales-iq-table-header-cell1 {
    width: 255px;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    padding: 13px 24px 11px;
    text-align: left;
    color: #475467;
  }
  
  .sales-iq-text170 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 93px;
  }
  
  .sales-iq-table-cell10 {
    width: 255px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 24px 24px;
  }
  
  .sales-iq-text171 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 85px;
  }
  
  .sales-iq-table-cell11 {
    width: 247px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 24px 24px;
  }
  
  .sales-iq-text172 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 91px;
  }
  
  .sales-iq-table-cell15,
  .sales-iq-table-cell16,
  .sales-iq-table-cell18 {
    width: 279px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 24px 24px;
  }
  
  .sales-iq-table-cell16,
  .sales-iq-table-cell18 {
    width: 271px;
  }
  
  .sales-iq-table-cell18 {
    width: 239px;
  }
  
  .sales-iq-column,
  .sales-iq-table-header-cell-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-column {
    width: 120px;
    flex-direction: column;
    padding: 0;
    box-sizing: border-box;
    z-index: 2;
    text-align: center;
    font-size: 12px;
    color: #344054;
  }
  
  .sales-iq-table-header-cell-parent {
    flex-direction: row;
    position: relative;
  }
  
  .sales-iq-frame-wrapper45 {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    max-width: 100%;
    color: #101828;
  }
  
  .sales-iq-text180 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 40px;
  }
  
  .sales-iq-help-icon {
    height: 16px;
    width: 16px;
    position: relative;
  }
  
  .sales-iq-table-header-cell2 {
    width: 297px;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 13px 24px 11px;
    font-size: 12px;
  }
  
  .sales-iq-text181 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 101px;
  }
  
  .sales-iq-table-cell20 {
    width: 297px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 24px 24px;
  }
  
  .sales-iq-user-cell {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 87px;
  }
  
  .sales-iq-table-cell21 {
    width: 407px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 23px 24px;
    max-width: 232%;
    flex-shrink: 0;
  }
  
  .sales-iq-user-cell1 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 93px;
  }
  
  .sales-iq-table-cell22 {
    width: 407px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 24px 24px;
    max-width: 232%;
    flex-shrink: 0;
  }
  
  .sales-iq-user-cell2,
  .sales-iq-user-cell3,
  .sales-iq-user-cell4 {
    position: relative;
    line-height: 20px;
    display: inline-block;
    min-width: 64px;
  }
  
  .sales-iq-user-cell3,
  .sales-iq-user-cell4 {
    min-width: 84px;
  }
  
  .sales-iq-user-cell4 {
    min-width: 82px;
  }
  
  .sales-iq-table-cell25 {
    width: 328px;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 26px 24px 24px;
    max-width: 187%;
    flex-shrink: 0;
  }
  
  .sales-iq-user-cell5 {
    display: inline-block;
    min-width: 92px;
  }
  
  .sales-iq-user-cell5,
  .sales-iq-user-cell6,
  .sales-iq-user-cell7 {
    position: relative;
    line-height: 20px;
  }
  
  .sales-iq-user-cell6 {
    display: inline-block;
    min-width: 68px;
  }
  
  .sales-iq-user-cell7 {
    white-space: nowrap;
  }
  
  .sales-iq-column1,
  .sales-iq-table-cell29 {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
  }
  
  .sales-iq-table-cell29 {
    width: 287px;
    border-bottom: 1px solid #eaecf0;
    flex-direction: row;
    align-items: center;
    padding: 26px 24px 24px;
  }
  
  .sales-iq-column1 {
    width: 176px;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    z-index: 1;
  }
  
  .sales-iq-user-header {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 37px;
  }
  
  .sales-iq-table-cell30,
  .sales-iq-table-header-cell3,
  .sales-iq-table-header2 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .sales-iq-table-header2 {
    flex: 1;
    justify-content: flex-start;
    gap: 4px;
  }
  
  .sales-iq-table-cell30,
  .sales-iq-table-header-cell3 {
    border-bottom: 1px solid #eaecf0;
    justify-content: center;
  }
  
  .sales-iq-table-header-cell3 {
    align-self: stretch;
    background-color: #f9fafb;
    padding: 13px 41px 11px;
  }
  
  .sales-iq-table-cell30 {
    padding: 26px 50px 24px;
  }
  
  .sales-iq-column2,
  .sales-iq-dropdown28 {
    width: 21px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-column2 {
    width: 120px;
    align-items: flex-end;
    padding: 0;
    box-sizing: border-box;
    z-index: 1;
    font-size: 12px;
  }
  
  .sales-iq-frame-parent73 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 100%;
    gap: 20px;
    color: #475467;
  }
  
  .sales-iq-page-label {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 59px;
  }
  
  .sales-iq-buttonsbutton29 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 11px;
    gap: 4px;
  }
  
  .sales-iq-button-wrap,
  .sales-iq-pagination-controls {
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-button-wrap {
    align-self: stretch;
    flex-direction: row;
    align-items: center;
  }
  
  .sales-iq-pagination-controls {
    width: 326px;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-start;
    padding: 2px 0 0;
    box-sizing: border-box;
  }
  
  .sales-iq-number11 {
    width: 7px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 7px;
  }
  
  .sales-iq-content24,
  .sales-iq-pagination-number-base {
    flex: 1;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-content24 {
    padding: 10px 16px;
  }
  
  .sales-iq-pagination-number-base {
    background-color: #f9fafb;
    overflow: hidden;
    color: #18222f;
  }
  
  .sales-iq-number12 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 9px;
  }
  
  .sales-iq-content25,
  .sales-iq-pagination-number-base1 {
    flex: 1;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-content25 {
    padding: 10px 15px;
  }
  
  .sales-iq-pagination-number-base1 {
    overflow: hidden;
  }
  
  .sales-iq-number13 {
    width: 10px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 10px;
  }
  
  .sales-iq-content26,
  .sales-iq-pagination-number-base2 {
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-content26 {
    padding: 10px 15px;
  }
  
  .sales-iq-pagination-number-base2 {
    overflow: hidden;
  }
  
  .sales-iq-number14 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 13px;
  }
  
  .sales-iq-content27 {
    flex: 1;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 13px;
  }
  
  .sales-iq-number17 {
    width: 16px;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 16px;
  }
  
  .sales-iq-content30,
  .sales-iq-pagination-numbers {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-content30 {
    border-radius: 8px;
    padding: 10px 12px;
  }
  
  .sales-iq-pagination-numbers {
    width: 292px;
    flex-shrink: 0;
    gap: 2px;
    text-align: center;
    color: #475467;
  }
  
  .sales-iq-page-label1 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 32px;
  }
  
  .sales-iq-button-wrap1,
  .sales-iq-buttonsbutton30 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .sales-iq-buttonsbutton30 {
    width: 84px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: center;
    padding: 6px 11px;
    gap: 4px;
  }
  
  .sales-iq-button-wrap1 {
    align-self: stretch;
    justify-content: flex-end;
  }
  
  .sales-iq-pagination,
  .sales-iq-table {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-pagination {
    align-self: stretch;
    border-top: 1px solid #eaecf0;
    overflow-x: auto;
    flex-direction: row;
    padding: 12px 24px 16px;
    gap: 12px;
    z-index: 3;
    color: #344054;
  }
  
  .sales-iq-table {
    flex: 1;
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow: hidden;
    flex-direction: column;
    max-width: 100%;
    z-index: 1;
  }
  
  .sales-iq-content-items10 {
    align-self: stretch;
    padding: 0 30px 24px 32px;
    max-width: 100%;
  }
  
  .sales-iq-content-items10,
  .sales-iq-content-items11,
  .sales-iq-table-cell40,
  .sales-iq-table-cell41,
  .sales-iq-table-cell42,
  .sales-iq-table-header-cell4 {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-content-items11 {
    align-self: stretch;
    padding: 0 30px 0 32px;
    max-width: 100%;
    font-size: 26px;
  }
  
  .sales-iq-table-cell40,
  .sales-iq-table-cell41,
  .sales-iq-table-cell42,
  .sales-iq-table-header-cell4 {
    position: absolute;
    left: 0;
    border-bottom: 1px solid #eaecf0;
    gap: 12px;
  }
  
  .sales-iq-table-header-cell4 {
    top: 0;
    background-color: #f9fafb;
    width: 100%;
    padding: 12px 24px 10px;
    max-width: 100%;
    font-size: 12px;
    color: #475467;
  }
  
  .sales-iq-table-cell40,
  .sales-iq-table-cell41,
  .sales-iq-table-cell42 {
    top: 44px;
    width: 245px;
    overflow-x: auto;
    padding: 16px 24px 14px;
  }
  
  .sales-iq-table-cell41,
  .sales-iq-table-cell42 {
    top: 116px;
    width: 252px;
  }
  
  .sales-iq-table-cell42 {
    top: 188px;
  }
  
  .sales-iq-table-cell43 {
    top: 260px;
    width: 252px;
  }
  
  .sales-iq-table-cell43,
  .sales-iq-table-cell44,
  .sales-iq-table-cell45 {
    position: absolute;
    left: 0;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .sales-iq-table-cell44 {
    top: 332px;
    width: 311px;
    text-align: center;
    font-size: 16px;
    color: #667085;
  }
  
  .sales-iq-table-cell45 {
    top: 404px;
    width: 260px;
  }
  
  .sales-iq-table-cell46 {
    top: 476px;
    box-sizing: border-box;
    width: 252px;
  }
  
  .sales-iq-table-cell46,
  .sales-iq-table-cell47,
  .sales-iq-table-cell48,
  .sales-iq-table-cell49 {
    position: absolute;
    left: 0;
    border-bottom: 1px solid #eaecf0;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 16px 24px 14px;
    gap: 12px;
  }
  
  .sales-iq-table-cell47 {
    top: 548px;
    box-sizing: border-box;
    width: 242px;
    text-align: center;
    font-size: 16px;
    color: #667085;
  }
  
  .sales-iq-table-cell48,
  .sales-iq-table-cell49 {
    top: 620px;
  }
  
  .sales-iq-table-cell49 {
    top: 692px;
    box-sizing: border-box;
    width: 242px;
    z-index: 1;
  }
  
  .sales-iq-text211 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 55px;
  }
  
  .sales-iq-table-header-cell5 {
    width: 441px;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 13px 24px 11px;
    max-width: 286%;
    flex-shrink: 0;
    font-size: 12px;
    color: #475467;
  }
  
  .sales-iq-background {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    background-color: #f5f5f5;
    width: 100%;
    height: 100%;
  }
  
  .sales-iq-f4b711e503bc72fe396e5043e0c2-1-icon3 {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 24px;
    height: 24px;
    object-fit: cover;
    z-index: 1;
  }
  
  .sales-iq-background-parent {
    height: 40px;
    width: 40px;
    position: relative;
  }
  
  .sales-iq-supporting-text70 {
    position: relative;
    line-height: 20px;
    color: #475467;
    display: inline-block;
    min-width: 101px;
    white-space: nowrap;
  }
  
  .sales-iq-table-cell50,
  .sales-iq-table-cell51 {
    border-bottom: 1px solid #eaecf0;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 228px 14px 24px;
    gap: 12px;
    max-width: 263%;
    flex-shrink: 0;
  }
  
  .sales-iq-table-cell51 {
    padding: 16px 290px 14px 24px;
    max-width: 303%;
  }
  
  .sales-iq-progress-label {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 98px;
  }
  
  .sales-iq-table-cell52 {
    border-bottom: 1px solid #eaecf0;
    flex-direction: row;
    align-items: center;
    padding: 16px 251px 14px 24px;
    gap: 12px;
    max-width: 278%;
    flex-shrink: 0;
  }
  
  .sales-iq-column3,
  .sales-iq-table-cell52,
  .sales-iq-table-cell53 {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
  }
  
  .sales-iq-table-cell53 {
    border-bottom: 1px solid #eaecf0;
    flex-direction: row;
    align-items: center;
    padding: 16px 234px 14px 24px;
    gap: 12px;
    max-width: 267%;
    flex-shrink: 0;
  }
  
  .sales-iq-column3 {
    position: absolute;
    top: 0;
    left: 264.5px;
    width: 154.5px;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    z-index: 1;
  }
  
  .sales-iq-table-header-cell-group {
    height: 764px;
    width: 441px;
    position: relative;
    max-width: 100%;
  }
  
  .sales-iq-marker-pin-02-group,
  .sales-iq-table-cell65 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .sales-iq-marker-pin-02-group {
    gap: 7px;
  }
  
  .sales-iq-table-cell65 {
    align-self: stretch;
    border-bottom: 1px solid #eaecf0;
    padding: 26px 24px 24px;
  }
  
  .sales-iq-table-header-cell-container {
    width: 279px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-column4 {
    align-self: stretch;
    display: flex;
    padding: 0;
    z-index: 1;
  }
  
  .sales-iq-column-wrapper,
  .sales-iq-column4,
  .sales-iq-column5 {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  
  .sales-iq-column-wrapper {
    width: 66px;
    display: flex;
    padding: 0 22px 0 0;
    box-sizing: border-box;
    text-align: center;
    font-size: 12px;
    color: #344054;
  }
  
  .sales-iq-column5 {
    width: 176px;
    padding: 0;
    min-width: 176px;
  }
  
  .sales-iq-column5,
  .sales-iq-column6,
  .sales-iq-table-header-cell8 {
    display: flex;
    box-sizing: border-box;
  }
  
  .sales-iq-table-header-cell8 {
    width: 120px;
    background-color: #f9fafb;
    border-bottom: 1px solid #eaecf0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 13px 41px 11px;
  }
  
  .sales-iq-column6 {
    width: 79px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 0;
    min-width: 79px;
    font-size: 12px;
  }
  
  .sales-iq-column-parent,
  .sales-iq-frame-parent74,
  .sales-iq-pagination1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  
  .sales-iq-column-parent {
    width: 333px;
    justify-content: flex-start;
    gap: 78px;
    max-width: 100%;
    z-index: 1;
    color: #475467;
  }
  
  .sales-iq-frame-parent74,
  .sales-iq-pagination1 {
    align-self: stretch;
  }
  
  .sales-iq-frame-parent74 {
    justify-content: space-between;
    max-width: 100%;
    gap: 20px;
  }
  
  .sales-iq-pagination1 {
    border-top: 1px solid #eaecf0;
    overflow-x: auto;
    justify-content: flex-start;
    padding: 12px 24px 16px;
    gap: 12px;
    z-index: 2;
    color: #344054;
  }
  
  .sales-iq-text227 {
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 73px;
  }
  
  .sales-iq-badge50,
  .sales-iq-text-and-badge4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .sales-iq-badge50 {
    width: 89px;
    border-radius: 9999px;
    background-color: #f9f5ff;
    border: 1px solid #e9d7fe;
    box-sizing: border-box;
    padding: 0 7px;
    white-space: nowrap;
    text-align: center;
    font-size: 12px;
    color: #6941c6;
  }
  
  .sales-iq-text-and-badge4 {
    align-self: stretch;
    padding: 0 332px 0 0;
    gap: 7px;
  }
  
  .sales-iq-content-items14,
  .sales-iq-text-and-supporting-text16 {
    display: flex;
    align-items: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-text-and-supporting-text16 {
    flex: 1;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    min-width: 436px;
  }
  
  .sales-iq-content-items14 {
    align-self: stretch;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 29px 0 33px;
    box-sizing: border-box;
    font-size: 26px;
  }
  
  .sales-iq-text232 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 53px;
  }
  
  .sales-iq-button-group-base27,
  .sales-iq-button-group-base28 {
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
  }
  
  .sales-iq-button-group-base27 {
    width: 85px;
    background-color: #f9fafb;
    flex-shrink: 0;
    white-space: nowrap;
  }
  
  .sales-iq-button-group-base28 {
    background-color: #fff;
  }
  
  .sales-iq-text240 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 89px;
  }
  
  .sales-iq-filter-bar-container {
    align-self: stretch;
    flex-direction: row;
    padding: 0 29px 0 33px;
    box-sizing: border-box;
    color: #344054;
  }
  
  .sales-iq-filter-bar-container,
  .sales-iq-frame-parent,
  .sales-iq-all-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  
  .sales-iq-frame-parent {
    flex: 1;
    flex-direction: column;
    min-width: 701px;
    font-size: 14px;
  }
  
  .sales-iq-all-content {
    align-self: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    /* padding: 0 0 67px; */
    box-sizing: border-box;
    row-gap: 20px;
  }
  
  .sales-iq-featured-icon {
    width: 48px;
    height: 48px;
    position: relative;
    border-radius: 10px;
  }
  
  .sales-iq-supporting-text82 {
    align-self: stretch;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
  }
  
  .sales-iq-text-and-supporting-text17 {
    width: 352px;
    flex-direction: column;
    justify-content: flex-start;
    gap: 4px;
    max-width: 352px;
  }
  
  .sales-iq-buttonsbutton33,
  .sales-iq-content45,
  .sales-iq-text-and-supporting-text17 {
    display: flex;
    align-items: center;
  }
  
  .sales-iq-content45 {
    align-self: stretch;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 20px;
    gap: 16px;
  }
  
  .sales-iq-buttonsbutton33 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    overflow: hidden;
    flex-direction: row;
    justify-content: center;
    padding: 8px 15px;
    gap: 6px;
  }
  
  .sales-iq-text245 {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    min-width: 91px;
  }
  
  .sales-iq-actions11,
  .sales-iq-buttonsbutton34 {
    display: flex;
    flex-direction: row;
  }
  
  .sales-iq-buttonsbutton34 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #7e56d9;
    border: 1px solid #7e56d9;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: 8px 15px;
    gap: 6px;
    color: #fff;
  }
  
  .sales-iq-actions11 {
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    text-align: left;
    color: #344054;
  }
  
  .sales-iq-empty-state,
  .sales-iq-empty-state1 {
    flex: 1;
    display: flex;
    align-items: center;
    max-width: 100%;
  }
  
  .sales-iq-empty-state1 {
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
  }
  
  .sales-iq-empty-state {
    flex-direction: row;
    justify-content: center;
    z-index: 1;
  }
  
  .sales-iq-empty-state-wrapper,
  .sales-iq-all {
    display: flex;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .sales-iq-empty-state-wrapper {
    width: 1074px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 29px;
    text-align: center;
  }
  
  .sales-iq-all {
    align-self: stretch;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    /* padding: 0 0 245px; */
  }
  
  .sales-iq-bifocal-optometry-measurement-icon {
    width: 391px;
    position: relative;
    max-height: 100%;
    object-fit: cover;
    max-width: 100%;
  }
  
  .sales-iq-title8 {
    align-self: stretch;
    position: relative;
    line-height: 30px;
    font-weight: 600;
  }
  
  .sales-iq-content46,
  .sales-iq-text-and-supporting-text18 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  .sales-iq-text-and-supporting-text18 {
    width: 352px;
    gap: 8px;
    max-width: 352px;
  }
  
  .sales-iq-content46 {
    align-self: stretch;
    padding: 0 20px;
    box-sizing: border-box;
    gap: 20px;
    max-width: 100%;
  }
  
  .sales-iq-add,
  .sales-iq-learn-more {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    min-width: 98px;
  }
  
  .sales-iq-learn-more {
    min-width: 88px;
  }
  
  .sales-iq-actions12,
  .sales-iq-empty-state3 {
    display: flex;
    justify-content: flex-start;
  }
  
  .sales-iq-actions12 {
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    text-align: left;
    font-size: 16px;
    color: #344054;
  }
  
  .sales-iq-empty-state3 {
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    max-width: 100%;
  }
  
  .sales-iq-empty-state-container,
  .sales-iq-empty-state2 {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    max-width: 100%;
  }
  
  .sales-iq-empty-state2 {
    flex: 1;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    padding: 41.5px 0;
    z-index: 1;
  }
  
  .sales-iq-empty-state-container {
    width: 1076px;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 0 30px 124px;
    text-align: center;
    font-size: 20px;
  }
  
  
  
  
  
  
  
  
  
  .sales-iq-sales-iq-frame-layout {
    max-width: 1524px;
    margin: 0 auto;
    position: relative;
    align-items: flex-end;
    padding: 7px 0 0;
    box-sizing: border-box;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-size: 16px;
    color: #101828;
    font-family: Inter;
  
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  
    background-color: #fff;
    
  
    align-self: stretch;
    height: max-content;
  }
  
  .sales-iq-buttonsbutton5a {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 15px;
    background-color: #7e56d9;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    gap: 6px;
    position: relative;
  }
  
  .sales-iq-buttonsbutton5a:hover{
    background-color: #6941c6;
  }
  
  .sales-iq-search-lg-icon1 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .sales-iq-text-padding15 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  
  .sales-iq-text74 {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 91px;
  }
  
  .sales-iq-dropdown-wrapper {
    position: absolute; 
    z-index: 3;
    right: 35px;
    top: 800px;
  }
  
  @media screen and (max-width: 1275px) {
  
    .sales-iq-all,
    .sales-iq-all-content {
      padding-bottom: 20px;
      box-sizing: border-box;
    }
  
    .sales-iq-all {
      padding-bottom: 67px;
    }
  
    .sales-iq-container3 {
      max-width: 100%;
    }
  }
  
  @media screen and (max-width: 1100px) {
    .sales-iq-content13 {
      gap: 35px;
      flex-wrap: wrap;
    }
  
    .sales-iq-main {
      height: auto;
      padding-top: 21px;
      padding-bottom: 21px;
      box-sizing: border-box;
    }
  
    .sales-iq-content-elements-parent,
    .sales-iq-content17,
    .sales-iq-frame-group,
    .sales-iq-rectangle-parent10,
    .sales-iq-rectangle-parent5,
    .sales-iq-rectangle-parent7 {
      flex-wrap: wrap;
    }
  
    .sales-iq-rectangle-parent11 {
      gap: 31px;
      flex-wrap: wrap;
    }
  
    .sales-iq-frame-wrapper31 {
      flex: 1;
    }
  
    .sales-iq-rectangle-parent13 {
      flex-wrap: wrap;
    }
  
    .sales-iq-list-item-parent {
      flex: 1;
    }
  
    .sales-iq-card-list,
    .sales-iq-content20,
    .sales-iq-content21,
    .sales-iq-metric-item-parent {
      flex-wrap: wrap;
    }
  
    .sales-iq-text-and-badge1 {
      flex-wrap: wrap;
      padding-right: 364px;
      box-sizing: border-box;
    }
  
    .sales-iq-content23,
    .sales-iq-frame-parent73,
    .sales-iq-frame-parent74 {
      flex-wrap: wrap;
    }
  
    .sales-iq-all {
      padding-bottom: 44px;
      box-sizing: border-box;
    }
  
    .sales-iq-empty-state-container {
      padding-bottom: 81px;
      box-sizing: border-box;
    }
  }
  
  @media screen and (max-width: 750px) {
  
  
    .sales-iq-heading {
      font-size: 24px;
      line-height: 30px;
    }
  
    .sales-iq-content13 {
      gap: 17px;
    }
  
    .sales-iq-tabs-and-filters {
      flex-wrap: wrap;
    }
  
    .sales-iq-jul {
      width: 100%;
      height: 17px;
    }
  
    .sales-iq-x-axis {
      flex-wrap: wrap;
    }
  
    .sales-iq-number {
      font-size: 24px;
      line-height: 30px;
    }
  
    .sales-iq-main {
      gap: 16px;
    }
  
    .sales-iq-number3,
    .sales-iq-number4 {
      font-size: 29px;
      line-height: 35px;
    }
  
    .sales-iq-dropdowns {
      padding-right: 83px;
      box-sizing: border-box;
      min-width: 100%;
      flex: 1;
    }
  
    .sales-iq-badge-elements,
    .sales-iq-card-elements,
    .sales-iq-dropdowns,
    .sales-iq-location-badge-number {
      flex-wrap: wrap;
    }
  
    .sales-iq-action-fields {
      min-width: 100%;
    }
  
    .sales-iq-content-actions {
      flex-wrap: wrap;
      min-width: 100%;
    }
  
    .sales-iq-frame-parent2,
    .sales-iq-primary-table {
      flex-wrap: wrap;
    }
  
    .sales-iq-frame-parent1 {
      flex-wrap: wrap;
      min-width: 100%;
    }
  
    .sales-iq-frame-parent17,
    .sales-iq-frame-parent18,
    .sales-iq-frame-parent21 {
      flex-wrap: wrap;
    }
  
    .sales-iq-element-details,
    .sales-iq-f4b711e503bc72fe396e5043e0c2-1-icon {
      flex: 1;
    }
  
    .sales-iq-action-icons,
    .sales-iq-element-details-parent {
      flex-wrap: wrap;
    }
  
    .sales-iq-icon-button {
      gap: 17px;
    }
  
    .sales-iq-icon-button,
    .sales-iq-sidebar1 {
      flex-wrap: wrap;
    }
  
    .sales-iq-frame-parent33 {
      flex-wrap: wrap;
      min-width: 100%;
    }
  
    .sales-iq-rectangle-parent11 {
      gap: 16px;
    }
  
    .sales-iq-frame-parent41 {
      gap: 17px;
      flex-wrap: wrap;
    }
  
    .sales-iq-frame-parent39 {
      min-width: 100%;
    }
  
    .sales-iq-frame-wrapper31 {
      margin-left: 0;
    }
  
    .sales-iq-card-row {
      min-width: 100%;
    }
  
    .sales-iq-list-item-parent {
      margin-left: 0;
    }
  
    .sales-iq-buttonsbutton-parent1 {
      flex-wrap: wrap;
    }
  
    .sales-iq-text-and-badge {
      flex-wrap: wrap;
      padding-right: 121px;
      box-sizing: border-box;
    }
  
    .sales-iq-text-and-supporting-text2 {
      min-width: 100%;
    }
  
    .sales-iq-actions4 {
      flex-wrap: wrap;
    }
  
    .sales-iq-text-and-badge1 {
      padding-right: 182px;
      box-sizing: border-box;
    }
  
    .sales-iq-text-and-supporting-text3 {
      min-width: 100%;
    }
  
    .sales-iq-actions5,
    .sales-iq-table-cell51,
    .sales-iq-table-cell52 {
      flex-wrap: wrap;
    }
  
    .sales-iq-text-and-badge4 {
      flex-wrap: wrap;
      padding-right: 166px;
      box-sizing: border-box;
    }
  
    .sales-iq-frame-parent,
    .sales-iq-text-and-supporting-text16 {
      min-width: 100%;
    }
  
    .sales-iq-empty-state3 {
      gap: 16px;
    }
  
    .sales-iq-empty-state2 {
      padding-top: 27px;
      padding-bottom: 27px;
      box-sizing: border-box;
    }
  
    .sales-iq-container3 {
      gap: 16px;
    }
  
  }
  
  @media screen and (max-width: 450px) {
  
    .sales-iq-account {
      gap: 23px;
      padding-right: 20px;
      box-sizing: border-box;
    }
  
    .sales-iq-heading {
      font-size: 18px;
      line-height: 23px;
    }
  
    .sales-iq-actions {
      flex-wrap: wrap;
    }
  
    .sales-iq-number {
      font-size: 18px;
      line-height: 23px;
    }
  
    .sales-iq-number-and-chart {
      flex-wrap: wrap;
    }
  
    .sales-iq-main {
      padding-top: 20px;
      padding-bottom: 20px;
      box-sizing: border-box;
    }
  
    .sales-iq-text46 {
      font-size: 21px;
      line-height: 30px;
    }
  
    .sales-iq-number3,
    .sales-iq-number4 {
      font-size: 22px;
      line-height: 26px;
    }
  
    .sales-iq-dropdowns {
      padding-right: 20px;
      box-sizing: border-box;
    }
  
    .sales-iq-price {
      font-size: 16px;
      line-height: 26px;
    }
  
    .sales-iq-buttonsbutton-parent {
      flex-wrap: wrap;
    }
  
    .sales-iq-date-elements-parent {
      flex: 1;
    }
  
    .sales-iq-badge-container,
    .sales-iq-frame-parent12 {
      flex-wrap: wrap;
    }
  
    .sales-iq-badge-group {
      height: auto;
      min-height: 104;
    }
  
    .sales-iq-price2 {
      font-size: 16px;
      line-height: 26px;
    }
  
    .sales-iq-work-type-icon {
      flex: 1;
    }
  
    .sales-iq-calendar-icon6 {
      flex-wrap: wrap;
    }
  
    .sales-iq-work-type-label {
      margin-left: 0;
    }
  
    .sales-iq-price4 {
      font-size: 16px;
      line-height: 26px;
    }
  
    .sales-iq-frame-parent36,
    .sales-iq-frame-parent42 {
      flex-wrap: wrap;
    }
  
    .sales-iq-price5,
    .sales-iq-price6,
    .sales-iq-price7 {
      font-size: 16px;
      line-height: 26px;
    }
  
    .sales-iq-frame-parent64 {
      flex-wrap: wrap;
    }
  
    .sales-iq-text147 {
      font-size: 21px;
      line-height: 22px;
    }
  
    .sales-iq-content22,
    .sales-iq-text-and-badge {
      padding-right: 20px;
      box-sizing: border-box;
    }
  
    .sales-iq-input-dropdown3 {
      max-width: 100%;
    }
  
    .sales-iq-table-cell50,
    .sales-iq-table-cell53 {
      flex-wrap: wrap;
    }
  
    .sales-iq-table-header-cell-group {
      height: auto;
      min-height: 764;
    }
  
    .sales-iq-column5,
    .sales-iq-column6 {
      flex: 1;
    }
  
    .sales-iq-column-parent {
      gap: 39px;
      flex-wrap: wrap;
    }
  
    .sales-iq-text-and-badge4 {
      padding-right: 20px;
      box-sizing: border-box;
    }
  
    .sales-iq-all {
      padding-bottom: 29px;
      box-sizing: border-box;
    }
  
    .sales-iq-title8 {
      font-size: 16px;
      line-height: 24px;
    }
  
    .sales-iq-empty-state-container {
      padding-bottom: 53px;
      box-sizing: border-box;
    }
  
  }