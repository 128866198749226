.progress-step-step-one {
    align-self: stretch;
    flex-direction: column;
   width: 85%;
    height: 374px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}

.progress-step-form-header {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 13px 0 0;
    gap: 5px;
}

.progress-step-form-title {
    flex: 1;
    position: relative;
    line-height: 24px;
    font-weight: 500;
    z-index: 1;
}

.progress-step-arrow-narrow-left-icon {
    height: 24px;
    width: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 24px;
    z-index: 1;
    display: none;
}

.progress-step-steps {
    align-self: stretch;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;

    gap: 38px;
    z-index: 1;
    font-size: 14px;
    color: #344054;
}

.progress-step-step-base1
{
  align-self: stretch;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  align-items: flex-end;
  padding: 0 0 4px;
}

.progress-step-step-icon
 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  flex-direction: row;
  gap: 12px;
}

.progress-step-step-icon-base1 {
    height: 24px;
    width: 24px;
    position: relative;
    border-radius: 9999px;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 24px;
}

.progress-step-text-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex: 1;
    flex-direction: column;
    padding: 2px 0 0;
}

.progress-step-step-three-title {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 600;
}

.progress-step-step-supporting
{
  align-self: stretch;
  flex: 1;
  display: flex;
  justify-content: flex-start;

  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 0 11px;
  gap: 23px;
  margin-top: -2px;
  font-size: 12px;
  color: #475467;
}

.progress-step-step-connector {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 6px 0 0;
    
}

.progress-step-connector {
    width: 2px;
    flex: 1;
    position: relative;
    border-radius: 2px;
    background-color: #eaecf0;
}

.progress-step-connector4 {
    width: 2px;
    flex: 1;
    position: relative;
    border-radius: 2px;
    background-color: #7e56d9;
}

.progress-step-supporting-text1 {
    flex: 1;
    position: relative;
    line-height: 20px;
}

.progress-step-connector-wrap,
.progress-step-connector-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.progress-step-connector-wrapper {
  flex: 1;
  flex-direction: row;
  padding: 0 11px;
}
.progress-step-connector-wrap {
  align-self: stretch;
  flex-direction: column;
  padding: 0 0 4px;
  gap: 4px;
}

.progress-step-step-icon-base {
    width: 24px;
    height: 24px;
    position: relative;
    border-radius: 9999px;
    overflow: hidden;
    flex-shrink: 0;
}

.progress-step-text-and-supporting-text {
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  

    flex-direction: column;
    padding: 2px 0 24px;
}


 @media screen and (max-width: 450px) {
   
    .progress-step-steps {
      gap: 19px;
    }
}