/* You can also animate the child elements, like icons or text */

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.sidebar-animate {
    transition: width 0.3s ease-in-out;
    /* Smooth transition only when toggled */
}

/* Optional: Add a keyframe for more complex animations (like slide in/out) */
@keyframes slideIn {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

/* Use slide animations if desired */
.sidebar-sidebar-navigation {
    animation: slideIn 0.3s ease-in-out;
}

.sidebar-sidebar-navigation-disabled {
    animation: slideOut 0.3s ease-in-out;
}

.sidebar-sidebar-navigation {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.sidebar-sidebar-navigation {
    height: 100vh;
    /* width: 362px; */
    background-color: #fff;
    border-right: 1px solid #eaecf0;
    box-sizing: border-box;
    overflow: hidden;
    /* flex-shrink: 0; */
    max-width: 100%;
    z-index: 1;
    position: sticky;
    top: 0;
}

.sidebar-sidebar-navigation-disabled {
    width: max-content !important;
}

.sidebar-content4 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.sidebar-content4 {
    align-self: stretch;
    border-right: 1px solid #eaecf0;
    justify-content: space-between;
    /* height: 100vh; */
}

.sidebar-navigation2 {
    padding: 0 16px;
    gap: 8px;
}

.sidebar-navigation1,
.sidebar-navigation2,
.sidebar-navigation3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.sidebar-navigation1 {
    padding: 32px 0 0;
}

.sidebar-navigation1-padding {
    padding: 9px 0 0 !important;
}

.sidebar-nav-item-button2,
.sidebar-nav-item-button3 {
    border-radius: 6px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px;
}

.sidebar-nav-item-button2:hover {
    background-color: #f9fafb;
}

.sidebar-nav-item-button3 {
    background-color: #f9fafb;
}

.sidebar-home-line-icon {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.sidebar-home-line-icon {
    height: 24px;
    width: 24px;
    flex-shrink: 0;
}





.sidebar-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.sidebar-footer {
    justify-content: flex-start;
    padding: 0 16px 24px;
    gap: 24px;
}

.sidebar-navigation3 {
    gap: 8px;
}

.sidebar-avatar1 {
    width: 48px;
    height: 48px;
    position: relative;
    border-radius: 9999px;
    background-image: url(../../icons/Gerator/dashboard/user-placeholder2.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    cursor: pointer;
}

.sidebar-contrast-border1 {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 9999px;
    border: 0.8px solid rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    overflow: hidden;
}

.sidebar-sub-nav {
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar-sub-nav {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
}

.sidebar-sub-nav-title {
    flex: 1;
    position: relative;
    line-height: 24px;
    font-weight: 500;
    width: 220px;
}

.sidebar-sub-nav-header {
    /* align-self: stretch; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 13px 0 0;
    gap: 5px;
}

.sidebar-content5 {
    display: flex;
    justify-content: flex-start;
}

.sidebar-content5 {
    padding: 36px 16px 0;
    gap: 16px;
}

.sidebar-content5 {
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
}

.sidebar-arrow-narrow-left-icon {
    height: 24px;
    width: 24px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 24px;
}

.sidebar-navigation4 {
    align-self: stretch;
    flex-direction: column;
    align-items: flex-start;
}

.sidebar-navigation4 {
    gap: 4px;
    color: #344054;
}

.sidebar-navigation4 {
    display: flex;
    justify-content: flex-start;
}

.sidebar-nav-item-base3 {
    align-self: stretch;
    border-radius: 6px;
    background-color: #f9fafb;
    overflow: hidden;
    padding: 8px 12px;
    gap: 8px;
    color: #18222f;
}

.sidebar-content6,
.sidebar-nav-item-base3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}


.sidebar-content6 {
    flex: 1;
    /* padding: 0 144px 0 0; */
    gap: 12px;
}

.sidebar-dot-icon {
    height: 10px;
    width: 10px;
    position: relative;
    display: none;
}

.sidebar-text6 {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    min-width: 46px;
}

.sidebar-badge1 {
    height: 22px;
    border-radius: 9999px;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: none;
    padding: 2px 7px;
    text-align: center;
    font-size: 12px;
    color: #344054;
}

.sidebar-badge1 {
    background-color: #f9fafb;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.sidebar-text1 {
    align-self: stretch;
    position: relative;
    line-height: 18px;
    font-weight: 500;
}

.sidebar-chevron-down-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}



.sidebar-nav-item-base4 {
    align-self: stretch;
    border-radius: 6px;
    background-color: #fff;
    overflow: hidden;
    padding: 8px 12px;
    gap: 8px;
}

.sidebar-nav-item-base4:hover {
    background-color: #f9fafb;
}

.sidebar-content7 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* padding: 0 103px 0 0; */
    gap: 12px;
}

.sidebar-text8 {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    min-width: 49px;
}

.sidebar-badge4 {
    width: 30px;
    border-radius: 9999px;
    background-color: #f9fafb;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    padding: 0 7px;
    text-align: center;
    font-size: 12px;
}



.sidebar-badge4,
.sidebar-content8,
.sidebar-nav-item-base4 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.sidebar-badge-count {
    width: 14px;
    position: relative;
    line-height: 18px;
    font-weight: 500;
    display: inline-block;
    min-width: 14px;
}

.sidebar-content8 {
    flex: 1;
    /* padding: 0 141px 0 0; */
    gap: 12px;
}

.sidebar-content12 {
    display: flex;
    justify-content: flex-start;
}

.sidebar-content12 {
    flex: 1;
    flex-direction: row;
    align-items: center;
    /* padding: 0 142px 0 0; */
    gap: 12px;
}

.sidebar-text17 {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    min-width: 48px;
}

.sidebar-account {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
}

.sidebar-account {
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 48px 24px 20px;
    position: relative;
    gap: 47px;
    font-size: 14px;
}

.sidebar-price-parent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    flex-direction: column;
    cursor: pointer;
    max-width:178px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sidebar-title,
.sidebar-supporting-text {
    align-self: stretch;
    /* position: relative; */
    line-height: 20px;
    font-weight: 600;
}

.sidebar-supporting-text {
    color: #475467;
    white-space: nowrap;
}

.sidebar-buttonsbutton {
    margin: 0 !important;
    position: absolute;
    top: -8px;
    right: 12px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px;
}

.sidebar-search-lg-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}

.sidebar-badge {
    height: 22px;
    border-radius: 9999px;
    background-color: #f9fafb;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 7px;
    text-align: center;
    font-size: 12px;
}

/* active class css  */
.sidebar-active-tab {
    background-color: #f3f4f6; /* Lighter version of #7e56d9 */
    border-radius: 8px; /* Optional for rounded corners */
}




@media screen and (max-width: 450px) {
    .sidebar-account {
        gap: 23px;
        padding-right: 20px;
        box-sizing: border-box;
    }
}