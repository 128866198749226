@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.reset-password-set-password-gerator-04-1-icon2 {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}

.reset-password-set-password-logo-container,
.reset-password-set-password-logo2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.reset-password-set-password-logo2 {
  align-self: stretch;
  height: 32px;
  flex-direction: row;
  background-image: url('../../icons/Gerator/gerator04-1@2x.png');
  /* background-image: url('../../../public/gerator04-1@2x.png'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.reset-password-set-password-logo-container {
  width: 121px;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
}

.reset-password-set-password-placeholder-icon15 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.reset-password-set-password-text8 {
  text-decoration: none;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  min-width: 47px;
  white-space: nowrap;
}

.reset-password-set-password-buttonsbutton9,
.reset-password-set-password-text-padding6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.reset-password-set-password-buttonsbutton9 {
  cursor: pointer;
  border: 0;
  padding: 10px 16px;
  background-color: transparent;
  border-radius: 8px;
  overflow: hidden;
  gap: 6px;
}

.reset-password-set-password-text9 {
  text-decoration: none;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 59px;
  white-space: nowrap;
}

.reset-password-set-password-buttonsbutton10,
.reset-password-set-password-navigation-actions2 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.reset-password-set-password-buttonsbutton10 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 15px;
  background-color: #7e56d9;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  justify-content: center;
  gap: 6px;
}

.reset-password-set-password-reset-password-new-password-hamburger-menu {
  display: none;
}

.reset-password-set-password-navigation-actions2 {
  width: 190px;
  justify-content: flex-start;
  gap: 12px;
}

.reset-password-set-password-frame-group{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.reset-password-set-password-frame-group {
  flex: 1;
  justify-content: space-between;
  gap: 20px;
}

.reset-password-set-password-reset-password-set-password-inner {
  width: 100%;
  justify-content: flex-start;
  padding: 0 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.reset-password-set-password-featured-icon2 {
  width: 56px;
  height: 56px;
  position: relative;
  border-radius: 12px;
}

.reset-password-set-password-supporting-text2,
.reset-password-set-password-title1 {
  align-self: stretch;
  position: relative;
}

.reset-password-set-password-title1 {
  margin: 0;
  font-size: inherit;
  line-height: 38px;
  font-weight: 600;
  font-family: inherit;
}

.reset-password-set-password-supporting-text2 {
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.reset-password-set-password-header2,
.reset-password-set-password-text-and-supporting-text2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.reset-password-set-password-header2 {
  align-items: center;
  gap: 24px;
}

.reset-password-set-password-label1 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 66px;
}

.reset-password-set-password-content6 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Inter;
  font-size: 16px;
  color: #667085;
  min-width: 199px;
  max-width: 100%;
}

.reset-password-set-password-help-icon1 {
  height: 16px;
  width: 16px;
  position: relative;
  display: none;
}

.reset-password-set-password-input1 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 13px;
  gap: 8px;
  max-width: 100%;
}

.reset-password-set-password-hint-text1 {
  width: 320px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: none;
}

.reset-password-set-password-input-field1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  max-width: 100%;
}

.reset-password-set-password-label2 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 124px;
}

.reset-password-set-password-check-icon {
  cursor: not-allowed;
  margin: 0;
  /* height: 20px;
  width: 20px; */
  position: relative;
  border-radius: 9999px;
  background-color: #d0d5dd;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 20px;
  
  
}



.reset-password-set-password-hint-text3 {
  flex: 1;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  color: #475467;
  text-align: left;
  display: inline-block;
  max-width: calc(100% - 28px);
}

.reset-password-set-password-check-and-text,
.reset-password-set-password-checks,
.reset-password-set-password-form1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  max-width: 100%;
}

.reset-password-set-password-checks,
.reset-password-set-password-form1 {
  flex-direction: column;
  gap: 12px;
}

.reset-password-set-password-form1 {
  gap: 20px;
}

.reset-password-set-password-text10 {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 124px;
}

.reset-password-set-password-buttonsbutton11,
.reset-password-set-password-content5 {
  align-self: stretch;
  display: flex;
  align-items: center;
}

.reset-password-set-password-buttonsbutton11 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 20px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  flex-direction: row;
  justify-content: center;
  gap: 6px;
}

.reset-password-set-password-content5 {
  margin: 0;
  border-radius: 12px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  max-width: 100%;
}




.reset-password-set-password-container4,
.reset-password-set-password-container5,
.reset-password-set-password-content4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.reset-password-set-password-content4 {
  width: 360px;
  gap: 32px;
  max-width: 360px;
}

.reset-password-set-password-container4,
.reset-password-set-password-container5 {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  max-width: 1280px;
}

.reset-password-set-password-container4 {
  padding: 0 32px;
  text-align: center;
  font-size: 30px;
  color: #101828;
  font-family: Inter;
}

.reset-password-set-password-help-link1 {
  bottom: 32px;
  left: 32px;
  line-height: 20px;
}

.reset-password-set-password-help-link1,
.reset-password-set-password-helpcentergeratorcom2,
.reset-password-set-password-mail-01-icon2 {
  position: absolute;
  margin: 0 !important;
  z-index: 1;
}

.reset-password-set-password-mail-01-icon2 {
  width: 16px;
  height: 16px;
  right: 204px;
  bottom: 34px;
  overflow: hidden;
  flex-shrink: 0;
}

.reset-password-set-password-helpcentergeratorcom2 {
  right: 31.5px;
  bottom: 32px;
  line-height: 20px;
  white-space: nowrap;
}

.reset-password-set-password-log-in2,
.reset-password-set-password-reset-password-set-password {
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
}

.reset-password-set-password-log-in2 {
  align-self: stretch;
  align-items: center;
  padding: 0 100px;
  gap: 32px;
  height: max-content;
  max-width: 100%;
  text-align: left;
  font-size: 14px;
  color: #475467;
  font-family: Inter;
}

.reset-password-set-password-footer{
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  align-self: stretch;
  align-items: center;
  width: 100%;
  gap: 32px;
  height: 10%;
  text-align: left;
  font-size: 14px;
  color: #475467;
  font-family: Inter;
}

.reset-password-set-password-reset-password-set-password {
  width: 100%;
  overflow: hidden;
  align-items: flex-start;
  padding: 18px 0 0;
  gap: 98px;
  line-height: normal;
  letter-spacing: normal;
  height: max-content;
}

.reset-password-set-password-error{
  left: 2px;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-family: Inter;
  text-align: left;
}

@media screen and (max-width: 1275px) {

  .reset-password-set-password-container4,
  .reset-password-set-password-container5 {
    max-width: 100%;
  }
}

@media screen and (max-width: 1100px) {
  .reset-password-set-password-log-in2 {
    padding-top: 62px;
    padding-bottom: 386px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 750px) {
  .reset-password-set-password-title1 {
    font-size: 24px;
    line-height: 30px;
  }

  .reset-password-set-password-container4 {
    gap: 16px;
  }

  .reset-password-set-password-log-in2 {
    gap: 16px;
    padding: 40px 40px 127px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {

  .reset-password-set-password-navigation-actions2 {
    display: none;
  }

  .reset-password-set-password-reset-password-new-password-hamburger-menu {
    display: flex;
    position: relative;
    right: -22px;
  }

  .reset-password-set-password-reset-password-set-password {
    justify-content: space-between;
  }

  .reset-password-set-password-title1 {
    font-size: 18px;
    line-height: 23px;
  }

  .reset-password-set-password-container5,
  .reset-password-set-password-content4 {
    gap: 16px;
  }

  .reset-password-set-password-help-link1 {
    bottom: 72px;
    left: 32px;
    line-height: 20px;
  }

  .reset-password-set-password-helpcentergeratorcom2,
  .reset-password-set-password-mail-01-icon2,
  .reset-password-set-password-help-link1 {
    position: absolute;
    margin: 0 !important;
    z-index: 1;
  }

  .reset-password-set-password-mail-01-icon2 {
    width: 14px;
    height: 14px;
    right: 365px;
    bottom: 34px;
    overflow: hidden;
    flex-shrink: 0;
  }

  .reset-password-set-password-helpcentergeratorcom2 {
    right: 190px;
    bottom: 32px;
    line-height: 20px;
    white-space: nowrap;
  }
}