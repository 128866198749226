.active {
    background-color: #f9fafb;
}


.custom-cursor {
    cursor: pointer;
}


.admin-dashboard-button-group {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    overflow-x: auto;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;

    box-sizing: border-box;
    display: flex;
    flex-direction: row;

  }

  .admin-dashboard-button-group-base1 {
  width: 87px;
  background-color: #fff;
  border-right: 1px solid #d0d5dd;
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  min-height: 40px;
  white-space: nowrap;
}

.admin-dashboard-text {
    position: relative;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    min-width: 30px;
  }

  .admin-dashboard-text31 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 71px;
  }

  .admin-dashboard-text32 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 55px;
  }

  .admin-dashboard-text39 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 61px;
  }

  .admin-dashboard-text29 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 45px;
  }

/* .filter-button-group-base {
    width: 87px;
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
    white-space: nowrap;
} */

/* .filter-button-group {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    overflow-x: auto;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
} */