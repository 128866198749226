.active {
    background-color: #f9fafb;
}


.custom-cursor {
    cursor: pointer;
}

/* .component-display-area-funnel{} */

.admin-dashboard-tab-main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    gap: 30px;
   
}

.component-display-area {
    padding-top: 20px;
}

.admin-dashboard-tab-tab {
    width: max-content;
    padding-left: 32px;
   
}

.admin-dashboard-tab-button-group-base1 {
    /* width: 87px; */
    background-color: #fff;
    border-right: 1px solid #d0d5dd;
    box-sizing: border-box;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    min-height: 40px;
    white-space: nowrap;
  }

  .admin-dashboard-tab-button-group {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    overflow-x: auto;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
  }

  .admin-dashboard-tab-text32 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    width: max-content;
  }
  
  .admin-dashboard-tab-text31 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    width: max-content;
  }

