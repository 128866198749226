.skeleton-component-listing-container {
    box-sizing: border-box;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: initial;
    padding: 0 29px;
    align-items: flex-start;
    width: inherit;
    display: flex;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.skeleton-component-listing-container::-webkit-scrollbar {
    display: none;
}

.skeleton-listing {
    position: relative;
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 20px;
    width: 100%;
    height: 245px;
    /* Adjusted to match your layout */
    min-height: 245px;
    overflow: hidden;
    background-color: #f5f5f5;
}

.skeleton-listing::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.6) 50%,
            rgba(255, 255, 255, 0) 100%);
    animation: shimmer 1.5s infinite linear;
}

.skeleton-header {
    display: flex;
    margin-bottom: 16px;
}

.skeleton-image {
    width: 120px;
    height: 120px;
    min-height: 120px;
    background: #e0e0e0;
    border-radius: 8px;
    margin-right: 16px;
    flex-shrink: 0;
}

.skeleton-info {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.skeleton-title-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 8px;
}

.skeleton-title {
    height: 20px;
    background: #e0e0e0;
    border-radius: 4px;
    width: 60%;
}

.skeleton-options {
    width: 24px;
    height: 24px;
    background: #e0e0e0;
    border-radius: 50%;
}

.skeleton-meta {
    display: flex;
    gap: 16px;
    margin-bottom: 8px;
}

.skeleton-meta-item {
    height: 16px;
    background: #e0e0e0;
    border-radius: 4px;
    width: 100px;
}

.skeleton-rating {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
}

.skeleton-rating-box {
    width: 45px;
    height: 24px;
    background: #e0e0e0;
    border-radius: 4px;
}

.skeleton-review-count {
    height: 16px;
    width: 120px;
    background: #e0e0e0;
    border-radius: 4px;
}

.skeleton-tags {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
    flex-wrap: wrap;
}

.skeleton-tag {
    height: 24px;
    width: 100px;
    background: #e0e0e0;
    border-radius: 16px;
}

.skeleton-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
}

.skeleton-user {
    display: flex;
    align-items: center;
    gap: 12px;
}

.skeleton-avatar {
    width: 36px;
    height: 36px;
    background: #e0e0e0;
    border-radius: 50%;
}

.skeleton-username {
    height: 16px;
    width: 100px;
    background: #e0e0e0;
    border-radius: 4px;
}

.skeleton-actions {
    display: flex;
    gap: 12px;
}

.skeleton-action-button {
    width: 80px;
    height: 32px;
    background: #e0e0e0;
    border-radius: 4px;
}

.skeleton-price {
    position: absolute;
    top: 16px;
    right: 16px;
    height: 24px;
    background: #e0e0e0;
    border-radius: 4px;
    width: 120px;
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(100%);
    }
}