

* {
  box-sizing: border-box;
}

/* Main slideshow wrapper */
.slideshow-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
 
 
  top: 70px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: white;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}

/* Slideshow container with horizontal scroll */
.slideshow-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
  overflow: hidden;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  top: -100px;
 
}

/* Individual slide styling */
.mySlides {
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 20%; /* Show 5 slides at a time (100% / 5 = 20%) */
}

.slide {
  width: 100%;
  height: 288px;
  position: relative;
  /* top: 25%; */
}

.prev, .next {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transform: translateY(-50%);
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from { opacity: 0.4; }
  to { opacity: 1; }
}

/* Previous and Next button in slideshow modal */
.slideshow-prev, .slideshow-next {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  transform: translateY(-50%);
}

.slideshow-prev {
  left: 30%;
}

.slideshow-next {
  right: 30%;
}
