@import url( 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.reset-password-finished-password-gerator-04-1-icon3 {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}
  .reset-password-finished-password-logo-frame,
  .reset-password-finished-password-logo3 {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .reset-password-finished-password-logo3 {
    align-self: stretch;
    height: 32px;
    flex-direction: row;
    /* background-image: url('../../../public/gerator04-1@2x.png'); */
    background-image: url('../../icons/Gerator/gerator04-1@2x.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
  .reset-password-finished-password-logo-frame {
    width: 121px;
    flex-direction: column;
    padding: 6px 0 0;
    box-sizing: border-box;
  }
  .reset-password-finished-password-placeholder-icon22 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
  }
  .reset-password-finished-password-text12 {
    text-decoration: none;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #475467;
    text-align: left;
    display: inline-block;
    min-width: 47px;
    white-space: nowrap;
  }
  .reset-password-finished-password-buttonsbutton13,
  .reset-password-finished-password-text-padding9 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  .reset-password-finished-password-buttonsbutton13 {
    cursor: pointer;
    border: 0;
    padding: 10px 16px;
    background-color: transparent;
    border-radius: 8px;
    overflow: hidden;
    gap: 6px;
  }
  .reset-password-finished-password-text13 {
    text-decoration: none;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 59px;
    white-space: nowrap;
  }
  .reset-password-finished-password-buttonsbutton14,
  .reset-password-finished-password-navigation-actions3 {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .reset-password-finished-password-buttonsbutton14 {
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 15px;
    background-color: #7e56d9;
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    justify-content: center;
    gap: 6px;
  }

  .reset-password-finished-password-reset-password-finished-hamburger-menu{
  display: none;
  }


  .reset-password-finished-password-navigation-actions3 {
    width: 190px;
    justify-content: flex-start;
    gap: 12px;
  }
  .reset-password-finished-password-frame-container,
  .reset-password-finished-password-reset-password-finished-des-inner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: 100%;
  }
  .reset-password-finished-password-frame-container {
    flex: 1;
    justify-content: space-between;
    gap: 20px;
  }
  .reset-password-finished-password-reset-password-finished-des-inner {
    width: 100%;
    justify-content: flex-start;
    padding: 0 32px;
    box-sizing: border-box;
  }
  .reset-password-finished-password-featured-icon3 {
    width: 56px;
    height: 56px;
    position: relative;
    border-radius: 12px;
  }
  .reset-password-finished-password-supporting-text3,
  .reset-password-finished-password-title2 {
    align-self: stretch;
    position: relative;
  }
  .reset-password-finished-password-title2 {
    margin: 0;
    font-size: inherit;
    line-height: 38px;
    font-weight: 600;
    font-family: inherit;
    color: #101828;
  }
  .reset-password-finished-password-supporting-text3 {
    font-size: 16px;
    line-height: 24px;
    color: #475467;
  }
  .reset-password-finished-password-header3,
  .reset-password-finished-password-text-and-supporting-text3 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
  }
  .reset-password-finished-password-header3 {
    align-items: center;
    gap: 24px;
  }
  .reset-password-finished-password-text14 {
    position: relative;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 71px;
  }
  .reset-password-finished-password-buttonsbutton15 {
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 20px;
    background-color: #7e56d9;
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }

  .reset-password-finished-password-container6,
  .reset-password-finished-password-container7,
  .reset-password-finished-password-content8 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .reset-password-finished-password-content8 {
    width: 360px;
    gap: 32px;
    max-width: 360px;
  }
  .reset-password-finished-password-container6,
  .reset-password-finished-password-container7 {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    max-width: 1280px;
  }
  .reset-password-finished-password-container6 {
    padding: 0 32px;
    text-align: center;
    font-size: 30px;
    color: #101828;
    font-family: Inter;
  }
  .reset-password-finished-password-text16 {
    bottom: 32px;
    left: 32px;
    line-height: 20px;
  }
  .reset-password-finished-password-helpcentergeratorcom3,
  .reset-password-finished-password-mail-01-icon3,
  .reset-password-finished-password-text16 {
    position: absolute;
    margin: 0 !important;
    z-index: 1;
  }
  .reset-password-finished-password-mail-01-icon3 {
    width: 16px;
    height: 16px;
    right: 204px;
    bottom: 34px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .reset-password-finished-password-helpcentergeratorcom3 {
    right: 31.5px;
    bottom: 32px;
    line-height: 20px;
    white-space: nowrap;
  }
  .reset-password-finished-password-log-in3,
  .reset-password-finished-password-reset-password-finished-des {
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
  }
  .reset-password-finished-password-log-in3 {
    align-self: stretch;
    align-items: center;
    padding: 100px 100px 0 100px;
    gap: 32px;
    height: max-content;
    text-align: left;
    font-size: 14px;
    color: #475467;
    font-family: Inter;
  }

  .reset-password-finished-password-footer{
    position: relative;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    align-self: stretch;
    align-items: center;
    width: 100%;
    gap: 32px;
    margin-top: 80px;
    height: 100px;
    text-align: left;
    font-size: 14px;
    color: #475467;
    font-family: Inter;
  }
  .reset-password-finished-password-reset-password-finished-des {
    width: 100%;
    overflow: hidden;
    align-items: flex-start;
    padding: 18px 0 0;
    gap: 18px;
    line-height: normal;
    letter-spacing: normal;
  }
  @media screen and (max-width: 1275px) {
    .reset-password-finished-password-container6,
    .reset-password-finished-password-container7 {
      max-width: 100%;
    }
  }
  @media screen and (max-width: 1100px) {
    .reset-password-finished-password-log-in3 {
      /* padding-top: 62px;
      padding-bottom: 363px; */
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 750px) {
    .reset-password-finished-password-title2 {
      font-size: 24px;
      line-height: 30px;
    }
    .reset-password-finished-password-container6 {
      gap: 16px;
    }
    .reset-password-finished-password-log-in3 {
      gap: 16px;
      
      box-sizing: border-box;
    }
  }
  @media screen and (max-width: 450px) {

    .reset-password-finished-password-navigation-actions3{
      display: none;
    }

    .reset-password-finished-password-reset-password-finished-hamburger-menu{
      display: flex;
      position: relative;
      right: -22px;
    }

    .reset-password-finished-password-title2 {
      font-size: 18px;
      line-height: 23px;
    }
    .reset-password-finished-password-container7,
    .reset-password-finished-password-content8 {
      gap: 16px;
    }

    .reset-password-finished-password-text16 {
      bottom: 72px;
      left: 32px;
      line-height: 20px;
    }
    .reset-password-finished-password-helpcentergeratorcom3,
    .reset-password-finished-password-mail-01-icon3,
    .reset-password-finished-password-text16 {
      position: absolute;
      margin: 0 !important;
      z-index: 1;
    }
    .reset-password-finished-password-mail-01-icon3{
      width: 14px;
      height: 14px;
      right: 365px;
      bottom: 34px;
      overflow: hidden;
      flex-shrink: 0;
    }
    .reset-password-finished-password-helpcentergeratorcom3 {
      right: 190px;
      bottom: 32px;
      line-height: 20px;
      white-space: nowrap;
    }

    .reset-password-finished-password-buttonsbutton15{
      max-width: 85%;
      position: relative;
      left: 30px;
    }

    .reset-password-finished-password-log-in3{
      padding: 170px 40px 236px ;
      min-height: 92vh;
    }

    
}
  