.add-form-header-header-section
 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  flex-shrink: 0;
}

.add-form-header-container
 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  padding: 0 32px;
  box-sizing: border-box;
}

.add-form-header-page-header {
    background-color: #fff;
    gap: 20px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }

.add-form-header-breadcrumb-button-base4,
.add-form-header-breadcrumbs,
.add-form-header-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.add-form-header-breadcrumb-button-base4 {
  flex: 1;
  border-radius: 6px;
  background-color: #f9fafb;
  justify-content: center;
  padding: 4px 8px;
  white-space: nowrap;
  color: #344054;
}
.add-form-header-breadcrumbs,
.add-form-header-tabs {
  justify-content: flex-start;
}
.add-form-header-tabs {
  flex: 1;
  gap: 8px;
}
.add-form-header-breadcrumbs {
  width: 276px;
}

.add-form-header-search-lg-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}

.add-form-header-chevron-right-icon {
    height: 16px;
    width: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
}

.add-form-header-breadcrumb-button-base1 {
    flex: 1;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
}

.add-form-header-text17 {
    text-decoration: none;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    color: inherit;
    display: inline-block;
    min-width: 74px;
}

.add-form-header-breadcrumb-button-base2 {
    align-self: stretch;
    border-radius: 6px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 7px;
}

.add-form-header-chevron-right-icon2 {
    height: 16px;
    width: 16px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
}

.add-form-header-breadcrumb-button-base3 {
    align-self: stretch;
    border-radius: 6px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
}

.add-form-header-text19 {
    align-self: stretch;
    position: relative;
    line-height: 20px;
    font-weight: 500;
    white-space: nowrap;
}

.add-form-header-text20 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 78px;
}

.add-form-header-content6
{
  align-self: stretch;
  max-width: 100%;
}
.add-form-header-content6 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  box-sizing: border-box;
  gap: 70px;
}

.add-form-header-text-and-supporting-text4 {
    width: 678px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    min-width: 320px;
    max-width: 100%;
    flex-shrink: 0;
    font-size: 26px;
    color: #101828;
}

.add-form-header-title {
    align-self: stretch;
    position: relative;
  
    margin: 0;
    font-size: inherit;
    line-height: 38px;
    font-weight: 600;
    font-family: inherit;
}


.add-form-header-supporting-text12
{
  align-self: stretch;
  position: relative;

  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.add-form-header-actions
{
  flex-direction: row;
  align-items: center;
    display: none;
    justify-content: flex-start;
    gap: 12px;
    max-width: 100%;
  
}

.add-form-header-button {
  height: 40px;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  gap: 4px;

  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
}


.add-form-header-placeholder-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
    min-height: 20px;
}

.add-form-header-input-dropdown {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    min-width: 200px;
    max-width: 320px;
    flex-shrink: 0;
}


.add-form-header-hint-text {
    width: 320px;
    position: relative;
    line-height: 20px;
    display: none;
}

.add-form-header-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}

.add-form-header-button6,
.add-form-header-text-padding6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.add-form-header-button6 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  gap: 4px;
}

.add-form-header-upload-04-icon {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    min-height: 20px;
}

.add-form-header-text27 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 45px;
}

.add-form-header-button7 {
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 13px;
    background-color: #7e56d9;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.add-form-header-text28 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 73px;
}

.add-form-header-divider-icon1 {
    align-self: stretch;
    max-width: 100%;
}
  
.add-form-header-divider-icon1 {
    position: relative;
    overflow: hidden;
    max-height: 100%;
    display: none;
}
  


@media screen and (max-width:1100px) {

    .add-form-header-content6 {
        gap: 35px;
        flex-wrap: wrap;
      }
    
}

@media screen and (max-width:750px) {
    .add-form-header-content6 {
        gap: 17px;
      }
    
}

@media screen and (max-width:450px) {
    
    .add-form-header-title {
        font-size: 21px;
        line-height: 30px;
      }

    .add-form-header-actions
       {
         flex-wrap: wrap;
        }
}