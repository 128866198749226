.footer-container3,
.footer-footer-frame-layout,
.footer-footer1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-container3 {
  width: 100%;
  align-items: flex-start;
  padding: 0 75px 0 28px;
  box-sizing: border-box;
  /* max-width: 1280px; */
}
.footer-footer-frame-layout,
.footer-footer1 {
  background-color: #fff;
  overflow: hidden;
}
.footer-footer1 {
  align-self: stretch;
  align-items: center;
  padding: 48px 0px;
  z-index: 1;
  color: #475467;
  font-family: Inter;
}

.footer-content47,
.footer-logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 29px;
}
.footer-content47 {
  align-self: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  gap: 20px;
}

.footer-footer-text {
    position: relative;
    line-height: 20px;
}

.footer-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.footer-change {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.footer-logomark,
.footer-logomark1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
/* .footer-logomark1 {
  box-shadow: 0 1.3px 3.75px rgba(16, 24, 40, 0.1),
    0 1.3px 2.5px rgba(16, 24, 40, 0.06);
}
.footer-logomark {
  filter: drop-shadow(0 1.3px 3.75px rgba(16, 24, 40, 0.1))
    drop-shadow(0 1.3px 2.5px rgba(16, 24, 40, 0.06));
} */

.footer-gerator-10-2-icon {
    height: 38px;
    width: 38px;
    position: relative;
    object-fit: cover;
}

@media screen and (max-width: 1275px) {
    
    .footer-container3 {
      max-width: 100%;
    }
}

@media screen and (max-width: 750px) {
   
    .footer-footer1 {
        gap: 32px;
        padding-left: 40px;
        padding-right: 40px;
        box-sizing: border-box;
    }

    .footer-container3 {
        gap: 16px;
    }
}

@media screen and (max-width: 450px) {
    
    .footer-footer1 {
        gap: 16px;
      }
}