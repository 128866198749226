@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");

.account-subscription-gerator-04-1-icon {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}

.account-subscription-logo {
  width: 121px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-image: url("../../../icons/Gerator/account_setup/gerator04-1@2x.png");
  /* background-image: url(../../../public/account_setup_profile_subscription/gerator04-1@2x.png); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.account-subscription-featured-icon {
  height: 48px;
  width: 48px;
  position: relative;
  border-radius: 10px;
}

.account-subscription-step-titles,
.account-subscription-supporting-text {
  position: relative;
  line-height: 24px;
}

.account-subscription-step-titles {
  align-self: stretch;
  font-weight: 600;
}

.account-subscription-supporting-text {
  color: #475467;
}

.account-subscription-step-titles-parent,
.account-subscription-step-wrappers {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.account-subscription-step-titles-parent {
  flex: 1;
  flex-direction: column;
  gap: 2px;
  min-width: 182px;
}

.account-subscription-step-wrappers {
  align-self: stretch;
  flex-direction: row;
  gap: 16px;
}

.account-subscription-connector {
  height: 26px;
  width: 2px;
  position: relative;
  border-radius: 2px;
  background-color: #eaecf0;
  left: 20px;
}

.account-subscription-connector-wrappers,
.account-subscription-step-base,
.account-subscription-step-base1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 px;
}

.account-subscription-step-base,
.account-subscription-step-base1 {
  align-self: stretch;
  flex-direction: column;
  padding: 0 0 4px;
  gap: 2px;
  opacity: 0.6;
}

.account-subscription-step-base1 {
  opacity: 0.6;
}

.account-subscription-featured-icon2 {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 10px;
}

.account-subscription-connector-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 4px;
  gap: 4px;
}

.account-subscription-supporting-text2 {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  color: #475467;
}

.account-subscription-step-base2,
.account-subscription-text-and-supporting-text {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.account-subscription-step-base4 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.account-subscription-text-and-supporting-text {
  flex: 1;
  flex-direction: column;
  padding: 0 0 32px;
  box-sizing: border-box;
  gap: 2px;
  min-width: 182px;
}

.account-subscription-step-base2 {
  align-self: stretch;
  flex-direction: row;
  gap: 16px;
  opacity: 1;
}

.account-subscription-step-base4 {
  align-self: stretch;
  flex-direction: row;
  gap: 16px;
  opacity: 0.6;
}

.account-subscription-connector3 {
  width: 2px;
  height: 26px;
  position: relative;
  border-radius: 2px;
  background-color: #eaecf0;
  display: none;
}

.account-subscription-connector-wrap1,
.account-subscription-progress-steps-progress-icon {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.account-subscription-connector-wrap1 {
  height: 82px;
  align-items: center;
  padding: 0 0 4px;
  box-sizing: border-box;
  gap: 4px;
}

.account-subscription-progress-steps-progress-icon {
  align-self: stretch;
  align-items: flex-start;
}

.account-subscription-progress-content,
.account-subscription-progress-content-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.account-subscription-progress-content {
  flex: 1;
  flex-direction: column;
  gap: 79.5px;
}

.account-subscription-progress-content-wrapper {
  width: 380px;
  flex-direction: row;
  padding: 0 18px;
  box-sizing: border-box;
}

.account-subscription-contact-title {
  height: 20px;
  width: 125px;
  position: relative;
  line-height: 20px;
  display: inline-block;
}

.account-subscription-mail-01-icon {
  width: 16px;
  height: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.account-subscription-mail-01-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 0 0;
}

.account-subscription-helpuntitleduicom {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 20px;
  white-space: nowrap;
}

.account-subscription-contact-info {
  height: 20px;
  width: 188px;
  flex-direction: row;
  justify-content: flex-start;
  gap: 7px;
}

.account-subscription-contact-info,
.account-subscription-contact-title-parent,
.account-subscription-section {
  display: flex;
  align-items: flex-start;
}

.account-subscription-contact-title-parent {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  font-size: 14px;
}

.account-subscription-section {
  /* flex: 0.9386; */
  flex-grow:1;
  background-color: #f9fafb;

  flex-direction: column;
  justify-content: flex-start;
  padding: 32.5px 13px 32px 14px;
  box-sizing: border-box;
  /* gap: 1200px; */
  min-height: 100vh;
  min-width: 286px;
  max-width: 440px;
}

.account-subscription-heading {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: 36px;
  letter-spacing: -0.02em;
  line-height: 44px;
  font-weight: 600;
  font-family: inherit;
  color: #101828;
}

.account-subscription-heading-and-subheading {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.account-subscription-supporting-text4 {
  align-self: stretch;
  position: relative;
  font-size: 20px;
  line-height: 30px;
  color: #475467;
}

.account-subscription-heading-and-supporting-text {
  width: 768px;
  gap: 20px;
  max-width: 768px;
}

.account-subscription-container1,
.account-subscription-content,
.account-subscription-heading-and-supporting-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.account-subscription-content {
  align-self: stretch;
  max-width: 100%;
}

.account-subscription-container1 {
  width: 100%;
  padding: 0 32px;
  box-sizing: border-box;
  max-width: 1280px;
  flex-shrink: 0;
}

.account-subscription-featured-icon4 {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 9999px;
}

.account-subscription-heading1 {
  align-self: stretch;
  position: relative;
  line-height: 30px;
  font-weight: 600;
}

.account-subscription-heading-and-icon {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.account-subscription-price,
.account-subscription-supporting-text5 {
  align-self: stretch;
  position: relative;
}

.account-subscription-price {
  margin: 0;
  font-size: 48px;
  letter-spacing: -0.02em;
  line-height: 60px;
  font-weight: 600;
  font-family: inherit;
  color: #101828;
}

.account-subscription-supporting-text5 {
  font-size: 16px;
  line-height: 24px;
  color: #475467;
}

.account-subscription-header,
.account-subscription-heading-and-price {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.account-subscription-heading-and-price {
  align-items: center;
  gap: 8px;
}

.account-subscription-header {
  align-items: flex-start;
  padding: 32px 32px 0;
}

.account-subscription-check-icon {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 9999px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.account-subscription-span {
  font-weight: 800;
}

.account-subscription-item-description {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}

.account-subscription-check-item-text,
.account-subscription-text-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.account-subscription-text-wrap {
  flex: 1;
  flex-direction: column;
  min-width: 208px;
}

.account-subscription-check-item-text {
  align-self: stretch;
  flex-direction: row;
  gap: 12px;
}

.account-subscription-business-page {
  font-size: 16px;
  color: #475467;
}

.account-subscription-check-item-text3 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  font-size: 15px;
  color: #155eef;
}

.account-subscription-check-icon5 {
  height: 24px;
  width: 24px;
  position: relative;
  border-radius: 9999px;
  overflow: hidden;
  flex-shrink: 0;
}

.account-subscription-check-item-text5,
.account-subscription-check-items,
.account-subscription-content2 {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
}

.account-subscription-check-item-text5 {
  display: none;
  flex-direction: row;
  gap: 12px;
}

.account-subscription-check-items,
.account-subscription-content2 {
  display: flex;
  flex-direction: column;
}

.account-subscription-check-items {
  gap: 16px;
}

.account-subscription-content2 {
  padding: 32px 32px 40px;
  text-align: left;
  font-size: 16px;
  color: #475467;
}

.account-subscription-placeholder-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.account-subscription-button-label {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 87px;
}

.account-subscription-buttonsbutton,
.account-subscription-text-padding {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.account-subscription-buttonsbutton {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  overflow: hidden;
  padding: 10px 20px;
  gap: 6px;
}

.account-subscription-text7 {
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
}

.account-subscription-buttonsbutton1,
.account-subscription-text-padding1 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.account-subscription-text-padding1 {
  height: 24px;
  display: flex;
  padding: 0 2px;
  box-sizing: border-box;
}

.account-subscription-buttonsbutton1 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: none;
  padding: 10px 18px;
  gap: 6px;
}

.account-subscription-footer,
.account-subscription-pricing-tier-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.account-subscription-footer {
  cursor: pointer;
  border: 0;
  padding: 30px 32px 32px;
  background-color: #f9fafb;
  align-self: stretch;
  border-top: 1px solid #eaecf0;
}

.account-subscription-pricing-tier-card {
  flex: 1;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 16px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow: hidden;
  min-width: 273px;
  max-width: 100%;
}

.account-subscription-price1 {
  align-self: stretch;
  position: relative;
  font-size: 48px;
  letter-spacing: -0.02em;
  line-height: 60px;
  font-weight: 600;
  color: #101828;
}

.account-subscription-content1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 32px;
  max-width: 100%;
  flex-wrap: wrap;
  height: max-content;
}

.account-subscription-container,
.account-subscription-container2,
.account-subscription-pricing-section {
  flex-direction: column;
  box-sizing: border-box;
}

.account-subscription-container2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 32px;
  gap: 32px;
  max-width: 1280px;
  flex-shrink: 0;
  text-align: center;
  font-size: 20px;
}

.account-subscription-container,
.account-subscription-pricing-section {
  align-self: stretch;
}

.account-subscription-pricing-section {
  height: max-content;
  background-color: #fff;
  overflow-y: scroll;
  scrollbar-width: none;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 96px 0 0;
  gap: 64px;
  max-width: 100%;
}

.account-subscription-container {
  padding: 0 32px;
}

.account-subscription-container,
.account-subscription-container-wrapper,
.account-subscription-frame-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}

.account-subscription-container-wrapper {
  flex: 1;
  flex-direction: column;
}

.account-subscription-frame-wrapper {
  align-self: stretch;
  flex-direction: row;
}

.account-subscription-mail-01-icon1 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.account-subscription-helpcentergeratorcom {
  position: relative;
  line-height: 20px;
  white-space: nowrap;
}

.account-subscription-icon-and-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
}

.account-subscription-description {
  position: relative;
  line-height: 20px;
}

.account-subscription-icon-and-text-parent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}

.account-subscription-frame-container,
.account-subscription-frame-parent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.account-subscription-frame-container {
  width: 983px;
  align-items: flex-start;
  padding: 0 20px;
  font-size: 14px;
  color: #475467;
}

.account-subscription-frame-parent {
  align-self: stretch;
  align-items: center;
  padding: 0 0 30px;
  gap: 103px;
}

.account-subscription-section1,
.account-subscription-account-subscription {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  font-size: 16px;
  font-family: Inter;
}

.account-subscription-section1 {
  flex: 1;
  flex-direction: column;
  align-items: center;
  min-width: 480px;
  max-width: 100%;
  color: #6941c6;
}

.account-subscription-account-subscription {
  width: 100%;
  position: relative;
  background-color: #fff;
  flex-direction: row;
  /* align-items: flex-start; */
  align-items: stretch;
  min-height: 960px;
  line-height: normal;
  letter-spacing: normal;
  row-gap: 20px;
  color: #344054;
}

/* New Css */

.account-subscription-display-CheckboxGroupItem {
  width: 768px;
 
  padding: 16px 16px 16px 16px;
  background: white;
  border-radius: 12px;
  border: 2px #EAECF0 solid;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  height: max-content;
  flex-direction: column;
  position: relative;
}

.account-subscription-display-CheckboxGroupItem:hover{
  border-color: #6941C6;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.account-subscription-display-Content {
  flex: 1 1 0;
  height: max-content;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: flex;
}

.account-subscription-display-Content-width {
  width: 70%;
}


.account-subscription-display-TextAndSupportingText {
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  display: inline-flex;
  height: max-content;
  /* overflow: hidden; */
}

.account-subscription-display-TextAndSubtext {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
}

.account-subscription-display-Text-1 {
  color: #344054;
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;

}

.account-subscription-display-Subtext {
  color: #475467;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.account-subscription-display-SupportingText {
  /* align-self: stretch; */
  color: #475467;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.account-subscription-display-SupportingText1 {
  /* align-self: stretch; */
  color: #475467;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.account-subscription-display-text-overlflow {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 100%; */
}

.account-subscription-display-text-overlflow1 {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 100%; */
}


.account-subscription-display-Actions {
  /* width: 133px; */
  width: max-content;
  height: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  /* gap: 22px; */
  position: relative;
  /* top: 14px; */
  display: inline-flex;
}

.account-subscription-display-ButtonsButton {
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: flex;
}

.account-subscription-display-ButtonsButton {
  transition: color 0.3s ease;
}

.account-subscription-display-ButtonsButton:hover {
  color: #6a1b9a; 
}

.account-subscription-display-Text-2 {
  color: #475467;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
}

.account-subscription-display-buttonsbutton-wrapper {
  align-self: stretch;
  justify-content: flex-end;
  /* padding: 0 27px 0 0; */
  /* margin-top: 20px; */
  cursor: pointer;
  position: absolute;
  top: 32px;
  right: 35px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.account-subscription-display-buttonsbutton-wrapper-width {
  width: 30%;
}

.account-subscription-display-buttonsbutton19 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  justify-content: flex-start;
  padding: 6px 12px;
  gap: 5px;
  z-index: 1;
}

.account-subscription-display-buttonsbutton19:hover{
  border-color: #6941C6;
}

.account-subscription-display-arrow-narrow-left-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.account-subscription-display-search-input {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
}

.account-subscription-display-text107 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;


}

.account-subscription-display-toggle-controls {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  /* max-width: 100%; */
  font-size: 14px;
  color: #344054;
  width: 100%;
  padding-left: 22px;
}

.account-subscription-display-rectangle-group {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0 10px 15px;
  gap: 8px;
}

.account-subscription-display-switch-label1 {
  letter-spacing: -0.02px;
  font-weight: 500;
  display: inline-block;
  position: relative;
  line-height: 20px;
  z-index: 1;
}

.account-subscription-display-switch-label1.active,
.account-subscription-display-switch-label.active {
font-weight: bold;
background-color: transparent;
color: #6941C6;
}

.account-subscription-display-toggle-base {
  border-radius: 9999px;
  overflow: hidden;
  flex-shrink: 0;

  height: 20px;
  width: 36px;
  background-color: #f2f4f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px;
  box-sizing: border-box;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.account-subscription-display-button6
{
  border-radius: 9999px;
  overflow: hidden;
  flex-shrink: 0;

  height: 16px;
  width: 16px;
  position: relative;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  background-color: #fff;
  position: absolute;
  transition: left 0.3s ease;
}

.account-subscription-display-button6.left {
  left: 0;
}

.account-subscription-display-button6.right {
  left: 20px;
}

.account-subscription-display-switch-label {
  letter-spacing: -0.02px;
  font-weight: 500;
  display: inline-block;
  min-width: 97px;
  z-index: 1;
  position: relative;
  line-height: 20px;
}

.account-subscription-display-supporting-text3 {
  align-self: stretch;
  width: 300px;
  color: #475467;
  display: none;
}



@media screen and (max-width: 1275px) {
  .account-subscription-progress-content {
    flex: 1;
  }

  .account-subscription-section {
    width: 100%;
    padding-top: 21px;
    padding-bottom: 21px;
    box-sizing: border-box;
  }

  .account-subscription-container1,
  .account-subscription-container2 {
    max-width: 100%;
  }

  .account-subscription-pricing-section {
    padding-top: 62px;
    box-sizing: border-box;
  }

  .account-subscription-section1 {
    flex: 1;
  }

  .account-subscription-account-subscription {
    flex-wrap: wrap;
  }

  .account-subscription-price1,
  .account-subscription-price {

    font-size: 44px;
    line-height: 60px;

  }

  .account-subscription-heading {
    font-size: 30px;
  }

  .account-subscription-heading-and-supporting-text {
    width: 95%;
  }

}

@media screen and (max-width: 1200px) {



  .account-subscription-content1 {
    flex-wrap: wrap;
  }

  /* .account-subscription-progress-content{
      position: fixed;
    }
     */
  .account-subscription-section {

    height: 249.5vh;

  }




  .account-subscription-pricing-section {
    height: auto;
    gap: 32px;
  }

  .account-subscription-container {
    gap: 40px;
  }

  .account-subscription-container-wrapper {
    gap: 484px;
  }

  .account-subscription-frame-parent {
    gap: 51px;
  }

  .account-subscription-price1,
  .account-subscription-price {

    font-size: 38px;
    line-height: 60px;

  }

  .account-subscription-supporting-text5 {
    font-size: 14px;
  }


}

@media screen and (max-width: 800px) {
  .account-subscription-section {
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    min-width: 100%;
    height: 50vh;
    flex-direction: column;

  }

  .account-subscription-heading {
    font-size: 29px;
    line-height: 35px;
  }

  .account-subscription-heading-and-supporting-text {
    max-width: 100%;
  }

  .account-subscription-container1 {
    gap: 16px;
  }

  .account-subscription-price {
    font-size: 38px;
    line-height: 48px;
  }

  .account-subscription-check-item-text,
  .account-subscription-check-item-text3,
  .account-subscription-check-item-text5 {
    flex-wrap: wrap;
  }

  .account-subscription-price1 {
    font-size: 38px;
    line-height: 48px;
  }

  .account-subscription-container2 {
    gap: 16px;
  }

  .account-subscription-pricing-section {
    gap: 16px;
    padding-top: 40px;
    box-sizing: border-box;
  }

  .account-subscription-container {
    gap: 20px;
  }

  .account-subscription-container-wrapper {
    gap: 242px;
  }

  .account-subscription-icon-and-text-parent {
    flex-wrap: wrap;
  }

  .account-subscription-frame-parent {
    gap: 26px;
  }

  .account-subscription-section1 {
    min-width: 100%;
  }

  .account-subscription-container2 {
    padding: 0;
  }

  .account-subscription-text-wrap {
    flex: 1;
    flex-direction: column;
    min-width: 190px;
  }
}


@media screen and (max-width: 450px) {

  .account-subscription-mobile-container {
    width: 375px;
    height: 10px;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    display: inline-flex;
    padding-top: 30px;
    padding-bottom: 80px;
  }

  .account-subscription-mobile-PaginationDotGroup {
    justify-content: center;
    align-items: center;
    gap: 16px;
    display: inline-flex;
  }

  .account-subscription-mobile-PaginationDotIndicator {
    width: 10px;
    height: 10px;
    position: relative;
    background-color: #EAECF0;
    border-radius: 9999px;
  }

  .account-subscription-mobile-PaginationDotIndicator1 {
    width: 10px;
    height: 10px;
    position: relative;
    background-color: #7F56D9;
    border-radius: 9999px;
  }

  .account-subscription-progress-steps-progress-icon {
    display: none;
  }

  .account-subscription-step-base2,
  .account-subscription-step-wrappers {
    flex-wrap: wrap;
  }

  .account-subscription-step-base4 {
    flex-wrap: wrap;
  }

  .account-subscription-progress-content {
    gap: 40px;
  }

  .account-subscription-contact-title-parent {
    flex-wrap: wrap;
  }

  .account-subscription-section {
    gap: 50px;
    max-width: 90%;
    height: fit-content;
    background-color: white;
  }

  .account-subscription-heading {
    font-size: 22px;
    line-height: 26px;
  }

  .account-subscription-supporting-text4 {
    font-size: 16px;
    line-height: 24px;
  }

  .account-subscription-content {
    gap: 16px;
  }

  .account-subscription-heading1 {
    font-size: 16px;
    line-height: 24px;
  }

  .account-subscription-price,
  .account-subscription-price1 {
    font-size: 29px;
    line-height: 36px;
  }

  .account-subscription-content1 {
    gap: 16px;
  }

  .account-subscription-pricing-section {
    padding-top: 26px;
    box-sizing: border-box;
  }

  .account-subscription-container-wrapper {
    gap: 121px;
  }
}

@media screen and (max-width: 375px) {

  .account-subscription-step-base2,
  .account-subscription-step-wrappers {
    flex-wrap: wrap;
  }

  .account-subscription-step-base4 {
    flex-wrap: wrap;
  }

  .account-subscription-progress-content {
    gap: 40px;
  }

  .account-subscription-contact-title-parent {
    flex-wrap: wrap;
  }

  .account-subscription-section {
    gap: 569px;
    max-width: 100%;
    flex-direction: row;
    height: 80vh;
  }

  .account-subscription-heading {
    font-size: 22px;
    line-height: 26px;
  }

  .account-subscription-supporting-text4 {
    font-size: 16px;
    line-height: 24px;
  }

  .account-subscription-content {
    gap: 16px;
  }

  .account-subscription-heading1 {
    font-size: 16px;
    line-height: 24px;
  }

  .account-subscription-price,
  .account-subscription-price1 {
    font-size: 29px;
    line-height: 36px;
  }

  .account-subscription-content1 {
    gap: 16px;
  }

  .account-subscription-pricing-section {
    padding-top: 26px;
    box-sizing: border-box;
  }

  .account-subscription-container-wrapper {
    gap: 121px;
  }
}