.add-team-featured-icon2 {
  /* height: 40px;
    width: 40px; */
  position: relative;
  border-radius: 8px;
  padding-top: 3px;
}

.add-team-supporting-text91,
.add-team-title9 {
  align-self: stretch;
  position: relative;
  white-space: nowrap;
}

.add-team-title9 {
  text-decoration: none;
  line-height: 30px;
  font-weight: 600;
  color: inherit;
}

.add-team-supporting-text91 {
  font-size: 14px;
  line-height: 20px;
  color: #475467;
}

.add-team-content71,
.add-team-text-and-supporting-text26 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.add-team-content71 {
  margin: 0 !important;
  position: absolute;
  top: 24px;
  left: 24px;
  flex-direction: row;
  gap: 16px;
}

.add-team-button-close-x6,
.add-team-slide-out-menu-header {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.add-team-button-close-x6 {
  height: 20px;
  margin: 0 !important;
  position: absolute;
  top: 27px;
  right: 26px;
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  z-index: 1;
  cursor: pointer;
}

.add-team-slide-out-menu-header {
  align-self: stretch;
  height: 78px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 24px;
  position: relative;
  gap: 8px;
  text-align: left;
  font-size: 20px;
  color: #101828;
  font-family: Inter;
}

.add-team-label9 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 67px;
}

.add-team-content72 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Inter;
  font-size: 16px;
  color: #101828;
  min-width: 194px;
  max-width: 100%;
}

.add-team-help-icon2 {
  height: 16px;
  width: 16px;
  position: relative;
  display: none;
}

.add-team-input6 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 13px;
  gap: 8px;
  max-width: 100%;
  cursor: pointer;
}

.add-team-input6:focus {
  border-color: #7e56d9;
  /* Set to your preferred active color */
  box-shadow: 0 0 5px rgba(126, 86, 217, 0.5);
  /* Optional glow effect */
}

.add-team-input6:hover {
  border-color: #7e56d9;
  /* Slightly darker border on hover */
}

.add-team-input6a {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 13px;
  gap: 8px;
  max-width: 100%;

  outline: none;
  appearance: none;
  position: relative;
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"%3E%3Cpath fill="gray" d="M10 12l-6-6h12z"/%3E%3C/svg%3E');
  /* Custom dropdown arrow */
  background-repeat: no-repeat;
  background-position: 95%;
  background-size: 15px;
}

.add-team-input6a:focus {
  border-color: #7e56d9;
  box-shadow: 0 0 5px rgba(126, 86, 217, 0.5);
}


.add-team-input6a:hover {
  border-color: #7e56d9;
}

.add-team-hint-text6 {
  width: 320px;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.add-team-input-field {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  max-width: 100%;
}

.add-team-label10 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 94px;
}

.add-team-mail-01-icon,
.add-team-text274 {
  position: relative;
  overflow: hidden;
}

.add-team-mail-01-icon {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
}

.add-team-text274 {
  /* width: calc(100% - 36px); */
  width: 80%;
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  flex: 1;
  line-height: 24px;
  color: #101828;
  text-align: left;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* min-width: 163px; */
  padding: 0;
}

.add-team-content73 {
  /* flex: 1; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
}

.add-team-help-icon3 {
  height: 16px;
  width: 16px;
  position: relative;
}

.add-team-form,
.add-team-input-field1,
.add-team-input7 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.add-team-input7 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
  width: 50%;
  cursor: pointer;
}

.add-team-input7:focus {
  border-color: #7e56d9;
  /* Set to your preferred active color */
  box-shadow: 0 0 5px rgba(126, 86, 217, 0.5);
  /* Optional glow effect */
}

.add-team-input7:hover {
  border-color: #7e56d9;
  /* Slightly darker border on hover */
}

.add-team-email-domain {
  width: 50%;
  position: relative;
  line-height: 20px;
  color: #475467;
  padding: 9px 0px 0px 8px;

}

.add-team-email-card {
  flex-direction: row;
  display: flex;
}

.add-team-form,
.add-team-input-field1 {
  flex-direction: column;
}

.add-team-input-field1 {
  align-items: flex-start;
  gap: 6px;
}

.add-team-form {
  align-items: flex-end;
}

.add-team-label11,
.add-team-label12 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 108px;
}

.add-team-label12 {
  min-width: 69px;
}

.add-team-section2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  max-width: 100%;
}

.add-team-divider12,
.add-team-section-container {
  align-self: stretch;
  max-width: 100%;
}

.add-team-section-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 24px;
  box-sizing: border-box;
}

.add-team-divider12 {
  flex: 1;
  position: relative;
  background-color: #eaecf0;
}

.add-team-divider-wrapper,
.add-team-slide-out-menu-header-parent {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.add-team-divider-wrapper {
  height: 1px;
  flex-direction: row;
  padding: 0 24px;
  box-sizing: border-box;
}

.add-team-slide-out-menu-header-parent {
  flex-direction: column;
  gap: 24px;
  text-align: left;
  font-size: 14px;
  color: #344054;
  font-family: Inter;
}

.add-team-placeholder-icon91 {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.add-team-text275 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 48px;
}

.add-team-buttonsbutton49,
.add-team-text-padding51 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.add-team-buttonsbutton49 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  background-color: #fff;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  gap: 4px;
}

.add-team-buttonsbutton49:hover {
  background-color: #f9fafb;
}

.add-team-text276 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 55px;
}

.add-team-actions19,
.add-team-buttonsbutton50 {
  display: flex;
  flex-direction: row;
}

.add-team-buttonsbutton50 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.add-team-buttonsbutton50:hover {
  background-color: #6941c6;
}

.add-team-actions19 {
  /* width: 179px; */
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}

.add-team-content76,
.add-team-footer2 {
  align-self: stretch;
  display: flex;
  align-items: center;
}

.add-team-content76 {
  flex-direction: row;
  justify-content: flex-end;
  padding: 16px 24px;
}

.add-team-footer2 {
  border-top: 1px solid #eaecf0;
  flex-direction: column;
  justify-content: flex-start;
}

.add-team-panel {
  background-color: #fff;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;

  width: 400px;
  box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08),
    0 8px 8px -4px rgba(16, 24, 40, 0.03);
  /* border-left: 1px solid #eaecf0; */
  border: 1px solid #7e56d9;
  border-radius: 8px;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
  /* gap: 433px; */
}

.add-team-slide-out-menu {
  flex-direction: row;
  justify-content: flex-end;
  /* padding: 0 0 0 40px; */
  line-height: normal;
  letter-spacing: normal;

  border-radius: 10px;
  background-color: #fff;
  max-width: 100%;
  /* overflow: hidden; */
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;

  position: absolute;

  z-index: 3;
  right: 3px;
  /* transform: translateX(100%);  */
  transition: transform 0.3s ease-in-out;


}

.add-team-slide-out-menu.open {
  transform: translateX(0);
}


@media screen and (max-width: 400px) {
  .add-team-panel {
    gap: 216px;
  }
}