@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

.custom-padding {
  padding: 0px !important;
}


.custom-banner {
  width: 500px;
  height: 200px;

}

.custom-logo {
  width: 200px;
  height: 200px;

}

.custom-banner-width,
.custom-logo-width {
  width: inherit;
}

.custom-logo-top {
  top: 65px !important;
  left: 0 !important;
}

.custom-status-text-positioning {
  margin-left: 650px !important;
  margin-top: -26px !important;
  border: 1px solid red;
  border-radius: 12px;
  padding: 0px !important;
}

.custom-edit-button-positioning {
  margin-left: 518px !important;
  margin-top: -26px !important;
  border: 1px solid #667085;
  border-radius: 12px;
  padding: 0px !important;
}

.custom-verify-button-positioning {
  margin-left: 380px !important;
  margin-top: -26px !important;
  border: 1px solid #667085;
  border-radius: 12px;
  padding: 0px !important;
}

.custom-verify-button-positioning a {
  color: #667085;
}

.custom-edit-button-positioning a {
  color: #667085 !important;
}

.custom-edit-button-positioning:hover,
.custom-verify-button-positioning:hover {
  background-color: #667085;
}

.custom-edit-button-positioning:hover a,
.custom-verify-button-positioning:hover a {
  color: white !important;
}

.business-display-dashboard-frame-layout-setti-child,
.business-display-frame-child {
  position: relative;
  background-color: #fff;
  display: none;
}

.business-display-dashboard-frame-layout-setti-child {
  align-self: stretch;
  height: 2583px;
  z-index: 1;
}

.business-display-frame-child {
  height: 72px;
  width: 1440px;
  max-width: 100%;
}

.business-display-gerator-04-1-icon {
  height: 32px;
  width: 121px;
  position: relative;
  object-fit: cover;
  display: none;
}

.business-display-logo,
.business-display-logo-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-display-logo {
  align-self: stretch;
  height: 32px;
  flex-direction: row;
  background-image: url("../../icons/Gerator/profile_settings/gerator04-1@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.business-display-logo-wrapper {
  width: 121px;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
}

.business-display-bar-chart-01-icon,
.business-display-dot-icon {
  height: 10px;
  width: 10px;
  position: relative;
  display: none;
}

.business-display-bar-chart-01-icon {
  height: 24px;
  width: 24px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.business-display-text {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 30px;
}

.business-display-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
}

.business-display-text1 {
  align-self: stretch;
  position: relative;
  line-height: 18px;
  font-weight: 500;
}

.business-display-badge {
  height: 22px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
}

.business-display-chevron-down-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.business-display-nav-item-base {
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  gap: 8px;
}

.business-display-text2,
.business-display-text3 {
  position: relative;
  font-family: Inter;
}

.business-display-text2 {
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #18222f;
  text-align: left;
  display: inline-block;
  min-width: 29px;
}

.business-display-text3 {
  align-self: stretch;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #344054;
  text-align: center;
}

.business-display-badge1,
.business-display-nav-item-base1 {
  background-color: #f9fafb;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.business-display-badge1 {
  height: 22px;
  border-radius: 9999px;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  padding: 2px 7px;
}

.business-display-nav-item-base1 {
  cursor: pointer;
  border: 0;
  padding: 8px 12px;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  gap: 8px;
}

.business-display-text4 {
  text-decoration: none;
  position: relative;
  line-height: 24px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 38px;
}

.business-display-navigation,
.business-display-navigation-wrapper {
  display: flex;
  justify-content: flex-start;
}

.business-display-navigation {
  flex-direction: row;
  align-items: center;
  gap: 4px;
  z-index: 1;
}

.business-display-navigation-wrapper {
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
}

.business-display-label {
  width: 47px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.business-display-search-lg-icon,
.business-display-text6 {
  position: relative;
  overflow: hidden;
}

.business-display-search-lg-icon {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
}

.business-display-text6 {
  text-decoration: none;
  flex: 1;
  line-height: 24px;
  color: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.business-display-content3,
.business-display-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.business-display-content3 {
  flex: 1;
  gap: 8px;
}

.business-display-input {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  padding: 8px 13px;
  font-size: 16px;
  color: #667085;
}

.business-display-frame-group,
.business-display-frame-parent,
.business-display-input-with-label {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-display-input-with-label {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  z-index: 1;
  font-size: 14px;
}

.business-display-frame-group,
.business-display-frame-parent {
  flex-direction: row;
  max-width: 100%;
}

.business-display-frame-group {
  flex: 1;
  gap: 38px;
}

.business-display-frame-parent {
  width: 754px;
  gap: 70px;
}

.business-display-icon {
  height: 19px;
  width: 19px;
  position: relative;
}

.business-display-nav-item-button {
  width: 40px;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
  box-sizing: border-box;
  z-index: 1;
}

.business-display-nav-item-button-child {
  height: 18.3px;
  width: 14.6px;
  position: relative;
}

.business-display-avatar-button1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 3px 0 0;
}

.business-display-contrast-border {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
  display: none;
}

.business-display-avatar {
  height: 40px;
  width: 40px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../icons/Gerator/profile_settings/avatar@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 1;
}

.business-display-avatar-button-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}

.business-display-dashboard-frame-layout-setti-inner,
.business-display-rectangle-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-sizing: border-box;
  max-width: 100%;
}

.business-display-rectangle-parent {
  flex: 1;
  background-color: #fff;
  justify-content: space-between;
  padding: 14px 64px 14px 31px;
  top: 0;
  z-index: 99;
  position: sticky;
  gap: 20px;
  text-align: left;
  font-size: 16px;
  color: #344054;
  font-family: Inter;
}

.business-display-dashboard-frame-layout-setti-inner {
  align-self: stretch;
  justify-content: flex-start;
  padding: 0 0 135px;
}

.business-display-divider-icon {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  z-index: 1;
}

.business-display-divider-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 134px;
  box-sizing: border-box;
  max-width: 100%;
  margin-top: -134px;
}

.business-display-home-line-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.business-display-nav-item-button2,
.business-display-nav-item-button3 {
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px;
}

.business-display-nav-item-button3 {
  background-color: #f9fafb;
}

.business-display-navigation2 {
  padding: 0 16px;
  gap: 8px;
}

.business-display-navigation1,
.business-display-navigation2,
.business-display-navigation3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-display-navigation1 {
  padding: 32px 0 0;
}

.business-display-navigation3 {
  gap: 8px;
}

.business-display-contrast-border1 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 9999px;
  border: 0.8px solid rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  overflow: hidden;
}

.business-display-avatar1 {
  width: 48px;
  height: 48px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../icons/Gerator/profile_settings/avatar1@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.business-display-content4,
.business-display-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.business-display-footer {
  justify-content: flex-start;
  padding: 0 16px 24px;
  gap: 24px;
}

.business-display-content4 {
  align-self: stretch;
  border-right: 1px solid #eaecf0;
  justify-content: space-between;
}

.business-display-sub-nav-title {
  text-decoration: none;
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  color: inherit;
}

.business-display-arrow-narrow-left-icon {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
}

.business-display-sub-nav-header {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 13px 0 0;
  gap: 5px;
}

.business-display-text7 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 46px;
}

.business-display-badge3,
.business-display-content6,
.business-display-nav-item-base3 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.business-display-content6 {
  flex: 1;
  display: flex;
  padding: 0 144px 0 0;
  gap: 12px;
}

.business-display-badge3,
.business-display-nav-item-base3 {
  background-color: #f9fafb;
}

.business-display-badge3 {
  height: 22px;
  border-radius: 9999px;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  display: none;
  padding: 2px 7px;
  text-align: center;
  font-size: 12px;
  color: #344054;
}

.business-display-nav-item-base3 {
  align-self: stretch;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  padding: 8px 12px;
  gap: 8px;
  color: #18222f;
}

.business-display-text9 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 49px;
}

.business-display-content7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 103px 0 0;
  gap: 12px;
}

.business-display-text10 {
  width: 14px;
  position: relative;
  line-height: 18px;
  font-weight: 500;
  display: inline-block;
  min-width: 14px;
}

.business-display-badge4 {
  width: 30px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 0 7px;
  text-align: center;
  font-size: 12px;
}

.business-display-badge4,
.business-display-content8,
.business-display-nav-item-base4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.business-display-nav-item-base4 {
  align-self: stretch;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  padding: 8px 12px;
  gap: 8px;
}

.business-display-content8 {
  flex: 1;
  padding: 0 141px 0 0;
  gap: 12px;
}

.business-display-text19 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 48px;
}

.business-display-content12,
.business-display-content5,
.business-display-navigation4 {
  display: flex;
  justify-content: flex-start;
}

.business-display-content12 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 142px 0 0;
  gap: 12px;
}

.business-display-content5,
.business-display-navigation4 {
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
}

.business-display-navigation4 {
  gap: 4px;
  color: #344054;
}

.business-display-content5 {
  padding: 36px 16px 0;
  gap: 16px;
}

.business-display-supporting-text,
.business-display-title {
  align-self: stretch;
  position: relative;
  line-height: 20px;
}

.business-display-title {
  font-weight: 600;
}

.business-display-supporting-text {
  color: #475467;
  white-space: nowrap;
}

.business-display-text21 {
  width: 214px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-display-buttonsbutton {
  margin: 0 !important;
  position: absolute;
  top: -8px;
  right: 12px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.business-display-account,
.business-display-sub-nav {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
}

.business-display-account {
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 20px 24px;
  position: relative;
  gap: 47px;
  font-size: 14px;
}

.business-display-sub-nav {
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.business-display-breadcrumb-button-base,
.business-display-sidebar-navigation {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-display-sidebar-navigation {
  height: 1260px;
  width: 362px;
  background-color: #fff;
  border-right: 1px solid #eaecf0;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  max-width: 100%;
  z-index: 1;
}

.business-display-breadcrumb-button-base {
  border-radius: 6px;
  padding: 4px;
}

.business-display-chevron-right-icon {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.business-display-text22 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  min-width: 74px;
}

.business-display-breadcrumb-button-base1 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.business-display-text23 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.business-display-breadcrumb-button-base2 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 7px;
}

.business-display-chevron-right-icon2 {
  height: 16px;
  width: 16px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.business-display-text24 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  white-space: nowrap;
}

.business-display-breadcrumb-button-base3 {
  align-self: stretch;
  border-radius: 6px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.business-display-text25 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 57px;
}

.business-display-breadcrumb-button-base4 {
  border-radius: 6px;
  background-color: #f9fafb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  color: #344054;
}

.business-display-tabs {
  gap: 8px;
}

.business-display-breadcrumbs,
.business-display-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.business-display-divider-icon1 {
  width: 255px;
  height: 1px;
  position: relative;
  display: none;
}

.business-display-page-header {
  align-self: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.business-display-container {
  padding: 0 32px;
}

.business-display-container,
.business-display-header-section {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-display-main-child {
  width: 1px;
  height: 29px;
  position: relative;
  border-right: 1px solid #000;
}

.business-display-main,
.business-display-main-child,
.business-display-main-item {
  flex-shrink: 0;
  box-sizing: border-box;
}

.business-display-main-item {
  width: 1px;
  height: 43px;
  position: relative;
  border-right: 1px solid #000;
}

.business-display-main {
  align-self: stretch;
  height: 60px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 0 0;
  gap: 32px;
  z-index: 1;
}

.business-display-text26 {
  height: 20px;
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
}

.business-display-breadcrumb-button-base6,
.business-display-breadcrumb-button-base7 {
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.business-display-breadcrumb-button-base6 {
  flex: 1;
  padding: 4px 8px;
  min-width: 47px;
}

.business-display-breadcrumb-button-base7 {
  height: 28px;
  padding: 4px 7px;
}

.business-display-text29 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.business-display-breadcrumbs1,
.business-display-tabs1 {
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.business-display-tabs1 {
  flex: 1;
  display: flex;
  gap: 8px;
}

.business-display-breadcrumbs1 {
  width: 278px;
  display: none;
}

.business-display-page-title {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 38px;
  font-weight: 600;
  font-family: inherit;
}

.business-display-supporting-text1 {
  width: 445px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  display: none;
  max-width: 100%;
}

.business-display-text-and-supporting-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 320px;
  max-width: 100%;
  font-size: 30px;
}

.business-display-placeholder-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
  min-height: 20px;
}

.business-display-text-padding {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.business-display-button,
.business-display-button1,
.business-display-button2 {
  height: 40px;
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  gap: 4px;
}

.business-display-button {
  flex-direction: row;
  align-items: center;
  padding: 10px 14px;
}

.business-display-button1,
.business-display-button2 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: #fff;
  padding: 10px 13px;
}

.business-display-button1 {
  border: 1px solid #d6bbfb;
  flex-direction: row;
  align-items: center;
  color: #6941c6;
}

.business-display-button2 {
  border: 1px solid #d0d5dd;
  color: #344054;
}

.business-display-actions,
.business-display-button2,
.business-display-button3 {
  flex-direction: row;
  align-items: center;
}

.business-display-button3 {
  height: 40px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #7e56d9;
  border: 1px solid #7e56d9;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 10px 13px;
  gap: 4px;
  color: #fff;
}

.business-display-actions {
  display: none;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
  color: #475467;
}

.business-display-text34 {
  width: calc(100% - 20px);
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  flex: 1;
  position: relative;
  line-height: 24px;
  color: #667085;
  text-align: left;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 158px;
  padding: 0;
}

.business-display-input-with-label1,
.business-display-input1 {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.business-display-input1 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
}

.business-display-input-with-label1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

.business-display-hint-text {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.business-display-content13,
.business-display-input-dropdown {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-display-input-dropdown {
  flex: 1;
  flex-direction: column;
  gap: 8px;
  min-width: 200px;
  max-width: 320px;
  color: #344054;
}

.business-display-content13 {
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 2px 0 0;
  box-sizing: border-box;
  gap: 16px 14px;
  color: #101828;
}

.business-display-content13,
.business-display-divider-icon2,
.business-display-page-header1 {
  align-self: stretch;
  max-width: 100%;
}

.business-display-divider-icon2 {
  position: relative;
  overflow: hidden;
  max-height: 100%;
  display: none;
}

.business-display-page-header1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}

.business-display-text35 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 44px;
}

.business-display-tab-button-base {
  border-bottom: 2px solid #7e56d9;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 4px 9px;
  color: #6941c6;
}

.business-display-text36 {
  text-decoration: none;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 62px;
}

.business-display-tab-button-base1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 4px 12px;
}

.business-display-badge-count,
.business-display-text38 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 38px;
}

.business-display-badge-count {
  line-height: 18px;
  font-weight: 500;
  min-width: 8px;
}

.business-display-badge10,
.business-display-tab-button-base3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.business-display-badge10 {
  width: 24px;
  border-radius: 9999px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  justify-content: flex-start;
  padding: 0 7px;
  text-align: center;
  font-size: 12px;
  color: #344054;
}

.business-display-tab-button-base3 {
  height: 32px;
  justify-content: center;
  padding: 0 4px 11px;
  gap: 8px;
}

.business-display-text39,
.business-display-text40,
.business-display-text43 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 30px;
}

.business-display-text40,
.business-display-text43 {
  text-decoration: none;
  color: inherit;
  min-width: 42px;
}

.business-display-text43 {
  min-width: 83px;
}

.business-display-tab-button-base7 {
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 4px 11px;
  box-sizing: border-box;
  gap: 7px;
}

.business-display-text45,
.business-display-text46 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 82px;
}

.business-display-text46 {
  min-width: 24px;
}

.business-display-tab-button-base9,
.business-display-tabs2 {
  display: flex;
  flex-direction: row;
}

.business-display-tab-button-base9 {
  width: 31px;
  align-items: center;
  justify-content: center;
  padding: 0 3px 12px;
  box-sizing: border-box;
}

.business-display-tabs2 {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  max-width: 100%;
}

.business-display-container1,
.business-display-horizontal-tabs {
  align-self: stretch;
  flex-direction: column;
  box-sizing: border-box;
}

.business-display-horizontal-tabs {
  border-bottom: 1px solid #eaecf0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  color: #667085;
}

.business-display-container1 {
  padding: 0 32px;
  gap: 24px;
}

.business-display-container1,
.business-display-header-content,
.business-display-header-section1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.business-display-header-section1 {
  flex: 1;
  flex-direction: column;
}

.business-display-header-content {
  align-self: stretch;
  flex-direction: row;
  padding: 0 0 12px;
  box-sizing: border-box;
}

.business-display-text47 {
  align-self: stretch;
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.business-display-button4,
.business-display-button5,
.business-display-text-padding4 {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.business-display-text-padding4 {
  align-self: stretch;
  flex: 1;
  display: flex;
  padding: 0 2px;
}

.business-display-button4,
.business-display-button5 {
  width: 86px;
  border-radius: 8px;
  overflow: hidden;
  display: none;
  padding: 10px 14px;
  box-sizing: border-box;
  gap: 4px;
}

.business-display-button5 {
  width: 106px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  background-color: #fff;
  border: 1px solid #d6bbfb;
  padding: 10px 13px;
  color: #6941c6;
}

.business-display-dots-vertical-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.business-display-dropdown {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-display-supporting-text2,
.business-display-toggle-title,
.business-display-title1 {
  align-self: stretch;
  position: relative;
}

.business-display-toggle-title {
  line-height: 28px;
  font-weight: 600;
}

.business-display-toggle-title1 {
  line-height: 28px;
  font-weight: 600;
  /* margin: 25px 0px 0px 20px; */
  color: #7e56d9;
}

.business-display-toggle-title2 {
  line-height: 28px;
  font-weight: 600;
  margin: 200px 0px 0px 20px;
  color: #7e56d9;
}

.business-display-supporting-text2 {
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.business-display-toggle-header {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  min-width: 320px;
  max-width: 100%;
}

.business-display-frame-item {
  height: 40px;
  width: 156px;
  position: relative;
  background-color: #fff;
  display: none;
}

.business-display-button6,
.business-display-toggle-base {
  border-radius: 9999px;
  overflow: hidden;
  flex-shrink: 0;
}

.business-display-button6 {
  height: 16px;
  width: 16px;
  position: relative;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  background-color: #fff;
}

.business-display-toggle-base {
  height: 20px;
  width: 36px;
  background-color: #f2f4f7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 2px;
  box-sizing: border-box;
  z-index: 1;
}

.business-display-supporting-text3,
.business-display-switch-label {
  position: relative;
  line-height: 20px;
}

.business-display-switch-label {
  letter-spacing: -0.02px;
  font-weight: 500;
  display: inline-block;
  min-width: 97px;
  z-index: 1;
}

.business-display-supporting-text3 {
  align-self: stretch;
  width: 300px;
  color: #475467;
  display: none;
}

.business-display-rectangle-group {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0 10px 15px;
  gap: 8px;
}

.business-display-button-labels {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 63px;
}

.business-display-button7 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 16px;
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-display-text49 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;
  min-width: 110px;
}

.business-display-button8,
.business-display-toggle-controls,
.business-display-toggle-header-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-display-button8 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 16px;
  background-color: #7e56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  overflow: hidden;
}

.business-display-toggle-controls,
.business-display-toggle-header-parent {
  gap: 12px;
  max-width: 100%;
  font-size: 14px;
  color: #344054;
}

.business-display-toggle-header-parent {
  align-self: stretch;
  gap: 16px;
  font-size: 18px;
  color: #101828;
}

.business-display-divider-icon3 {
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.business-display-divider-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 4px;
  box-sizing: border-box;
  max-width: 100%;
}

.business-display-text50 {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 600;
}

.business-display-section-label {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 200px;
  max-width: 280px;
}

.business-display-label2 {
  width: 36px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.business-display-content16 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Inter;
  font-size: 16px;
  color: #101828;
  min-width: 130px;
}

.business-display-help-icon {
  height: 16px;
  width: 16px;
  position: relative;
  display: none;
}

.business-display-input-field,
.business-display-input2 {
  display: flex;
  justify-content: flex-start;
}

.business-display-input2 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
}

.business-display-input-field {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  min-width: 159px;
}

.business-display-label3 {
  width: 96px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.business-display-content17 {
  width: calc(100% - 46px);
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  color: #101828;
  min-width: 113px;
}

.business-display-content15,
.business-display-content17,
.business-display-input-fields,
.business-display-input3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.business-display-input3 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
}

.business-display-content15,
.business-display-input-fields {
  align-items: flex-start;
}

.business-display-input-fields {
  flex: 1;
  gap: 24px;
  min-width: 480px;
  max-width: 512px;
}

.business-display-content15 {
  align-self: stretch;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16px 32px;
  max-width: 100%;
}

.business-display-divider {
  align-self: stretch;
  height: 1px;
  position: relative;
  background-color: #eaecf0;
}

.business-display-section-label1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 200px;
  max-width: 280px;
}

.business-display-extra-input-info {
  width: calc(100% - 40px);
  border: 0;
  outline: 0;
  font-family: Inter;
  font-size: 16px;
  background-color: transparent;
  height: 24px;
  flex: 1;
  position: relative;
  line-height: 24px;
  color: #101828;
  text-align: left;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 250px;
  max-width: calc(100% - 28px);
  padding: 0;
}

.business-display-content19,
.business-display-input4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.business-display-content19 {
  flex: 1;
  max-width: calc(100% - 28px);
}

.business-display-input4 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  padding: 8px 13px;
  max-width: 100%;
}

.business-display-hint-text2 {
  width: 320px;
  position: relative;
  line-height: 20px;
  color: #475467;
  display: none;
}

.business-display-content18,
.business-display-input-field1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-display-input-field1 {
  flex: 1;
  flex-direction: column;
  gap: 6px;
  min-width: 480px;
  max-width: 512px;
}

.business-display-content18 {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  /* padding: 0 102px 0 0; */
  box-sizing: border-box;
  gap: 16px 32px;
  max-width: 100%;
}

.business-display-upload-title {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 75px;
}

.business-display-help-icon3 {
  width: 16px;
  height: 16px;
  position: relative;
}

.business-display-label-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}

.business-display-supporting-text4 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: #475467;
}

.business-display-avatar2 {
  height: 64px;
  width: 64px;
  position: relative;
  border-radius: 9999px;
  background-image: url("../../icons/Gerator/profile_settings/avatar2@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.business-display-featured-icon {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 8px;
}

.business-display-text54 {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 101px;
}

.business-display-buttonsbutton1 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.business-display-text55 {
  position: relative;
  line-height: 20px;
  color: #475467;
  display: inline-block;
  min-width: 112px;
}

.business-display-action {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px;
  gap: 4px;
}

.business-display-supporting-text5 {
  align-self: stretch;
  position: relative;
  font-size: 12px;
  line-height: 18px;
  color: #475467;
  text-align: center;
}

.business-display-text-and-supporting-text1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.business-display-page-icon {
  position: absolute;
  height: 100%;
  width: 80%;
  top: 0;
  right: 2.5%;
  bottom: 0;
  left: 17.5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.business-display-file-type {
  position: relative;
  display: inline-block;
  min-width: 20px;
}

.business-display-file-type-wrap {
  position: absolute;
  height: 40%;
  width: 65%;
  top: 45%;
  right: 32.5%;
  bottom: 15%;
  left: 2.5%;
  border-radius: 2px;
  background-color: #7e56d9;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 3px;
  box-sizing: border-box;
  z-index: 1;
}

.business-display-cursor-icon,
.business-display-file-type-icon {
  position: absolute;
  margin: 0 !important;
}

.business-display-file-type-icon {
  width: 40px;
  height: 40px;
  right: 0;
  bottom: 4px;
  filter: drop-shadow(0 24px 48px rgba(16, 24, 40, 0.18));
  z-index: 1;
  text-align: center;
  font-size: 10px;
  color: #fff;
}

.business-display-cursor-icon {
  width: 20px;
  height: 20px;
  right: -8px;
  bottom: -4px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 2;
}

.business-display-content23,
.business-display-file-upload-base {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.business-display-content23 {
  align-self: stretch;
  align-items: center;
  position: relative;
  gap: 12px;
}

.business-display-file-upload-base {
  flex: 1;
  border-radius: 12px;
  background-color: #fff;
  border: 2px solid #7e56d9;
  box-sizing: border-box;
  align-items: flex-start;
  padding: 13px 24px;
  min-width: 278px;
  max-width: 100%;
}

.business-display-avatar-and-file-upload,
.business-display-content25 {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.business-display-avatar-and-file-upload {
  align-items: flex-start;
  gap: 20px;
  min-width: 480px;
  max-width: 512px;
  color: #6941c6;
}

.business-display-content25 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  color: #101828;
  min-width: 250px;
  max-width: 100%;
}

.business-display-supporting-text6,
.business-display-text59 {
  position: relative;
  line-height: 24px;
}

.business-display-text59 {
  font-weight: 500;
  display: inline-block;
  min-width: 208px;
}

.business-display-supporting-text6 {
  align-self: stretch;
  width: 54px;
  color: #475467;
  display: none;
}

.business-display-content29 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 360px 0 0;
  box-sizing: border-box;
  max-width: calc(100% - 28px);
}

.business-display-content29,
.business-display-input-dropdown1,
.business-display-input8 {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.business-display-input8 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  max-width: 100%;
  font-size: 16px;
  color: #101828;
}

.business-display-input-dropdown1 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  min-width: 480px;
  max-width: 512px;
}

.business-display-text61 {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 126px;
}

.business-display-content31 {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  max-width: 100%;
}

.business-display-input9 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  padding: 8px 13px;
  font-size: 16px;
  color: #101828;
}

.business-display-content33,
.business-display-input10,
.business-display-input9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
}

.business-display-content33 {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
  height: 24px;
  flex: 1;
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  color: #101828;
  min-width: 250px;
}

.business-display-input10 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  padding: 8px 13px;
}

.business-display-label-title {
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: inline-block;
  min-width: 67px;
}

.business-display-help-icon6 {
  height: 16px;
  width: 16px;
  position: relative;
}

.business-display-heading-and-help-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
}

.business-display-supporting-text8,
.business-display-text66 {
  position: relative;
  line-height: 24px;
}

.business-display-text66 {
  font-weight: 500;
}

.business-display-supporting-text8 {
  color: #475467;
  display: inline-block;
  min-width: 89px;
  white-space: nowrap;
}

.business-display-content41 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding: 0 116px 0 0;
  box-sizing: border-box;
  gap: 8px;
}

.business-display-content41,
.business-display-form-content,
.business-display-form-content-wrapper,
.business-display-input14 {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.business-display-input14 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  gap: 8px;
  font-size: 16px;
  color: #101828;
}

.business-display-form-content,
.business-display-form-content-wrapper {
  align-items: flex-start;
}

.business-display-form-content {
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.business-display-form-content-wrapper {
  align-self: stretch;
  flex-direction: row;
  padding: 0 32px;
  box-sizing: border-box;
  color: #344054;
}

.business-display-write-a-short {
  margin-block-start: 0;
  margin-block-end: 14px;
}

.business-display-this-is-also {
  margin: 0;
}

.business-display-dropdown-placeholder {
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 59px;
}

.business-display-content43 {
  flex: 1;
  flex-direction: row;
  align-items: center;
  min-width: 59px;
}

.business-display-content43,
.business-display-input-dropdown8,
.business-display-input15 {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.business-display-input15 {
  align-self: stretch;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  padding: 8px 13px;
  font-size: 16px;
  color: #101828;
}

.business-display-input-dropdown8 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  min-width: 156px;
  max-width: 240px;
}

.business-display-wysiwyg-editor-icon,
.business-display-wysiwyg-editor-icon2 {
  height: 32px;
  width: 32px;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 32px;
}

.business-display-wysiwyg-editor-icon2 {
  display: none;
}

.business-display-icons,
.business-display-wysiwyg-toolbar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
}

.business-display-wysiwyg-toolbar {
  align-self: stretch;
  align-items: center;
  padding: 0 84px 0 0;
  gap: 12px;
}

.business-display-label16 {
  width: 77px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: none;
}

.business-display-text68 {
  height: 130px;
  flex: 1;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: 100%;
}

.business-display-input16,
.business-display-textarea-input-field {
  align-self: stretch;
  align-items: flex-start;
  max-width: 100%;
}

.business-display-input16 {
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 13px;
  font-size: 16px;
  color: #101828;
}

.business-display-textarea-input-field {
  gap: 6px;
}

.business-display-content45,
.business-display-file-upload-base1,
.business-display-textarea-input-field,
.business-display-toolbar-and-text-input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.business-display-toolbar-and-text-input {
  flex: 1;
  align-items: flex-start;
  gap: 12px;
  min-width: 480px;
  max-width: 512px;
}

.business-display-content45,
.business-display-file-upload-base1 {
  align-self: stretch;
  align-items: center;
}

.business-display-content45 {
  gap: 12px;
}

.business-display-file-upload-base1 {
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  padding: 14px 23px;
}

.business-display-page-icon1 {
  height: 40px;
  width: 32px;
  position: absolute;
  margin: 0 !important;
  top: -18px;
  right: -12px;
}

.business-display-file-type-wrap1 {
  border-radius: 2px;
  background-color: #da2d20;
  flex-direction: row;
  padding: 2px 3px;
  z-index: 1;
}

.business-display-file-type-wrap1,
.business-display-item-container,
.business-display-item-info-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-display-item-info-container {
  flex-direction: row;
  position: relative;
}

.business-display-item-container {
  flex-direction: column;
  padding: 18px 0 0;
}

.business-display-supporting-text13,
.business-display-uploaded-file-name {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.business-display-uploaded-file-name {
  font-weight: 500;
}

.business-display-supporting-text13 {
  color: #475467;
}

.business-display-background,
.business-display-progress {
  align-self: stretch;
  width: 379px;
  position: relative;
  border-radius: 9999px;
  background-color: #eaecf0;
  display: none;
  max-width: 100%;
}

.business-display-progress {
  background-color: #7e56d9;
}

.business-display-item-progress-info,
.business-display-progress-bar {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.business-display-progress-bar {
  width: 379px;
  height: 8px;
  border-radius: 9999px;
  background-color: #7e56d9;
  flex-direction: row;
  z-index: 1;
}

.business-display-item-progress-info {
  flex: 1;
  flex-direction: column;
  gap: 10px;
  min-width: 278px;
}

.business-display-checkbox-base-icon {
  height: 16px;
  width: 16px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
}

.business-display-select-file-checkbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 0 21px;
}

.business-display-percentage {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 37px;
}

.business-display-item-status {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 28px;
  margin-left: -37px;
}

.business-display-file-upload-item-base,
.business-display-item-progress-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 100%;
}

.business-display-item-progress-container {
  flex: 1;
  justify-content: center;
  min-width: 278px;
  row-gap: 20px;
  text-align: left;
  font-size: 14px;
  color: #344054;
}

.business-display-file-upload-item-base {
  align-self: stretch;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  justify-content: flex-start;
  padding: 14px 15px 14px 16px;
  gap: 25px;
}

.business-display-page-icon2 {
  height: 40px;
  width: 32px;
  position: absolute;
  margin: 0 !important;
  top: -18px;
  right: -9px;
}

.business-display-file-type2 {
  position: relative;
  display: inline-block;
  min-width: 23px;
}

.business-display-file-type-wrap2 {
  border-radius: 2px;
  background-color: #155eef;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 3px;
  z-index: 1;
}

.business-display-text71 {
  flex: 1;
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.business-display-buttonsbutton3 {
  margin: 0 !important;
  position: absolute;
  top: calc(50% - 18px);
  right: -8px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.business-display-progress-bar-container {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  max-width: 100%;
}

.business-display-background1,
.business-display-progress1 {
  position: relative;
  border-radius: 9999px;
}

.business-display-background1 {
  align-self: stretch;
  width: 386px;
  background-color: #eaecf0;
  display: none;
  max-width: 100%;
}

.business-display-progress1 {
  height: 8px;
  width: 155.5px;
  background-color: #7e56d9;
  z-index: 1;
}

.business-display-progress-bar-wrapper,
.business-display-progress-bar1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.business-display-progress-bar1 {
  align-self: stretch;
  border-radius: 9999px;
  background-color: #eaecf0;
  flex-direction: row;
}

.business-display-progress-bar-wrapper {
  flex: 1;
  flex-direction: column;
  padding: 6px 0 0;
  box-sizing: border-box;
  min-width: 251px;
}

.business-display-percentage1 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 33px;
}

.business-display-progress-bar-container1 {
  align-self: stretch;
  flex-direction: row;
  gap: 9px;
}

.business-display-file-upload-item-base1,
.business-display-progress-bar-container-parent,
.business-display-progress-bar-container1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.business-display-progress-bar-container-parent {
  flex: 1;
  flex-direction: column;
  gap: 2px;
  min-width: 278px;
  text-align: left;
  font-size: 14px;
  color: #344054;
}

.business-display-file-upload-item-base1 {
  align-self: stretch;
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  flex-direction: row;
  padding: 14px 15px 14px 16px;
  gap: 22px;
}

.business-display-page-icon3 {
  height: 40px;
  width: 32px;
  position: absolute;
  margin: 0 !important;
  top: -18px;
  right: -15px;
}

.business-display-file-type3 {
  position: relative;
  display: inline-block;
  min-width: 17px;
}

.business-display-file-type-wrap3,
.business-display-progress2 {
  background-color: #7e56d9;
  z-index: 1;
}

.business-display-file-type-wrap3 {
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 3px;
}

.business-display-progress2 {
  height: 8px;
  width: 311.1px;
  position: relative;
  border-radius: 9999px;
}

.business-display-percentage2 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 32px;
}

.business-display-file-upload-item-base2,
.business-display-frame-div {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  max-width: 100%;
}

.business-display-file-upload-item-base2 {
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
  padding: 14px 15px 14px 16px;
  gap: 28px;
}

.business-display-file-queue {
  align-self: stretch;
  flex-direction: column;
  gap: 12px;
  max-width: 100%;
  text-align: center;
  font-size: 10px;
  color: #fff;
}

.business-display-content-wrapper,
.business-display-content44,
.business-display-file-queue,
.business-display-file-upload {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-display-file-upload {
  flex: 1;
  flex-direction: column;
  gap: 16px;
  min-width: 480px;
  max-width: 512px;
  color: #6941c6;
}

.business-display-content-wrapper,
.business-display-content44 {
  flex-direction: row;
  max-width: 100%;
}

.business-display-content44 {
  flex: 1;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 16px 32px;
}

.business-display-content-wrapper {
  align-self: stretch;
  padding: 0 32px 4px;
  box-sizing: border-box;
  color: #344054;
}

.business-display-divider-icon4,
.business-display-text73 {
  align-self: stretch;
  position: relative;
}

.business-display-divider-icon4 {
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}

.business-display-text73 {
  line-height: 20px;
  font-weight: 600;
  white-space: nowrap;
}

.business-display-button9,
.business-display-buttonsbutton5 {
  overflow: hidden;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.business-display-buttonsbutton5 {
  height: 20px;
  gap: 6px;
}

.business-display-button9 {
  align-self: stretch;
  border-radius: 8px;
  padding: 10px 14px;
  gap: 4px;
}

.business-display-text75 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #344054;
  text-align: left;
  display: inline-block;
  min-width: 48px;
}

.business-display-button10,
.business-display-text-padding7 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.business-display-button10 {
  cursor: pointer;
  border: 1px solid #d0d5dd;
  padding: 8px 13px;
  background-color: #fff;
  flex: 1;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  gap: 4px;
  min-width: 52px;
}

.business-display-text76 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-align: left;
  display: inline-block;

}

.business-display-text-padding8 {
  width: 37px;
  justify-content: center;
  padding: 0 1px;
  box-sizing: border-box;
}

.business-display-actions1,
.business-display-button11,
.business-display-content46,
.business-display-text-padding8 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.business-display-button11 {
  cursor: pointer;
  border: 1px solid #7e56d9;
  padding: 8px 13px;
  background-color: #7e56d9;
  width: 65px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  gap: 4px;
}

.business-display-actions1,
.business-display-content46 {
  justify-content: flex-end;
  max-width: 100%;
}

.business-display-actions1 {
  flex: 1;
  padding: 0 0 0 769px;
  box-sizing: border-box;
  gap: 12px;
}

.business-display-content46 {
  align-self: stretch;
  gap: 20px;
}

.business-display-main1,
.business-display-section-footer,
.business-display-upload-footer {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
}

.business-display-section-footer {
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.business-display-main1,
.business-display-upload-footer {
  align-items: flex-start;
  box-sizing: border-box;
}

.business-display-upload-footer {
  align-self: stretch;
  flex-direction: row;
  padding: 0 32px;
}

.business-display-main1 {
  flex: 1;
  background-color: #fff;
  overflow: hidden;
  flex-direction: column;
  padding: 32px 0 48px;
  gap: 20px;
  z-index: 1;
}

.business-display-main-wrapper {
  width: 1038px;
  flex-direction: row;
  padding: 0 24px;
  box-sizing: border-box;
}

.business-display-main-parent,
.business-display-main-wrapper,
.business-display-sidebar-navigation-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
}

.business-display-main-parent {
  flex: 1;
  flex-direction: column;
  min-width: 701px;
  font-size: 14px;
  color: #475467;
}

.business-display-sidebar-navigation-parent {
  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 0 0 137px;
  box-sizing: border-box;
  row-gap: 20px;
  margin-top: -134px;
  text-align: left;
  font-size: 16px;
  color: #101828;
  font-family: Inter;
}

.business-display-footer-text {
  position: relative;
  line-height: 20px;
}

.business-display-logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.business-display-gerator-10-2-icon {
  height: 38px;
  width: 38px;
  position: relative;
  object-fit: cover;
}

.business-display-logomark1 {
  box-shadow: 0 1.3px 3.75px rgba(16, 24, 40, 0.1),
    0 1.3px 2.5px rgba(16, 24, 40, 0.06);
  align-items: flex-start;
  justify-content: flex-start;
}

.business-display-logo-wrapper1,
.business-display-logomark,
.business-display-logomark1 {
  display: flex;
  flex-direction: row;
}

.business-display-logomark {
  filter: drop-shadow(0 1.3px 3.75px rgba(16, 24, 40, 0.1)) drop-shadow(0 1.3px 2.5px rgba(16, 24, 40, 0.06));
  align-items: flex-start;
  justify-content: flex-start;
}

.business-display-logo-wrapper1 {
  align-items: center;
  justify-content: center;
  gap: 29px;
}

.business-display-content47,
.business-display-logo1 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-display-content47 {
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  gap: 20px;
}

.business-display-container2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 32px;
  box-sizing: border-box;
  max-width: 1280px;
}

.business-display-divider-icon5,
.business-display-footer1 {
  align-self: stretch;
  overflow: hidden;
  margin-top: -134px;
}

.business-display-footer1 {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 48px 80px;
  z-index: 1;
  text-align: left;
  font-size: 16px;
  color: #475467;
  font-family: Inter;
}

.business-display-divider-icon5 {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  z-index: 2;
}

.business-display-dashboard-frame-layout-setti,
.business-display-dashboard-frame-layout-setti1 {
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-display-dashboard-frame-layout-setti1 {
  flex: 1;
  flex-direction: column;
  padding: 7px 0 0;
  box-sizing: border-box;
  min-height: 1480px;
  max-width: 100%;
}

.business-display-dashboard-frame-layout-setti {
  width: 100%;
  position: relative;
  overflow: hidden;
  flex-direction: row;
  line-height: normal;
  letter-spacing: normal;
}

.business-display-span {
  color: #f04438;
}

.business-display-tagged-inputs {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;

  align-items: flex-start;

  flex: 1;
  flex-direction: column;
  gap: 6px;

  align-self: stretch;
  flex-direction: row;
  z-index: 1;
}

.business-display-input-field12 {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  flex: 1;
  flex-direction: column;
  gap: 6px;
}

.business-display-input-with-label19 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 20px 0 0;

}

.business-display-label21 {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  font-family: Inter;
  text-align: left;
  display: inline-block;
  min-width: 50px;
}

.business-display-transaction-type {
  color: #344054;
}

.business-display-file-button-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}

.business-display-buttonsbutton4 {
  border-radius: 8px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  gap: 6px;
}

.business-display-search-lg-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}

.business-display-text-padding6 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}

.business-display-content-display-div {
  width: 100%;

  height: 900px;
  box-shadow: 0px 0px 10px rgba(128, 128, 128, 0.5);
  border: none;
  border-radius: 10px;
}


.business-display-supporting-text2 {
  align-self: stretch;
  position: relative;
}

.business-display-supporting-text2 {
  font-size: 14px;
  line-height: 20px;
  color: #475467;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: -24px 0 0 45px;
}

.business-display-marker-pin-02-icon {
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-left: 16px;
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  min-height: 20px;
}

.business-display-bookmark-check-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;

}

.business-display-bookmark-check-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 11px 0 0;
  width: 20px;
  margin: -58px 0 0 780px;
}

.business-display-supporting-text-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: -30px 0 0 800px;
  width: 114px;
  flex-direction: column;
  padding: 9px 0 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 12px;
  color: #475467;
}

.business-display-supporting-text22 {
  align-self: stretch;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  min-width: 114px;
  z-index: 1;
}

.business-display-rectangle-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 !important;
  /* position: absolute; */
  /* top: 580px; */
  /* left: 440px; */
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  padding: 14px 14px 13px 15px;
  /* z-index: 2; */
}

.business-display-frame-item {
  position: relative;
  border-radius: 12px;
  display: none;

  height: 261px;
  width: 261px;
  background-color: #fff;
  border: 1px solid #eaecf0;
  box-sizing: border-box;
}

.business-display-rectangle-container {
  border-radius: 12px;
  background-color: #f5f5f5;
  /* padding: 38px 35px 37px; */
  z-index: 3;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.business-display-frame-inner {
  position: relative;
  border-radius: 12px;
  display: none;

  height: 230px;
  width: 230px;
  background-color: #f5f5f5;
}

.business-display-f4b711e503bc72fe396e5043e0c2-7-icon {
  height: 155px;
  width: 100%;
  position: relative;
  object-fit: cover;
  z-index: 4;
  border-radius: 12px;
  border: 1px solid #eaecf0;
  /* height: 46px;
  width: 46px; */
  position: relative;
  object-fit: cover;
  z-index: 1;
}

.business-display-details {
  align-self: stretch;
  border-radius: 12px;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  padding: 18px 23px 18px 23px;
  gap: 20px;
  font-size: 14px;
  width: 90%;

}

.business-display-text32 {
  position: relative;
  line-height: 20px;
  font-weight: 500;
  display: inline-block;
  min-width: 110px;
}

.business-display-text32 {
  line-height: 24px;
  font-weight: 600;
  min-width: 85px;
}

.business-display-label-and-text {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.business-display-label-and-text1 {
  width: 126px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.business-display-label-and-text2 {
  width: 114px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.business-display-label-and-text3 {
  width: 198px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
}

.business-display-text14 {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.business-display-buttonsbutton6 {

  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 16px;
  color: #344054;
}

.business-display-chevron-down-icon {
  height: 20px;
  width: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}

.business-display-text33 {
  position: relative;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  min-width: 126px;
  white-space: nowrap;
}

.business-display-text14a {
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.business-display-buttonsbutton8 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 16px;
  color: #6941c6;
}

.business-display-text35a {
  position: relative;
  line-height: 24px;
  font-weight: 600;
}


.business-display-content4a {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;

  margin: 20px 0 0 20px;
  flex-direction: row;

  flex: 1;
  flex-wrap: wrap;
  align-content: flex-start;
  z-index: 1;
}

.business-display-about {

  flex: 1;
  flex-direction: column;
  min-width: 720px;
}


.business-display-paragraph-and-social-icons {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  align-self: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  max-width: 100%;
}

.business-display-paragraph-and-button {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  width: 640px;
  flex-direction: column;
  padding: 0;
  box-sizing: border-box;
  gap: 20px;
  min-width: 480px;
  max-width: 640px;
}

.business-display-paragraph {
  width: 817px;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: 128%;
  flex-shrink: 0;
}

.business-display-paragraph1 {
  width: 817px;
  position: relative;
  line-height: 24px;
  display: inline-block;
  max-width: 128%;
  flex-shrink: 0;
  margin: 20px 0 0 20px;
}

.business-display-as-lawmakers-seek {
  margin-block-start: 0;
  margin-block-end: 16px;
  font-weight: 600;
}

.business-display-the-texas-hospital {
  margin: 0;
  white-space: pre-wrap;
}

.business-display-buttonsbutton5a {
  width: 130px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 14px;
  color: #6941c6;
}

.business-display-text31 {
  align-self: stretch;
  width: 73px;
  position: relative;
  line-height: 20px;
  font-weight: 600;
  display: none;
}

.business-display-dashboard-arrow-square-up-right-wrapper1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  flex-direction: column;
  padding: 8px 0 0;
}

.business-display-dashboard-arrow-square-up-right-icon1 {
  width: 24px;
  height: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}

.business-display-badge-icon1 {
  height: max-content;
  width: max-content;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  top: 5px;
  min-height: 20px;
}

.business-display-dots-icon1 {
  height: max-content;
  width: max-content;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  top: 10px;
  min-height: 20px;
}

.business-display-dropdown-wrapper {
  position: absolute; 
  z-index: 3;
  top: 295px;
  right: 20px;
}

/* Verification css  */


.business-form-contact-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: max-content;
  align-self: stretch;
  /* padding: 0 52px; */
}

.business-form-message-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  flex: 1;
  gap: 2px;
}

.business-form-navigation {
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0 0;
  display: flex;
  justify-content: flex-start;
}

.business-form-bookmark-check-icon {
  width: 20px;
  height: 20px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 2;
}

.business-form-supporting-text10 {
  flex: 1;
  position: relative;
  line-height: 24px;
  font-weight: 500;
  display: inline-block;
  /* min-width: 114px; */
  z-index: 2;
}

@media screen and (max-width: 1275px) {
  .business-display-main1 {
    padding-top: 154px;
    padding-bottom: 20px;
    box-sizing: border-box;
  }

  .business-display-sidebar-navigation-parent {
    padding-bottom: 58px;
    box-sizing: border-box;
  }

  .business-display-container2 {
    max-width: 100%;
  }
}

@media screen and (max-width: 1100px) {

  .business-display-tabs2,
  .business-display-toggle-header-parent {
    flex-wrap: wrap;
  }

  .business-display-content18 {
    padding-right: 51px;
    box-sizing: border-box;
  }

  .business-display-actions1 {
    flex-wrap: wrap;
    padding-left: 384px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 750px) {
  .business-display-frame-group {
    gap: 19px;
  }

  .business-display-frame-parent {
    gap: 35px;
  }

  .business-display-rectangle-parent {
    padding-right: 32px;
    box-sizing: border-box;
  }

  .business-display-main {
    gap: 16px;
  }

  .business-display-page-title {
    font-size: 24px;
    line-height: 30px;
  }

  .business-display-actions {
    flex-wrap: wrap;
  }

  .business-display-input-dropdown {
    max-width: 100%;
  }

  .business-display-input-fields,
  .business-display-toggle-controls {
    flex-wrap: wrap;
  }

  .business-display-input-field1,
  .business-display-input-fields {
    max-width: 100%;
    min-width: 100%;
  }

  .business-display-avatar-and-file-upload {
    flex-wrap: wrap;
  }

  .business-display-avatar-and-file-upload,
  .business-display-input-dropdown1 {
    max-width: 100%;
    min-width: 100%;
  }

  .business-display-content41,
  .business-display-input14 {
    flex-wrap: wrap;
  }

  .business-display-wysiwyg-toolbar {
    flex-wrap: wrap;
    padding-right: 42px;
    box-sizing: border-box;
  }

  .business-display-toolbar-and-text-input {
    max-width: 100%;
    min-width: 100%;
  }

  .business-display-file-upload-item-base,
  .business-display-file-upload-item-base1,
  .business-display-file-upload-item-base2,
  .business-display-frame-div,
  .business-display-item-progress-container,
  .business-display-progress-bar-container1 {
    flex-wrap: wrap;
  }

  .business-display-file-upload {
    max-width: 100%;
  }

  .business-display-file-upload,
  .business-display-main-parent {
    min-width: 100%;
  }

  .business-display-sidebar-navigation-parent {
    padding-bottom: 38px;
    box-sizing: border-box;
  }

  .business-display-container2 {
    gap: 16px;
  }

  .business-display-footer1 {
    gap: 32px;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 450px) {
  .business-display-frame-parent {
    gap: 17px;
  }

  .business-display-account {
    gap: 23px;
  }

  .business-display-tabs1 {
    flex-wrap: wrap;
  }

  .business-display-page-title {
    font-size: 18px;
    line-height: 23px;
  }

  .business-display-content15 {
    gap: 16px;
  }

  .business-display-content18 {
    gap: 16px;
    padding-right: 25px;
    box-sizing: border-box;
  }

  .business-display-action {
    flex-wrap: wrap;
  }

  .business-display-content29,
  .business-display-content41 {
    padding-right: 180px;
    box-sizing: border-box;
  }

  .business-display-content41 {
    padding-right: 58px;
  }

  .business-display-input15 {
    flex-wrap: wrap;
  }

  .business-display-wysiwyg-toolbar {
    padding-right: 20px;
    box-sizing: border-box;
  }

  .business-display-item-status {
    margin-left: 0;
  }

  .business-display-content44 {
    gap: 16px;
  }

  .business-display-actions1 {
    padding-left: 192px;
    box-sizing: border-box;
  }

  .business-display-footer1 {
    gap: 16px;
  }
}