.skeleton-table-loader {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-gap: .2rem;
    width: 100%;
}

.skeleton-table-item {
    height: 40px;
    background-color: #f0f0f0;
    animation: shimmer 1s infinite linear;
}


/* .skeleton-table-item::before {
    content: "";
    display: block;
    padding-top: 100%;
} */

@keyframes shimmer {
    0% {
        background-color: #f0f0f0;
    }

    50% {
        background-color: #e0e0e0;
    }

    100% {
        background-color: #f0f0f0;
    }
}