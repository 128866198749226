.report-abuse-featured-icon2 {
    /* height: 40px;
    width: 40px; */
    position: relative;
    border-radius: 8px;
    padding-top: 3px;
  }
  .report-abuse-supporting-text91,
  .report-abuse-title9 {
    align-self: stretch;
    position: relative;
    white-space: nowrap;
  }
  .report-abuse-title9 {
    text-decoration: none;
    line-height: 30px;
    font-weight: 600;
    font-size: 18px;
    color: inherit;
  }
  .report-abuse-supporting-text91 {
    font-size: 14px;
    line-height: 20px;
    color: #475467;
  }
  .report-abuse-content71,
  .report-abuse-text-and-supporting-text26 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
  }
  .report-abuse-content71 {
    margin: 0 !important;
    position: absolute;
    top: 24px;
    left: 24px;
    flex-direction: row;
    gap: 16px;
  }
  .report-abuse-button-close-x6,
  .report-abuse-slide-out-menu-header {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
  }
  .report-abuse-button-close-x6 {
    height: 20px;
    margin: 0 !important;
    position: absolute;
    top: 27px;
    right: 26px;
    border-radius: 8px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    z-index: 1;
    cursor: pointer;
  }
  .report-abuse-slide-out-menu-header {
    align-self: stretch;
    height: 78px;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 24px;
    position: relative;
    gap: 8px;
    text-align: left;
    font-size: 20px;
    color: #101828;
    font-family: Inter;
  }
  .report-abuse-label9 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 67px;
  }
  .report-abuse-content72 {
    width: 100%;
    border: 0;
    outline: 0;
    background-color: transparent;
    height: 24px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-family: Inter;
    font-size: 16px;
    color: #101828;
    min-width: 194px;
    max-width: 100%;
  }
  .report-abuse-help-icon2 {
    height: 16px;
    width: 16px;
    position: relative;
    display: block;
  }
  .report-abuse-input6 {
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 13px;
    gap: 8px;
    max-width: 100%;
  cursor: pointer;
 
  }

  .report-abuse-input6:focus {
    border-color: #7e56d9; /* Set to your preferred active color */
    box-shadow: 0 0 5px rgba(126, 86, 217, 0.5); /* Optional glow effect */
  }

  .report-abuse-input6:hover {
    border-color: #7e56d9; /* Slightly darker border on hover */
  }
  
  .report-abuse-input6a{
    align-self: stretch;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 8px 13px;
    gap: 8px;
    max-width: 100%;

    outline: none; 
    appearance: none; 
    position: relative; 
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"%3E%3Cpath fill="gray" d="M10 12l-6-6h12z"/%3E%3C/svg%3E'); /* Custom dropdown arrow */
    background-repeat: no-repeat;
    background-position: 95%; 
    background-size: 15px;
  }

  .report-abuse-input6a:focus {
    border-color: #7e56d9; 
    box-shadow: 0 0 5px rgba(126, 86, 217, 0.5); 
  }
  
 
  .report-abuse-input6a:hover {
    border-color: #7e56d9; 
  }

  .report-abuse-hint-text6 {
    width: 320px;
    position: relative;
    line-height: 20px;
    color: #475467;
    display: none;
  }
  .report-abuse-input-field {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    max-width: 100%;
  }
  .report-abuse-label10 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 94px;
  }
  .report-abuse-mail-01-icon,
  .report-abuse-text274 {
    position: relative;
    overflow: hidden;
  }
  .report-abuse-mail-01-icon {
    height: 20px;
    width: 20px;
    flex-shrink: 0;
  }
  .report-abuse-text274 {
    /* width: calc(100% - 36px); */
    width: 80%;
    border: 0;
    outline: 0;
    font-family: Inter;
    font-size: 16px;
    background-color: transparent;
    height: 24px;
    flex: 1;
    line-height: 24px;
    color: #101828;
    text-align: left;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* min-width: 163px; */
    padding: 0;
  }
  .report-abuse-content73 {
    /* flex: 1; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;
  }
  .report-abuse-help-icon3 {
    height: 16px;
    width: 16px;
    position: relative;
  }
  .report-abuse-form,
  .report-abuse-input-field1,
  .report-abuse-input7 {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
  }
  .report-abuse-input7 {
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #d0d5dd;
    flex-direction: row;
    align-items: center;
    padding: 8px 13px;
    gap: 8px;
    width: 50%;
  }

  .report-abuse-email-domain {
    width: 50%;
    position: relative;
    line-height: 20px;
    color: #475467;
    padding: 9px 0px 0px 8px;
    
  }

  .report-abuse-email-card{
    flex-direction: row;
    display: flex;
  }

  .report-abuse-form,
  .report-abuse-input-field1 {
    flex-direction: column;
  }
  .report-abuse-input-field1 {
    align-items: flex-start;
    gap: 6px;
  }
  .report-abuse-form {
    align-items: flex-end;
  }
  .report-abuse-label11,
  .report-abuse-label12 {
    position: relative;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    min-width: 108px;
  }
  .report-abuse-label12 {
    min-width: 69px;
  }
  .report-abuse-section2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    max-width: 100%;
  }
  .report-abuse-divider12,
  .report-abuse-section-container {
    align-self: stretch;
    max-width: 100%;
  }
  .report-abuse-section-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 24px;
    box-sizing: border-box;
  }
  .report-abuse-divider12 {
    flex: 1;
    position: relative;
    background-color: #eaecf0;
  }
  .report-abuse-divider-wrapper,
  .report-abuse-slide-out-menu-header-parent {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .report-abuse-divider-wrapper {
    height: 1px;
    flex-direction: row;
    padding: 0 24px;
    box-sizing: border-box;
  }
  .report-abuse-slide-out-menu-header-parent {
    flex-direction: column;
    gap: 14px;
    text-align: left;
    font-size: 14px;
    color: #344054;
    font-family: Inter;
  }
  .report-abuse-placeholder-icon91 {
    height: 20px;
    width: 20px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    display: none;
    min-height: 20px;
  }
  .report-abuse-text275 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #344054;
    text-align: left;
    display: inline-block;
    min-width: 48px;
  }
  .report-abuse-buttonsbutton49,
  .report-abuse-text-padding51 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 2px;
  }
  .report-abuse-buttonsbutton49 {
    cursor: pointer;
    border: 1px solid #d0d5dd;
    padding: 8px 13px;
    background-color: #fff;
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    gap: 4px;
  }

  .report-abuse-buttonsbutton49:hover{
 background-color: #f9fafb;
  }

  .report-abuse-text276 {
    position: relative;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-align: left;
    display: inline-block;
    min-width: 55px;
    width: max-content;
    padding: 0 14px;
  }
  .report-abuse-actions19,
  .report-abuse-buttonsbutton50 {
    display: flex;
    flex-direction: row;
  }
  .report-abuse-buttonsbutton50 {
    cursor: pointer;
    border: 1px solid #7e56d9;
    padding: 8px 13px;
    background-color: #7e56d9;
    flex: 1;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  .report-abuse-buttonsbutton50:hover{
    background-color: #6941c6;
  }

  .report-abuse-actions19 {
    /* width: 179px; */
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
  }
  .report-abuse-content76,
  .report-abuse-footer2 {
    align-self: stretch;
    display: flex;
    align-items: center;
  }
  .report-abuse-content76 {
    flex-direction: row;
    justify-content: flex-end;
    padding: 16px 24px;
  }
  .report-abuse-footer2 {
    border-top: 1px solid #eaecf0;
    flex-direction: column;
    justify-content: flex-start;
  }
  .report-abuse-panel {
    background-color: #fff;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
  }
  .report-abuse-panel {
    width: 400px;
    box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08),
      0 8px 8px -4px rgba(16, 24, 40, 0.03);
    /* border-left: 1px solid #eaecf0; */
    border: 1px solid #7e56d9;
    border-radius: 8px;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: flex-start;
    /* gap: 433px; */
  }
  .report-abuse-slide-out-menu {
    flex-direction: row;
    justify-content: flex-end;
    /* padding: 0 0 0 40px; */
    line-height: normal;
    letter-spacing: normal;
    top: 120px;
    border-radius: 10px;
    background-color: #fff;
    max-width: 100%;
    /* overflow: hidden; */
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    z-index: 5;
    position: absolute;
   
    
    right: 3px;
    /* transform: translateX(100%);  */
    transition: transform 0.3s ease-in-out;

  
  }



  .report-abuse-slide-out-menu.open {
    transform: translateX(0); 
  }


/* Attachments css  */

.report-abuse-step3-file-upload-item-base3 {
        display: flex;
        justify-content: flex-start;
        max-width: 100%;
      
        flex-direction: column;
      
        align-self: stretch;
        border-radius: 12px;
        background-color: #fff;
        border: 1px solid #eaecf0;
        box-sizing: border-box;
        align-items: flex-end;
        padding: 14px 15px;
        gap: 4px;
        text-align: center;
        font-size: 10px;
        color: #fff;
}
      
.report-abuse-step3-frame-parent7 {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          max-width: 100%;
}
.report-abuse-step3-frame-parent7 {
          flex: 1;
          flex-direction: column;
          min-width: 267px;
          text-align: left;
          font-size: 14px;
          color: #344054;
}

.report-abuse-step3-file-type-icon-parent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;


  align-self: stretch;
  flex-direction: row;
  gap: 12px;
}

.report-abuse-step3-file-type-icon3 {
    height: 40px;
    width: 40px;
    position: relative;
}

.report-abuse-step3-page-icon3 {
    position: absolute;
    height: 100%;
    width: 80%;
    top: 0;
    right: 2.5%;
    bottom: 0;
    left: 17.5%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
  }

  .report-abuse-step3-file-type-wrap3 {
    position: absolute;
    height: 40%;
    width: 65%;
    top: 45%;
    right: 32.5%;
    bottom: 15%;
    left: 2.5%;
    border-radius: 2px;
    background-color: #da2d20;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2px 3px;
    box-sizing: border-box;
    z-index: 1;
  }

  .report-abuse-step3-file-type3 {
    position: relative;
    display: inline-block;
    min-width: 20px;
  }

  .report-abuse-step3-text-parent1 {
    align-self: stretch;
    position: relative;
 
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }

  .report-abuse-step3-text90 {
    position: relative;
    overflow: hidden;

    flex: 1;
    line-height: 20px;
    font-weight: 500;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }

.report-abuse-step3-supporting-text29{
  align-self: stretch;
  position: relative;

  line-height: 20px;
  color: #475467;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.report-abuse-step3-content69 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  gap: 12px;
}

.report-abuse-step3-featured-icon5 {
    width: 40px;
    height: 40px;
    position: relative;
    border-radius: 8px;
}

.report-abuse-step3-text-and-supporting-text13 {
    gap: 4px;
 
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.report-abuse-step3-action5 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0 20px;
    gap: 4px;
}

.report-abuse-step3-buttonsbutton16 {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 6px;
  }

  .report-abuse-step3-text88 {
    position: relative;
    line-height: 20px;
    font-weight: 600;
    display: inline-block;
    min-width: 101px;
    color: #6941c6;
  }

  .report-abuse-step3-text89 {
    position: relative;
    line-height: 20px;
    color: #475467;
    display: inline-block;
    min-width: 112px;
  }

  .report-abuse-step3-supporting-text28 {
    align-self: stretch;
    position: relative;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #475467;
  }

  .report-abuse-step3-pdf1 {
    font-weight: 600;
  }

  .documentLabel{
    padding: 5px;
    /* margin-bottom: 15px; */
    /* margin-left: 143px; */
    border:solid 2px #583c97;
    border-radius: 10px;
  }

  
  .custom-action{
    padding-top: 10px;
    display: block;
    }  

    .hide-input-field-report-abuse {
       display: none;
      }

  @media screen and (max-width: 400px) {
    .report-abuse-panel {
      gap: 216px;
    }
  }
  