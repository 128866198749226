body {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
}

.layout-chat-container {
    width: 100%;
    height: 800px;
    background: white;
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

/* Skeleton Loading Effect */
.layout-skeleton {
    background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 8px;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}

/* Chat Header */
.layout-chat-header {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

.layout-profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.layout-name {
    width: 120px;
    height: 15px;
}

/* Load Previous Messages Button */
.layout-load-btn {
    width: 150px;
    height: 25px;
    margin: 10px auto;
}

/* Messages Container */
.layout-messages {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    overflow-y: auto;
    width: 100%;
}

/* Messages */
.layout-message {
    width: 60%;
    height: 20px;
}

.layout-self-message {
    width: 50%;
    height: 20px;
    margin-left: auto;
}

/* Input Box */
.layout-input-container {
    display: flex;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #ddd;
    width: 100%;
}

.layout-input-box {
    flex: 1;
    height: 40px;
    border-radius: 5px;
}